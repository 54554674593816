import {
  Alert,
  Badge,
  Button,
  Flex,
  Group,
  Modal,
  Pagination,
  Progress,
  Radio,
  SimpleGrid,
  Switch,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LoraDevice, LoraDeviceUplink } from '../../../../shared/types/LoraDevice';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import BatteryChart from './BatteryChart';
import { readableDate } from '../../../../shared/utils/date';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import {
  batteryLevelToPercentage,
  batteryLevelToVoltage,
  batteryLevelToVoltageLiIion,
  getBatteryProgressBarColor,
} from '../../../../shared/utils/device';
import moment from 'moment';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { routes } from '../../../../shared/constants/routes';
import apiService from '../../../../shared/services/api';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import ChangeConfigurationModal from './ChangeConfigurationModal';
import ChangeConfigurationTimeModal from './ChangeConfigurationTimeModal';
import ChangeSthsConfigurationModal from './ChangeSthsConfigurationModal';
import useFetch from '../../../../shared/hooks/useFetch';

interface Props {
  loraDevice: LoraDevice;
  // uplinks?: LoraDeviceUplink[];
  // batteryUplinks?: LoraDeviceUplink[];
  // isLoadingUplinks?: boolean;
  refresh?: any;
}

const Row = ({ label, value = '-', to = undefined }: any) => (
  <div>
    <div>
      <strong>{label}</strong>:
    </div>
    <div>{to ? <Link to={to}>{value}</Link> : value}</div>
  </div>
);

function determineValue(value, inverted) {
  if (inverted) return 'inverted';
  return value ? 'true' : 'false';
}

const LoraDeviceData = (props: Props) => {
  const { loraDevice, refresh } = props;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const {
    isLoading: isLoadingUplinks,
    isLoaded: isLoadedUplinks,
    data: dataUplinks,
    error: errorUplinks,
    refresh: fetchLoraDeviceUplinks,
  } = useFetch<{ rows: any[]; count: number; batteryUplinks: any[] }>({
    fetchAction: () =>
      apiService({}).get(
        `/api/lora/devices/${loraDevice.uuid}/uplinks/paginated?page=${page - 1}&limit=${limit}`,
      ),
    fetchActionDeps: [loraDevice.uuid, page, limit],
    initialValue: { rows: [], count: 0, batteryUplinks: [] },
  });

  const translations = useTranslations();

  const percentageBatteryLevel = Number(
    batteryLevelToPercentage(loraDevice?.lastUplinkData?.Status?.batteryLevel) || -1,
  );

  const [isClearingAlert, setIsClearingAlert] = useState(false);
  const [isSettingLegacyAlgorithm, setIsSettingLegacyAlgorithm] = useState(false);
  const [isSettingLegacyHC, setIsSettingLegacyHC] = useState(false);
  const [isOpenChangeConfigurationModal, setIsOpenChangeConfigurationModal] = useState(false);
  const [isOpenChangeConfigurationTimeModal, setIsOpenChangeConfigurationTimeModal] =
    useState(false);
  const [isOpenChangeConfigurationSthsModal, setIsOpenChangeConfigurationSthsModal] =
    useState(false);

  const handleOnClearAlert = async () => {
    try {
      setIsClearingAlert(true);
      await apiService({}).post(`/api/lora/devices/${loraDevice.uuid}/clear-alert`);
      notifications.show({
        title: 'Alert został wyczyszczony',
        message: 'Przy kolejnym połączeniu urządzenia do sieci, alert zostanie wyzerowany',
        color: 'blue',
      });
    } catch (e) {
    } finally {
      setIsClearingAlert(false);
    }
  };

  const handleOnLegacy = async () => {
    try {
      setIsSettingLegacyAlgorithm(true);
      await apiService({}).post(`/api/lora/devices/${loraDevice.uuid}/update-legacy-device`);
      notifications.show({
        title: 'Ustawianie algorytmu',
        message: 'Przy kolejnym połączeniu urządzenia do sieci, algorytmy będą pobrane',
        color: 'blue',
      });
    } catch (e) {
    } finally {
      setIsSettingLegacyAlgorithm(false);
    }
  };

  const handleOnHealthCheck = async () => {
    try {
      setIsSettingLegacyHC(true);
      await apiService({}).post(`/api/lora/devices/${loraDevice.uuid}/update-legacy-healthcheck`, {
        value: 60,
      });
      notifications.show({
        title: 'Ustawianie health check',
        message: 'Przy kolejnym połączeniu urządzenia do sieci, zmiany będą pobrane',
        color: 'blue',
      });
    } catch (e) {
    } finally {
      setIsSettingLegacyHC(false);
    }
  };

  const getAlgorithmText = (algorithm: number) => {
    if ([1, 2].includes(algorithm)) {
      return `PIR (${algorithm})`;
    }
    if (algorithm === 4) {
      return 'Kontraktron (4)';
    }
    return algorithm;
  };
  const isOld = get(loraDevice, 'appEui') === '0000000000000000';
  return (
    <>
      <SimpleGrid cols={2} mb={20}>
        <div>
          {loraDevice && (
            <div>
              <ChangeConfigurationModal
                loraDevice={loraDevice}
                open={isOpenChangeConfigurationModal}
                onClose={() => setIsOpenChangeConfigurationModal(false)}
                onSuccess={() => {
                  refresh();
                  setIsOpenChangeConfigurationModal(false);
                }}
              />
              <ChangeConfigurationTimeModal
                loraDevice={loraDevice}
                open={isOpenChangeConfigurationTimeModal}
                onClose={() => setIsOpenChangeConfigurationTimeModal(false)}
                onSuccess={() => {
                  refresh();
                  setIsOpenChangeConfigurationTimeModal(false);
                }}
              />
              <ChangeSthsConfigurationModal
                loraDevice={loraDevice}
                open={isOpenChangeConfigurationSthsModal}
                onClose={() => setIsOpenChangeConfigurationSthsModal(false)}
                onSuccess={() => {
                  refresh();
                  setIsOpenChangeConfigurationSthsModal(false);
                }}
              />

              {/* {data?.awsData?.error && (
                <Alert color="red">To urządzenie nie istnieje w platformie AWS</Alert>
              )} */}
              <Flex direction="column" gap={10}>
                <Row
                  label={translations.global.name}
                  value={
                    <>
                      {loraDevice.name}
                      {isOld ? <Badge color="pink">old</Badge> : null}
                    </>
                  }
                />
                <Row label={translations.global.description} value={loraDevice.description} />
                <Row label={translations.loraDevice.devEui} value={loraDevice.devEui} />
                <Row
                  label={translations.global.company}
                  value={loraDevice.company?.name}
                  to={loraDevice.company && routes.companies.manage.to(loraDevice.company.uuid)}
                />
                <Row
                  label={translations.global.facility}
                  to={loraDevice.place && routes.places.manage.to(loraDevice.place.id)}
                  value={loraDevice.place?.name}
                />
                <Row
                  label={translations.global.device}
                  to={loraDevice.device && routes.devices.manage.to(loraDevice.device.id)}
                  value={loraDevice.device?.uniqueId}
                />
                <Row label={translations.loraDevice.devEui} value={loraDevice.devEui} />
                <Row
                  label={translations.global.createdAt}
                  value={readableDate(loraDevice.createdAt)}
                />
                <Row
                  label={translations.global.status}
                  value={
                    loraDevice.detectedValue ? (
                      <Badge color="red" size="lg">
                        {translations.devices.alert}
                      </Badge>
                    ) : (
                      <Badge color="green" size="lg">
                        {translations.devices.online}
                      </Badge>
                    )
                  }
                />
                <Row
                  label={'Last uplink at'}
                  value={
                    loraDevice.lastUplinkAt ? (
                      <div>
                        {readableDate(loraDevice.lastUplinkAt)} -{' '}
                        {moment(get(loraDevice, 'lastUplinkAt')).fromNow()}
                      </div>
                    ) : null
                  }
                />

                <Row
                  label={'Battery'}
                  value={
                    <div>
                      {loraDevice?.lastUplinkData && (
                        <Group>
                          <div style={{ width: 200 }}>
                            <Progress
                              style={{ height: 30, fontSize: 16 }}
                              color={getBatteryProgressBarColor(percentageBatteryLevel)}
                              value={percentageBatteryLevel}
                              size="xl"
                              label={`${percentageBatteryLevel}%`}
                            />{' '}
                          </div>
                          {percentageBatteryLevel}% /{' '}
                          {batteryLevelToVoltage(loraDevice?.lastUplinkData?.Status?.batteryLevel)}V
                        </Group>
                      )}
                    </div>
                  }
                />
              </Flex>
            </div>
          )}
          <Group mt={20}>
            <Button variant="outline" loading={isClearingAlert} onClick={handleOnClearAlert}>
              {translations.global.clearAlert}
            </Button>
            {!isOld && (
              <>
                <Button variant="outline" onClick={() => setIsOpenChangeConfigurationModal(true)}>
                  {translations.global.changeDetectionsConfiguration}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setIsOpenChangeConfigurationTimeModal(true)}>
                  {translations.global.changeTimeConfiguration}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setIsOpenChangeConfigurationSthsModal(true)}>
                  Zmień ustawienia czujnika STHS
                </Button>
              </>
            )}
            {isOld && (
              <Button
                variant="outline"
                onClick={() => handleOnLegacy()}
                loading={isSettingLegacyAlgorithm}>
                Ustaw legacy algorytm
              </Button>
            )}
            {isOld && (
              <Button
                variant="outline"
                onClick={() => handleOnHealthCheck()}
                loading={isSettingLegacyHC}>
                Ustaw health check na 1h
              </Button>
            )}
          </Group>
        </div>
        <div>
          <BatteryChart data={dataUplinks.batteryUplinks || []} />
        </div>
      </SimpleGrid>

      <Title order={3}>{translations.loraDevice.uplinks}</Title>
      <Pagination
        mb={20}
        total={Math.ceil(dataUplinks.count / limit)}
        value={page}
        onChange={setPage}
        mt="sm"
      />
      <Table isLoading={isLoadingUplinks}>
        <Thead>
          <Tr>
            <Th>{translations.global.createdAt}</Th>
            <Th>{translations.devices.battery}</Th>
            <Th>{translations.global.detectStatus}</Th>
            <Th>{translations.global.detectionAlgorithm}</Th>
            {!isOld && <Th>{translations.global.detectionNumber}</Th>}
            <Th>{translations.global.detectionCounter}</Th>
            {!isOld && <Th>{translations.global.timeFromDetection}</Th>}
            {!isOld && <Th>{translations.global.temperatureLevel}</Th>}
            <Th>Gateways RSSI</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataUplinks.rows?.map(uplink => (
            <Tr key={uplink.uuid}>
              <Td>{readableDate(uplink.createdAt)}</Td>
              <Td>
                <Progress
                  color={getBatteryProgressBarColor(
                    batteryLevelToPercentage(uplink.data?.Status?.batteryLevel),
                  )}
                  value={Number(batteryLevelToPercentage(uplink.data?.Status?.batteryLevel))}
                  size="xl"
                  label={`${batteryLevelToPercentage(uplink?.data?.Status?.batteryLevel)}%`}
                />
              </Td>
              <Td>
                {uplink.data.Status.detectStatus === 1 ? (
                  <Badge color="red">Detected</Badge>
                ) : (
                  <Badge color="green">Health check</Badge>
                )}
                {uplink.data.Status.ackedMessageId && <Badge color="violet">Pobrano downlink</Badge>}
              </Td>
              <Td>{getAlgorithmText(uplink.data.Status.detectedByAlgorithm)}</Td>
              {!isOld && <Td>{uplink.data.Status.detectionNumber}</Td>}
              <Td>
                {uplink.data.Status.detectionsCounterNew || uplink.data.Status.detectionsCounter}
              </Td>
              {!isOld && <Td>{uplink.data.Status.detectionTimestamp} minutes</Td>}
              {!isOld && <Td>{uplink.data.Status.temperatureLevel} °C</Td>}
              <Td>
                {uplink.data.Gateways?.map(gateway => {
                  return (
                    <div key={gateway.GatewayEui}>
                      {gateway.GatewayEui}: {gateway.Rssi} dBm
                    </div>
                  );
                })}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        mb={20}
        total={Math.ceil(dataUplinks.count / limit)}
        value={page}
        onChange={setPage}
        mt="sm"
      />
    </>
  );
};

export default LoraDeviceData;
