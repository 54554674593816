import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const FETCH_USER = createRequestTypes('users/FETCH_USER');
export const SAVE_USER = createRequestTypes('users/SAVE_USER');

export const fetchUser = ({ id }) => ({
  type: FETCH_USER.REQUEST,
  payload: { id }
});
export const saveUser = payload => ({
  type: SAVE_USER.REQUEST,
  payload: payload
});

const initState = {
  details: {
    states: loadingStates,
    data: {}
  },
  save: {
    states: loadingStates
  }
};

export default createReducer(initState, {
  [FETCH_USER.REQUEST]: state => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [FETCH_USER.SUCCESS]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [FETCH_USER.FAILURE]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [SAVE_USER.REQUEST]: state => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [SAVE_USER.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [SAVE_USER.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  }
});
