import React from 'react';
import moment from 'moment';
import {
  CircleCheck as IconCircleCheck,
  Calendar as IconCalendar,
  AlertCircle,
} from 'tabler-icons-react';
import { readableDate } from '../../../../shared/utils/date';
import { UserNotification, UserNotificationKey } from '../../../../shared/types/UserNotification';
import { translationService } from '../../../../shared/translations/translations';

const timeDifference = (endDate, createdAt) => {
  let daysDifference: any = '';
  if (endDate) {
    daysDifference = moment(endDate).from(createdAt, true);
  }
  return daysDifference;
};

export const getNotificationData = (notification: UserNotification) => {
  const endDate = notification.metadata.eventDate || undefined;
  const translations = translationService.getTranslations();
  let daysDifference = timeDifference(endDate, notification.createdAt);
  const body = `
    ${translations.global.name}: ${notification.scheduleEvent?.name || '-'} <br /> 
    ${translations.global.date}: ${readableDate(endDate)} <br /> 
    ${translations.global.description}: ${notification.scheduleEvent?.description || '-'} <br /> 
    ${translations.global.facility}: ${notification.place?.name || '-'}`;

  if (
    [
      UserNotificationKey.UPCOMING_INSPECTION,
      UserNotificationKey.UPCOMING_INTERVENTION,
      UserNotificationKey.UPCOMING_EVENT,
    ].includes(notification.notificationKey)
  ) {
    const title =
      translations.notifications.userNotificationsByKey[UserNotificationKey.UPCOMING_INSPECTION] ||
      translations.notifications.userNotificationsByKey.UPCOMING_EVENT;
    return {
      title,
      body,
    };
  }
  if (UserNotificationKey.LORA_DEVICE_ALERT === notification.notificationKey) {
    const body = `

    ${translations.global.facility}: ${notification.place?.name || '-'}`;

    return {
      title: `${
        translations.notifications.userNotificationsByKey[UserNotificationKey.LORA_DEVICE_ALERT]
      }: ${notification.device?.uniqueId}`,
      body,
    };
  }
  return { title: notification.title, description: notification.body };
};

export const getNotificationIcon = (notificationKey: UserNotificationKey, size = 25) => {
  if ([UserNotificationKey.LORA_DEVICE_ALERT].includes(notificationKey)) {
    return <AlertCircle size={size || 25} color="red" />;
  }
  if (
    [
      UserNotificationKey.UPCOMING_EVENT,
      UserNotificationKey.UPCOMING_INSPECTION,
      UserNotificationKey.UPCOMING_INTERVENTION,
    ].includes(notificationKey)
  ) {
    return <IconCalendar size={size || 25} />;
  }
  return <IconCircleCheck size={size || 30} />;
};

export const getNotificationIconColor = (notificationKey: UserNotificationKey) => {
  if([UserNotificationKey.LORA_DEVICE_ALERT].includes(notificationKey)) {
    return '#ffcaca'
  }
  return 'teal';
};
