import isEmpty from 'lodash/isEmpty';

const validate = values => {
  return {
    email: isEmpty(values.email) ? 'Please enter email' : null,
    password: isEmpty(values.password) ? 'Please enter password' : null,
  };
};

export default validate;
