import React, { forwardRef, useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import {
  Alert,
  Button,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  Select,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';
import AppContent from '../../../../../shared/components/AppContent';
import ListHeading from '../../../../../shared/components/ListHeading';
import { useHistory, useParams } from 'react-router';
import { routes } from '../../../../../shared/constants/routes';
import useFetch from '../../../../../shared/hooks/useFetch';
import { deviceErrorMessages } from '../../../../../shared/constants/errors';
import { pick } from 'lodash';
import LoraDeviceForm from '../../../../Lora/Devices/Form';
import { LoraDevice } from '../../../../../shared/types/LoraDevice';

const schema = z.object({
  uniqueId: z.string().optional(),
  prefix: z
    .string()
    .regex(new RegExp(/^[A-Z]+$/))
    .optional(),
  description: z.string().optional(),
  location: z.string().optional(),
  zoneId: z.string().optional(),
  deviceTypeId: z.number(),
});
interface Props {
  onClose: () => any;
  refreshParent: () => any;
  placeUuid: string;
  deviceUuid: string;
}

interface DeviceData {
  device: any;
}
const LoraDeviceModal = (props: Props) => {
  const { onClose, placeUuid, deviceUuid, refreshParent } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const formCreate = useForm({
    initialValues: {},
  });
  const formAssign = useForm({
    initialValues: {},
  });

  const {
    data: loraDevicesData,
    isLoading: isLoadingLoraDevices,
    isLoaded: isLoadedLoraDevices,
    error: errorLoraDevieces,
    refresh: refreshLoraDevices,
  } = useFetch<{
    loraDevices: Pick<LoraDevice, 'uuid' | 'name' | 'description' | 'devEui' | 'deviceUuid'>[];
  }>({
    initialValue: { loraDevices: [] },
    fetchAction: () => api({}).get(`/api/places/${placeUuid}/lora-devices`),
  });

  const { data, isLoading, isLoaded, error, refresh } = useFetch<DeviceData>({
    initialValue: { device: undefined },
    fetchAction: () => api({}).get(`/api/places/${placeUuid}/devices/${deviceUuid}`),
  });

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const { data } = await api({}).patch(
        `/api/places/${placeUuid}/devices/${deviceUuid}`,
        pick(formValues, ['name', 'description', 'zoneId', 'uniqueId', 'location']),
      );
      toast.success(translations.devices.create.success); // TODO
      setSaving(false);
      refreshParent();
      onClose();
    } catch (e) {
      setSaving(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    name: string;
    label: string;
    description: string;
    devEui: string;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ name, label, description, devEui, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/* <Avatar src={image} /> */}
          <div>
            <Text size="sm">
              {name} - {description}
            </Text>
            <div>
              <Text size="xs" opacity={0.65}>
                DevEUI: {devEui}
              </Text>
            </div>
          </div>
        </Group>
      </div>
    ),
  );

  const [isAssigning, setAssigning] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const onAssignLoraDevice = async formValues => {
    setAssigning(true);
    try {
      const { data } = await api({}).post(
        `/api/places/${placeUuid}/devices/${deviceUuid}/lora-device/assign`,
        pick(formValues, ['loraDeviceUuid']),
      );
      toast.success(translations.devices.create.success); // TODO
      setAssigning(false);
      refreshParent();
      onClose();
    } catch (e) {
      setAssigning(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };
  const onCreateLoraDevice = async formValues => {
    setCreating(true);
    try {
      const { data } = await api({}).post(
        `/api/places/${placeUuid}/devices/${deviceUuid}/lora-device`,
        pick(formValues, ['devEui', 'name', 'description']),
      );
      toast.success(translations.devices.create.success); // TODO
      setCreating(false);
      refreshParent();
      onClose();
    } catch (e) {
      setCreating(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  return (
    <div style={{ minHeight: 500 }}>
      <LoadingOverlay visible={isAssigning || isCreating} />
      <div>
        <form onSubmit={formAssign.onSubmit(onAssignLoraDevice)}>
          <Select
            mt={20}
            label="Urządzenie LoRa"
            name="loraDeviceUuid"
            required
            {...formAssign.getInputProps('loraDeviceUuid')}
            description="Widoczne są tylko urządzenia LoRa, które są przypisane do placówki, ale nie są przypisane do zadnego urządzenia"
            searchable
            data={loraDevicesData?.loraDevices.map(device => ({
              label: [device.name, device.description, device.devEui].filter(v => !!v).join(' - '),
              name: device.name,
              description: device.description,
              devEui: device.devEui,
              value: device.uuid,
              disabled: device.deviceUuid !== null,
            }))}
            itemComponent={SelectItem}
          />
          <Group mb={40} mt={20} position="left">
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.assign || 'Przypisz'}
            </Button>
          </Group>
        </form>
      </div>
      <Divider my="xs" label="Lub dodaj nowe" labelPosition="center" />
      {isLoading && <Loader />}
      {error && <Alert color="red">{translations.global.somethingWentWrong}</Alert>}
      {isLoaded && (
        <form onSubmit={formCreate.onSubmit(onCreateLoraDevice)}>
          <LoraDeviceForm form={formCreate} loraDeviceUuid={undefined} />
          <Group position="left" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={() => onClose()}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      )}
    </div>
  );
};

export default LoraDeviceModal;
