export enum RecommendationPriority {
  'LOW' = 'LOW',
  'MEDIUM' = 'MEDIUM',
  'HIGH' = 'HIGH',
}

export enum RecommendationStatus {
  'OPEN' = 'OPEN',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CLOSE' = 'CLOSE',
  'WAITING' = 'WAITING',
}

export interface Recommendation {
  map?: {
    name: string;
  };
  mapId?: number;
  area?: string;
  owner?: {
    name: string;
    surname: string;
  };
  ownerId: number;
  ownerText?: string;
  placeId: number;
  status: RecommendationStatus;
  priority: RecommendationPriority;
  deviceTypeId?: number;
  uniqueId: string;
  id: string;
  mapX: string;
  mapY: string;
}
