import React from 'react';
import { useForm } from '@mantine/form';
import { Anchor, Button, PasswordInput, TextInput } from '@mantine/core';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Logo from 'assets/mousee_white.png';
import { LoginUser, LoginPassword } from 'shared/components/Icons';
import { routes } from 'shared/constants/routes';
import { LanguageSelector } from 'shared/components/LanguageSelector';
import { useActions } from 'shared/hooks/useActions';
import { useTranslations } from 'shared/translations/useTranslations';

import EnterpriseClient from '../EnterpriseClient';
import { performLogin as performLoginAction } from '../../reducers/login';
import validate from './formValidate';

import './styles.scss';

const Login = () => {
  const { performLogin } = useActions({
    performLogin: performLoginAction,
  });

  //@ts-ignore
  const isLoading = useSelector(state => get(state.auth, 'login.states.isLoading'));
  //@ts-ignore
  const loginError = useSelector(state => get(state.auth, 'login.states.hasError'));

  const translations = useTranslations();

  const submit = formValues => {
    performLogin(formValues);
  };

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
  });

  return (
    <div className="Login">
      <div className="Login__wrapper">
        <div className="Login__header">
          <img src={Logo} className="Login__header__logo" alt="Mousee" />
          <div className="Login__language">
            <LanguageSelector />
          </div>
        </div>

        <form onSubmit={form.onSubmit(values => submit(values))}>
          <TextInput
            name="email"
            placeholder={translations.auth.userName}
            type="email"
            className="Login__input"
            icon={<LoginUser className="Login__input__icon" />}
            required
            {...form.getInputProps('email')}
          />

          <PasswordInput
            name="password"
            id="password"
            placeholder={translations.auth.password}
            icon={<LoginPassword className="Login__input__icon" />}
            className="Login__input"
            classNames={{
              input: 'Login__mantineInput',
              rightSection: 'Login__rightSection',
            }}
            {...form.getInputProps('password')}
          />
          {loginError && <span className="Login__error">{translations.auth.wrongCredentials}</span>}

          <Button loading={isLoading} className="Login__submit" type="submit">
            {translations.auth.signIn}
          </Button>
        </form>
        <EnterpriseClient />
        <Link className="Login__link" to={routes.auth.forgotPassword.path}>
          <Anchor align="center" style={{ width: '100%', display: 'inline-block' }}>
            {translations.auth.forgotPassword}
          </Anchor>
        </Link>
      </div>
    </div>
  );
};

export default Login;
