import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

import api from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { routes } from 'shared/constants/routes';
import { useTranslations } from 'shared/translations/useTranslations';

import Form from '../Form';

const getUuidOrId = (id: string | number) => {
  if (Number.isNaN(Number(id))) {
    return { placeUuid: id };
  }
  return { placeId: Number(id) };
};

interface Props {}
const Create = (props: Props) => {
  const translations = useTranslations();
  const { placeUuid } = useParams<{ placeUuid: string }>();
  const { id } = useParams<{ id: string }>();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      priority: 'LOW',
      status: 'OPEN',
      recommendation: undefined,
      comment: undefined,
      ownerId: undefined,
      ownerEmail: undefined,
      ownerText: undefined,
      area: undefined,
      dueDate: new Date(),
      recommendationCategoryId: undefined,
      deviceId: undefined,
      deviceIdA: [],
      deviceTypeId: undefined,
      zoneId: undefined,
      mapId: undefined,
      mapX: undefined,
      mapY: undefined,
      notifications: [],
    },

    validate: {},
  });
  const history = useHistory();
  const onSubmit = async (data: any) => {
    setSaving(true);
    const isUserCreated = !!data?.ownerText;
    const ownerText = isUserCreated ? data?.ownerText : undefined;
    const ownerId = !isUserCreated
      ? Number(String(data.ownerId).replace('existing-', ''))
      : undefined;
    try {
      const body = {
        ...data,
        ownerId,
        ownerText,
        mapId: !Number.isNaN(Number(data.mapId)) ? data.mapId : undefined,
        mapText: !Number.isNaN(Number(data.mapId)) ? undefined : data.mapId,
        // placeId: getUuidOrId(placeId).placeId,
        placeUuid
        // placeId,
      };
      await api({}).patch(`/api/recommendations/${id}`, body);
      toast.success(translations.recommendations.hasBeenModifiedSuccessfully);
      setSaving(false);
      history.push(routes.recommendation.details.to(placeUuid, id));
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  const goToDetails = () => {
    history.push(routes.recommendation.details.to(placeUuid, id));
  };
  return (
    <>
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <ListHeading title={translations.recommendations.editRecommendation}>
          <Group position="right" mt="md">
            <Button type="submit" loading={isSaving}>
              {translations.global.edit}
            </Button>
            <Button type="button" color="" variant="outline" onClick={goToDetails}>
              {translations.global.cancel}
            </Button>
          </Group>
        </ListHeading>
        <AppContent>
          <Form form={form} />
        </AppContent>
      </form>
    </>
  );
};

export default Create;
