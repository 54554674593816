import React from 'react';
import { MultiSelect, TextInput } from '@mantine/core';
import { Company } from '../../../../shared/types/Company';

interface Props {
  form: any; // UseFormReturnType<any>;
  subCompanies: Company[];
}

const AddCompanyFileCategoryForm = (props: Props) => {
  const { form, subCompanies } = props;
  return (
    <div>
      <TextInput
        name={`name`}
        placeholder="Nazwa"
        mb={10}
        label="Nazwa kategorii"
        required
        {...form.getInputProps('name')}
      />
      <TextInput
        name={`description`}
        placeholder=""
        mb={10}
        label="Opis"
        {...form.getInputProps('description')}
      />
      <MultiSelect
        name="subCompanies"
        data={subCompanies?.map(sc => ({ label: sc.name, value: sc.uuid })) || []}
        label="Firmy"
        description="Uzytkownicy w wybranych firmach, będą mieli dostęp do plików w tej kategorii"
        {...form.getInputProps('subCompanies')}
      />
    </div>
  );
};
export default AddCompanyFileCategoryForm;
