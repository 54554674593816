import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import {
  Button,
  Title,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Loader,
  Flex,
} from '@mantine/core';
import { useHistory, useParams } from 'react-router';

import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import useFetch from 'shared/hooks/useFetch';
import { useOnSubmit } from './hooks';
import { routes } from '../../../../shared/constants/routes';
import { pick } from 'lodash';
import useCurrentUser from '../../../../shared/hooks/useCurrentUser';
import { UserType } from '../../../../shared/types/User';
import LoraDeviceForm from '../Form';

function generateDevEUI() {
  let devEUI = '';
  for (let i = 0; i < 8; i++) {
    let randomByte = Math.floor(Math.random() * 256);
    devEUI += ('0' + randomByte.toString(16)).slice(-2).toUpperCase();
  }
  return devEUI;
}

const Manage = () => {
  const user = useCurrentUser();
  const form = useForm({
    initialValues: {
      name: ``,
      description: '',
      appEui: '0101010101010101',
      devEui: generateDevEUI(),
      placeUuid: undefined,
      companyUuid: undefined,
      deviceUuid: undefined,
    },
  });

  const { loraDeviceUuid } = useParams<{
    loraDeviceUuid?: string;
  }>();

  const translations = useTranslations();

  const { submitValues, isSubmittingForm } = useOnSubmit(loraDeviceUuid);

  const onSubmit = async values => {
    if (loraDeviceUuid) {
      submitValues(pick(values, ['name', 'description', 'deviceUuid', 'placeUuid', 'companyUuid']));
    } else {
      submitValues(values);
    }
  };

  // TODO: Translations
  const title = loraDeviceUuid
    ? `${translations.devices.editDevice} Lora`
    : `${translations.devices.addDevice} Lora`;
  // ? translations.loraDevice.editLoraDevice
  // : translations.loraDevice.createLoraDevice;

  const {
    isLoading,
    isLoaded,
    data: loraDevice,
    error,
    refresh: fetchLoraDevice,
  } = useFetch<{ loraDevice: LoraDevice }>({
    fetchAction: () => apiService({}).get(`/api/lora/devices/${loraDeviceUuid}`),
    fetchActionDeps: [loraDeviceUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });
  const { isLoading: isLoadingCompanies, data: dataCompanies } = useFetch<any[]>({
    fetchAction: () => apiService({}).get(`/companies`),
    fetchActionDeps: [loraDeviceUuid],
    initialValue: [],
  });

  useEffect(() => {
    if (loraDeviceUuid) {
      fetchLoraDevice();
    }
  }, [loraDeviceUuid]);

  useEffect(() => {
    if (loraDevice) {
      //@ts-ignore
      form.setValues(loraDevice?.loraDevice);
    }
  }, [loraDevice]);

  const {
    data: placesData,
    refresh: loadPlaces,
    isLoading: isLoadingPlaces,
  } = useFetch<any[]>({
    initialValue: [],
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(
        `/places${form.values.companyUuid ? `?companyUuid=${form.values.companyUuid}` : ''}`,
      ),
  });

  const {
    data: devicesData,
    refresh: loadDevices,
    isLoading: isLoadingDevices,
  } = useFetch<{ devices: any[] }>({
    initialValue: { devices: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/places/${form.values.placeUuid}/devices`),
  });

  useEffect(() => {
    if (user.type === UserType.COMPANY_ADMIN || user.type === UserType.SUPER_ADMIN) {
      if (form.values.companyUuid) {
        loadPlaces();
      }
    } else {
      loadPlaces();
    }
  }, [form.values.companyUuid]);
  useEffect(() => {
    if (form.values.placeUuid) {
      loadDevices();
    }
  }, [form.values.placeUuid]);

  const companiesList = useMemo(() => {
    const result = [];
    result.push(
      ...dataCompanies?.map(company => ({
        label: `${company.name}`,
        value: String(company.uuid),
        group: String(company.name),
      })),
    );
    dataCompanies?.forEach(c => {
      result.push(
        ...c.subCompanies?.map(sc => ({
          label: `${sc.name}`,
          value: String(sc.uuid),
          group: String(c.name),
        })),
      );
    });
    return result;
  }, [dataCompanies]);
  const places = placesData
    ?.filter(p => {
      if (user.type === UserType.COMPANY_ADMIN || user.type === UserType.SUPER_ADMIN) {
        return p.companyUuid === form.values.companyUuid;
      }
      return true;
    })
    ?.map(place => ({
      value: String(place.uuid),
      label: place.name,
      group: place.company?.name,
    }));

  const devices = devicesData?.devices
    ?.filter(d => d.placeUuid === form.values.placeUuid)
    ?.map(device => ({
      value: String(device.uuid),
      label: `${device.uniqueId}`,
    }));
  const [placeSearchValue, setPlaceSearchValue] = useState('');
  const history = useHistory();

  return (
    <AppContent>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex align="center" mb={20} gap={20}>
          <Title>{title}</Title>
          <Button type="submit" loading={isSubmittingForm}>
            {translations.global.save}
          </Button>
          <Button
            type="button"
            disabled={isSubmittingForm}
            variant="outline"
            onClick={() => history.push(routes.lora.devices.default.path)}>
            {translations.global.cancel}
          </Button>
        </Flex>
        <SimpleGrid cols={2} spacing={20} mb={20}>
          <div>
            <LoadingOverlay visible={isLoading} overlayBlur={1} />
            <LoraDeviceForm form={form} loraDeviceUuid={loraDeviceUuid} />
          </div>
          <div>
            {[UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN].includes(user.type) && (
              <Select
                placeholder=""
                searchable
                clearable
                mb={20}
                label={translations.global.company}
                data={companiesList}
                {...form.getInputProps('companyUuid')}
                onChange={value => {
                  form.setFieldValue('placeUuid', undefined);
                  form.setFieldValue('deviceUuid', undefined);
                  form.getInputProps('companyUuid').onChange(value);
                  setPlaceSearchValue('');
                }}
                rightSection={isLoadingCompanies ? <Loader size="xs" /> : null}
              />
            )}
            <Select
              clearable
              label={translations.global.facility}
              mb={20}
              name="placeUuid"
              data={places}
              rightSection={isLoadingPlaces ? <Loader size="xs" /> : null}
              searchable
              searchValue={placeSearchValue}
              onSearchChange={setPlaceSearchValue}
              onChange={value => {
                form.setFieldValue('deviceUuid', undefined);
                form.getInputProps('placeUuid').onChange(value);
                // setPlaceSearchValue('');
              }}
              // disabled={!form.values.companyUuid}
              {...form.getInputProps('placeUuid')}
            />
            <Select
              clearable
              label={translations.global.device}
              mb={20}
              name="deviceUuid"
              data={devices}
              searchable
              rightSection={isLoadingDevices ? <Loader size="xs" /> : null}
              // searchable
              // searchValue={placeSearchValue}
              // onSearchChange={setPlaceSearchValue}
              disabled={!form.values.placeUuid}
              {...form.getInputProps('deviceUuid')}
            />
          </div>
        </SimpleGrid>
      </form>
    </AppContent>
  );
};

export default Manage;
