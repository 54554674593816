import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Button, Group, Loader, Modal, SimpleGrid, Tabs, Text, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import AppContent from '../../../../../shared/components/AppContent';
import ListHeading from '../../../../../shared/components/ListHeading';
import { useHistory, useParams } from 'react-router';
import { routes } from '../../../../../shared/constants/routes';
import useFetch from '../../../../../shared/hooks/useFetch';
import QRCode from 'react-qr-code';
import ActionsAndObjects from '../../../../Device/containers/Manage/ActionsAndObjects';
import { reverse, sortBy } from 'lodash';
import PlaceDevicesEdit from '../EditModal';
import PlaceDevicesLora from '../Lora';

const schema = z.object({
  uniqueId: z.string().optional(),
  prefix: z
    .string()
    .regex(new RegExp(/^[A-Z]+$/))
    .optional(),
  description: z.string().optional(),
  location: z.string().optional(),
  zoneId: z.string().optional(),
  deviceTypeId: z.number(),
});

interface Props {}

interface DeviceData {
  device: any;
}
const PlaceDevicesDetails = (props: Props) => {
  const params = useParams<{ deviceUuid: string; placeId: string }>();
  const { deviceUuid, placeId } = params;
  const translations = useTranslations();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<DeviceData>({
    initialValue: { device: undefined },
    fetchAction: () => api({}).get(`/api/places/${placeId}/devices/${deviceUuid}`),
  });

  const [editMode, setEditMode] = useState(false);

  return (
    <>
      {data.device && (
        <Modal
          opened={editMode}
          onClose={() => setEditMode(false)}
          title={translations.global.edit}>
          <PlaceDevicesEdit
            onClose={() => setEditMode(false)}
            refreshParent={() => {
              refresh();
            }}
            placeId={placeId}
            deviceUuid={data.device.uuid}
          />
        </Modal>
      )}
      <ListHeading
        title={
          [data.device?.name, data.device?.uniqueId].filter(i => !!i).join(' / ') ||
          translations.global.device
        }>
        <Group>
          <Button
            onClick={e => {
              setEditMode(true);
            }}>
            Edytuj
          </Button>
          {/* <Button onClick={e => {}} color="red">
            Usuń
          </Button> */}
          <Button
            ml={'auto'}
            onClick={e => {
              window.open(routes.devices.manage.to(data.device.id), '_blank');
            }}
            variant="outline"
            color="blue">
            Otwórz stary widok
          </Button>
        </Group>
      </ListHeading>
      <AppContent>
        {isLoading && <Loader />}
        {isLoaded && data.device && (
          <Tabs aria-label="disabled tabs example" defaultValue="0" keepMounted={false}>
            <Tabs.List>
              <Tabs.Tab value="0" style={{ padding: '25px' }}>
                {translations.devices.details}
              </Tabs.Tab>
              <Tabs.Tab value="1" style={{ padding: '25px' }}>
                {translations.devices.actionsAndObjects}
              </Tabs.Tab>
              <Tabs.Tab value="2" style={{ padding: '25px' }}>
                {translations.loraDevice.loraDevice}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="0" pt={20}>
              <div>
                <SimpleGrid cols={2}>
                  <div>
                    <Row label={translations.devices.uniqueId} value={data.device?.uniqueId} />
                    <Row label={translations.global.name} value={data.device?.name} />
                    <Row label={translations.global.description} value={data.device?.description} />
                    <Row
                      label={translations.global.deviceType}
                      value={data.device?.deviceType?.name}
                    />
                    <Row label={translations.zones.zone} value={data.device?.zone?.name} />
                    <Row label={translations.devices.location} value={data.device?.location} />
                  </div>
                  <div>
                    <Row
                      label="QR"
                      value={
                        data.device?.qrcodeId ? (
                          <QRCode value={data.device.qrcodeId} />
                        ) : (
                          'Brak przypisanego kodu QR'
                        )
                      }
                    />
                    <Row
                      label="NFC"
                      value={
                        data.device?.nfcId ? 'NFC zostalo sprecyzowane' : 'Brak przypisanego NFC'
                      }
                    />
                  </div>
                </SimpleGrid>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="1" pt={20}>
              <ActionsAndObjects
                deviceId={data.device.id}
                deviceTypeId={data.device.deviceTypeId}
                actions={reverse(sortBy(data.device.deviceActions))}
                objects={reverse(sortBy(data.device.deviceObjects))}
              />
            </Tabs.Panel>
            <Tabs.Panel value="2" pt={20}>
              <PlaceDevicesLora placeUuid={data.device.placeUuid} deviceUuid={data.device.uuid} />
            </Tabs.Panel>
          </Tabs>
        )}
      </AppContent>
    </>
  );
};

const Row = ({ label, value }) => (
  <Text mb={10}>
    <div>
      <strong>{label}</strong>:
    </div>
    <div>{value || '-'}</div>
  </Text>
);

export default PlaceDevicesDetails;
