import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { fetchCompanies } from '../../services/companyService';

export default ({ companyId, form }) => {
  const [companies, setCompanies] = useState([]);
  const translations = useTranslations();
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await fetchCompanies();
        // setData(data);
        const mappedCompanies = data
          .filter(
            company => String(companyId) !== String(company.uuid) && !company.parentCompanyUuid,
          )
          .map(company => ({
            label: company.name,
            value: company.uuid,
          }));
        setCompanies(mappedCompanies);
      } catch (e) {}
    };
    fetch();
  }, [companyId]);
  return (
    <div>
      <Select
        name="parentCompanyUuid"
        label={translations.companies.parentCompany}
        placeholder={translations.global.selectCompany}
        data={companies}
        {...form.getInputProps('parentCompanyUuid')}
      />
    </div>
  );
};
