import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteDeviceTypeObject } from 'modules/DeviceType/services/deviceTypeService';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';

const DeleteDeviceTypeObject = ({ id, onFinish, close, isOpen }) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState();
  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteDeviceTypeObject(id);
      setLoading(false);
      close();
      onFinish();
      toast.success(translations.deviceTypes.deleteObjectSuccess);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      paragraph={
        <div>
          {translations.deviceTypes.deleteObjectDesc}
          <br />
        </div>
      }
      isLoading={isLoading}
      onClose={() => close()}
      onConfirm={onDelete}
      title={translations.deviceTypes.deleteObjectTitle}
      confirmLabel={translations.global.delete}
      cancelLabel={translations.global.cancel}
    />
  );
};

export default DeleteDeviceTypeObject;
