import React from 'react';
import './styles.scss';

export default ({ children, isActive }) => {
  return (
    <div className="FormSection">
      {!isActive && <div className="FormSection__blocked" />}
      {children}
    </div>
  );
};
