import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Modal, TextInput } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import TranslationForm from 'shared/components/TranslationForm';

const AddDeviceTypeAction = ({
  onFinish,
  deviceTypeId = undefined,
  parentCompanyId = undefined,
}) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>();

  const form = useForm({
    initialValues: {
      name: '',
      shouldClean: false,
      isInspection: false,
      settings: { addWhenDeviceAddedToInspection: false },
      translations: [
        { lang: 'pl', name: undefined },
        { lang: 'en', name: undefined },
        { lang: 'de', name: undefined },
        { lang: 'it', name: undefined },
        { lang: 'ru', name: undefined },
      ],
    },
  });

  const handleClose = () => {
    setOpen(false);
    form.reset();
  };
  const handleSubmit = async formValues => {
    try {
      setLoading(true);
      const body = {
        deviceTypeId,
        name: formValues.name,
        translations: {
          pl: { name: '' },
          en: { name: '' },
          de: { name: '' },
          it: { name: '' },
          ru: { name: '' },
        },
        shouldClean: formValues.shouldClean,
        isInspection: formValues.isInspection,
        parentCompanyId,
        settings: formValues.settings || {},
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
      });
      await apiService({}).post(`/device-type-actions`, body);
      handleClose();
      setLoading(false);
      onFinish();
      toast.success(translations.deviceTypes.addActionSuccess);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => handleClose()}
        title={translations.deviceTypes.addActionTitle}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            required
            label={translations.global.name}
            placeholder=""
            {...form.getInputProps('name')}
          />
          <TranslationForm
            fieldsLabel={translations.global.name}
            languageKey="lang"
            translationsKey="translations"
            fieldKey="name"
            form={form}
          />
          <Checkbox
            mt="md"
            label={translations.devices.cleanAction}
            {...form.getInputProps('shouldClean', { type: 'checkbox' })}
          />
          <Checkbox
            mt="md"
            label={translations.devices.inspection}
            {...form.getInputProps('isInspection', { type: 'checkbox' })}
          />
          <Checkbox
            mt="md"
            label={'Automatyczna akcja przy dodaniu urządzenia do przeglądu'}
            {...form.getInputProps('settings.addWhenDeviceAddedToInspection', { type: 'checkbox' })}
          />
          <Group position="right" mt="md">
            <Button type="submit" loading={isLoading}>
              {translations.global.add}
            </Button>
            <Button type="button" variant="outline" onClick={() => handleClose()}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>

      <Button ml={30} leftIcon={<Plus size={14} />} size="xs" compact onClick={() => setOpen(true)}>
        {translations.global.add}
      </Button>
    </>
  );
};

export default AddDeviceTypeAction;
