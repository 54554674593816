import React, { useEffect, useState } from 'react';
import { Alert, Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import apiService from '../../../../../shared/services/api';
import AddCompanySubscriptionForm from './AddCompanySubscriptionForm';
import { AlertCircle } from 'tabler-icons-react';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
}

const AddCompanySubscriptionModal = (props: Props) => {
  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      validUntil: undefined,
      devicesLimit: undefined,
      usersLimit: undefined,
      active: 'true',
    },
  });
  const { onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState<any>();

  useEffect(() => {
    if (!props.opened) {
      form.reset();
    }
  }, [props.opened]);

  const handleAdd = async formValues => {
    try {
      setError(undefined);
      setSaving(true);
      const { data } = await apiService({}).post(`/companies/${props.companyId}/subscriptions`, {
        ...formValues,
        active: String(formValues.active) === 'true' ? true : false,
      });
      form.reset();
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e?.response?.data?.code);
    } finally {
      setSaving(false);
    }
  };

  const translations = useTranslations();
  return (
    <Modal opened={opened} onClose={onClose} title="Dodaj subskrypcję">
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanySubscriptionForm form={form} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            {translations.global.add}
          </Button>
        </Group>
        {hasError === 'subscription/only_one_active' && (
          <Alert color="red" mt={10} icon={<AlertCircle size={16} />}>
            {translations.global.onlyOneActiveSubscription}
          </Alert>
        )}
      </form>
    </Modal>
  );
};
export default AddCompanySubscriptionModal;
