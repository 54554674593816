import React from 'react';

import { MapContainer } from 'react-leaflet';
import { CRS, LatLngBounds } from 'leaflet';
import PlaceMapDetails, { PlaceMapDetailsProps } from './PlaceMapDetails';
import { Device } from './PlaceMap';

interface PlaceMap {
  id: number;
  url: string;
  name: string;
  devices: string[];
}

interface MapProps extends PlaceMapDetailsProps {
  setMap: React.Dispatch<unknown>;
  bounds: LatLngBounds;
}

const Map = (props: MapProps) => {
  const {
    bounds,
    currentMap,
    droppedDevice,
    devicesWithoutMap,
    setMap,
    placeId,
    onRefetch,
    onSetDroppedDeviceMarker,
    selectedDeviceType,
    setBounds,
  } = props;

  return (
    <MapContainer
      whenCreated={setMap}
      center={bounds.getCenter()}
      scrollWheelZoom={false}
      style={{
        width: '100%',
        height: 732,
      }}
      boxZoom={true}
      keyboard={false}
      doubleClickZoom={false}
      zoom={-3}
      maxZoom={8}
      minZoom={-8}
      zoomControl={true}
      crs={CRS.Simple}
      touchZoom={false}
      tap={false}>
      {currentMap && (
        <PlaceMapDetails
          bounds={bounds}
          placeId={placeId}
          selectedDeviceType={selectedDeviceType}
          onRefetch={onRefetch}
          currentMap={currentMap}
          devicesWithoutMap={devicesWithoutMap}
          setBounds={setBounds}
          droppedDevice={droppedDevice}
          onSetDroppedDeviceMarker={onSetDroppedDeviceMarker}
        />
      )}
    </MapContainer>
  );
};

export default React.memo(Map);
