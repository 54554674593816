import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';

const schema = z.object({
  name: z.string(),
  amount: z.number(),
  companyId: z.number().optional(),
});
interface Props {
  onClose: () => any;
  refreshParent: () => any;
}
const Create = (props: Props) => {
  const { refreshParent, onClose } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      name: undefined,
      amount: undefined,
      companyId: undefined,
    },
    schema: zodResolver(schema),
  });
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api({}).get('/companies');
        setCompanies(data);
      } catch (e) {}
    }
    fetchData();
  }, []);

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        amount: formValues.amount,
        companyId: formValues.companyId,
      };
      await api({}).post(`/api/qrcodes/batches`, body);
      toast.success(translations.qrCodes.batchAddedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <>
      <Modal opened={opened} onClose={handleClose} title={translations.global.add}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} companies={companies} />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={() => setOpened(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>
      <Group position="center">
        <Button onClick={() => setOpened(true)}>{translations.global.generate}</Button>
      </Group>
    </>
  );
};

export default Create;
