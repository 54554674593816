import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
// import DeleteRecommendationModal from '../DeleteRecommendationModal';
import { useTranslations } from 'shared/translations/useTranslations';
import { map } from 'lodash';

import { Grid, SimpleGrid, Space, Table } from '@mantine/core';
import EditDeviceTypeObject from '../EditDeviceTypeObject';

import styles from './styles.module.scss';
import DeleteDeviceTypeObject from '../DeleteDeviceTypeObject';
import UnassignObjectFromDeviceType from '../UnassignObjectFromDeviceType';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {
  isLoading: boolean;
  deviceTypeId: string | number;
  objects?: any[];
  reset: () => void;
}

const Objects = (props: Props) => {
  const translations = useTranslations();
  const { isLoading, objects, reset, deviceTypeId } = props;
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>{translations.global.name}</th>
          <th style={{ width: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {map(objects, object => (
          <tr key={`object-${object.id}`} className={styles.row}>
            <td>{object.name}</td>
            <td>
              <div className={styles.buttons}>
                <div className="d-flex">
                  {/* <EditDeviceTypeObject id={object.id} object={object} onFinish={reset} /> */}
                  <UnassignObjectFromDeviceType
                    id={object?.objectAssociation?.uuid}
                    onFinish={reset}
                    deviceTypeId={deviceTypeId}
                  />
                  <Space w="xs" />
                  {/* <DeleteDeviceTypeObject id={object.id} onFinish={reset} /> */}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>{/* <AddAction deviceTypeId={id} /> */}</tfoot>
    </Table>
  );
};

export default Objects;
