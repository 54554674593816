import React, { useState } from 'react';
import map from 'lodash/map';
import { Badge, Grid, Select, TextInput } from '@mantine/core';
import get from 'lodash/get';

import ListHeading from 'shared/components/ListHeading';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import Button from 'shared/components/Button';
import {
  getUserTypeLabel,
  getUserStatusLabel,
  selectIsCompanyAdmin,
  selectIsSuperAdmin,
  getUserTypeSelectData,
} from 'shared/utils/user';
import { translationService } from 'shared/translations/translations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { usePushAction, usePushActionWithId } from 'shared/hooks/usePushRoute';
import { User, UserType } from 'shared/types/User';
import { Search } from 'tabler-icons-react';
import { getCompanySelectData } from './helpers';
import { useFilteredUsers } from './hooks';

const List = () => {
  const { data, isLoading, isLoaded, error } = useFetch<User[]>({
    initialValue: [],
    fetchAction: () => apiService({}).get('/users'),
  });

  const isCompanyAdmin = selectIsCompanyAdmin();
  const isSuperAdmin = selectIsSuperAdmin();
  const goToCreate = usePushAction(routes.users.create.path);
  const goToEdit = usePushActionWithId(routes.users.manage.to, (user: User) => user.uuid);
  const translations = translationService.getTranslations();
  const companySelectData = getCompanySelectData(data);

  const [typeFilter, setTypeFilter] = useState<UserType | undefined>();
  const [companyFilter, setCompanyFilter] = useState<string>();
  const [searchFilter, setSearchFilter] = useState<string>();
  const filteredUsers = useFilteredUsers(data, searchFilter, typeFilter, companyFilter);
  return (
    <div>
      <ListHeading title={translations.global.users}>
        {(isSuperAdmin || isCompanyAdmin) && (
          <Button type="primary" onClick={goToCreate}>
            {translations.global.add}
          </Button>
        )}
      </ListHeading>
      <AppContent>
        <Grid mb={20}>
          <Grid.Col span={3}>
            <TextInput
              label={translations.users.searchUsers}
              placeholder={translations.users.searchUsers}
              icon={<Search size={14} />}
              onChange={event => setSearchFilter(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label={translations.global.type}
              placeholder={translations.global.type}
              data={getUserTypeSelectData(isSuperAdmin)}
              clearable
              //@ts-ignore
              onChange={setTypeFilter}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label={translations.global.company}
              placeholder={translations.global.company}
              data={companySelectData}
              clearable
              onChange={setCompanyFilter}
            />
          </Grid.Col>
        </Grid>
        <div className="row">
          <div className="col-md-12">
            <Table isLoading={isLoading}>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th> {translations.global.firstName}</Th>
                  <Th> {translations.global.lastName}</Th>
                  <Th> {translations.global.email}</Th>
                  <Th>{translations.global.company}</Th>
                  <Th> {translations.global.status}</Th>
                  <Th> {translations.global.type}</Th>
                  <Th> {translations.global.facilities}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {map(filteredUsers, (user, index) => (
                  <Tr key={user.uuid} onClick={goToEdit(user)}>
                    <Td>{index + 1}</Td>
                    <Td>{user.name}</Td>
                    <Td>{user.surname}</Td>
                    <Td>{user.email}</Td>
                    <Td>{get(user, 'company.name') || '-'}</Td>
                    <Td>
                      <Badge color={user.status === 'ACTIVE' ? 'green' : 'red'}>
                        {getUserStatusLabel(user.status)}
                      </Badge>
                    </Td>
                    <Td>{getUserTypeLabel(user.type)}</Td>
                    <Td>{user.places.length}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
      </AppContent>
    </div>
  );
};

export default List;
