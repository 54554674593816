import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import api from 'shared/services/api';

interface DashboardResponse {
  places: {
    count: number;
  };
  recommendations: {
    count: number;
    placesCount: number;
  };
  schedule: {
    todayUpcomingEventsCount: number;
  };
  company: {
    uuid: string;
    name: string;
    usersCount: number;
    clientsCount: number;
  };

  electronicDevices: {
    alertCount: number;
    list: {
      uuid: string;
      uniqueId: string;
      placeUuid: string;
      detect: boolean;
      location: string;
      place: {
        uuid: string;
        name: string;
      };
    }[];
  };
}

export const useDashboardQuery = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: () => api({}).get<DashboardResponse>(`/api/dashboard`),
    staleTime: Infinity,
    refetchOnMount: true,
    select: (response: AxiosResponse<DashboardResponse>) => {
      if (!response.data) return undefined;
      return response.data;
    },
  });
};
