import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { get } from 'lodash';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Marker, Popup, ImageOverlay, useMapEvents } from 'react-leaflet';
import { divIcon, LatLng, latLng, LatLngBounds, latLngBounds, Point } from 'leaflet';

import { batteryLevelPercentage, dateLongerThanMinutes, rssiToText } from 'shared/utils/device';
import { USER_CONTROLLER } from 'shared/constants/user';

import { Device } from './PlaceMap';
import SetDeviceOnMap from './SetDeviceOnMap';

import styles from './styles.module.scss';

export interface PlaceMapDetailsProps {
  selectedDeviceType?: number;
  placeId: string | number;
  currentMap?: any;
  setBounds: (bounds: LatLngBounds) => void;
  onRefetch: () => void;
  devicesWithoutMap: {
    description: string;
    detect: boolean;
    id: number;
    mapId: string;
    mapX: string;
    mapY: string;
    name: string;
  }[];
  droppedDevice?: {
    device: Device;
    point: {
      x: number;
      y: number;
    };
  };
  onSetDroppedDeviceMarker: (droppedDevice: {
    mapId: number;
    device: Device;
    lat: number;
    lng: number;
  }) => void;
  bounds: LatLngBounds;
}

interface PlaceMap {
  id: number;
  url: string;
  name: string;
  devices: string[];
}
interface State {
  maps: PlaceMap[];
}

function getMeta(url, setMapSize, setBounds) {
  const img = new Image();
  img.addEventListener('load', function () {
    // setMapSize({ width: this.naturalWidth, height: this.naturalHeight });
    setBounds(latLngBounds([[0, 0], latLng(this.naturalHeight, this.naturalWidth)]));
  });
  img.src = url;
}

const getSignedUrlMap = async (placeMap, props, setSignedUrlMap) => {
  const {
    data: { url },
  } = await api({}).get(`places/${props.placeId}/maps/${placeMap.id}/signed-url`);
  setSignedUrlMap(url);
};

const meIcon = (device, type) => {
  const classByType = {
    red: styles.iconRed,
    green: styles.iconGreen,
    normal: styles.iconNormal,
    yellow: styles.iconYellow,
    yellowRed: styles.iconYellowRed,
  };
  let style = '';
  if (type === 'normal' && device.deviceType?.color) {
    style = `background-color: ${device.deviceType.color} !important;border-radius: 0 !important;`;
  }
  return divIcon({
    className: styles.iconWrapper,
    html: `<div class="${styles.icon} ${classByType[type]}" style="${style}">${device.uniqueId}</div>`,
    iconAnchor: [20, 40],
    iconSize: [40, 40],
    popupAnchor: [0, -40],
  });
};

const PlaceMapDetails = (props: PlaceMapDetailsProps) => {
  const { bounds } = props;
  const [addDevice, setAddDevice] = useState<undefined | LatLng>();
  const { push } = useHistory();
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  const [signedUrlMap, setSignedUrlMap] = useState();
  const { currentMap, setBounds, selectedDeviceType } = props;
  const userType = useSelector(state => state.auth.data.userType);
  const map = useMapEvents({
    dblclick: e => {
      if (userType !== USER_CONTROLLER) {
        setAddDevice(e.latlng);
      }
    },
  });

  useEffect(() => {
    if (signedUrlMap) {
      getMeta(signedUrlMap, setMapSize, setBounds);
    }
  }, [signedUrlMap, currentMap.id]);

  useEffect(() => {
    getSignedUrlMap(currentMap, props, setSignedUrlMap);
  }, [currentMap.id]);

  useEffect(() => {
    map.fitBounds(bounds);
  }, [bounds]);

  const [droppedDeviceMarker, setDroppedDeviceMarker] = useState<undefined | LatLng>();

  useEffect(() => {
    if (props.droppedDevice) {
      const droppedLatLng = map.containerPointToLatLng(
        new Point(props.droppedDevice.point.x, props.droppedDevice.point.y),
      );
      setDroppedDeviceMarker(droppedLatLng);
      props.onSetDroppedDeviceMarker({
        mapId: currentMap.id,
        device: props.droppedDevice.device,
        lat: droppedLatLng.lat,
        lng: droppedLatLng.lng,
      });
    } else {
      setDroppedDeviceMarker(undefined);
    }
  }, [props.droppedDevice]);
  return (
    <>
      <SetDeviceOnMap
        open={!!addDevice}
        close={(shouldRefetch: boolean) => {
          setAddDevice(undefined);
          if (shouldRefetch) {
            props.onRefetch();
          }
        }}
        placeId={props.placeId}
        latLng={addDevice}
        mapId={currentMap.id}
        devices={props.devicesWithoutMap}
      />

      {signedUrlMap && (
        <>
          <ImageOverlay url={signedUrlMap} bounds={bounds} />
          {addDevice && <Marker position={[addDevice.lat, addDevice.lng]}> </Marker>}
          {droppedDeviceMarker && (
            <Marker position={[droppedDeviceMarker.lat, droppedDeviceMarker.lng]}> </Marker>
          )}
          {currentMap?.devices?.map(device => {
            if (selectedDeviceType && device.deviceType?.id !== selectedDeviceType) {
              return null;
            }
            const loraDevice = get(device, 'loraDevices[0]');
            const date =
              get(loraDevice, 'lastUplinkAt') || get(device, 'deviceHistory[0].createdAt');

            const battery = get(loraDevice,'lastUplinkData.Status.batteryLevel') || get(device, 'deviceHistory[0].battery');
            const rssi = get(loraDevice,'lastUplinkData.Gateways[0].Rssi') || get(device, 'deviceHistory[0].rssi');
            const lastSignalInterval = get(device, 'place.radioErrorInterval');
            const showDateAlert = date ? dateLongerThanMinutes(date, lastSignalInterval) : true;
            // const showDateAlert = date && dateLongerThanOneDay(date);
            const isDetect = get(loraDevice, 'detectedValue') || device.detect;
            const getAlertIcon = () => {
              if (isDetect && showDateAlert) {
                return meIcon(device, 'yellowRed');
              }
              if (isDetect && !showDateAlert) {
                return meIcon(device, 'red');
              }
              if (!isDetect && showDateAlert) {
                return meIcon(device, 'yellow');
              }
              return meIcon(device, 'green');
            };
            const alertIcon = getAlertIcon();
            const icon = device.loraDevEui || loraDevice ? alertIcon : meIcon(device, 'normal');
            return (
              <Marker position={[device.mapY, device.mapX]} icon={icon} key={`device-${device.id}`}>
                <Popup>
                  <div
                    className="device-box device-box--no-border"
                    key={device.id}
                    onClick={() => {
                      window.open(`/app/devices/edit/${device.id}`, '_blank').focus();
                      // push(`/app/devices/edit/${device.id}`);
                    }}>
                    <div className="device-box__header">
                      <div className="device-box__id">{device.uniqueId}</div>
                      <div className="device-box__id__icon">
                        {get(device, 'detect') ? (
                          <i className="fa fa-exclamation-triangle device-box__id__icon--alert"></i>
                        ) : (
                          <i className="fa fa-check-circle device-box__id__icon--good"></i>
                        )}
                      </div>
                    </div>
                    <div className="device-box__desc">{device.description}</div>
                    <div className="device-box__place device-box__row">
                      <i className="fas fa-warehouse device-box__icon" />
                      {get(device, 'place.name')}
                    </div>

                    <div className="device-box__device-type device-box__row">
                      <i className="fas fa-tablet-alt device-box__icon"></i>
                      {get(device, 'deviceType.name')}
                    </div>
                    <div className="device-box__battery device-box__row">
                      <i className="fas fa-battery-full  device-box__icon"></i>
                      {battery ? `${batteryLevelPercentage(battery)}%` : '-'}
                    </div>
                    <div className="device-box__battery device-box__row">
                      <i className="fas fa-signal  device-box__icon"></i>
                      {battery ? `${rssiToText(rssi)}` : '-'}
                    </div>
                    <div className="device-box__last-action device-box__row">
                      <i className="fas fa-clock device-box__icon"></i>
                      {date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-'}
                      {showDateAlert && (
                        <i className="fa fa-exclamation-triangle icon-alert-date"></i>
                      )}
                    </div>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </>
      )}
    </>
  );
};
export default PlaceMapDetails;
