import React from 'react';
import styles from './styles.module.scss';
interface Props {
  label: string;
  children: React.ReactNode;
}
const DetailsRow = ({ label, children }: Props) => {
  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{children}</div>
    </div>
  );
};

export default DetailsRow;
