import { Button, Group, Modal, Radio } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { LoraDevice } from 'shared/types/LoraDevice';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

interface Props {
  loraDevice: LoraDevice;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

function determineValue(value, inverted) {
  if (inverted) return 'inverted';
  return value ? 'true' : 'false';
}

const ChangeConfigurationModal = (props: Props) => {
  const { loraDevice, onClose, open, onSuccess } = props;
  const translations = useTranslations();
  const [isChangingConfiguration, setIsChangingConfiguration] = useState(false);

  const form = useForm({
    initialValues: {},
  });
  useEffect(() => {
    if (loraDevice?.configuration) {
      form.setValues({
        pir: loraDevice.configuration.pir ? 'true' : 'false',
        reed1: determineValue(loraDevice.configuration.reed1, loraDevice.configuration.reed1Inv),
        reed2: determineValue(loraDevice.configuration.reed2, loraDevice.configuration.reed2Inv),
        ext1: loraDevice.configuration.ext1 ? 'true' : 'false',
        ext2: loraDevice.configuration.ext2 ? 'true' : 'false',
      });
    }
  }, [loraDevice]);

  const handleChangeConfiguration = async formValues => {
    try {
      setIsChangingConfiguration(true);
      const values = {
        pir: formValues.pir === 'true' ? 1 : 0,
        reed1: formValues.reed1 === 'true' ? 1 : 0,
        reed2: formValues.reed2 === 'true' ? 1 : 0,
        ext1: formValues.ext1 === 'true' ? 1 : 0,
        ext2: formValues.ext2 === 'true' ? 1 : 0,
        reed1Inv: formValues.reed1 === 'inverted' ? 1 : 0,
        reed2Inv: formValues.reed2 === 'inverted' ? 1 : 0,
      };
      await apiService({}).put(`/api/lora/devices/${loraDevice.uuid}/configuration/device`, values);
      notifications.show({
        title: translations.global.configurationChanged,
        message: translations.global.messageConfigurationChanged,
        color: 'blue',
      });

      onSuccess();
    } catch (e) {
    } finally {
      setIsChangingConfiguration(false);
    }
  };

  return (
    <Modal opened={open} onClose={onClose} title={translations.global.changeConfiguration}>
      <form onSubmit={form.onSubmit(handleChangeConfiguration)}>
        {/* <Form form={form} /> */}
        <Radio.Group
          label="PIR"
          required
          {...form.getInputProps('pir')}
          mb={10}
          defaultValue="false">
          <Group>
            <Radio value="false" label={translations.global.inactive} />
            <Radio value="true" label={translations.global.active} />
          </Group>
        </Radio.Group>
        <Radio.Group
          label="Kontraktron 1"
          required
          {...form.getInputProps('reed1')}
          mb={10}
          defaultValue="false">
          <Group>
            <Radio value="false" label={translations.global.inactive} />
            <Radio value="true" label={translations.global.active} />
            <Radio value="inverted" label={translations.global.activeInverted} />
          </Group>
        </Radio.Group>
        <Radio.Group
          label="Kontraktron 2"
          required
          {...form.getInputProps('reed2')}
          mb={10}
          defaultValue="false">
          <Group>
            {' '}
            <Radio value="false" label={translations.global.inactive} />
            <Radio value="true" label={translations.global.active} />
            <Radio value="inverted" label={translations.global.activeInverted} />
          </Group>
        </Radio.Group>
        <Radio.Group
          label={`${translations.global.externalExit} 1`}
          required
          {...form.getInputProps('ext1')}
          mb={10}
          defaultValue="false">
          <Group>
            <Radio value="false" label={translations.global.inactive} />
            <Radio value="true" label={translations.global.active} />
          </Group>
        </Radio.Group>
        <Radio.Group
          label={`${translations.global.externalExit} 2`}
          required
          {...form.getInputProps('ext2')}
          mb={10}
          defaultValue="false">
          <Group>
            <Radio value="false" label={translations.global.inactive} />
            <Radio value="true" label={translations.global.active} />
          </Group>
        </Radio.Group>

        <Group position="right" mt={20}>
          <Button color="primary" loading={isChangingConfiguration} type="submit">
            {translations.global.save}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default ChangeConfigurationModal;
