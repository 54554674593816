import React from 'react';
import { NumberInput, Select, Textarea, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import SelectInput from '@material-ui/core/Select/SelectInput';
import { DateTimePicker } from '@mantine/dates';

interface Props {
  form: any;
  isCreating?: boolean;
  deviceTypeActions?: any[];
}

const EditInspectionActionForm = (props: Props) => {
  const { form, deviceTypeActions, isCreating } = props;
  const translations = useTranslations();

  return (
    <>
      {isCreating && (
        <Select
          label={translations.devices.action}
          name="deviceTypeActionId"
          data={deviceTypeActions}
          required
          mb={20}
          {...form.getInputProps('deviceTypeActionId')}
        />
      )}
      {!isCreating && (
        <TextInput
          label={translations.global.name}
          name="name"
          required
          mb={20}
          disabled
          {...form.getInputProps('name')}
        />
      )}
      {!isCreating && form.values.createdAt && (
        <DateTimePicker
          withSeconds
          label={'Data dodania akcji'}
          name="createdAt"
          required
          mb={20}
          // disabled
          {...form.getInputProps('createdAt')}
        />
      )}
      <NumberInput
        label={translations.global.amount}
        name="amount"
        required
        min={1}
        mb={20}
        {...form.getInputProps('amount')}
      />

      <Textarea
        label={translations.global.description}
        name="note"
        mb={20}
        {...form.getInputProps('note')}
      />
    </>
  );
};

export default EditInspectionActionForm;
