import React from 'react';
import classnames from 'classnames';
import './styles.scss';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { Loader } from '@mantine/core';

export const Table = ({ children, isLoading = false, fixed = undefined }) => {
  const classes = classnames('Table', {
    'Table--is-loading': isLoading,
    'Table--fixed': fixed,
  });
  const loaderClasses = classnames('TableWrapper__loader', {
    'TableWrapper__loader--active': isLoading,
  });
  return (
    <div className="TableWrapper">
      <div className={loaderClasses}>{isLoading && <Loader />}</div>
      <table className={classes}>{children}</table>
    </div>
  );
};
export const Tbody = ({ children }) => <tbody className="Tbody">{children}</tbody>;
export const Tfoot = ({ children }) => <tfoot className="Tfoot">{children}</tfoot>;
export const Thead = ({ children }) => <thead className="Thead">{children}</thead>;
export const Th = ({
  children = null,
  sortable = false,
  onSort = null,
  short = undefined,
  style = {},
}) => {
  const classes = classnames('Th', {
    'Th--short': short,
  });
  return (
    <th className={classes} onClick={onSort} style={style}>
      {children}
      {sortable && (
        <button className="Th__sort">
          <SortIcon />
        </button>
      )}
    </th>
  );
};
export const Tr = ({ children, onClick = null }) => (
  <tr className="Tr" onClick={onClick}>
    {children}
  </tr>
);
export const Td = ({ children, colSpan = 1, style = {}, noBreak = false }) => {
  const classes = classnames('Td', {
    'Td--no-break': noBreak,
  });
  return (
    <td className={classes} colSpan={colSpan} style={style}>
      {children}
    </td>
  );
};
