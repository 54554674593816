import React from 'react';
import get from 'lodash/get';
import { TextInput, Title } from '@mantine/core';

import { TranslationFormProps } from './types';

const TranslationForm = <T extends {}>(props: TranslationFormProps<T>) => {
  const { form, formTitle, translationsKey, fieldKey, fieldsLabel, languageKey } = props;
  const translations = get(form.values, 'translations');

  return (
    <div>
      <Title order={5} mb={10}>
        {formTitle}
      </Title>
      {translations.map((item, index) => (
        <TextInput
          mb={12}
          key={item[languageKey]}
          label={`${fieldsLabel} ${item[languageKey]}`}
          sx={{ flex: 1 }}
          {...form.getInputProps(`${translationsKey.toString()}.${index}.${fieldKey}`)}
        />
      ))}
    </div>
  );
};

export default TranslationForm;
