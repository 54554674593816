import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { COMPANIES_FETCH_LIST } from '../../reducers/list/';

function* fetchCompanies() {
  try {
    const { data } = yield apiService({}).get('/companies');
    yield put({
      type: COMPANIES_FETCH_LIST.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: COMPANIES_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(COMPANIES_FETCH_LIST.REQUEST, fetchCompanies);
  },
];
