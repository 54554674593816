import React, { useState } from 'react';
import Button from 'shared/components/Button';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteCompany } from 'modules/Company/services/companyService';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { toast } from 'react-toastify';
import api from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';

// import './styles.scss';

const DeleteRecommendationModal = ({ id }) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>();
  const history = useHistory();
  const onDelete = async () => {
    try {
      setLoading(true);
      setOpen(false);
      await api({}).delete(`/api/admin/recommendations/categories/${id}`);
      setLoading(false);
      history.push(routes.admin.recommendation.default.path);
      toast.success(translations.recommendations.deleteRecommendationCategorySuccess);
    } catch {
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        paragraph={<div>{translations.recommendations.deleteRecommendationCategoryDesc}</div>}
        isLoading={isLoading}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        title={translations.recommendations.deleteRecommendationCategory}
        confirmLabel={translations.global.delete}
        cancelLabel={translations.global.cancel}
      />
      <Button type="red" buttonType="button" onClick={() => setOpen(true)} isLoading={isLoading}>
        {translations.global.delete}
      </Button>
    </>
  );
};

export default DeleteRecommendationModal;
