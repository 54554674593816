import React, { useState } from 'react';
import FilesCategories from './FilesCategories';
import Files from './Files';
import ListHeading from '../../../../shared/components/ListHeading';
import AppContent from '../../../../shared/components/AppContent';
import { useTranslations } from '../../../../shared/translations/useTranslations';

const FilesWrapper = props => {
  const [categoriesData, setCategoriesData] = useState<any>({
    categories: [],
  });
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
  const translations = useTranslations();
  return (
    <div>
      <ListHeading title={translations.global.files}></ListHeading>
      <AppContent>
        <div className="row">
          <div className="col-md-4">
            <FilesCategories
              {...props}
              categoriesData={categoriesData}
              setCategoriesData={setCategoriesData}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          </div>
          <div className="col-md-8">
            <Files {...props} categoriesData={categoriesData} selectedCategory={selectedCategory} />
          </div>
        </div>
      </AppContent>
    </div>
  );
};
export default FilesWrapper;
