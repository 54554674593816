import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import { useTranslations } from '../../../shared/translations/useTranslations';
import AdminDevicesActions from './Actions';
import AdminDevicesObjects from './Objects';
import AdminDevicesObservations from './Observations';

import styles from './styles.module.scss';
import AdminDevicesTypes from './Types';

function AdminDevicesRoutes() {
  const translations = useTranslations();
  return (
    // <AppContent>
    <div className={styles.root}>
      <div className={styles.nav}>
        <NavLink
          to={routes.admin.devices.types.path}
          className={styles.navItem}
          activeClassName={styles.activeNavItem}>
          {translations.global.deviceTypes}
        </NavLink>
        <NavLink
          to={routes.admin.devices.actions.path}
          className={styles.navItem}
          activeClassName={styles.activeNavItem}>
          {translations.devices.actions}
        </NavLink>
        <NavLink
          to={routes.admin.devices.objects.path}
          className={styles.navItem}
          activeClassName={styles.activeNavItem}>
          {translations.devices.objects}
        </NavLink>
        <NavLink
          to={routes.admin.devices.observations.path}
          className={styles.navItem}
          activeClassName={styles.activeNavItem}>
          {translations.global.observations}
        </NavLink>
      </div>
      <div className={styles.content}>
        <Switch>
          <Route path={[routes.admin.devices.default.path]} exact>
            <Redirect
              to={{
                pathname: routes.admin.devices.actions.path,
              }}
            />
          </Route>
          <Route path={[routes.admin.devices.actions.path]} component={AdminDevicesActions}></Route>
          <Route path={[routes.admin.devices.objects.path]} component={AdminDevicesObjects}></Route>
          <Route path={[routes.admin.devices.types.path]} component={AdminDevicesTypes}></Route>
          <Route
            path={[routes.admin.devices.observations.path]}
            component={AdminDevicesObservations}></Route>
          {/* <Route path={[routes.admin.visualInspection.default.path]}>
        <VisualInspection />
        </Route>
        <Route path={[routes.admin.mixtures.default.path]}>
        <Mixtures />
      </Route> */}
        </Switch>
      </div>
    </div>
    // </AppContent>
  );
}

export default AdminDevicesRoutes;
