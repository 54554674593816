import { DependencyList, useMemo } from 'react';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

export const useActions = <TAction, T extends ActionCreatorsMapObject<TAction>>(
  actions: T,
  deps: DependencyList = [],
) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch, ...deps]);
};
