import React from 'react';
import { Route } from 'react-router';
import { routes } from 'shared/constants/routes';
import List from './containers/List';
import Manage from './containers/Manage';
import './styles.scss';

export default () => [
  <Route
    path={routes.devices.default.path}
    key="devices-default"
    component={List}
    exact
  />,
  <Route
    path={routes.devices.edit.path}
    key="devices-manage"
    component={Manage}
    exact
  />,
  <Route
    path={routes.devices.create.path}
    key="devices-create"
    component={Manage}
    exact
  />
];
