import { Checkbox, NumberInput, Select, Table, Text, Textarea, TextInput, Title } from '@mantine/core';
import React from 'react';

import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  form: any;
  deviceTypeData: {
    deviceTypeObjects: any[];
  };
  readOnly?: boolean;
}

const LimitForm = (props: Props) => {
  const { form, readOnly } = props;
  const translations = useTranslations();
  return (
    <>
      <NumberInput
        label={translations.limits.criticalLimit}
        description={translations.limits.criticalLimitDesc}
        name="description"
        mb={20}
        placeholder={2}
        min={0}
        disabled={readOnly}
        {...form.getInputProps('limit')}
      />
      <Title order={5} mt={20}>
        {translations.global.pestRelated}
      </Title>
      <Table>
        <thead>
          <tr>
            <th>{translations.global.pest}</th>
            <th>Limit</th>
            <th> {translations.global.type}</th>
          </tr>
        </thead>
        <tbody>
          {form.values.objects?.map((dto, index) => {
            return (
              <tr key={dto.id}>
                <td style={{ width: '150px' }}>{dto.name}</td>
                <td style={{ width: '130px' }}>
                  <NumberInput
                    placeholder="0"
                    min={0}
                    mb={20}
                    disabled={readOnly}
                    {...form.getInputProps(`objects.${index}.limit`)}
                  />
                </td>
                <td>
                  <Select
                    disabled={readOnly}
                    data={[
                      {
                        label: translations.objects.normal,
                        value: 'NORMAL',
                      },
                      { label: translations.objects.dangerous, value: 'DANGEROUS' },
                    ]}
                    mb={20}
                    {...form.getInputProps(`objects.${index}.objectCategory`)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Title order={5} mt={20}>
        {translations.global.observations}
      </Title>
      <Table>
        <thead>
          <tr>
            <th>{translations.global.observation}</th>
            <th>{translations.limits.limitInPercent}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {form.values.observations?.map((dto, index) => {
            return (
              <tr key={dto.id}>
                <td style={{ width: '150px' }}>{dto.name}</td>
                <td style={{ width: '130px' }}>
                  <NumberInput
                    disabled={readOnly}
                    placeholder="0"
                    max={100}
                    min={0}
                    mb={20}
                    {...form.getInputProps(`observations.${index}.limit`)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default LimitForm;
