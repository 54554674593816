import { Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core';
import React, { useEffect } from 'react';
import LoraDeviceModal from '../LoraDeviceModal';
import useFetch from '../../../../../shared/hooks/useFetch';
import apiService from '../../../../../shared/services/api';
import LoraDeviceData from '../../../../Lora/shared/LoraDeviceData';
import { LoraDevice, LoraDeviceUplink } from '../../../../../shared/types/LoraDevice';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  placeUuid: string;
  deviceUuid: string;
}
const PlaceDevicesLora = (props: Props) => {
  const { placeUuid, deviceUuid } = props;
  const [assignModalOpened, setAssignModalOpened] = React.useState(false);
  const [unassignModalOpened, setUnassignModalOpened] = React.useState(false);
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{ loraDevice: LoraDevice }>({
    initialValue: { device: undefined },
    fetchAction: () =>
      apiService({}).get(`/api/places/${placeUuid}/devices/${deviceUuid}/lora-device`),
  });
  const {
    //  data, isLoading, isLoaded, error, refresh
    data: uplinks,
    refresh: refreshUplinks,
  } = useFetch<{ uplinks: LoraDeviceUplink[]; batteryUplinks: LoraDeviceUplink[] }>({
    initialValue: { device: undefined },
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(`/api/places/${placeUuid}/devices/${deviceUuid}/lora-device/uplinks`),
  });

  useEffect(() => {
    if (data.loraDevice?.uuid) {
      refreshUplinks();
    }
  }, [data]);
  const [isUnassigning, setUnassigning] = React.useState(false);

  const handleUnassign = async () => {
    try {
      setUnassigning(true);
      await apiService({}).delete(`/api/places/${placeUuid}/devices/${deviceUuid}/lora-device`);
      setUnassignModalOpened(false);
      refresh();
    } catch (e) {
    } finally {
      setUnassigning(false);
    }
  };

  const translations = useTranslations();

  return (
    <div style={{ minHeight: 300 }}>
      <LoadingOverlay visible={isLoading} />
      <Modal
        opened={unassignModalOpened}
        onClose={() => {
          setUnassignModalOpened(false);
        }}
        title={translations.loraDevice.unassignDevice}>
        <Text>{translations.loraDevice.unassignDeviceMessage}</Text>
        <Group mt={20}>
          <Button color="red" onClick={handleUnassign} loading={isUnassigning}>
            {translations.global.yesUnassign}
          </Button>
          <Button
            disabled={isUnassigning}
            variant="outline"
            onClick={() => {
              setUnassignModalOpened(false);
            }}>
            {translations.global.cancel}
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={assignModalOpened}
        onClose={() => {
          setAssignModalOpened(false);
        }}
        title={translations.loraDevice.assignDevice}>
        <LoraDeviceModal
          onClose={() => {
            setAssignModalOpened(false);
          }}
          refreshParent={() => {
            refresh();
          }}
          deviceUuid={deviceUuid}
          placeUuid={placeUuid}
        />
      </Modal>
      {isLoaded && (
        <div>
          <Button
            mb={20}
            ml={'auto'}
            variant="outline"
            onClick={() => {
              setUnassignModalOpened(true);
            }}>
            {translations.loraDevice.unassignDevice}
          </Button>
          <LoraDeviceData
            refresh={refresh}
            uplinks={uplinks.uplinks || []}
            batteryUplinks={uplinks.batteryUplinks || []}
            loraDevice={data.loraDevice}
          />
        </div>
      )}
      {error && (
        <div>
          {translations.loraDevice.noDevicesAssigned}
          <Button
            mt={20}
            onClick={() => {
              setAssignModalOpened(true);
            }}>
            {translations.loraDevice.assignDevice}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PlaceDevicesLora;
