import React, { useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { AppShell, Navbar, TextInput, Textarea, Title, Button, ActionIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import {
  BuildingStore,
  CalendarEvent,
  Components,
  FileAnalytics,
  ListDetails,
  Receipt,
} from 'tabler-icons-react';

import ContentLoader from 'shared/components/ContentLoader';
import FormSection from 'shared/components/FormSection';
import { translationService } from 'shared/translations/translations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Company } from 'shared/types/Company';
import useSave from 'shared/hooks/useSave';
import { routes } from 'shared/constants/routes';
import { selectIsCompanyAdmin, selectIsSuperAdmin } from 'shared/utils/user';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { UserType } from 'shared/types/User';
import AppContent from 'shared/components/AppContent';

import validate from './formValidate';
import CompanyPlaces from './CompanyPlaces';
import DeleteCompany from '../DeleteCompany';
import CompanyLogo from './CompanyLogo';
import ManageSelectCompany from '../ManageSelectCompany';
import CompanySubCompanies from './CompanySubCompanies';
import CompanySubscriptions from './CompanySubscriptions';
import CompanyFiles from './CompanyFiles';

import './styles.scss';
import VisualInspection from '../../../Admin/VisualInspection';
import CompanyZones from './Zones';

const Manage = ({ match }) => {
  const [tab, setTab] = useState('details');
  const user = useCurrentUser();
  const companyId = get(match, 'params.id');

  useEffect(() => {
    if (companyId) {
      setTab('details');
    }
  }, [match, companyId]);

  const {
    data,
    isLoading,
    isLoaded,
    error,
    refresh: fetchCompany,
  } = useFetch<Company>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/companies/${companyId}`),
    fetchActionDeps: [companyId],
  });
  useEffect(() => {
    if (companyId) {
      fetchCompany();
    }
  }, [companyId]);

  const history = useHistory();

  const { save: saveCompany, isLoading: isSavingCompany } = useSave({
    postEndpoint: `/companies`,
    patchEndpoint: `/companies/${data.uuid}`,
    isPatch: data.uuid !== undefined,
    additionalDeps: [data.uuid],
    onSuccessCallback: (data: Company) => {
      if (!companyId) {
        history.push(routes.companies.manage.to(data.uuid));
        return;
      }
      history.push(routes.companies.list.path);
    },
  });

  const isCompanyAdmin = selectIsCompanyAdmin();
  const isAdmin = selectIsSuperAdmin();

  const translations = translationService.getTranslations();

  const form = useForm({
    initialValues: data,
    validate,
  });

  const submit = formValues => {
    saveCompany(formValues);
  };

  const [logo, setLogo] = useState(data.logo);
  const onNewLogoSet = useCallback(
    (url: string) => {
      setLogo(url);
    },
    [setLogo],
  );

  useEffect(() => {
    // Initialize form values when data is available
    if (data && Object.keys(data).length > 0) {
      form.setValues(data);
      setLogo(data.logo);
    }
  }, [data]);

  const buttonClassNames = useCallback(
    (section: string) => {
      return classNames('navigationButton', {
        'navigationButton--active': section === tab,
      });
    },
    [tab],
  );

  return (
    <ContentLoader states={{ isLoading, isLoaded, hasError: error }} isNew={!companyId}>
      <AppShell
        styles={{
          main: {
            paddingLeft: 0,
          },
        }}
        navbar={
          <Navbar width={{ base: 250 }} height="auto" style={{ position: 'relative' }}>
            <Navbar.Section mb={14}>
              <Title order={2} style={{ padding: '16px 16px 0px 16px' }}>
                {translations.global.company}
              </Title>
            </Navbar.Section>
            <Navbar.Section>
              <Button
                className={buttonClassNames('details')}
                variant="outline"
                leftIcon={
                  <ActionIcon color="blue" variant="light" size="lg">
                    <ListDetails strokeWidth={1} />
                  </ActionIcon>
                }
                onClick={() => setTab('details')}
                fullWidth>
                {translations.global.details}
              </Button>
              {companyId && (
                <>
                  {!data.parentCompanyUuid && (
                    <Button
                      className={buttonClassNames('devices')}
                      variant="outline"
                      leftIcon={
                        <ActionIcon color="yellow" variant="light" size="lg">
                          <Receipt strokeWidth={1} />
                        </ActionIcon>
                      }
                      onClick={() => setTab('clients')}
                      fullWidth>
                      {translations.global.clients}
                    </Button>
                  )}
                  <Button
                    className={buttonClassNames('places')}
                    variant="outline"
                    leftIcon={
                      <ActionIcon color="green" variant="light" size="lg">
                        <BuildingStore strokeWidth={1} />
                      </ActionIcon>
                    }
                    onClick={() => setTab('places')}
                    fullWidth>
                    {translations.global.facilities}
                  </Button>
                  {!data.parentCompanyUuid && (
                    <Button
                      className={buttonClassNames('files')}
                      variant="outline"
                      leftIcon={
                        <ActionIcon color="orange" variant="light" size="lg">
                          <FileAnalytics strokeWidth={1} />
                        </ActionIcon>
                      }
                      onClick={() => setTab('files')}
                      fullWidth>
                      {translations.global.files}
                    </Button>
                  )}
                  {!data.parentCompanyUuid && UserType.SUPER_ADMIN === user?.type && (
                    <Button
                      className={buttonClassNames('subscriptions')}
                      variant="outline"
                      leftIcon={
                        <ActionIcon color="indigo" variant="light" size="lg">
                          <CalendarEvent strokeWidth={1} />
                        </ActionIcon>
                      }
                      onClick={() => setTab('subscriptions')}
                      fullWidth>
                      {translations.global.subscriptions}
                    </Button>
                  )}
                  {!data.parentCompanyUuid && UserType.COMPANY_ADMIN === user?.type && (
                    <Button
                      className={buttonClassNames('visualInspection')}
                      variant="outline"
                      leftIcon={
                        <ActionIcon color="indigo" variant="light" size="lg">
                          <CalendarEvent strokeWidth={1} />
                        </ActionIcon>
                      }
                      onClick={() => setTab('visual-inspection')}
                      fullWidth>
                      {translations.global.visualInspection}
                    </Button>
                  )}
                  {!data.parentCompanyUuid && (
                    <Button
                      className={buttonClassNames('zones')}
                      variant="outline"
                      leftIcon={
                        <ActionIcon color="indigo" variant="light" size="lg">
                          <Components strokeWidth={1} />
                        </ActionIcon>
                      }
                      onClick={() => setTab('zones')}
                      fullWidth>
                      {translations.companyZones.zones}
                    </Button>
                  )}
                </>
              )}
            </Navbar.Section>
          </Navbar>
        }>
        {tab === 'details' && (
          <div className="">
            <form onSubmit={form.onSubmit(values => submit(values))}>
              <div className="CampaignHeader">
                <div className="HeaderTextField__input">
                  {companyId ? get(data, 'name') : translations.companies.addClient}
                </div>
                <div className="CampaignHeader__right">
                  {companyId && isAdmin && <DeleteCompany id={companyId} />}
                  <Button type="submit" loading={isSavingCompany}>
                    {translations.global.save}
                  </Button>
                </div>
              </div>

              <div className="ManageSurvey">
                <FormSection isActive>
                  <div className="row">
                    <div className="col-md-5">
                      <TextInput
                        name="name"
                        label={translations.global.name}
                        required
                        mb={20}
                        {...form.getInputProps('name')}
                      />
                      <Textarea
                        name="address"
                        label={translations.global.address}
                        required
                        autosize
                        minRows={1}
                        mb={20}
                        {...form.getInputProps('address')}
                      />
                      <TextInput
                        name="email"
                        label={translations.global.email}
                        required
                        mb={20}
                        {...form.getInputProps('email')}
                      />
                      {!isCompanyAdmin && (
                        <TextInput
                          name="subCompanyLimit"
                          label={translations.companies.clientsLimit}
                          disabled={data.parentCompanyUuid !== undefined}
                          required
                          mb={20}
                          {...form.getInputProps('subCompanyLimit')}
                        />
                      )}
                      {!isCompanyAdmin && <ManageSelectCompany companyId={companyId} form={form} />}
                    </div>
                    {!companyId && translations.companies.logoDesc}
                    {companyId && (
                      <div className="col-md-7">
                        {logo && (
                          <div>
                            <h4>{translations.global.logo}</h4>
                            <img src={logo} style={{ width: '100px' }} alt="Company logo" />
                          </div>
                        )}
                        <h4 style={{ marginTop: 20 }}>
                          {data.logo ? translations.global.changeLogo : translations.global.addLogo}
                        </h4>
                        <CompanyLogo id={companyId} onNewLogoSet={onNewLogoSet} />
                      </div>
                    )}
                  </div>
                </FormSection>
              </div>
            </form>
          </div>
        )}
        {tab === 'clients' && (
          <div className="CWrapper">
            <CompanySubCompanies data={data.subCompanies} />
          </div>
        )}
        {tab === 'places' && (
          <div className="CWrapper">
            <CompanyPlaces data={data.places} />
          </div>
        )}

        {tab === 'files' && (
          <div className="CWrapper">
            <CompanyFiles data={data.places} subCompanies={data.subCompanies} />
          </div>
        )}
        {tab === 'subscriptions' && (
          <div className="CWrapper">
            <CompanySubscriptions />
          </div>
        )}
        {tab === 'zones' && (
          <div className="CWrapper">
            <CompanyZones />
          </div>
        )}
        {tab === 'visual-inspection' && <VisualInspection />}
      </AppShell>
    </ContentLoader>
  );
};

export default Manage;
