import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Loader, Modal, TextInput } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { get } from 'lodash';
import TranslationForm from 'shared/components/TranslationForm';

const getInitialValues = action => {
  return {
    name: action?.name,
    shouldClean: action?.shouldClean || false,
    isInspection: action?.isInspection || false,
    settings: action?.settings || { addWhenDeviceAddedToInspection: false },
    translations: action?.translations
      ? [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ].map((translation, key) => {
          return {
            ...translation,
            name: get(action?.translations, `${translation.lang}.name`),
            description: get(action?.translations, `${translation.lang}.description`),
          };
        })
      : [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ],
  };
};

const EditDeviceTypeActionModal = ({ id, onFinish, close }) => {
  const translations = useTranslations();
  const isOpen = !!id;
  const [isSaving, setSaving] = useState<boolean>();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const action = data?.deviceTypeAction;

  const form = useForm({
    initialValues: getInitialValues(action),
  });

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`/device-type-actions/${id}`);
      setData({ deviceTypeAction: data });
      form.setValues(getInitialValues(data));
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, [id, form]);

  useEffect(() => {
    if (id && isOpen) {
      fetchDetails();
    } else {
      form.reset();
      setLoaded(false);
      setLoading(false);
    }
  }, [id, isOpen]);

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        name: formValues.name,
        translations: {
          pl: { name: '' },
          en: { name: '' },
          de: { name: '' },
          it: { name: '' },
          ru: { name: '' },
        },
        shouldClean: formValues.shouldClean,
        isInspection: formValues.isInspection,
        settings: formValues.settings || { addWhenDeviceAddedToInspection: false },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
      });
      await apiService({}).patch(`/device-type-actions/${id}`, body);
      close();
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.editActionSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => close()}
        title={translations.deviceTypes.editActionTitle}>
        {isLoading && <Loader />}
        {isLoaded && !isLoading && (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              required
              label={translations.global.name}
              placeholder=""
              {...form.getInputProps('name')}
            />
            <TranslationForm
              fieldsLabel={translations.global.name}
              languageKey="lang"
              translationsKey="translations"
              fieldKey="name"
              form={form}
            />
            <Checkbox
              mt="md"
              label={translations.devices.cleanAction}
              {...form.getInputProps('shouldClean', { type: 'checkbox' })}
            />
            <Checkbox
              mt="md"
              label={translations.devices.inspection}
              {...form.getInputProps('isInspection', { type: 'checkbox' })}
            />
            <Checkbox
              mt="md"
              label={'Automatyczna akcja przy dodaniu urządzenia do przeglądu'}
              {...form.getInputProps('settings.addWhenDeviceAddedToInspection', {
                type: 'checkbox',
              })}
            />
            <Group position="right" mt="md">
              <Button type="submit" loading={isLoading}>
                {translations.global.save}
              </Button>
              <Button type="button" variant="outline" onClick={() => close()}>
                {translations.global.cancel}
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default EditDeviceTypeActionModal;
