import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, Loader, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';
import useFetch from '../../../../../shared/hooks/useFetch';
import { deviceErrorMessages } from '../../../../../shared/constants/errors';
import { pick } from 'lodash';

interface Props {
  onClose: () => any;
  refreshParent: () => any;
  placeId: string;
  selectedDevices: any[];
  opened: boolean;
}

const DeleteDevicesModal = (props: Props) => {
  const { onClose, placeId, selectedDevices, refreshParent, opened } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const onSubmit = async () => {
    setSaving(true);
    try {
      const { data } = await api({}).post(`/api/places/${placeId}/devices/bulk-delete`, {
        deviceUuids: selectedDevices?.map(d => d.uuid),
      });

      toast.success(translations.global.deleteSuccessful);
      setSaving(false);
      refreshParent();
      onClose();
    } catch (e) {
      setSaving(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Usuń urządzenia">
      <Group position="right" mt={20}>
        Are you sure you want to remove {selectedDevices.length} devices? All actions, objects and
        observations will be removed as well.
        <Button color="red" loading={isSaving} type="submit" onClick={onSubmit}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={() => onClose()}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteDevicesModal;
