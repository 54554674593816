import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import Create from './Create';
import List from './List';
import Edit from './Edit';
import Places from './Places';
import Details from './Details';
import Statistics from './Statistics';

function RecommendationsRoutes() {
  return (
    <Switch>
      <Route path={[routes.recommendation.default.path]} exact>
        <Places />
      </Route>
      <Route path={[routes.recommendation.statistics.default.path]} exact>
        <Statistics />
      </Route>
      <Route path={[routes.recommendation.place.path]} exact>
        <List />
      </Route>
      <Route path={[routes.recommendation.create.path]} exact>
        <Create />
      </Route>
      <Route path={[routes.recommendation.edit.path]} exact>
        <Edit />
      </Route>
      <Route path={[routes.recommendation.details.path]} exact>
        <Details />
      </Route>
    </Switch>
  );
}

export default RecommendationsRoutes;
