import React from 'react';
import { Route, Switch } from 'react-router';

import { routes } from 'shared/constants/routes';

import Events from './Events';

function ScheduleRoutes() {
  return (
    <>
      <Switch>
        <Route path={routes.schedule.default.path} component={Events} />
      </Switch>
    </>
  );
}

export default ScheduleRoutes;
