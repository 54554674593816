import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { Button, Center, Loader, PasswordInput, Text, Title } from '@mantine/core';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Logo from 'assets/mousee_white.png';

import useSave from 'shared/hooks/useSave';
import { useTranslations } from 'shared/translations/useTranslations';
import { LoginPassword } from 'shared/components/Icons';
import { useActions } from 'shared/hooks/useActions';

import { performLogin } from 'modules/Auth/reducers/login';

import validate from './validate';
import './styles.scss';
import { routes } from 'shared/constants/routes';

const ResetPasswordForm = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const {
    save: verifyToken,
    isLoading: isVerifying,
    data,
    error: verifyError,
  } = useSave<{ email: string }>({
    postEndpoint: 'auth/verify',
    additionalDeps: [token],
  });

  useEffect(() => {
    verifyToken({
      token,
    });
  }, [token]);

  const form = useForm({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      token,
    },
    validate,
  });

  const { login } = useActions({
    login: performLogin,
  });

  const { save, isLoading, error } = useSave<{ email: string }>({
    postEndpoint: `/auth/reset-password`,
    onSuccessCallback: () => {
      const { newPassword } = form.values;
      login({
        email: data.email,
        password: newPassword,
      });
      form.reset();
    },
  });

  const submit = formValues => {
    save(formValues);
  };

  const translations = useTranslations();

  const wrapperClassnames = classNames('ResetPassword__wrapper', {
    'ResetPassword__wrapper--error': verifyError,
  });

  return (
    <div className="ResetPassword">
      <div className={wrapperClassnames}>
        <div className="ResetPassword__header">
          <img src={Logo} className="ResetPassword__header__header__logo" alt="Mousee" />
        </div>
        <Title
          order={2}
          style={{
            color: 'white',
            textAlign: 'center',
            marginBottom: '30px',
          }}>
          {translations.auth.resetPassword}
        </Title>
        {isVerifying && (
          <Center>
            <Loader />
          </Center>
        )}
        {!isVerifying && !verifyError && (
          <form onSubmit={form.onSubmit(submit)}>
            <PasswordInput
              className="ResetPassword__input"
              name="newPassword"
              label={translations.auth.password}
              placeholder={translations.auth.password}
              icon={<LoginPassword className="ResetPassword__input__icon" />}
              required
              classNames={{
                rightSection: 'ResetPassword__rightSection',
                input: 'ResetPassword__mantineInput',
              }}
              {...form.getInputProps('newPassword')}
            />
            <PasswordInput
              className="ResetPassword__input"
              name="confirmPassword"
              label={translations.auth.confirmPassword}
              placeholder={translations.auth.confirmPassword}
              icon={<LoginPassword className="ResetPassword__input__icon" />}
              required
              classNames={{
                rightSection: 'ResetPassword__rightSection',
                input: 'ResetPassword__mantineInput',
              }}
              {...form.getInputProps('confirmPassword')}
            />

            {error && !isLoading && (
              <span className="ResetPassword__error">{translations.auth.wrongCredentials}</span>
            )}
            <Button type="submit" loading={isLoading} disabled={isLoading} fullWidth>
              {translations.global.save}
            </Button>
          </form>
        )}
        {verifyError && (
          <Center style={{ flexDirection: 'column' }}>
            <Text
              style={{ whiteSpace: 'pre-line', textAlign: 'center', marginBottom: '30px' }}
              color="white"
              size="lg">
              {translations.auth.tokenExpiredErrorMessage}
            </Text>
            <Link to={routes.auth.login.path}>
              <Button>{translations.global.backToLogin}</Button>
            </Link>
          </Center>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
