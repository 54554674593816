import { uniqueId } from 'lodash';

export interface NotificationsCountResponse {
  unreadCount: number;
}

export enum UserNotificationKey {
  UPCOMING_INSPECTION = 'UPCOMING_INSPECTION',
  UPCOMING_INTERVENTION = 'UPCOMING_INTERVENTION',
  UPCOMING_EVENT = 'UPCOMING_EVENT',
  LORA_DEVICE_ALERT = 'LORA_DEVICE_ALERT',
}

export interface UserNotification {
  uuid: string;
  isRead: boolean;
  title: string;
  body: string;
  createdAt: Date;
  notificationKey: UserNotificationKey;
  placeUuid: string;
  companyUuid: string;
  device?: {
    uuid: string;
    id: number;
    uniqueId: string;
  };
  place?: {
    name: string;
  };
  scheduleEvent?: {
    uuid: string;
    name: string;
    description: string;
  };
  metadata: {
    placeUuid: string;
    eventDate?: string;
    companyUuid: string;

    // LORA_DEVICE_ALERT
    deviceUuid: string;
    deviceId: number;
  };
}
export interface NotificationsLatestResponse {
  count: number;
  rows: UserNotification[];
}
export interface NotificationsResponse {
  count: number;
  rows: UserNotification[];
}
