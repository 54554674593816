import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, getFormValues, reduxForm, initialize } from 'redux-form';
import { Button } from '@mantine/core';
import { toast } from 'react-toastify';

import api from 'shared/services/api';
import CheckboxField from 'shared/components/CheckboxField';
import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import ListHeading from 'shared/components/ListHeading';

const FORM_NAME = 'profile/NOTIFICATIONS';
interface State {
  notifications: {
    notificationEmail: boolean;
    notificationMobile: boolean;
  };
}

async function fetchData(setData) {
  try {
    const { data } = await api({}).get(`account/settings/notifications`);
    setData(data);
  } catch (e) {}
}

const NotificationSettings = () => {
  const translations = useTranslations();
  const dispatch = useDispatch();
  const [isSaving, setSaving] = useState(false);
  const [data, setData] = useState<State>({
    notifications: {
      notificationEmail: false,
      notificationMobile: false,
    },
  });

  useEffect(() => {
    fetchData(data => {
      dispatch(initialize(FORM_NAME, data.notifications));
      setData(data);
    });
  }, []);

  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};
  const saveFile = async () => {
    if (!values) {
      return;
    }
    try {
      setSaving(true);
      await api({}).patch(`account/settings/notifications`, values);
      await fetchData(data => {
        dispatch(initialize(FORM_NAME, data.notifications));
        setData(data);
      });
      toast.success(translations.account.notificationsSuccess);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <ListHeading title={translations.account.notifications} />
      <AppContent>
        <div className="row">
          <div className="col-md-12">
            <Field
              component={CheckboxField}
              isLight
              id="notificationEmail"
              name="notificationEmail">
              {translations.account.email}
            </Field>
            <Field
              component={CheckboxField}
              isLight
              id="notificationMobile"
              name="notificationMobile"
              label="Mobile">
              {translations.account.mobile}
            </Field>
          </div>

          <div className="col-md-3">
            <Button onClick={saveFile} loading={isSaving}>
              {translations.global.save}
            </Button>
          </div>
        </div>
      </AppContent>
    </div>
  );
};
export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  initialValues: {
    notificationEmail: false,
    notificationMobile: false,
  },
})(NotificationSettings);
