import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Modal } from '@mantine/core';
import apiService from 'shared/services/api';
import { Plus } from 'tabler-icons-react';
import DeviceTypeObservationForm from '../DeviceTypeObservationForm';
import {
  mapDeviceTypeObservationFormValuesToApiBody,
  useDeviceTypeObservationForm,
} from '../DeviceTypeObservationForm/device.type.observation.form.mapper';

const AddDeviceTypeObservation = ({
  onFinish,
  deviceTypeId = undefined,
  currentObservations = [],
}) => {
  const translations = useTranslations();
  const form = useDeviceTypeObservationForm();
  const [isOpen, setOpen] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>();
  const handleSubmit = async formValues => {
    try {
      setLoading(true);
      const body = mapDeviceTypeObservationFormValuesToApiBody({ formValues, deviceTypeId });
      await apiService({}).post(`/api/v3/device-type-observations`, body);
      setOpen(false);
      setLoading(false);
      onFinish();
      toast.success(translations.deviceTypes.addObservationSuccess);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => setOpen(false)}
        title={translations.deviceTypes.addObservationTitle}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <DeviceTypeObservationForm
            close={() => setOpen(false)}
            isLoading={isLoading}
            setOpen={setOpen}
            form={form}
            currentObservations={currentObservations}
          />
        </form>
      </Modal>

      <Button ml={30} leftIcon={<Plus size={14} />} size="xs" compact onClick={() => setOpen(true)}>
        {translations.global.add}
      </Button>
    </>
  );
};

export default AddDeviceTypeObservation;
