import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/ru';
import { COMPANY_ADMIN, SUPER_ADMIN, USER, USER_CONTROLLER } from 'shared/constants/user';
import { MixtureType } from 'shared/types/mixtures';
import { DeviceTypeMeasurementMethod, DeviceTypeObservationType } from 'shared/types/deviceType';
import { UserPlaceNotification, UserRole } from '../types/User';
import { RecommendationPriority, RecommendationStatus } from '../types/recommendation';
import { ErrorCode } from '../constants/errors';
import { get } from 'lodash';
import { UserNotificationKey } from '../types/UserNotification';
import { PlaceInspectionStatus } from '../types/PlaceInspection';

export enum Language {
  PL = 'PL',
  EN = 'EN',
}

export const POSSIBLE_APP_LANGUAGES = [
  { value: Language.PL, label: 'Polski' },
  { value: Language.EN, label: 'English' },
];

export const LanguageToMoment = {
  [Language.PL]: 'pl',
  [Language.EN]: 'en-gb',
};

export interface Translation {
  errors?: {
    [ErrorCode.USER_EMAIL_TAKEN]: string;
    [ErrorCode.USER_LIMIT_REACHED]: string;
  };
  enterprise: {
    title: string;
    identifierOfTheCompany: string;
    areYouClient: string;
    clickHere: string;
  };
  qrCodes: {
    title: string;
    batchAddedSuccess: string;
    batchUpdatedSuccess: string;
    batchDeletedSuccess: string;
    batchDeleteDescription: string;
  };
  visualInspection: {
    addQuestion: string;
    editQuestion: string;
    deleteQuestion: string;
    questionDeletedSuccess: string;
    questionDeleteDescription: string;
    question: string;
    comment: string;
    defaultAnswer: string;
    questionAddedSuccess: string;
    questionUpdatedSuccess: string;
  };
  zones: {
    title: string;
    zone: string;
    zones: string;
    zoneAddedSuccess: string;
    zoneUpdatedSuccess: string;
    zoneDeletedSuccess: string;
    zoneDeleteDescription: string;
  };
  limits: {
    title: string;
    limit: string;
    limits: string;
    limitAddedSuccess: string;
    limitUpdatedSuccess: string;
    limitDeletedSuccess: string;
    limitDeleteDescription: string;
    informationDetails: string;
    limitInPercent: string;
    criticalLimit: string;
    criticalLimitDesc: string;
  };
  mixtures: {
    title: string;
    addMixture: string;
    editMixture: string;
    deleteMixture: string;
    mixtureDeletedSuccess: string;
    mixtureDeleteDescription: string;
    mixtureAddedSuccess: string;
    mixtureUpdatedSuccess: string;
    mixture: string;
    name: string;
    description: string;
    grammage: string;
    grammageDesc: string;
    type: string;
    types: {
      [MixtureType.BAIT]: string;
    };
  };
  auth: {
    userName: string;
    password: string;
    newPassword: string;
    currentPassword: string;
    confirmPassword: string;
    confirmNewPassword: string;
    signIn: string;
    wrongCredentials: string;
    resetPassword: string;
    forgotPassword: string;
    changePassword: string;
    passwordChanged: string;
    passwordNoMatch: string;
    forgotPasswordInstruction: string;
    tokenExpiredErrorMessage: string;
    passwordRule: string;
    emailResetSent: string;
  };
  statistics: {
    searchAgain: string;
  };
  notifications: {
    noNotifications: string;
    setAllAsRead: string;
    notifications: string;
    notification: string;
    userNotificationsByKey: {
      [UserNotificationKey.UPCOMING_EVENT]: string;
      [UserNotificationKey.UPCOMING_INSPECTION]: string;
      [UserNotificationKey.UPCOMING_INTERVENTION]: string;
      [UserNotificationKey.LORA_DEVICE_ALERT]: string;
    };
  };

  global: {
    noData: string;
    showAll: string;
    interval: string;
    selectAll: string;
    unselectAll: string;
    errorOccured: string;
    information: string;
    confirm: string;
    search: string;
    changeSuccessful: string;
    deleteSuccessful: string;
    amount: string;
    translations: string;
    mainInformations: string;
    reports: string;
    company: string;
    companies: string;
    facilities: string;
    facility: string;
    device: string;
    devices: string;
    deviceType: string;
    deviceTypes: string;
    recommendations: string;
    visualInspection: string;
    users: string;
    signOut: string;
    statistics: string;
    myCompany: string;
    add: string;
    edit: string;
    delete: string;
    save: string;
    list: string;
    createdAt: string;
    status: string;
    download: string;

    sortBy: string;
    dateRange: string;
    selectDateRange: string;
    cancel: string;
    somethingWentWrong: string;

    attachements: string;
    addComment: string;
    addAttachement: string;
    file: string;
    fileName: string;
    attachementHasBeenAdded: string;
    commentHasBeenAdded: string;
    all: string;
    description: string;
    user: string;

    clients: string;
    client: string;
    technicians: string;
    inspectors: string;

    dateFrom: string;
    dateTo: string;

    selectCompany: string;
    selectClient: string;
    selectFacility: string;
    selectDeviceType: string;
    selectDevice: string;
    selectDate: string;

    pestRelated: string;
    pest: string;
    observations: string;
    observation: string;
    author: string;
    note: string;
    actions: string;
    generatedDate: string;
    generate: string;
    generatePdf: string;
    date: string;
    facilityCount: string;
    devicesCount: string;
    clientsCount: string;
    name: string;
    email: string;
    address: string;
    logo: string;
    details: string;
    addLogo: string;
    changeLogo: string;
    saveLogo: string;
    selectFile: string;
    changeFile: string;
    files: string;
    facilityPlan: string;
    inspections: string;
    firstAndLastName: string;
    type: string;
    category: string;
    categories: string;
    addCategory: string;
    editCategory: string;
    categoryName: string;
    report: string;
    startedAt: string;
    finishedAt: string;
    firstName: string;
    lastName: string;
    color: string;
    yes: string;
    no: string;

    active: string;
    inactive: string;
    activeInverted: string;
    account: string;
    backToLogin: string;
    subscriptions: string;

    dataSaved: string;

    day: string;
    week: string;
    month: string;
    year: string;
    every: string;

    withoutWeekends: string;
    securingCompany: string;
    showData: string;
    navigate: string;
    shortcut: string;
    lastInspections: string;
    activity: string;
    viewResults: string;
    seeMore: string;
    filters: string;
    validUntil: string;
    uploadFiles: string;
    filesToUpload: string;
    deleteFile: string;
    devicesLimit: string;
    usersLimit: string;
    activeFem: string;
    inactiveFem: string;
    onlyOneActiveSubscription: string;
    activeSubscription: string;
    expired: string;
    uniqIdentifier: string;
    without: string;
    selected: string;
    onlyAdminCanEdit: string;
    refresh: string;
    clearAlert: string;
    changeDetectionsConfiguration: string;
    changeTimeConfiguration: string;
    detectStatus: string;
    detectionAlgorithm: string;
    detectionNumber: string;
    detectionCounter: string;
    timeFromDetection: string;
    temperatureLevel: string;
    externalExit: string;
    configurationChanged: string;
    messageConfigurationChanged: string;
    changeConfiguration: string;
    timeInSeconds: string;
    numberOfTimes: string;
    utilized: string;
    timeZone: string;
    lastInformation: string;
    language: string;
    yesUnassign: string;
    deleteComment: string;
    deleteCommentConfirmation: string;
    deleteAttachment: string;
    deleteAttachmentConfirmation: string;
    attachmentDeteleted: string;
    inspectionDate: string;
    count: string;
  };
  routes: {
    dashboard: string;
    statistics: string;
    admin: string;
    adminRecommendation: string;
  };
  account: {
    notifications: string;
    email: string;
    mobile: string;
    notificationsSuccess: string;
  };
  users: {
    deleteUser: string;
    deleteUserDesc1: string;
    deleteUserDesc2: string;
    userHasBeenDeleted: string;
    searchUsers: string;
    types: {
      [USER]: string;
      [COMPANY_ADMIN]: string;
      [SUPER_ADMIN]: string;
      [USER_CONTROLLER]: string;
    };
  };
  user: {
    manage: {
      createdSuccess: string;
      updatedSuccess: string;
      addUser: string;
    };
    roles: {
      title: string;
      rolesUpdatedSuccessfully: string;
      [UserRole.APPROVE_INSPECTION]: string;
      [UserRole.CREATE_INSPECTION]: string;
    };
    placeNotifications?: {
      title: string;
      description: string;
      notifications: {
        [key in UserPlaceNotification]: {
          name: string;
          description: string;
        };
      };
    };
  };
  map: {
    deviceLegends: string;
    mouseeLegend: string;
    withoutCoordinations: string;
    devicesOnMap: string;
    addMap: string;
    facilityPlan: string;
    mapUploaded: string;
    addedToMap: string;
    addOnMap: string;

    removedFromMap: string;
    removeFromPlan: string;
    removeFromPlanDesc: string;
  };
  inspections: {
    confirmUnapproval: string;
    confirmUnapprovalDesc: string;
    confirmApproval: string;
    confirmApprovalDesc: string;
    approveInspection: string;
    unapproveInspection: string;
    approved: string;
    checkedDevices: string;
    startedAt: string;
    finishedAt: string;
    status: {
      [PlaceInspectionStatus.COMPLETED]: string;
      [PlaceInspectionStatus.CANCELLED]: string;
      [PlaceInspectionStatus.IN_PROGRESS]: string;
    };
  };
  files: {
    addFile: string;
    fileAdded: string;
    addFileCreateCategory: string;
    dropZoneFirstLine: string;
    dropZoneSecondLine: string;
    dropZoneThirdLine: string;
    deleteConfirmation: string;
    currentFiles: string;
  };

  objects: {
    normal: string;
    dangerous: string;
  };
  places: {
    maxTimeForDeviceInMin: string;
    deleteFacility: string;
    deleteFacilitySuccess: string;
    deleteFacilityDesc1: string;
    deleteFacilityDesc2: string;
    searchPlace: string;
  };
  companies: {
    clients: string;
    myCompany: string;
    addCompany: string;
    clientsLimit: string;
    logoDesc: string;
    parentCompany: string;
    addClient: string;
    deleteCompany: string;
    deleteCompanyDesc1: string;
    deleteCompanyDesc2: string;
    companyWasDeleted: string;
  };
  reports: {
    noReports: string;
    generateReport: string;
    reports: string;
    generalInspection: string;
    mouseeDetections: string;
    noData: string;
    reportWasGenerated: string;
    xlsxModal: {
      title: string;
      select: string;
      currentMonth: string;
      currentYear: string;
      noDataInCurrentRange: string;
    };
  };
  dashboard: {
    tasksToDo: string;
    activeAlerts: string;

    table: string;
    circleDiagram: string;
    trends: string;
    caughtPets: string;
    equipmentMaintenance: string;
    allClients: string;
    total: string;

    triggeredMouseeAlerts: string;
    todaysEvents: string;
    activeRecommendations: string;
    usersInCompany: string;
    clientsAmount: string;
    placeAmount: string;
    dataForPlace: string;
  };
  devices: {
    one: string;
    prefixTooltip: string;
    multiple: string;
    deviceCountToAdd: string;
    editActionTitle: string;
    editActionDesc: string;
    editActionSuccess: string;

    editObservationTitle: string;
    editObservationDesc: string;
    editObservationSuccess: string;

    deleteObservationTitle: string;
    deleteObservationDesc: string;
    deleteObservationSuccess: string;

    editObjectTitle: string;
    editObjectDesc: string;
    editObjectSuccess: string;

    deleteActionTitle: string;
    deleteActionDesc: string;
    deleteActionSuccess: string;

    deleteObjectTitle: string;
    deleteObjectDesc: string;
    create: {
      success: string;
    };
    deleteObjectSuccess: string;

    deleteImageTitle: string;
    deleteImageDesc: string;
    deleteImageSuccess: string;
    images: string;
    image: string;
    inspection: string;
    online: string;
    alert: string;
    noSignal: string;
    signalHigh: string;
    signalMedium: string;
    signalLow: string;

    deleteDevice: string;
    addDevice: string;
    editDevice: string;
    deleteAlert: string;
    location: string;
    uniqueId: string;

    details: string;
    actionsAndObjects: string;
    history: string;
    radioOptions: string;

    radioOptionsPowerTitle: string;
    radioOptionsPowerDesc: string;
    radioOptionsTimeIntervalTitle: string;
    radioOptionsTimeIntervalDesc: string;
    radioOptionsAlgorithmTitle: string;
    radioOptionsAlgorithmDesc: string;
    radioOptionsAlgorithm1SecondsTitle: string;
    radioOptionsAlgorithm1SecondsDesc: string;
    radioOptionsAlgorithm2ActionsTitle: string;
    radioOptionsAlgorithm2ActionsDesc: string;
    radioOptionsAlgorithm2MinTimeTitle: string;
    radioOptionsAlgorithm2MinTimeDesc: string;
    radioOptionsAlgorithm2MaxTimeTitle: string;
    radioOptionsAlgorithm2MaxTimeDesc: string;

    radioOptionsHistory: string;
    radioOptionsHistorySetUpDate: string;
    radioOptionsHistorySetUpDeviceAcceptationDate: string;
    radioOptionsHistoryStatus: string;
    radioOptionsHistorySettingType: string;
    radioOptionsHistoryValue: string;

    action: string;
    actions: string;
    objects: string;
    object: string;
    date: string;
    note: string;
    amount: string;

    batteryLevelInPercent: string;
    rssiLevel: string;

    battery: string;
    status: string;
    historyType: string;
    signalPower: string;
    downloadFullHistory: string;
    cleanAction: string;
    confirmDeleteFirstLine: string;
    confirmDeleteSecondLine: string;
  };
  deviceTypes: {
    addCustomDeviceType: string;
    assignDeviceTypeFromGlobal: string;
    measurementMethod: string;
    deleteActionDesc: string;
    deleteActionSuccess: string;
    deleteActionTitle: string;
    editActionDesc: string;
    editActionSuccess: string;
    editActionTitle: string;
    addActionTitle: string;
    addActionDesc: string;
    addActionSuccess: string;

    measurementMethods: {
      [key: string]: string;
    };
    observationTypes: {
      [key: string]: string;
    };
    deleteObservationDesc: string;
    deleteObservationSuccess: string;
    deleteObservationTitle: string;
    editObservationDesc: string;
    editObservationSuccess: string;
    editObservationTitle: string;
    addObservationTitle: string;
    addObservationDesc: string;
    addObservationSuccess: string;

    deleteObjectDesc: string;
    deleteDeviceTypeDesc: string;
    deleteObjectSuccess: string;
    deleteDeviceTypeSuccess: string;

    deleteObjectTitle: string;
    deleteDeviceTypeTitle: string;

    editObjectDesc: string;
    editDeviceTypeDesc: string;
    editObjectSuccess: string;
    editDeviceTypeSuccess: string;

    editObjectTitle: string;
    editDeviceTypeTitle: string;

    addObjectDesc: string;
    addDeviceTypeDesc: string;
    addObjectSuccess: string;
    addDeviceTypeSuccess: string;

    addObjectTitle: string;
    addDeviceTypeTitle: string;
  };
  recommendations: {
    addPerson: string;
    emailNotification: string;
    emailSendExplanation: string;
    enums: {
      priority: {
        [key: string]: any;
      };
      status: {
        [key: string]: any;
      };
    };
    id: string;
    recommendationRequiredAction: string;
    downloadCsv: string;
    priority: string;
    category: string;
    location: string;
    map: string;
    author: string;
    owner: string;
    dueDate: string;
    recommendation: string;

    sortByDateAsc: string;
    sortByDateDesc: string;
    sortByStatusAsc: string;
    sortByStatusDesc: string;
    sortByPriorityAsc: string;
    sortByPriorityDesc: string;
    sortByDeadlineAsc: string;
    sortByDeadlineDesc: string;

    addRecommendation: string;
    editRecommendation: string;
    comment: string;

    priorityLow: string;
    priorityMedium: string;
    priorityHigh: string;
    statusOpen: string;
    statusInProgress: string;
    statusAwaiting: string;
    statusClosed: string;

    hasBeenAddedSuccessfully: string;
    hasBeenModifiedSuccessfully: string;
    hasBeenRemovedSuccessfully: string;

    deleteRecommendation: string;
    deleteRecommendationQuestion: string;
    changes: string;
    change: string;
    historyRecommendationHasBeenAdded: string;
    historyRecommendationHasBeenAdded2: string;
    historyRecommendationAttachementAdded: string;
    historyRecommendationHasBeenModified: string;
    showChanges: string;
    wrote: string;
    statistics: string;
    statisticsByDate: string;

    recommendationCategories: string;
    editRecommendationCategory: string;
    deleteRecommendationCategory: string;
    addRecommendationCategory: string;
    editRecommendationCategorySuccess: string;
    deleteRecommendationCategorySuccess: string;
    addRecommendationCategorySuccess: string;
    deleteRecommendationCategoryDesc: string;
  };
  validationRules: {
    requiredField: string;
  };
  interventions: {
    interventions: string;
    createIntervention: string;
    editIntervention: string;
  };
  loraDevice: {
    loraDevice: string;
    list: {
      devices: string;
      create: string;
      edit: string;
    };
    name: string;
    description: string;
    awsUuid: string;
    devEui: string;
    appKey: string;
    appEui: string;
    lastUplinkAt: string;
    lastUplinkData: string;
    uplinks: string;
    deleteConfirmation: string;
    unassignDevice: string;
    assignDevice: string;
    unassignDeviceMessage: string;
    noDevicesAssigned: string;
  };

  intervention: {
    title: string;
    purpose: string;
    pestsRemoved: string;
    actionTaken: string;
    usedProducts: string;
    usedProductsName: string;
    usedProductsActiveSubstanceName: string;
    usedProductsAmount: string;
    usedProductsUnit: string;
    area: string;
    recommendations: string;
    cause: string;
    description: string;
    deleteSavedImage: string;
    createdSuccess?: string;
    modifiedSuccess?: string;
    reportGeneratedSuccess?: string;
  };
  units: {
    grams: string;
    milliliters: string;
    pieces: string;
    package: string;
    percentage: string;
    set: string;
    ppm: string;
    cubicMeters: string;
  };
  schedule: {
    title: string;
    visitForm: {
      title: string;
      visitAddedSuccess: string;
      visitUpdatedSuccess: string;
      types: {
        intervention: string;
        inspection: string;
        maintenance: string;
        other: string;
        neutral: string;
      };
      periodical: string;
      cyclicity: string;
      periodicity: {
        daily: string;
        weekly: string;
        biweekly: string;
        monthly: string;
        yearly: string;
        custom: string;
      };
    };
  };
  companyZones: {
    title: string;
    zones: string;
    zone: string;
    zoneAddedSuccess: string;
    zoneUpdatedSuccess: string;
    zoneDeletedSuccess: string;
    zoneDeleteDescription: string;
    parentZone: string;
    addZone: string;
    editZone: string;
    deleteZone: string;
  };
}

export type Translations = Record<Language, Translation>;

export const translations: Translations = {
  [Language.PL]: {
    companyZones: {
      title: 'Strefy',
      zones: 'Strefy',
      zone: 'Strefa',
      zoneAddedSuccess: 'Strefa została dodana',
      zoneUpdatedSuccess: 'Strefa została zaktualizowana',
      zoneDeletedSuccess: 'Strefa została usunięta',
      zoneDeleteDescription: 'Czy napewno chcesz usunac tą strefe?',
      parentZone: 'Strefa nadrzędna',
      addZone: 'Dodaj strefę',
      editZone: 'Edytuj strefę',
      deleteZone: 'Usuń strefę',
    },
    notifications: {
      noNotifications: 'Brak powiadomień',
      setAllAsRead: 'Oznacz wszystkie jako przeczytane',
      notifications: 'Powiadomienia',
      notification: 'Powiadomienie',
      userNotificationsByKey: {
        [UserNotificationKey.UPCOMING_EVENT]: 'Nadchodzące wydarzenie',
        [UserNotificationKey.UPCOMING_INSPECTION]: 'Nadchodzący przegląd',
        [UserNotificationKey.UPCOMING_INTERVENTION]: 'Nadchodząca interwencja',
        [UserNotificationKey.LORA_DEVICE_ALERT]: 'Detekcja w urządzeniu',
      },
    },
    loraDevice: {
      loraDevice: 'Urządzenie Lora',
      list: {
        devices: 'Urządzenia',
        create: 'Utwórz',
        edit: 'Edytuj',
      },
      name: 'Nazwa',
      description: 'Opis',
      awsUuid: 'AWS UUID',
      devEui: 'DevEui',
      appKey: 'Klucz Aplikacji',
      appEui: 'AppEui',
      lastUplinkAt: 'Ostatnie Połączenie',
      lastUplinkData: 'Dane Ostatniego Połączenia',
      uplinks: 'Uplinks',
      deleteConfirmation: 'Czy na pewno chcesz usunąć to urządzenie?',
      unassignDevice: 'Odpisz urządzenie Lora',
      assignDevice: 'Przypisz urządzenie Lora',
      unassignDeviceMessage:
        'Czy napewno chcesz odłączyć to urządzenie LoRa od aktualnego urządzenia?',
      noDevicesAssigned: 'Aktualnie nie jest przypisane zadne urządzenie LoRa.',
    },
    enterprise: {
      title: 'Klient korporacyjny',
      identifierOfTheCompany: 'Identyfikator firmy',
      areYouClient: 'Klient korporacyjny?',
      clickHere: 'Podaj identyfikator firmy',
    },
    user: {
      manage: {
        createdSuccess: 'Użytkownik został stworzony',
        updatedSuccess: 'Użytkownik został zaktualizowany',
        addUser: 'Dodaj użytkownika',
      },
      roles: {
        title: 'Uprawnienia użytkownika',
        rolesUpdatedSuccessfully: 'Role zostały zaktualizowane',
        [UserRole.APPROVE_INSPECTION]: 'Zatwierdzanie inspekcji',
        [UserRole.CREATE_INSPECTION]: 'Tworzenie inspekcji',
      },
      placeNotifications: {
        title: 'Powiadomienia użytkownika',
        description: 'Dotyczy powiadomien e-mail i push',
        notifications: {
          [UserPlaceNotification.RECEIVE_INSPECTION_PDF_REPORT]: {
            name: 'Inspekcja',
            description:
              'Powiadomienie zostanie wysłane przy stworzeniu nowej inspekcji, zatwierdzeniu jej lub ponownym wygenerowaniu pliku PDF.',
          },
          [UserPlaceNotification.RECEIVE_INTERVENTION_PDF_REPORT]: {
            name: 'Interwencja ',
            description:
              'Powiadomienie zostanie wysłane przy stworzeniu nowej interwencji lub jej edycji.',
          },
        },
      },
    },
    zones: {
      title: 'Strefy',
      zones: 'Strefy',
      zone: 'Strefa',
      zoneAddedSuccess: 'Strefa została dodana',
      zoneUpdatedSuccess: 'Strefa została zaktualizowana',
      zoneDeletedSuccess: 'Strefa została usunięta',
      zoneDeleteDescription: 'Czy napewno chcesz usunac tą strefe?',
    },
    limits: {
      title: 'Limity szkodników',
      limits: 'Limity',
      limit: 'Limit',
      limitAddedSuccess: 'Limit został zaktualizowany',
      limitUpdatedSuccess: 'Limit został zaktualizowany',
      limitDeletedSuccess: 'Limit został usunięty',
      limitDeleteDescription: 'Czy napewno chcesz usunać ten limit?',
      informationDetails: 'W przypadku podania limitu o wartosci 0, oznacza, ze nie jest aktywny',
      limitInPercent: 'Limit w %',
      criticalLimit: 'Limit krytyczny',
      criticalLimitDesc:
        'Jeżeli suma wszystkich szkodników niebezpiecznych przekroczy podany limit, zostanie to oznaczone na raporcie.',
    },
    qrCodes: {
      title: 'Kody QR',
      batchAddedSuccess: 'Kody QR zostały dodane',
      batchUpdatedSuccess: 'Kody QR zostałe zaktualizowane',
      batchDeletedSuccess: 'Kody QR zostały usunięte',
      batchDeleteDescription: 'Czy napewno chcesz usunac kody QR?',
    },
    visualInspection: {
      addQuestion: 'Dodaj pytanie',
      editQuestion: 'Edytuj pytanie',
      deleteQuestion: 'Usuń pytanie',
      question: 'Pytanie',
      comment: 'Komentarz',
      defaultAnswer: 'Domyślna odpowiedź',
      questionAddedSuccess: 'Pytanie zostało dodane',
      questionDeletedSuccess: 'Pytanie zostało usunięte',
      questionDeleteDescription: 'Czy napewno chcesz usunąć to pytanie?',
      questionUpdatedSuccess: 'Pytanie zostało zmodyfikowane',
    },
    mixtures: {
      title: 'Baza preparatów',
      addMixture: 'Dodaj preparat',
      editMixture: 'Edytuj preparat',
      deleteMixture: 'Usuń preparat',
      mixture: 'Preparat',
      name: 'Nazwa',
      description: 'Substancja czynna',
      grammage: 'Gramatura',
      grammageDesc: 'g/szt',
      type: 'Typ',
      mixtureAddedSuccess: 'Preparat został dodany',
      mixtureDeletedSuccess: 'Preparat został usunięty',
      mixtureDeleteDescription: 'Czy napewno chcesz usunąć ten preparat?',
      mixtureUpdatedSuccess: 'Preparat został zmodyfikowany',
      types: {
        [MixtureType.BAIT]: 'Przynęta',
      },
    },

    objects: {
      normal: 'Normalny',
      dangerous: 'Niebezpieczny',
    },
    auth: {
      userName: 'Adres email',
      password: 'Hasło',
      newPassword: 'Nowe hasło',
      currentPassword: 'Obecne hasło',
      confirmPassword: 'Potwierdź hasło',
      confirmNewPassword: 'Potwierdź nowe hasło',
      signIn: 'Zaloguj',
      wrongCredentials: 'Błędne dane.',
      forgotPassword: 'Zapomniałeś hasła',
      changePassword: 'Zmień hasło',
      passwordChanged: 'Twoje hasło zostało zmienione',
      passwordNoMatch: 'Hasła nie są identyczne',
      forgotPasswordInstruction:
        'Po przesłaniu adresu e-mail, otrzymasz wiadomość z linkiem do resetowania hasła.',
      tokenExpiredErrorMessage: `Przepraszamy, ale token resetu hasła wygasł. Tokeny resetu hasła są ważne tylko przez ograniczony czas, aby zapewnić bezpieczeństwo Twojego konta. 
      
      Aby zresetować hasło, proszę rozpocząć proces resetowania hasła ponownie. Możesz poprosić o nowy token resetu hasła, klikając na link "Zapomniałeś hasła" na stronie logowania.`,
      passwordRule:
        'Hasło powinno mieć co najmniej 8 znaków, 1 wielką literę, 1 liczbę i 1 znak specjalny.',
      resetPassword: 'Zresetuj hasło',
      emailResetSent: 'E-mail z instrukcjami resetowania hasła został wysłany.',
    },
    statistics: {
      searchAgain: 'Wyszukaj ponownie, aby zobaczyć rezultaty dla tej placówki',
    },
    errors: {
      [ErrorCode.USER_EMAIL_TAKEN]: 'Adres e-mail jest juz zajęty',
      [ErrorCode.USER_LIMIT_REACHED]: 'Przekroczono limit ilości użytkowników w firmie',
    },
    global: {
      noData: 'Brak danych',
      showAll: 'Pokaż wszystkie',
      interval: 'Interwał',
      selectAll: 'Zaznacz wszystkie',
      unselectAll: 'Odznacz wszystkie',
      errorOccured: 'Wystąpił błąd',
      information: 'Informacja',
      confirm: 'Zatwierdzam',
      changeSuccessful: 'Zmiana została dokonana pomyślnie',
      deleteSuccessful: 'Usunięcie zostałe przeprowadzone pomyślnie',
      amount: 'Ilość',
      translations: 'Translacje',
      mainInformations: 'Detale',
      reports: 'Raporty',
      company: 'Firma',
      companies: 'Firmy',
      facilities: 'Placówki',
      facility: 'Placówka',
      devices: 'Urządzenia',
      deviceTypes: 'Typy urządzeń',
      recommendations: 'Zalecenia',
      visualInspection: 'Wizualna inspekcja',
      statistics: 'Statystyki',
      search: 'Szukaj',
      users: 'Użytkownicy',
      signOut: 'Wyloguj',
      myCompany: 'Moja Firma',
      add: 'Dodaj',
      delete: 'Usuń',
      edit: 'Edytuj',
      save: 'Zapisz',
      list: 'Lista',
      device: 'Urządzenie',
      deviceType: 'Typ urządzenia',
      createdAt: 'Stworzone',
      status: 'Status',
      download: 'Pobierz',
      sortBy: 'Sortuj po',
      dateRange: 'Zakres dat',
      selectDateRange: 'Wybierz zakres dat',
      cancel: 'Anuluj',
      somethingWentWrong: 'Coś poszło nie tak.',
      attachements: 'Załączniki',
      addComment: 'Dodaj komentarz',
      addAttachement: 'Dodaj załącznik',
      file: 'Plik',
      fileName: 'Nazwa pliku',

      attachementHasBeenAdded: 'Ząłącznik został dodany',
      commentHasBeenAdded: 'Komentarz został dodany',
      all: 'Wszystkie',
      description: 'Opis',
      user: 'Użytkownik',

      clients: 'Klienci',
      client: 'Klient',
      technicians: 'Technicy',
      inspectors: 'Kontrolerzy',

      dateFrom: 'Data od',
      dateTo: 'Data do',
      selectCompany: 'Wybierz firmę',
      selectClient: 'Wybierz klienta',
      selectFacility: 'Wybierz placówke',
      selectDeviceType: 'Wybierz typ urządzenia',
      selectDevice: 'Wybierz urządzenie',
      selectDate: 'Wybierz date',

      pestRelated: 'Szkodniki',
      pest: 'Szkodnik',
      observations: 'Obserwacje',
      observation: 'Obserwacja',
      author: 'Autor',
      note: 'Notatka',
      actions: 'Akcje',
      generatedDate: 'Wygenerowano dnia',
      generate: 'Wygeneruj',
      generatePdf: 'Wygeneruj plik pdf',
      date: 'Data',

      facilityCount: 'Ilość placówek',
      devicesCount: 'Ilość urządzeń',
      clientsCount: 'Ilość klientów',
      name: 'Nazwa',
      email: 'Email',
      address: 'Adres',
      logo: 'Logo',
      details: 'Detale',
      addLogo: 'Dodaj logo',
      changeLogo: 'Zmien logo',
      saveLogo: 'Zapisz wybrane logo',
      selectFile: 'Wybierz plik',
      changeFile: 'Kliknij tutaj, aby zmienić plik.',
      files: 'Pliki',
      facilityPlan: 'Rzut placówki',
      inspections: 'Przeglądy',
      firstAndLastName: 'Imię i nazwisko',
      type: 'Typ',
      category: 'Kategoria',
      categories: 'Kategorie',
      addCategory: 'Dodaj kategorie',
      editCategory: 'Edytuj kategorie',
      categoryName: 'Nazwa kategorii',
      report: 'Raport',
      startedAt: 'Rozpoczęte',
      finishedAt: 'Zakończone',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      color: 'Kolor',
      yes: 'Tak',
      no: 'Nie',
      active: 'Aktywny',
      inactive: 'Nieaktywny',
      activeInverted: 'Aktywny odwrócony',
      account: 'Konto',
      backToLogin: 'Powrót do logowania',
      subscriptions: 'Subskrypcje',
      dataSaved: 'Dane zostały zapisane',

      day: 'Dzień',
      week: 'Tydzień',
      month: 'Miesiąc',
      year: 'Rok',
      every: 'Co',
      withoutWeekends: 'Bez weekendów',

      securingCompany: 'Firma zabezpieczająca obiekt',
      showData: 'Pokaż dane',
      navigate: 'Przejdź',
      shortcut: 'Skrót',
      lastInspections: 'Ostatnie przeglądy',
      activity: 'Aktywność',
      viewResults: 'Zobacz wyniki',
      seeMore: 'Zobacz więcej',
      filters: 'Filtry',
      validUntil: 'Ważny do',
      uploadFiles: 'Wgraj pliki',
      filesToUpload: 'Pliki do wgrania',
      deleteFile: 'Usuń plik',
      devicesLimit: 'Limit urządzeń',
      usersLimit: 'Limit użytkowników',
      activeFem: 'Aktywna',
      inactiveFem: 'Nieaktywna',
      onlyOneActiveSubscription: 'Tylko jedna subskrypcja może byc aktywna',
      activeSubscription: 'Subskrypcja aktywna',
      expired: 'Wygasła',
      uniqIdentifier: 'Unikalny ID',
      without: 'Bez',
      selected: 'Zaznaczono',
      onlyAdminCanEdit: 'Tylko admin firmy moze edytowac te dane.',
      refresh: 'Odśwież',
      clearAlert: 'Wyczyść alert',
      changeDetectionsConfiguration: 'Zmień konfigurację detekcji',
      changeTimeConfiguration: 'Zmień konfigurację czasu',
      detectStatus: 'Status detekcji',
      detectionAlgorithm: 'Algorytm detekcji',
      detectionNumber: 'Numer detekcji',
      detectionCounter: 'Liczba detekcji',
      timeFromDetection: 'Czas od detekcji',
      temperatureLevel: 'Temperatura',
      externalExit: 'Wyjście zewnętrzne',
      configurationChanged: 'Konfiguracja została zmieniona',
      messageConfigurationChanged:
        'Przy kolejnym połączeniu urządzenia do sieci, konfiguracja zostanie zmieniona.',
      changeConfiguration: 'Zmień konfigurację urządzenia',
      timeInSeconds: 'Czas w sekundach',
      numberOfTimes: 'Ilość razy',
      utilized: 'Wykorzystane',
      timeZone: 'Strefa czasowa',
      lastInformation: 'Ostatnia informacja',
      language: 'Język',
      yesUnassign: 'Tak, odpisz',
      deleteComment: 'Usuń komentarz',
      deleteCommentConfirmation: 'Czy napewno chcesz usunąć ten komentarz?',
      deleteAttachment: 'Usuń załącznik',
      deleteAttachmentConfirmation: 'Czy napewno chcesz usunąć ten załącznik?',
      attachmentDeteleted: 'Załącznik został usunięty',
      inspectionDate: 'Data inspekcji',
      count: 'Ilość',
    },
    routes: {
      dashboard: 'Pulpit',
      statistics: '',
      admin: '',
      adminRecommendation: '',
    },
    account: {
      notifications: 'Powiadomienia',
      email: 'Email',
      mobile: 'Mobile',
      notificationsSuccess: 'Ustawienia zostały zapisane',
    },
    users: {
      deleteUser: 'Usun uzytkownika',
      deleteUserDesc1: ' Czy napewno chcesz usunąć użytkownika o ID:',
      deleteUserDesc2: 'Wszystkie przypisane akcje zostaną usunięte.',
      userHasBeenDeleted: 'Użytkownik został usunięty',
      searchUsers: 'Szukaj użytkownika',
      types: {
        [USER]: 'Technik',
        [COMPANY_ADMIN]: 'Administrator firmy',
        [SUPER_ADMIN]: 'Super Admin (Mousee)',
        [USER_CONTROLLER]: 'Kontroler',
      },
    },
    map: {
      devicesOnMap: 'Urządzenia na rzucie',
      deviceLegends: 'Legenda urządzeń',
      mouseeLegend: 'Legenda Mousee',
      withoutCoordinations: 'Bez współrzędnych',
      addMap: 'Dodaj mapę',
      facilityPlan: 'Rzut placówki',
      addOnMap: 'Umiesc urządzenie na mapie',
      mapUploaded: 'Rzut został dodany',
      addedToMap: 'Urządzenie zostało umieszczone na mapie',
      removedFromMap: 'Urządzenie zostało usunięte z mapy',
      removeFromPlan: 'Usuń urządzenie z mapy',
      removeFromPlanDesc: 'Czy napewno chcesz usunąc to urządzenie z mapy?',
    },
    inspections: {
      approveInspection: 'Zatwierdź inspekcję',
      unapproveInspection: 'Anuluj zatwierdzoną inspekcję',
      approved: 'Zatwierdzony',
      checkedDevices: 'Sprawdzone Urządzenia',
      startedAt: 'Rozpoczęte',
      finishedAt: 'Zakończone',
      confirmApproval: 'Potwierdź zatwierdzenie',
      confirmApprovalDesc: 'Klikając poniższy przycisk potwierdzasz zatwierdzenie inspekcji',
      confirmUnapproval: 'Potwierdź usunięcie',
      confirmUnapprovalDesc:
        'Klikając poniższy przycisk potwierdzasz usunięcie potwierdzenia inspekcji',
      status: {
        [PlaceInspectionStatus.COMPLETED]: 'Zakończony',
        [PlaceInspectionStatus.CANCELLED]: 'Anulowany',
        [PlaceInspectionStatus.IN_PROGRESS]: 'W trakcie',
      },
    },
    files: {
      addFile: 'Dodaj plik',
      fileAdded: 'Plik został wgrany',
      addFileCreateCategory: 'Aby dodać pliki, stwórz najpierw kategorię',
      dropZoneFirstLine: 'Przeciągnij tutaj pliki lub naciśnij aby wybrać.',
      dropZoneSecondLine: 'Wielkość kazdego nie powinna przekroczyć 5 MB.',
      dropZoneThirdLine: 'Formaty akceptowane: JPG, PNG, PDF',
      deleteConfirmation: 'Czy napewno chcesz usunąć plik',
      currentFiles: 'Aktualne pliki',
    },
    places: {
      maxTimeForDeviceInMin: 'MAKSYMALNY CZAS DLA URZĄDZENIA DO WYSŁANIA SYGNAŁU (W MINUTACH)',
      deleteFacility: 'Usuń placówkę',
      deleteFacilitySuccess: 'Placówka została usunięta',
      deleteFacilityDesc1: 'Czy napewno chcesz usunąć placówkę o ID:',
      deleteFacilityDesc2: 'Wszystkie przypisane urządzenia zostaną usunięte.',
      searchPlace: 'Szukaj placówki',
    },
    companies: {
      clients: 'Klienci',
      myCompany: 'Moja firma',
      addCompany: 'Dodaj firme',
      clientsLimit: 'Limit klientów',
      logoDesc: 'Dodanie logotypu możliwe po stworzeniu firmy',
      parentCompany: 'Firma nadrzędna',
      addClient: 'Dodaj klienta',
      deleteCompany: 'Usuń firmę',
      deleteCompanyDesc1: 'Czy napewno chcesz usunąć firmę o ID:',
      deleteCompanyDesc2: 'Wszystkie przypisane placówki razem z urządzeniami zostaną usunięte',
      companyWasDeleted: 'Firma została usunięta',
    },
    reports: {
      xlsxModal: {
        title: 'Wygeneruj XLSX',
        select: 'Zaznacz',
        currentMonth: 'aktualny miesiąc',
        currentYear: 'aktualny rok',
        noDataInCurrentRange: 'Brak danych w podanym zakresie.',
      },
      noReports: 'Brak wygenerowanych raportów',
      generateReport: 'Wygeneruj raport',
      reports: 'Raporty',
      generalInspection: 'Przeglądy',
      mouseeDetections: 'Detekcje mousee',
      noData: 'Brak danych. Kliknij "wygeneruj" aby wyświetlić dane',
      reportWasGenerated: 'Raport został wygenerowany',
    },
    dashboard: {
      tasksToDo: 'Zadania do wykonania',
      activeAlerts: 'Aktywne alerty',

      table: 'Tabela',
      circleDiagram: 'Wykres kołowy',
      trends: 'Trendy',
      caughtPets: 'Schwytane szkodniki',
      equipmentMaintenance: 'Konserwacja sprzętu',
      allClients: 'Wszyscy klienci',
      total: 'Ogólnie',

      triggeredMouseeAlerts: 'Wyzwolonych alertów Mousee',
      todaysEvents: 'Dzisiaj wydarzenia w kalendarzu',
      activeRecommendations: 'Aktywne zalecenia',
      usersInCompany: 'Ilosc uzytkownikow w ',
      clientsAmount: 'Ilosc klientow w',
      placeAmount: 'Ilosc placowek',
      dataForPlace: 'Dane dla poszczegolnej placowki',
    },
    devices: {
      one: 'Jedno',
      prefixTooltip: 'Max 2 znaki z alfabetu, z duzych liter, np AA',
      multiple: 'Wiele',
      deviceCountToAdd: 'Ilość urządzeń',
      editActionTitle: 'Edytuj akcje',
      editActionDesc: ' Czy napewno chcesz edytować tą akcję?',
      editActionSuccess: 'Akcja została zmodyfikowana',
      editObservationTitle: 'Edytuj obserwacje',
      editObservationDesc: ' Czy napewno chcesz edytować tą obserwacje?',
      editObservationSuccess: 'Obserwacja została zmodyfikowana',

      deleteObservationTitle: 'Usuń obserwacje',
      deleteObservationDesc: 'Czy napewno chcesz usunąć tą obserwacje?',
      deleteObservationSuccess: 'Obserwacja została usunięta',

      editObjectTitle: 'Edytuj szkodnika',
      editObjectDesc: 'Czy napewno chcesz edytować tego szkodnika?',
      editObjectSuccess: 'Szkodnik został zmodyfikowany',

      deleteActionTitle: 'Usuń akcje',
      deleteActionDesc: 'Czy napewno chcesz usunąć tą akcję?',
      deleteActionSuccess: 'Akcja została usunięta',

      deleteObjectTitle: 'Usuń szkodnika',
      deleteObjectDesc: 'Czy napewno chcesz usunąć tego szkodnika?',
      deleteObjectSuccess: 'Szkodnik został usunięty',

      deleteImageTitle: 'Usuń zdjęcie',
      deleteImageDesc: 'Czy napewno chcesz usunąć to zdjęcie?',
      deleteImageSuccess: 'Zdjęcie zostało usunięte',
      images: 'Zdjęcia',
      image: 'Zdjęcie',
      inspection: 'Inspekcja',
      online: 'Monitoring',
      alert: 'Alert',
      noSignal: 'Brak sygnału',
      signalHigh: 'Silny',
      signalMedium: 'Dobry',
      signalLow: 'Akceptowalny',

      addDevice: 'Dodaj urządzenie',
      editDevice: 'Edytuj urządzenie',
      deleteDevice: 'Usuń urządzenie',
      deleteAlert: 'Usuń alert',
      location: 'Lokalizacja',

      uniqueId: 'Unikalny ID w placówce (max 4 znaki)',
      details: 'Detale',
      actionsAndObjects: 'Akcje i szkodniki',
      history: 'Historia',
      radioOptions: 'Opcje radiowe',

      radioOptionsPowerTitle: 'Ustaw moc nadawczą',
      radioOptionsPowerDesc: 'MOC (DOMYŚLNIE 14)',
      radioOptionsTimeIntervalTitle: 'Ustaw interwał czasowy',
      radioOptionsTimeIntervalDesc: 'WARTOŚĆ W MINUTACH (DOMYŚLNIE 15)',
      radioOptionsAlgorithmTitle: 'Ustaw aktywny algorytm',
      radioOptionsAlgorithmDesc: 'ALGORYTM (DOMYŚLNIE 1 I 2)',
      radioOptionsAlgorithm1SecondsTitle: 'Długość sygnału aktywującego Algorytm 1 w sekundach',
      radioOptionsAlgorithm1SecondsDesc: 'WARTOŚĆ W SEKUNDACH (DOMYŚLNIE 15)',
      radioOptionsAlgorithm2ActionsTitle: 'Ilość sygnałów potrzebnych do aktywacji Algorytmu 2',
      radioOptionsAlgorithm2ActionsDesc: 'ILOŚĆ SYGNAŁÓW (DOMYŚLNIE 2)',
      radioOptionsAlgorithm2MinTimeTitle:
        'Minimalny wymagany odstęp czasowy pomiędzy pierwszym a ostatnim sygnałem aktywującym Algorytm 2 w sekundach',
      radioOptionsAlgorithm2MinTimeDesc: 'WARTOŚĆ W SEKUNDACH (DOMYŚLNIE 10)',
      radioOptionsAlgorithm2MaxTimeTitle:
        'Maksymalny odstęp czasowy pomiędzy pierwszym a ostatnim sygnałem aktywującym Algorytm 2 w sekundach',
      radioOptionsAlgorithm2MaxTimeDesc: 'WARTOŚĆ W SEKUNDACH (DOMYŚLNIE 20)',

      radioOptionsHistory: 'Historia ustawień',
      radioOptionsHistorySetUpDate: 'DATA USTAWIENIA',
      radioOptionsHistorySetUpDeviceAcceptationDate: 'DATA AKCEPTACJI OD STRONY URZĄDZENIA',
      radioOptionsHistoryStatus: 'Status',
      radioOptionsHistorySettingType: 'TYP USTAWIENIA',
      radioOptionsHistoryValue: 'WARTOŚĆ',

      actions: 'Akcje',
      action: 'Akcja',
      objects: 'Szkodniki',
      object: 'Szkodnik',
      date: 'Data',
      note: 'Notatka',
      amount: 'Ilość',
      batteryLevelInPercent: 'Poziom baterii w %',
      rssiLevel: 'Zasięgi RSSI',

      battery: 'Bateria',
      status: 'Status',
      historyType: 'Typ',
      signalPower: 'Zasięg',
      downloadFullHistory: 'Pobierz pelna historie',
      cleanAction: 'Akcja czyszcząca',
      confirmDeleteFirstLine: 'Czy napewno chcesz usunąć to urządzenie?',
      confirmDeleteSecondLine: 'Cała historia zostanie usunięta razem z akcjami i szkodnikami.',
      create: {
        success: 'Urządzenie zostało dodane',
      },
    },
    deviceTypes: {
      addCustomDeviceType: 'Dodaj własny typ urządzenia',
      assignDeviceTypeFromGlobal: 'Przypisz typ urządzenia z globalnej bazy',

      measurementMethod: 'Metoda pomiaru',
      deleteActionDesc:
        ' Czy napewno chcesz usunąć tą akcję? Wszystkie przypisane akcje w urządzeniach również zostaną usunięte',
      deleteActionSuccess: 'Akcja została usunięta',
      deleteActionTitle: 'Usuń akcje',
      addActionDesc:
        ' Czy napewno chcesz usunąć tą akcję? Wszystkie przypisane akcje w urządzeniach również zostaną usunięte',
      addActionSuccess: 'Akcja została usunięta',
      addActionTitle: 'Dodaj akcje',
      editActionTitle: 'Edytuj akcje',
      editActionDesc: ' Czy napewno chcesz edytować tą akcję?',
      editActionSuccess: 'Akcja została zmodyfikowana',
      measurementMethods: {
        [DeviceTypeMeasurementMethod.AMOUNT]: 'Ilość',
        [DeviceTypeMeasurementMethod.PERCENTAGE]: 'Procent',
        [DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE]: 'Ilość i procent',
      },

      observationTypes: {
        [DeviceTypeObservationType.BAIT_COLLECT]: 'Pobór trutki',
        [DeviceTypeObservationType.REFILL]: 'Uzupełnienie rodotentycydów',
        [DeviceTypeObservationType.NEUTRAL]: 'Neutralna',
      },
      deleteObservationDesc:
        ' Czy napewno chcesz usunąć tą obserwację? Wszystkie przypisane obserwację w urządzeniach również zostaną usunięte',
      deleteObservationSuccess: 'Obserwacja została usunięta',
      deleteObservationTitle: 'Usuń obserwację',
      addObservationDesc: ' Czy napewno chcesz dodać tą obserwację?',
      addObservationSuccess: 'Obserwacja została dodana',
      addObservationTitle: 'Dodaj obserwację',
      editObservationTitle: 'Edytuj obserwację',
      editObservationDesc: ' Czy napewno chcesz edytować tą obserwację?',
      editObservationSuccess: 'Obserwacja została zmodyfikowana',

      deleteObjectDesc:
        'Czy napewno chcesz usunąć tego szkodnika? Wszystkie przypisane szkodniki w urządzeniach również zostaną usunięte',
      deleteDeviceTypeDesc: 'Czy napewno chcesz usunąć ten typ urządzenia?',
      deleteObjectSuccess: 'Szkodnik został usunięty',
      deleteDeviceTypeSuccess: 'Typ urządzenia został usunięty',
      deleteDeviceTypeTitle: 'Usuń typ urządzenia',
      deleteObjectTitle: 'Usuń szkodnika',

      addObjectDesc:
        'Czy napewno chcesz usunąć tego szkodnika? Wszystkie przypisane szkodniki w urządzeniach również zostaną usunięte',
      addDeviceTypeDesc: 'Czy napewno chcesz usunąć ten typ urządzenia?',
      addObjectSuccess: 'Szkodnik został dodany',
      addDeviceTypeSuccess: 'Typ urządzenia został dodany',
      addDeviceTypeTitle: 'Usuń typ urządzenia',
      addObjectTitle: 'Dodaj szkodnika',

      editObjectTitle: 'Edytuj szkodnika',
      editObjectDesc: 'Czy napewno chcesz edytować tego szkodnika?',
      editObjectSuccess: 'Szkodnik został zmodyfikowany',

      editDeviceTypeDesc: 'Czy napewno chcesz edytować ten typ urządzenia?',
      editDeviceTypeSuccess: 'Typ urządzenia został zmodyfikowany',
      editDeviceTypeTitle: 'Edytuj typ urządzenia',
    },
    recommendations: {
      addPerson: 'Dodaj osobę',
      emailNotification: 'Powiadomienie email',
      emailSendExplanation: `
      Email zostanie wysłany do tych użytkowników na: \n
        1) 3 dni przed data wygaśnięcia terminu \n
        2) w dzień wygaśnięcia terminu \n
        3) dzień po wygaśnięciu terminu \n
        4) co 7 dni aż do momentu zakończenia rekomendacji.\n`,
      id: 'Identyfikator',
      recommendationRequiredAction: 'zalecenia do uzupełnienia',
      downloadCsv: 'Pobierz CSV',
      priority: 'Priorytet',
      category: 'Kategoria',
      location: 'Lokalizacja',
      map: 'Rzut mapy',
      author: 'Autor',
      owner: 'Os. odpowiedzialna',
      dueDate: 'Termin',
      recommendation: 'Zalecenie',
      sortByDateAsc: 'Data dodania rosnąco',
      sortByDateDesc: 'Data dodania malejąco',
      sortByStatusAsc: 'Status rosnąco',
      sortByStatusDesc: 'Status malejąco',
      sortByPriorityAsc: 'Priorytet rosnąco',
      sortByPriorityDesc: 'Priorytet malejąco',
      sortByDeadlineAsc: 'Termin rosnąco',
      sortByDeadlineDesc: 'Termin malejąco',
      addRecommendation: 'Dodaj zalecenie',
      editRecommendation: 'Edytuj zalecenie',
      comment: 'Komentarz',

      priorityLow: 'Niski',
      priorityMedium: 'Sredni',
      priorityHigh: 'Wysoki',
      statusOpen: 'Otwarty',
      statusInProgress: 'W trakcie',
      statusAwaiting: 'Oczekujacy',
      statusClosed: 'Zamkniety',

      hasBeenAddedSuccessfully: 'Zalecenie zostało dodane',
      hasBeenModifiedSuccessfully: 'Zalecenie zostało zmodyfikowane',
      hasBeenRemovedSuccessfully: 'Zalecenie zostało usunięte',
      deleteRecommendation: 'Usuń zalecenie',
      deleteRecommendationQuestion: 'Czy napewno chcesz usunąć te zalecenie?',
      changes: 'Wprowadzone zmiany',
      change: 'Zmiana',
      historyRecommendationHasBeenAdded: 'Zalecenie zostało stworzone',
      historyRecommendationHasBeenAdded2: 'przez',
      historyRecommendationAttachementAdded: 'dodał nowy plik',
      historyRecommendationHasBeenModified: 'wprowadził zmiany do zalecenia',
      showChanges: 'Pokaż zmiany',
      wrote: 'Napisał',

      statistics: 'Statystyki zaleceń',
      statisticsByDate: 'Wygenerowane zalecenia po dacie',

      recommendationCategories: 'Kategorie zaleceń ',
      addRecommendationCategory: 'Dodaj kategorie zaleceń',
      editRecommendationCategory: 'Edytuj kategorie zaleceń',
      deleteRecommendationCategory: 'Usuń kategorie zaleceń',
      addRecommendationCategorySuccess: 'Kategoria została dodana',
      editRecommendationCategorySuccess: 'Kategoria została zmodyfikowana',
      deleteRecommendationCategorySuccess: 'Kategoria została usunięta',
      deleteRecommendationCategoryDesc: 'Czy napewno chcesz usunąć tą kategorię?',
      enums: {
        priority: {
          [RecommendationPriority.HIGH]: 'Wysoki',
          [RecommendationPriority.MEDIUM]: 'Średni',
          [RecommendationPriority.LOW]: 'Niski',
        },
        status: {
          [RecommendationStatus.CLOSE]: 'Zamknięte',
          [RecommendationStatus.IN_PROGRESS]: 'W trakcie',
          [RecommendationStatus.OPEN]: 'Otwarte',
          [RecommendationStatus.WAITING]: 'Oczekujące',
        },
      },
    },
    validationRules: {
      requiredField: 'To pole jest wymagane',
    },
    intervention: {
      title: 'Nazwa interwencji',
      purpose: 'Cel wizyty interwencyjnej',
      pestsRemoved: 'Szkodniki tępione',
      actionTaken: 'Podjęte działania',
      usedProducts: 'Zastosowane preparaty',
      usedProductsName: 'Nazwa',
      usedProductsActiveSubstanceName: 'Substancja aktywna',
      usedProductsAmount: 'Ilość',
      usedProductsUnit: 'Dawka/stężenie',
      area: 'Metraż/tonaż',
      recommendations: 'Zalecenia (po zabiegowe)',
      cause: 'Przyczyna interwencji',
      description: 'Opis sytuacji',
      deleteSavedImage: 'Ten obraz został wcześniej zapisany. Jesteś pewien, że chcesz go usunąć?',
      createdSuccess:
        'Interwencja została dodana. Niebawem otrzymasz powiadomienie mail z plikiem PDF.',
      modifiedSuccess:
        'Interwencja została zmodyfikowana. Niebawem otrzymasz powiadomienie mail z plikiem PDF.',
      reportGeneratedSuccess:
        'Raport został wygenerowany. Niebawem otrzymasz powiadomienie mail z plikiem PDF.',
    },
    units: {
      grams: 'Gram',
      milliliters: 'Millilitr',
      pieces: 'Sztuka',
      package: 'Opakowanie',
      percentage: 'Procent',
      set: 'Komplet',
      ppm: 'PPM',
      cubicMeters: 'Metr sześcienny',
    },
    interventions: {
      interventions: 'Interwencje',
      createIntervention: 'Stwórz protokół interwencyjny',
      editIntervention: 'Edytuj protokół interwencyjny',
    },
    schedule: {
      title: 'Harmonogram',
      visitForm: {
        title: 'Zaplanuj wizytę',
        visitAddedSuccess: 'Wizyta została dodana',
        visitUpdatedSuccess: 'Wizyta została zaktualizowana',
        types: {
          intervention: 'Interwencja',
          inspection: 'Inspekcja',
          maintenance: 'Konserwacja',
          other: 'Inne',
          neutral: 'Wydarzenie',
        },
        periodical: 'Cykliczne',
        cyclicity: 'Cykliczność',
        periodicity: {
          daily: 'Codziennie',
          weekly: 'Co tydzień',
          biweekly: 'Co dwa tygodnie',
          monthly: 'Co miesiąc',
          yearly: 'Co rok',
          custom: 'Niestandardowa cykliczność',
        },
      },
    },
  },
  [Language.EN]: {
    companyZones: {
      title: 'Company zones',
      zones: 'Zones',
      zone: 'Zone',
      zoneAddedSuccess: 'Zone has been added',
      zoneUpdatedSuccess: 'Zone has been updated',
      zoneDeletedSuccess: 'Zone has been deleted',
      zoneDeleteDescription: 'Are you sure you want to delete that zone?',
      parentZone: 'Parent zone',
      addZone: 'Add zone',
      editZone: 'Edit zone',
      deleteZone: 'Delete zone',
    },
    notifications: {
      noNotifications: 'No notifications',
      setAllAsRead: 'Set all as read',
      notifications: 'Notifications',
      notification: 'Notification',
      userNotificationsByKey: {
        [UserNotificationKey.UPCOMING_EVENT]: 'Upcoming event',
        [UserNotificationKey.UPCOMING_INSPECTION]: 'Upcoming inspection',
        [UserNotificationKey.UPCOMING_INTERVENTION]: 'Upcoming intervention',
        [UserNotificationKey.LORA_DEVICE_ALERT]: 'Detection in device',
      },
    },
    loraDevice: {
      loraDevice: 'Lora Device',
      list: {
        devices: 'Devices',
        create: 'Create',
        edit: 'Edit',
      },
      name: 'Name',
      description: 'Description',
      awsUuid: 'AWS UUID',
      devEui: 'DevEui',
      appKey: 'App Key',
      appEui: 'AppEui',
      lastUplinkAt: 'Last Uplink At',
      lastUplinkData: 'Last Uplink Data',
      uplinks: 'Uplinks',
      deleteConfirmation: 'Are you sure you want to delete this device?',
      unassignDevice: 'Unassign Lora device',
      assignDevice: 'Assign Lora device',
      unassignDeviceMessage:
        'Are you sure you want to disconnect this LoRa device from the current device?',
      noDevicesAssigned: 'Currently, no LoRa device is assigned.',
    },
    enterprise: {
      title: 'Enterprise customer',
      identifierOfTheCompany: 'Unique identifier of the company',
      areYouClient: 'Enterprise customer?',
      clickHere: 'Click here',
    },
    user: {
      manage: {
        createdSuccess: 'User has been created',
        updatedSuccess: 'User has been updated',
        addUser: 'Create user',
      },
      roles: {
        title: 'User roles',
        rolesUpdatedSuccessfully: 'Roles has been updated successfully',
        [UserRole.APPROVE_INSPECTION]: 'Approve inspection',
        [UserRole.CREATE_INSPECTION]: 'Create inspection',
      },
    },
    zones: {
      title: 'Zones',
      zones: 'Zones',
      zone: 'Zone',
      zoneAddedSuccess: 'Zone has been added',
      zoneUpdatedSuccess: 'Zone has been updated',
      zoneDeletedSuccess: 'Zone has been deleted',
      zoneDeleteDescription: 'Are you sure you want to delete that zone?',
    },
    limits: {
      title: 'Limits',
      limits: 'Limits',
      limit: 'Limit',
      limitAddedSuccess: 'Limit has been added',
      limitUpdatedSuccess: 'Limit has been updated',
      limitDeletedSuccess: 'Limit has been deleted',
      limitDeleteDescription: 'Are you sure you want to delete that limit?',
      informationDetails: 'When limit is 0, it means it is not active ',
      limitInPercent: 'Limit in %',
      criticalLimit: 'Critical limit',
      criticalLimitDesc:
        'If sum of all dangerous pests will be above the limit, it will be pointed out in report.',
    },
    qrCodes: {
      title: 'QR Codes',
      batchAddedSuccess: 'QR codes has been added',
      batchUpdatedSuccess: 'QR codes has been updated',
      batchDeletedSuccess: 'QR codes has been deleted',
      batchDeleteDescription: 'Are you sure you want to delete qr codes?',
    },
    visualInspection: {
      addQuestion: 'Add question',
      editQuestion: 'Edit question',
      deleteQuestion: 'Delete question',
      questionDeletedSuccess: 'Question has been removed',
      questionDeleteDescription: 'Are you sure you want to remove that question?',
      question: 'Question',
      comment: 'Comment',
      defaultAnswer: 'Default answer',
      questionAddedSuccess: 'Question has been added',
      questionUpdatedSuccess: 'Question has been modified',
    },
    mixtures: {
      title: 'Bait types',
      addMixture: 'Add bait type',
      editMixture: 'Edit bait type',
      deleteMixture: 'Delete mixture',
      mixtureDeletedSuccess: 'Bait type has been removed',
      mixtureDeleteDescription: 'Are you sure you want to remove that bait type?',
      name: 'Name',
      mixture: 'Bait type',
      description: 'Active substance',
      grammage: 'Grammage',
      grammageDesc: 'g/unit',
      type: 'Type',
      mixtureAddedSuccess: 'Bait type has been added',
      mixtureUpdatedSuccess: 'Bait type has been modified',
      types: {
        [MixtureType.BAIT]: 'Bait',
      },
    },
    auth: {
      userName: 'Email address',
      password: 'Password',
      newPassword: 'New password',
      currentPassword: 'Current password',
      confirmPassword: 'Confirm password',
      confirmNewPassword: 'Confirm new password',
      signIn: 'Sign in',
      wrongCredentials: 'Wrong credentials.',
      forgotPassword: 'Forgot password',
      changePassword: 'Change password',
      passwordChanged: 'Your password has been changed',
      passwordNoMatch: 'The passwords do not match',
      forgotPasswordInstruction:
        'An email with a password reset link will be sent to you upon submitting your email address.',
      tokenExpiredErrorMessage: `We apologize, but the password reset token has expired. Password reset tokens are only valid for a limited time to ensure the security of your account. To reset your password, please initiate the password reset process again.
        
        You can request a new password reset token by clicking on the "Forgot Password" link on the login page.`,
      passwordRule:
        'The password should have at least 8 characters, 1 uppercase character, 1 number and 1 special character',
      resetPassword: 'Reset password',
      emailResetSent: 'An email with password reset instructions has been sent.',
    },
    statistics: {
      searchAgain: 'Search again, to see results for selected facility',
    },
    errors: {
      [ErrorCode.USER_EMAIL_TAKEN]: 'Provided e-mail address is already taken',
      [ErrorCode.USER_LIMIT_REACHED]: 'User limit reached in the company',
    },
    global: {
      noData: 'No data',
      showAll: 'Show all',
      interval: 'Interval',
      selectAll: 'Select all',
      unselectAll: 'Unselect all',
      errorOccured: 'An error occured',
      information: 'Information',
      confirm: 'Confirm',
      changeSuccessful: 'Change was performed successfully',
      deleteSuccessful: 'Delete was performed successfully',
      amount: 'Amount',
      translations: 'Translations',
      mainInformations: 'Main informations',
      reports: 'Reports',
      company: 'Company',
      companies: 'Companies',
      facilities: 'Facilities',
      facility: 'Facility',
      devices: 'Devices',
      deviceTypes: 'Device types',
      recommendations: 'Recommendations',
      visualInspection: 'Visual inspection',
      statistics: 'Statistics',
      search: 'Search',
      users: 'Users',
      signOut: 'Sign out',
      myCompany: 'My company',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Save',
      list: 'List',
      device: 'Device',
      deviceType: 'Device type',
      createdAt: 'Created at',
      status: 'Status',
      download: 'Download',
      sortBy: 'Sort by',
      dateRange: 'Date range',
      selectDateRange: 'Select date range',
      cancel: 'Cancel',
      somethingWentWrong: 'Something went wrong',
      attachements: 'Attachements',
      file: 'File',
      fileName: 'Filename',

      addComment: 'Add comment',
      addAttachement: 'Add attachement',
      attachementHasBeenAdded: 'Attachement has been added',
      commentHasBeenAdded: 'Comment has been added',
      all: 'All',
      description: 'Description',
      user: 'User',

      clients: 'Clients',
      client: 'Client',
      technicians: 'Technicans',
      inspectors: 'Inspectors',

      dateFrom: 'Date from',
      dateTo: 'Date to',
      selectCompany: 'Select company',
      selectClient: 'Select client',
      selectFacility: 'Select facility',
      selectDeviceType: 'Select device type',
      selectDevice: 'Select device',
      selectDate: 'Select date',

      pestRelated: 'Pest related',
      pest: 'Pest',
      observations: 'Observations',
      observation: 'Observation',
      author: 'Author',
      note: 'Note',
      actions: 'Actions',
      generatedDate: 'Generated date',
      generate: 'Generate',
      generatePdf: 'Generate pdf file',
      date: 'Data',
      facilityCount: 'Facilities count',
      devicesCount: 'Devices count',
      clientsCount: 'Clients count',
      name: 'Name',
      email: 'Email',
      address: 'Address',
      logo: 'Logo',
      details: 'Details',
      addLogo: 'Add logo',
      changeLogo: 'Change logo',
      saveLogo: 'Save the selected logo',
      selectFile: 'Select file',
      changeFile: 'Click here to change the file.',
      files: 'Files',
      facilityPlan: 'Facility plan',
      inspections: 'Inspections',
      firstAndLastName: 'Name',
      type: 'Type',
      category: 'Category',
      categories: 'Categories',
      addCategory: 'Add category',
      editCategory: 'Edit category',
      categoryName: 'Category name',
      report: 'Report',
      startedAt: 'Started at',
      finishedAt: 'Finished at',
      firstName: 'First name',
      lastName: 'Last name',
      color: 'Color',
      yes: 'Yes',
      no: 'No',
      active: 'Active',
      inactive: 'Inactive',
      activeInverted: 'Active Inverted',
      account: 'Account',
      backToLogin: 'Back to login',
      subscriptions: 'Subscriptions',
      dataSaved: 'The data have been saved',

      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      every: 'Every',

      withoutWeekends: 'Without weekends',

      securingCompany: 'Company in charge for securing',
      showData: 'Show data',
      navigate: 'Navigate',
      shortcut: 'Shortcut',
      lastInspections: 'Last inspections',
      activity: 'Activity',
      viewResults: 'View results',
      seeMore: 'See more',
      filters: 'Filters',
      validUntil: 'Valid until',
      uploadFiles: 'Upload files',
      filesToUpload: 'Files to upload',
      deleteFile: 'Delete file',
      devicesLimit: 'Devices limit',
      usersLimit: 'Users limit',
      activeFem: 'Active',
      inactiveFem: 'Inactive',
      onlyOneActiveSubscription: 'Only one subscription can be active',
      activeSubscription: 'Active subscription',
      expired: 'Expired',
      uniqIdentifier: 'Unique ID',
      without: 'Without',
      selected: 'Selected',
      onlyAdminCanEdit: 'Only the company admin can edit these info',
      refresh: 'Refresh',
      clearAlert: 'Clear alert',
      changeDetectionsConfiguration: 'Change detections configuration',
      changeTimeConfiguration: 'Change time configuration',
      detectStatus: 'Detect status',
      detectionAlgorithm: 'Detection algorithm',
      detectionNumber: 'Detection number',
      detectionCounter: 'Detection counter',
      timeFromDetection: 'Time from detection',
      temperatureLevel: 'Temperature level',
      externalExit: 'External exit',
      configurationChanged: 'Configuration has been changed',
      messageConfigurationChanged:
        'The configuration will be changed upon the next device connection to the network',
      changeConfiguration: 'Change configuration',
      timeInSeconds: 'Time in seconds',
      numberOfTimes: 'Number of times',
      utilized: 'Utilized',
      timeZone: 'Time zone',
      lastInformation: 'Last information',
      language: 'Language',
      yesUnassign: 'Yes, unassign',
      deleteComment: 'Delete comment',
      deleteCommentConfirmation: 'Are you sure you want to delete this comment?',
      deleteAttachment: 'Delete attachment',
      deleteAttachmentConfirmation: 'Are you sure you want to delete this attachment?',
      attachmentDeteleted: 'Attachment has been deleted',
      inspectionDate: 'Inspection date',
      count: 'Count',
    },
    routes: {
      dashboard: 'Dashboard',
      statistics: '',
      admin: '',
      adminRecommendation: '',
    },
    account: {
      notifications: 'Notifications',
      email: 'Email',
      mobile: 'Mobile',
      notificationsSuccess: 'Settings has been saved',
    },
    users: {
      deleteUser: 'Delete user',
      deleteUserDesc1: 'Are you sure you want to delete user with ID: ',
      deleteUserDesc2: 'All actions will be deleted',
      userHasBeenDeleted: 'User has been deleted',
      searchUsers: 'Search for user',
      types: {
        [USER]: 'Technician',
        [COMPANY_ADMIN]: 'Company admin',
        [SUPER_ADMIN]: 'Super Admin (Mousee)',
        [USER_CONTROLLER]: 'Inspector',
      },
    },
    map: {
      deviceLegends: 'Map legend',
      mouseeLegend: 'Mousee Legend',
      withoutCoordinations: 'Devices not attached on the plan',
      devicesOnMap: 'Facility plan devices list',
      addMap: 'Add new',
      facilityPlan: 'Facility plan',
      mapUploaded: 'Map has been uploaded',
      addedToMap: 'Device has been added to plan',
      addOnMap: 'Add device to plan',
      removedFromMap: 'Device has been removed from plan',
      removeFromPlan: 'Remove device from plan',
      removeFromPlanDesc: 'Are you sure you want to remove device from facility plan',
    },
    inspections: {
      approveInspection: 'Approve inspection',
      unapproveInspection: 'Unapprove inspection',
      approved: 'Approved',
      checkedDevices: 'Inspected checkpoints count',
      startedAt: 'Regular start end time',
      finishedAt: 'Regular visit end time',
      confirmApproval: 'Confirm approval',
      confirmApprovalDesc: 'By clicking button below you confirm approval of the inspection',
      confirmUnapproval: 'Confirm removal',
      confirmUnapprovalDesc: 'By clicking button below you confirm removal of the inspection',
      status: {
        [PlaceInspectionStatus.COMPLETED]: 'Finished',
        [PlaceInspectionStatus.CANCELLED]: 'Cancelled',
        [PlaceInspectionStatus.IN_PROGRESS]: 'In progress',
      },
    },
    files: {
      addFile: 'Add file',
      fileAdded: 'File has been added',
      addFileCreateCategory: 'To add files, first create a category.',
      dropZoneFirstLine: 'Drag files here or click to select.',
      dropZoneSecondLine: 'The size of each file should not exceed 5 MB.',
      dropZoneThirdLine: 'Accepted formats: JPG, PNG, PDF',
      deleteConfirmation: 'Are you sure you want to delete this file?',
      currentFiles: 'Current files',
    },
    places: {
      maxTimeForDeviceInMin: 'Max time for device to send data (in minutes)',
      deleteFacility: 'Delete facility',
      deleteFacilitySuccess: 'Facility has been removed',
      deleteFacilityDesc1: 'Are you sure you want to delete facility with ID:',
      deleteFacilityDesc2: 'All devices will be deleted.',
      searchPlace: 'Search for facility',
    },
    companies: {
      clients: 'Clients',
      myCompany: 'My company',
      addCompany: 'Add company',
      clientsLimit: 'Clients limit',
      logoDesc: 'To add logo, save company first',
      parentCompany: 'Superior company',
      addClient: 'Add client',
      deleteCompany: 'Delete company',
      deleteCompanyDesc2: 'All facilities and devices will be removed',
      deleteCompanyDesc1: 'Are you sure you want to delete company with ID: ',
      companyWasDeleted: 'Company has been deleted',
    },
    reports: {
      xlsxModal: {
        title: 'Generate XLSX',
        select: 'Select',
        currentMonth: 'current month',
        currentYear: 'current year',
        noDataInCurrentRange: 'No data in the specified range.',
      },
      noReports: 'No reports',
      generateReport: 'Generate report',
      reports: 'Reports',
      generalInspection: 'General inspection',
      mouseeDetections: 'Mousee detections',
      noData: 'No data. Click "generate" to see',
      reportWasGenerated: 'Report has been created',
    },
    dashboard: {
      tasksToDo: 'Tasks to do',
      activeAlerts: 'Active alerts',

      table: 'Table',
      circleDiagram: 'Circle diagram',
      trends: 'Trends',
      caughtPets: 'Caught pets',
      equipmentMaintenance: 'Equipment maintenace',
      allClients: 'All clients',
      total: 'Total',
      triggeredMouseeAlerts: 'Triggered Mousee alerts',
      todaysEvents: 'Todays events',
      activeRecommendations: 'Active recommendations',
      usersInCompany: 'Users in company ',
      clientsAmount: 'Clients amount',
      placeAmount: 'Facility amount',
      dataForPlace: 'Data for selected facility',
    },
    devices: {
      one: 'One',
      prefixTooltip: 'Max 2 latin characters, uppercase, e.g. AA',
      multiple: 'Multiple',
      deviceCountToAdd: 'Amount',
      deleteObservationTitle: 'Delete observation',
      deleteObservationDesc: 'Are you sure you want to remove this observation?',
      deleteObservationSuccess: 'Observation has been removed',
      editObservationTitle: 'Edit observation',
      editObservationDesc: 'Are you sure you want to edit this observation?',
      editObservationSuccess: 'Observation has been modified',

      editActionTitle: 'Edit action',
      editActionDesc: 'Are you sure you want to edit this action?',
      editActionSuccess: 'Action has been modified',

      editObjectTitle: 'Edit pest',
      editObjectDesc: 'Are you sure you want to edit this pest?',
      editObjectSuccess: 'Pest has been modified',

      deleteActionTitle: 'Delete action',
      deleteActionDesc: 'Are you sure you want to remove this action?',
      deleteActionSuccess: 'Action has been removed',

      deleteObjectTitle: 'Delete pest',
      deleteObjectDesc: 'Are you sure you want to remove this pest?',
      create: {
        success: 'Device has been added',
      },
      deleteObjectSuccess: 'Pest has been removed',

      deleteImageTitle: 'Delete image',
      deleteImageDesc: 'Are you sure you want to remove this image?',
      deleteImageSuccess: 'Image has been removed',

      images: 'Images',
      image: 'Image',
      inspection: 'Inspection',
      online: 'Online',
      alert: 'Alert',
      noSignal: 'No signal',

      signalHigh: 'Strong',
      signalMedium: 'Good',
      signalLow: 'Acceptable',

      addDevice: 'Add device',
      editDevice: 'Edit device',
      deleteDevice: 'Delete device',
      deleteAlert: 'Delete alert',

      uniqueId: 'Unique ID in facility (max 4 characters)',
      location: 'Location',

      details: 'Details',
      actionsAndObjects: 'Activity and pest',
      history: 'History',
      radioOptions: 'Radio options',

      radioOptionsPowerTitle: 'Transmitting power',
      radioOptionsPowerDesc: 'Power default 14',
      radioOptionsTimeIntervalTitle: 'Set the interval time',
      radioOptionsTimeIntervalDesc: '',
      radioOptionsAlgorithmTitle: 'Select algorithm ',
      radioOptionsAlgorithmDesc: 'Algorithm default 1 and 2',
      radioOptionsAlgorithm1SecondsTitle: 'Length of signal activating algorithm 1 in seconds ',
      radioOptionsAlgorithm1SecondsDesc: 'Value in second,d efault 15',
      radioOptionsAlgorithm2ActionsTitle: 'Number of signals needed to activate the algorithm 2',
      radioOptionsAlgorithm2ActionsDesc: '',
      radioOptionsAlgorithm2MinTimeTitle:
        'Minimum required interval time between first and last activation signal Algorithm 2',
      radioOptionsAlgorithm2MinTimeDesc: ' in seconds ',
      radioOptionsAlgorithm2MaxTimeTitle:
        'Maximum required interval time between first and last activation signal Algorithm 2',
      radioOptionsAlgorithm2MaxTimeDesc: ' in seconds ',

      radioOptionsHistory: 'Radio history',
      radioOptionsHistorySetUpDate: 'Request settings change date',
      radioOptionsHistorySetUpDeviceAcceptationDate: 'Setting change acceptance date',
      radioOptionsHistoryStatus: 'Status',
      radioOptionsHistorySettingType: 'Setting type',
      radioOptionsHistoryValue: 'Value',

      actions: 'Activity related',
      action: 'Activity',
      objects: 'Pest related',
      object: 'Pest',
      date: 'Date',
      note: 'Note',
      amount: 'Amount',
      batteryLevelInPercent: 'Battery charge level in %',
      rssiLevel: 'Signal power RSSI',
      battery: 'Charge level',
      status: 'Status',
      historyType: 'Type',
      signalPower: 'Radio signal',
      downloadFullHistory: 'Download full history',
      cleanAction: 'Counting reset when change ',
      confirmDeleteFirstLine: 'Are you sure you want to delete this device?',
      confirmDeleteSecondLine: 'All history will be deleted along with actions and pests.',
    },
    deviceTypes: {
      addCustomDeviceType: 'Create custom device type',
      assignDeviceTypeFromGlobal: 'Assign device type from global database',
      measurementMethod: 'Measurement method',
      measurementMethods: {
        [DeviceTypeMeasurementMethod.AMOUNT]: 'Amount',
        [DeviceTypeMeasurementMethod.PERCENTAGE]: 'Percentage',
        [DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE]: 'Amount and percentage',
      },

      addActionTitle: 'Add action',
      addActionDesc: 'Are you sure you want to add this action?',
      addActionSuccess: 'Action has been added',

      deleteActionTitle: 'Delete action',
      deleteActionDesc: 'Are you sure you want to remove this action?',
      deleteActionSuccess: 'Action has been removed',

      editActionTitle: 'Edit action',
      editActionDesc: 'Are you sure you want to edit this action?',
      editActionSuccess: 'Action has been modified',

      addObservationTitle: 'Add observation',
      addObservationDesc: 'Are you sure you want to add this observation?',
      addObservationSuccess: 'Observation has been added',

      observationTypes: {
        [DeviceTypeObservationType.BAIT_COLLECT]: 'Bait collection',
        [DeviceTypeObservationType.REFILL]: 'Uzupełnienie rodotentycydów EN',
        [DeviceTypeObservationType.NEUTRAL]: 'Neutral',
      },
      deleteObservationTitle: 'Delete observation',
      deleteObservationDesc: 'Are you sure you want to remove this observation?',
      deleteObservationSuccess: 'Observation has been removed',

      editObservationTitle: 'Edit observation',
      editObservationDesc: 'Are you sure you want to edit this observation?',
      editObservationSuccess: 'Observation has been modified',

      deleteObjectTitle: 'Delete pest',
      deleteObjectDesc: 'Are you sure you want to remove this pest?',
      deleteObjectSuccess: 'Pest has been removed',

      editObjectTitle: 'Edit pest',
      editObjectDesc: 'Are you sure you want to edit this pest?',
      editObjectSuccess: 'Pest has been modified',

      editDeviceTypeDesc: 'Are you sure you want to edit this device type?',
      editDeviceTypeSuccess: 'Device type has been modified',
      editDeviceTypeTitle: 'Edit device type',
      deleteDeviceTypeDesc: 'Are you sure you want to remove this device type?',
      deleteDeviceTypeSuccess: 'Device type has been removed',
      deleteDeviceTypeTitle: 'Delete device type',

      addObjectDesc: 'Are you sure you want to add this pest?',
      addDeviceTypeDesc: 'Are you sure you want to add this device type?',
      addObjectSuccess: 'Pest has been added',
      addDeviceTypeSuccess: 'Device type has been added',
      addDeviceTypeTitle: 'Add device type',
      addObjectTitle: 'Delete pest',
    },

    objects: {
      normal: 'Normal',
      dangerous: 'Dangerous',
    },
    recommendations: {
      addPerson: 'Add receiver',
      emailNotification: 'Email notification',
      emailSendExplanation: `
      An email will be sent to these users on:\n

      1) 3 days before the expiration date\n
      2) on the day of expiration date\n
      3) the day after the expiration date\n
      4) every 7 days until the recommendation is completed.\n`,
      id: 'Id',
      recommendationRequiredAction: 'recommendation required action',
      downloadCsv: 'Download CSV',
      priority: 'Priority',
      category: 'Category',
      location: 'Location',
      map: 'Floor plan',
      author: 'Author',
      owner: 'Responsible person',
      dueDate: 'Deadline',
      recommendation: 'Recommendation',
      sortByDateAsc: 'Date ascending',
      sortByDateDesc: 'Date descending',
      sortByStatusAsc: 'Status ascending',
      sortByStatusDesc: 'Status descending',
      sortByPriorityAsc: 'Priority ascending',
      sortByPriorityDesc: 'Priority descending',
      sortByDeadlineAsc: 'Deadline ascending',
      sortByDeadlineDesc: 'Deadline descending',
      addRecommendation: 'Add recommendation',
      editRecommendation: 'Edit recommendation',
      comment: 'Comment',

      priorityLow: 'Low',
      priorityMedium: 'Medium',
      priorityHigh: 'High',
      statusOpen: 'Open',
      statusInProgress: 'In progress',
      statusAwaiting: 'Awaiting',
      statusClosed: 'Closed',

      hasBeenAddedSuccessfully: 'Recommendation has been added',
      hasBeenModifiedSuccessfully: 'Recommendation has been modified',
      hasBeenRemovedSuccessfully: 'Recommendation has been removed',

      deleteRecommendation: 'Delete recommendation',
      deleteRecommendationQuestion: 'Are you sure you want to delete recommendation?',
      changes: 'Changes',
      change: 'Change',
      historyRecommendationHasBeenAdded: 'Recommendation has been added',
      historyRecommendationHasBeenAdded2: 'By ',
      historyRecommendationAttachementAdded: 'added new attachement',
      historyRecommendationHasBeenModified: 'modified recommendation',
      showChanges: 'Show changes',
      wrote: 'wrote',

      statistics: 'Recommendation statistic overview',
      statisticsByDate: 'By date',

      recommendationCategories: 'Categories of recommendations ',
      addRecommendationCategory: 'Add recommendation category',
      editRecommendationCategory: 'Edit recommendation category',
      deleteRecommendationCategory: 'Delete recommendation category',
      deleteRecommendationCategorySuccess: 'Recommendation category has been removed',
      addRecommendationCategorySuccess: 'Recommendation category has been added',
      editRecommendationCategorySuccess: 'Recommendation category has been modified',
      deleteRecommendationCategoryDesc: 'Are you sure you want to remove recommendation category?',
      enums: {
        priority: {
          [RecommendationPriority.HIGH]: 'High',
          [RecommendationPriority.MEDIUM]: 'Medium',
          [RecommendationPriority.LOW]: 'Low',
        },
        status: {
          [RecommendationStatus.CLOSE]: 'Close',
          [RecommendationStatus.IN_PROGRESS]: 'In progress',
          [RecommendationStatus.OPEN]: 'Open',
          [RecommendationStatus.WAITING]: 'Waiting',
        },
      },
    },
    validationRules: {
      requiredField: 'This field is required',
    },
    interventions: {
      interventions: 'Interventions',
      createIntervention: 'Create an intervention protocol',
      editIntervention: 'Edit intervention protocol',
    },
    intervention: {
      title: 'Review Title',
      purpose: 'Purpose of Intervention Visit',
      pestsRemoved: 'Pests Removed',
      actionTaken: 'Actions Taken',
      usedProducts: 'Used Products',
      usedProductsName: 'Name',
      usedProductsActiveSubstanceName: 'Active substance',
      usedProductsAmount: 'Quantity',
      usedProductsUnit: 'Dose/Concentration',
      area: 'Area/Weight',
      recommendations: 'Recommendations (Post-Treatment)',
      cause: 'Cause of Intervention',
      description: 'Situation Description',
      deleteSavedImage: 'This image was previously saved. Are you sure you want to delete it?',
      createdSuccess:
        'Intervention has been added. Soon you will receive an email notification with a PDF file.',
      modifiedSuccess:
        'Intervention has been modified. Soon you will receive an email notification with a PDF file.',
      reportGeneratedSuccess:
        'The report has been generated. Soon you will receive an email notification with a PDF file.',
    },
    units: {
      grams: 'Gram',
      milliliters: 'Milliliter',
      pieces: 'Piece',
      package: 'Package',
      percentage: 'Percentage',
      set: 'Set',
      ppm: 'PPM',
      cubicMeters: 'Cubic meter',
    },
    schedule: {
      title: 'Schedule',
      visitForm: {
        title: 'Plan visit',
        visitAddedSuccess: 'Visit has been added',
        visitUpdatedSuccess: 'Visit has been updated',
        types: {
          intervention: 'Intervention',
          inspection: 'Inspection',
          maintenance: 'Maintenance',
          other: 'Other',
          neutral: 'Event',
        },
        periodical: 'Periodical',
        cyclicity: 'Cyclicity',
        periodicity: {
          daily: 'Daily',
          weekly: 'Weekly',
          biweekly: 'Biweekly',
          monthly: 'Monthly',
          yearly: 'Yearly',
          custom: 'Custom periodicity',
        },
      },
    },
  },
};

export const LANGUAGE_STORAGE_KEY = 'language';

class TranslationService {
  defaultLanguage = Language.PL;

  constructor() {
    const lang = this.getCurrentLanguage();
    if (!lang) {
      this.setCurrentLanguage(this.defaultLanguage);
    }
  }

  setCurrentLanguage = (lang: any) => {
    if (!Object.values(Language).includes(lang as Language)) {
      lang = this.defaultLanguage;
    }
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
    moment.locale(LanguageToMoment[lang]);
  };

  getCurrentLanguage = () => {
    return localStorage.getItem(LANGUAGE_STORAGE_KEY);
  };

  getTranslations = () => {
    let lang = this.getCurrentLanguage();
    if (!Object.values(Language).includes(lang as Language)) {
      lang = this.defaultLanguage;
    }

    moment.locale(LanguageToMoment[lang]);
    return translations[lang] as Translation;
  };

  changeLanguage = (lang: Language) => {
    this.setCurrentLanguage(lang.toUpperCase());
    window.location.reload();
  };
  //   (currentLanguage = getCurrentLanguage = () => {});
}

export const translationService = new TranslationService();

export const getTranslationOrDefault = (providedTranslations, path) => {
  return get(providedTranslations, path) || get(translations[Language.EN], path);
};
