import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Alert, Button, TextInput, Title, Text } from '@mantine/core';
import Logo from 'assets/mousee_white.png';

import useSave from 'shared/hooks/useSave';
import { useTranslations } from 'shared/translations/useTranslations';
import { LoginUser } from 'shared/components/Icons';
import { routes } from 'shared/constants/routes';

import './styles.scss';
import { Check } from 'tabler-icons-react';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const [showSuccessNotification, setShowSuccesNotification] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
    },
  });

  const { save, isLoading, error } = useSave({
    postEndpoint: `/auth/forgot-password`,
    onSuccessCallback: () => {
      setShowSuccesNotification(true);
      form.reset();
    },
  });

  const submit = formValues => {
    setShowSuccesNotification(false);
    save(formValues);
  };

  const translations = useTranslations();
  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__wrapper">
        <div className="ForgotPassword__header">
          <img src={Logo} className="ForgotPassword__header__header__logo" alt="Mousee" />
        </div>
        <Title
          order={2}
          style={{
            color: 'white',
            textAlign: 'center',
            marginBottom: '30px',
          }}>
          {translations.auth.forgotPassword}
        </Title>
        <Text size="xs" mb="20px" align="center" color="white">
          {translations.auth.forgotPasswordInstruction}
        </Text>
        <form onSubmit={form.onSubmit(submit)}>
          <TextInput
            className="ForgotPassword__input"
            name="email"
            label={translations.auth.userName}
            placeholder={translations.auth.userName}
            icon={<LoginUser className="ForgotPassword__input__icon" />}
            required
            {...form.getInputProps('email')}
          />

          {error && !isLoading && (
            <span className="ForgotPassword__error">{translations.auth.wrongCredentials}</span>
          )}
          <Button
            style={{ marginBottom: '16px' }}
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            fullWidth>
            {translations.auth.resetPassword}
          </Button>

          <Link to={routes.auth.login.path} style={{ textDecoration: 'none' }}>
            <Button variant="outline" fullWidth>
              {translations.global.backToLogin}
            </Button>
          </Link>

          {showSuccessNotification && (
            <Alert
              className="ForgotPassword__notification"
              variant="filled"
              icon={<Check size={18} />}
              color="teal">
              {translations.auth.emailResetSent}
            </Alert>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
