import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  EDIT_DEVICE,
  FETCH_DEVICE,
  FETCH_PLACES,
  DELETE_DEVICE,
  CLEAN_DETECTION_IN_DEVICE,
} from '../../reducers/manage/';
import { push } from 'connected-react-router';
import { routes } from 'shared/constants/routes';
import { fetchDevices } from 'modules/Device/reducers/list';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { translationService } from 'shared/translations/translations';
import { deviceErrorMessages } from '../../../../shared/constants/errors';

function* fetchPlaces(action) {
  try {
    const { data } = yield apiService({}).get(`/places`);
    yield put({
      type: FETCH_PLACES.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: FETCH_PLACES.FAILURE, payload: getError(err) });
  }
}

function* fetchDevice(action) {
  try {
    const { data } = yield apiService({}).get(`/devices/${action.payload.id}`);
    yield put({
      type: FETCH_DEVICE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: FETCH_DEVICE.FAILURE, payload: getError(err) });
  }
}

function* deleteDevice(action) {
  try {
    const { data } = yield apiService({}).delete(`/devices/${action.payload.id}`);
    yield put(fetchDevices({}));
    yield put(push(routes.devices.list.path));
    yield put({
      type: DELETE_DEVICE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: DELETE_DEVICE.FAILURE, payload: getError(err) });
  }
}

function* editDevice(action) {
  const translations = translationService.getTranslations();
  let data = {};
  try {
    if (action.payload.id) {
      const { data: editData } = yield apiService({}).patch(
        `/devices/${action.payload.id}`,
        action.payload,
      );
      data = editData;
      toast.success(`Urządzenie zostało edytowane`);
    } else {
      const { data: createData } = yield apiService({}).post(`/devices`, action.payload);
      data = createData;
      yield put(push(routes.devices.list.path));
      toast.success(`Urządzenie zostało stworzone`);
    }
    yield put({
      type: EDIT_DEVICE.SUCCESS,
      payload: data,
    });
  } catch (err) {
    const errorMessages = deviceErrorMessages(translations)
    const error = getError(err);
    const hasError = get(errorMessages, get(error, 'response.error'));
    const hasCode = get(errorMessages, get(err, 'response.data.code'));
    if (hasError) {
      toast.error(hasError);
    } else if (hasCode) {
      toast.error(hasCode);
    } else {
      toast.error(`Coś poszło nie tak`);
    }
    yield put({ type: EDIT_DEVICE.FAILURE, payload: getError(err) });
  }
}

function* cleanDetection(action) {
  try {
    const { data } = yield apiService({}).post(
      `/devices/${action.payload.deviceId}/clean-detection`,
    );
    yield put(fetchDevices());
    yield put({ type: CLEAN_DETECTION_IN_DEVICE.SUCCESS, payload: data });
  } catch (err) {
    yield put({
      type: CLEAN_DETECTION_IN_DEVICE.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(FETCH_DEVICE.REQUEST, fetchDevice);
  },
  function* () {
    yield takeEvery(EDIT_DEVICE.REQUEST, editDevice);
  },
  function* () {
    yield takeEvery(FETCH_PLACES.REQUEST, fetchPlaces);
  },
  function* () {
    yield takeEvery(DELETE_DEVICE.REQUEST, deleteDevice);
  },
  function* () {
    yield takeLatest(CLEAN_DETECTION_IN_DEVICE.REQUEST, cleanDetection);
  },
];
