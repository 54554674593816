import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import ZoneForm from '../ZoneForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  zoneId: string | number;
}

interface Data {}

const EditZoneModal = (props: Props) => {
  const { refreshParent, onClose, placeId, zoneId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/places/${placeId}/zones/${zoneId}`),
  });

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
    },
    schema: zodResolver(schema),
  });

  useEffect(() => {
    form.setValues(data);
  }, [data]);

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        description: formValues.description,
      };
      await api({}).patch(`/api/places/${placeId}/zones/${zoneId}`, body);
      toast.success(translations.zones.zoneUpdatedSuccess);
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!zoneId);
    if (zoneId) {
      refresh();
    }
  }, [zoneId]);

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.global.edit}>
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && <ZoneForm form={form} />}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditZoneModal;
