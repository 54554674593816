import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Modal } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import useFetch from '../../../../shared/hooks/useFetch';

const AssignGlobalDeviceTypeToCompany = ({
  onFinish,
  deviceTypeId = undefined,
  parentCompanyId = undefined,
  currentDeviceTypes = [],
  onClose,
  open,
}) => {
  const translations = useTranslations();

  const [isSaving, setSaving] = useState<boolean>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<any[]>({
    initialValue: [],
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/device-types?type=GLOBAL`),
  });

  const form = useForm({
    initialValues: {
      deviceTypeIds: undefined,
    },
  });

  useEffect(() => {
    if (open) {
      form.setValues({
        deviceTypeIds: currentDeviceTypes?.map(dt => String(dt.id)),
      });
      refresh();
    }
  }, [open, currentDeviceTypes]);

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        deviceTypeIds: formValues.deviceTypeIds,
        parentCompanyId,
      };
      await apiService({}).put(`/companies/${parentCompanyId}/device-types/assign`, body);
      onClose();
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.addActionSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <Modal opened={open} onClose={() => onClose()} title={translations.deviceTypes.addActionTitle}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Checkbox.Group
          {...form.getInputProps('deviceTypeIds', { type: 'checkbox' })}
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          label="Wybierz akcje"
          defaultValue={form.values.deviceTypeIds}
          description="Wybierz akcje, która zostanie przypisana do tego typu urządzenia"
          required>
          {data?.map(da => (
            <Checkbox value={String(da.id)} label={da.name} key={da.id} />
          ))}
        </Checkbox.Group>
        <Group position="right" mt="md">
          <Button type="submit" loading={isSaving} disabled={!form.values.deviceTypeIds}>
            {translations.global.add}
          </Button>
          <Button type="button" variant="outline" onClick={() => onClose()}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default AssignGlobalDeviceTypeToCompany;
