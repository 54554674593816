import React, { PureComponent, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import map from 'lodash/map';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchDeviceTypes } from 'modules/DeviceType/reducers/list';
import { translationService } from 'shared/translations/translations';
import { ActionIcon, Badge, Button, Group, Menu } from '@mantine/core';
import { Globe, Pencil, Plus, User } from 'tabler-icons-react';
import AssignGlobalDeviceTypeToCompany from '../AssignGlobalDeviceTypeToCompany';
import { getRedirectOptionLabel } from '../../../../shared/components/devices/types/DeviceTypesList';

const List = props => {
  useEffect(() => {
    props.fetchDeviceTypes();
  }, []);
  const currentUser = useSelector(state => state.auth.data.user.data);
  const goToCreate = () => {
    props.history.push(routes.deviceTypes.create.path);
  };

  const goToSingle = id => () => {
    props.history.push(routes.deviceTypes.manage(id).path);
  };

  const translations = translationService.getTranslations();
  const { data, states } = props;
  const [isOpenAssignModal, setOpenAssignModal] = useState<boolean>();
  return (
    <div>
      <AssignGlobalDeviceTypeToCompany
        currentDeviceTypes={data}
        onFinish={() => {
          props.fetchDeviceTypes();
        }}
        open={isOpenAssignModal}
        parentCompanyId={currentUser.companyId}
        onClose={() => setOpenAssignModal(false)}
      />
      <ListHeading title={translations.global.deviceTypes}>
        <Group>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button>{translations.global.add}</Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<Plus size={14} />} onClick={goToCreate}>
                {translations.deviceTypes.addCustomDeviceType}
              </Menu.Item>
              <Menu.Item icon={<Globe size={14} />} onClick={() => setOpenAssignModal(true)}>
                {translations.deviceTypes.assignDeviceTypeFromGlobal}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </ListHeading>
      <AppContent>
        <div className="row">
          <div className="col-md-12">
            <Table isLoading={states.isLoading}>
              <Thead>
                <Tr>
                  <Th>{translations.global.name}</Th>
                  <Th>{translations.global.description}</Th>
                  <Th>Prefix</Th>
                  <Th>Przekierowanie po zeskanowaniu urządzenia</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {map(data, deviceType => (
                  <Tr key={deviceType.id}>
                    <Td>
                      <Badge color={deviceType.parentCompanyId ? 'green' : 'violet'} mr={10}>
                        {deviceType.parentCompanyId ? <User size={16} /> : <Globe size={16} />}
                      </Badge>
                      {deviceType.name}
                    </Td>
                    <Td>{deviceType.description}</Td>
                    <Td>{deviceType.settings?.prefix || '-'}</Td>
                    <Td>{getRedirectOptionLabel(deviceType.settings?.redirectOption)}</Td>
                    <Td>
                      <Group>
                        <ActionIcon variant="transparent" onClick={goToSingle(deviceType.id)}>
                          <Pencil size={16} />
                        </ActionIcon>
                      </Group>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
      </AppContent>
    </div>
  );
};

const mapStateToProps = ({ deviceTypes }) => ({
  data: deviceTypes.list.data,
  states: deviceTypes.list.states,
});
const mapDispatchToProps = dispatch => bindActionCreators({ fetchDeviceTypes }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(List);
