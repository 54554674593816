import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  DEVICE_TYPES_FETCH_SINGLE,
  DEVICE_TYPES_SAVE,
  ADD_ACTION,
  ADD_OBJECT,
  fetchDeviceType
} from '../../reducers/manage';
import { push } from 'connected-react-router';
import { routes } from 'shared/constants/routes';

function* fetchDeviceTypes(action) {
  try {
    const { data } = yield apiService({}).get(
      `/device-types/${action.payload.id}`
    );
    yield put({
      type: DEVICE_TYPES_FETCH_SINGLE.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({
      type: DEVICE_TYPES_FETCH_SINGLE.FAILURE,
      payload: getError(err)
    });
  }
}
function* addAction(action) {
  try {
    const { data } = yield apiService({}).post(
      `/device-type-actions`,
      action.payload
    );
    yield put({
      type: ADD_ACTION.SUCCESS,
      payload: data
    });
    yield put(fetchDeviceType({ id: action.payload.deviceTypeId }));
  } catch (err) {
    yield put({
      type: ADD_ACTION.FAILURE,
      payload: getError(err)
    });
  }
}
function* addObject(action) {
  try {
    const { data } = yield apiService({}).post(
      `/device-type-objects`,
      action.payload
    );
    yield put({
      type: ADD_OBJECT.SUCCESS,
      payload: data
    });
    yield put(fetchDeviceType({ id: action.payload.deviceTypeId }));
  } catch (err) {
    yield put({
      type: ADD_OBJECT.FAILURE,
      payload: getError(err)
    });
  }
}
function* saveDeviceType(action) {
  try {
    let data = {};
    if (action.payload.id) {
      const { data: editData } = yield apiService({}).patch(
        `/device-types/${action.payload.id}`,
        action.payload
      );
      data = editData;
    } else {
      const { data: createData } = yield apiService({}).post(
        `/device-types`,
        action.payload
      );
      data = createData;
      yield put(push(routes.deviceTypes.manage(data.id).path));
    }
    yield put({
      type: DEVICE_TYPES_SAVE.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({ type: DEVICE_TYPES_SAVE.FAILURE, payload: getError(err) });
  }
}

export default [
  function*() {
    yield takeEvery(DEVICE_TYPES_FETCH_SINGLE.REQUEST, fetchDeviceTypes);
  },
  function*() {
    yield takeEvery(ADD_ACTION.REQUEST, addAction);
  },
  function*() {
    yield takeEvery(ADD_OBJECT.REQUEST, addObject);
  },
  function*() {
    yield takeEvery(DEVICE_TYPES_SAVE.REQUEST, saveDeviceType);
  }
];
