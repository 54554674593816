import React from 'react';
import { Route } from 'react-router';

import { routes } from 'shared/constants/routes';
import Profile from './containers/Profile';
import Notifications from './containers/Notifications';

import './styles.scss';

export default () => [
  <Route path={routes.account.default.path} key="account-default" component={Profile} exact />,
  <Route
    path={routes.account.notifications.path}
    key="notifications-default"
    component={Notifications}
    exact
  />,
];
