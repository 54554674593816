import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const FETCH_PLACE = createRequestTypes('places/FETCH_PLACE');
export const SAVE_PLACE = createRequestTypes('places/SAVE_PLACE');

export const fetchPlace = ({ id }) => ({
  type: FETCH_PLACE.REQUEST,
  payload: { id },
});
export const savePlace = payload => ({
  type: SAVE_PLACE.REQUEST,
  payload: payload,
});

const initState = {
  details: {
    states: loadingStates,
    data: {},
  },
  save: {
    states: loadingStates,
  },
};

export default createReducer(initState, {
  [FETCH_PLACE.REQUEST]: state => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [FETCH_PLACE.SUCCESS]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [FETCH_PLACE.FAILURE]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [SAVE_PLACE.REQUEST]: state => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [SAVE_PLACE.SUCCESS]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [SAVE_PLACE.FAILURE]: (state, action) => {
    return update(state, {
      save: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
