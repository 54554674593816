import { forEach, map, sortBy } from 'lodash';
import {
  Recommendation,
  RecommendationPriority,
  RecommendationStatus,
} from 'shared/types/recommendation';
import { readableDateWithoutTime } from 'shared/utils/date';

const dataFilter = values => recommendation => {
  if (values.dateRange && values.dateRange[0]) {
    const createdAt = new Date(recommendation.createdAt).getTime();
    const dateFromStart = new Date(new Date(values.dateRange[0]).setHours(0, 0, 0, 0)).getTime();
    const dateFromEnd = new Date(new Date(values.dateRange[0]).setHours(23, 59, 59, 59)).getTime();
    const dateToEnd = new Date(new Date(values.dateRange[1]).setHours(23, 59, 59, 59)).getTime();

    if (values.dateRange[0] && values.dateRange[1]) {
      const isInRange = createdAt >= dateFromStart && createdAt <= dateToEnd;
      if (!isInRange) {
        return false;
      }
    }
    if (values.dateRange[0] && !values.dateRange[1]) {
      const isInRange = createdAt >= dateFromStart && createdAt < dateFromEnd;
      if (!isInRange) {
        return false;
      }
    }
  }
  if (values.placeId) {
    if (String(recommendation.placeId) !== String(values.placeId)) {
      return false;
    }
  }
  if (values.deviceTypeId) {
    if (String(recommendation.deviceTypeId) !== String(values.deviceTypeId)) {
      return false;
    }
  }
  return true;
};
export const getPrioritiesData = (recommendations?: Recommendation[], values: any) => {
  const data = [...recommendations].filter(dataFilter(values));
  // data.recommendations
  const byPriority = Object.assign(RecommendationPriority);
  forEach(byPriority, (_, k) => {
    byPriority[k] = 0;
  });

  forEach(data, r => {
    byPriority[r.priority]++;
  });

  return byPriority;
};

export const getRecommendationsByDate = (recommendations?: Recommendation[], values: any) => {
  const data = [...recommendations].filter(dataFilter(values));
  // data.recommendations
  const dates = sortBy(data, 'createdAt').map(item => readableDateWithoutTime(item.createdAt));
  const byDate = {};
  forEach(dates, date => {
    byDate[date] = 0;
  });

  forEach(data, r => {
    byDate[readableDateWithoutTime(r.createdAt)]++;
  });
  return byDate;
};

export const getOwnerData = (recommendations?: Recommendation[], values?: any) => {
  const data = [...recommendations].filter(dataFilter(values));
  const emptyKey = 'Brak os. odpowiedzialnej';
  const owners = map(data, item => {
    if (!item.owner && !item.ownerText) {
      return emptyKey;
    }
    return item.owner ? `${item.owner?.name} ${item.owner?.surname}` : item.ownerText;
  }).filter(v => !!v);

  const byOwner = { [emptyKey]: 0 };
  forEach(owners, owner => {
    byOwner[owner] = 0;
  });

  forEach(data, item => {
    if (!item.owner && !item.ownerText) {
      byOwner[emptyKey]++;
    } else {
      byOwner[item.owner ? `${item.owner?.name} ${item.owner?.surname}` : item.ownerText]++;
    }
  });

  return byOwner;
};
export const getLocationData = (recommendations?: Recommendation[], values?: any) => {
  const data = [...recommendations].filter(dataFilter(values));
  const emptyKey = 'Brak lokalizacji';
  const maps = map(data, item => {
    if (!item.mapId && !item.area) {
      return emptyKey;
    }
    return item.map ? item.map?.name : item.area;
  }).filter(v => !!v);

  const byLocation = { [emptyKey]: 0 };
  forEach(maps, item => {
    byLocation[item] = 0;
  });

  forEach(data, item => {
    if (!item.mapId && !item.area) {
      byLocation[emptyKey]++;
    } else {
      byLocation[item.map ? item.map?.name : item.area]++;
    }
  });

  return byLocation;
};
export const getStatusesData = (recommendations?: Recommendation[], values?: any) => {
  const data = [...recommendations].filter(dataFilter(values));
  const byStatus = Object.assign(RecommendationStatus);
  forEach(byStatus, (_, k) => {
    byStatus[k] = 0;
  });

  forEach(data, r => {
    byStatus[r.status]++;
  });

  return byStatus;
};

export const getDeviceTypesData = (
  deviceTypes?: any,
  recommendations?: Recommendation[],
  values?: any,
) => {
  if (!deviceTypes?.length) {
    return {};
  }
  const data = [...recommendations].filter(dataFilter(values));
  const result = {
    'Bez typu urządzenia': { value: 0, color: 'gray' },
  };
  const nameById = { 'no-id': 'Bez typu urządzenia' };
  deviceTypes?.forEach(deviceType => {
    nameById[deviceType.id] = deviceType.name;
    result[deviceType.name] = {
      value: 0,
      color: deviceType.color || 'gray',
    };
  });

  forEach(data, r => {
    if (!r.deviceTypeId) {
      result[nameById['no-id']].value++;

      return;
    }
    result[nameById[r.deviceTypeId]].value++;
  });
  return result;
};

interface Place {
  name: string;
  id: string;
}

export const getPlaceData = (places?: Place[], recommendations?: Recommendation[]) => {
  if (!places?.length) {
    return {};
  }
  const data = [...recommendations].filter(r => {
    return true;
  });
  const byPlace = {};
  const nameById = {};
  places?.forEach(place => {
    nameById[place.id] = place.name;
    byPlace[place.name] = 0;
  });
  forEach(byPlace, (_, k) => {
    byPlace[k] = 0;
  });

  forEach(data, r => {
    byPlace[nameById[r.placeId]]++;
  });

  return byPlace;
};
