import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { DEVICE_TYPES_FETCH_LIST } from '../../reducers/list';

function* fetchDeviceTypes() {
  try {
    const { data } = yield apiService({}).get('/device-types');
    yield put({
      type: DEVICE_TYPES_FETCH_LIST.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({
      type: DEVICE_TYPES_FETCH_LIST.FAILURE,
      payload: getError(err)
    });
  }
}

export default [
  function*() {
    yield takeEvery(DEVICE_TYPES_FETCH_LIST.REQUEST, fetchDeviceTypes);
  }
];
