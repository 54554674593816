import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import Create from './../Create';
import useFetch from 'shared/hooks/useFetch';
import styles from './styles.module.scss';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { routes } from 'shared/constants/routes';
import { useHistory } from 'react-router';
import RotateLoader from 'shared/components/RotateLoader';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { Link } from 'react-router-dom';
import { useTranslations } from 'shared/translations/useTranslations';
interface RecomendationCategory {
  id?: string;
  name: string;
}

interface State {
  categories: RecomendationCategory[];
}

interface Props {}

const Details = (_: Props) => {
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { categories: [] },
    fetchAction: () => api({}).get(`/api/admin/recommendations/categories`),
  });
  const history = useHistory();
  const handleCreate = useCallback(() => {
    history.push(routes.admin.recommendation.create.path);
  }, [history]);
  return (
    <div>
      <ListHeading title={translations.recommendations.recommendationCategories}>
        <Button onClick={handleCreate}>{translations.global.add}</Button>
      </ListHeading>
      <AppContent>
        {isLoading && <RotateLoader relative />}
        <div className={styles.list}>
          {data.categories.map(setting => (
            <Link
              to={routes.admin.recommendation.details.to(setting.id)}
              key={setting.name}
              className={styles.item}>
              <div>{setting.name}</div>
            </Link>
          ))}
        </div>
      </AppContent>
    </div>
  );
};
export default Details;
