import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContent from 'shared/components/AppContent';
import { routes } from 'shared/constants/routes';
import RecommendationCategories from './RecommendationCategories';
import VisualInspection from './VisualInspection';
import Mixtures from './Mixtures';
import AdminDevicesRoutes from './Devices';

function AdminRoutes() {
  return (
    // <AppContent>
    <Switch>
      <Route path={[routes.admin.recommendation.default.path]}>
        <RecommendationCategories />
      </Route>
      <Route path={[routes.admin.visualInspection.default.path]}>
        <VisualInspection />
      </Route>
      <Route path={[routes.admin.mixtures.default.path]}>
        <Mixtures />
      </Route>
      <Route path={[routes.admin.devices.default.path]}>
        <AdminDevicesRoutes />
      </Route>
    </Switch>
    // </AppContent>
  );
}

export default AdminRoutes;
