import React from 'react';
import get from 'lodash/get';
import { Tooltip } from '@mantine/core';
import moment from 'moment-timezone';

import { batteryLevelPercentage, rssiToText, dateLongerThanMinutes } from 'shared/utils/device';
import { useTranslations } from 'shared/translations/useTranslations';

import styles from './styles.module.scss';

interface Props {
  device: {
    id: string;
    uniqueId: string;
    description: string;
  };
  isAdmin: boolean;
  isSuperAdmin: boolean;
  onClick: (id: string) => void;
  deleteAlert: () => void;
  toggleDeleteModal: (id: string) => void;
}
const DeviceBox = (props: Props) => {
  const { device, onClick, isAdmin, isSuperAdmin, deleteAlert, toggleDeleteModal } = props;
  const date = get(device, 'deviceHistory[0].createdAt');
  const radioErrorInterval = get(device, 'place.radioErrorInterval');
  const battery = get(device, 'deviceHistory[0].battery');
  const rssi = get(device, 'deviceHistory[0].rssi');
  const detectionCounter = get(device, 'lastHistoryDetectionCounter');
  const translations = useTranslations();
  return (
    <>
      <div
        className={`device-box ${styles.deviceBox}`}
        key={device.id}
        onClick={() => onClick(device.id)}>
        <div className="device-box__header">
          <div className="device-box__id">{device.uniqueId}</div>

          <div className="device-box__id__icon">
            {get(device, 'detect') ? (
              <i className="fa fa-exclamation-triangle device-box__id__icon--alert"></i>
            ) : (
              <i className="fa fa-check-circle device-box__id__icon--good"></i>
            )}
          </div>
        </div>
        <div className="device-box__desc">
          {' '}
          {get(device, 'qrcodeId') && <i className={`fa fa-qrcode ${styles.iconQrCode}`}></i>}{' '}
          {device.location}
        </div>

        <div className="device-box__place device-box__row">
          <i className="fas fa-warehouse device-box__icon" />
          {get(device, 'place.name')}
        </div>
        <div className="device-box__place device-box__row">
          <i className="fas fa-map-marker device-box__icon" />
          {get(device, 'zone.name') || '-'}
        </div>

        <div className="device-box__device-type device-box__row">
          <i className="fas fa-tablet-alt device-box__icon"></i>
          {get(device, 'deviceType.name') || '-'}
        </div>
        <div className="device-box__battery device-box__row">
          <i className="fas fa-battery-full  device-box__icon"></i>
          {battery ? `${batteryLevelPercentage(battery)}%` : '-'}
        </div>
        <div className="device-box__battery device-box__row">
          <i className="fas fa-signal  device-box__icon"></i>
          {battery ? `${rssiToText(rssi)}` : '-'}
        </div>
        <div className="device-box__last-action device-box__row">
          <i className="fas fa-clock device-box__icon"></i>

          <Tooltip label={date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-'}>
            <span>{date ? moment(date).fromNow() : '-'}</span>
          </Tooltip>
          {date && dateLongerThanMinutes(date, radioErrorInterval) && (
            <i className="fa fa-exclamation-triangle icon-alert-date"></i>
          )}
        </div>
        {detectionCounter > 0 && (
          <div className="device-box__last-action device-box__row">
            <i className="fas fa-stopwatch-20 device-box__icon"></i>
            {detectionCounter}
          </div>
        )}
        {(toggleDeleteModal || deleteAlert) && (
          <div className={styles.deviceBoxActions}>
            {get(device, 'detect') && deleteAlert ? (
              <button onClick={deleteAlert} className={styles.deleteAlertButton}>
                {translations.devices.deleteAlert}
              </button>
            ) : null}
            {isSuperAdmin && toggleDeleteModal && (
              <button
                onClick={() => toggleDeleteModal(device.id)}
                className={styles.deleteDeviceButton}>
                <i className="fa fa-trash"></i>
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default DeviceBox;
