import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router';
import { routes } from 'shared/constants/routes';
import Company from 'modules/Company';
import Place from 'modules/Place';
import DeviceType from 'modules/DeviceType';
import Device from 'modules/Device';
import User from 'modules/User';
import Account from 'modules/Account';
import Reports from 'modules/Reports';
import Navigation from './containers/Navigation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import AdminRoutes from 'modules/Admin';
import RecommendationsRoutes from 'modules/Recommendations';
import StatisticsRoutes from 'modules/Statistics';
import QRCodes from '../QRCodes';
import DashboardRoutes from '../Dashboard';
import ProtectedRoute from '../../shared/components/ProtectedRoute';
import { UserType } from '../../shared/types/User';
import LoraRoutes from '../Lora';
// import RecommendationsRoutes from 'modules/Recommendations';
import styles from './styles.module.scss';
import TopBar from './containers/TopBar';
import ScheduleRoutes from 'modules/Schedule';

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <div className="App__sidebar">
        <Navigation />
      </div>
      <div className="App__content">
        <TopBar />
        <Route path={routes.app.default.path} component={DashboardRoutes} exact />
        <Route path={routes.dashboard.default.path} component={DashboardRoutes} exact />
        <Route path={routes.reports.default.path} component={Reports} />
        <ProtectedRoute
          userTypes={[UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN]}
          path={routes.companies.default.path}
          component={Company}
        />
        <Route path={routes.places.default.path} component={Place} />
        <ProtectedRoute
          userTypes={[UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN]}
          path={routes.deviceTypes.default.path}
          component={DeviceType}
        />
        <Route path={routes.devices.default.path} component={Device} />
        <ProtectedRoute
          userTypes={[UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN]}
          path={routes.users.default.path}
          component={User}
        />
        <Route path={routes.account.default.path} component={Account} />
        <ProtectedRoute
          userTypes={[UserType.SUPER_ADMIN]}
          path={routes.admin.default.path}
          component={AdminRoutes}
        />
        <Route path={routes.recommendation.default.path} component={RecommendationsRoutes} />
        <Route path={routes.statistics.default.path} component={StatisticsRoutes} />
        <Route path={routes.lora.default.path} component={LoraRoutes} />
        <Route path={routes.schedule.default.path} component={ScheduleRoutes} />
        <ProtectedRoute
          userTypes={[UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN, UserType.TECHNICIAN]}
          path={routes.qrCodes.default.path}
          component={QRCodes}
        />
        {/* <Route path={routes.recommendation.default.path} component={RecommendationsRoutes} /> */}
      </div>
    </div>
  );
};

export default App;
