import { Translation } from 'shared/translations/translations';

export const unitValues = (unitsTranslations: Translation['units']) => [
  { label: unitsTranslations.grams, value: 'GRAMS' },
  { label: unitsTranslations.milliliters, value: 'MILLILITERS' },
  { label: unitsTranslations.pieces, value: 'PIECES' },
  { label: unitsTranslations.package, value: 'PACKAGE' },
  { label: unitsTranslations.percentage, value: 'PERCENTAGE' },
  { label: unitsTranslations.set, value: 'SET' },
  { label: unitsTranslations.ppm, value: 'PPM' },
  { label: unitsTranslations.cubicMeters, value: 'CUBIC_METERS' },
];
