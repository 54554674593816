import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import { readableDate } from 'shared/utils/date';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { toast } from 'react-toastify';
import { isInteger, get } from 'lodash';
import { useTranslations } from 'shared/translations/useTranslations';

const decimalToHex = value => Number(value).toString(16).padStart(2, '0');
const hexToDecimal = value => parseInt(value, 16);
const POWER_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(value => ({
  label: value,
  value,
}));
const SIGNAL_COUNT_TO_ACTIVATE_ALG2 = [2, 3, 4, 5].map(value => ({
  label: value,
  value,
}));
const ALGORITHMS_OPTIONS = [
  {
    label: 'Brak algorytmu',
    value: 0,
  },
  {
    label: 'Algorytm 1',
    value: 1,
  },
  {
    label: 'Algorytm 2',
    value: 2,
  },
  {
    label: 'Algorytm 1 i 2',
    value: 3,
  },
];

const FORM_NAME = 'device/RADIO_OPTIONS';
interface Props {
  deviceId: string | number;
}

interface RadioSetting {
  createdAt: string;
  deletedAt: string | null;
  deviceId: number;
  id: number;
  receivedAt: string;
  setInLora: true;
  type: 'HEALTHCHECK_INTERVAL' | 'POWER';
  updatedAt: string;
  value: string;
}
interface State {
  radioSettings: RadioSetting[];
}

async function fetchData(setData, props) {
  try {
    const { data } = await api({}).get(`devices/${props.deviceId}/radio-settings`);
    setData(data);
  } catch (e) {}
}

const RadioOptions = (props: Props) => {
  const translations = useTranslations();
  const [isSavingPower, setSavingPower] = useState(false);
  const [isSavingInterval, setSavingInterval] = useState(false);
  const [isSaving, setSaving] = useState({});
  const [data, setData] = useState<State>({
    radioSettings: [],
  });
  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};

  useEffect(() => {
    fetchData(setData, props);
  }, []);

  const saveValue = (fieldName, port) => async () => {
    if (!values || !values[fieldName]) {
      return;
    }
    const value = values[fieldName];
    try {
      if (!isInteger(Number(value))) {
        return;
      }
      setSaving({ ...isSaving, [fieldName]: true });
      const data = decimalToHex(value);
      await api({}).post(`devices/${props.deviceId}/radio-setting`, {
        data,
        port,
      });
      toast.success('Zmiana została wprowadzona');
      fetchData(setData, props);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving({ ...isSaving, [fieldName]: false });
    }
  };
  // 6 - Active algorythms - 0, 1, 2, 3, default 0
  // 7 - Active signal length to activate Alg1, default 15
  // 8 - Signal count needed to activate alg2 [2,5], default 2
  // 9 - Minimal time between first and last signal activating alg2 in seconds, default 10
  // 10 - Max time between first and last signal in seconds, default 2
  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsPowerTitle}</h6>
          <Field
            component={SelectField}
            name="power"
            label={translations.devices.radioOptionsPowerDesc}
            dataSource={POWER_OPTIONS}
            placeholder="1 - 14"
          />
          <Button
            className={styles.button}
            onClick={saveValue('power', 4)}
            isLoading={get(isSaving, 'power')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsTimeIntervalTitle}</h6>
          <Field
            component={TextField}
            name="interval"
            label={translations.devices.radioOptionsTimeIntervalDesc}
            dataSource={[]}
          />
          <Button
            className={styles.button}
            onClick={saveValue('interval', 1)}
            isLoading={get(isSaving, 'interval')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsAlgorithmTitle}</h6>
          <Field
            component={SelectField}
            name="ALGORITHMS"
            label={translations.devices.radioOptionsAlgorithmDesc}
            dataSource={ALGORITHMS_OPTIONS}
          />
          <Button
            className={styles.button}
            onClick={saveValue('ALGORITHMS', 6)}
            isLoading={get(isSaving, 'ALGORITHMS')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsAlgorithm1SecondsTitle}</h6>
          <Field
            component={TextField}
            name="ALG1_SIGNAL_LENGTH"
            label={translations.devices.radioOptionsAlgorithm1SecondsDesc}
            dataSource={[]}
          />
          <Button
            className={styles.button}
            onClick={saveValue('ALG1_SIGNAL_LENGTH', 7)}
            isLoading={get(isSaving, 'ALG1_SIGNAL_LENGTH')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsAlgorithm2ActionsTitle}</h6>
          <Field
            component={SelectField}
            name="ALG2_SIGNAL_COUNT"
            label={translations.devices.radioOptionsAlgorithm2ActionsDesc}
            dataSource={SIGNAL_COUNT_TO_ACTIVATE_ALG2}
          />
          <Button
            className={styles.button}
            onClick={saveValue('ALG2_SIGNAL_COUNT', 8)}
            isLoading={get(isSaving, 'ALG2_SIGNAL_COUNT')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsAlgorithm2MinTimeTitle}</h6>
          <Field
            component={TextField}
            name="ALG2_MINIMAL_TIME_BETWEEN_SIGNALS"
            label={translations.devices.radioOptionsAlgorithm2MinTimeDesc}
            dataSource={[]}
          />
          <Button
            className={styles.button}
            onClick={saveValue('ALG2_MINIMAL_TIME_BETWEEN_SIGNALS', 9)}
            isLoading={get(isSaving, 'ALG2_MINIMAL_TIME_BETWEEN_SIGNALS')}>
            {translations.global.save}
          </Button>
        </div>
        <div className="col-md-3">
          <h6>{translations.devices.radioOptionsAlgorithm2MaxTimeTitle}</h6>
          <Field
            component={TextField}
            name="ALG2_MAXIMUM_TIME_BETWEEN_SIGNALS"
            label={translations.devices.radioOptionsAlgorithm2MaxTimeDesc}
            dataSource={[]}
          />
          <Button
            className={styles.button}
            onClick={saveValue('ALG2_MAXIMUM_TIME_BETWEEN_SIGNALS', 10)}
            isLoading={get(isSaving, 'ALG2_MAXIMUM_TIME_BETWEEN_SIGNALS')}>
            {translations.global.save}
          </Button>
        </div>
      </div>

      <h4> {translations.devices.radioOptionsHistory}</h4>
      <Table>
        <Thead>
          <Th>{translations.devices.radioOptionsHistorySetUpDate}</Th>
          <Th>{translations.devices.radioOptionsHistorySetUpDeviceAcceptationDate}</Th>
          <Th>{translations.devices.radioOptionsHistoryStatus}</Th>
          <Th>{translations.devices.radioOptionsHistorySettingType}</Th>
          <Th>{translations.devices.radioOptionsHistoryValue}</Th>
        </Thead>
        <Tbody>
          {data.radioSettings.map(setting => (
            <Tr key={setting.id}>
              <Td>{readableDate(setting.createdAt)}</Td>
              <Td>{readableDate(setting.receivedAt)}</Td>
              <Td>{setting.receivedAt ? 'Ustawione' : 'Oczekujące'}</Td>
              <Td>{setting.type}</Td>
              <Td>
                {Number.isNaN(hexToDecimal(setting.value)) ? '-' : hexToDecimal(setting.value)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    interval: 0,
    power: 0,
  },
})(RadioOptions);

/*
{"data":"0E","port":4} -> ustawi moc na 14

{"data":"00","port":4} -> ustawi moc na 0
json powinien być wysłany na topic downlink zgodny z DEVEUI urządzenia, np. "downlink/0004A30B00E92EFD".
Zmiana zajdzie dopiero przy kolejnej komunikacji modułu z chmurą - tak działa lorawan. Potwierdzenie przychodzi z następną wiadomością na uplinks, pole receive_confirm=1.

a
data – wartość przesyłanej nastawy w formacie heksadecymalnym o maksymalnej długości 4 bajtów
(8 znaków heksadecymalnych)

hexString = yourNumber.toString(16); // Number to hexadecimal
yourNumber = parseInt(hexString, 16); // hexadecimal to number


port – numer nastawy, gdzie poszczególne wartości to:
◦ 1 – okres w minutach, co który urządzenie powinno wysyłać informację o stanie baterii,
domyślnie 15
◦ 4 – moc nadawcza (TX power) modułu LoRaWAN, domyślnie 14. Przyjmuje wartości od 0 do 14
włącznie (pole data „00” do „0E”)



◦ 2 – czas w sekundach, po którym urządzenie powinno ponowić próbę dołączenia do sieci
LoRaWAN, domyślnie 30
◦ 3 – czas w sekundach, po którym urządzenie powinno ponowić próbę przesłania wiadomości,
domyślnie 10
◦ 4 – moc nadawcza (TX power) modułu LoRaWAN, domyślnie 14. Przyjmuje wartości od 0 do 14
włącznie (pole data „00” do „0E”)

*/
