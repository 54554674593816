import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Paper } from '@mantine/core';

import { readableDate } from 'shared/utils/date';
import { batteryLevelPercentage, rssiToText } from 'shared/utils/device';
import Button from 'shared/components/Button';
import api from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';


const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const getRowType = type => {
  if (type === 0) {
    return 'UNKNOWN';
  }
  if (type === 1) {
    return 'HEALTH_CHECK';
  }
  if (type === 2) {
    return 'ALERT';
  }
};
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const History = ({ data = [], deviceId }) => {
  const translations = useTranslations();
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [fullHistory, setFullHistory] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const onLoadFullHistory = useCallback(async () => {
    try {
      setLoading(true);
      const { data: response } = await api({}).get(`/devices/${deviceId}/full-history`);
      setFullHistory(response.history);
      setRowsPerPage(100);
      setPage(0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [setLoading, setFullHistory, deviceId]);

  const realData = fullHistory || data || [];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, realData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <h2>{translations.devices.history}</h2>
      <Button onClick={onLoadFullHistory} isLoading={isLoading}>
        {translations.devices.downloadFullHistory}
      </Button>
      <Paper className={classes.root} shadow='xs'>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-label="custom pagination table"
            isLoading={isLoading}>
            <TableHead>
              <TableRow>
                <TableCell> {translations.devices.historyType}</TableCell>
                <TableCell> {translations.devices.status}</TableCell>
                <TableCell> {translations.devices.battery}</TableCell>
                <TableCell> {translations.devices.signalPower}</TableCell>
                <TableCell> {translations.devices.date}</TableCell>
                <TableCell> Detection counter</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? realData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : realData
              ).map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {getRowType(row.type)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Number(row.detect) ? translations.devices.alert : translations.devices.online}
                  </TableCell>
                  <TableCell>{batteryLevelPercentage(row.battery)}%</TableCell>
                  <TableCell>{rssiToText(row.rssi)}</TableCell>
                  <TableCell>{readableDate(row.createdAt)}</TableCell>
                  <TableCell>{row.detectionCounter}</TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={3}
                  count={realData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </div>
  );
};

export default History;
