import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { ImageFile, SavedImage } from 'shared/components/ImagesUpload/types';
import { routes } from 'shared/constants/routes';
import useSave from 'shared/hooks/useSave';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { Intervention, InterventionImage, InterventionImageType } from 'shared/types/Intervention';

export enum ImageType {
  PESTS_REMOVED = 'PESTS_REMOVED',
  CAUSE = 'CAUSE',
  DESCRIPTION = 'DESCRIPTION',
}

export const useOnSubmit = (
  placeId: string,
  placeUuid: string,
  pestsRemovedImages: ImageFile[],
  causeImages: ImageFile[],
  descriptionImages: ImageFile[],
  interventionUuid?: string,
) => {
  const { uploadImages: uploadPestsRemovedImages, isUploading: isUploadingPestsRemovedImages } =
    useSubmitImages(pestsRemovedImages, 'PESTS_REMOVED', placeUuid);

  const { uploadImages: uploadCauseImages, isUploading: isUploadingCauseImages } = useSubmitImages(
    causeImages,
    'CAUSE',
    placeUuid,
  );

  const { uploadImages: uploadDescriptionImages, isUploading: isUploadingDescriptionImages } =
    useSubmitImages(descriptionImages, 'DESCRIPTION', placeUuid);
  const history = useHistory();
  const [isSavingIntervention, setIsSavingIntervention] = useState(false);
  const translations = useTranslations();
  const submitValues = useCallback(
    async values => {
      setIsSavingIntervention(true);
      try {
        const { data } = interventionUuid
          ? await apiService({}).patch<{ intervention: Intervention }>(
              `/api/places/${placeUuid}/interventions/${interventionUuid}`,
              values,
            )
          : await apiService({}).post<{ intervention: Intervention }>(
              `/api/places/${placeUuid}/interventions`,
              values,
            );
        if (!data.intervention) {
          return;
        }
        await uploadCauseImages(data.intervention.id);
        await uploadDescriptionImages(data.intervention.id);
        await uploadPestsRemovedImages(data.intervention.id);
        history.push(routes.places.interventions.default.to(placeId));
        setIsSavingIntervention(false);
        if (interventionUuid) {
          toast.success(translations.intervention.modifiedSuccess);
        } else {
          toast.success(translations.intervention.createdSuccess);
        }
      } catch (error) {
        setIsSavingIntervention(false);
        toast.error(translations.global.somethingWentWrong);
      }
    },
    [
      pestsRemovedImages,
      causeImages,
      descriptionImages,
      uploadCauseImages,
      uploadDescriptionImages,
      uploadPestsRemovedImages,
      isUploadingCauseImages,
      isUploadingDescriptionImages,
      isUploadingPestsRemovedImages,
      history,
      placeUuid,
      interventionUuid,
      isSavingIntervention,
      setIsSavingIntervention,
      translations,
    ],
  );

  return {
    submitValues,
    isSubmittingForm:
      isSavingIntervention ||
      isUploadingCauseImages ||
      isUploadingDescriptionImages ||
      isUploadingPestsRemovedImages,
  };
};

export const useSubmitImages = (
  images: ImageFile[],
  type: InterventionImageType,
  placeUuid: string,
) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadImages = useCallback(
    async (interventionUuid: string) => {
      setIsUploading(true);
      try {
        await Promise.all(
          images.map(async file => {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', file.data);
            formData.append('name', file.data.name);
            formData.append('type', type);

            await apiService({
              headers: {
                'content-type': 'multipart/form-data',
              },
            }).put(`/api/places/${placeUuid}/interventions/${interventionUuid}/images`, formData);
          }),
        );
        setIsUploading(false);
      } catch (e) {
        setIsUploading(false);
      }
    },
    [images, isUploading, setIsUploading],
  );

  return {
    uploadImages,
    isUploading,
  };
};

export const useDeleteSavedImage = (
  placeUuid: string,
  interventionUuid: string,
  setSavedImages: (images: InterventionImage[]) => void,
  savedImages: InterventionImage[],
) => {
  return useCallback(
    async (imageId: SavedImage['id']) => {
      await apiService({}).delete(
        `/api/places/${placeUuid}/interventions/${interventionUuid}/images/${imageId}`,
      );
      const updatedImages = savedImages.filter(savedImage => savedImage.id !== imageId);
      setSavedImages(updatedImages);
    },
    [setSavedImages, savedImages, placeUuid, interventionUuid],
  );
};
