import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import { readableDate } from 'shared/utils/date';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { toast } from 'react-toastify';
import { isInteger, get } from 'lodash';
import FileFieldClean from 'shared/components/FileFieldClean';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import Modal from 'shared/components/Modal';
import { useTranslations } from 'shared/translations/useTranslations';

const FORM_NAME = 'place/UPLOAD_MAP';
interface Props {
  close: (shouldRefetch: boolean) => void;
  open: boolean;
  placeId: number | string;
}

const PlaceMap = (props: Props) => {
  const translations = useTranslations();
  const { close, open } = props;
  const [file, setFile] = useState();
  const [fileRaw, setFileRaw] = useState();
  const [isSaving, setSaving] = useState(false);
  const [data, setData] = useState<State>({
    files: [],
  });
  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};

  const onChange = e => {
    setFileRaw(e);
    setFile({
      url: URL.createObjectURL(e),
      data: e,
      value: { name: e.name },
    });
  };
  const saveFile = async () => {
    if (!values || !values.name) {
      return;
    }
    try {
      setSaving(true);
      const formData = new FormData();
      formData.append('file', fileRaw);
      formData.append('name', values.name);
      await api({
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).post(`places/${props.placeId}/maps`, formData);
      toast.success(translations.map.mapUploaded);
      close(true);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={open} onClose={close} title={translations.map.addMap}>
      <div className="row">
        <div className="col-md-12">
          <Field component={TextField} name="name" label={translations.global.name} />
        </div>
        <div className="col-md-12">
          <FileFieldClean
            onChange={onChange}
            file={file}
            label={translations.global.file}
            accept="image/jpeg"
          />
        </div>

        <div className="col-md-3">
          <Button
            className={styles.uploadButton}
            onClick={saveFile}
            type="primary"
            isLoading={isSaving}>
            {translations.global.save}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    interval: 0,
    power: 0,
  },
})(PlaceMap);
