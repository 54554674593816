import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import api from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useParams } from 'react-router';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import { get } from 'lodash';
import { Loader, SimpleGrid, Space, Title } from '@mantine/core';
import Actions from './Actions';
import Objects from './Objects';
import Observations from './Observations';
import AssignActionToDeviceType from '../AssignActionToDeviceType';
import AssignObjectToDeviceType from '../AssignObjectToDeviceType';
import AssignObservationToDeviceType from '../AssignObservationToDeviceType';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { UserType } from 'shared/types/User';
import { DeviceType, DeviceTypeMeasurementMethod } from 'shared/types/deviceType';
import { Language, translationService } from 'shared/translations/translations';
import ManageDeviceTypeForm from './Form';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {
  onCreate?: (id: number | string) => void;
}

const canEditDeviceType = (
  userType: UserType,
  userCompanyId: number,
  deviceTypeCompanyId?: number,
  id?: number | string,
) => {
  if (userType === UserType.SUPER_ADMIN) {
    return true;
  }
  if (!id && userType === UserType.COMPANY_ADMIN) {
    return true;
  }
  if (userCompanyId && String(userCompanyId) === String(deviceTypeCompanyId)) {
    return true;
  }

  return false;
};

const Details = (props: Props) => {
  const { onCreate } = props;
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const currentUser = useCurrentUser();
  const [data, setData] = useState<{ deviceType: DeviceType | undefined }>({
    deviceType: undefined,
  });
  const [color, setColor] = useState(undefined);

  const canEdit = canEditDeviceType(
    currentUser.type,
    currentUser.companyId,
    data.deviceType?.parentCompanyId,
    id,
  );

  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      measurementMethod: undefined,
      settings: {},
      translations: [
        { lang: 'pl', name: undefined, description: undefined },
        { lang: 'en', name: undefined, description: undefined },
        { lang: 'de', name: undefined, description: undefined },
        { lang: 'it', name: undefined, description: undefined },
        { lang: 'ru', name: undefined, description: undefined },
      ],
    },

    validate: {},
  });
  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await api({}).get(`/device-types/${id}`);
      setColor(data.color);
      setData({ deviceType: data });

      // form.setValues(omit(data, 'translations'));

      if (data?.translations) {
        const translations = form.values.translations.map((translation, key) => {
          return {
            ...translation,
            name: get(data?.translations, `${translation.lang}.name`),
            description: get(data?.translations, `${translation.lang}.description`),
          };
        });
        form.setValues({
          name: data?.name,
          measurementMethod: data?.measurementMethod,
          description: data?.description,
          translations: translations,
          settings: data?.settings || {
            prefix: '',
            redirectToAddObjectsModal: false,
            redirectToAddObservationsModal: false,
          },
        });
      } else {
        form.setValues({
          name: data?.name,
          measurementMethod: data?.measurementMethod,
          description: data?.description,
          settings: data?.settings || {
            prefix: '',
            redirectToAddObjectsModal: false,
            redirectToAddObservationsModal: false,
          },
          translations: [
            {
              lang: 'pl',
              name: data?.name,
              description: data?.description,
            },
            {
              lang: 'en',
              name: data?.name,
              description: data?.description,
            },
            {
              lang: 'de',
              name: data?.name,
              description: data?.description,
            },
            {
              lang: 'it',
              name: data?.name,
              description: data?.description,
            },
            {
              lang: 'ru',
              name: data?.name,
              description: data?.description,
            },
          ],
        });
      }

      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setLoaded(true);
      setLoading(false);
    }
  }, [id]);

  const currentLanguage =
    translationService.getCurrentLanguage().toLowerCase() || Language.PL.toLowerCase();

  return (
    <>
      <ListHeading
        title={
          <div>
            <small style={{ color: 'grey' }}>{translations.global.deviceTypes}</small> /{' '}
            {id
              ? get(data, `deviceType.translations.${currentLanguage}.name`)
              : translations.global.add}
          </div>
        }></ListHeading>
      <AppContent>
        {isLoading && <Loader />}
        {isLoaded && canEdit && (
          <ManageDeviceTypeForm
            data={data}
            form={form}
            onCreate={id => {
              onCreate(id);
            }}
            onSuccess={() => {
              fetchDetails();
            }}
          />
        )}
        {isLoaded && !canEdit && id && (
          <div>
            <Title order={5}>{translations.global.deviceType}:</Title>
            {data?.deviceType.name}
            <br />
            <br />
            <Title order={4}>{translations.global.description}</Title>
            {data?.deviceType.description}
            <br />
            <br />
            <Title order={4}>{translations.global.color} </Title>
            {data?.deviceType.color ? (
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  display: 'block',
                  backgroundColor: data.deviceType.color,
                }}></div>
            ) : (
              'Brak koloru'
            )}
          </div>
        )}
      </AppContent>
      <AppContent>
        {isLoaded && (
          <>
            <Space h={30} />
            <SimpleGrid
              cols={
                form.values.measurementMethod === DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE
                  ? 3
                  : 2
              }>
              {id && (
                <>
                  <div>
                    <Title order={3}>
                      {translations.global.actions}{' '}
                      <AssignActionToDeviceType
                        onFinish={() => fetchDetails()}
                        deviceTypeId={id}
                        currentActions={data?.deviceType?.deviceTypeActions}
                      />
                    </Title>
                    <Actions
                      isLoading={isLoading}
                      actions={data?.deviceType?.deviceTypeActions}
                      reset={() => fetchDetails()}
                      deviceTypeId={id}
                    />
                  </div>
                  <div>
                    <Title order={3}>
                      {translations.global.pestRelated}{' '}
                      <AssignObjectToDeviceType
                        onFinish={() => fetchDetails()}
                        deviceTypeId={id}
                        currentObjects={data?.deviceType?.deviceTypeObjects}
                      />
                      {/* <AddDeviceTypeObject onFinish={() => fetchDetails()} deviceTypeId={id} /> */}
                    </Title>
                    <Objects
                      isLoading={isLoading}
                      objects={data?.deviceType?.deviceTypeObjects}
                      reset={() => fetchDetails()}
                      deviceTypeId={id}
                    />
                  </div>
                  {form.values.measurementMethod ===
                    DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE && (
                    <div>
                      <Title order={3}>
                        {translations.global.observations}{' '}
                        <AssignObservationToDeviceType
                          onFinish={() => fetchDetails()}
                          deviceTypeId={id}
                          currentObservations={data?.deviceType?.deviceTypeObservations}
                        />
                        {/* <AddDeviceTypeObservation
                          onFinish={() => fetchDetails()}
                          currentObservations={data?.deviceType?.deviceTypeObservations || []}
                          deviceTypeId={id}
                        /> */}
                      </Title>
                      <Observations
                        isLoading={isLoading}
                        observations={data?.deviceType?.deviceTypeObservations || []}
                        reset={() => fetchDetails()}
                        deviceTypeId={id}
                      />
                    </div>
                  )}
                </>
              )}
            </SimpleGrid>
          </>
        )}
      </AppContent>
    </>
  );
};

export default Details;
