export const filterRecommendationsByDate = (recommendations: any[], dateRange) => {
  return recommendations?.filter(recommendation => {
    if (dateRange && dateRange[0]) {
      const createdAt = new Date(recommendation.createdAt).getTime();
      const dateFromStart = new Date(new Date(dateRange[0]).setHours(0, 0, 0, 0)).getTime();
      const dateFromEnd = new Date(new Date(dateRange[0]).setHours(23, 59, 59, 59)).getTime();
      const dateToEnd = new Date(new Date(dateRange[1]).setHours(23, 59, 59, 59)).getTime();

      if (dateRange[0] && dateRange[1]) {
        return createdAt >= dateFromStart && createdAt <= dateToEnd;
      }
      if (dateRange[0] && !dateRange[1]) {
        return createdAt >= dateFromStart && createdAt < dateFromEnd;
      }
    }
    return true;
  });
};
