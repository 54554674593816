import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';

interface DeviceObjectModel {
  id: number;
  name: string;
  amount: string;
  note: string;
  createdAt: Date;
}

interface Props {
  deviceId?: string | number;
  objectId?: string | number;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  object: DeviceObjectModel;
}

const DeleteObjectModal = (props: Props) => {
  const { deviceId, objectId, refreshParent, onClose } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { object: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/devices/${deviceId}/objects/${objectId}`),
  });

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();

  const handleSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(`/api/v3/devices/${deviceId}/objects/${objectId}`);
      toast.success(translations.devices.deleteObjectSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!objectId);
    if (objectId) {
      refresh();
    }
  }, [objectId]);

  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.devices.deleteObjectTitle}>
      <LoadingOverlay visible={isLoading} />
      {error && (
        <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
          {translations.global.somethingWentWrong}
        </Notification>
      )}
      {!error && isLoaded && <>{translations.devices.deleteObjectDesc}</>}
      <Group position="right" mt={20}>
        <Button
          color="primary"
          loading={isSaving}
          type="button"
          onClick={handleSubmit}
          disabled={!!error || !isLoaded}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteObjectModal;
