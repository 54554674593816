import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { USERS_FETCH_LIST } from '../../reducers/list/';

function* fetchUsers() {
  try {
    const { data } = yield apiService({}).get('/users');
    yield put({
      type: USERS_FETCH_LIST.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({ type: USERS_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function*() {
    yield takeEvery(USERS_FETCH_LIST.REQUEST, fetchUsers);
  }
];
