import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import TextField from 'shared/components/TextField';
import { fetchPlaces, fetchReport } from '../../reducers/report';
import { modifyReportForTable } from './helpers';
import styles from './styles.module.scss';
import Button from 'shared/components/Button';
import DateField from 'shared/components/DateField';
import { generatePdf, generatePdfPreview } from '../../services/reportService';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';

const FORM_NAME = 'reports/REPORT';

const Profile = props => {
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const placesState = useSelector(state => state.reports.report.places);
  const reportState = useSelector(state => state.reports.report.report);
  const isLoading = placesState.states.isLoading || reportState.states.isLoading;
  const data = modifyReportForTable(reportState.data);
  const values = useSelector(state => getFormValues(FORM_NAME)(state));
  const formValues = useMemo(() => values, [values]);

  const places = useMemo(
    () =>
      map(placesState.data, place => ({
        value: place.id,
        label: place.name,
      })),
    [placesState],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPlaces({}));
  }, [dispatch]);

  const onPdfGenerate = async () => {
    if (!formValues.placeId) {
      return;
    }
    let df = new Date();
    let dt = new Date();
    df.setHours(0, 0, 0, 0);
    dt.setHours(23, 59, 59, 59);
    if (formValues.dateFrom) {
      df = new Date(formValues.dateFrom);
      df.setHours(0, 0, 0, 0);
    }
    if (formValues.dateTo) {
      dt = new Date(formValues.dateTo);
      dt.setHours(23, 59, 59, 59);
    }

    try {
      setSaving(true);

      await generatePdf({
        dateFrom: df,
        dateTo: dt,
        placeId: formValues.placeId,
        note: formValues.note,
        generatedAt: new Date().toLocaleString(),
      });
      // history.push(routes.reports.default.path);
      toast.success('Raport został wygenerowany');
      setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  const generatePreview = async () => {
    if (!formValues.placeId) {
      return;
    }
    try {
      let df = new Date();
      let dt = new Date();
      df.setHours(0, 0, 0, 0);
      dt.setHours(23, 59, 59, 59);
      if (formValues.dateFrom) {
        df = new Date(formValues.dateFrom);
        df.setHours(0, 0, 0, 0);
      }
      if (formValues.dateTo) {
        dt = new Date(formValues.dateTo);
        dt.setHours(23, 59, 59, 59);
      }

      setSaving(true);
      const { data } = await generatePdfPreview({
        dateFrom: df,
        dateTo: dt,
        placeId: formValues.placeId,
        note: formValues.note,
        generatedAt: new Date().toLocaleString(),
      });
      const iframe = document.getElementById('report-preview');
      const html = data;
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();

      iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
      // history.push(routes.reports.default.path);
      // toast.success('Raport został wygenerowany');
      // setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  useEffect(() => {
    if (places[0]) {
      dispatch(change(FORM_NAME, 'placeId', places[0].value));
    }
  }, [places, dispatch]);

  useEffect(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    dispatch(change(FORM_NAME, 'dateFrom', firstDay));
    dispatch(change(FORM_NAME, 'dateTo', lastDay));
  }, []);

  const iframeLoaded = () => {
    const iframe = document.getElementById('report-preview');

    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  };

  return (
    <div>
      <ListHeading title={translations.reports.generateReport}></ListHeading>
      <AppContent>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={SelectField}
              className={styles.field}
              name="placeId"
              label={translations.global.facility}
              dataSource={places}
            />
            <Field
              component={DateField}
              className={styles.field}
              name="dateFrom"
              label={translations.global.dateFrom}
            />
            <Field component={DateField} name="dateTo" label={translations.global.dateTo} />
            <Field
              component={TextField}
              className={styles.noteField}
              name="note"
              label={translations.global.note}
              multiLine
              rows={5}
            />
            <div className={styles.buttons}>
              {/* <Button buttonType="button" onClick={generate} isLoading={isLoading}>
                {translations.global.generate}
              </Button> */}
              <Button buttonType="button" onClick={generatePreview} isLoading={isLoading}>
                {translations.global.generate} Preview
              </Button>
              <Button type="primary" onClick={onPdfGenerate} isLoading={isSaving}>
                {translations.global.generatePdf}
              </Button>
            </div>
          </div>
          <div className="col-md-6" style={{ display: 'none' }}>
            <Table isLoading={isLoading}>
              <Thead>
                <Tr>
                  <Th>{translations.global.date}</Th>
                  <Th>{translations.global.pestRelated}</Th>
                  <Th>{translations.global.actions}</Th>
                  <Th>{translations.reports.mouseeDetections}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan="4">{translations.reports.noData}</Td>
                  </Tr>
                )}
                {map(data, history => (
                  <Tr key={history.date}>
                    <Td>{history.date}</Td>
                    <Td>
                      {history.objects.map(object => (
                        <div key={`${history.date}-${object.objectName}`}>
                          {object.total}x {object.objectName}
                        </div>
                      ))}
                    </Td>
                    <Td>
                      {history.actions.map(action => (
                        <div key={`${history.date}-${action.actionName}`}>
                          {action.total}x {action.actionName}
                        </div>
                      ))}
                    </Td>
                    <Td>
                      {history.alerts.map(alerts => (
                        <div key={`${history.date}-alerts`}>{alerts.total}</div>
                      ))}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
        <div style={{ width: '100%', minHeight: '100vh' }}>
          <iframe id="report-preview" style={{ width: '100%' }} onLoad={iframeLoaded}></iframe>
        </div>
      </AppContent>
    </div>
  );
};
const currentDate = new Date();
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    dateTo: currentDate,
    dateFrom: undefined,
  },
})(Profile);
// const mapStateToProps = ({ users, auth }) => ({
//   data: users.list.data,
//   states: users.list.states,
//   token: auth.data.token,
// });
// const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile);
