import React, { useCallback } from 'react';
import moment from 'moment';
import { useForm } from '@mantine/form';
import {
  Button,
  Checkbox,
  Flex,
  Group,
  MultiSelect,
  NumberInput,
  Select,
  Textarea,
  TextInput,
  Text,
} from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';

import { useTranslations } from 'shared/translations/useTranslations';
import useSave from 'shared/hooks/useSave';
import useDelete from 'shared/hooks/useDelete';

import { VisitFormProps, VisitFormValues, VisitType } from './types';
import { ScheduleEvent, PeriodicityUnit } from '../types';
import { useAllPlacesList, usePlaceUsersQuery } from 'queries/places';

const VisitForm = (props: VisitFormProps) => {
  const { onSaveEvent, initialValues, placeUuid, uuid, onDeleteEvent } = props;
  const form = useForm({
    initialValues: {
      name: undefined,
      type: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      periodicity: {
        endDate: undefined,
        unit: undefined,
        count: undefined,
        withoutWeekends: undefined,
      },
      periodical: false,
      placeUuid: placeUuid,
      userUuids: [],
      timeFrom: undefined,
      timeTo: undefined,
      description: undefined,
      customPeriodicity: false,
      ...initialValues,
    },
  });

  const translations = useTranslations();

  const visitTypeOptions = [
    {
      value: VisitType.NEUTRAL,
      label: translations.schedule.visitForm.types.neutral,
    },
    {
      key: VisitType.INSPECTION,
      value: VisitType.INSPECTION,
      label: translations.schedule.visitForm.types.inspection,
    },
    {
      key: VisitType.INTERVENTION,
      value: VisitType.INTERVENTION,
      label: translations.schedule.visitForm.types.intervention,
    },
    {
      value: VisitType.MAINTENANCE,
      label: translations.schedule.visitForm.types.maintenance,
    },
    {
      value: VisitType.OTHER,
      label: translations.schedule.visitForm.types.other,
    },
  ];

  const periodicityOptions = [
    {
      value: PeriodicityUnit.DAILY,
      label: translations.schedule.visitForm.periodicity.daily,
    },
    {
      value: PeriodicityUnit.WEEKLY,
      label: translations.schedule.visitForm.periodicity.weekly,
    },
    {
      value: PeriodicityUnit.BIWEEKLY,
      label: translations.schedule.visitForm.periodicity.biweekly,
    },
    {
      value: PeriodicityUnit.MONTHLY,
      label: translations.schedule.visitForm.periodicity.monthly,
    },
    {
      value: PeriodicityUnit.YEARLY,
      label: translations.schedule.visitForm.periodicity.yearly,
    },
  ];

  const periodicityUnitOptions = [
    {
      value: PeriodicityUnit.DAILY,
      label: translations.global.day,
    },
    {
      value: PeriodicityUnit.WEEKLY,
      label: translations.global.week,
    },
    {
      value: PeriodicityUnit.MONTHLY,
      label: translations.global.month,
    },
    {
      value: PeriodicityUnit.YEARLY,
      label: translations.global.year,
    },
  ];

  const { save, isLoading, error } = useSave({
    postEndpoint: `/api/places/${form.values.placeUuid}/schedule/events`,
    patchEndpoint:
      uuid !== undefined ? `/api/places/${form.values.placeUuid}/schedule/events/${uuid}` : undefined,
    isPatch: uuid !== undefined,
    additionalDeps: [uuid, form.values.placeUuid],
    onSuccessCallback: (data: { scheduleEvent: ScheduleEvent }) => {
      notifications.show({
        title: translations.schedule.visitForm.title,
        message: translations.schedule.visitForm.visitAddedSuccess,
        color: 'green',
      });
      onSaveEvent(data.scheduleEvent, uuid !== undefined);
      form.reset();
    },
  });

  const submit = (formValues: VisitFormValues) => {
    const values = {
      ...formValues,
      periodicity: formValues.periodical
        ? {
            unit: formValues.periodicity.unit,
            endDate: formValues.periodicity.endDate,
            count: formValues.customPeriodicity ? formValues.periodicity?.count : undefined,
          }
        : undefined,
      customPeriodicity: undefined,
    };

    values.dateFrom.setHours(
      Number(values.timeFrom.split(':')[0]),
      Number(values.timeFrom.split(':')[1]),
    );

    values.dateTo = new Date(values.dateFrom.getTime());
    values.dateTo.setHours(
      Number(values.timeTo.split(':')[0]),
      Number(values.timeTo.split(':')[1]),
    );

    values?.periodicity?.endDate?.setHours(24);
    save({
      ...values,
      dateFrom: values.dateFrom.toString(),
      dateTo: values.dateTo.toString(),
      periodicity: values.periodicity
        ? {
            ...values.periodicity,
            endDate: values.periodicity.endDate
              ? values.periodicity?.endDate.toString()
              : undefined,
          }
        : undefined,
    });
  };

  const { deleteFn, isDeleting } = useDelete({
    endpoint: !uuid ? undefined : `/api/places/${form.values.placeUuid}/schedule/events/${uuid}`,
    additionalDeps: [uuid],
    onSuccessCallback: () => {
      onDeleteEvent(uuid);
    },
  });

  const { data: placeList = [] } = useAllPlacesList();

  const placesOptions = placeList.map(place => ({
    value: place.uuid,
    label: place.name,
  }));

  const { data: users } = usePlaceUsersQuery(form.values.placeUuid);
  const usersData = users?.users || [];

  const userOptions = usersData.map(user => ({
    value: user.uuid,
    label: `${user.name} ${user.surname}`,
    key: user.uuid,
  }));

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <TextInput
        label={translations.global.name}
        {...form.getInputProps('name')}
        mb={12}
        required
      />
      <Select
        data={visitTypeOptions}
        label={translations.global.type}
        mb={12}
        required
        {...form.getInputProps('type')}
      />
      <Textarea
        label={translations.global.description}
        {...form.getInputProps('description')}
        mb={12}
      />
      <Group>
        <DatePickerInput
          name="dateFrom"
          label={translations.global.date}
          placeholder="Date"
          mb={12}
          required
          {...form.getInputProps('dateFrom')}
        />
        <TimeInput
          name="timeFrom"
          label=" "
          mb={12}
          required
          withAsterisk={false}
          {...form.getInputProps('timeFrom')}
        />
        <TimeInput
          name="timeTo"
          label=" "
          mb={12}
          required
          withAsterisk={false}
          {...form.getInputProps('timeTo')}
        />
      </Group>
      <Checkbox
        mt="md"
        label={translations.schedule.visitForm.periodical}
        mb={12}
        {...form.getInputProps('periodical', { type: 'checkbox' })}
      />
      <>
        <Select
          data={periodicityOptions}
          label={translations.schedule.visitForm.cyclicity}
          disabled={form.values.customPeriodicity && form.values.periodical}
          required={!form.values.customPeriodicity && form.values.periodical}
          {...form.getInputProps('periodicity.unit')}
          mb={12}
        />
        {form.values.periodical && (
          <Flex direction="column">
            <Checkbox
              mt="md"
              label={translations.global.withoutWeekends}
              mb={12}
              {...form.getInputProps('periodicity.withoutWeekends', { type: 'checkbox' })}
            />
            <Checkbox
              mt="md"
              label={translations.schedule.visitForm.periodicity.custom}
              mb={12}
              {...form.getInputProps('customPeriodicity', { type: 'checkbox' })}
            />
          </Flex>
        )}
        {form.values.customPeriodicity && (
          <Flex direction="column" mb={12}>
            <Text mb={10}>{translations.schedule.visitForm.periodicity.custom}</Text>
            <Flex direction="row" justify="center" align="center">
              <Text mr={10}>{translations.global.every}</Text>
              <NumberInput
                name="periodicity.count"
                min={1}
                mr={10}
                required={form.values.customPeriodicity && form.values.periodical}
                {...form.getInputProps('periodicity.count')}
              />
              <Select
                data={periodicityUnitOptions}
                required={form.values.customPeriodicity && form.values.periodical}
                {...form.getInputProps('periodicity.unit')}
              />
            </Flex>
          </Flex>
        )}
        <DatePickerInput
          name="periodicity.endDate"
          label={translations.global.dateTo}
          placeholder="Date"
          mb={12}
          minDate={moment(form.values.dateTo).add(1, 'day').toDate()}
          disabled={!form.values.periodical}
          clearable
          {...form.getInputProps('periodicity.endDate')}
        />
      </>
      <Select
        data={placesOptions}
        searchable
        placeholder=""
        label={translations.global.facility}
        clearable
        required
        mb={12}
        {...form.getInputProps('placeUuid')}
      />
      <MultiSelect
        data={userOptions}
        label={translations.global.technicians}
        mb={12}
        {...form.getInputProps('userUuids')}
      />
      <Group position="right" mt={40}>
        {uuid && (
          <Button
            onClick={deleteFn}
            loading={isDeleting}
            disabled={isLoading}
            type="button"
            color="red">
            {translations.global.delete}
          </Button>
        )}
        <Button loading={isLoading} disabled={isDeleting} type="submit">
          {translations.global.save}
        </Button>
      </Group>
    </form>
  );
};

export default VisitForm;
