import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Breadcrumbs,
  Space,
  SimpleGrid,
  Select,
  Popover,
  Button,
  Group,
  Modal,
  Flex,
} from '@mantine/core';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import useFetch from 'shared/hooks/useFetch';
import { routes } from 'shared/constants/routes';
import map from 'lodash/map';
import { useHistory, useParams } from 'react-router';
import RotateLoader from 'shared/components/RotateLoader';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { Link } from 'react-router-dom';
import { get, identity, pickBy, reverse, sortBy, uniqBy } from 'lodash';

import {
  getChartColorByRecommendationStatus,
  getColorByRecommendationPriority,
  getColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import Filter from './Filter';
import { RecommendationPriority, RecommendationStatus } from 'shared/types/recommendation';
import { DatePickerInput } from '@mantine/dates';
import { filterRecommendationsByDate } from './helpers';
import ListMap from './ListMap';
import DownloadCsv from './DownloadCsv';
import { Doughnut } from 'react-chartjs-2';
import { getStatusesData } from '../StatisticsByPlace/helpers';
import { useTranslations } from 'shared/translations/useTranslations';
import { useDisclosure } from '@mantine/hooks';
interface RecomendationCategory {
  id?: string;
  name: string;
}

interface State {
  categories: RecomendationCategory[];
}

interface Props {}

const List = (_: Props) => {
  const translations = useTranslations();
  const { placeId } = useParams<{ placeId: string }>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { recommendations: [] },
    fetchAction: () => api({}).get(`/api/recommendations?placeId=${placeId}`),
  });
  const {
    data: place,
    isLoading: isLoadingPlace,
    isLoaded: isLoadedPlace,
  } = useFetch<any>({
    initialValue: { recommendations: [] },
    fetchAction: () => api({}).get(`/places/${placeId}`),
  });
  const history = useHistory();
  const handleCreate = useCallback(() => {
    history.push(routes.recommendation.create.to(placeId));
  }, [history]);
  const goToDetails = useCallback(
    (id: string) => () => {
      history.push(routes.recommendation.details.to(placeId, id));
    },
    [history],
  );
  const items = [
    { title: translations.global.recommendations, href: routes.recommendation.default.path },
    { title: place?.name, href: routes.recommendation.place.to(placeId) },
    { title: translations.global.list },
  ].map((item, index) =>
    item.href ? (
      <Link to={item.href} key={index}>
        <span className="g-breadcrumb"> {item.title}</span>
      </Link>
    ) : (
      <span key={index}>{item.title}</span>
    ),
  );

  const [filters, setFilters] = useState({
    priority: undefined,
    recommendationCategoryId: undefined,
    authorId: undefined,
    ownerId: undefined,
    deviceTypeId: undefined,
    mapId: undefined,
    status: ['IN_PROGRESS', 'OPEN', 'WAITING'],
  });
  const handleSetFilters = useCallback(
    (filter, optionalFilter = undefined) =>
      data => {
        setFilters(filters => {
          const result = {
            ...filters,
            [filter]: data,
          };
          if (optionalFilter) {
            result[optionalFilter] = data;
          }
          return result;
        });
      },
    [],
  );

  const possibleCategories = map(data.recommendations, recommendation =>
    recommendation.recommendationCategoryId
      ? {
          label: get(recommendation, 'recommendationCategory.name'),
          value: recommendation.recommendationCategoryId,
        }
      : undefined,
  ).filter(category => !!category);

  const possibleDeviceTypes = map(data.recommendations, recommendation =>
    recommendation.deviceTypeId
      ? {
          label: get(recommendation, 'deviceType.name'),
          value: recommendation.deviceTypeId,
        }
      : undefined,
  ).filter(v => !!v);
  const possibleAuthors = map(data.recommendations, recommendation =>
    recommendation.authorId
      ? {
          label: `${get(recommendation, 'author.name')} ${get(recommendation, 'author.surname')}`,
          value: recommendation.authorId,
        }
      : undefined,
  ).filter(v => !!v);
  const possibleLocations = map(data.recommendations, recommendation =>
    recommendation.mapId
      ? {
          label: get(recommendation, 'map.name'),
          value: recommendation.mapId,
        }
      : undefined,
  ).filter(v => !!v);

  const possibleOwners = uniqBy(
    map(data.recommendations, recommendation => {
      if (recommendation.ownerId) {
        return {
          label: `${get(recommendation, 'owner.name')} ${get(recommendation, 'owner.surname')}`,
          value: recommendation.ownerId,
        };
      }
      if (recommendation.ownerText) {
        return {
          label: recommendation.ownerText,
          value: recommendation.ownerText,
        };
      }
      return undefined;
    }).filter(v => !!v),
    'value',
  );

  const shouldReturnCheck = (values, value) => {
    let shouldReturn = true;
    if (Array.isArray(values) && values.length > 0) {
      if (!values.map(v => String(v)).includes(String(value))) {
        shouldReturn = false;
      }
    }
    return shouldReturn;
  };

  const [sortByValue, setSortByValue] = useState('createdAt-desc');
  const [showComment, setShowComment] = useState(false);

  let filteredData = (data.recommendations || []).filter(recommendation => {
    const finalReturn = true;
    let hasAtLeastOneFalse = false;
    const notNullFilters = pickBy(filters, identity);

    let hasAlreadyOwner = false;
    map(notNullFilters, (values, key) => {
      let shouldReturn = true;
      if (['ownerText', 'ownerId'].includes(key)) {
        shouldReturn = true;
      } else {
        shouldReturn = shouldReturnCheck(values, recommendation[key]);
      }
      if (!shouldReturn) {
        hasAtLeastOneFalse = true;
      }
    });
    if (notNullFilters?.ownerId?.length || notNullFilters?.ownerText?.length) {
      const hasId = notNullFilters?.ownerId
        ?.map(v => String(v))
        .includes(String(recommendation.ownerId));
      const hasText = notNullFilters?.ownerText
        ?.map(v => String(v))
        .includes(String(recommendation.ownerText));
      if (!hasId && !hasText) {
        hasAtLeastOneFalse = true;
      }
    }

    if (hasAtLeastOneFalse) {
      return false;
    }
    return true;
  });

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleSetDateRange = value => {
    setDateRange(value);
  };
  const sortValue = sortByValue.split('-')[0];
  const sortType = sortByValue.split('-')[1];
  filteredData = sortBy(filteredData, sortValue);
  if (sortType === 'desc') {
    filteredData = reverse(filteredData);
  }
  if (dateRange[0]) {
    filteredData = filterRecommendationsByDate(filteredData, dateRange);
  }
  const byStatus = useMemo(
    () => getStatusesData(data?.recommendations, { dateRange }),
    [data.recommendations, {}],
  );

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div>
      <ListHeading
        title={translations.global.recommendations}
        footer={
          <Flex direction="row" justify="space-between" align="center">
            <Breadcrumbs className="g-breadcrumbs">{items}</Breadcrumbs>
            <Button onClick={open} variant="outline">
              {translations.map.facilityPlan}
            </Button>
          </Flex>
        }>
        <div className="d-flex">
          <Group>
            <Button onClick={handleCreate}>{translations.global.add}</Button>
            <DownloadCsv />
          </Group>
        </div>
      </ListHeading>
      <AppContent>
        <Modal opened={opened} onClose={close} size="100%">
          <SimpleGrid cols={2} spacing="lg">
            <ListMap recommendations={filteredData} />
            <div style={{ maxWidth: '400px' }}>
              <Doughnut
                width={100}
                height={100}
                data={{
                  labels: [...Object.keys(byStatus)],
                  datasets: [
                    {
                      label: 'Status',
                      data: [...Object.values(byStatus)],
                      backgroundColor: [
                        ...Object.keys(byStatus).map(getChartColorByRecommendationStatus),
                      ],
                    },
                  ],
                }}
              />
            </div>
          </SimpleGrid>
        </Modal>
        <Space h="lg" />
        {isLoading && <RotateLoader relative />}
        <SimpleGrid cols={5} spacing="lg">
          <Select
            label={translations.global.sortBy}
            name="placeId"
            value={sortByValue}
            onChange={value => setSortByValue(value)}
            data={[
              { value: 'createdAt-desc', label: translations.recommendations.sortByDateDesc },
              { value: 'createdAt-asc', label: translations.recommendations.sortByDateAsc },
              { value: 'status-desc', label: translations.recommendations.sortByStatusDesc },
              { value: 'status-asc', label: translations.recommendations.sortByStatusAsc },
              { value: 'priority-desc', label: translations.recommendations.sortByPriorityDesc },
              { value: 'priority-asc', label: translations.recommendations.sortByPriorityAsc },
              { value: 'dueDate-desc', label: translations.recommendations.sortByDeadlineDesc },
              { value: 'dueDate-asc', label: translations.recommendations.sortByDeadlineAsc },
              // { value: 'createdAt-desc', label: 'Data dodania (malejąco)' },
              // { value: 'createdAt-asc', label: 'Data dodania (rosnąco)' },
              // { value: 'status-desc', label: 'Status (rosnąco)' },
              // { value: 'status-asc', label: 'Status (malejąco)' },
              // { value: 'priority-desc', label: 'Priorytet (rosnąco)' },
              // { value: 'priority-asc', label: 'Priorytet (malejąco)' },
              // { value: 'dueDate-desc', label: 'Termin (rosnąco)' },
              // { value: 'dueDate-asc', label: 'Termin (malejąco)' },
            ]}
            defaultValue="createdAt-desc"
          />
          <DatePickerInput
            type="range"
            label={translations.global.dateRange}
            placeholder={translations.global.selectDateRange}
            value={dateRange}
            onChange={handleSetDateRange}
          />
        </SimpleGrid>
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>
                <Filter
                  title={translations.recommendations.priority}
                  defaultValue={filters.priority}
                  onChange={handleSetFilters('priority')}
                  options={Object.keys(RecommendationPriority).map(priority => ({
                    value: priority,
                    label: priority,
                  }))}
                />
              </Th>
              <Th>
                <Filter
                  title={translations.recommendations.category}
                  defaultValue={filters.recommendationCategoryId}
                  onChange={handleSetFilters('recommendationCategoryId')}
                  options={possibleCategories}
                />
              </Th>
              <Th>
                <Filter
                  title={translations.recommendations.map}
                  defaultValue={filters.mapId}
                  onChange={handleSetFilters('mapId')}
                  options={possibleLocations}
                />
              </Th>
              <Th>{translations.recommendations.location}</Th>
              <Th>{translations.global.device}</Th>
              <Th>
                <Filter
                  title={translations.global.deviceType}
                  defaultValue={filters.deviceTypeId}
                  onChange={handleSetFilters('deviceTypeId')}
                  options={possibleDeviceTypes}
                />
              </Th>
              <Th>
                <Filter
                  title={translations.recommendations.author}
                  onChange={handleSetFilters('authorId')}
                  options={possibleAuthors}
                  defaultValue={filters.authorId}
                />
              </Th>
              <Th>
                <Filter
                  title={translations.recommendations.owner}
                  onChange={handleSetFilters('ownerId', 'ownerText')}
                  options={possibleOwners}
                  defaultValue={filters.ownerId}
                />
              </Th>

              <Th>{translations.global.createdAt}</Th>
              <Th>{translations.recommendations.dueDate}</Th>
              <Th>{translations.recommendations.recommendation}</Th>
              <Th>
                <Filter
                  title={translations.global.status}
                  defaultValue={filters.status}
                  onChange={handleSetFilters('status')}
                  options={Object.keys(RecommendationStatus).map(status => ({
                    value: status,
                    label: status,
                  }))}
                />
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(filteredData, (recommendation, index) => (
              <Tr key={recommendation.id} onClick={goToDetails(recommendation.id)}>
                <Td noBreak>{recommendation.uniqueId}</Td>
                <Td>
                  <Badge color={getColorByRecommendationPriority(recommendation.priority)}>
                    {translations.recommendations.enums.priority[recommendation.priority]}
                  </Badge>
                </Td>
                <Td>
                  {recommendation.recommendationCategoryId
                    ? get(recommendation, 'recommendationCategory.name')
                    : '-'}
                </Td>
                <Td>{recommendation.mapId ? get(recommendation, 'map.name') : '-'}</Td>
                <Td>{recommendation.area ? get(recommendation, 'area') : '-'}</Td>
                <Td>{recommendation.deviceId ? get(recommendation, 'device.uniqueId') : '-'}</Td>
                <Td>
                  {recommendation.deviceTypeId ? get(recommendation, 'deviceType.name') : '-'}
                </Td>
                <Td>
                  {recommendation.author
                    ? `${recommendation.author.name} ${recommendation.author.surname}`
                    : '-'}
                </Td>
                <Td>
                  {recommendation.owner
                    ? `${recommendation.owner.name} ${recommendation.owner.surname}`
                    : recommendation.ownerText}
                </Td>
                <Td>{readableDate(recommendation.createdAt)}</Td>
                <Td noBreak>{readableDateWithoutTime(recommendation.dueDate)}</Td>
                <Td>{recommendation.recommendation}</Td>
                <Td>
                  <Badge color={getColorByRecommendationStatus(recommendation.status)}>
                    {translations.recommendations.enums.status[recommendation.status]}
                  </Badge>
                </Td>
                <Td>
                  {recommendation.isMoreDataNeeded && (
                    <i className="fa fa-exclamation-triangle" style={{ color: 'red' }}></i>
                  )}
                  <Popover
                    opened={showComment === recommendation.id}
                    onClose={() => setShowComment(false)}
                    position="bottom"
                    placement="center"
                    withArrow
                    trapFocus={false}
                    closeOnEscape={false}
                    transition="pop-top-left"
                    width={260}
                    styles={{ body: { pointerEvents: 'none' } }}>
                    <Popover.Target>
                      <i
                        className="fa fa-info"
                        onMouseEnter={() => setShowComment(recommendation.id)}
                        onMouseLeave={() => setShowComment(false)}></i>
                    </Popover.Target>
                    <Popover.Dropdown>{recommendation.comment}</Popover.Dropdown>
                  </Popover>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
};
export default List;
