import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { SliderPicker } from 'react-color';
import { Button, Checkbox, Group, Radio, Select, Space, TextInput, Title } from '@mantine/core';

import { useTranslations } from 'shared/translations/useTranslations';
import { DeviceType, DeviceTypeMeasurementMethod } from 'shared/types/deviceType';
import TranslationForm from 'shared/components/TranslationForm';

import DeleteDeviceType from '../DeleteDeviceType';
import { RadioGroup } from '@mantine/core/lib/Radio/RadioGroup/RadioGroup';
interface Props {
  onCreate?: (id: number | string) => void;
  form: any;
  data?: { deviceType: DeviceType };
  onSuccess: () => void;
}

const ManageDeviceTypeForm = (props: Props) => {
  const { onCreate, form, data, onSuccess } = props;
  const translations = useTranslations();
  const id = data?.deviceType?.id;
  const [color, setColor] = useState(undefined);

  useEffect(() => {
    setColor(data?.deviceType?.color);
    // if (data?.deviceType?.translations) {
    //   const translations = form.values.translations.map((translation, key) => {
    //     return {
    //       ...translation,
    //       name: get(data?.deviceType?.translations, `${translation.lang}.name`),
    //       description: get(data?.deviceType?.translations, `${translation.lang}.description`),
    //     };
    //   });
    //   form.setValues({
    //     name: data?.deviceType?.name,
    //     measurementMethod: data?.deviceType?.measurementMethod,
    //     description: data?.deviceType?.description,
    //     translations: formList(translations),
    //   });
    // } else {
    //   form.setValues({
    //     name: data?.deviceType?.name,
    //     measurementMethod: data?.deviceType?.measurementMethod,
    //     description: data?.deviceType?.description,
    //     translations: formList([
    //       { lang: 'pl', name: data?.deviceType?.name, description: data?.deviceType?.description },
    //       { lang: 'en', name: data?.deviceType?.name, description: data?.deviceType?.description },
    //     ]),
    // });
    // }
  }, [data?.deviceType]);

  const [isSaving, setSaving] = useState(false);
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        description: formValues.description,
        measurementMethod: formValues.measurementMethod,
        color,
        settings: formValues.settings || {},
        translations: {
          pl: { name: '', description: '' },
          en: { name: '', description: '' },
          de: { name: '', description: '' },
          it: { name: '', description: '' },
          ru: { name: '', description: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
        body.translations[translation.lang].description = translation.description;
      });
      setSaving(false);
      if (id) {
        await api({}).patch(`/device-types/${id}`, body);
        onSuccess();
        toast.success(translations.deviceTypes.editDeviceTypeSuccess);
      } else {
        const { data: postData } = await api({}).post(`/device-types`, body);
        toast.success(translations.deviceTypes.addDeviceTypeSuccess);
        if (onCreate) {
          onCreate(postData.id);
        }
      }
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    }
  };

  const handleColorChange = newColor => {
    if (!newColor) {
      return;
    }
    setColor(newColor.hex);
  };
  const measurementMethodData = [
    {
      label: translations.deviceTypes.measurementMethods[DeviceTypeMeasurementMethod.AMOUNT],
      value: DeviceTypeMeasurementMethod.AMOUNT,
    },
    // {label:'',value:DeviceTypeMeasurementMethod.PERCENTAGE},
    {
      label:
        translations.deviceTypes.measurementMethods[DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE],
      value: DeviceTypeMeasurementMethod.AMOUNT_PERCENTAGE,
    },
  ];
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-4">
          <Title order={5} mb={10} mt={30}>
            {translations.global.details}
          </Title>
          <TextInput label={translations.global.name} {...form.getInputProps('name')} mb={20} />
          <TextInput
            label={translations.global.description}
            {...form.getInputProps('description')}
            mb={20}
          />
          <Select
            data={measurementMethodData}
            label={translations.deviceTypes.measurementMethod}
            {...form.getInputProps('measurementMethod')}
            mb={20}
          />
          <TextInput
            label={'Prefix'}
            {...form.getInputProps('settings.prefix')}
            mb={20}
            description="Przy tworzeniu urządzeń tego typu, automatycznie zostanie dodany prefix. Moze zostac zmieniony podczas tworzenia urządzenia"
          />
          <Radio.Group label="Mobile redirection on scan device" {...form.getInputProps('settings.redirectOption')}>
            <Radio value="" label="No redirection" mb={10} />
            <Radio value="observationsModal" label="Redirect to Add Observations Modal" mb={10} />
            <Radio value="objectsModal" label="Redirect to Add Objects Modal" mb={10} />
          </Radio.Group>
          
          <SliderPicker color={color || undefined} onChangeComplete={handleColorChange} />
        </div>
        <div className="col-md-8">
          <Title order={5} mb={10}>
            {translations.global.translations}
          </Title>
          <Group mb={20}>
            <TranslationForm
              fieldsLabel={translations.global.name}
              languageKey="lang"
              translationsKey="translations"
              fieldKey="name"
              form={form}
            />
            <TranslationForm
              fieldsLabel={translations.global.description}
              languageKey="lang"
              translationsKey="translations"
              fieldKey="description"
              form={form}
            />
          </Group>
        </div>
        <Space h={30} />
      </div>
      <div className="d-flex">
        <Group>
          {
            <Button color="primary" className="ml-5" loading={isSaving} type="submit">
              {translations.global.save}
            </Button>
          }
          {id && <DeleteDeviceType id={id} />}
        </Group>
      </div>
    </form>
  );
};

export default ManageDeviceTypeForm;
