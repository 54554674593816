import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import EditInspectionActionForm from '../EditInspectionActionForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  actionId: number;
  inspectionId: number;
}

interface Data {
  action: {
    deviceTypeAction: {
      name: string;
    };
    note: string;
    amount: number;
    createdAt: string;
  };
}

const EditInspectionActionModal = (props: Props) => {
  const { refreshParent, onClose, placeId, actionId, inspectionId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      api({}).get(`/api/places/${placeId}/inspections/${inspectionId}/actions/${actionId}`),
  });

  const form = useForm({
    initialValues: {
      amount: 1,
      name: '',
      note: '',
      createdAt: undefined,
    },
    schema: zodResolver(schema),
  });

  useEffect(() => {
    if (data.action) {
      form.setValues({
        name: data.action?.deviceTypeAction?.name,
        note: data.action?.note,
        amount: Number(data.action?.amount),
        createdAt: new Date(data.action.createdAt),
      });
    }
  }, [data]);

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        note: formValues.note,
        amount: formValues.amount,
        createdAt: formValues.createdAt,
      };
      await api({}).patch(
        `/api/places/${placeId}/inspections/${inspectionId}/actions/${actionId}`,
        body,
      );
      toast.success('Akcja została zaktualizowana');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!actionId);
    if (actionId) {
      refresh();
    }
  }, [actionId]);

  return (
    <Modal opened={opened} onClose={handleClose} title={'Edytuj akcję'}>
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && <EditInspectionActionForm form={form} />}
        <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
          Edytując akcję <strong>należy pamiętać aby </strong>
          później manualnie edytować kolejne lub poprzednie akcję czy szkodniki. Inaczej wartości
          mogą się nie zgadzać. Tyczy się to również zmian wymaganych w innych przeglądach.
          <br />
          <br />
          <strong>Uwaga:</strong> Akcje czyszczenia lepa należy dodawać przed dodaniem szkodnika w
          danym przeglądzie, inaczej wpłynie to na poprawne wyliczenia statystyki
        </Alert>
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditInspectionActionModal;
