import React, { useEffect, useMemo, useState } from 'react';
import api from 'shared/services/api';
import useFetch from 'shared/hooks/useFetch';
import styles from './styles.module.scss';
import './styles.scss';
// import Card from 'shared/components/Card';
import RotateLoader from 'shared/components/RotateLoader';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { Card, Group, Text, SimpleGrid, Select, Space, MultiSelect } from '@mantine/core';
import { Doughnut } from 'react-chartjs-2';
import { useForm } from '@mantine/form';
import { getPlaceData } from './helpers';
import { DatePickerInput } from '@mantine/dates';
import StatisticsByPlace from '../StatisticsByPlace';
import { useTranslations } from 'shared/translations/useTranslations';
import { htmlLegendPlugin } from 'shared/chartjs/htmlLegend';
interface RecomendationCategory {
  id?: string;
  name: string;
}

interface State {
  categories: RecomendationCategory[];
}

interface Props {}

const Statistics = (_: Props) => {
  const translations = useTranslations();
  const {
    data,
    isLoading: isLoadingRecommendations,
    isLoaded: isLoadedRecommendations,
    refresh,
  } = useFetch<any>({
    initialValue: { recommendations: [] },
    fetchAction: () => api({}).get(`/api/recommendations`),
  });
  const {
    data: placesData,
    isLoading,
    isLoaded: isLoadedPlaces,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api({}).get(`/places`),
  });
  const {
    data: deviceTypesData,
    isLoading: isLoadingDeviceTypes,
    isLoaded: isLoadedDeviceTypes,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api({}).get(`/device-types`),
  });
  const form = useForm({
    initialValues: {
      placeId: undefined,
      dateRange: [null, null],
    },
  });

  // data.recommendations
  const byPlace = useMemo(
    () => getPlaceData(placesData, data?.recommendations),
    [placesData, data.recommendations],
  );

  const isLoadedAll = isLoadedRecommendations && isLoadedDeviceTypes && isLoadedPlaces;
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const handleSetDateRange = value => {
    form.setFieldValue('dateRange', value);
    setDateRange(value);
  };

  return (
    <div className={styles.root}>
      <ListHeading title={translations.recommendations.statistics}></ListHeading>

      <AppContent>
        {isLoading && <RotateLoader relative />}
        {isLoadedRecommendations && (
          <SimpleGrid cols={3} spacing="lg">
            <Card shadow="sm" p="lg" style={{ overflow: 'unset' }}>
              <div id="legend-container-statistics-1" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  data={{
                    labels: [...Object.keys(byPlace)],
                    datasets: [
                      {
                        label: 'Status',
                        data: [...Object.values(byPlace)],
                        backgroundColor: [
                          '#3366cc',
                          '#dc3912',
                          '#ff9900',
                          '#109618',
                          '#990099',
                          '#0099c6',
                          '#dd4477',
                          '#66aa00',
                          '#b82e2e',
                          '#316395',
                          '#3366cc',
                          '#994499',
                          '#22aa99',
                          '#aaaa11',
                          '#6633cc',
                          '#e67300',
                          '#8b0707',
                          '#651067',
                          '#329262',
                          '#5574a6',
                          '#3b3eac',
                          '#b77322',
                          '#16d620',
                          '#b91383',
                          '#f4359e',
                          '#9c5935',
                          '#a9c413',
                          '#2a778d',
                          '#668d1c',
                          '#bea413',
                          '#0c5922',
                          '#743411',
                        ].map(color => `${color}99`),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-statistics-1',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.global.facility}</Text>
              </Group>
            </Card>
          </SimpleGrid>
        )}
        <Space h="xl" />
        <SimpleGrid cols={4} spacing="lg">
          <MultiSelect
            clearable
            label={translations.global.facility}
            name="placeId"
            data={placesData?.map(place => ({
              value: String(place.id),
              label: place.name,
            }))}
            {...form.getInputProps('placeId')}
          />
          <Select
            clearable
            label={translations.global.deviceType}
            name="deviceTypeId"
            data={deviceTypesData?.map(deviceType => ({
              value: String(deviceType.id),
              label: deviceType.name,
            }))}
            {...form.getInputProps('deviceTypeId')}
          />
          <DatePickerInput
            type="range"
            label={translations.global.dateRange}
            value={dateRange}
            onChange={handleSetDateRange}
          />
        </SimpleGrid>

        <Space h="xl" />
        {isLoadedAll && (
          <>
            {form?.values?.placeId?.length > 1 && (
              <SimpleGrid cols={form.values.placeId?.length} spacing="lg">
                {form.values.placeId.map(placeId => (
                  <StatisticsByPlace
                    key={placeId}
                    placeId={placeId}
                    cols={form.values.placeId?.length === 1 ? 3 : 1}
                    placesData={placesData}
                    data={data}
                    deviceTypesData={deviceTypesData}
                    formValues={form.values}
                  />
                ))}
              </SimpleGrid>
            )}
            {(!form?.values?.placeId?.length || form?.values?.placeId?.length === 1) && (
              <StatisticsByPlace
                cols={3}
                placeId={(form?.values?.placeId && form?.values?.placeId[0]) || undefined}
                placesData={placesData}
                data={data}
                deviceTypesData={deviceTypesData}
                formValues={form.values}
              />
            )}
          </>
        )}
      </AppContent>
    </div>
  );
};
export default Statistics;
