import { Company } from './Company';

export enum UserRole {
  APPROVE_INSPECTION = 'APPROVE_INSPECTION',
  CREATE_INSPECTION = 'CREATE_INSPECTION',
}
export enum UserPlaceNotification {
  RECEIVE_INTERVENTION_PDF_REPORT = 'RECEIVE_INTERVENTION_PDF_REPORT',
  RECEIVE_INSPECTION_PDF_REPORT = 'RECEIVE_INSPECTION_PDF_REPORT',
}

export enum UserType {
  SUPER_ADMIN = 'ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  CONTROLLER = 'CONTROLLER',
  TECHNICIAN = 'USER',
}

export interface User {
  id?: number | null;
  uuid?: string | null;
  name?: string | null;
  surname?: string | null;
  email?: string | null;
  type?: string | null;
  status?: string | null;
  notificationEmail?: boolean | null;
  notificationMobile?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  companyId?: number | null;
  companyUuid?: string | null;
  company?: Company | null;
  places?: string[] | null;
  timezone: number;
  language: string;
}
