import React from 'react';
import { Line } from 'react-chartjs-2';
import uniqBy from 'lodash/uniqBy';
import { readableDate } from 'shared/utils/date';
import moment from 'moment';
import { useTranslations } from 'shared/translations/useTranslations';

const getData = data => {
  const translations = useTranslations();
  const x = data.map(y => ({
    ...y,
    date: moment(y.createdAt).format('DD/MM/YYYY'),
  }));
  const un = uniqBy(x, 'date');
  return {
    labels: un.map(({ createdAt }) => readableDate(createdAt)),
    datasets: [
      {
        label: translations.devices.rssiLevel,
        data: un.map(({ rssi }) => rssi),
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };
};
export default ({ data }) => {
  return (
    <Line
      data={getData(data)}
      height={200}
      options={{
        maintainAspectRatio: true,
      }}
    />
  );
};
