import { Badge, Popover, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { Recommendation } from 'shared/types/recommendation';
import { getColorByRecommendationStatus } from 'shared/utils/recommendation';
import styles from './styles.module.scss';
interface Props {
  mapSize: { height: number; width: number };
  markerPosition: { x: number; y: number };
  renderedMap: { height: number; width: number };
  recommendation: Recommendation;
}

const ListMapMarker = (props: Props) => {
  const { mapSize, markerPosition, renderedMap, recommendation } = props;
  const [showDetails, setShowDetails] = useState(false);
  const percentageMultiplyHeight = renderedMap.height / 2 / mapSize.height;
  const percentageMultiplyWidth = renderedMap.width / 2 / mapSize.width;
  const theme = useMantineTheme();
  const top =
    Number(markerPosition.y) >= 0
      ? (mapSize.height - Number(markerPosition.y)) * percentageMultiplyHeight
      : (mapSize.height - Number(markerPosition.y)) * percentageMultiplyHeight;
  const left =
    Number(markerPosition.x) > 0
      ? Number(markerPosition.x) * percentageMultiplyWidth + renderedMap.width / 2
      : Number(markerPosition.x) * percentageMultiplyWidth + renderedMap.width / 2;
  const history = useHistory();
  const goToDetails = () => {
    history.push(routes.recommendation.details.to(recommendation.placeId, recommendation.id));
  };
  return (
    <div
      className={styles.root}
      onClick={goToDetails}
      style={{
        top: `${top}px`,
        left: `${left}px`,
      }}>
      <Popover
        opened={showDetails}
        onClose={() => setShowDetails(false)}
        position="bottom"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={260}
        styles={{ body: { pointerEvents: 'none' } }}>
        <Popover.Target>
          <div
            onMouseEnter={() => setShowDetails(true)}
            onMouseLeave={() => setShowDetails(false)}
            className={styles.marker}
            style={{
              background: theme.colors[getColorByRecommendationStatus(recommendation.status)][9],
              top: `${top}px`,
              left: `${left}px`,
            }}></div>
        </Popover.Target>
        <Popover.Dropdown>
          {recommendation.uniqueId}{' '}
          <Badge color={getColorByRecommendationStatus(recommendation.status)}>
            {recommendation.status}
          </Badge>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
export default ListMapMarker;
