import { translationService } from 'shared/translations/translations';
import { isStrongPassword } from 'shared/utils/validation';

const validate = values => {
  const translations = translationService.getTranslations();

  return {
    newPassword: !isStrongPassword(values.newPassword) ? translations.auth.passwordRule : null,
    confirmNewPassword:
      values.newPassword !== values.confirmNewPassword ? translations.auth.passwordNoMatch : null,
  };
};

export default validate;
