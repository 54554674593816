import React from 'react';
import { Radio, TextInput, Textarea } from '@mantine/core';

import { useTranslations } from 'shared/translations/useTranslations';

const LoraDeviceForm = ({ form, loraDeviceUuid }) => {
  const translations = useTranslations();
  const handleTypeChange = value => {
    if (value === 'NEW') {
      form.setFieldValue('appEui', '0101010101010101');
    }
    if (value === 'OLD') {
      form.setFieldValue('appEui', '0000000000000000');
    }
  };

  return (
    <div>
      <TextInput
        label={translations.global.name}
        name="name"
        mb={20}
        {...form.getInputProps('name')}
      />
      <Textarea
        autosize
        label={translations.global.description}
        name="description"
        mb={20}
        {...form.getInputProps('description')}
      />
      <TextInput
        label={translations.loraDevice.devEui}
        name="devEui"
        required
        mb={20}
        {...form.getInputProps('devEui')}
        disabled={!!loraDeviceUuid}
      />
      <Radio.Group label="Typ urządzenia" mb={20} onChange={handleTypeChange} defaultValue='NEW'>
        <Radio value="OLD" label="Stare urządzenie" mb={5} mt={5}></Radio>
        <Radio value="NEW" label="Nowe urządzenie" mb={5}></Radio>
      </Radio.Group>
      <TextInput
        label={translations.loraDevice.appEui}
        name="appEui"
        // required
        mb={20}
        disabled={!!loraDeviceUuid}
        {...form.getInputProps('appEui')}
      />
    </div>
  );
};

export default LoraDeviceForm;
