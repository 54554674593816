import { Anchor, Box, Button, Group, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import enterpriseClientService from './enterpriseClient.service';
import { useSelector } from 'react-redux';
import { useTranslations } from '../../../../shared/translations/useTranslations';

const EnterpriseClient = () => {
  const translations = useTranslations();
  const enterprise = useSelector(state => state.auth.data.enterprise);

  const [opened, setOpened] = useState(false);
  const form = useForm({
    initialValues: {
      enterprise: undefined,
    },

    validate: {},
  });
  const handleSubmit = values => {
    enterpriseClientService.set(values.enterprise);
    setOpened(false);
    form.reset();
  };
  useEffect(() => {
    if (opened) {
      form.setValues({ enterprise: enterpriseClientService.name });
    }
  }, [opened]);
  if (enterprise.isDomainClient) {
    return null;
  }
  return (
    <>
      <Modal opened={opened} onClose={() => setOpened(false)} title={translations.enterprise.title}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label={translations.enterprise.identifierOfTheCompany}
            placeholder="client_one"
            {...form.getInputProps('enterprise')}
          />

          <Group position="right" mt="md">
            <Button type="submit">{translations.global.save}</Button>
          </Group>
        </form>
      </Modal>
      <Group position="center">
        <Text color="white" align="center">
          <br />
          {translations.enterprise.areYouClient}{' '}
          <Anchor
            style={{ width: '100%', display: 'inline-block' }}
            onClick={() => setOpened(true)}>
            {translations.enterprise.clickHere}
          </Anchor>
        </Text>
      </Group>
    </>
  );
};

export default EnterpriseClient;
