import React, { useEffect, useState } from 'react';
import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';

import apiService from '../../../../shared/services/api';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
  fileId: string;
}

const DeleteCompanyFileModal = (props: Props) => {
  const { companyId, fileId } = props;
  const { onClose, opened } = props;

  const { data, isLoading, refresh } = useFetch<any>({
    initialValue: { category: undefined },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/companies/${companyId}/files/${fileId}`),
  });

  useEffect(() => {
    if (fileId) {
      refresh();
    }
  }, [fileId]);

  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const handleDelete = async () => {
    try {
      setSaving(true);
      const { data } = await apiService({}).delete(`/companies/${props.companyId}/files/${fileId}`);
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };

  const translations = useTranslations();
  return (
    <Modal opened={opened} onClose={onClose} title={translations.global.deleteFile}>
      <LoadingOverlay visible={isLoading} />
      {translations.files.deleteConfirmation} {data.name}
      <Group position="right" mt="md">
        <Button onClick={onClose} variant="outline" type="button">
          {translations.global.cancel}
        </Button>
        <Button type="submit" loading={isSaving} onClick={handleDelete} color="red">
          {translations.global.delete}
        </Button>
      </Group>
    </Modal>
  );
};
export default DeleteCompanyFileModal;
