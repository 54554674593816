import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button } from '@mantine/core';
import { Trash } from 'tabler-icons-react';
import apiService from '../../../../shared/services/api';

const UnassignObjectFromDeviceType = ({ id, onFinish, deviceTypeId }) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const onDelete = async () => {
    try {
      setLoading(true);
      setOpen(false);
      await apiService({}).delete(`/device-types/${deviceTypeId}/objects/${id}`);
      setLoading(false);
      onFinish();
      toast.success(translations.deviceTypes.deleteObjectSuccess);
    } catch {
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        paragraph={
          <div>
            {translations.deviceTypes.deleteObjectDesc}
            <br />
          </div>
        }
        isLoading={isLoading}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        title={translations.deviceTypes.deleteObjectTitle}
        confirmLabel={translations.global.delete}
        cancelLabel={translations.global.cancel}
      />
      <Button color="red" onClick={() => setOpen(true)} loading={isLoading} size="xs" compact>
        <Trash size={14} />
      </Button>
    </>
  );
};

export default UnassignObjectFromDeviceType;
