import React, { useState, useEffect } from 'react';

import { ActionIcon, Group, LoadingOverlay } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Pencil } from 'tabler-icons-react';
import AppContent from 'shared/components/AppContent';
import ListHeading from 'shared/components/ListHeading';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import EditLimitModal from './EditLimitModal';
import { useIsSuperAdmin } from '../../../../shared/hooks/useIsSuperAdmin';
import { useSelector } from 'react-redux';
import { USER, USER_CONTROLLER } from '../../../../shared/constants/user';

interface Props {
  placeId: string | number;
}

function Limits({ placeId }: Props) {
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    deviceTypes: any[];
  }>({
    initialValue: { deviceTypes: [] },
    fetchAction: () => apiService({}).get(`/api/places/${placeId}/device-types`),
  });
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>();
  const handleRefresh = () => {
    refresh();
  };
  const handleModalClose = () => {
    setSelectedEditId(undefined);
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data.deviceTypes);
  }, [data.deviceTypes]);
  const isSuperAdmin = useIsSuperAdmin();
  const userType = useSelector(state => state.auth.data.userType);
  const readOnly = [USER_CONTROLLER, USER].includes(userType);
  return (
    <div style={{ position: 'relative' }}>
      <EditLimitModal
        placeId={placeId}
        deviceTypeId={selectedEditId}
        onClose={handleModalClose}
        refreshParent={handleRefresh}
      />
      <ListHeading title={translations.limits.title}></ListHeading>
      <AppContent>
        <LoadingOverlay visible={isLoading} />
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>{translations.global.name}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.deviceTypes?.map(limit => {
              return (
                <Tr key={limit.id}>
                  <Td>{limit.name}</Td>
                  <Td>
                    <Group ml="auto">
                      {/* {!isSuperAdmin && ( */}
                      <ActionIcon
                        variant="transparent"
                        onClick={() => {
                          setSelectedEditId(limit.id);
                        }}>
                        <Pencil size={16} />
                      </ActionIcon>
                      {/* )} */}
                    </Group>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
}

export default Limits;
