import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ListHeading from '../../../../shared/components/ListHeading';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import AddDeviceTypeObservation from '../../../DeviceType/containers/AddDeviceTypeObservation';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import apiService from '../../../../shared/services/api';
import EditDeviceTypeObservationModal from '../../../DeviceType/containers/EditDeviceTypeObservationModal';
import { Button, Group } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import DeleteDeviceTypeObservation from '../../../DeviceType/containers/DeleteDeviceTypeObservation';
// import EditDeviceTypeObservationModal from '../../../DeviceType/containers/EditDeviceTypeObservationModal';

function AdminDevicesObservations() {
  const translations = useTranslations();
  const history = useHistory();
  const reset = () => {
    fetchDetails();
  };
  const [observationToEdit, setObservationToEdit] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [deleteObservation, setDeleteObservation] = useState();

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(
        `/api/v3/device-type-observations?parentCompanyId=ROOT`,
      );
      setData(data.observations);
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <DeleteDeviceTypeObservation
        id={deleteObservation?.id}
        isOpen={!!deleteObservation}
        onFinish={reset}
        close={() => {
          setDeleteObservation(undefined);
        }}
      />
      <EditDeviceTypeObservationModal
        id={observationToEdit?.id}
        // observation={observationToEdit}
        onFinish={reset}
        close={() => setObservationToEdit(undefined)}
      />
      <ListHeading title={translations.global.observations}>
        <AddDeviceTypeObservation onFinish={reset} />
      </ListHeading>

      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>{translations.global.name}</Th>
            <Th>{translations.global.type}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(observation => (
            <Tr key={observation.id}>
              <Td>{observation.name}</Td>
              <Td>
                <td>{translations.deviceTypes.observationTypes[observation.type]}</td>
              </Td>
              <Td>
                <Group>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setObservationToEdit(observation);
                    }}
                    compact
                    size="xs"
                    color="blue">
                    <Pencil size={14} />
                  </Button>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDeleteObservation(observation);
                    }}
                    compact
                    size="xs"
                    color="red">
                    <Trash size={14} />
                  </Button>
                </Group>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default AdminDevicesObservations;
