import { Button, Card, Center, Container, Group, Loader, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { VisualInspectionQuestionModel } from 'modules/Admin/VisualInspection/VisualInspection';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';

interface Data {
  questions: VisualInspectionQuestionModel[];
}

interface FormValues {
  questions: { id: string; use: boolean }[];
}

const PlaceVisualInspection = props => {
  const [isSaving, setSaving] = useState(false);
  const { placeId } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { question: {} },
    fetchAction: () => apiService({}).get(`/api/v3/places/visual-inspection/questions`),
  });
  const {
    data: placeQuestions,
    isLoaded: isPlaceQuestionsLoaded,
    isLoading: isPlaceQuestionsLoading,
  } = useFetch<Data>({
    initialValue: { question: {} },
    fetchAction: () => apiService({}).get(`/api/v3/places/${placeId}/visual-inspection/questions`),
  });
  const form = useForm({
    initialValues: {
      questions: [],
    },
  });
  const handleSubmit = async (values: FormValues) => {
    try {
      setSaving(true);
      const ids = values.questions.filter(q => q.use).map(q => q.id) || [];
      await apiService({}).put(`/api/v3/places/${placeId}/visual-inspection/questions`, {
        questions: ids,
      });
      setSaving(false);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  useEffect(() => {
    if (isLoaded && isPlaceQuestionsLoaded) {
      const ids = placeQuestions?.questions?.map(q => q.id) || [];
      const list =
        data?.questions?.map(question => ({ id: question.id, use: ids?.includes(question.id) })) ||
        [];
      form.setValues({ questions: list });
    }
  }, [placeQuestions.questions, data.questions, isPlaceQuestionsLoaded, isLoaded]);
  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ListHeading title={translations.global.visualInspection}>
          <Button type="submit" loading={isSaving}>
            {translations.global.save}
          </Button>
        </ListHeading>
        <AppContent>
          {(isLoading || isPlaceQuestionsLoading) && (
            <Center mt={30} mb={30}>
              <Loader />
            </Center>
          )}
          {isPlaceQuestionsLoaded && isLoaded && (
            <div>
              {data?.questions?.map((question, index) => {
                return (
                  <div key={question.id}>
                    <Group>
                      <Switch
                        checked={form.values?.questions[index]?.use}
                        {...form.getInputProps(`questions.${index}.use`)}
                      />
                      {index + 1}. {question.question}
                    </Group>
                  </div>
                );
              })}
            </div>
          )}
        </AppContent>
      </form>
    </div>
  );
};
export default PlaceVisualInspection;
