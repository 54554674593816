import React, { useState } from 'react';
import * as yup from 'yup';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useForm } from '@mantine/form';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {
  onClose: () => any;
  refreshParent: () => any;
}
const Create = (props: Props) => {
  const { refreshParent, onClose } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      question: undefined,
      comment: undefined,
      defaultAnswer: undefined,
      translations: [
        { lang: 'pl', question: undefined },
        { lang: 'en', question: undefined },
        { lang: 'de', question: undefined },
        { lang: 'it', question: undefined },
        { lang: 'ru', question: undefined },
      ],
    },
  });
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        question: formValues.question,
        comment: formValues.comment,
        defaultAnswer: formValues.defaultAnswer,
        translations: {
          pl: { question: '' },
          en: { question: '' },
          de: { question: '' },
          it: { question: '' },
          ru: { question: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].question = translation.question;
      });
      await api({}).post(`/api/visual-inspection/questions`, body);
      toast.success(translations.visualInspection.questionAddedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={handleClose}
        title={translations.visualInspection.addQuestion}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={() => setOpened(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>
      <Group position="center">
        <Button onClick={() => setOpened(true)}>{translations.visualInspection.addQuestion}</Button>
      </Group>
    </>
  );
};

export default Create;
