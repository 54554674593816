import includes from 'lodash/includes';
import { useSelector } from 'react-redux';

import { UserType } from 'shared/types/User';
import { COMPANY_ADMIN, SUPER_ADMIN, USER, USER_CONTROLLER } from 'shared/constants/user';
import { translationService } from 'shared/translations/translations';

export const isSuperAdminByAuthState = auth => auth.data.userType === SUPER_ADMIN;
export const isCompanyAdminByAuthState = auth => auth.data.userType === COMPANY_ADMIN;
export const isControllerByAuthState = auth => auth.data.userType === USER_CONTROLLER;
export const isTechnicianByAuthState = auth => auth.data.userType === USER;

export const isAnyAdminByAuthState = auth =>
  includes([SUPER_ADMIN, COMPANY_ADMIN], auth.data.userType);

export const getUserTypeLabel = type => {
  const translations = translationService.getTranslations();
  const label = translations.users.types[type];
  return label || '-';
};

export const getUserStatusLabel = status => {
  const translations = translationService.getTranslations();
  const values = {
    ACTIVE: translations.global.active,
    BLOCKED: translations.global.inactive,
  };
  return values[status] || status;
};

export const getUserStatuses = () => {
  const translations = translationService.getTranslations();
  return [
    { label: translations.global.active, value: 'ACTIVE' },
    { label: translations.global.inactive, value: 'BLOCKED' },
  ];
};

export const getUserTypes = () => {
  return [
    { label: getUserTypeLabel(USER), value: USER },
    { label: getUserTypeLabel(USER_CONTROLLER), value: USER_CONTROLLER },
    { label: getUserTypeLabel(COMPANY_ADMIN), value: COMPANY_ADMIN },
  ];
};

export const getUserTypesByAdmin = () => {
  return [
    { label: getUserTypeLabel(USER), value: USER },
    { label: getUserTypeLabel(USER_CONTROLLER), value: USER_CONTROLLER },
    { label: getUserTypeLabel(COMPANY_ADMIN), value: COMPANY_ADMIN },
    { label: getUserTypeLabel(SUPER_ADMIN), value: SUPER_ADMIN },
  ];
};

export const getUserTypeSelectData = isSuperAdmin => {
  const userTypes = isSuperAdmin
    ? Object.values(UserType)
    : Object.values(UserType).filter(value => value !== UserType.SUPER_ADMIN);
  return userTypes.map(userType => {
    return {
      value: userType,
      label: getUserTypeLabel(userType),
    };
  });
};

export const selectIsCompanyAdmin = () =>
  //@ts-ignore
  useSelector(state => isCompanyAdminByAuthState(state.auth));
//@ts-ignore
export const selectIsSuperAdmin = () => useSelector(state => isSuperAdminByAuthState(state.auth));
//@ts-ignore
export const selectIsAnyAdmin = () => useSelector(state => isAnyAdminByAuthState(state.auth));
//@ts-ignore
export const selectIsControllerByAuthSate = () => useSelector(state => isControllerByAuthState(state.auth));