import React, { useState } from 'react';
import { Popover, Checkbox } from '@mantine/core';
import { uniqBy } from 'lodash';
import styles from './styles.module.scss';

const Filter = ({ title, options, onChange, defaultValue }) => {
  const [opened, setOpened] = useState(false);
  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      width={260}
      position="bottom"
      withArrow>
      <Popover.Target>
        <span
          onClick={() => setOpened(o => !o)}
          className={`${styles.filterLabel} ${
            defaultValue?.length ? styles.filterLabelActive : ''
          }`}>
          <i className={`${styles.filterLabelIcon} fa fa-filter`}></i>
          {title}
        </span>
      </Popover.Target>
      <Popover.Dropdown>
        <div style={{ display: 'flex' }}>
          <Checkbox.Group
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            defaultValue={defaultValue || []}
            // label="Filtruj "
            // description="This is anonymous"
            onChange={onChange}
            required>
            {uniqBy(options || [], 'value').map(option => (
              <Checkbox key={option.value} label={option.label} value={String(option.value)} />
            ))}
          </Checkbox.Group>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Filter;
