import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FieldWrapper from '../FieldWrapper';
import './styles.scss';

class TextField extends PureComponent {
  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
  setRef = ref => {
    this.ref = ref;
  };
  render() {
    const {
      input,
      label,
      meta,
      noMarginTop,
      noMargin,
      className,
      disabled,
      labelLeft,
      iconLeft,
      icon,
      tooltip,
      setRef,
      isRequired,
      hiddenDisabled,
      isLoading,
      multiLine,
      ...props
    } = this.props;

    const inputClasses = classnames('TextField__input', {
      'TextField__input--disabled': disabled && !hiddenDisabled,
      'TextField__input--icon': icon,
      'TextField__input--required': isRequired,
    });
    const Component = multiLine ? 'textarea' : 'input';
    if (multiLine && this.ref) {
      this.ref.style.height = 'auto';
      // this.ref.style.height = this.ref.scrollHeight + 'px';
    }
    return (
      <FieldWrapper {...this.props} onClick={this.onClick} isLoading={isLoading}>
        <Component
          {...input}
          value={input.value}
          id={input.name}
          {...props}
          className={inputClasses}
          disabled={disabled}
          ref={this.setRef}
        />
      </FieldWrapper>
    );
  }
}

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOf(['scope', 'arrow_down']),
};

export default TextField;
