import React from 'react';
import { Route, Switch } from 'react-router';
import { routes } from 'shared/constants/routes';
import PlaceDevices from './List';
import PlaceDevicesCreate from './Create';
import PlaceDevicesDetails from './Details';

export default () => (
  <Switch>
    <Route
      path={routes.places.devices.default.path}
      key="places-devices"
      component={PlaceDevices}
      exact
    />
    <Route
      path={routes.places.devices.create.path}
      key="places-device-create"
      component={PlaceDevicesCreate}
      exact
    />
    <Route
      path={routes.places.devices.details.path}
      key="places-device-manage"
      component={PlaceDevicesDetails}
      exact
    />
  </Switch>
);
