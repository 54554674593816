import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import map from 'lodash/map';
import get from 'lodash/get';
import { Trash } from 'tabler-icons-react';
import { ActionIcon, Group, LoadingOverlay, Title, Paper } from '@mantine/core';
import { useSelector } from 'react-redux';

import { readableDate } from 'shared/utils/date';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Observation } from 'shared/types/observations';
import { isAnyAdminByAuthState } from 'shared/utils/user';

import { observationAmountByData } from './helpers';
import EditObservationModal from '../EditObservationModal';
import DeleteObservationModal from '../DeleteObservationModal';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

interface Data {
  observations: Observation[];
}

const DeviceObservations = ({ deviceId, deviceTypeId }) => {
  const {
    data: dataActions,
    isLoading,
    isLoaded,
    error,
    refresh,
  } = useFetch<Data>({
    initialValue: { action: {} },
    fetchAction: () => apiService({}).get(`/api/v3/devices/${deviceId}/observations`),
  });
  const isAdmin = useSelector(state => isAnyAdminByAuthState(state.auth));
  const classes = useStyles();
  const translations = useTranslations();
  const [editActionId, setEditActionId] = useState(undefined);
  const [deleteActionId, setDeleteActionId] = useState(undefined);

  const handleClose = () => {
    setEditActionId(undefined);
    setDeleteActionId(undefined);
  };
  const handleRefreshAction = () => {
    setEditActionId(undefined);
    setDeleteActionId(undefined);
    refresh();
  };

  const handleOnEditAction = (id: string) => () => {
    setEditActionId(id);
  };
  const handleOnDeleteAction = (id: string) => () => {
    setDeleteActionId(id);
  };
  return (
    <div>
      <EditObservationModal
        observationId={editActionId}
        deviceId={deviceId}
        deviceTypeId={deviceTypeId}
        refreshParent={handleRefreshAction}
        onClose={handleClose}
      />

      <DeleteObservationModal
        observationId={deleteActionId}
        deviceId={deviceId}
        refreshParent={handleRefreshAction}
        onClose={handleClose}
      />

      <Title order={3} mb={20}>
        {translations.global.observations}
      </Title>
      <div style={{ position: 'relative', minHeight: '300px' }}>
        <Paper className={classes.root} shadow='xs'>
          <LoadingOverlay visible={isLoading} />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.devices.amount}</TableCell>
                <TableCell>{translations.devices.action}</TableCell>
                <TableCell>{translations.global.user}</TableCell>
                <TableCell>{translations.devices.date}</TableCell>
                <TableCell>{translations.devices.note}</TableCell>
                <TableCell>{translations.devices.inspection}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isLoaded && (
              <TableBody>
                {map(dataActions.observations, row => (
                  <TableRow key={`action-${row.id}`}>
                    <TableCell>{observationAmountByData(row)}</TableCell>
                    <TableCell>{get(row, 'deviceTypeObservation.name')}</TableCell>
                    <TableCell>
                      {get(row, 'user.name')} {get(row, 'user.surname')}
                    </TableCell>
                    <TableCell>{readableDate(row.createdAt)}</TableCell>
                    <TableCell>{row.note}</TableCell>
                    <TableCell>{row.inspection?.name || '-'}</TableCell>
                    <TableCell>
                      {isAdmin && (
                        <Group>
                          {/* <ActionIcon variant="transparent" onClick={handleOnEditAction(row.id)}>
                          <Pencil size={16} />
                        </ActionIcon> */}
                          <ActionIcon variant="transparent" onClick={handleOnDeleteAction(row.id)}>
                            <Trash size={16} />
                          </ActionIcon>
                        </Group>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </div>
    </div>
  );
};

export default DeviceObservations;
