import { translationService } from 'shared/translations/translations';
import { isStrongPassword } from 'shared/utils/validation';

const validate = values => {
  const translations = translationService.getTranslations();

  return {
    timezone: !values.timezone ? translations.validationRules.requiredField : null,
    language: !values.language ? translations.validationRules.requiredField : null,
  };
};

export default validate;
