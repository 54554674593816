import React from 'react';
import classnames from 'classnames';
import RotateLoader from '../RotateLoader';
import './styles.scss';
export default ({
  states: { isLoading, isLoaded, hasError },
  children,
  isNew
}) => {
  const contentClasses = classnames('ContentLoader__content', {
    'ContentLoader__content--active': isLoaded || isNew
  });

  return (
    <div className="ContentLoader">
      <div className={contentClasses}>{children}</div>

      {isLoading && (
        <div className="ContentLoader__loader">
          <RotateLoader relative />
        </div>
      )}
      {hasError && <div className="ContentLoader__error" />}
    </div>
  );
};
