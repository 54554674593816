import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import { Paper, Tabs, Tab, Select, Button, Flex } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useHistory } from 'react-router';

import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import { routes } from 'shared/constants/routes';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { USER_CONTROLLER } from 'shared/constants/user';
import { useTranslations } from 'shared/translations/useTranslations';

import { fetchReports, getPdfTemporaryUrl, deleteReport } from '../../services/reportService';
import { fetchPlaces } from '../../reducers/report';
import GenerateReportV2 from '../GenerateV2';

const List = props => {
  const [activeTab, setActiveTab] = useState('reports');
  const translations = useTranslations();
  const [reports, setReports] = useState([]);
  const [isLoadingReports, setLoadingReports] = useState(false);
  const userType = useSelector(state => state.auth.data.userType);

  const history = useHistory();
  const placesState = useSelector(state => state.reports.report.places);
  const reportState = useSelector(state => state.reports.report.report);
  const isLoading =
    placesState.states.isLoading || reportState.states.isLoading || isLoadingReports;
  const form = useForm({
    initialValues: {
      placeUuid: undefined,
    },
  });
  const formValues = form.values;

  const onFetchReports = useCallback(async () => {
    if (!formValues.placeUuid) {
      return;
    }
    try {
      setLoadingReports(true);
      const {
        data: { reports },
      } = await fetchReports({ placeUuid: formValues.placeUuid });
      setReports(reports);
    } catch (e) {
    } finally {
      setLoadingReports(false);
    }
  }, [formValues]);

  const places = useMemo(
    () =>
      map(placesState.data, place => ({
        value: String(place.uuid),
        label: place.name,
      })),
    [placesState],
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPlaces({}));
  }, [dispatch]);
  useEffect(() => {
    if (!formValues.placeUuid) {
      return;
    }
    onFetchReports();
  }, [dispatch, formValues, onFetchReports]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.placeUuid) {
      form.setFieldValue('placeUuid', String(params.placeUuid));
    } else if (places[0]) {
      form.setFieldValue('placeUuid', String(places[0].value));
    }
    if (params.tab) {
      setActiveTab(params.tab);
    }
  }, [places]);

  const onDownloadPdf = report => async () => {
    const {
      data: { url },
    } = await getPdfTemporaryUrl(report.uuid);
    await saveFileFromUrl(
      url,
      `${get(report, 'place.company.name')}_${get(report, 'place.name')}_${readableDate(
        report.createdAt,
      )}`.toLowerCase(),
    );
  };
  const onDelete = report => async () => {
    await deleteReport(report.id);
    dispatch(fetchPlaces({}));
  };

  const goToGenerate = () => {
    history.push(routes.reports.generate.path);
  };

  const getDate = report => {
    if (report.placeInspection) {
      return `${readableDate(report.placeInspection.startedAt)} - ${readableDate(
        report.placeInspection.finishedAt,
      )}`;
    }

    return `${readableDateWithoutTime(report.dateFrom)} - ${readableDateWithoutTime(
      report.dateTo,
    )}`;
  };

  const filteredReports =
    filter(reports, report =>
      activeTab === 'reports' ? !report.placeInspectionId : report.placeInspectionId,
    ) || [];

  const [generateReportModal, setGenerateReportModal] = useState(false);

  return (
    <div>
      <GenerateReportV2
        refreshParent={() => {
          onFetchReports();
        }}
        defaultPlaceUuid={form.values.placeUuid}
        onClose={() => {
          setGenerateReportModal(false);
        }}
        open={generateReportModal}
      />
      <ListHeading title={translations.reports.reports}>
        <Flex align="end" justify="center" gap={16}>
          <Select
            {...form.getInputProps('placeUuid')}
            name="placeUuid"
            label={translations.global.facility}
            data={places}
            searchable
          />

          <Button onClick={goToGenerate}>{translations.reports.generateReport}</Button>
          <Button
            variant="outline"
            ml={10}
            onClick={() => {
              setGenerateReportModal(true);
            }}>
            Pobierz plik XLSX
          </Button>
        </Flex>
      </ListHeading>
      <AppContent>
        <Paper shadow="xs">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List mb={20}>
              <Tabs.Tab style={{ padding: '25px' }} value="reports">
                {translations.reports.reports}
              </Tabs.Tab>
              <Tabs.Tab style={{ padding: '25px' }} value="inspections">
                {translations.reports.generalInspection}
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Paper>
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>{translations.global.dateRange}</Th>
              <Th>{translations.global.company}</Th>
              <Th>{translations.global.facility}</Th>
              <Th>{translations.global.author}</Th>
              <Th style={{ maxWidth: '100px' }}>{translations.global.note}</Th>
              <Th>{translations.global.actions}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {get(filteredReports, 'length') === 0 && (
              <Tr>
                <Td colSpan="5">{translations.reports.noReports}</Td>
              </Tr>
            )}
            {map(filteredReports, report => (
              <Tr key={report.uuid}>
                <Td>{getDate(report)}</Td>
                <Td>{get(report, 'place.company.name')}</Td>
                <Td>{get(report, 'place.name')}</Td>
                <Td>
                  {report.placeInspectionId
                    ? `${get(report, 'placeInspection.user.name')} ${get(
                        report,
                        'placeInspection.user.surname',
                      )}`
                    : `${get(report, 'user.name')} ${get(report, 'user.surname')}`}
                </Td>
                <Td>
                  <div style={{ maxWidth: '100px' }}>
                    {(get(report, 'note') || '').substring(0, 120)}
                  </div>
                </Td>
                <Td>
                  <div style={{ display: 'flex' }}>
                    <Button onClick={onDownloadPdf(report)} type="button" size="xs" mr={10}>
                      {translations.global.download}
                    </Button>
                    {userType !== USER_CONTROLLER && (
                      <Button
                        onClick={onDelete(report)}
                        type="button"
                        size="xs"
                        variant="outline"
                        color="red">
                        {translations.global.delete}
                      </Button>
                    )}
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
};
const currentDate = new Date();
export default List;
