import React, { useMemo } from 'react';
import { useForm } from '@mantine/form';
import { Grid, Select } from '@mantine/core';

import Schedule from 'containers/Schedule';
import { ScheduleEvent } from 'containers/Schedule/types';

import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';
import { Place } from 'shared/types/Place';
import api from 'shared/services/api';

import styles from './styles.module.scss';
import { useAllPlacesList } from 'queries/places';

const Events = () => {
  const form = useForm({
    initialValues: { placeUuid: undefined },
  });

  const { data: fetchedEvents } = useFetch<{ scheduleEvents: ScheduleEvent[] }>({
    initialValue: { scheduleEvents: [] },
    fetchAction: () => api({}).get(`/api/schedule/events`),
  });

  const events = useMemo(() => {
    if (!form.values.placeUuid) {
      return fetchedEvents.scheduleEvents;
    }

    return fetchedEvents.scheduleEvents.filter(
      (event: ScheduleEvent) => event.placeUuid === form.values.placeUuid,
    );
  }, [fetchedEvents, form.values.placeUuid]);

  const { data: placeList = [] } = useAllPlacesList();
  const translations = useTranslations();

  const selectedPlace = placeList.find(place => place.uuid === form.values.placeUuid);
  const placesOptions = placeList.map(place => ({
    value: place.uuid,
    label: place.name,
  }));

  return (
    <div className={styles.container}>
      <Grid>
        <Grid.Col span={5} mb={20}>
          <Select
            data={placesOptions}
            searchable
            placeholder=""
            label={translations.global.facility}
            clearable
            itemComponent={({ label, ...others }) => (
              <div
                {...others}
                onClick={e => e.stopPropagation()}
                onMouseEnter={e => {
                  e.stopPropagation();
                  others.onMouseEnter(e);
                }}
                onMouseDown={e => {
                  e.stopPropagation();
                  others.onMouseDown(e);
                }}
                style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 10 }}>{label}</div>
              </div>
            )}
            {...form.getInputProps('placeUuid')}
          />
        </Grid.Col>
      </Grid>
      <Schedule place={selectedPlace} fetchedEvents={events} />
    </div>
  );
};

export default Events;
