import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteDeviceType } from 'modules/DeviceType/services/deviceTypeService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button } from '@mantine/core';
// import './styles.scss';

const DeleteDeviceType = ({ id }) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState();
  const [isLoading, setLoading] = useState();
  const history = useHistory();
  const onDelete = async () => {
    try {
      setLoading(true);
      setOpen(false);
      await deleteDeviceType(id);
      setLoading(false);
      history.push(routes.deviceTypes.list.path);
      toast.success(translations.deviceTypes.deleteDeviceTypeSuccess);
    } catch {
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        paragraph={
          <div>
            {translations.deviceTypes.deleteDeviceTypeDesc}
            <br />
          </div>
        }
        isLoading={isLoading}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        title={translations.deviceTypes.deleteDeviceTypeTitle}
        confirmLabel={translations.global.delete}
        cancelLabel={translations.global.cancel}
      />
      <Button color="red" type="button" onClick={() => setOpen(true)} isLoading={isLoading}>
        {translations.global.delete}
      </Button>
    </>
  );
};

export default DeleteDeviceType;
