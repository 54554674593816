import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import EditInspectionObservationForm from '../EditInspectionObservationForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';
import { DeviceType, DeviceTypeObservationType } from '../../../../../shared/types/deviceType';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  inspectionDeviceId: number;
  inspectionId: number;
  mixturesById: any;
}

interface Data {
  deviceType: DeviceType;
}

const CreateInspectionObservationModal = (props: Props) => {
  const { refreshParent, onClose, placeId, inspectionDeviceId, inspectionId, mixturesById } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      api({}).get(
        `/api/places/${placeId}/inspections/${inspectionId}/devices/${inspectionDeviceId}`,
        // `/api/places/${placeId}/inspections/${inspectionId}/devices/${inspectionDeviceId}`,
      ),
  });

  const form = useForm({
    initialValues: {
      name: '',
      amount: 1,
      note: '',
      type: DeviceTypeObservationType.NEUTRAL,
      amountPercentage: 0,
      mixtures: {},
      deviceTypeObservationId: '',
    },
    schema: zodResolver(schema),
  });

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    try {
      setSaving(true);
      const selectedDeviceTypeObservation = data?.deviceType?.deviceTypeObservations?.find(
        observation => observation.id === form.values?.deviceTypeObservationId,
      );
      const type = selectedDeviceTypeObservation.type;
      let body = {
        amountPercentage: formValues.amountPercentage,
        type,
        mixtures: formValues.mixtures,
        totalMixtures: formValues.totalMixtures,
        deviceTypeObservationId: formValues.deviceTypeObservationId,
      };

      if (type === DeviceTypeObservationType.BAIT_COLLECT) {
        body.amountPercentage = Number(formValues.amountPercentage);
      }
      if (type === DeviceTypeObservationType.REFILL) {
        const updated = updateMixtures(formValues.mixtures);
        body.mixtures = updated.updatedMixtures;
        body.totalMixtures = updated.totalMixtures;
      }

      // console.log('body', body);
      // return;
      await api({}).post(
        `/api/places/${placeId}/inspections/${inspectionId}/devices/${inspectionDeviceId}/observations`,
        body,
      );
      toast.success('Obserwacja została dodana');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!inspectionDeviceId);
    if (!inspectionDeviceId) {
      form.reset();
    }
    if (inspectionDeviceId) {
      // { [key:string]: {value:number}}
      const initialMixtures = Object.values(mixturesById).reduce((acc, mixture) => {
        acc[mixture.id] = {
          value: 0,
          mixtureId: mixture.id,
          grammage: mixture.grammage,
          totalValue: 0,
        };
        return acc;
      }, {} as { [key: string]: { value: number; mixtureId: string; grammage: number; totalValue: number } });

      form.setValues({ mixtures: initialMixtures });
      refresh();
    }
  }, [inspectionDeviceId]);

  return (
    <Modal opened={opened} onClose={handleClose} title={'Dodaj obserwację'}>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={form.onSubmit(onSubmit)} style={{ minHeight: 400 }}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && (
          <EditInspectionObservationForm
            form={form}
            mixturesById={mixturesById}
            isCreating
            deviceTypeObservations={
              data?.deviceType?.deviceTypeObservations?.map(dta => ({
                label: dta.name,
                value: dta.id,
                type: dta.type,
              })) || []
            }
          />
        )}
        <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
          Dodając nową obserwację <strong>należy pamiętać aby </strong>
          później manualnie edytować kolejne lub poprzednie obserwację. Inaczej wartości mogą się nie zgadzać.
          Tyczy się to również zmian wymaganych w innych przeglądach.
          <br />
          <br />
          Podczas dodania obserwacji , <strong>data</strong> jej jest ustawiona na datę stworzenia przeglądu. Później można ją edytować. 
        </Alert>
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!form.values.deviceTypeObservationId}>
            {translations.global.add}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

const updateMixtures = (mixtures: {
  [key: string]: { grammage: number; mixtureId: string; totalValue: number; value: number };
}) => {
  // Tworzymy nowy obiekt zaktualizowany na podstawie oryginalnych danych
  const updatedMixtures = Object.entries(mixtures).reduce((acc, [key, mixture]) => {
    // Obliczamy totalValue na podstawie value i grammage
    acc[key] = { ...mixture, totalValue: mixture.value * mixture.grammage };
    return acc;
  }, {} as { [key: string]: { grammage: number; mixtureId: string; totalValue: number; value: number } });
  // if value is 0, remove it
  Object.entries(updatedMixtures).forEach(([key, mixture]) => {
    if (mixture.value === 0) {
      delete updatedMixtures[key];
    }
  });
  // Obliczamy totalMixtures, sumując totalValue ze wszystkich elementów
  const totalMixtures = Object.values(updatedMixtures).reduce(
    (sum, { totalValue }) => sum + totalValue,
    0,
  );

  // Zwracamy zaktualizowaną kopię mixtures i totalMixtures
  return { updatedMixtures, totalMixtures };
};

export default CreateInspectionObservationModal;
