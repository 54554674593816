import React, { useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';

interface Props {
  onClose: () => any;
  refreshParent: () => any;
}
const Create = (props: Props) => {
  const { refreshParent, onClose } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      grammage: undefined,
      type: 'BAIT',
      translations: [
        { lang: 'pl', name: undefined, description: undefined },
        { lang: 'en', name: undefined, description: undefined },
        { lang: 'de', name: undefined, description: undefined },
        { lang: 'it', name: undefined, description: undefined },
        { lang: 'ru', name: undefined, description: undefined },
      ],
    },
  });
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        description: formValues.description,
        type: formValues.type,
        grammage: formValues.grammage,
        translations: {
          pl: { name: '', description: '' },
          en: { name: '', description: '' },
          de: { name: '', description: '' },
          it: { name: '', description: '' },
          ru: { name: '', description: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
        body.translations[translation.lang].description = translation.description;
      });
      await api({}).post(`/api/v3/admin/mixtures`, body);
      toast.success(translations.mixtures.mixtureAddedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <>
      <Modal opened={opened} onClose={handleClose} title={translations.mixtures.addMixture}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={() => setOpened(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>
      <Group position="center">
        <Button onClick={() => setOpened(true)}>{translations.mixtures.addMixture}</Button>
      </Group>
    </>
  );
};

export default Create;
