import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteDeviceTypeObservation } from 'modules/DeviceType/services/deviceTypeService';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';

const DeleteDeviceTypeObservation = ({ id, onFinish, close, isOpen }) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState();
  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteDeviceTypeObservation(id);
      close();
      setLoading(false);
      onFinish();
      toast.success(translations.deviceTypes.deleteObservationSuccess);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      paragraph={
        <div>
          {translations.deviceTypes.deleteObservationDesc}
          <br />
        </div>
      }
      isLoading={isLoading}
      onClose={() => close()}
      onConfirm={onDelete}
      title={translations.deviceTypes.deleteObservationTitle}
      confirmLabel={translations.global.delete}
      cancelLabel={translations.global.cancel}
    />
  );
};

export default DeleteDeviceTypeObservation;
