import React from 'react';
import { Route } from 'react-router';
import { routes } from 'shared/constants/routes';
import List from './containers/List';
import './styles.scss';
import Manage from './containers/Manage';

export default () => [
  <Route
    path={routes.users.default.path}
    key="users-default"
    component={List}
    exact
  />,
  <Route
    path={routes.users.manage.path}
    key="users-edit"
    component={Manage}
    exact
  />,
  <Route
    path={routes.users.create.path}
    key="users-create"
    component={Manage}
    exact
  />
];
