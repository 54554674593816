import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { SAVE_USER, FETCH_USER } from '../../reducers/manage/';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { routes } from 'shared/constants/routes';

function* fetchUser(action) {
  try {
    const { data } = yield apiService({}).get(`/users/${action.payload.id}`);
    yield put({
      type: FETCH_USER.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: FETCH_USER.FAILURE, payload: getError(err) });
  }
}

function* editUser(action) {
  try {
    let data = {};
    if (action.payload.uuid) {
      const { data: editData } = yield apiService({}).patch(
        `/users/${action.payload.uuid}`,
        action.payload,
      );
      toast.success('Użytkownik został zaktualizowany');
      data = editData;
    } else {
      const { data: createData } = yield apiService({}).post(`/users`, action.payload);
      data = createData;
      toast.success('Użytkownik został stworzony');
      yield put(push(routes.users.list.path));
    }
    yield put({
      type: SAVE_USER.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: SAVE_USER.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(FETCH_USER.REQUEST, fetchUser);
  },
  function* () {
    yield takeEvery(SAVE_USER.REQUEST, editUser);
  },
];
