import React, { useCallback, useEffect, useState } from 'react';
import api from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../Table';

interface Props {
  onRowClick: (id) => void;
}
const DeviceTypesList = (props: Props) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await api({}).get(`/device-types?parentCompanyId=ROOT`);
      setData(data);
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{translations.global.name}</Th>
            <Th>{translations.global.description}</Th>
            <Th>Prefix</Th>
            <Th>Przekierowanie po zeskanowaniu urządzenia</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(deviceType => (
            <Tr
              key={deviceType.id}
              onClick={() => {
                props.onRowClick(deviceType.id);
              }}>
              <Td>{deviceType.id}</Td>
              <Td>{deviceType.name}</Td>
              <Td>{deviceType.description}</Td>
              <Td>{deviceType.settings?.prefix || '-'}</Td>
              <Td>{getRedirectOptionLabel(deviceType.settings?.redirectOption)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default DeviceTypesList;

export const getRedirectOptionLabel = (option: string) => {
  if (option === 'objectsModal') {
    return 'Dodawanie szkodnika';
  }
  if (option === 'observationsModal') {
    return 'Dodawanie obserwacji';
  }
  return '-';
};
