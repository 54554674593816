import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { z } from 'zod';

import api from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';

import AppContent from '../../../../../shared/components/AppContent';
import ListHeading from '../../../../../shared/components/ListHeading';
import { routes } from '../../../../../shared/constants/routes';
import Form from '../Form';

const schema = z.object({
  uniqueId: z.string().optional(),
  prefix: z
    .string()
    .regex(new RegExp(/^[A-Z]+$/))
    .optional(),
  description: z.string().optional(),
  location: z.string().optional(),
  zoneId: z.string().optional(),
  deviceTypeId: z.number(),
});
interface Props {
  onClose: () => any;
  refreshParent: () => any;
}
const PlaceDevicesCreate = (props: Props) => {
  const params = useParams();
  const placeId = params.placeId;
  const history = useHistory();
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      amountType: 'ONE',
      amount: 1,
      startFrom: 'NO',
      startFromNumber: 0,
      specificUniqueIds: [{ key: randomId(), uniqueId: '' }],
      deviceTypeId: undefined,
      uniqueId: undefined,
    },

    validate: {
      deviceTypeId: value => {
        if (!value) {
          return 'Wybierz typ urządzenia';
        }
        return null;
      },
    },
  });
  const [zones, setZones] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api({}).get(`/api/places/${placeId}/zones`);
        setZones(data.zones);
      } catch (e) {}
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api({}).get(`/api/places/${placeId}/device-types`);
        setDeviceTypes(data.deviceTypes);
      } catch (e) {}
    }
    fetchData();
  }, []);

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        ...formValues,
      };
      if (body.amountType === 'ONE') {
        delete body.specificUniqueIds;
        delete body.startFrom;
        delete body.startFromNumber;
      }
      if (body.amountType === 'MULTIPLE') {
        body.startFromNumber =
          formValues.startFrom === 'YES' ? Number(formValues.startFromNumber) : 0;
        body.specificUniqueIds = [];
      }
      if (body.amountType === 'SPECIFIC') {
        body.specificUniqueIds =
          formValues.specificUniqueIds?.map((item: any) => String(item.uniqueId).toUpperCase()) ||
          [];
      }

      const { data } = await api({}).post(`/api/places/${placeId}/devices`, body);
      toast.success(translations.devices.create.success);
      setSaving(false);
      if (Array.isArray(data) && data.length > 1) {
        history.push(routes.places.devices.default.to(placeId));
        return;
      }
      if (Array.isArray(data) && data.length === 1) {
        history.push(routes.places.devices.details.to(placeId, data[0].uuid));
        return;
      }
      history.push(routes.places.devices.details.to(placeId, data.uuid));
    } catch (e) {
      setSaving(false);
      if (e?.response?.data?.code === 'devices/company_devices_limit_reached') {
        toast.error('Limit urządzeń w placówce został przekroczony');
      } else if (e?.response?.data?.code === 'devices/uniqueid_already_exists') {
        toast.error(
          `Urządzenia o podanym identyfikatorze już istnieje - ${e?.response?.data?.details}`,
        );
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  const handleCancel = () => {};

  return (
    <>
      <ListHeading title={translations.devices.addDevice}></ListHeading>
      <AppContent>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} zones={zones} deviceTypes={deviceTypes} />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={handleCancel}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </AppContent>
    </>
  );
};

export default PlaceDevicesCreate;
