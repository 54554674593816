import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { DEVICES_FETCH_LIST } from '../../reducers/list/';

function* fetchDevices() {
  try {
    const { data } = yield apiService({}).get('/devices');
    yield put({
      type: DEVICES_FETCH_LIST.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({ type: DEVICES_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function*() {
    yield takeEvery(DEVICES_FETCH_LIST.REQUEST, fetchDevices);
  }
];
