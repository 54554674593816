import React, { useEffect, useState } from 'react';
import { CRS, divIcon, latLng, latLngBounds, Util } from 'leaflet';
import { ImageOverlay, MapContainer, Marker, useMap, useMapEvents } from 'react-leaflet';
import api from 'shared/services/api';

interface Props {
  mapId: any;
  placeId: any;
  map: any;
  signedUrlMap: any;
  bounds: any;
  position: { x?: string; y?: string };
  setValues: (values: any) => void;
}

const SetLocationMap = (props: Props) => {
  const { signedUrlMap, bounds, setValues, position } = props;
  const [x, setX] = useState(position.x);
  const [y, setY] = useState(position.y);
  useMapEvents({
    dblclick: e => {
      setX(e.latlng.lng);
      setY(e.latlng.lat);
      setValues({ mapX: e.latlng.lng, mapY: e.latlng.lat });
      // setAddDevice(e.latlng);
    },
  });
  const icon = divIcon({
    className: '',
    html: `<div class="" style="width:40px;height:40px;background:#12b886;display:flex;border-radius:50%;justify-content:center;align-items:center;color:white;font-size:20px;">Z</div>`,
    iconAnchor: [20, 20],
  });
  const map = useMap();

  useEffect(() => {
    // map.fitBounds(bounds);
  }, [bounds]);

  return (
    <>
      {signedUrlMap && (
        <div>
          <ImageOverlay url={signedUrlMap} bounds={bounds} />
          {x && y && <Marker position={[y, x]} icon={icon}></Marker>}
        </div>
      )}
    </>
  );
};

export default SetLocationMap;
