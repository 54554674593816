import React, { useEffect, useState } from 'react';

import api from 'shared/services/api';

import { useParams } from 'react-router';
import {
  TextInput,
  Select,
  Radio,
  Textarea,
  Grid,
  Group,
  ActionIcon,
  Button,
  Title,
  Notification,
  Flex,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import useFetch from 'shared/hooks/useFetch';
import SetLocation from '../SetLocation';
import { useTranslations } from 'shared/translations/useTranslations';
import { Trash } from 'tabler-icons-react';
import { randomId } from '@mantine/hooks';
interface Props {
  form: any;
}
const Form = (props: Props) => {
  const translations = useTranslations();
  const { form } = props;
  const { placeId } = useParams<{ placeId: string }>();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { recommendation: {} },
    fetchAction: () => api({}).get(`/api/recommendations/${id}`),
    shouldNotFetchAtStart: true,
  });

  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [id]);

  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isLoaded: isLoadedCategories,
  } = useFetch<any>({
    initialValue: { categories: [] },
    fetchAction: () => api({}).get(`/api/recommendations/categories`),
  });
  const {
    data: devicesData,
    isLoading: isLoadingDevices,
    isLoaded: isLoadedDevices,
  } = useFetch<any>({
    initialValue: { devices: [] },
    fetchAction: () => api({}).get(`/places/${placeId}/devices`),
  });
  const {
    data: deviceTypesData,
    isLoading: isLoadingDeviceTypes,
    isLoaded: isLoadedDeviceTypes,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api({}).get(`/device-types`),
  });
  const {
    data: usersData,
    isLoading: isLoadingUsers,
    isLoaded: isLoadedUsers,
  } = useFetch<any>({
    initialValue: { users: [] },
    fetchAction: () => api({}).get(`/places/${placeId}/users`),
  });
  const { data: mapsData, isLoading: isLoadingMaps } = useFetch<any>({
    initialValue: { maps: [] },
    fetchAction: () => api({}).get(`/places/${placeId}/maps`),
  });
  const { data: zonesData, isLoading: isLoadingZones } = useFetch<any>({
    initialValue: { maps: [] },
    fetchAction: () => api({}).get(`/api/places/${placeId}/zones`),
  });
  useEffect(() => {
    if (!data.recommendation?.id) {
      return;
    }
    data.recommendation.notifications = data.recommendation.notifications || [];
    form.setValues({
      ...data?.recommendation,
      dueDate: data?.recommendation?.dueDate ? new Date(data?.recommendation.dueDate) : new Date(),
      ownerId: data?.recommendation?.ownerId ? String(data?.recommendation?.ownerId) : undefined,
      recommendationCategoryId: data?.recommendation?.recommendationCategoryId
        ? String(data?.recommendation?.recommendationCategoryId)
        : undefined,
      deviceTypeId: data?.recommendation?.deviceTypeId
        ? String(data?.recommendation?.deviceTypeId)
        : undefined,
      deviceId: data?.recommendation?.deviceId ? String(data?.recommendation?.deviceId) : undefined,
      zoneId: data?.recommendation?.zoneId ? String(data?.recommendation?.zoneId) : undefined,
      mapId: data?.recommendation?.mapId ? String(data?.recommendation?.mapId) : undefined,
      notifications: data?.recommendation?.notifications
        ? data.recommendation?.notifications?.map(notification => ({
            userId: notification?.userId ? String(notification?.userId) : undefined,
          }))
        : [],
    });
    if (data?.recommendation?.ownerId) {
      form.setFieldValue('ownerId', `existing-${data?.recommendation.ownerId}`);
    }
    if (data?.recommendation?.ownerText && !data?.recommendation?.ownerId) {
      form.setFieldValue('ownerId', data?.recommendation.ownerText);
      // form.setFieldValue('ownerId', null);
    }
  }, [data?.recommendation]);

  useEffect(() => {
    if (data?.recommendation?.ownerText) {
      setPossibleUsers(currentUsers => [...currentUsers, data?.recommendation?.ownerText]);
    }
  }, [data?.recommendation?.ownerText]);

  const [possibleUsers, setPossibleUsers] = useState([]);
  useEffect(() => {
    setPossibleUsers(currentUsers => [...currentUsers, ...usersData.users]);
  }, [usersData.users]);

  const recommendation = data?.recommendation;
  const isLoadedAll =
    (id ? isLoaded : true) &&
    isLoadedCategories &&
    isLoadedDevices &&
    isLoadedDeviceTypes &&
    isLoadedUsers;

  const devicesFieldOptions =
    devicesData.devices
      ?.filter(device =>
        form?.values?.deviceTypeId
          ? String(form.values.deviceTypeId) === String(device.deviceTypeId)
          : true,
      )
      .map(device => ({
        value: String(device.id),
        label: device.uniqueId,
      })) || [];
  const isCustomOwner =
    form.values.ownerText && !String(form.values?.ownerText).includes('existing-');
  return (
    isLoadedAll && (
      <Grid>
        <Grid.Col span={4}>
          <Flex direction="column" gap={16}>
            {recommendation?.uniqueId && (
              <TextInput
                defaultValue={recommendation?.uniqueId}
                disabled={!!recommendation?.uniqueId}
                label={translations.recommendations.id}
              />
            )}
            <Select
              searchable
              creatable
              label={translations.recommendations.owner}
              data={
                possibleUsers?.map(user =>
                  !user?.id
                    ? user
                    : {
                        value: String(`existing-${user.id}`),
                        label: `${user.name} ${user.surname}`,
                      },
                ) || []
              }
              getCreateLabel={query => `${query}`}
              onCreate={query => {
                setPossibleUsers(current => [...current, query]);
                form.setFieldValue('ownerText', query);
              }}
              {...form.getInputProps('ownerId')}
              onChange={value => {
                form.setFieldValue('ownerId', value);
                form.setFieldValue('ownerText', undefined);
              }}
            />
            {isCustomOwner && (
              <TextInput
                // defaultValue={recommendation?.uniqueId}
                // disabled={!!recommendation?.uniqueId}
                label={translations.global.email}
                {...form.getInputProps('ownerEmail')}
              />
            )}
            <Select
              label={translations.recommendations.category}
              data={
                categoriesData.categories?.map(category => ({
                  value: String(category.id),
                  label: category.name,
                })) || []
              }
              {...form.getInputProps('recommendationCategoryId')}
            />
            <Select
              label={translations.global.deviceType}
              name="deviceTypeId"
              data={
                deviceTypesData?.map(deviceType => ({
                  value: String(deviceType.id),
                  label: deviceType.name,
                })) || []
              }
              {...form.getInputProps('deviceTypeId')}
            />
            <Select
              label={translations.global.device}
              name="deviceId"
              data={devicesFieldOptions}
              {...form.getInputProps('deviceId')}
            />
            <TextInput
              label={translations.recommendations.location}
              {...form.getInputProps('area')}
            />
            <Select
              label={translations.zones.zone}
              clearable
              data={
                zonesData.zones?.map(zone => ({
                  value: String(zone.id),
                  label: zone.name,
                })) || []
              }
              {...form.getInputProps('zoneId')}
            />
            <Select
              label={translations.recommendations.map}
              clearable
              data={
                mapsData.maps?.map(map => ({
                  value: String(map.id),
                  label: map.name,
                })) || []
              }
              {...form.getInputProps('mapId')}
            />
            <Textarea
              label={translations.recommendations.comment}
              {...form.getInputProps('comment')}
            />
            <Textarea
              label={translations.recommendations.recommendation}
              {...form.getInputProps('recommendation')}
              required
            />
            <DatePickerInput
              label={translations.recommendations.dueDate}
              required
              {...form.getInputProps('dueDate')}
            />
            <Radio.Group
              label={translations.recommendations.priority}
              name="priority"
              {...form.getInputProps('priority')}
              required>
              <Radio value="LOW" label={translations.recommendations.priorityLow} />
              <Radio value="MEDIUM" label={translations.recommendations.priorityMedium} />
              <Radio value="HIGH" label={translations.recommendations.priorityHigh} />
            </Radio.Group>
            <Radio.Group
              label={translations.global.status}
              name="status"
              {...form.getInputProps('status')}
              required>
              <Radio value="OPEN" label={translations.recommendations.statusOpen} />
              <Radio value="IN_PROGRESS" label={translations.recommendations.statusInProgress} />
              <Radio value="WAITING" label={translations.recommendations.statusAwaiting} />
              <Radio value="CLOSE" label={translations.recommendations.statusClosed} />
            </Radio.Group>
            <Title order={4} mt={40}>
              {translations.recommendations.emailNotification}
            </Title>
            <Notification title="">
              {translations.recommendations.emailSendExplanation
                .split('\n')
                .map((content, index) => (
                  <div key={`explanation-${index}`}>{content}</div>
                ))}
            </Notification>
            {form.values?.notifications?.map((item, index) => (
              <Group key={item.key} mt="xs">
                <Select
                  searchable
                  label={translations.global.firstAndLastName}
                  data={
                    possibleUsers?.map(user =>
                      !user?.id
                        ? user
                        : {
                            value: String(`${user.id}`),
                            label: `${user.name} ${user.surname}`,
                          },
                    ) || []
                  }
                  {...form.getInputProps(`notifications.${index}.userId`)}
                />
                <ActionIcon
                  color="red"
                  variant="hover"
                  onClick={() => form.removeListItem('notifications', index)}>
                  <Trash size={16} />
                </ActionIcon>
              </Group>
            ))}
            <Group position="center" mt="md">
              <Button
                onClick={() =>
                  form.insertListItem('notifications', { userId: '', key: randomId() })
                }>
                {translations.recommendations.addPerson}
              </Button>
            </Group>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8}>
          {data.placeId && (
            <SetLocation
              position={{ x: recommendation?.mapX, y: recommendation?.mapY }}
              setValues={values => {
                form.setValues({
                  ...form.values,
                  ...values,
                });
              }}
              mapId={form.values.mapId}
              placeId={data.placeId}
              map={mapsData?.maps?.find(m => m.id === form.values.mapId)}
            />
          )}
        </Grid.Col>
      </Grid>
    )
  );
};

export default Form;
