import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  NumberInput,
  Select,
  Textarea,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';

interface DeviceActionModel {
  id: number;
  amount: number;
  createdAt: Date;
  deviceTypeActionId: string | number;
  deviceTypeAction?: {
    name: string;
    id: number;
  };
  note?: string;
}

interface Props {
  deviceId?: string | number;
  actionId?: string | number;
  deviceTypeId?: string | number;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  action: DeviceActionModel;
}
interface ActionsData {
  actions: { name: string; id: string; deviceTypeId: string }[];
}
const EditActionModal = (props: Props) => {
  const { deviceId, actionId, refreshParent, onClose, deviceTypeId } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { action: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/devices/${deviceId}/actions/${actionId}`),
  });
  const {
    data: actionsData,
    refresh: refreshActions,
    isLoaded: isActionsLoaded,
    isLoading: isActionsLoading,
    // data, isLoading, isLoaded, error, refresh
  } = useFetch<ActionsData>({
    shouldNotFetchAtStart: true,
    initialValue: { actions: [] },
    fetchAction: () => api({}).get(`/api/v3/device-types/${deviceTypeId}/actions`),
  });

  const form = useForm({
    initialValues: {
      note: undefined,
      deviceTypeActionId: undefined,
      amount: undefined,
    },

    validate: {},
  });
  useEffect(() => {
    form.setValues({
      deviceTypeActionId: String(data.action?.deviceTypeActionId),
      note: data?.action?.note,
      amount: data?.action?.amount,
    });
  }, [data?.action]);

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        amount: formValues.amount,
        note: formValues.note,
        deviceTypeActionId: formValues.deviceTypeActionId,
      };

      await api({}).patch(`/api/v3/devices/${deviceId}/actions/${actionId}`, body);
      toast.success(translations.devices.editActionSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!actionId);
    if (actionId) {
      refresh();
      refreshActions();
    }
    if (!actionId) {
      form.reset();
    }
  }, [actionId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  const actionsSelectOptions = actionsData.actions?.map(action => ({
    label: action.name,
    value: String(action.id),
  }));
  return (
    <Modal opened={opened} onClose={handleClose}title={translations.devices.editActionTitle}>
      <LoadingOverlay visible={isLoading || isActionsLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
            {translations.global.somethingWentWrong}
          </Notification>
        )}
        {!error && isLoaded && isActionsLoaded && (
          <>
            <Select
              label={translations.devices.action}
              name="deviceTypeActionId"
              data={actionsSelectOptions}
              required
              mb={20}
              {...form.getInputProps('deviceTypeActionId')}
            />
            <Textarea
              label={translations.devices.note}
              required
              name="note"
              mb={20}
              {...form.getInputProps('note')}
            />
            <NumberInput
              label={translations.devices.amount}
              placeholder="1"
              min={0}
              required
              name="amount"
              mb={20}
              {...form.getInputProps('amount')}
            />
          </>
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!!error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditActionModal;
