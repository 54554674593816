import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const DEVICE_TYPES_FETCH_SINGLE = createRequestTypes(
  'deviceTypes/FETCH_SINGLE'
);
export const DEVICE_TYPES_SAVE = createRequestTypes('deviceTypes/SAVE');
export const ADD_ACTION = createRequestTypes('deviceTypes/ADD_ACTION');
export const ADD_OBJECT = createRequestTypes('deviceTypes/ADD_OBJECT');

export const fetchDeviceType = ({ id }) => ({
  type: DEVICE_TYPES_FETCH_SINGLE.REQUEST,
  payload: { id }
});

export const saveDeviceType = payload => ({
  type: DEVICE_TYPES_SAVE.REQUEST,
  payload: payload
});

export const addAction = ({ name, deviceTypeId }) => ({
  type: ADD_ACTION.REQUEST,
  payload: { name, deviceTypeId }
});
export const addObject = ({ name, deviceTypeId }) => ({
  type: ADD_OBJECT.REQUEST,
  payload: { name, deviceTypeId }
});

const initState = {
  details: {
    data: {},
    states: loadingStates,
    response: null
  },
  save: {
    states: loadingStates
  },
  addAction: {
    states: loadingStates,
    data: {},
    response: null
  },
  addObject: {
    states: loadingStates,
    data: {},
    response: null
  }
};

export default createReducer(initState, {
  [DEVICE_TYPES_FETCH_SINGLE.REQUEST]: state => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [DEVICE_TYPES_FETCH_SINGLE.SUCCESS]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [DEVICE_TYPES_FETCH_SINGLE.FAILURE]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [ADD_ACTION.REQUEST]: state => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [ADD_ACTION.SUCCESS]: (state, action) => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [ADD_ACTION.FAILURE]: (state, action) => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [ADD_OBJECT.REQUEST]: state => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [ADD_OBJECT.SUCCESS]: (state, action) => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [ADD_OBJECT.FAILURE]: (state, action) => {
    return update(state, {
      addAction: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  }
});
