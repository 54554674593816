import React from 'react';
import { Divider, Group, NumberInput, Radio, Select, Textarea, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { DeviceTypeObservationType } from '../../../../../shared/types/deviceType';
import { get } from 'lodash';
import { DateTimePicker } from '@mantine/dates';

interface Props {
  form: any;
  isCreating?: boolean;
  deviceTypeObservations?: any[];
  mixturesById: any;
}

const EditInspectionObservationForm = (props: Props) => {
  const { form, isCreating, deviceTypeObservations, mixturesById } = props;
  const translations = useTranslations();
  const selectedDeviceTypeObservation = deviceTypeObservations?.find(
    observation => observation.value === form.values?.deviceTypeObservationId,
  );
  return (
    <>
      {!isCreating && (
        <TextInput
          label={translations.global.name}
          name="name"
          required
          mb={20}
          disabled
          {...form.getInputProps('name')}
        />
      )}
      {!isCreating && form.values.createdAt && (
        <DateTimePicker
          withSeconds
          label={'Data stworzenia obserwacji'}
          name="createdAt"
          required
          mb={20}
          // disabled
          {...form.getInputProps('createdAt')}
        />
      )}
      {/* 
        <Select
          label={translations.global.observation}
          name=""
          data={deviceTypeObservations}
          required
          mb={20}
          {...form.getInputProps('deviceTypeObservationId')}
        />
      )} */}
      {isCreating && (
        <Select
          label={translations.global.type}
          name="deviceTypeObservationId"
          data={deviceTypeObservations}
          {...form.getInputProps('deviceTypeObservationId')}
        />
      )}
      {(form.values?.type === DeviceTypeObservationType.BAIT_COLLECT ||
        selectedDeviceTypeObservation?.type === DeviceTypeObservationType.BAIT_COLLECT) && (
        <Radio.Group
          mt="md"
          label={"Ilość pobranej trutki"}
          name="amountPercentage"
          {...form.getInputProps('amountPercentage')}>
          <Radio value={'0'} label="0%" mt={10} />
          <Radio value={'10'} label="10%" mt={10} />
          <Radio value={'20'} label="20%" mt={10} />
          <Radio value={'30'} label="30%" mt={10} />
          <Radio value={'40'} label="40%" mt={10} />
          <Radio value={'50'} label="50%" mt={10} />
          <Radio value={'60'} label="60%" mt={10} />
          <Radio value={'70'} label="70%" mt={10} />
          <Radio value={'80'} label="80%" mt={10} />
          <Radio value={'90'} label="90%" mt={10} />
          <Radio value={'100'} label="100%" mt={10} />
        </Radio.Group>
      )}
      {selectedDeviceTypeObservation?.type === DeviceTypeObservationType.REFILL && isCreating && (
        <div>
          {Object.values(mixturesById || {}).map((mixture: any, index: number) => {
            const inputProps = form.getInputProps(`mixtures.${mixture.id}.value`);
            const totalGrams = inputProps.value * mixture.grammage;
            return (
              <div key={`mixture-${mixture.id}`}>
                <strong>{index + 1}. </strong>
                {mixture.name}
                <Group>
                  <NumberInput
                    label={translations.global.amount}
                    name={`mixtures.${mixture.id}.value`}
                    description={`${mixture.grammage}g na opakowanie`}
                    required
                    min={1}
                    mb={20}
                    {...inputProps}
                  />
                  {totalGrams}g
                </Group>
                <Divider mt={5} mb={15} />
              </div>
            );
          })}
        </div>
      )}
      {form.values?.type === DeviceTypeObservationType.REFILL && !isCreating && (
        <div>
          {Object.values(form.values?.mixtures || {}).map((mixture: any, index: number) => {
            const inputProps = form.getInputProps(`mixtures.${mixture.mixtureId}.value`);
            const totalGrams = inputProps.value * mixture.grammage;
            return (
              <div key={`mixture-${mixture.mixtureId}`}>
                <strong>{index + 1}. </strong>
                {get(mixturesById, `${mixture.mixtureId}.name`)}
                <Group>
                  <NumberInput
                    label={translations.global.amount}
                    name={`mixtures.${mixture.mixtureId}.value`}
                    description={`${mixture.grammage}g na opakowanie`}
                    required
                    min={1}
                    mb={20}
                    {...inputProps}
                  />
                  {totalGrams}g
                </Group>
                <Divider mt={5} mb={15} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default EditInspectionObservationForm;
