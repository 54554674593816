import React from 'react';
import { Route } from 'react-router';
import { routes } from 'shared/constants/routes';
import Generate from './containers/Generate';
import List from './containers/List';
import CreateIntervention from '../Place/containers/Interventions/Manage';
import './styles.scss';

export default () => [
  <Route
    path={routes.reports.default.path}
    key="reports-default"
    component={List}
    exact
  />,
  <Route
    path={routes.reports.generate.path}
    key="reports-generate"
    component={Generate}
    exact
  />
];
