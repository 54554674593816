import isEmpty from 'lodash/isEmpty';

const validate = values => {
  return {
    address: isEmpty(values.address) ? 'To pole jest wymagane' : null,
    email: isEmpty(values.email) ? 'To pole jest wymagane' : null,
    name: isEmpty(values.name) ? 'To pole jest wymagane' : null,
  };
};

export default validate;
