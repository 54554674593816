import React, { useState } from 'react';

import { toast } from 'react-toastify';
import api from 'shared/services/api';
import { Textarea, Button, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';

const AddCommentDrawer = ({ id, onClose, onAdd }) => {
  const [isLoading, setLoading] = useState<boolean>();
  const translations = useTranslations();

  const onSubmit = async values => {
    try {
      setLoading(true);
      await api({}).post(`/api/recommendations/${id}/comment`, values);
      setLoading(false);
      toast.success(translations.global.commentHasBeenAdded);
      onAdd();
      onClose();
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const form = useForm({
    initialValues: {
      comment: undefined,
    },

    validate: {},
  });
  return (
    <>
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <Textarea label={translations.recommendations.comment} {...form.getInputProps('comment')} />
        <Space h={10}></Space>
        <Button loading={isLoading} type="submit">
          {translations.global.add}
        </Button>
      </form>
    </>
  );
};

export default AddCommentDrawer;
