import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppContent from 'shared/components/AppContent';
import { routes } from 'shared/constants/routes';
import DashboardDetails from './DashboardDetails';
// import List from './List';

function DashboardRoutes() {
  return (
    <Switch>
      <Route path={[routes.app.default.path]} exact>
        <Redirect to={routes.dashboard.default.path} />
      </Route>
      <Route path={[routes.dashboard.default.path]}>
        <DashboardDetails />
      </Route>
    </Switch>
  );
}

export default DashboardRoutes;
