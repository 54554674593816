export const getColorByRecommendationPriority = (type: 'LOW' | 'MEDIUM' | 'HIGH') => {
  const colors = {
    LOW: 'gray',
    MEDIUM: 'blue',
    HIGH: 'red',
  };
  return colors[type];
};

export const getColorByRecommendationStatus = (
  type: 'OPEN' | 'IN_PROGRESS' | 'CLOSE' | 'WAITING',
) => {
  const colors = {
    OPEN: 'violet',
    IN_PROGRESS: 'green',
    CLOSE: 'gray',
    WAITING: 'yellow',
  };
  return colors[type];
};
export const getChartColorByRecommendationPriority = (type: 'LOW' | 'MEDIUM' | 'HIGH') => {
  const colors = {
    LOW: 'rgba(248, 249, 250, 1)',
    MEDIUM: 'rgba(231, 245, 255, 1)',
    HIGH: 'rgba(255, 245, 245, 1)',
  };
  return colors[type];
};

export const getChartColorByRecommendationStatus = (
  type: 'OPEN' | 'IN_PROGRESS' | 'CLOSE' | 'WAITING',
) => {
  const colors = {
    OPEN: 'rgba(243, 240, 255, 1)',
    IN_PROGRESS: 'rgba(235, 251, 238, 1)',
    CLOSE: 'rgba(248, 249, 250, 1)',
    WAITING: 'rgba(255, 249, 219, 1)',
  };
  return colors[type];
};
export const getChartFontColorByRecommendationPriority = (type: 'LOW' | 'MEDIUM' | 'HIGH') => {
  const colors = {
    LOW: '#868e96',
    MEDIUM: '#228be6',
    HIGH: '#fa5252',
  };
  return colors[type];
};

export const getChartFontColorByRecommendationStatus = (
  type: 'OPEN' | 'IN_PROGRESS' | 'CLOSE' | 'WAITING',
) => {
  const colors = {
    OPEN: '#7950f2',
    IN_PROGRESS: '#40c057',
    CLOSE: '#868e96',
    WAITING: '#fab005',
  };
  return colors[type];
};
