import React, { useEffect, useState } from 'react';
import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import apiService from 'shared/services/api';
import AddCompanyFileCategoryForm from './AddCompanyFileCategoryForm';
import { Company } from 'shared/types/Company';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  subCompanies: Company[];
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
}

const AddCompanyFileCategoryModal = (props: Props) => {
  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      subCompanies: [],
    },
  });
  const { subCompanies, onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (!props.opened) {
      form.reset();
    }
  }, [props.opened]);

  const handleAdd = async formValues => {
    try {
      setSaving(true);
      const { data } = await apiService({}).post(
        `/companies/${props.companyId}/files/categories`,
        formValues,
      );
      form.reset();
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };

  const translations = useTranslations();
  return (
    <Modal opened={opened} onClose={onClose} title="Dodaj kategorię">
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanyFileCategoryForm form={form} subCompanies={subCompanies} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            Dodaj
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default AddCompanyFileCategoryModal;
