import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';

import { useTranslations } from 'shared/translations/useTranslations';
import { Alert, Button, Group, Modal, Notification } from '@mantine/core';

interface Props {
  objectId?: number;
  refreshParent: () => any;
  onClose: () => any;
  placeId: string | number;
  inspectionId: number;
}

const DeleteInspectionObjectModal = (props: Props) => {
  const { objectId, refreshParent, onClose, placeId, inspectionId } = props;
  const translations = useTranslations();

  const [isSaving, setSaving] = useState(false);
  const handleClick = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(
        `/api/places/${placeId}/inspections/${inspectionId}/objects/${objectId}`,
      );
      toast.success('Szkodnik została usunięty');
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!objectId);
  }, [objectId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal opened={opened} onClose={handleClose} title="Usuń szkodnika">
      Czy napewno chcesz usunąć tego szkodnika?
      <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
        Usuwając szkodnika <strong>należy pamiętać aby </strong>
        później manualnie edytować kolejne lub poprzednie akcję czy szkodniki. Inaczej wartości mogą
        się nie zgadzać. Tyczy się to również zmian wymaganych w innych przeglądach.
        <br />
        <br />
        <strong>Uwaga:</strong> Akcje czyszczenia lepa należy dodawać przed dodaniem szkodnika w
        danym przeglądzie, inaczej wpłynie to na poprawne wyliczenia statystyki
      </Alert>
      <Group position="right" mt={20}>
        <Button color="primary" loading={isSaving} type="submit" onClick={handleClick}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteInspectionObjectModal;
