import React, { useState } from 'react';
// import Button from 'shared/components/Button';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteCompany } from 'modules/Company/services/companyService';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { toast } from 'react-toastify';
import api from 'shared/services/api';
import { Textarea, Button, TextInput, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import FileFieldClean from 'shared/components/FileFieldClean';
import { useTranslations } from 'shared/translations/useTranslations';

// import './styles.scss';

const AddFileDrawer = ({ id, onClose, onAdd }) => {
  const [isLoading, setLoading] = useState<boolean>();
  const translations = useTranslations();
  const [file, setFile] = useState();
  const [fileRaw, setFileRaw] = useState();

  const onChange = e => {
    setFileRaw(e);
    setFile({
      url: URL.createObjectURL(e),
      data: e,
      value: { name: e.name },
    });
  };

  const onSubmit = async values => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', fileRaw);
      formData.append('name', values.name);
      await api({
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).post(`/api/recommendations/${id}/files`, formData);
      setLoading(false);
      toast.success(translations.global.attachementHasBeenAdded);
      onAdd();
      onClose();
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const form = useForm({
    initialValues: {
      name: undefined,
    },

    validate: {},
  });
  return (
    <>
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <div className="col-md-12">
          <FileFieldClean
            onChange={onChange}
            file={file}
            label={translations.global.file}
            accept="image/jpeg"
          />
        </div>
        <TextInput label={translations.global.fileName} {...form.getInputProps('name')} />
        <Space h={20} />
        <Button loading={isLoading} type="submit" className="mt-10">
          {translations.global.add}
        </Button>
      </form>
    </>
  );
};

export default AddFileDrawer;
