import { useMemo } from 'react';
import fuzzysort from 'fuzzysort';
import { Company } from 'shared/types/Company';


export const useFilterCompanies = (
  companies: Company[],
  searchFilter?: string,
) => {
  return useMemo(() => {
    let filteredCompanies = companies;
    if (searchFilter) {
      filteredCompanies = fuzzysort
        .go(searchFilter, filteredCompanies, { keys: ['name'] })
        .map(result => result.obj);
    }

    return filteredCompanies;
  }, [companies, searchFilter]);
};
