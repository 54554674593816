export const saveFileFromBlob = (blob, fileName, type) => {
  return new Promise((resolve, reject) => {
    try {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([blob], { type });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        return resolve();
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);

      resolve();
    } catch (err) {
      reject(err);
    }
  });
};
export const saveFileFromUrl = (url, fileName) => {
  return new Promise((resolve, reject) => {
    try {
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      link.click();

      resolve();
    } catch (err) {
      reject(err);
    }
  });
};
