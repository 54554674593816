import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Menu,
  Select,
  Popover,
  Table,
  TextInput,
  SimpleGrid,
} from '@mantine/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import AppContent from '../../../../../shared/components/AppContent';
import ListHeading from '../../../../../shared/components/ListHeading';
import { useTranslations } from '../../../../../shared/translations/useTranslations';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { readableDate } from '../../../../../shared/utils/date';
import { Observation } from '../../../../../shared/types/observations';
import styles from './inspection.module.scss';
import { flatMap, get, map, orderBy, set, sortBy, uniqBy } from 'lodash';
import { MixtureModel } from '../../../../Admin/Mixtures/Mixture';
import { Dots, Pencil, Trash } from 'tabler-icons-react';
import DeleteInspectionActionModal from '../DeleteInspectionActionModal';
import EditInspectionActionModal from '../EditInspectionActionModal';
import DeleteInspectionObjectModal from '../DeleteInspectionObjectModal';
import EditInspectionObjectModal from '../EditInspectionObjectModal';
import CreateInspectionActionModal from '../CreateInspectionActionModal';
import CreateInspectionObjectModal from '../CreateInspectionObjectModal';
import EditInspectionObservationModal from '../EditInspectionObservationModal';
import { useForm } from '@mantine/form';
import { useVisualInspectionAnswers } from './hooks';
import DeleteInspectionObservationModal from '../DeleteInspectionObservationModal';
import CreateInspectionObservationModal from '../CreateInspectionObservationModal';

interface PlaceInspection {
  id: number;
  uuid: string;
  approved: boolean;
  approvedAt: string;
  approvedBy?: {
    name: string;
    surname: string;
  };
  approvedByImage: null;
  approvedByText: null;
  approvedByUserId: null;
  additionalFields: {
    repairOrders: string;
    additionalTreatments: string;
    batchNumberOfUsedPreparations: string;
  };
  reports: {
    id: number;
    uuid: string;
    url: string;
    createdAt: string;
  }[];
  visualInspection: {
    answers: {}[];
    questions: {}[];
  };
  name: string;
  startedAt: string;
  finishedAt: string;

  user: {
    name: string;
    surname: string;
  };
  status: 'COMPLETED';
}

interface PlaceInspectionDetails {
  imagesById: {
    [key: number]: {
      signedUrl: string;
    };
  };
  devices: {
    id: number;
    deviceId: number;
    scanned: boolean;
    status: 'CHECKED' | 'NOT_CHECKED';
    device: {
      id: number;
      uuid: string;
      uniqueId: string;
      detect: boolean;
      deviceTypeId: number;
      deviceType: {
        id: number;
        name: string;
      };
      deviceImages: {
        id: number;
      }[];
      deviceActions: {
        amount: number;
        id: number;
        note: string;
        deviceTypeAction: {
          id: number;
          name: string;
        };
      }[];
      deviceObjects: {
        id: number;
        note: string;
        amount: number;
        deviceTypeObject: {
          id: number;
          name: string;
        };
      }[];
      deviceObservations: Observation[];
    };
  }[];

  mixtures: MixtureModel[];
}

const Inspection = () => {
  const { placeId, inspectionId } = useParams();
  const [selectedDeviceCreateActionId, setSelectedDeviceCreateActionId] = useState<
    number | undefined
  >();
  const [selectedDeviceCreateObjectId, setSelectedDeviceCreateObjectId] = useState<
    number | undefined
  >();
  const [selectedDeviceCreateObservationId, setSelectedDeviceCreateObservationId] = useState<
    number | undefined
  >();
  const [selectedActionEditId, setSelectedActionEditId] = useState<number | undefined>();
  const [selectedActionDeleteId, setSelectedActionDeleteId] = useState<number | undefined>();
  const [selectedObjectDeleteId, setSelectedObjectDeleteId] = useState<number | undefined>();
  const [selectedObservationDeleteId, setSelectedObservationDeleteId] = useState<
    string | undefined
  >();
  const [selectedObjectEditId, setSelectedObjectEditId] = useState<number | undefined>();
  const [selectedObservationEditId, setSelectedObservationEditId] = useState<string | undefined>();
  const [selectedImageEditId, setSelectedImageEditId] = useState<number | undefined>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<PlaceInspection>({
    initialValue: { observation: {} },
    fetchAction: () => apiService({}).get(`/api/places/${placeId}/inspections/${inspectionId}`),
  });
  const {
    data: details,
    isLoading: isLoadingDetails,
    isLoaded: isLoadedDetails,
    refresh: refreshDetails,
  } = useFetch<PlaceInspectionDetails>({
    initialValue: { observation: {} },
    fetchAction: () =>
      apiService({}).get(`/api/places/${placeId}/inspections/${inspectionId}/details`),
  });

  const preview = async () => {
    const { data } = await apiService({
      baseUrl: process.env.REACT_APP_BACKEND_PDF_URL,
    }).post(`/reports/pdf/inspection/${inspectionId}?shouldRenderHtml=true`, {
      placeId,
    });

    const iframe = document.getElementById('report-preview') as HTMLIFrameElement;
    const html = data;
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();

    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
    // @ts-ignore
    // const win = window.open(
    //   '',
    //   'Title',
    //   `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=${
    //   (window.screen.height - 400)} ,left=${(window.screen.width - 840)}`
    // );
    // win.document.body.innerHTML = html;
  };
  const translations = useTranslations();
  const iframeLoaded = () => {
    const iframe = document.getElementById('report-preview') as HTMLIFrameElement;

    iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  };
  const [settingScannedDevice, setSettingScannedDevice] = useState<number | undefined>();

  const setAsScanned = useCallback(async (inspectionDeviceId: number) => {
    try {
      setSettingScannedDevice(inspectionDeviceId);
      await apiService({}).post(
        `/api/places/${placeId}/inspections/${inspectionId}/devices/${inspectionDeviceId}/set-scanned`,
        {},
      );
      refreshDetails();
    } catch {
    } finally {
      setSettingScannedDevice(undefined);
    }
  }, []);

  const setAsChecked = useCallback(async (inspectionDeviceId: number) => {
    try {
      setSettingScannedDevice(inspectionDeviceId);
      await apiService({}).post(
        `/api/places/${placeId}/inspections/${inspectionId}/devices/${inspectionDeviceId}/set-checked`,
        {},
      );
      refreshDetails();
    } catch {
    } finally {
      setSettingScannedDevice(undefined);
    }
  }, []);

  const mixturesById = useMemo(() => {
    const mixtures = details?.mixtures || [];
    return mixtures.reduce((acc, mixture) => {
      acc[mixture.id] = mixture;
      return acc;
    }, {});
  }, [details?.mixtures]);

  const isLoadingAll = isLoading || isLoadingDetails;
  const unscanned = details?.devices?.filter(device => !device.scanned).length;
  const notchecked = details?.devices?.filter(device => device.status !== 'CHECKED').length;
  const form = useForm({});
  const deviceTypesOptions = useMemo(() => {
    const deviceTypes =
      uniqBy(
        flatMap(details.devices, device => device.device?.deviceType).filter(
          deviceType => !!deviceType,
        ),
        'id',
      ) || [];
    return [
      { label: translations.global.all, value: '' },
      ...deviceTypes.map(({ id, name }) => ({ label: name, value: id })),
    ];
  }, [details]);

  const formValues = form.values;
  const filteredDevices = useMemo(() => {
    const splitId = (uniqueId: string) => {
      const match = uniqueId.match(/^([a-zA-Z]+)(\d+)$/);
      if (match) {
        const [, prefix, num] = match;
        return [prefix.toLowerCase(), parseInt(num, 10)];
      }
      return [uniqueId.toLowerCase(), 0];
    };

    const ordered1 =
      orderBy(
        details.devices,
        [
          ({ device }) => !!device.detect,
          ({ device }) => splitId(device.uniqueId)[0],
          ({ device }) => splitId(device.uniqueId)[1],
        ],
        ['desc', 'asc', 'asc'],
      ) || [];

    let result = ordered1;
    if (formValues && formValues.deviceType) {
      result = result.filter(device => device.device?.deviceTypeId === formValues.deviceType);
    }

    if (formValues.notScanned) {
      result = result.filter(device => {
        return !device.scanned;
      });
    }
    if (formValues.notChecked) {
      result = result.filter(device => {
        return device.status !== 'CHECKED';
      });
    }
    if (formValues && formValues.uniqueId) {
      result = result.filter(device => {
        return String(device.device?.uniqueId)
          .toLowerCase()
          .includes(String(formValues.uniqueId).toLowerCase());
      });
    }

    return result;
  }, [details?.devices, formValues]);
  const visualInspectionAnswers = useVisualInspectionAnswers(data);
  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={isLoadingAll || !!settingScannedDevice} />
      {isLoaded && (
        <div>
          <ListHeading title={get(data, 'name')}>
            {data && get(data, 'approved') ? (
              <Badge color="green" size="lg">
                Zatwierdzony
              </Badge>
            ) : (
              <Badge color="red" size="lg">
                Nie zatwierdzony
              </Badge>
            )}
          </ListHeading>
          <AppContent>
            {isLoaded && isLoadedDetails && (
              <>
                <DeleteInspectionActionModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  actionId={selectedActionDeleteId}
                  onClose={() => setSelectedActionDeleteId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <CreateInspectionActionModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  inspectionDeviceId={selectedDeviceCreateActionId}
                  onClose={() => setSelectedDeviceCreateActionId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <CreateInspectionObjectModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  inspectionDeviceId={selectedDeviceCreateObjectId}
                  onClose={() => setSelectedDeviceCreateObjectId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <CreateInspectionObservationModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  mixturesById={mixturesById}
                  inspectionDeviceId={selectedDeviceCreateObservationId}
                  onClose={() => setSelectedDeviceCreateObservationId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <EditInspectionActionModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  actionId={selectedActionEditId}
                  onClose={() => setSelectedActionEditId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <DeleteInspectionObjectModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  objectId={selectedObjectDeleteId}
                  onClose={() => setSelectedObjectDeleteId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <DeleteInspectionObservationModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  observationId={selectedObservationDeleteId}
                  onClose={() => setSelectedObservationDeleteId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <EditInspectionObjectModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  objectId={selectedObjectEditId}
                  onClose={() => setSelectedObjectEditId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                />
                <EditInspectionObservationModal
                  placeId={placeId}
                  inspectionId={inspectionId}
                  observationId={selectedObservationEditId}
                  onClose={() => setSelectedObservationEditId(undefined)}
                  refreshParent={() => {
                    refreshDetails();
                  }}
                  mixturesById={mixturesById}
                />
                {/* <Button onClick={preview}>Preview</Button>
            <div style={{ width: '100%', minHeight: '100vh' }}>
              <iframe id="report-preview" style={{ width: '100%' }} onLoad={iframeLoaded}></iframe>
            </div> */}
                <SimpleGrid cols={3}>
                  <div>
                    <h4>Detale</h4>
                    <div>Nazwa: {data.name}</div>
                    <div>Rozpoczęty: {readableDate(data.startedAt)}</div>
                    <div>Zakończony: {readableDate(data.finishedAt)}</div>
                    <div>Nie zeskanowanych: {unscanned}</div>
                    <div>Nie sprawdzonych: {notchecked}</div>
                    <div>Nie sprawdzonych: {notchecked}</div>
                    <div>
                      Pracownik: {data.user?.name} {data.user?.surname}
                    </div>
                  </div>
                  <div>
                    {visualInspectionAnswers.length > 0 && (
                      <div>
                        <h4>Inspekcja Wizualna</h4>
                        {visualInspectionAnswers.map((answer, index) => {
                          return (
                            <div key={`answer-${index}`}>
                              <strong>
                                {index + 1}. {answer.question}
                              </strong>
                              <br />
                              Odpowiedź: <strong>{answer.answer}</strong>
                              <br />
                              Notatka: <strong>{answer.note}</strong>
                              <br />
                              Rekomendacja: <strong>{answer.recommendation}</strong>
                              <br />
                              <div>
                                {/*
                                // TODO: Add images
                                                      {#each images}
                              <img src="{ url }" style="max-width:300px;height:200px;margin-left:30px;" />
                              {/each} */}
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    <h4>Zatwierdzenie</h4>
                    {data && get(data, 'approved') ? (
                      <Badge color="green" size="lg">
                        Zatwierdzony
                      </Badge>
                    ) : (
                      <Badge color="red" size="lg">
                        Nie zatwierdzony
                      </Badge>
                    )}

                    {data.approvedBy && (
                      <div>
                        Zatwierdzone przez: {data.approvedBy?.name}, {data.approvedBy?.surname},
                        {readableDate(data.approvedAt)}
                      </div>
                    )}

                    {data.approvedByText && (
                      <div>
                        Zatwierdzone przez: {data.approvedByText}, {readableDate(data.approvedAt)}
                      </div>
                    )}
                    {data.approvedByImage && (
                      <div>
                        <img src={data.approvedByImage} />
                      </div>
                    )}
                  </div>
                </SimpleGrid>
                <Group align="end" mb={20}>
                  <TextInput
                    label={translations.devices.uniqueId}
                    {...form.getInputProps('uniqueId')}
                  />
                  <Select
                    clearable
                    searchable
                    data={deviceTypesOptions}
                    label={translations.global.deviceType}
                    {...form.getInputProps('deviceType')}
                  />
                  <Checkbox
                    mb={10}
                    label={`Nie zeskanowane`}
                    {...form.getInputProps('notScanned', { type: 'checkbox' })}
                  />
                  <Checkbox
                    mb={10}
                    label={`Nie sprawdzone`}
                    {...form.getInputProps('notChecked', { type: 'checkbox' })}
                  />
                </Group>
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Urządzenie</TableCell>
                        <TableCell>Urządzenie</TableCell>
                        <TableCell>Akcje</TableCell>
                        <TableCell>Szkodniki</TableCell>
                        <TableCell>Obserwacje</TableCell>
                        <TableCell>Zdjęcia</TableCell>
                        <TableCell>Dane</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredDevices?.map(inspectionDevice => {
                        return (
                          <TableRow key={inspectionDevice.id}>
                            <TableCell>{inspectionDevice.device?.uniqueId}</TableCell>
                            <TableCell>{inspectionDevice.device?.deviceType?.name}</TableCell>
                            <TableCell>
                              {sortBy(inspectionDevice.device?.deviceActions, 'createdAt')?.map(
                                action => {
                                  return (
                                    <div key={action.id} className={styles.rowItem}>
                                      <div>
                                        {action.amount}x {action.deviceTypeAction?.name}
                                        {action.note ? (
                                          <div>
                                            <strong>Opis</strong>: {action.note}
                                          </div>
                                        ) : null}
                                      </div>
                                      <Group ml="auto" className={styles.rowItemOptions}>
                                        <ActionIcon
                                          variant="transparent"
                                          onClick={() => {
                                            setSelectedActionEditId(action.id);
                                          }}>
                                          <Pencil size={16} />
                                        </ActionIcon>
                                        <ActionIcon
                                          variant="transparent"
                                          onClick={() => {
                                            setSelectedActionDeleteId(action.id);
                                          }}>
                                          <Trash size={16} />
                                        </ActionIcon>
                                      </Group>
                                    </div>
                                  );
                                },
                              )}
                            </TableCell>
                            <TableCell>
                              {sortBy(inspectionDevice.device?.deviceObjects, 'createdAt')?.map(
                                object => {
                                  return (
                                    <div key={object.id} className={styles.rowItem}>
                                      <div>
                                        {object.amount}x {object.deviceTypeObject?.name}
                                        {object.note ? (
                                          <div>
                                            <strong>Opis</strong>: {object.note}
                                          </div>
                                        ) : null}
                                      </div>
                                      <Group ml="auto" className={styles.rowItemOptions}>
                                        <ActionIcon
                                          variant="transparent"
                                          onClick={() => {
                                            setSelectedObjectEditId(object.id);
                                          }}>
                                          <Pencil size={16} />
                                        </ActionIcon>
                                        <ActionIcon
                                          variant="transparent"
                                          onClick={() => {
                                            setSelectedObjectDeleteId(object.id);
                                          }}>
                                          <Trash size={16} />
                                        </ActionIcon>
                                      </Group>
                                    </div>
                                  );
                                },
                              )}
                            </TableCell>
                            <TableCell>
                              {sortBy(
                                inspectionDevice.device?.deviceObservations,
                                'createdAt',
                              )?.map(observation => {
                                const stringAmount = observationAmountByData(observation);
                                const amountPercentage =
                                  observation.data && observation.data.amountPercentage
                                    ? observation.data.amountPercentage
                                    : 0;
                                const colorClass =
                                  getColorClassByAmountPercentage(amountPercentage);
                                // const { totalAmount, usedAmount } =
                                const showBar =
                                  observation.deviceTypeObservation &&
                                  observation.deviceTypeObservation.type === 'BAIT_COLLECT'; // TODO: Use Enum
                                //   observationAmountDataByData(observation);
                                // return {
                                //   name: translatedDeviceTypeObservation.name,
                                //   mixtures: observation.mixtures,
                                //   usedAmount,
                                //   showBaitCollectInSumUp:
                                //     observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT,
                                //   totalAmount,
                                //   type: observation.data?.type,
                                //   stringAmount,
                                //   colorClass,
                                //   showBar:
                                //     observation.deviceTypeObservation &&
                                //     observation.deviceTypeObservation.type === 'BAIT_COLLECT', // TODO: Use Enum
                                //   amountPercentage,
                                // };
                                // const stringAmount = '';
                                // const amountPercentage = 0;
                                return (
                                  <div key={observation.id} className={styles.rowItem}>
                                    <div className="amount">
                                      <div className="observations-title">
                                        {observation.deviceTypeObservation?.name}{' '}
                                        <span className="observations-amount">{stringAmount}</span>
                                      </div>
                                      {map(observation?.data?.mixtures, (mixture, mixtureIndex) => {
                                        return (
                                          <div
                                            className={styles.mixture}
                                            key={`${observation.id}-mixture-${mixtureIndex}`}>
                                            <span className={styles.mixtureName}>
                                              <span className={styles.mixtureTotal}>
                                                {mixture.totalValue}g
                                              </span>{' '}
                                              {mixturesById[mixture.mixtureId]?.name}
                                            </span>{' '}
                                            <br />
                                            <span className={styles.mixtureDescription}>
                                              {mixture.description}
                                            </span>{' '}
                                            <br />
                                          </div>
                                        );
                                      })}

                                      {showBar && (
                                        <div className={styles.barWrapper}>
                                          <div
                                            className={`${styles.barAmount} ${
                                              styles[`barAmount${colorClass}`]
                                            }`}
                                            style={{ width: `${amountPercentage}%` }}>
                                            {amountPercentage}%
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <Group ml="auto" className={styles.rowItemOptions}>
                                      <ActionIcon
                                        variant="transparent"
                                        onClick={() => {
                                          setSelectedObservationEditId(observation.id);
                                        }}>
                                        <Pencil size={16} />
                                      </ActionIcon>
                                      <ActionIcon
                                        variant="transparent"
                                        onClick={() => {
                                          setSelectedObservationDeleteId(observation.id);
                                        }}>
                                        <Trash size={16} />
                                      </ActionIcon>
                                    </Group>
                                  </div>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              {inspectionDevice.device?.deviceImages?.map(image => {
                                if (!details?.imagesById[image.id]) {
                                  return 'Problem z pobraniem zdjęcia';
                                }
                                return (
                                  <div key={image.id}>
                                    <img
                                      src={details?.imagesById[image.id].signedUrl}
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                );
                              })}
                            </TableCell>
                            <TableCell>
                              {inspectionDevice.status === 'CHECKED' ? (
                                <Badge color="green">Sprawdzone</Badge>
                              ) : (
                                <Badge color="red">Nie sprawdzone</Badge>
                              )}
                              <br />
                              {inspectionDevice.scanned ? (
                                <Badge color="green">Zeskanowany QR</Badge>
                              ) : (
                                <Badge color="red">Bez skanu</Badge>
                              )}
                            </TableCell>
                            <TableCell>
                              <Menu shadow="md" width={200}>
                                <Menu.Target>
                                  <Button size="xs" variant="light">
                                    <Dots />
                                  </Button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                  <Menu.Item
                                    onClick={() => {
                                      setSelectedDeviceCreateActionId(inspectionDevice.id);
                                    }}>
                                    Dodaj akcję
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={() => {
                                      setSelectedDeviceCreateObjectId(inspectionDevice.id);
                                    }}>
                                    Dodaj szkodnika
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={() => {
                                      setSelectedDeviceCreateObservationId(inspectionDevice.id);
                                    }}>
                                    Dodaj obserwację
                                  </Menu.Item>
                                  {inspectionDevice.status !== 'CHECKED' && (
                                    <Menu.Item
                                      color="green"
                                      onClick={() => {
                                        setAsChecked(inspectionDevice.id);
                                      }}>
                                      Oznacz jako sprawdzone
                                    </Menu.Item>
                                  )}
                                  {inspectionDevice.scanned !== true && (
                                    <Menu.Item
                                      onClick={() => {
                                        setAsScanned(inspectionDevice.id);
                                      }}
                                      color="green">
                                      <div>Oznacz jako zeskanowane</div>
                                    </Menu.Item>
                                  )}
                                </Menu.Dropdown>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </>
            )}
          </AppContent>
        </div>
      )}
    </div>
  );
};

export const getColorClassByAmountPercentage = (amountPercentage = 0) => {
  let colorClass = 'green';

  /* 0-20 - zielony, 21-50 % - żółty, 51-70- pomarańczowy,  71-100 - czerwony */
  if (amountPercentage >= 0 && amountPercentage <= 20) {
    colorClass = 'green';
  }
  if (amountPercentage >= 21 && amountPercentage <= 50) {
    colorClass = 'yellow';
  }
  if (amountPercentage >= 51 && amountPercentage <= 70) {
    colorClass = 'orange';
  }
  if (amountPercentage >= 71) {
    colorClass = 'red';
  }
  return `bar-amount-${colorClass}`;
};

export enum DeviceTypeObservationType {
  'NEUTRAL' = 'NEUTRAL',
  'BAIT_COLLECT' = 'BAIT_COLLECT',
  'REFILL' = 'REFILL',
}

export const observationAmountDataByData = (observation: Observation) => {
  if (observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
    const previousTotalMixtures = observation.data?.previousTotalMixtures;
    if (!previousTotalMixtures) {
      return { totalAmount: 0, usedAmount: 0 };
    }
    const calculated = (observation.data.amountPercentage * previousTotalMixtures) / 100;
    return { totalAmount: previousTotalMixtures, usedAmount: calculated };
  }

  return { totalAmount: 0, usedAmount: 0 };
};

export const observationAmountByData = (observation: Observation) => {
  if (observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
    const previousTotalMixtures = observation.data?.previousTotalMixtures;
    if (!previousTotalMixtures) {
      return `${observation.data.amountPercentage}%`;
    }
    const calculated = (observation.data.amountPercentage * previousTotalMixtures) / 100;
    return `${calculated}g / ${previousTotalMixtures}g  (${observation.data.amountPercentage}%)`;
  }
  if (observation.data?.type === DeviceTypeObservationType.REFILL) {
    return `${observation.data.totalMixtures}g`;
  }
  return 1;
};

export default Inspection;
