import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import ContentLoader from 'shared/components/ContentLoader';
import { routes } from 'shared/constants/routes';
import AppContent from 'shared/components/AppContent';
import { fetchDevices } from 'modules/Device/reducers/list';
import { deleteDevice, cleanDetect } from 'modules/Device/reducers/manage';
import { dateLongerThanMinutes } from 'shared/utils/device';
import Button from 'shared/components/Button';
import {
  isAnyAdminByAuthState,
  isCompanyAdminByAuthState,
  isSuperAdminByAuthState,
} from 'shared/utils/user';
import './styles.scss';
import ConfirmModal from 'shared/components/ConfirmModal';
import orderBy from 'lodash/orderBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import { reduxForm, Field, getFormValues } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import DeviceBox from 'shared/components/DeviceBox';
import styles from './styles.module.scss';
import { translationService } from 'shared/translations/translations';
import TextField from '../../../../shared/components/TextField';

const LIST_FORM_NAME = 'devices/list';

class List extends PureComponent {
  translations = translationService.getTranslations();
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      deleteId: null,
    };
    // this.intervalID = setInterval(this.fetchD, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  componentDidMount() {
    this.props.fetchDevices();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deleteStates.isLoading && this.props.deleteStates.isLoaded) {
      this.setState({ isDeleteModalOpen: false, deleteId: null });
    }
  }

  fetchD = () => {
    this.props.fetchDevices();
  };

  goToCreate = () => {
    this.props.history.push(routes.devices.create.path);
  };

  toggleDeleteModal = id => e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      deleteId: id,
    });
  };

  deleteDevice = () => {
    this.props.deleteDevice({ id: this.state.deleteId });
  };

  deleteAlert = deviceId => e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.cleanDetect({ deviceId });
  };
  getData = () => {
    const { data, formValues } = this.props;
    const ordered = orderBy(data, ({ detect }) => !!detect, 'desc') || [];
    let result = ordered;

    if (formValues && formValues.place) {
      result = result.filter(device => device.placeId === formValues.place);
    }
    if (formValues && formValues.deviceType) {
      result = result.filter(device => device.deviceTypeId === formValues.deviceType);
    }
    if (formValues && formValues.zone) {
      result = result.filter(device => {
        return device.zone?.id === formValues.zone;
      });
    }
    if (formValues && formValues.uniqueId) {
      result = result.filter(device => {
        return String(device.uniqueId)
          .toLowerCase()
          .includes(String(formValues.uniqueId).toLowerCase());
      });
    }
    if (formValues && formValues.status) {
      if (formValues.status === 'last-signal')
        result = result.filter(device => {
          const lastSignal = get(device, 'deviceHistory[0].createdAt');
          const lastSignalInterval = get(device, 'place.radioErrorInterval');
          return dateLongerThanMinutes(lastSignal, lastSignalInterval);
        });

      if (formValues.status === 'monitoring') {
        result = result.filter(device => {
          const lastSignal = get(device, 'deviceHistory[0].createdAt');
          const lastSignalInterval = get(device, 'place.radioErrorInterval');
          return !dateLongerThanMinutes(lastSignal, lastSignalInterval) && lastSignal;
        });
        // result = result.filter((device) => !Number(device.detect));
      }

      if (formValues.status === 'alert') result = result.filter(device => !!Number(device.detect));
    }
    return result;
  };

  getPlaces = () => {
    const { data } = this.props;
    const places =
      uniqBy(
        flatMap(data, device => device.place).filter(place => !!place),
        'id',
      ) || [];
    return [
      { label: this.translations.global.all, value: '' },
      ...places.map(({ id, name }) => ({ label: name, value: id })),
    ];
  };

  getDeviceTypes = () => {
    const { data } = this.props;
    const deviceTypes =
      uniqBy(
        flatMap(data, device => device.deviceType).filter(deviceType => !!deviceType),
        'id',
      ) || [];
    return [
      { label: this.translations.global.all, value: '' },
      ...deviceTypes.map(({ id, name }) => ({ label: name, value: id })),
    ];
  };
  getZones = () => {
    const { data } = this.props;
    const zones =
      uniqBy(
        flatMap(data, device => device.zone).filter(zone => !!zone),
        'id',
      ) || [];
    return [
      { label: this.translations.global.all, value: '' },
      ...zones.map(({ id, name }) => ({ label: name, value: id })),
    ];
  };
  render() {
    const { deleteStates, isAdmin, isSuperAdmin, states } = this.props;
    const translations = translationService.getTranslations();
    
    return (
      <div>
        <ConfirmModal
          isOpen={this.state.isDeleteModalOpen}
          paragraph={
            <div>
              Czy napewno chcesz usunąć urządzenie o ID: {this.state.deleteId}.
              <br /> Cała historia zostanie usunięta razem z akcjami i szkodnikami.
            </div>
          }
          isLoading={deleteStates.isLoading}
          onClose={this.toggleDeleteModal(null)}
          onConfirm={this.deleteDevice}
          title="Usuń urządzenie"
          confirmLabel={translations.global.delete}
          cancelLabel={translations.global.cancel}
        />
        <ListHeading title={translations.global.devices}>
          {/* {isSuperAdmin && (
            <Button type="primary" onClick={this.goToCreate}>
              {translations.devices.addDevice}
            </Button>
          )} */}
        </ListHeading>

        <AppContent>
          <ContentLoader states={states}>
            <div className="row">
              <div className="col-md-12">
                <div className={styles.filters}>
                  <Field
                    component={SelectField}
                    name="place"
                    label={translations.global.facility}
                    dataSource={this.getPlaces()}
                  />
                  <Field
                    component={SelectField}
                    name="status"
                    label={translations.global.status}
                    dataSource={[
                      { label: translations.global.all, value: '' },
                      { label: translations.devices.alert, value: 'alert' },
                      { label: translations.devices.online, value: 'monitoring' },
                      { label: translations.devices.noSignal, value: 'last-signal' },
                    ]}
                  />
                  <div>
                    <Field
                      component={TextField}
                      name="uniqueId"
                      label={translations.devices.uniqueId}
                    />
                  </div>
                  <Field
                    component={SelectField}
                    name="zone"
                    label={translations.zones.zone}
                    dataSource={this.getZones()}
                  />
                  <Field
                    component={SelectField}
                    name="deviceType"
                    label={translations.global.deviceType}
                    dataSource={this.getDeviceTypes()}
                  />
                </div>
                <div className="device-boxes-wrapper">
                  {map(this.getData(), device => (
                    <DeviceBox
                      device={device}
                      key={device.id}
                      onClick={() => this.props.history.push(`/app/devices/edit/${device.id}`)}
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                      toggleDeleteModal={() => this.toggleDeleteModal(device.id)}
                      deleteAlert={e => {
                        this.deleteAlert(device.id)(e);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </ContentLoader>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ devices, auth, ...state }) => ({
  formValues: getFormValues(LIST_FORM_NAME)(state),
  data: devices.list.data,
  deleteStates: devices.manage.delete.states,
  states: devices.list.states,
  isSuperAdmin: isSuperAdminByAuthState(auth),
  isAdmin: isAnyAdminByAuthState(auth),
  isCompanyAdmin: isCompanyAdminByAuthState(auth),
  initialValues: {
    place: '',
    status: '',
  },
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchDevices, deleteDevice, cleanDetect }, dispatch);

export default compose(
  reduxForm({
    form: LIST_FORM_NAME,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(List);
