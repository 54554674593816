import { useMemo } from 'react';
import fuzzysort from 'fuzzysort';
import { User, UserType } from 'shared/types/User';

export const useFilteredUsers = (
  users: User[],
  searchFilter?: string,
  typeFilter?: UserType,
  companyFilter?: string,
) => {
  return useMemo(() => {
    let filteredUsers = users;

    if (typeFilter) {
      filteredUsers = filteredUsers.filter(user => user.type === typeFilter);
    }

    if (companyFilter) {
      filteredUsers = filteredUsers.filter(user => user.companyUuid === companyFilter);
    }

    if (searchFilter) {
      filteredUsers = filteredUsers.map(user => ({
        ...user,
        fullName: `${user.name || ''} ${user.surname || ''}`,
      }));

      filteredUsers = fuzzysort
        .go(searchFilter, filteredUsers, { keys: ['fullName', 'email'] })
        .map(result => result.obj);
    }

    return filteredUsers;
  }, [users, searchFilter, typeFilter, companyFilter]);
};
