import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { toast } from 'react-toastify';
import FileFieldClean from 'shared/components/FileFieldClean';

import { useTranslations } from 'shared/translations/useTranslations';

const FORM_NAME = 'place/FILES';
interface Props {
  placeId: string | number;
  categoriesData: any;
}

interface PlaceFile {
  id: number;
  createdAt: string;
  deletedAt: string | null;
  placeId: number;
  userId: number;
  name: string;
  note: true;
  downloadUrl: string;
}
interface State {
  files: PlaceFile[];
}

const AddFileForm = (props: Props) => {
  const translations = useTranslations();
  const { categoriesDataSource } = props;
  const [file, setFile] = useState();
  const [fileRaw, setFileRaw] = useState();
  const [isSaving, setSaving] = useState(false);
  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};

  const onChange = e => {
    setFileRaw(e);
    setFile({
      url: URL.createObjectURL(e),
      data: e,
      value: { name: e.name },
    });
  };
  const saveFile = async () => {
    if (!values || !values.name) {
      return;
    }
    try {
      setSaving(true);
      const formData = new FormData();
      formData.append('file', fileRaw);
      formData.append('name', values.name);
      formData.append('note', values.note);
      formData.append('fileCategoryId', values.fileCategoryId);
      await api({
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).post(`places/${props.placeId}/files`, formData);
      toast.success('Plik został wgrany');
      setTimeout(() => props.refetch(), 1000); // TODO: It seems that we need to wait for that response
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <h4>{translations.files.addFile}</h4>
        <div className="row">
          <div className="col-md-3">
            <FileFieldClean
              onChange={onChange}
              file={file}
              label={translations.global.file}
              accept="application/pdf"
            />
          </div>
          <div className="col-md-2">
            <Field component={TextField} name="name" label={translations.global.fileName} />
          </div>
          <div className="col-md-3">
            <Field component={TextField} name="note" label={translations.global.description} />
          </div>
          <div className="col-md-2">
            <Field
              component={SelectField}
              name="fileCategoryId"
              label={translations.global.category}
              dataSource={categoriesDataSource}
            />
          </div>
          <div className="col-md-2">
            <Button
              className={styles.uploadButton}
              onClick={saveFile}
              type="primary"
              isLoading={isSaving}>
              {translations.global.save}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    name: '',
    note: '',
  },
})(AddFileForm);
