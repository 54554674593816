import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Info } from '../Icons';
import './styles.scss';

class FieldLabel extends PureComponent {
  render() {
    const {
      children,
      tooltip,
      labelLeft,
      className,
      isRequired,
      htmlFor
    } = this.props;

    const labelClasses = classnames('FieldLabel', className, {
      'FieldLabel--left': labelLeft
    });

    return (
      <label className={labelClasses} htmlFor={htmlFor}>
        {children}
        {isRequired && '*'}
        {tooltip ? (
          <div className="FieldLabel__tooltip">
            <Info />
            <div className="FieldLabel__tooltip__content">{tooltip}</div>
          </div>
        ) : null}
      </label>
    );
  }
}

FieldLabel.propTypes = {};

export default FieldLabel;
