import React, { useEffect, useRef, useState } from 'react';
import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';

import { useForm } from '@mantine/form';
import apiService from '../../../../shared/services/api';
import AddCompanyFileCategoryForm from './AddCompanyFileCategoryForm';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  subCompanies: any[];
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
  categoryId: string;
}

const EditCompanyFileCategoryModal = (props: Props) => {
  const { companyId, categoryId } = props;

  const { data, isLoading, refresh, reset } = useFetch<any>({
    initialValue: { category: undefined },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/companies/${companyId}/files/categories/${categoryId}`),
  });

  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      subCompanies: [],
    },
  });

  useEffect(() => {
    if (categoryId) {
      refresh();
    } else {
      reset();
      form.reset();
    }
  }, [categoryId]);

  useEffect(() => {
    if (categoryId && data) {
      form.setValues({
        ...data.category,
        subCompanies: data.category?.subCompanies?.map(sc => sc.uuid),
      });
    }
  }, [categoryId, data]);

  const { subCompanies, onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const handleAdd = async formValues => {
    try {
      setSaving(true);
      const { data } = await apiService({}).patch(
        `/companies/${props.companyId}/files/categories/${categoryId}`,
        formValues,
      );
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };

  const translations = useTranslations();

  return (
    <Modal opened={opened} onClose={onClose} title={translations.global.editCategory}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanyFileCategoryForm form={form} subCompanies={subCompanies} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            {translations.global.save}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default EditCompanyFileCategoryModal;
