import { AppShell } from '@mantine/core';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, useParams } from 'react-router';

import Schedule from 'containers/Schedule';
import ContentLoader from 'shared/components/ContentLoader';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Place } from 'shared/types/Place';
import { selectIsControllerByAuthSate } from 'shared/utils/user';

import InterventionsRoutes from '../Interventions';
import Interventions from '../Interventions/List';
import Limits from '../Limits';
import PlaceDevices from '../PlaceDevices';
import PlaceHeatmap from '../PlaceHeatmap';
import PlaceSchedule from '../PlaceSchedule';
import PlaceVisualInspection from '../PlaceVisualInspection';
import Zones from '../Zones';
import Files from './FilesWrapper';
import Inspection from './Inspection/Inspection';
import Inspections from './Inspections';
import PlaceDetails from './PlaceDetails';
import PlaceMap from './PlaceMap';
import PlaceNavbar from './PlaceNavbar';
import RadioOptions from './RadioOptions';

import './styles.scss';

const Manage = () => {
  const { id: placeId } = useParams<{ id: string }>();

  const {
    isLoading,
    isLoaded,
    data,
    error,
    refresh: fetchPlace,
  } = useFetch<Place>({
    fetchAction: () => apiService({}).get(`/places/${placeId}`),
    fetchActionDeps: [placeId],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });
  useEffect(() => {
    if (placeId) {
      fetchPlace();
    }
  }, [placeId]);

  const isController = selectIsControllerByAuthSate();

  return (
    <ContentLoader
      states={{
        isLoaded,
        isLoading,
        hasError: error,
      }}
      isNew={!placeId}>
      <div className="Content">
        <AppShell
          styles={{
            main: {
              paddingLeft: 0,
            },
          }}
          navbar={<PlaceNavbar place={data} placeId={placeId} />}>
          <>
            <Route path={routes.places.create.path} component={PlaceDetails} exact />
            {isLoaded && (
              <>
                <Route path={routes.places.manage.path} component={PlaceDetails} exact />
                <Route path={routes.places.devices.default.path} component={PlaceDevices} />
                <Route
                  path={routes.places.radioOptions.default.path}
                  component={() => <RadioOptions placeId={placeId} />}
                />
                <Route
                  path={routes.places.files.default.path}
                  component={() => <Files placeId={placeId} />}
                />
                <Route
                  path={routes.places.map.default.path}
                  component={() => (
                    <DndProvider backend={HTML5Backend}>
                      {/*//@ts-ignore*/}
                      <PlaceMap placeId={placeId} devices={data?.devices} />
                    </DndProvider>
                  )}
                />
                <Route
                  path={routes.places.heatmap.default.path}
                  component={() => <PlaceHeatmap placeUuid={placeId} />}
                />
                {!isController && (
                  <Route
                    exact
                    path={routes.places.inspections.default.path}
                    component={() => <Inspections placeId={placeId} />}
                  />
                )}
                {!isController && (
                  <Route
                    exact
                    path={routes.places.inspections.manage.path}
                    component={() => <Inspection placeId={placeId} />}
                  />
                )}
                <Route
                  path={routes.places.visualInspection.default.path}
                  component={() => <PlaceVisualInspection placeId={placeId} />}
                />
                <InterventionsRoutes place={data} />
                {!isController && (
                  <Route
                    path={routes.places.zones.default.path}
                    component={() => <Zones placeId={placeId} />}
                  />
                )}
                <Route
                  path={routes.places.objectsLimit.default.path}
                  component={() => <Limits placeId={placeId} />}
                />
                <Route
                  path={routes.places.schedule.default.path}
                  component={() => (data ? <PlaceSchedule place={data} /> : null)}
                />
              </>
            )}
          </>
        </AppShell>
      </div>
    </ContentLoader>
  );
};

export default Manage;
