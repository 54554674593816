import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Loader, Modal } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { get } from 'lodash';
import DeviceTypeObservationForm from '../DeviceTypeObservationForm';
import { mapDeviceTypeObservationFormValuesToApiBody } from '../DeviceTypeObservationForm/device.type.observation.form.mapper';

const getInitialValues = observation => {
  return {
    name: observation?.name,
    type: observation?.type,
    translations: observation?.translations
      ? [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ].map((translation, key) => {
          return {
            ...translation,
            name: get(observation?.translations, `${translation.lang}.name`),
            description: get(observation?.translations, `${translation.lang}.description`),
          };
        })
      : [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ],
  };
};

const EditDeviceTypeObservationModal = ({
  id,
  onFinish,
  deviceTypeId = undefined,
  currentObservations = [],
  close,
}) => {
  const translations = useTranslations();
  const isOpen = !!id;
  const [isSaving, setSaving] = useState<boolean>();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<{ deviceTypeObservation: any[] | undefined }>({
    deviceTypeObservation: undefined,
  });
  const observation = data?.deviceTypeObservation;

  const form = useForm({
    initialValues: getInitialValues(observation),
  });

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`/api/v3/device-type-observations/${id}`);
      setData({ deviceTypeObservation: data });
      form.setValues(getInitialValues(data?.observation));
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, [id, form]);

  useEffect(() => {
    if (id && isOpen) {
      fetchDetails();
    } else {
      setLoaded(false);
      setLoading(false);
    }
  }, [id, isOpen]);

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = mapDeviceTypeObservationFormValuesToApiBody({ formValues, deviceTypeId });
      await apiService({}).patch(`/api/v3/device-type-observations/${id}`, body);
      close();
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.editObservationSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => close()}
      title={translations.deviceTypes.editObservationTitle}>
      {isLoading && <Loader />}
      {isLoaded && !isLoading && (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <DeviceTypeObservationForm
            isLoading={isLoading}
            close={close}
            form={form}
            currentObservations={currentObservations}
            currentId={id}
          />
        </form>
      )}
    </Modal>
  );
};

export default EditDeviceTypeObservationModal;
