import React from 'react';
import { Route } from 'react-router';

import { routes } from 'shared/constants/routes';

import List from './containers/List';
import Manage from './containers/Manage';
import Inspection from './containers/Manage/Inspection/Inspection';

import './styles.scss';

export default () => [
  <Route path={routes.places.default.path} key="places-default" component={List} exact />,
  <Route path={routes.places.manage.path} key="places-manage" component={Manage} />,
  <Route path={routes.places.create.path} key="places-create" component={Manage} exact />,
  // <Route
  //   path={routes.places.inspection.path}
  //   key="places-inspection"
  //   component={Inspection}
  //   exact
  // />
];
