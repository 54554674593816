import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from '../../../../../shared/services/api';

const DeleteCategoryModal = ({ onFinish, isOpen, close, placeId, categoryId }) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState(false);
  const onDelete = async () => {
    try {
      setLoading(true);
      close();
      await apiService({}).delete(`places/${placeId}/files-categories/${categoryId}`);
      setLoading(false);
      onFinish();
      toast.success(translations.deviceTypes.deleteActionSuccess);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <ConfirmModal
      isOpen={isOpen}
      paragraph={
        <div>
          {translations.deviceTypes.deleteActionDesc}
          <br />
        </div>
      }
      isLoading={isLoading}
      onClose={() => close()}
      onConfirm={onDelete}
      title={translations.deviceTypes.deleteActionTitle}
      confirmLabel={translations.global.delete}
      cancelLabel={translations.global.cancel}
    />
  );
};

export default DeleteCategoryModal;
