import React from 'react';
import './styles.scss';

export default ({ title, children = null, footer = undefined }) => (
  <div className="ListHeading">
    <div className="ListHeading__wrapper">
      <h1>{title}</h1>
      <div className="ListHeading__content">{children}</div>
    </div>
    {footer && <div className="ListHeading__footer">{footer}</div>}
  </div>
);
