import React, { useCallback, useState } from 'react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import classNames from 'classnames';
import { ActionIcon, Button, Flex, Group, Image, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Trash, Upload } from 'tabler-icons-react';
import { useTranslations } from 'shared/translations/useTranslations';

import { ImagesUploadProps } from './types';

import styles from './styles.module.scss';

const ImagesUpload = (props: ImagesUploadProps) => {
  const { files = [], setFiles, savedImages = [], onDeleteSavedImage } = props;
  const translations = useTranslations();

  const [opened, { open, close }] = useDisclosure();
  const [imageToDelete, setImageToDelete] = useState<string>(null);

  const openModal = (imageToDelete: string) => {
    setImageToDelete(imageToDelete);
    open();
  };

  const closeModal = () => {
    setImageToDelete(null);
    close();
  };

  const onChange = selectedFiles => {
    const newFiles = files.concat(
      selectedFiles.map(file => ({
        url: URL.createObjectURL(file),
        data: file,
      })),
    );
    setFiles(newFiles);
  };

  const allImages = [...savedImages, ...files];

  const onClickDeleteFile = useCallback(
    (file: (typeof allImages)[number]) => () => {
      //@ts-ignore
      if (file.id) {
        //@ts-ignore
        openModal(file.id);
        return;
      }
      const newFiles = files.filter(f => f.url !== file.url);
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  const dropzoneClassNames = classNames(styles.dropzone, {
    [styles.dropzoneSelectedFiles]: files.length > 0,
  });

  const [isDeletingSavedImage, setIsDeletingSavedImage] = useState(false);
  const onConfirmDeleteSavedImage = useCallback(async () => {
    if (!imageToDelete) {
      return;
    }
    setIsDeletingSavedImage(true);
    await onDeleteSavedImage(imageToDelete);
    setIsDeletingSavedImage(false);
    setImageToDelete(null);
    closeModal();
  }, [setIsDeletingSavedImage, imageToDelete, setImageToDelete, closeModal]);

  return (
    <div className={styles.imagesUpload}>
      <Flex gap={24}>
        {allImages?.map((file, index) => {
          return (
            <div key={`preview-${file.url}`} className={styles.preview}>
              <ActionIcon
                onClick={onClickDeleteFile(file)}
                className={styles.deleteIcon}
                variant="light"
                color="green"
                size="lg">
                <Trash size="1rem" />
              </ActionIcon>
              <Image className={styles.imagePreview} key={index} src={file.url} fit="cover" />
            </div>
          );
        })}
        {allImages.length < 3 && (
          <Dropzone
            className={dropzoneClassNames}
            onDrop={onChange}
            accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
            multiple={true}
            maxFiles={3}>
            <Group position="center" spacing="sm" style={{ minHeight: 120 }}>
              <Group position="center">
                <Upload />
                <div>{translations.global.selectFile}</div>
              </Group>
            </Group>
          </Dropzone>
        )}
      </Flex>
      <Modal opened={opened} onClose={close} title={translations.devices.deleteImageTitle} centered>
        <Text mb={20}>{translations.intervention.deleteSavedImage}</Text>
        <Flex gap={10} justify="end">
          <Button onClick={closeModal} variant="white" disabled={isDeletingSavedImage}>
            {translations.global.no}, {translations.global.cancel}
          </Button>
          <Button color="red" loading={isDeletingSavedImage} onClick={onConfirmDeleteSavedImage}>
            {translations.global.yes}, {translations.global.delete}
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default ImagesUpload;
