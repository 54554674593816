import { DependencyList, useCallback, useState } from 'react';
import apiService from 'shared/services/api';

interface Props {
  endpoint?: string;
  additionalDeps?: DependencyList;
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
}

const useDelete = (props: Props) => {
  const { endpoint, additionalDeps, onSuccessCallback, onErrorCallback } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [error, setError] = useState(false);

  const deleteFn = useCallback(async () => {
    if (!endpoint) {
      return;
    }
    try {
      setError(false);
      setIsDeleting(true);
      await apiService({}).delete(endpoint);
      setIsDeleting(false);
      setIsDeleted(true);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    } catch {
      setIsDeleting(false);
      setError(true);
      setIsDeleted(false);
      if (onErrorCallback) {
        onErrorCallback();
      }
    }
  }, [endpoint, onSuccessCallback, onErrorCallback, ...(additionalDeps || [])]);

  return {
    isDeleting,
    isDeleted,
    error,
    deleteFn,
  };
};

export default useDelete;
