import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ListHeading from '../../../../shared/components/ListHeading';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import AddDeviceTypeAction from '../../../DeviceType/containers/AddDeviceTypeAction';
import EditDeviceTypeActionModal from '../../../DeviceType/containers/EditDeviceTypeActionModal';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import apiService from '../../../../shared/services/api';
import { Badge, Button, Group } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import DeleteDeviceTypeAction from '../../../DeviceType/containers/DeleteDeviceTypeAction';

function AdminDevicesActions() {
  const translations = useTranslations();
  const history = useHistory();
  const reset = () => {
    fetchDetails();
  };
  const [actionToEdit, setActionToEdit] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [deleteAction, setDeleteAction] = useState();
  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`/device-type-actions?parentCompanyId=ROOT`);
      setData(data);
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <EditDeviceTypeActionModal
        id={actionToEdit?.id}
        // action={actionToEdit}
        onFinish={reset}
        close={() => setActionToEdit(undefined)}
      />
      <DeleteDeviceTypeAction
        id={deleteAction?.id}
        isOpen={!!deleteAction}
        onFinish={reset}
        close={() => {
          setDeleteAction(undefined);
        }}
      />
      <ListHeading title={translations.devices.actions}>
        <AddDeviceTypeAction onFinish={reset} />
      </ListHeading>

      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{translations.global.name}</Th>
            <Th>{translations.devices.cleanAction}</Th>
            <Th>{translations.devices.inspection}</Th>
            <Th>Automatyczna akcja podczas montazu</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(action => (
            <Tr key={action.id}>
              <Td>{action.id}</Td>
              <Td>{action.name}</Td>

              <Td>
                {action.shouldClean ? (
                  <Badge>{translations.global.yes}</Badge>
                ) : (
                  translations.global.no
                )}
              </Td>
              <Td>
                {action.isInspection ? (
                  <Badge>{translations.global.yes} </Badge>
                ) : (
                  translations.global.no
                )}
              </Td>
              <Td>
                {action.settings?.addWhenDeviceAddedToInspection ? (
                  <Badge>{translations.global.yes}</Badge>
                ) : (
                  translations.global.no
                )}
              </Td>
              <Td>
                <Group>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActionToEdit(action);
                    }}
                    compact
                    size="xs"
                    color="blue">
                    <Pencil size={14} />
                  </Button>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDeleteAction(action);
                    }}
                    compact
                    size="xs"
                    color="red">
                    <Trash size={14} />
                  </Button>
                </Group>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default AdminDevicesActions;
