import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';
import FieldError from '../FieldError';
import styles from './styles.module.scss';

class CheckboxField extends PureComponent {
  render() {
    const {
      input,
      meta,
      noMarginTop,
      className,
      disabled,
      children,
      isLight,
      id,
      noMargin,
      labelLeft,
      small,
      ...props
    } = this.props;

    const inputClasses = classnames(styles.CheckboxFieldsInput, className, {
      [styles.CheckboxFieldsInputSmall]: small,
    });
    const inputLabelClasses = classnames(styles.CheckboxFieldsInputLabel, className, {
      [styles.CheckboxFieldsInputLabelDisabled]: disabled,
      [styles.CheckboxFieldsInputLabelSmall]: small,
    });
    return (
      <div className={inputClasses}>
        <input
          type="checkbox"
          id={id}
          {...input}
          {...props}
          checked={input.value}
          className={styles.CheckboxFieldsInputField}
        />
        <label htmlFor={id} className={inputLabelClasses}>
          {children}
        </label>
      </div>
    );
    // return (
    //   <label className={classes} htmlFor={id}>
    //     <input
    //       type="checkbox"
    //       id={id}
    //       {...input}
    //       {...props}
    //       className={inputClasses}
    //       disabled={disabled}
    //     />
    //     <div className={boxClasses}>&#10003;</div>
    //     <span className="CheckboxField__label">{children}</span>
    //     <FieldError meta={meta} />
    //   </label>
    // );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  isLight: PropTypes.bool,
};

export default CheckboxField;
