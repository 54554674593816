import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { TextInput, Button, Title, LoadingOverlay, Textarea } from '@mantine/core';
import { useParams } from 'react-router';

import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import useFetch from 'shared/hooks/useFetch';
import { useOnSubmit } from './hooks';

function generateDevEUI() {
  let devEUI = '';
  for (let i = 0; i < 8; i++) {
    let randomByte = Math.floor(Math.random() * 256);
    devEUI += ('0' + randomByte.toString(16)).slice(-2).toUpperCase();
  }
  return devEUI;
}

const Manage = () => {
  const form = useForm({
    initialValues: {
      name: ``,
      description: '',
      devEui: generateDevEUI(),
    },
  });

  const { loraDeviceUuid } = useParams<{
    loraDeviceUuid?: string;
  }>();

  const translations = useTranslations();

  const { submitValues, isSubmittingForm } = useOnSubmit(loraDeviceUuid);

  const onSubmit = async values => {
    submitValues(values);
  };

  // const title = loraDeviceUuid
  //   ? translations.loraDevice.editLoraDevice
  //   : translations.loraDevice.createLoraDevice;

  const title = `${translations.devices.addDevice} Lora`;
  const {
    isLoading,
    isLoaded,
    data: loraDevice,
    error,
    refresh: fetchLoraDevice,
  } = useFetch<{ loraDevice: LoraDevice }>({
    fetchAction: () => apiService({}).get(`/api/lora/devices/${loraDeviceUuid}`),
    fetchActionDeps: [loraDeviceUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });

  useEffect(() => {
    if (loraDeviceUuid) {
      fetchLoraDevice();
    }
  }, [loraDeviceUuid]);

  useEffect(() => {
    if (loraDevice) {
      //@ts-ignore
      form.setValues(loraDevice?.loraDevice);
    }
  }, [loraDevice]);

  return (
    <AppContent>
      <Title mb={20}>{title}</Title>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          label={translations.global.name}
          name="name"
          mb={20}
          {...form.getInputProps('name')}
        />
        <Textarea
          autosize
          label={translations.global.description}
          name="description"
          mb={20}
          {...form.getInputProps('description')}
        />
        <TextInput
          label={translations.loraDevice.devEui}
          name="devEui"
          required
          mb={20}
          {...form.getInputProps('devEui')}
        />

        <Button type="submit" loading={isSubmittingForm}>
          {translations.global.save}
        </Button>
      </form>
    </AppContent>
  );
};

export default Manage;
