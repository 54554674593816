import React from 'react';
import { Radio, Textarea, TextInput, Title } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import TranslationForm from 'shared/components/TranslationForm';

interface Props {
  form: any;
}

const Form = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();
  return (
    <>
      <TextInput
        label={translations.visualInspection.question}
        name="question"
        required
        mb={20}
        {...form.getInputProps('question')}
      />
      <Textarea
        label={translations.visualInspection.comment}
        required
        name="comment"
        mb={20}
        {...form.getInputProps('comment')}
      />
      <Radio.Group
        label={translations.visualInspection.defaultAnswer}
        name="defaultAnswer"
        mb={20}
        {...form.getInputProps('defaultAnswer')}
        required>
        <Radio value="YES" label={translations.global.yes} />
        <Radio value="NO" label={translations.global.no} />
      </Radio.Group>
      <TranslationForm
        formTitle={translations.global.translations}
        languageKey="lang"
        fieldsLabel={translations.visualInspection.question}
        translationsKey="translations"
        fieldKey="question"
        form={form}
      />
    </>
  );
};

export default Form;
