import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'shared/components/Button';
import {
  Badge,
  Skeleton,
  Grid,
  Breadcrumbs,
  Timeline,
  Text,
  Drawer,
  Modal,
  ScrollArea,
  Anchor,
} from '@mantine/core';
import api, { API_URL } from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useHistory, useParams } from 'react-router';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import DeleteRecommendationModal from '../DeleteRecommendationModal';
import {
  getColorByRecommendationPriority,
  getColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import { get, map, reverse, sortBy } from 'lodash';
import DetailsRow from './DetailsRow';
import { dateFromNow, readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { Link } from 'react-router-dom';
import AddCommentDrawer from '../AddCommentDrawer';
import CompareHistoryModal from './CompareHistoryModal';
import AddFileDrawer from '../AddFileDrawer';
import DeleteFileModal from '../DeleteFileModal';
import styles from './styles.module.scss';

interface Props {
  recommendation: any;
}

function getMeta(url, setMapSize) {
  const img = new Image();
  img.addEventListener('load', function () {
    setMapSize({ width: this.naturalWidth, height: this.naturalHeight });
  });
  img.src = url;
}

const DetailsMap = (props: Props) => {
  const { recommendation } = props;
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  const url = useMemo(
    () => (recommendation?.map?.url ? `${API_URL}/files/${recommendation?.map?.url}` : undefined),
    [recommendation],
  );

  useEffect(() => {
    if (url) {
      getMeta(url, setMapSize);
    }
  }, [url]);

  const [renderMapWidth, setRenderMapWidth] = useState(0);
  const [renderMapHeight, setRenderMapHeight] = useState(100);
  const aspectRatio = mapSize.height / mapSize.width;

  const percentageMultiplyHeight = renderMapHeight / 2 / mapSize.height;
  const percentageMultiplyWidth = renderMapWidth / 2 / mapSize.width;

  const top =
    Number(recommendation.mapY) >= 0
      ? (mapSize.height - Number(recommendation.mapY)) * percentageMultiplyHeight
      : (mapSize.height - Number(recommendation.mapY)) * percentageMultiplyHeight;
  const left =
    Number(recommendation.mapX) > 0
      ? Number(recommendation.mapX) * percentageMultiplyWidth + renderMapWidth / 2
      : Number(recommendation.mapX) * percentageMultiplyWidth + renderMapWidth / 2;
  const topCalculated = top;
  const leftCalculated = left;
  const imgr = useRef();

  const onLoad = () => {
    setRenderMapWidth(imgr?.current?.offsetWidth);
  };

  useEffect(() => {
    setRenderMapHeight(aspectRatio * renderMapWidth);
  }, [aspectRatio, setRenderMapHeight, renderMapWidth]);
  useEffect(() => {
    function handleResize() {
      onLoad();
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {url && recommendation.mapY && recommendation.mapY ? (
        <div style={{ position: 'relative', height: renderMapHeight, width: '100%' }}>
          <div
            style={{
              position: 'absolute',
              width: 30,
              marginTop: -15,
              marginLeft: -15,
              height: 30,
              top: `${topCalculated}px`,
              left: `${leftCalculated}px`,
              background: '#12b886',
              borderRadius: '50%',
            }}></div>
          <img
            src={url}
            style={{ width: '100%', height: renderMapHeight }}
            ref={imgr}
            onLoad={onLoad}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DetailsMap;
