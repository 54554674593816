import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { routes } from 'shared/constants/routes';
import { isCompanyAdminByAuthState } from 'shared/utils/user';
import List from './containers/List';
import CompanyAdminList from './containers/CompanyAdminList';
import Manage from './containers/Manage';
import './styles.scss';

export default () => {
  const isCompanyAdmin = useSelector((state) =>
    isCompanyAdminByAuthState(state.auth)
  );
  return [
    <Route
      path={routes.companies.default.path}
      key="companies-default"
      component={isCompanyAdmin ? CompanyAdminList : List}
      exact
    />,
    <Route
      path={routes.companies.manage.path}
      key="companies-manage"
      component={Manage}
      exact
    />,
    <Route
      path={routes.companies.create.path}
      key="companies-create"
      component={Manage}
      exact
    />,
  ];
};
