import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import get from 'lodash/get';

import { useForm } from '@mantine/form';

import { TextInput, Textarea, Button, Select } from '@mantine/core';

import FormSection from 'shared/components/FormSection';
import { selectIsAnyAdmin, selectIsControllerByAuthSate } from 'shared/utils/user';
import { translationService } from 'shared/translations/translations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import useSave from 'shared/hooks/useSave';
import { routes } from 'shared/constants/routes';
import ListHeading from 'shared/components/ListHeading';

import ManageSelectCompany from 'modules/Place/containers/ManageSelectCompany';

import DeletePlace from '../DeletePlace';
import PlaceUsers from '../PlaceUsers';
import validate from './formValidate';

import './styles.scss';
import { CompanyZone } from '../../../Company/containers/Manage/Zones/types';
import { formatZonesToDataOptions } from '../../../Company/containers/Manage/Zones/helpers';

const PlaceDetails = () => {
  const [tab, setTab] = useState('details');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (search && params.tab) {
      setTab(params.tab);
      return;
    }
  }, []);

  const { id: placeId } = useParams<{ id: string }>();

  const {
    isLoading,
    isLoaded,
    data,
    error,
    refresh: fetchPlace,
  } = useFetch({
    fetchAction: () => apiService({}).get(`/places/${placeId}`),
    fetchActionDeps: [placeId],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });
  useEffect(() => {
    if (placeId) {
      fetchPlace();
    }
  }, [placeId]);

  const { save: savePlace, isLoading: isSaving } = useSave({
    patchEndpoint: `/places/${placeId}`,
    postEndpoint: '/places',
    isPatch: placeId !== undefined,
    additionalDeps: [placeId],
    onSuccessCallback: (newData: any) => {
      history.replace(routes.places.manage.to(newData.uuid));
    },
  });
  const submit = formValues => {
    savePlace({
      ...formValues,
      radioErrorInterval: Number(formValues.radioErrorInterval || 0),
    });
  };

  const form = useForm({
    initialValues: placeId ? data : { radioErrorInterval: 15, companyId: undefined },
    validate,
  });

  useEffect(() => {
    // Initialize form values when data is available
    if (data && Object.keys(data).length > 0) {
      form.setValues(data);
    }
  }, [data]);

  const isAdmin = selectIsAnyAdmin();
  const isController = selectIsControllerByAuthSate();

  const translations = translationService.getTranslations();
  const disabled = isController;
  const disabledClientSelector = !!placeId;

  const { data: zonesData, refresh: fetchZones } = useFetch<{
    zones: CompanyZone[];
  }>({
    initialValue: { zones: [] },
    fetchAction: () => apiService({}).get(`/companies/${form.values.companyUuid}/zones`),
    shouldNotFetchAtStart: true,
  });
  useEffect(() => {
    if (form.values.companyUuid) {
      fetchZones();
    }
  }, [form.values.companyUuid]);
  const zonesDataOptions = useMemo(() => {
    return formatZonesToDataOptions(zonesData.zones || []);
  }, [zonesData.zones]);

  return (
    <div className="">
      <form onSubmit={form.onSubmit(submit)}>
        <ListHeading
          title={
            <div>
              {!placeId && <div className="HeaderTextField__input">{translations.global.add}</div>}
              {placeId && <div className="HeaderTextField__input">{get(data, 'name')}</div>}
            </div>
          }>
          {!isController && (
            <div className="CampaignHeader__right">
              {placeId && isAdmin && <DeletePlace id={placeId} />}
              <Button loading={isSaving} type="submit">
                {translations.global.save}
              </Button>
            </div>
          )}
        </ListHeading>
        <div className="ManageSurvey" style={{ marginBottom: '30px' }}>
          <FormSection isActive>
            <div className="row">
              <div className="col-md-7">
                <TextInput
                  name="name"
                  label={translations.global.name}
                  required
                  disabled={disabled}
                  mb={20}
                  {...form.getInputProps('name')}
                />
                <Textarea
                  name="address"
                  label={translations.global.address}
                  required
                  autosize
                  minRows={5}
                  disabled={disabled}
                  mb={20}
                  {...form.getInputProps('address')}
                />
                <TextInput
                  name="radioErrorInterval"
                  label={translations.places.maxTimeForDeviceInMin}
                  required
                  disabled={disabled}
                  mb={20}
                  {...form.getInputProps('radioErrorInterval')}
                />
                <ManageSelectCompany disabled={disabledClientSelector} form={form} />
                <Select
                  label="Strefa"
                  data={zonesDataOptions}
                  mb={20}
                  name="companyZoneUuid"
                  {...form.getInputProps('companyZoneUuid')}
                />
              </div>
            </div>
          </FormSection>
        </div>
      </form>
      {!isController && !!placeId && (
        <FormSection isActive>
          <PlaceUsers placeId={placeId} />
        </FormSection>
      )}
    </div>
  );
};

export default PlaceDetails;
