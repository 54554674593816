import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, LoadingOverlay } from '@mantine/core';
import QRCode from 'qrcode';
import { useParams } from 'react-router';

import api, { apiService } from 'shared/services/api';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { readableDate } from 'shared/utils/date';
import { saveAs } from 'file-saver';

interface Props {
  id?: string;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  id: string;
  amount: number;
  used: boolean;
  usedAmount: number;
  name: string;
  company?: any;
  user?: any;
  createdAt: Date;
}
interface CodesData {
  codes: {
    id: string;
    used: boolean;
  }[];
}
const Details = (props: Props) => {
  const { refreshParent, onClose } = props;
  const { batchId } = useParams<{ batchId: string }>();
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: {},
    // shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/qrcodes/batches/${batchId}`),
  });
  const {
    data: codesData,
    isLoading: isLoadingCodes,
    isLoaded: isLoadedCodes,
    error: codesError,
  } = useFetch<CodesData>({
    initialValue: { codes: [] },
    fetchAction: () => api({}).get(`/api/qrcodes/batches/${batchId}/codes`),
  });

  const [unUsedCodes, setUnUsedCodes] = useState([]);

  const mapQrCodesToDataUrl = async (codes: any[]) => {
    const result = await Promise.all(
      codes.map(async code => {
        const value = await QRCode.toDataURL(code.id);
        return { ...code, base64value: value };
      }),
    );
    setUnUsedCodes(result);
  };

  useEffect(() => {
    const unused = codesData?.codes?.filter(code => !code.used) || [];
    mapQrCodesToDataUrl(unused);
  }, [codesData.codes]);

  const [isDownloading, setDownloading] = useState(false);
  const [isDownloadingError, setDownloadingError] = useState(false);
  const downloadPdf = async () => {
    try {
      setDownloadingError(false);
      setDownloading(true);
      const response = await apiService({
        baseUrl: process.env.REACT_APP_BACKEND_PDF_URL,
      }).get(`/api/qrcodes/batches/${batchId}/pdf`, {
        responseType: 'blob',
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      saveAs(pdfBlob, 'qr-codes.pdf');
    } catch (error) {
      setDownloadingError(true);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <ListHeading title={translations.qrCodes.title}></ListHeading>
        <AppContent>
          <Grid>
            <Grid.Col span={4}>
              <div>
                {translations.global.name}: {data.name}
              </div>
              <div>
                {translations.global.company}: {data.company?.name}
              </div>
              <div>
                Uzycie: {data.usedAmount} / {data.amount}
              </div>
              <div>
                {translations.global.createdAt}: {readableDate(data.createdAt)}
              </div>
              <div>
                {translations.global.user}:{' '}
                {data.user ? `${data.user?.name} ${data.user.surname}` : '-'}
              </div>
              <LoadingOverlay visible={isLoading} />
              {isLoadingCodes && <div>Wczytuję kody QR...</div>}
              {isLoadedCodes && unUsedCodes.length === 0 && <div>Brak dostępnych kodów QR</div>}
              {isLoadedCodes && unUsedCodes.length > 0 && (
                <Button onClick={downloadPdf} loading={isDownloading}>
                  Pobierz plik pdf z niewykorzystanymi kodami QR
                </Button>
              )}
              {isDownloadingError && (
                <Alert color="red">{translations.global.somethingWentWrong}</Alert>
              )}
            </Grid.Col>
            <Grid.Col span={4}></Grid.Col>
          </Grid>
        </AppContent>
      </div>
    </>
  );
};

export default Details;
