import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionIcon,
  AppShell,
  Badge,
  Button,
  Flex,
  Navbar,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';

import AppContent from 'shared/components/AppContent';
import { Bell as IconBell } from 'tabler-icons-react';
import useFetch from '../../../../shared/hooks/useFetch';
import { NotificationsResponse } from '../../../../shared/types/UserNotification';
import apiService from '../../../../shared/services/api';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import styles from './styles.module.scss';

import moment from 'moment';
import { getNotificationData, getNotificationIcon, getNotificationIconColor } from './helpers';

const Notifications = () => {
  const { data, isLoading, isLoaded, error, refresh } = useFetch<NotificationsResponse>({
    initialValue: { rows: [], count: 0 },
    fetchAction: () => apiService({}).get(`/account/notifications`),
  });
  const [isClearing, setIsClearing] = useState(false);
  const translations = useTranslations();
  const handleClear = (uuid: string) => async () => {
    try {
      setIsClearing(true);
      await apiService({}).post(`/account/notifications/clear`, {
        notificationUuids: [uuid],
      });
      refresh();
    } catch {
    } finally {
      setIsClearing(false);
    }
  };
  const handleAllClear = async () => {
    try {
      setIsClearing(true);
      await apiService({}).post(`/account/notifications/clear`, {
        notificationUuids: data.rows
          .filter(notification => !notification.isRead)
          .map(notification => notification.uuid),
      });
      refresh();
    } catch {
    } finally {
      setIsClearing(false);
    }
  };
  const hasUnreadNotifications = data.rows.filter(n => !n.isRead).length > 0;

  return (
    <AppContent className={styles.content}>
      <AppShell
        styles={{
          main: {
            paddingLeft: 0,
          },
        }}>
        <>
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'sm', sm: 'lg' }}
            justify={{ sm: 'center' }}
            mb={20}>
            <Title order={2} className={styles.navigationTitle}>
              <Flex align-items="center">
                <ThemeIcon mr={10} variant="white" radius="xs" size="xl">
                  <IconBell size={30} />
                </ThemeIcon>
                {translations.notifications.notifications}
              </Flex>
            </Title>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                variant="outline"
                disabled={isClearing || !hasUnreadNotifications}
                onClick={handleAllClear}>
                {translations.notifications.setAllAsRead}
              </Button>
            </div>
          </Flex>
          {data?.rows?.map(notification => {
            const notificationData = getNotificationData(notification);

            return (
              <button
                className={styles.notification}
                onClick={notification.isRead ? null : handleClear(notification.uuid)}
                key={notification.uuid}>
                <div className={styles.notificationIcon}>
                  <ThemeIcon
                    color= {getNotificationIconColor(notification.notificationKey)}
                    size={44}
                    radius="xl"
                    variant={notification.isRead ? 'default' : 'filled'}>
                    {getNotificationIcon(notification.notificationKey)}
                  </ThemeIcon>
                </div>
                <div className={styles.notificationBody}>
                  <div className={styles.notificationTitle}>{notificationData.title}</div>
                  <div
                    className={styles.notificationDescription}
                    dangerouslySetInnerHTML={{ __html: notificationData.body }}></div>
                </div>
                <div className={styles.notificationTime}>
                  <Badge
                    color={notification.isRead ? 'gray' : 'default'}
                    variant={notification.isRead ? 'light' : 'light'}>
                    {moment(notification.createdAt).fromNow()}
                  </Badge>
                </div>
              </button>
            );
          })}
        </>
      </AppShell>
    </AppContent>
  );
};

export default Notifications;
