import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import FieldWrapper from '../FieldWrapper';
import './styles.scss';
import { Upload } from '../Icons';
import { translationService } from 'shared/translations/translations';

class FileFieldClean extends PureComponent {
  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
  onChange = e => {
    e.preventDefault();
    this.props.onChange(e.target.files[0]);
  };

  triggerFile = () => {
    this.refs.file.click();
  };

  render() {
    const translations = translationService.getTranslations();
    const { file, disabled, isRequired, hiddenDisabled, isLoading, accept, label } = this.props;
    const selectedName = get(file, 'value.name') || translations.global.selectFile;
    const inputClasses = classnames('FileField__input', {
      'FileField__input--disabled': disabled && !hiddenDisabled,
      'FileField__input--required': isRequired,
    });
    const placeholderClasses = classnames('FileField__placeholder', {
      'FileField__placeholder--selected': get(file, 'value.name'),
    });

    return (
      <FieldWrapper meta={{}} input={{}} onClick={this.onClick} isLoading={isLoading} label={label}>
        <input
          type="file"
          onChange={this.onChange}
          className={inputClasses}
          disabled={disabled}
          ref="file"
          accept={accept}
        />
        <div className={placeholderClasses}>{selectedName}</div>
        <button className="FileField__button" type="button" onClick={this.triggerFile}>
          <Upload />
        </button>
      </FieldWrapper>
    );
  }
}

FileFieldClean.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelLeft: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOf(['scope', 'arrow_down']),
};

export default FileFieldClean;
