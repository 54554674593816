import { Button } from '@mantine/core';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import DeviceTypesList from '../../../../shared/components/devices/types/DeviceTypesList';
import ListHeading from '../../../../shared/components/ListHeading';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import Details from '../../../DeviceType/containers/ManageV2';

function AdminDevicesTypes() {
  const translations = useTranslations();
  const history = useHistory();
  return (
    <>
      <Switch>
        <Route path={[routes.admin.devices.types.path]} exact>
          <ListHeading title={translations.global.deviceTypes}>
            <Button onClick={() => history.push(routes.admin.devices.types.create.path)}>
              Dodaj
            </Button>
          </ListHeading>
          <DeviceTypesList
            onRowClick={id => {
              history.push(routes.admin.devices.types.manage(id).path);
            }}
          />
        </Route>
        <Route
          path={routes.admin.devices.types.manage(':id').path}
          component={props => <Details {...props} />}
          exact
        />
        <Route
          path={routes.admin.devices.types.create.path}
          component={props => (
            <Details
              {...props}
              onCreate={id => {
                history.push(routes.admin.devices.types.manage(id).path);
              }}
            />
          )}
          exact
        />
      </Switch>
    </>
  );
}

export default AdminDevicesTypes;
