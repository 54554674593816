import React, { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import {
  Flex,
  TextInput,
  Text,
  NumberInput,
  Select,
  ActionIcon,
  Button,
  Title,
  LoadingOverlay,
  Textarea,
} from '@mantine/core';
import { Plus, Trash } from 'tabler-icons-react';
import { useParams } from 'react-router';
import groupBy from 'lodash/groupBy';

import AppContent from 'shared/components/AppContent';
import { readableDate } from 'shared/utils/date';
import ImagesUpload from 'shared/components/ImagesUpload';
import { useTranslations } from 'shared/translations/useTranslations';
import { ImageFile } from 'shared/components/ImagesUpload/types';
import apiService from 'shared/services/api';
import { Intervention, InterventionImage } from 'shared/types/Intervention';
import useFetch from 'shared/hooks/useFetch';

import { unitValues } from './constants';
import { useDeleteSavedImage, useOnSubmit } from './hooks';

const Manage = () => {
  const form = useForm({
    initialValues: {
      name: `Protokół wizyty interwencyjnej ${readableDate(new Date())}`,
      purpose: '',
      pestsRemoved: '',
      actionTaken: '',
      usedProducts: [{ name: '', amount: '', unit: '' }],
      area: '',
      recommendations: '',
      signedByClient: null,
      cause: '',
      description: '',
    },
  });

  const { placeId, placeUuid, interventionUuid } = useParams<{
    placeId: string;
    placeUuid: string;
    interventionUuid?: string;
  }>();

  const translations = useTranslations();
  const createInterventionForm = translations.intervention;

  const onClickAddUsedProduct = () => {
    form.insertListItem('usedProducts', { name: '', amount: '', unit: '' });
  };

  const onClickRemoveUsedProduct = (index: number) => () => {
    form.removeListItem('usedProducts', index);
  };

  const [pestsRemovedImages, setPestsRemovedImages] = useState<ImageFile[]>([]);
  const [causeImages, setCauseImages] = useState<ImageFile[]>([]);
  const [descriptionImages, setDescriptionImages] = useState<ImageFile[]>([]);
  const [pestsRemovedSavedImages, setPestsRemovedSavedImages] = useState<InterventionImage[]>([]);
  const [causeSavedImages, setCauseSavedImages] = useState<InterventionImage[]>([]);
  const [descriptionSavedImages, setDescriptionSavedImages] = useState<InterventionImage[]>([]);

  const { submitValues, isSubmittingForm } = useOnSubmit(
    placeId,
    placeUuid,
    pestsRemovedImages,
    causeImages,
    descriptionImages,
    interventionUuid,
  );

  const onSubmit = async values => {
    submitValues(values);
  };

  const title = interventionUuid
    ? translations.interventions.editIntervention
    : translations.interventions.createIntervention;

  const {
    isLoading,
    isLoaded,
    data: intervention,
    error,
    refresh: fetchIntervention,
  } = useFetch<{ intervention: Intervention }>({
    fetchAction: () =>
      apiService({}).get(`/api/places/${placeUuid}/interventions/${interventionUuid}`),
    fetchActionDeps: [placeUuid, interventionUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });

  const {
    isLoading: isLoadingImages,
    data: images,
    refresh: fetchImages,
  } = useFetch<{ images: InterventionImage[] }>({
    fetchAction: () =>
      apiService({}).get(`/api/places/${placeUuid}/interventions/${interventionUuid}/images`),
    fetchActionDeps: [placeUuid, interventionUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });

  useEffect(() => {
    if (interventionUuid) {
      fetchIntervention();
      fetchImages();
    }
  }, [interventionUuid]);

  useEffect(() => {
    if (intervention) {
      //@ts-ignore
      form.setValues(intervention?.intervention);
    }
  }, [intervention]);

  useEffect(() => {
    if (images?.images.length > 0) {
      const groupedImages = groupBy(images.images, 'type');
      setCauseSavedImages(groupedImages.CAUSE);
      setDescriptionSavedImages(groupedImages.DESCRIPTION);
      setPestsRemovedSavedImages(groupedImages.PESTS_REMOVED);
    }
  }, [images, setCauseSavedImages, setDescriptionSavedImages, setPestsRemovedImages]);

  const onDeleteCauseSavedImages = useDeleteSavedImage(
    placeUuid,
    interventionUuid,
    setCauseSavedImages,
    causeSavedImages,
  );

  const onDeletePestsRemovedSavedImages = useDeleteSavedImage(
    placeUuid,
    interventionUuid,
    setPestsRemovedSavedImages,
    pestsRemovedSavedImages,
  );

  const onDeleteDescriptionSavedImages = useDeleteSavedImage(
    placeUuid,
    interventionUuid,
    setDescriptionSavedImages,
    descriptionSavedImages,
  );

  return (
    <AppContent>
      <Title mb={20}>{title}</Title>
      <LoadingOverlay visible={isLoading || isLoadingImages} overlayBlur={1} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          label={createInterventionForm.title}
          name="name"
          required
          mb={20}
          {...form.getInputProps('name')}
        />
        <Flex direction="column" mb={20}>
          <Textarea
            autosize
            label={createInterventionForm.cause}
            name="cause"
            mb={20}
            {...form.getInputProps('cause')}
          />
          <ImagesUpload
            files={causeImages}
            setFiles={setCauseImages}
            savedImages={causeSavedImages}
            onDeleteSavedImage={onDeleteCauseSavedImages}
          />
        </Flex>
        <Textarea
          autosize
          label={createInterventionForm.purpose}
          name="purpose"
          required
          mb={20}
          {...form.getInputProps('purpose')}
        />
        <Flex direction="column" mb={20}>
          <Textarea
            autosize
            label={createInterventionForm.pestsRemoved}
            name="pestsRemoved"
            mb={20}
            {...form.getInputProps('pestsRemoved')}
          />
          <ImagesUpload
            files={pestsRemovedImages}
            setFiles={setPestsRemovedImages}
            savedImages={pestsRemovedSavedImages}
            onDeleteSavedImage={onDeletePestsRemovedSavedImages}
          />
        </Flex>
        <Flex direction="column" mb={20}>
          <Textarea
            autosize
            label={createInterventionForm.description}
            name="description"
            mb={20}
            {...form.getInputProps('description')}
          />
          <ImagesUpload
            files={descriptionImages}
            setFiles={setDescriptionImages}
            savedImages={descriptionSavedImages}
            onDeleteSavedImage={onDeleteDescriptionSavedImages}
          />
        </Flex>
        <Textarea
          autosize
          label={createInterventionForm.actionTaken}
          name="actionTaken"
          mb={20}
          {...form.getInputProps('actionTaken')}
        />
        <Flex direction="column" mb={20}>
          <Text fz="sm" fw={500} mb={12}>
            {createInterventionForm.usedProducts}
          </Text>
          {form.values.usedProducts.map((item, index) => (
            <Flex key={`usedProducts-${index}`} gap={10} align="center">
              <TextInput
                style={{ flexGrow: 2 }}
                label={createInterventionForm.usedProductsName}
                name={`usedProducts.${index}.name`}
                required
                mb={20}
                {...form.getInputProps(`usedProducts.${index}.name`)}
              />
              <TextInput
                style={{ flexGrow: 2 }}
                label={createInterventionForm.usedProductsActiveSubstanceName}
                name={`usedProducts.${index}.activeSubstance`}
                required
                mb={20}
                {...form.getInputProps(`usedProducts.${index}.activeSubstance`)}
              />
              <NumberInput
                style={{ flexGrow: 1 }}
                label={createInterventionForm.usedProductsAmount}
                name={`usedProducts.${index}.amount`}
                required
                mb={20}
                min={0}
                precision={2}
                {...form.getInputProps(`usedProducts.${index}.amount`)}
              />
              <Select
                style={{ flexGrow: 1 }}
                data={unitValues(translations.units)}
                label={createInterventionForm.usedProductsUnit}
                name={`usedProducts.${index}.unit`}
                required
                mb={20}
                {...form.getInputProps(`usedProducts.${index}.unit`)}
              />
              <ActionIcon
                variant="light"
                color="gray"
                size="lg"
                onClick={onClickRemoveUsedProduct(index)}>
                <Trash />
              </ActionIcon>
            </Flex>
          ))}
          <ActionIcon
            variant="light"
            color="gray"
            size="lg"
            mt={10}
            onClick={onClickAddUsedProduct}>
            <Plus />
          </ActionIcon>
        </Flex>
        <TextInput
          label={createInterventionForm.area}
          name="area"
          mb={20}
          {...form.getInputProps('area')}
        />
        <Textarea
          autosize
          label={createInterventionForm.recommendations}
          name="recommendations"
          mb={20}
          {...form.getInputProps('recommendations')}
        />

        <Button type="submit" loading={isSubmittingForm}>
          {translations.global.save}
        </Button>
      </form>
    </AppContent>
  );
};

export default Manage;
