import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';

import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Group, Modal, Notification } from '@mantine/core';

interface Props {
  zoneId?: string;
  refreshParent: () => any;
  onClose: () => any;
  placeId: string | number;
}

const DeleteZoneModal = (props: Props) => {
  const { zoneId, refreshParent, onClose, placeId } = props;
  const translations = useTranslations();

  const [isSaving, setSaving] = useState(false);
  const handleClick = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(`/api/places/${placeId}/zones/${zoneId}`);
      toast.success(translations.zones.zoneDeletedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!zoneId);
  }, [zoneId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal opened={opened} onClose={handleClose} title={translations.mixtures.deleteMixture}>
      {translations.zones.zoneDeleteDescription}
      <Group position="right" mt={20}>
        <Button color="primary" loading={isSaving} type="submit" onClick={handleClick}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteZoneModal;
