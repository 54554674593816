import { Button } from '@mantine/core';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslations } from 'shared/translations/useTranslations';
import { ArrowRight } from 'tabler-icons-react';

interface Props {
  to: string;
}
const SeeMoreButton = (props: Props) => {
  const history = useHistory();
  const translations = useTranslations();
  return (
    <Button
      rightIcon={<ArrowRight size={14} />}
      radius="xl"
      size="xs"
      onClick={() => history.push(props.to)}>
      {translations.global.seeMore}
    </Button>
  );
};

export default SeeMoreButton;
