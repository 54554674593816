import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Alert, Button, PasswordInput } from '@mantine/core';
import { Check } from 'tabler-icons-react';

import useSave from 'shared/hooks/useSave';
import { useTranslations } from 'shared/translations/useTranslations';
import { LoginPassword } from 'shared/components/Icons';
import AppContent from 'shared/components/AppContent';
import ListHeading from 'shared/components/ListHeading';

import validate from './validate';

import './styles.scss';

const ChangePassword = () => {
  const [showSuccessNotification, setShowSuccesNotification] = useState(false);

  const form = useForm({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
      currentPassword: '',
    },
    validate,
  });

  const { save, isLoading, error } = useSave({
    patchEndpoint: `/account/change-password`,
    isPatch: true,
    onSuccessCallback: data => {
      setShowSuccesNotification(true);
      form.reset();
    },
  });

  const submit = formValues => {
    setShowSuccesNotification(false);
    save(formValues);
  };

  const translations = useTranslations();
  return (
    <>
      <ListHeading title={translations.account.notifications} />
      <AppContent>
        <div className="ChangePassword">
          <div className="ChangePassword__wrapper">
            <form onSubmit={form.onSubmit(submit)}>
              <PasswordInput
                className="ChangePassword__input"
                name="currentPassword"
                label={translations.auth.currentPassword}
                placeholder={translations.auth.currentPassword}
                icon={<LoginPassword className="ChangePassword__input__icon" />}
                required
                {...form.getInputProps('currentPassword')}
              />
              <PasswordInput
                className="ChangePassword__input"
                name="newPassword"
                label={translations.auth.newPassword}
                placeholder={translations.auth.newPassword}
                icon={<LoginPassword className="ChangePassword__input__icon" />}
                required
                {...form.getInputProps('newPassword')}
              />
              <PasswordInput
                className="ChangePassword__input"
                name="confirmNewPassword"
                label={translations.auth.confirmNewPassword}
                placeholder={translations.auth.confirmNewPassword}
                icon={<LoginPassword className="ChangePassword__input__icon" />}
                required
                {...form.getInputProps('confirmNewPassword')}
              />

              {error && !isLoading && (
                <span className="ChangePassword__error">{translations.auth.wrongCredentials}</span>
              )}
              <Button type="submit" loading={isLoading} disabled={isLoading} fullWidth>
                {translations.global.save}
              </Button>

              {showSuccessNotification && (
                <Alert
                  className="ChangePassword__notification"
                  variant="filled"
                  icon={<Check size={18} />}
                  color="teal">
                  {translations.auth.passwordChanged}
                </Alert>
              )}
            </form>
          </div>
        </div>
      </AppContent>
    </>
  );
};

export default ChangePassword;
