import React from 'react';
import { Route, useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import DeviceTypeActions from './Actions';
import List from './containers/List';
import Manage from './containers/Manage';
import ManageV2 from './containers/ManageV2';
import DeviceTypeObjects from './Objects';
import DeviceTypeObservations from './Observations';
import './styles.scss';

export default () => {
  const history = useHistory();
  return (
    <>
      <Route
        path={routes.deviceTypes.default.path}
        key="device-types-default"
        component={List}
        exact
      />

      <Route
        path={routes.deviceTypes.manage(':id').path}
        key="device-types-single"
        component={ManageV2}
        exact
      />

      <Route
        path={routes.deviceTypes.create.path}
        key="device-types-create"
        component={props => (
          <ManageV2
            {...props}
            onCreate={id => {
              history.push(routes.deviceTypes.manage(id).path);
            }}
          />
        )}
        exact
      />

      <Route path={routes.deviceTypes.actions.path} component={DeviceTypeActions} exact />
      <Route path={routes.deviceTypes.objects.path} component={DeviceTypeObjects} exact />
      <Route path={routes.deviceTypes.observations.path} component={DeviceTypeObservations} exact />
    </>
  );
};
