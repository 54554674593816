import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import api, { apiService } from 'shared/services/api';
import { reduxForm, Field, getFormValues } from 'redux-form';
import DeviceBox from 'shared/components/DeviceBox';
import { isAnyAdminByAuthState, isSuperAdminByAuthState } from 'shared/utils/user';
import RotateLoader from 'shared/components/RotateLoader';
import { useHistory, useParams } from 'react-router';
import { compose } from 'redux';
import orderBy from 'lodash/orderBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import SelectField from 'shared/components/SelectField';
import { batteryLevelPercentage, dateLongerThanMinutes, rssiToText } from 'shared/utils/device';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Select, TextInput, Tooltip } from '@mantine/core';
import { routes } from '../../../../../shared/constants/routes';
import TextField from '../../../../../shared/components/TextField';
import { USER_CONTROLLER } from '../../../../../shared/constants/user';
import ListHeading from '../../../../../shared/components/ListHeading';
import AppContent from '../../../../../shared/components/AppContent';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../../shared/components/Table';
import styles from './styles.module.scss';
import { useForm } from '@mantine/form';
import DeleteDevicesModal from '../DeleteDevicesModal';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AssignDevicesToZoneModal from '../AssignDevicesToZoneModal';

interface Props {
  placeId: string | number;
}
interface State {
  devices: [];
}

async function fetchData(setData, placeId, setLoading) {
  try {
    setLoading(true);
    const { data } = await api({}).get(`places/${placeId}/devices/all-data`);
    setData(data);
  } catch (e) {
  } finally {
    setLoading(false);
  }
}
const LIST_FORM_NAME = 'place/devices';
const PlaceDevices = (props: Props) => {
  const params = useParams();
  const placeId = props.placeId || params.placeId;
  const translations = useTranslations();
  const [data, setData] = useState<State>({
    devices: [],
  });
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const { isSuperAdmin, isAdmin } = useSelector(state => ({
    isSuperAdmin: isSuperAdminByAuthState(state.auth),
    isAdmin: isAnyAdminByAuthState(state.auth),
    // isCompanyAdmin: isCompanyAdminByAuthState(state.auth),
  }));

  useEffect(() => {
    fetchData(setData, placeId, setLoading);
  }, []);
  const form = useForm({});
  const formValues = form.values;
  const currentStatus = formValues.status;
  const currentZone = formValues.zone;

  const getDeviceTypes = () => {
    const deviceTypes =
      uniqBy(
        flatMap(data.devices, device => device.deviceType).filter(deviceType => !!deviceType),
        'id',
      ) || [];
    return [
      { label: translations.global.all, value: '' },
      ...deviceTypes.map(({ id, name }) => ({ label: name, value: id })),
    ];
  };
  const getZones = () => {
    const zones =
      uniqBy(
        flatMap(data.devices, device => device.zone).filter(zone => !!zone),
        'id',
      ) || [];
    return [
      { label: translations.global.all, value: '' },
      ...zones.map(({ id, name }) => ({ label: name, value: id })),
    ];
  };

  const filteredDevices = useMemo(() => {
    const splitId = (uniqueId: string) => {
      const match = uniqueId.match(/^([a-zA-Z]+)(\d+)$/);
      if (match) {
        const [, prefix, num] = match;
        return [prefix.toLowerCase(), parseInt(num, 10)];
      }
      return [uniqueId.toLowerCase(), 0];
    };

    const ordered1 =
      orderBy(
        data.devices,
        [
          ({ detect }) => !!detect,
          ({ uniqueId }) => splitId(uniqueId)[0],
          ({ uniqueId }) => splitId(uniqueId)[1],
        ],
        ['desc', 'asc', 'asc'],
      ) || [];

    let result = ordered1;
    if (formValues && formValues.deviceType) {
      result = result.filter(device => device.deviceTypeId === formValues.deviceType);
    }
    if (currentZone) {
      result = result.filter(device => {
        return device.zone?.id === currentZone;
      });
    }
    if (formValues.withoutQr) {
      result = result.filter(device => {
        return !device.qrcodeId;
      });
    }
    if (formValues.withoutNfc) {
      result = result.filter(device => {
        return !device.nfcId;
      });
    }
    if (formValues && formValues.uniqueId) {
      result = result.filter(device => {
        return (
          String(device.uniqueId)
            .toLowerCase()
            .includes(String(formValues.uniqueId).toLowerCase()) ||
          String(device.loraDevEui)
            .toLowerCase()
            .includes(String(formValues.uniqueId).toLowerCase())
        );
      });
    }
    if (currentStatus) {
      if (currentStatus === 'last-signal')
        result = result.filter(device => {
          const lastSignal = get(device, 'deviceHistory[0].createdAt');
          const lastSignalInterval = get(device, 'place.radioErrorInterval');
          return dateLongerThanMinutes(lastSignal, lastSignalInterval);
        });

      if (currentStatus === 'monitoring') {
        result = result.filter(device => {
          const lastSignal = get(device, 'deviceHistory[0].createdAt');
          const lastSignalInterval = get(device, 'place.radioErrorInterval');
          return !dateLongerThanMinutes(lastSignal, lastSignalInterval) && lastSignal;
        });
        // result = result.filter((device) => !Number(device.detect));
      }

      if (currentStatus === 'alert') result = result.filter(device => !!Number(device.detect));
    }
    return result;
  }, [data, formValues]);

  const cleanDetection = async (deviceId: string | number) => {
    try {
      const { data } = await apiService({}).post(`/devices/${deviceId}/clean-detection`);
      fetchData(setData, placeId, setLoading);
    } catch (e) {}
  };

  const userType = useSelector(state => state.auth.data.userType);
  const canAddDevice = userType !== USER_CONTROLLER;

  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const handleAllSelect = e => {
    const { checked } = e.target;
    if (checked) {
      setSelectedDevices(filteredDevices.map(device => device.uuid));
    } else {
      setSelectedDevices([]);
    }
  };

  const handleSelectDevice = (deviceUuid: string) => e => {
    const { checked } = e.target;
    if (checked) {
      setSelectedDevices([...selectedDevices, deviceUuid]);
    } else {
      setSelectedDevices(selectedDevices.filter(uuid => uuid !== deviceUuid));
    }
  };
  const [isDevicesModalVisible, setDevicesModalVisible] = useState(false);
  const [isAssignToZoneVisible, setIsAssignToZoneVisible] = useState(false);
  const onSuccess = () => {
    fetchData(setData, placeId, setLoading);
    setSelectedDevices([]);
  };

  return (
    <div>
      <DeleteDevicesModal
        opened={isDevicesModalVisible}
        refreshParent={() => onSuccess()}
        placeId={placeId}
        onClose={() => {
          setDevicesModalVisible(false);
        }}
        selectedDevices={data.devices.filter(device => selectedDevices.includes(device.uuid)) || []}
      />
      <AssignDevicesToZoneModal
        opened={isAssignToZoneVisible}
        refreshParent={() => onSuccess()}
        placeId={placeId}
        onClose={() => {
          setIsAssignToZoneVisible(false);
        }}
        selectedDevices={data.devices.filter(device => selectedDevices.includes(device.uuid)) || []}
      />
      <ListHeading title={translations.global.devices}>
        <Group>
          {canAddDevice && (
            <Button onClick={() => history.push(routes.places.devices.create.to(placeId))}>
              {translations.devices.addDevice}
            </Button>
          )}
          {canAddDevice && (
            <Button
              disabled={selectedDevices.length === 0}
              onClick={() => {
                setDevicesModalVisible(true);
              }}
              color="red">
              {translations.devices.deleteDevice}
            </Button>
          )}
          {canAddDevice && (
            <Button
              disabled={selectedDevices.length === 0}
              onClick={() => {
                setIsAssignToZoneVisible(true);
              }}
              color="blue">
              Przypisz urządzenia do strefy
            </Button>
          )}
        </Group>
      </ListHeading>
      <AppContent>
        {isLoading && (
          <div className="ContentLoader__loader">
            <RotateLoader relative />
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <Group align="end" mb={20}>
              <Select
                searchable
                clearable
                data={[
                  { label: translations.global.all, value: '' },
                  { label: translations.devices.alert, value: 'alert' },
                  { label: translations.devices.online, value: 'monitoring' },
                  { label: translations.devices.noSignal, value: 'last-signal' },
                ]}
                label={translations.global.status}
                {...form.getInputProps('status')}
              />
              <Select
                searchable
                clearable
                data={getZones()}
                label={translations.zones.zone}
                {...form.getInputProps('zone')}
              />
              <TextInput
                label={translations.devices.uniqueId}
                {...form.getInputProps('uniqueId')}
              />
              <Select
                clearable
                searchable
                data={getDeviceTypes()}
                label={translations.global.deviceType}
                {...form.getInputProps('deviceType')}
              />
              <Checkbox
                mb={10}
                label={`${translations.global.without} QRCode`}
                {...form.getInputProps('withoutQr', { type: 'checkbox' })}
              />
              <Checkbox
                mb={10}
                label={`${translations.global.without} NFC`}
                {...form.getInputProps('withoutNfc', { type: 'checkbox' })}
              />
            </Group>
          </div>
        </div>
        {selectedDevices.length > 0 && (
          <div>
            {translations.global.selected}: {selectedDevices.length}
          </div>
        )}
        <div className="">
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox onChange={handleAllSelect} value={'true'} />
                </Th>
                <Th>{translations.global.uniqIdentifier}</Th>
                <Th>{translations.global.deviceType}</Th>
                <Th>{translations.zones.zone}</Th>
                <Th>{translations.devices.location}</Th>
                <Th>Mousee</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredDevices.map(device => {
                const date = get(device, 'deviceHistory[0].createdAt');
                const radioErrorInterval = get(device, 'place.radioErrorInterval');
                const battery = get(device, 'deviceHistory[0].battery');
                const rssi = get(device, 'deviceHistory[0].rssi');
                const detectionCounter = get(device, 'lastHistoryDetectionCounter');
                // TODO: Nasty solution, change when new lora implemented
                const isElectronicDevice = get(device, 'deviceTypeId') === 1;
                const noInfo = date && dateLongerThanMinutes(date, radioErrorInterval);
                return (
                  <Tr key={device.uuid}>
                    <Td>
                      <Group>
                        <Checkbox
                          checked={selectedDevices.includes(device.uuid)}
                          onChange={handleSelectDevice(device.uuid)}
                        />
                        {get(device, 'qrcodeId') ? (
                          <i className={`fa fa-qrcode ${styles.iconQrCode}`}></i>
                        ) : (
                          ''
                        )}
                        {get(device, 'nfcId') ? 'NFC' : ''}
                      </Group>
                    </Td>
                    <Td>
                      <Link to={routes.places.devices.details.to(placeId, device.uuid)}>
                        {get(device, 'uniqueId') || '-'}
                      </Link>
                    </Td>
                    <Td>{get(device, 'deviceType.name') || '-'}</Td>
                    <Td>{get(device, 'zone.name') || '-'}</Td>
                    <Td>{get(device, 'location') || '-'}</Td>
                    <Td>
                      {!isElectronicDevice && '-'}
                      {isElectronicDevice && (
                        <Group>
                          <div>
                            {get(device, 'detect') ? (
                              <Tooltip label="Wykryta detekcja">
                                <i className="fa fa-exclamation-triangle device-box__id__icon--alert"></i>
                              </Tooltip>
                            ) : (
                              <i className="fa fa-check-circle device-box__id__icon--good"></i>
                            )}
                            {noInfo && (
                              <Tooltip label="Brak informacji w długim przedziale czasowym">
                                <i className="fa fa-exclamation-triangle icon-alert-date"></i>
                              </Tooltip>
                            )}
                          </div>
                          <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
                            <div>
                              <Tooltip
                                label={date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-'}>
                                <span>
                                  {date ? moment(date).fromNow() : '-'}{' '}
                                  <i
                                    className={`fas fa-clock device-box__icon ${
                                      noInfo ? 'icon-alert-date' : ''
                                    }`}></i>
                                </span>
                              </Tooltip>
                            </div>
                            <div>
                              {battery ? `${batteryLevelPercentage(battery)}%` : '-'}{' '}
                              <i className="fas fa-battery-full  device-box__icon"></i>
                            </div>
                            <div>
                              {rssi ? `${rssiToText(rssi)}` : '-'}{' '}
                              <i className="fas fa-signal  device-box__icon"></i>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              {detectionCounter}{' '}
                              <i className="fas fa-stopwatch-20 device-box__icon"></i>
                            </div>
                          </div>
                        </Group>
                      )}
                    </Td>
                  </Tr>
                  // <DeviceBox
                  //   device={device}
                  //   key={device.id}
                  //   onClick={() => {
                  //     // history.push(`/app/devices/edit/${device.id}`);
                  //     history.push(routes.places.devices.details.to(placeId, device.uuid));
                  //   }}
                  //   isAdmin={isAdmin}
                  //   isSuperAdmin={isSuperAdmin}
                  //   // toggleDeleteModal={() => {
                  //   //   // this.toggleDeleteModal(device.id)}
                  //   // }}
                  //   deleteAlert={e => {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     cleanDetection(device.id);
                  //   }}
                  // />
                );
              })}
            </Tbody>
          </Table>
        </div>
      </AppContent>
    </div>
  );
};
export default reduxForm({
  form: LIST_FORM_NAME,
  enableReinitialize: true,
  initialValues: {
    place: '',
    status: '',
  },
})(PlaceDevices);
