import shared from 'shared/sagas';
import companies from 'modules/Company/sagas';
import auth from 'modules/Auth/sagas';
import places from 'modules/Place/sagas';
import devices from 'modules/Device/sagas';
import deviceTypes from 'modules/DeviceType/sagas';
import reports from 'modules/Reports/sagas';
import user from 'modules/User/sagas';

export default [
  ...shared,
  ...auth,
  ...companies,
  ...places,
  ...devices,
  ...user,
  ...reports,
  ...deviceTypes,
];
