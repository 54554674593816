import React, { useCallback, useEffect, useState } from 'react';
import Button from 'shared/components/Button';
import {
  Badge,
  Skeleton,
  Grid,
  Breadcrumbs,
  Timeline,
  Text,
  Drawer,
  Modal,
  ScrollArea,
  Anchor,
} from '@mantine/core';
import api, { API_URL } from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useHistory, useParams } from 'react-router';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import DeleteRecommendationModal from '../DeleteRecommendationModal';
import {
  getColorByRecommendationPriority,
  getColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import { get, map, reverse, sortBy } from 'lodash';
import DetailsRow from './DetailsRow';
import { dateFromNow, readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { Link } from 'react-router-dom';
import AddCommentDrawer from '../AddCommentDrawer';
import CompareHistoryModal from './CompareHistoryModal';
import AddFileDrawer from '../AddFileDrawer';
import DeleteFileModal from '../DeleteFileModal';
import DetailsMap from './DetailsMap';
import styles from './styles.module.scss';
import EditCommentDrawer from '../EditCommentDrawer';
import { useIsSuperAdmin } from 'shared/hooks/useIsSuperAdmin';
import { useTranslations } from 'shared/translations/useTranslations';
import DeleteCommentModal from '../DeleteCommentModal';

interface Props {}

const Details = (props: Props) => {
  const translations = useTranslations();
  const { placeId } = useParams<{ placeId: string }>();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { recommendation: {} },
    fetchAction: () => api({}).get(`/api/recommendations/${id}`),
  });

  const history = useHistory();

  const goToEdit = useCallback(() => {
    history.push(routes.recommendation.edit.to(placeId, id));
  }, [history]);
  const recommendation = data.recommendation || {};
  const items = [
    { title: translations.global.recommendations, href: routes.recommendation.default.path },
    { title: data?.recommendation?.place?.name, href: routes.recommendation.place.to(placeId) },
    { title: data?.recommendation?.uniqueId },
  ].map((item, index) =>
    item.href ? (
      <Link to={item.href} key={index}>
        <span className="g-breadcrumb"> {item.title}</span>
      </Link>
    ) : (
      <span key={index}>{item.title}</span>
    ),
  );
  const [addCommentModal, setAddCommentModal] = useState(false);
  const [editCommentModal, setEditCommentModal] = useState<any | boolean>(false);
  const [deleteCommentModal, setDeleteCommentModal] = useState<any | boolean>(false);
  const [addFileModal, setAddFileModal] = useState(false);

  const getTimelineData = useCallback(() => {
    const files = map(sortBy(recommendation.recommendationFiles, 'createdAt'), (file, index) => ({
      createdAt: file.createdAt,
      type: 'FILE',
      data: file,
      body: file.url,
      realIndex: index,
      author: {
        name: file.user ? `${file.user.name} ${file.user.surname}` : '-',
        id: file.userId,
      },
    }));
    const comments = map(
      sortBy(recommendation.recommendationComments, 'createdAt'),
      (comment, index) => ({
        createdAt: comment.createdAt,
        type: 'COMMENT',
        data: comment,
        body: comment.comment,
        realIndex: index,
        author: {
          name: comment.author ? `${comment.author.name} ${comment.author.surname}` : '-',
          id: comment.authorId,
        },
      }),
    );
    const histories = map(
      sortBy(recommendation.recommendationHistories, 'createdAt'),
      (history, index) => ({
        createdAt: history.createdAt,
        type: 'EDIT',
        data: history,
        body: undefined,
        realIndex: index,
        author: {
          name: `${history.author.name} ${history.author.surname}`,
          id: history.authorId,
        },
      }),
    );
    const result = reverse(sortBy([...files, ...comments, ...histories], 'createdAt'));
    return result;
  }, [recommendation]);

  const [showChangesModal, setChangesModal] = useState(false);
  const [changesModalData, setChangesModalData] = useState({ old: undefined, new: undefined });
  const handleShowChanges = useCallback(
    (data, index) => () => {
      let oldData;
      let newData;

      if (index >= 0 && index < recommendation.recommendationHistories.length - 1) {
        oldData = recommendation.recommendationHistories[index - 1];
        newData = data;
      }

      if (index > 0 && index === recommendation.recommendationHistories.length - 1) {
        oldData = data;
        newData = recommendation;
      }
      setChangesModalData({
        new: newData,
        old: oldData,
      });
      setChangesModal(true);
    },
    [recommendation],
  );
  const [showRemoveFileModal, setRemoveFileModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState<string>();
  const handleShowRemoveFileModal = (fileId: string) => () => {
    setRemoveFileModal(true);
    setDeleteFileId(fileId);
  };
  const handleHideRemoveFileModal = () => {
    setRemoveFileModal(false);
    setDeleteFileId(undefined);
  };
  const handleHideRemoveCommentModal = () => {
    setDeleteCommentModal(undefined);
  };
  const isSuperAdmin = useIsSuperAdmin();
  return (
    <>
      <Drawer
        opened={addCommentModal}
        onClose={() => setAddCommentModal(false)}
        title={translations.global.addComment}
        position="right"
        padding="xl"
        size="xl">
        <AddCommentDrawer
          onClose={() => setAddCommentModal(false)}
          id={recommendation.id}
          onAdd={refresh}
        />
      </Drawer>
      <Drawer
        opened={editCommentModal}
        onClose={() => setEditCommentModal(false)}
        title="Edytuj komentarz"
        position="right"
        padding="xl"
        size="xl">
        <EditCommentDrawer
          onClose={() => setEditCommentModal(false)}
          id={recommendation.id}
          comment={editCommentModal}
          onAdd={refresh}
        />
      </Drawer>
      <Drawer
        opened={addFileModal}
        onClose={() => setAddFileModal(false)}
        title={translations.global.addAttachement}
        position="right"
        padding="xl"
        size="xl">
        <AddFileDrawer
          onClose={() => setAddFileModal(false)}
          id={recommendation.id}
          onAdd={refresh}
        />
      </Drawer>
      <DeleteFileModal
        fileId={deleteFileId}
        recommendationId={id}
        isOpen={showRemoveFileModal}
        onClose={handleHideRemoveFileModal}
        onSuccess={() => {
          refresh();
        }}
      />
      <DeleteCommentModal
        commentId={deleteCommentModal?.id}
        recommendationId={id}
        isOpen={deleteCommentModal}
        onClose={handleHideRemoveCommentModal}
        onSuccess={() => {
          refresh();
        }}
      />
      <ListHeading
        title={
          <>
            {translations.recommendations.recommendation}: {recommendation.uniqueId}
            <Badge color={getColorByRecommendationStatus(recommendation.status)}>
              {recommendation.status}
            </Badge>
          </>
        }
        footer={<Breadcrumbs className="g-breadcrumbs">{items}</Breadcrumbs>}>
        <div className="d-flex">
          <Button color="primary" className="ml-5" onClick={goToEdit}>
            {translations.global.edit}
          </Button>
          <DeleteRecommendationModal id={id} placeId={placeId} />
          <Button color="primary" className="ml-5" onClick={() => setAddCommentModal(true)}>
            {translations.global.addComment}
          </Button>
          <Button color="primary" className="ml-5" onClick={() => setAddFileModal(true)}>
            {translations.global.addAttachement}
          </Button>
        </div>
      </ListHeading>
      <AppContent>
        {isLoading && (
          <>
            <Skeleton height={14} mt={14} radius="xl" />
            <Skeleton height={14} mt={14} radius="xl" />
            <Skeleton height={14} mt={14} radius="xl" />
            <Skeleton height={14} mt={14} radius="xl" />
          </>
        )}
        <CompareHistoryModal
          show={showChangesModal}
          handleClose={() => setChangesModal(false)}
          recommendation={recommendation}
          oldData={changesModalData.old}
          newData={changesModalData.new}
        />
        {isLoaded && (
          <>
            <Grid>
              <Grid.Col span={4}>
                <DetailsRow label="#">{recommendation.uniqueId}</DetailsRow>
                <DetailsRow label={translations.recommendations.priority}>
                  <Badge color={getColorByRecommendationPriority(recommendation.priority)}>
                    {recommendation.priority}
                  </Badge>
                </DetailsRow>
                <DetailsRow label={translations.recommendations.category}>
                  {recommendation.recommendationCategoryId
                    ? get(recommendation, 'recommendationCategory.name')
                    : '-'}
                </DetailsRow>
                <DetailsRow label={translations.global.status}>
                  <Badge color={getColorByRecommendationStatus(recommendation.status)}>
                    {recommendation.status}
                  </Badge>
                </DetailsRow>
                <DetailsRow label={translations.recommendations.author}>
                  {recommendation.author
                    ? `${recommendation.author.name} ${recommendation.author.surname}`
                    : '-'}
                </DetailsRow>
                <DetailsRow label={translations.recommendations.owner}>
                  {recommendation.owner
                    ? `${recommendation.owner.name} ${recommendation.owner.surname}`
                    : recommendation.ownerText}
                </DetailsRow>
                <DetailsRow label={translations.recommendations.dueDate}>
                  {readableDateWithoutTime(recommendation.dueDate)}
                </DetailsRow>
                <DetailsRow label={translations.recommendations.comment}>
                  {recommendation.comment ? get(recommendation, 'comment') : '-'}
                </DetailsRow>
                <DetailsMap recommendation={recommendation} />
              </Grid.Col>
              <Grid.Col span={4}>
                <DetailsRow label={translations.global.device}>
                  {recommendation.deviceId ? get(recommendation, 'device.uniqueId') : '-'}
                </DetailsRow>
                <DetailsRow label={translations.global.deviceType}>
                  {recommendation.deviceTypeId ? get(recommendation, 'deviceType.name') : '-'}
                </DetailsRow>

                <DetailsRow label={translations.recommendations.map}>
                  {recommendation.mapId ? get(recommendation, 'map.name') : '-'}
                </DetailsRow>
                <DetailsRow label={translations.zones.zone}>
                  {recommendation.zoneId ? get(recommendation, 'zone.name') : '-'}
                </DetailsRow>
                <DetailsRow label={translations.recommendations.location}>
                  {recommendation.area || '-'}
                </DetailsRow>
                <DetailsRow label={translations.global.createdAt}>
                  {readableDate(recommendation.createdAt)}
                </DetailsRow>
                <DetailsRow label={`${translations.global.attachements}:`}>
                  {(recommendation?.recommendationFiles || []).map(item => (
                    <div key={item.url} className={styles.removeFileRow}>
                      <Anchor href={`${API_URL}/files/${item.url}`} target="_blank">
                        <i className="fa fa-photo"></i> {item.name}
                      </Anchor>
                      <i
                        className={`${styles.removeFileIcon} fa fa-trash`}
                        onClick={handleShowRemoveFileModal(item.id)}></i>
                    </div>
                  ))}
                </DetailsRow>
              </Grid.Col>
              <Grid.Col span={4}>
                <Timeline active={1} bulletSize={24} lineWidth={2}>
                  {map(getTimelineData(), (item, index) => {
                    if (item.realIndex === 0 && item.type === 'EDIT') {
                      // Don't show first item - it's copy of the recommendation that was created
                      return null;
                    }
                    const title = {
                      EDIT: translations.recommendations.change,
                      COMMENT: (
                        <div>
                          {translations.recommendations.comment}{' '}
                          {isSuperAdmin && (
                            <>
                              <span
                                className={styles.commentEdit}
                                onClick={() => {
                                  setEditCommentModal({
                                    id: item.data.id,
                                    comment: item.data.comment,
                                  });
                                }}>
                                <i className="fa fa-edit"></i>
                              </span>
                              <span
                                className={styles.commentEdit}
                                onClick={() => {
                                  setDeleteCommentModal({
                                    id: item.data.id,
                                    comment: item.data.comment,
                                  });
                                }}>
                                <i className="fa fa-trash"></i>
                              </span>
                            </>
                          )}
                        </div>
                      ),
                      FILE: (
                        <div>
                          {translations.global.file}
                          <span
                            className={styles.commentEdit}
                            onClick={() => {
                              handleShowRemoveFileModal(item.data.id)();
                            }}>
                            <i className="fa fa-trash"></i>
                          </span>
                        </div>
                      ),
                    };
                    const bullet = {
                      EDIT: 'fa fa-pencil',
                      COMMENT: 'fa fa-message',
                      FILE: 'fa fa-file',
                    };
                    return (
                      <Timeline.Item
                        key={index}
                        title={title[item.type]}
                        bullet={<i className={bullet[item.type]}></i>}
                        lineVariant="dashed">
                        {item.type === 'EDIT' && (
                          <>
                            <Text variant="link" component="span" inherit>
                              {item.author.name}
                            </Text>{' '}
                            <Text color="dimmed" size="sm">
                              {' '}
                              {
                                translations.recommendations.historyRecommendationHasBeenModified
                              }{' '}
                            </Text>
                            <Text
                              variant="link"
                              component="span"
                              inherit
                              onClick={handleShowChanges(item.data, item.realIndex)}>
                              {translations.recommendations.showChanges}
                            </Text>
                          </>
                        )}
                        {item.type === 'COMMENT' && (
                          <>
                            <Text color="dimmed" size="sm">
                              <Text variant="link" component="span" inherit>
                                {item.author.name}
                              </Text>{' '}
                              {translations.recommendations.wrote}:
                              <br />
                            </Text>
                            <Text color="dark" size="sm">
                              {item.body}
                            </Text>
                          </>
                        )}
                        {item.type === 'FILE' && (
                          <>
                            <Text color="dimmed" size="sm">
                              <Text variant="link" component="span" inherit>
                                {item.author.name}
                              </Text>{' '}
                              {translations.recommendations.historyRecommendationAttachementAdded}:
                              <br />
                            </Text>
                            <Anchor href={`${API_URL}/files/${item.data.url}`} target="_blank">
                              {item.data.name}
                            </Anchor>
                          </>
                        )}
                        <Text size="xs" mt={4}>
                          {dateFromNow(item.createdAt)}
                        </Text>
                      </Timeline.Item>
                    );
                  })}

                  <Timeline.Item
                    title={translations.recommendations.historyRecommendationHasBeenAdded}
                    bullet={''}>
                    <Text color="dimmed" size="sm">
                      {translations.recommendations.historyRecommendationHasBeenAdded2}{' '}
                      <Text variant="link" component="span" inherit>
                        {recommendation.author?.name} {recommendation.author?.surname}
                      </Text>
                    </Text>
                    <Text size="xs" mt={4}>
                      {dateFromNow(recommendation.createdAt)}
                    </Text>
                  </Timeline.Item>
                </Timeline>
              </Grid.Col>
            </Grid>
          </>
        )}
      </AppContent>
    </>
  );
};

export default Details;
