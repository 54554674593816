import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import Create from './Create';
import List from './List';
import Details from './Details';

function RecommendationsRoutes() {
  return (
    <Switch>
      <Route path={[routes.admin.recommendation.default.path]} exact>
        <List />
      </Route>
      <Route path={[routes.admin.recommendation.create.path]} exact>
        <Create />
      </Route>
      <Route path={[routes.admin.recommendation.details.path]} exact>
        <Details />
      </Route>
    </Switch>
  );
}

export default RecommendationsRoutes;
