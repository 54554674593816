import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter, RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Loader } from '@mantine/core';

export const AuthorizedRoute = ({ component: Component, ...rest }: any) => {
  const { isLoading, data, error } = useFetch({
    fetchAction: () => apiService({}).get('/api/account/me'),
    initialValue: [],
  });

  const isLoginLoaded = useSelector(state => state.auth.login.states.isLoaded);

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) {
          return (
            <div
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Loader />
            </div>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default compose(withRouter)(AuthorizedRoute);
