import { useCallback } from 'react';
import { useHistory } from 'react-router';

export const usePushAction = (pathname: string, search?: string) => {
  const { push } = useHistory();

  return useCallback(
    () =>
      push({
        pathname,
        search,
      }),
    [pathname, search, push],
  );
};

export const usePushActionWithId = <T, K = number>(
  path: (id: K) => string,
  idGetter: (record: T) => K,
  search?: string,
) => {
  const { push } = useHistory();

  return useCallback(
    (record: T) => () => {
      push({ pathname: path(idGetter(record)), search });
    },
    [push, path, idGetter],
  );
};
