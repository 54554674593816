import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Button, Group } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';

import api from 'shared/services/api';
import { Upload } from 'shared/components/Icons';
import { useTranslations } from 'shared/translations/useTranslations';

import { getCroppedImg } from './helpers';

import './styles.scss';

const CompanyLogo = props => {
  const { id, onNewLogoSet } = props;
  const translations = useTranslations();
  const [crop, setCrop] = useState({
    // aspect: 16 / 9,
    unit: 'px', // default, can be 'px' or '%'
    x: 130,
    y: 50,
    width: 200,
    height: 200,
  });
  const [file, setFile] = useState<{
    url: string;
    data;
  }>(undefined);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = files => {
    setFile({
      url: URL.createObjectURL(files[0]),
      data: files[0],
    });
  };
  const save = async () => {
    const img = await getCroppedImg(image, crop, 'me.jpg');
    const formData = new FormData();
    formData.append('file', img);
    setIsLoading(true);
    try {
      await api({
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).post(`/companies/${id}/logo`, formData);
      setIsLoading(false);
      onNewLogoSet(file.url);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dropzone onDrop={onChange} accept={[MIME_TYPES.png, MIME_TYPES.jpeg]} multiple={false}>
        <Group position="center" spacing="sm" style={{ minHeight: 120 }}>
          {file ? (
            <div className="UploadedFile__wrapper">
              <div onClick={e => e.stopPropagation()}>
                <ReactCrop
                  src={file.url}
                  crop={crop}
                  onImageLoaded={i => setImage(i)}
                  onChange={newCrop => setCrop(newCrop)}
                  style={{ pointerEvents: 'all' }}
                />
              </div>
              <div>{translations.global.changeFile}</div>
            </div>
          ) : (
            <Group position="center">
              <Upload />
              <div>{translations.global.selectFile}</div>
            </Group>
          )}
        </Group>
      </Dropzone>
      <Group position="right" style={{ marginTop: 20 }}>
        <Button onClick={save} loading={isLoading}>
          {translations.global.saveLogo}
        </Button>
      </Group>
    </div>
  );
};

export default CompanyLogo;
