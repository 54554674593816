import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CloseButton,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { Check, Plus, Upload } from 'tabler-icons-react';
import { DatePickerInput } from '@mantine/dates';
import AddCompanyFileCategoryModal from './AddCompanyFileCategoryModal';
import CompanyFilesCategoryRow from './CompanyFilesCategoryRow';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import EditCompanyFileCategoryModal from './EditCompanyFileCategoryModal';
import DeleteCompanyFileCategoryModal from './DeleteCompanyFileCategoryModal';
import { useForm } from '@mantine/form';
import CompanyFilesTable from './CompanyFilesTable';
import { useTranslations } from 'shared/translations/useTranslations';

const useFilesCategories = ({ companyId }) => {
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { categories: [] },
    fetchAction: () => apiService({}).get(`/companies/${companyId}/files/categories`),
  });

  return {
    categories: data.categories,
    isCategoriesLoading: isLoading,
    isCategoriesLoaded: isLoaded,
    isCategoriesError: error,
    refreshCategories: refresh,
  };
};

const CompanyFiles = props => {
  const [shouldRefetchFiles, setShouldRefetchFiles] = useState<undefined | any>();

  const { id: companyId } = useParams<any>();
  const { subCompanies } = props;
  const [isAddCategoryModalOpened, setAddCategoryModalOpened] = useState(false);

  const { categories, isCategoriesLoading, isCategoriesLoaded, refreshCategories } =
    useFilesCategories({ companyId });

  const form = useForm({
    initialValues: {
      files: [],
    },
  });

  const [activeCategoryId, setActiveCategoryId] = useState('');
  const [editCategoryId, setEditCategoryId] = useState<string | undefined>(undefined);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | undefined>(undefined);
  const activeCategory = categories.find(c => c.id === activeCategoryId);
  const initialLoading = !isCategoriesLoaded && isCategoriesLoading;
  useEffect(() => {
    if (!activeCategoryId && categories.length > 0) {
      setActiveCategoryId(categories[0].id);
    }
  }, [categories, activeCategoryId]);

  const handleCloseAddCategoryModal = () => {
    setAddCategoryModalOpened(false);
  };

  const handleOpenAddCategoryModal = () => {
    setAddCategoryModalOpened(true);
  };

  const handleCloseEditCategoryModal = () => {
    setEditCategoryId(undefined);
  };

  const handleOpenEditCategoryModal = (categoryId: string) => {
    setEditCategoryId(categoryId);
  };

  const handleCloseDeleteCategoryModal = () => {
    setDeleteCategoryId(undefined);
  };

  const handleOpenDeleteCategoryModal = (categoryId: string) => {
    setDeleteCategoryId(categoryId);
  };

  const handleFileRemove = removeIndex => () => {
    form.setFieldValue(
      'files',
      form.values.files.filter((_, index) => index !== removeIndex),
    );
  };
  const [isUploading, setUploading] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const handleUpload = async () => {
    try {
      const newStatuses = form.values.files.map((file, index) => {
        return 'WAITING';
      });
      setUploading(true);
      setStatuses(newStatuses);

      await Promise.all(
        form.values.files.map(async (file, fileIndex) => {
          setStatuses(currentStatuses =>
            currentStatuses.map((status, statusIndex) =>
              fileIndex === statusIndex ? 'UPLOADING' : status,
            ),
          );
          const formData = new FormData();
          formData.append('file', file.file);
          formData.append('name', file.name);
          formData.append('description', file.description);
          formData.append('categoryId', activeCategoryId);
          if (file.validUntil) {
            formData.append('validUntil', new Date(file.validUntil).toUTCString());
          }

          await apiService({
            headers: {
              'content-type': 'multipart/form-data',
            },
          }).post(`/companies/${companyId}/files`, formData);
          setStatuses(currentStatuses =>
            currentStatuses.map((status, statusIndex) =>
              fileIndex === statusIndex ? 'UPLOADED' : status,
            ),
          );
        }),
      );
      setShouldRefetchFiles(new Date());
      form.setFieldValue('files', []);
      setStatuses([]);
      setUploading(false);
    } catch (e) {
      setShouldRefetchFiles(new Date());
      setUploading(false);
    }
  };

  const handleDropFiles = files => {
    form.setFieldValue(
      'files',

      files.map(file => ({
        file,
        name: file.name,
        description: '',
        validUntil: undefined,
      })),
    );
  };
  const translations = useTranslations();
  return (
    <div style={{ position: 'relative' }}>
      <Grid>
        <LoadingOverlay visible={initialLoading} />
        <Grid.Col span={3} mr={20}>
          <div style={{ position: 'relative' }}>
            <LoadingOverlay visible={isCategoriesLoaded && isCategoriesLoading} />
            <Flex align="bottom" justify="start" mb={20}>
              <Title order={3} pr={10}>
                {translations.global.categories}{' '}
              </Title>
              <Button size="xs" leftIcon={<Plus />} ml={10} onClick={handleOpenAddCategoryModal}>
                {translations.global.add}
              </Button>
            </Flex>

            {categories.map(category => (
              <CompanyFilesCategoryRow
                category={category}
                active={activeCategoryId === category.id}
                key={category.id}
                onSelect={() => setActiveCategoryId(category.id)}
                onEdit={() => handleOpenEditCategoryModal(category.id)}
                onDelete={() => handleOpenDeleteCategoryModal(category.id)}
              />
            ))}
          </div>
        </Grid.Col>

        <Grid.Col span={8}>
          <Title order={3} mb={10}>
            {translations.global.files} / {activeCategory?.name}
          </Title>
          {!activeCategoryId && <div>{translations.files.addFileCreateCategory}</div>}
          {activeCategoryId && (
            <div>
              <Dropzone
                disabled={isUploading}
                onDrop={handleDropFiles}
                accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
                maxSize={5 * 1024 * 1024}>
                <Group
                  position="center"
                  align="center"
                  style={{ minHeight: 220, pointerEvents: 'none' }}>
                  <Upload size={50} />
                  <div>
                    <Text size="xl" inline>
                      {translations.files.dropZoneFirstLine}
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                      {translations.files.dropZoneSecondLine}
                      <br />
                      {translations.files.dropZoneThirdLine}
                    </Text>
                  </div>
                </Group>
              </Dropzone>

              <Group position="right" mt="md">
                <Button
                  onClick={() => {
                    form.setFieldValue('files', []);
                  }}
                  variant="outline"
                  disabled={form.values.files.length === 0}>
                  {translations.global.cancel}
                </Button>
                <Button onClick={handleUpload} disabled={form.values.files.length === 0}>
                  {translations.global.uploadFiles}
                </Button>
              </Group>
            </div>
          )}
          {form.values.files.length > 0 && (
            <>
              <Title order={4} mt={20} mb={10}>
                {translations.global.filesToUpload}
              </Title>
              <Table captionSide="bottom">
                <thead>
                  <tr>
                    <th>{translations.global.fileName}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {form.values.files.map((file, index) => (
                    <tr key={`${file.index}`}>
                      <td>{file.file.name}</td>
                      <td>
                        <TextInput
                          placeholder={translations.global.fileName}
                          pr={10}
                          pl={10}
                          required
                          {...form.getInputProps(`files.${index}.name`)}
                        />
                      </td>
                      <td>
                        <TextInput
                          name={`description.${index}`}
                          placeholder={translations.global.description}
                          pr={10}
                          pl={10}
                          {...form.getInputProps(`files.${index}.description`)}
                        />
                      </td>
                      <td>
                        <DatePickerInput
                          name="validUntil"
                          placeholder={translations.global.validUntil}
                          clearable
                          pr={10}
                          pl={10}
                          {...form.getInputProps(`files.${index}.validUntil`)}
                        />
                      </td>
                      <td>
                        {!statuses[index] && (
                          <CloseButton aria-label="Close modal" onClick={handleFileRemove(index)} />
                        )}

                        {statuses[index] === 'WAITING' && 'Oczekujący'}
                        {statuses[index] === 'UPLOADING' && <Loader size={'xs'} />}
                        {statuses[index] === 'UPLOADED' && <Check color="green" />}
                        {statuses[index] === 'ERROR' && 'Wystąpił bład. Spróbuj ponownie'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <CompanyFilesTable
            companyId={companyId}
            categoryId={activeCategoryId}
            shouldRefetchFiles={shouldRefetchFiles}
          />
        </Grid.Col>
      </Grid>
      <AddCompanyFileCategoryModal
        subCompanies={subCompanies}
        opened={isAddCategoryModalOpened}
        onClose={handleCloseAddCategoryModal}
        companyId={companyId}
        onSuccess={refreshCategories}
      />
      <EditCompanyFileCategoryModal
        subCompanies={subCompanies}
        opened={!!editCategoryId}
        onClose={handleCloseEditCategoryModal}
        companyId={companyId}
        categoryId={editCategoryId}
        onSuccess={refreshCategories}
      />
      <DeleteCompanyFileCategoryModal
        opened={!!deleteCategoryId}
        onClose={handleCloseDeleteCategoryModal}
        companyId={companyId}
        categoryId={deleteCategoryId}
        onSuccess={refreshCategories}
      />
    </div>
  );
};
export default CompanyFiles;
