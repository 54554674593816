import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContent from 'shared/components/AppContent';
import { routes } from 'shared/constants/routes';
import List from './List';

function StatisticsRoutes() {
  return (
    // <AppContent>
    <Switch>
      <Route path={[routes.statistics.default.path]}>
        <List />
      </Route>
    </Switch>
    // </AppContent>
  );
}

export default StatisticsRoutes;
