import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import ZoneForm from '../ZoneForm';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
}

const CreateZoneModal = (props: Props) => {
  const { refreshParent, onClose, placeId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
    },
    schema: zodResolver(schema),
  });

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        description: formValues.description,
      };
      await api({}).post(`/api/places/${placeId}/zones`, body);
      toast.success(translations.zones.zoneAddedSuccess);
      setSaving(false);
      handleClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <>
      <Modal opened={opened} onClose={handleClose} title={translations.global.add}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <ZoneForm form={form} />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.add}
            </Button>
            <Button variant="outline" onClick={() => setOpened(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>
      <Group position="center">
        <Button onClick={() => setOpened(true)}>{translations.global.add}</Button>
      </Group>
    </>
  );
};

export default CreateZoneModal;
