import React from 'react';

import DeviceActions from '../DeviceActions';
import DeviceImages from '../DeviceImages';
import DeviceObjects from '../DeviceObjects';
import DeviceObservations from '../DeviceObservations';
import useFetch from '../../../../shared/hooks/useFetch';
import { DeviceType } from '../../../../shared/types/deviceType';
import apiService from '../../../../shared/services/api';
import { useParams } from 'react-router';
import { Loader } from '@mantine/core';

const ActionsAndObjects = ({ deviceId, deviceTypeId }) => {
  const params = useParams<{ placeId: string }>();
  const {
    data: deviceType,
    refresh: refreshDeviceType,
    isLoaded: isDeviceTypeLoaded,
    isLoading: isLoadingDeviceType,
    // data, isLoading, isLoaded, error, refresh
  } = useFetch<DeviceType>({
    initialValue: {},
    fetchAction: () =>
      apiService({}).get(`/api/places/${params.placeId}/device-types/${deviceTypeId}`, {}),
  });

  return (
    <div>
      {isLoadingDeviceType && <Loader />}
      {isDeviceTypeLoaded && (
        <>
          <DeviceActions deviceId={deviceId} deviceTypeId={deviceTypeId} />
          <DeviceObjects deviceId={deviceId} deviceTypeId={deviceTypeId} deviceType={deviceType} />
          <DeviceObservations deviceId={deviceId} deviceTypeId={deviceTypeId} />
          <DeviceImages deviceId={deviceId} />
        </>
      )}
    </div>
  );
};

export default ActionsAndObjects;
