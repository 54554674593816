import React from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ userTypes = [], ...rest }: any) => {
  const userType = useSelector(state => state.auth.data.userType);
  if (userTypes.length > 0) {
    if (!userTypes.includes(userType)) {
      return null;
    }
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
