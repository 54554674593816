import React, { useState } from 'react';
import { Badge, Button, Flex, Grid, LoadingOverlay, Menu, Table, Title } from '@mantine/core';
import { Dots, Plus } from 'tabler-icons-react';
import { useParams } from 'react-router';
import useFetch from '../../../../shared/hooks/useFetch';
import apiService from '../../../../shared/services/api';
import AddCompanySubscriptionModal from './Subscriptions/AddCompanySubscriptionModal';
import { readableDate } from 'shared/utils/date';
import EditCompanySubscriptionModal from './Subscriptions/EditCompanySubscriptionModal';
import { useTranslations } from 'shared/translations/useTranslations';

interface CompanySubscription {
  id: string;
  name: string;
  description?: string;
  active: boolean;
  validUntil?: Date;
  devicesLimit?: number;
  usersLimit?: number;
}

const CompanySubscriptions = props => {
  const { id: companyId } = useParams<any>();
  const [isAddSubscriptionModalOpened, setAddSubscriptionModalOpened] = useState(false);
  const [editSubscriptionId, setEditSubscriptionId] = useState<string>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    subscriptions: CompanySubscription[];
  }>({
    initialValue: { subscriptions: [] },
    fetchAction: () => apiService({}).get(`/companies/${companyId}/subscriptions`),
  });

  const handleCloseAddSubscriptionModal = () => {
    setAddSubscriptionModalOpened(false);
  };

  const handleCloseEditSubscriptionModal = () => {
    setEditSubscriptionId(undefined);
  };

  const handleOpenAddSubscriptionModal = () => {
    setAddSubscriptionModalOpened(true);
  };

  const translations = useTranslations();
  return (
    <div style={{ position: 'relative' }}>
      <Grid>
        <LoadingOverlay visible={isLoading} />
        <Grid.Col span={12}>
          <Flex align="bottom" justify="start" mb={10}>
            <Title order={3}>
              {translations.global.subscriptions}{' '}
            </Title>
            <Button size="xs" leftIcon={<Plus />} ml={10} onClick={handleOpenAddSubscriptionModal}>
              {translations.global.add}
            </Button>
          </Flex>
          <Table captionSide="bottom">
            <thead>
              <tr>
                <th>{translations.global.name}</th>
                <th>{translations.global.validUntil}</th>
                <th></th>
                <th>{translations.global.devicesLimit}</th>
                <th>{translations.global.status}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.subscriptions.map(subscription => (
                <tr key={`${subscription.id}`}>
                  <td>{subscription.name}</td>
                  <td>{subscription.validUntil ? readableDate(subscription.validUntil) : '-'}</td>
                  <td>{subscription.devicesLimit}</td>
                  <td>{subscription.usersLimit}</td>
                  <td>
                    {subscription.active ? (
                      <Badge color="green">{translations.global.activeSubscription}</Badge>
                    ) : (
                      <Badge color="red">{translations.global.expired}</Badge>
                    )}
                  </td>

                  <td>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <Button size="xs" variant="light">
                          <Dots />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => {
                            setEditSubscriptionId(subscription.id);
                          }}>
                          Edytuj
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
      <AddCompanySubscriptionModal
        opened={isAddSubscriptionModalOpened}
        onClose={handleCloseAddSubscriptionModal}
        companyId={companyId}
        onSuccess={refresh}
      />
      <EditCompanySubscriptionModal
        subscriptionId={editSubscriptionId}
        opened={!!editSubscriptionId}
        onClose={handleCloseEditSubscriptionModal}
        companyId={companyId}
        onSuccess={refresh}
      />
    </div>
  );
};
export default CompanySubscriptions;
