import React from 'react';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Group, Radio, TextInput, Title } from '@mantine/core';
import { DeviceTypeObservationType } from 'shared/types/deviceType';
import TranslationForm from 'shared/components/TranslationForm';

interface Props {
  form: any;
  isLoading: boolean;
  close: any;
  currentObservations: any[];
  currentId?: string;
}

const DeviceTypeObservationForm = ({
  form,
  isLoading,
  close,
  currentObservations,
  currentId,
}: Props) => {
  const translations = useTranslations();
  const hasRefill = currentObservations?.find(
    observation =>
      observation.type === DeviceTypeObservationType.REFILL && observation.id !== currentId,
  );
  const hasBaitCollect = currentObservations?.find(
    observation =>
      observation.type === DeviceTypeObservationType.BAIT_COLLECT && observation.id !== currentId,
  );
  const disabled = !form.values.type || !form.values.name;
  return (
    <>
      <TextInput required label={translations.global.name} {...form.getInputProps('name')} />
      <Radio.Group mt="md" label={translations.global.type} {...form.getInputProps('type')}>
        <Radio
          value={DeviceTypeObservationType.NEUTRAL}
          label={translations.deviceTypes.observationTypes[DeviceTypeObservationType.NEUTRAL]}
        />
        <Radio
          value={DeviceTypeObservationType.BAIT_COLLECT}
          disabled={hasBaitCollect}
          label={translations.deviceTypes.observationTypes[DeviceTypeObservationType.BAIT_COLLECT]}
        />
        <Radio
          value={DeviceTypeObservationType.REFILL}
          disabled={hasRefill}
          label={translations.deviceTypes.observationTypes[DeviceTypeObservationType.REFILL]}
        />
      </Radio.Group>
      <Title order={5} mt={30} mb={15}>
        {translations.global.translations}
      </Title>
      <TranslationForm
        fieldsLabel={translations.global.name}
        languageKey="lang"
        translationsKey="translations"
        fieldKey="name"
        form={form}
      />

      <Group position="right" mt="md">
        <Button type="submit" loading={isLoading} disabled={disabled}>
          {translations.global.add}
        </Button>
        <Button type="button" variant="outline" onClick={() => close(false)}>
          {translations.global.cancel}
        </Button>
      </Group>
    </>
  );
};

export default DeviceTypeObservationForm;
