import React from 'react';

import Devices from './Devices/';
import Gateways from './Gateways';
import { Route, Switch } from 'react-router';
import { routes } from '../../shared/constants/routes';

function LoraRoutes() {
  return (
    <>
      <Switch>
        <Route path={routes.lora.devices.default.path} component={Devices} />
        <Route path={routes.lora.gateways.default.path} component={Gateways} />
      </Switch>
    </>
  );
}

export default LoraRoutes;
