import React from 'react';
import { Textarea, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  form: any;
}

const ZoneForm = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();
  return (
    <>
      <TextInput
        label={translations.global.name}
        name="name"
        required
        mb={20}
        {...form.getInputProps('name')}
      />

      <Textarea
        label={translations.global.description}
        name="description"
        mb={20}
        {...form.getInputProps('description')}
      />
    </>
  );
};

export default ZoneForm;
