import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Menu,
  Progress,
  Select,
  SimpleGrid,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useHistory } from 'react-router';
import { filter, get, isNumber, map, sortBy, uniqBy } from 'lodash';
import { Dots } from 'tabler-icons-react';

import { routes } from 'shared/constants/routes';
import { useTranslations } from 'shared/translations/useTranslations';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { selectIsControllerByAuthSate } from 'shared/utils/user';

import { LoraDevicesListProps } from './types';
import { toast } from 'react-toastify';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useForm } from '@mantine/form';
import {
  batteryLevelToPercentage,
  batteryLevelToVoltage,
  getBatteryProgressBarColor,
} from '../../../../shared/utils/device';

const List = (props: LoraDevicesListProps) => {
  const history = useHistory();
  const translations = useTranslations();
  const goToCreateLoraDevice = () => {
    history.push(routes.lora.devices.create.path);
  };

  const goToEditLoraDevice = (loraDeviceUuid: string) => () => {
    history.push(routes.lora.devices.edit.to(loraDeviceUuid));
  };

  const {
    data: loraDevices,
    isLoading,
    refresh: refreshLoraDevices,
  } = useFetch<{ loraDevices: LoraDevice[] }>({
    fetchAction: () => apiService({}).get(`/api/lora/devices`),
    initialValue: { loraDevices: [] },
    shouldNotFetchAtStart: false,
    fetchActionDeps: [],
  });

  const isController = selectIsControllerByAuthSate();
  const [isDeletingLoraDevice, setIsDeletingLoraDevice] = useState(false);

  const onClickDelete = useCallback(
    (loraDeviceUuid: string) => async () => {
      setIsDeletingLoraDevice(true);
      try {
        await apiService({}).delete(`/api/lora/devices/${loraDeviceUuid}`);
        refreshLoraDevices();
      } catch (error) {
        toast.error(translations.global.somethingWentWrong);
      } finally {
        setIsDeletingLoraDevice(false);
      }
    },
    [setIsDeletingLoraDevice, refreshLoraDevices],
  );
  const form = useForm({
    initialValues: {
      companyId: undefined,
      placeId: undefined,
      sortBy: 'createdAt',
      version: 'ALL',
      name: undefined,
    },
  });
  const companiesList = useMemo(() => {
    const companies = loraDevices.loraDevices
      .filter(loraDevice => loraDevice.company)
      .map(loraDevice => loraDevice.company);
    return sortBy(uniqBy(companies, 'id'), ['name']).map(company => ({
      value: company.uuid,
      label: company.name,
    }));
  }, [loraDevices]);
  const placesList = useMemo(() => {
    const places = loraDevices.loraDevices
      .filter(loraDevice => loraDevice.place)
      .map(loraDevice => loraDevice.place);

    return sortBy(uniqBy(places, 'id'), ['name']).map(place => ({
      value: place.id,
      label: place.name,
    }));
  }, [loraDevices]);

  const filtered = useMemo(() => {
    let result = [...loraDevices.loraDevices];
    if (form.values.name) {
      result = filter(result, loraDevice =>
        String(loraDevice.name).toLowerCase().includes(String(form.values.name).toLowerCase()),
      );
    }
    if (form.values.companyId) {
      result = filter(result, loraDevice => loraDevice.company?.uuid === form.values.companyId);
    }
    if (form.values.placeId) {
      result = filter(result, loraDevice => loraDevice.place?.id === form.values.placeId);
    }
    if (form.values.version === 'OLD') {
      result = filter(result, loraDevice => loraDevice.appEui === '0000000000000000');
    }

    if (form.values.version === 'NEW') {
      result = filter(result, loraDevice => loraDevice.appEui === '0101010101010101');
    }

    if (['createdAt', 'lastUplinkAt'].includes(form.values.sortBy)) {
      result = sortBy(result, [form.values.sortBy]).reverse();
    } else {
      result = sortBy(result, [form.values.sortBy]);
    }
    return result;
  }, [loraDevices, form.values]);

  return (
    <AppContent>
      <LoadingOverlay
        visible={isDeletingLoraDevice}
        loaderProps={{ size: 'lg', variant: 'dots' }}></LoadingOverlay>
      <Flex justify="space-between" mb={20}>
        <Title>
          {translations.loraDevice.loraDevice}
          <SimpleGrid cols={5} mb={20}>
            <TextInput
              name="name"
              label={translations.global.name}
              {...form.getInputProps('name')}
            />
            <Select
              name="version"
              label={'Wersja'}
              data={[
                { value: 'ALL', label: 'Wszystkie' },
                { value: 'OLD', label: 'Stare' },
                { value: 'NEW', label: 'Nowe' },
              ]}
              {...form.getInputProps('version')}
              defaultValue="ALL"
              searchable
              clearable
            />
            <Select
              name="companyId"
              label={translations.global.company}
              data={companiesList}
              {...form.getInputProps('companyId')}
              searchable
              clearable
            />
            <Select
              name="placeId"
              label={translations.global.facility}
              data={placesList}
              {...form.getInputProps('placeId')}
              searchable
              clearable
            />
            <Select
              name="sortBy"
              label={translations.global.sortBy}
              data={[
                { value: 'createdAt', label: translations.global.createdAt },
                { value: 'lastUplinkAt', label: translations.global.lastInformation },
                { value: 'name', label: translations.global.name },
              ]}
              {...form.getInputProps('sortBy')}
              searchable
            />
            {/* <TextInput
              label={translations.places.searchPlace}
              placeholder={translations.places.searchPlace}
              icon={<Search size={14} />}
              onChange={event => setSearchFilter(event.currentTarget.value)}
              value={searchFilter}
            /> */}
          </SimpleGrid>
        </Title>
        <div>
          <Button onClick={() => refreshLoraDevices()} mb={20} mr={15} variant="outline">
            {translations.global.refresh}
          </Button>
          <Button onClick={goToCreateLoraDevice} mb={20}>
            {translations.loraDevice.list.create}
          </Button>
        </div>
      </Flex>

      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>{translations.global.name}</Th>
            <Th>{translations.global.createdAt}</Th>
            <Th>{translations.global.lastInformation}</Th>
            <Th>{translations.global.company}</Th>
            <Th>{translations.global.facility}</Th>
            <Th>{translations.global.device}</Th>
            <Th>{translations.loraDevice.devEui}</Th>
            <Th>{translations.global.detectStatus}</Th>
            <Th>{translations.global.detectionCounter}</Th>
            <Th>{translations.devices.battery}</Th>
            <Th>{translations.global.actions}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {get(loraDevices, 'length') === 0 && (
            <Tr>
              <Td colSpan={5}>{translations.reports.noReports}</Td>
            </Tr>
          )}
          {map(filtered, loraDevice => {
            const percentageBatteryLevel = Number(
              batteryLevelToPercentage(loraDevice?.lastUplinkData?.Status?.batteryLevel) || -1,
            );
            return (
              <Tr key={loraDevice.uuid}>
                <Td>
                  <Link to={routes.lora.devices.details.to(loraDevice.uuid)}>
                    {get(loraDevice, 'name')}
                    {get(loraDevice, 'appEui') === '0000000000000000' ? (
                      <Badge color="pink">old</Badge>
                    ) : null}
                    {}
                  </Link>
                </Td>
                <Td>{readableDate(get(loraDevice, 'createdAt'))}</Td>
                <Td>
                  {loraDevice.lastUplinkAt ? (
                    <Tooltip label={readableDate(get(loraDevice, 'lastUplinkAt'))}>
                      <span>{moment(get(loraDevice, 'lastUplinkAt')).fromNow()}</span>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>
                  {loraDevice.company ? (
                    <Link to={routes.companies.manage.to(loraDevice.company.uuid)}>
                      {get(loraDevice, 'company.name')}
                    </Link>
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>
                  {loraDevice.place ? (
                    <Link to={routes.places.manage.to(loraDevice.place.id)}>
                      {get(loraDevice, 'place.name')}
                    </Link>
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>
                  {loraDevice.device ? (
                    <Link to={routes.devices.manage.to(loraDevice.device.id)}>
                      {get(loraDevice, 'device.uniqueId')}
                    </Link>
                  ) : (
                    '-'
                  )}
                </Td>

                <Td>{get(loraDevice, 'devEui')}</Td>
                <Td>
                  {get(loraDevice, 'detectedValue') === true ? (
                    <>
                      <Tooltip label={readableDate(loraDevice.detectedAt)}>
                        <Badge color="red">{moment(loraDevice.detectedAt).fromNow()}</Badge>
                      </Tooltip>
                    </>
                  ) : (
                    <Badge color="green">{translations.devices.online}</Badge>
                  )}
                </Td>
                <Td>
                  {loraDevice.lastUplinkData?.Status
                    ? loraDevice.lastUplinkData?.Status.detectionsCounterNew ||
                      loraDevice.lastUplinkData?.Status.detectionsCounter
                    : '-'}
                </Td>
                <Td>
                  <div>
                    {loraDevice?.lastUplinkData &&
                      isNumber(loraDevice?.lastUplinkData?.Status?.batteryLevel) && (
                        <Group>
                          <div style={{ width: 100 }}>
                            <Progress
                              style={{ height: 20, fontSize: 16 }}
                              color={getBatteryProgressBarColor(percentageBatteryLevel)}
                              value={percentageBatteryLevel}
                              size="xl"
                              label={`${percentageBatteryLevel}%`}
                            />{' '}
                          </div>
                          {batteryLevelToVoltage(loraDevice?.lastUplinkData?.Status?.batteryLevel)}V
                        </Group>
                      )}
                  </div>
                </Td>
                <Td>
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <Button size="xs" variant="light">
                        <Dots />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item onClick={goToEditLoraDevice(loraDevice.uuid)}>
                        {translations.global.edit}
                      </Menu.Item>
                      {!isController && (
                        <Menu.Item onClick={onClickDelete(loraDevice.uuid)} color="red">
                          {translations.global.delete}
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </AppContent>
  );
};
export default List;
