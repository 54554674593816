import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import { readableDate } from 'shared/utils/date';
import { get, reverse, sortBy } from 'lodash';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { USER_CONTROLLER } from 'shared/constants/user';
import EditCategoryModal from './EditCategoryModal';
import AddFileForm from './AddFileForm';
import RotateLoader from 'shared/components/RotateLoader';
import { useTranslations } from 'shared/translations/useTranslations';
import { Group, Button } from '@mantine/core';

interface Props {
  placeId: string | number;
  categoriesData: any;
  selectedCategory: number | undefined;
}

interface PlaceFile {
  id: number;
  createdAt: string;
  deletedAt: string | null;
  placeId: number;
  userId: number;
  name: string;
  note: true;
  downloadUrl: string;
}
interface CompanyFile {
  id: string;
  validUntil: Date;
  name: string;
  url: string;
  categoryId?: string;
}
interface State {
  files: PlaceFile[];
  companyFiles: CompanyFile[];
}

async function fetchData(setData, props, setLoading) {
  try {
    setLoading(true);
    const { data } = await api({}).get(`places/${props.placeId}/files`);
    setData(data);
  } catch (e) {
  } finally {
    setLoading(false);
  }
}

const Files = (props: Props) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sortProperty, setSortProperty] = useState('name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const { categoriesData } = props;
  const categoriesDataSource = categoriesData.categories
    ? categoriesData.categories.map(c => ({ label: c.name, value: String(c.id) }))
    : [];
  const [fileToEdit, setFileToEdit] = useState();
  const [data, setData] = useState<State>({
    files: [],
    companyFiles: [],
  });
  const authData = useSelector(state => state.auth.data);
  const user = authData?.user?.data;
  const userType = authData?.userType;

  useEffect(() => {
    fetchData(setData, props, setLoading);
  }, []);

  const onDownloadFile = file => async () => {
    const {
      data: { url },
    } = await api({}).get(`places/${props.placeId}/files/${file.id}/signed-url`);
    await saveFileFromUrl(
      url,
      `${get(file, 'name')}_${readableDate(file.createdAt)}`.toLowerCase(),
    );
  };

  const onDownloadCompanyFile = file => async () => {
    const {
      data: { url },
    } = await api({}).get(`/companies/${file.companyId}/files/${file.id}/signed-url`);
    await saveFileFromUrl(
      url,
      `${get(file, 'name')}_${readableDate(file.createdAt)}`.toLowerCase(),
    );
  };

  const onDeleteFile = file => async () => {
    await api({}).delete(`places/${props.placeId}/files/${file.id}`);
    setTimeout(() => fetchData(setData, props, setLoading), 1000); // TODO: It seems that we need to wait for that response
  };
  let sortedData =
    sortOrder === 'DESC'
      ? reverse(sortBy([...data.files, ...data.companyFiles], sortProperty))
      : sortBy([...data.files, ...data.companyFiles], sortProperty);
  if (props.selectedCategory && sortedData) {
    sortedData = sortedData.filter(
      file =>
        file.category?.id === props.selectedCategory || file.categoryId === props.selectedCategory,
    );
  }

  const refetch = () => fetchData(setData, props, setLoading);
  return (
    <div>
      <EditCategoryModal
        close={(shouldRefetch: boolean) => {
          setFileToEdit(undefined);
          if (shouldRefetch) {
            setTimeout(() => fetchData(setData, props, setLoading), 100);
          }
        }}
        placeId={props.placeId}
        fileId={fileToEdit?.id}
        onFinish={() => {
          refetch();
        }}
        categories={categoriesDataSource}
      />
      <AddFileForm
        categoriesDataSource={categoriesDataSource}
        placeId={props.placeId}
        refetch={() => {
          fetchData(setData, props, setLoading);
        }}
      />
      <h3>{translations.global.files}</h3>
      <Table fixed isLoading={isLoading}>
        <Thead>
          <Th style={{ width: '30px' }}>#</Th>
          <Th>{translations.global.name}</Th>
          <Th>{translations.global.description}</Th>
          <Th
            onSort={() => {
              setSortProperty('category.name');
              setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
            }}
            sortable>
            {translations.global.category}
          </Th>
          <Th>{translations.global.author}</Th>
          <Th
            onSort={() => {
              setSortProperty('createdAt');
              setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC');
            }}
            sortable>
            {translations.global.createdAt}
          </Th>
          <Th>{translations.global.actions}</Th>
        </Thead>
        <Tbody>
          {sortedData.map((file, index) => (
            <Tr key={file.id}>
              <Td style={{ width: '30px' }}>{index + 1}</Td>
              <Td>{file.name || '-'}</Td>
              <Td>{file.note || '-'}</Td>
              <Td>
                <div style={{ paddingRight: 20 }}>
                  {file.category && (
                    <i
                      className="fa fa-folder"
                      style={{ marginRight: '5px', color: 'rgba(0,0,0,0.3)' }}
                    />
                  )}
                  {file.category?.name || '-'}
                </div>
              </Td>
              <Td>
                {file.user?.name} {file.user?.surname}
              </Td>
              <Td>{readableDate(file.createdAt)}</Td>
              <Td>
                {{ ...file }.hasOwnProperty('validUntil') && (
                  <Button variant="outline" onClick={onDownloadCompanyFile(file)} size="sm">
                    {translations.global.download}
                  </Button>
                )}
                {!{ ...file }.hasOwnProperty('validUntil') && (
                  <Group>
                    <Button variant="outline" onClick={onDownloadFile(file)} size="sm">
                      {translations.global.download}
                    </Button>
                    {userType !== USER_CONTROLLER && (
                      <Button variant="outline" onClick={() => setFileToEdit(file)} size="sm">
                        {translations.global.edit}
                      </Button>
                    )}
                    {(userType !== USER_CONTROLLER || file.user?.id === user.id) && (
                      <Button variant="outline" onClick={onDeleteFile(file)} size="sm">
                        {translations.global.delete}
                      </Button>
                    )}
                  </Group>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};
export default Files;
