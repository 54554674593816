import React from 'react';
import classnames from 'classnames';
import './styles.scss';

export default ({ relative }) => {
  const classes = classnames('RotateLoader', {
    'RotateLoader--relative': relative
  });
  return <div className={classes} />;
};
