import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import EditInspectionObservationForm from '../EditInspectionObservationForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';
import { DeviceTypeObservationType } from '../../../../../shared/types/deviceType';

const schema = z.object({
  name: z.string(),
  description: z.string().optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  observationId: string;
  inspectionId: number;
  mixturesById: any;
}

interface Data {
  observation: {
    deviceTypeObservation: {
      name: string;
    };
    note: string;
    amount: number;
    data?: {
      type: DeviceTypeObservationType;
      amountPercentage: number;
      amount: number;
      totalMixtures: number;
      previousMixtures: number;
      mixtures?: {
        [key: string]: {
          grammage: number;
          mixtureId: string;
          totalValue: number;
          value: number;
        };
      };
    };
  };
}

const EditInspectionObservationModal = (props: Props) => {
  const { refreshParent, onClose, placeId, observationId, inspectionId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      api({}).get(
        `/api/places/${placeId}/inspections/${inspectionId}/observations/${observationId}`,
      ),
  });

  const form = useForm({
    initialValues: {
      name: '',
      type: '',
      amountPercentage: '0',
      totalMixtures: 0,
      previousMixtures: 0,
      mixtures: {},
      createdAt: undefined,
    },
    schema: zodResolver(schema),
  });

  useEffect(() => {
    if (data.observation) {
      form.setValues({
        name: data.observation?.deviceTypeObservation?.name,
        type: data.observation?.data?.type,
        amountPercentage: String(data.observation?.data?.amountPercentage),
        totalMixtures: data.observation?.data?.totalMixtures,
        previousMixtures: data.observation?.data?.previousMixtures,
        mixtures: data.observation?.data?.mixtures,
        createdAt: new Date(data.observation?.createdAt),
      });
    }
  }, [data]);

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      let body = {
        amountPercentage: formValues.amountPercentage,
        type: formValues.type,
        mixtures: formValues.mixtures,
        totalMixtures: formValues.totalMixtures,
        createdAt: formValues.createdAt,
      };

      if (formValues.type === DeviceTypeObservationType.BAIT_COLLECT) {
        body.amountPercentage = formValues.amountPercentage;
      }
      if (formValues.type === DeviceTypeObservationType.REFILL) {
        const updated = updateMixtures(formValues.mixtures);
        body.mixtures = updated.updatedMixtures;
        body.totalMixtures = updated.totalMixtures;
      }

      await api({}).patch(
        `/api/places/${placeId}/inspections/${inspectionId}/observations/${observationId}`,
        body,
      );
      toast.success('Obserwacja została zaktualizowana');
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!observationId);
    if (observationId) {
      refresh();
    }
  }, [observationId]);

  return (
    <Modal opened={opened} onClose={handleClose} title={'Edytuj obserwację'}>
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && <EditInspectionObservationForm form={form} mixturesById={props.mixturesById} />}
        <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
          <strong>Zmieniając:</strong><br />
          1. ilość pobranej trutki<br />
          2. ilość wyłożonej trutki <br />
          3. lub datę obserwacji,<br />
          <br />
          należy później manualnie edytować kolejne lub poprzednie obserwację. Tyczy się to również
          zmian wymaganych w innych przeglądach.
        </Alert>
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

const updateMixtures = (mixtures: {
  [key: string]: { grammage: number; mixtureId: string; totalValue: number; value: number };
}) => {
  // Tworzymy nowy obiekt zaktualizowany na podstawie oryginalnych danych
  const updatedMixtures = Object.entries(mixtures).reduce((acc, [key, mixture]) => {
    // Obliczamy totalValue na podstawie value i grammage
    acc[key] = { ...mixture, totalValue: mixture.value * mixture.grammage };
    return acc;
  }, {} as { [key: string]: { grammage: number; mixtureId: string; totalValue: number; value: number } });

  // Obliczamy totalMixtures, sumując totalValue ze wszystkich elementów
  const totalMixtures = Object.values(updatedMixtures).reduce(
    (sum, { totalValue }) => sum + totalValue,
    0,
  );

  // Zwracamy zaktualizowaną kopię mixtures i totalMixtures
  return { updatedMixtures, totalMixtures };
};
export default EditInspectionObservationModal;
