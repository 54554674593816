import { SelectProps } from '@mantine/core';
import { Moment } from 'moment';

import { PeriodicityUnit, ScheduleEvent } from '../types';

export interface VisitFormValues {
  name: string;
  type: 'INTERVENTION' | 'INSPECTION';
  placeUuid: string;
  userUuids: string[];
  dateFrom: Date;
  dateTo: Date;
  periodicity?: {
    unit: PeriodicityUnit;
    endDate?: Date;
    count?: number;
    withoutWeekends?: boolean;
  };
  periodical: boolean;
  customPeriodicity: boolean;
  timeFrom: string;
  timeTo: string;
  description?: string;
}

export interface VisitFormProps {
  initialValues: Partial<VisitFormValues>;
  onSaveEvent: (values: ScheduleEvent, isUpdate: boolean) => void;
  onDeleteEvent: (uuid: ScheduleEvent['uuid']) => void;
  placeUuid?: string;
  uuid?: string;
}

export enum VisitType {
  'INTERVENTION' = 'INTERVENTION',
  'INSPECTION' = 'INSPECTION',
  'NEUTRAL' = 'NEUTRAL',
  'MAINTENANCE' = 'MAINTENANCE',
  'OTHER' = 'OTHER',
}
