import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FieldError from '../FieldError';
import RotateLoader from '../RotateLoader';
import './styles.scss';
import FieldLabel from '../FieldLabel';

class FieldWrapper extends PureComponent {
  render() {
    const {
      meta,
      label,
      tooltip,
      labelLeft,
      noMargin,
      className,
      iconLeft,
      icon,
      input,
      children,
      onClick,
      onKeyDown,
      isRequired,
      disabled,
      hiddenDisabled,
      isLoading,
      noBorder,
    } = this.props;
    const classes = classnames('FieldWrapper', className, {
      'FieldWrapper--label-left': labelLeft,
      'FieldWrapper--no-margin': noMargin,
    });

    const wrapperClasess = classnames('FieldWrapper__input-wrapper', className, {
      'FieldWrapper__input-wrapper--disabled': disabled && !hiddenDisabled,
      'FieldWrapper__input-wrapper--error': meta.touched && meta.error,
      'FieldWrapper__input-wrapper--no-border': noBorder,
    });

    return (
      <div className={classes} onClick={onClick} onKeyDown={onKeyDown}>
        {label && (
          <FieldLabel
            isRequired={isRequired}
            labelLeft={labelLeft}
            tooltip={tooltip}
            htmlFor={input.name}>
            {' '}
            {label}
          </FieldLabel>
        )}
        <div className={wrapperClasess}>
          {iconLeft && <div className="FieldWrapper__icon-left">{iconLeft}</div>}
          {children}
          {icon && <img src={icon} alt="icon" className="FieldWrapper__icon" />}
          {isLoading && (
            <div className="FieldWrapper__loader">
              <RotateLoader relative />
            </div>
          )}
        </div>
        <FieldError meta={meta} />
      </div>
    );
  }
}

FieldWrapper.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  label: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  tooltip: PropTypes.node,
  iconLeft: PropTypes.node,
  labelLeft: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default FieldWrapper;
