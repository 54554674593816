import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, Field, getFormValues, change } from 'redux-form';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import { Paper, Tabs } from '@mantine/core';
import { find } from 'lodash';
import QRCode from 'react-qr-code';

import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import SelectField from 'shared/components/SelectField';
import FormSection from 'shared/components/FormSection';
import {
  isAnyAdminByAuthState,
  isCompanyAdminByAuthState,
  isControllerByAuthState,
  isSuperAdminByAuthState,
  isTechnicianByAuthState,
} from 'shared/utils/user';
import ConfirmModal from 'shared/components/ConfirmModal';
import { translationService } from 'shared/translations/translations';

import { fetchDeviceTypes } from 'modules/DeviceType/reducers/list';
import {
  editDevice,
  fetchDevice,
  fetchPlaces,
  deleteDevice,
  cleanDetect,
} from 'modules/Device/reducers/manage';
import RadioOptions from './RadioOptions';
import formValidation from './formValidate';
import ActionsAndObjects from './ActionsAndObjects';
import History from './History';
import RssiChart from './RssiChart';
import BatteryChart from './BatteryChart';
import './styles.scss';
import DetectionCounterChart from './DetectionCounterChart';

const FORM_NAME = 'device/MANAGE';

class List extends PureComponent {
  translations = translationService.getTranslations();
  state = { isDeleteModalOpen: false, deleteId: null };
  componentDidMount() {
    const id = get(this.props, 'match.params.id') || get(this.props, 'match.params.deviceId');

    this.props.fetchPlaces();
    this.props.fetchDeviceTypes();
    if (id) {
      this.props.fetchDevice({ id });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deleteStates.isLoading && this.props.deleteStates.isLoaded) {
      this.setState({ isDeleteModalOpen: false, deleteId: null });
    }
  }

  submit = () => {
    const { description, loraDevEui, placeId, deviceTypeId, id, uniqueId, zoneId, location } =
      this.props.formValues;
    this.props.editDevice({
      id,
      description,
      loraDevEui,
      placeId,
      deviceTypeId,
      uniqueId,
      zoneId,
      location,
    });

    return;
  };

  toggleDeleteModal = id => e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
      deleteId: id,
    });
  };

  deleteDevice = () => {
    this.props.deleteDevice({ id: this.state.deleteId });
  };
  render() {
    const {
      editStates,
      handleSubmit,
      fetchStates,
      data,
      places,
      deviceTypes,
      formValues,
      isSuperAdmin,
      deleteStates,
      isAdmin,
      isTechnician,
    } = this.props;
    const deviceId = get(this.props, 'match.params.id') || get(this.props, 'match.params.deviceId');
    // const date = get(data, 'deviceHistory[0].det');
    const isMouseeDevice = get(formValues, 'deviceTypeId') === 1; // TODO: Should check for param isElectronic
    const canEditForm = isSuperAdmin;
    const selectedPlaceId = formValues?.placeId;

    const zonesOptions =
      find(places, place => place.id === selectedPlaceId)?.zones?.map(zone => ({
        value: zone.id,
        label: zone.name,
      })) || [];

    const detectionCounter = get(data, 'lastHistoryDetectionCounter');
    return (
      <ContentLoader states={fetchStates} isNew={!deviceId}>
        <ConfirmModal
          isOpen={this.state.isDeleteModalOpen}
          paragraph={
            <div>
              {this.translations.devices.confirmDeleteFirstLine}
              <br />  {this.translations.devices.confirmDeleteSecondLine}
            </div>
          }
          isLoading={deleteStates.isLoading}
          onClose={this.toggleDeleteModal(null)}
          onConfirm={this.deleteDevice}
          title={this.translations.devices.deleteDevice}
          confirmLabel={this.translations.global.delete}
          cancelLabel={this.translations.global.cancel}
        />

        <Paper shadow="xs">
          <Tabs aria-label="disabled tabs example" defaultValue="0">
            <Tabs.List>
              <Tabs.Tab value="0" style={{ padding: '25px' }}>
                {this.translations.devices.details}
              </Tabs.Tab>
              {deviceId && (
                <Tabs.Tab value="1" style={{ padding: '25px' }}>
                  {this.translations.devices.actionsAndObjects}
                </Tabs.Tab>
              )}
              {deviceId && (
                <Tabs.Tab value="2" style={{ padding: '25px' }}>
                  {this.translations.devices.history}
                </Tabs.Tab>
              )}
              {deviceId && isSuperAdmin && (
                <Tabs.Tab value="3" style={{ padding: '25px' }}>
                  {this.translations.devices.radioOptions}
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="0">
              <div className="">
                <form onSubmit={handleSubmit(this.submit)}>
                  <div className="CampaignHeader">
                    <div className="HeaderTextField__input">
                      {this.translations.global.device} {data.uniqueId} /{' '}
                      {!deviceId ? 'Dodaj nowe' : ''} <small>{get(data, 'place.name')}</small>
                    </div>
                    <div className="CampaignHeader__right">
                      {isAdmin && (
                        <Button
                          type="red"
                          isLoading={deleteStates.isLoading}
                          buttonType="button"
                          onClick={this.toggleDeleteModal(deviceId)}>
                          {this.translations.global.delete}
                        </Button>
                      )}
                      {(isAdmin || isTechnician) && (
                        <Button type="primary" isLoading={editStates.isLoading}>
                          {this.translations.global.save}
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="ManageSurvey">
                    <FormSection isActive>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            component={TextField}
                            name="uniqueId"
                            label={this.translations.devices.uniqueId}
                            isRequired
                            disabled={!canEditForm}
                          />
                          <Field
                            component={TextField}
                            name="description"
                            label={this.translations.global.description}
                            isRequired
                          />
                          <Field
                            component={TextField}
                            name="location"
                            label={this.translations.devices.location}
                          />
                          <Field
                            component={SelectField}
                            name="deviceTypeId"
                            label={this.translations.global.deviceType}
                            isRequired
                            dataSource={map(deviceTypes, type => ({
                              value: type.id,
                              label: type.name,
                            }))}
                            disabled={!canEditForm || !!deviceId}
                          />
                          <Field
                            component={SelectField}
                            name="placeId"
                            label={this.translations.global.facility}
                            isRequired
                            onChangeOption={() => {
                              this.props.changeFormValue(FORM_NAME, 'zoneId', undefined);
                              this.props.changeFormValue(FORM_NAME, 'mapId', undefined);
                            }}
                            tooltip="Zmiana placówki dla urządzenia jest niemozliwa. Nalezy stworzyc nowe urzadzenie do kolejnej placowki. Aby przepiąc urządzenie Mousee, nalezy usunac tutaj loraDevEui, i wykorzystac w nowym."
                            dataSource={map(places, place => ({
                              value: place.id,
                              label: place.name,
                            }))}
                            disabled={!!deviceId}
                          />
                          <Field
                            component={SelectField}
                            name="zoneId"
                            label={this.translations.zones.zone}
                            dataSource={zonesOptions}
                          />
                          {isMouseeDevice && (
                            <Field
                              component={TextField}
                              name="loraDevEui"
                              label="loraDevEui"
                              disabled={!canEditForm}
                            />
                          )}
                          {data.qrcodeId && <QRCode value={data.qrcodeId} />}
                        </div>
                        <div className="col-md-6">
                          {detectionCounter > 0 && (
                            <div className="device-box__last-action device-box__row">
                              <i className="fas fa-stopwatch-20 device-box__icon"></i>
                              Ilość detekcji: {detectionCounter}
                            </div>
                          )}
                          {data.batteryHistory && isMouseeDevice && (
                            <BatteryChart data={data.batteryHistory} />
                          )}
                          {data.batteryHistory && isMouseeDevice && (
                            <RssiChart data={data.batteryHistory} />
                          )}
                          {data.batteryHistory && isMouseeDevice && (
                            <DetectionCounterChart data={data.batteryHistory} />
                          )}
                        </div>
                      </div>
                    </FormSection>
                  </div>
                </form>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="1">
              <div className="CWrapper">
                <ActionsAndObjects
                  deviceId={deviceId}
                  deviceTypeId={this.props.data.deviceTypeId}
                  actions={reverse(sortBy(data.deviceActions))}
                  objects={reverse(sortBy(data.deviceObjects))}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="2">
              <div className="CWrapper">
                <History
                  data={reverse(sortBy(data.deviceHistory, 'createdAt'))}
                  deviceId={deviceId}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="3">
              <div className="CWrapper">
                <RadioOptions deviceId={deviceId} />
              </div>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </ContentLoader>
    );
  }
}

const mapStateToProps = ({ devices: { manage }, deviceTypes, auth, ...state }, ownProps) => {
  const deviceId =
    get(ownProps, 'match.params.id') || get(ownProps, 'match.params.deviceId');

  return {
    formValues: getFormValues(FORM_NAME)(state),
    data: deviceId ? manage.details.data : {},
    places: manage.places.data,
    fetchStates: manage.details.states,
    editStates: manage.edit.states,
    initialValues: deviceId ? manage.details.data : {},
    deviceTypes: deviceTypes.list.data,
    isSuperAdmin: isSuperAdminByAuthState(auth),
    isController: isControllerByAuthState(auth),
    isTechnician: isTechnicianByAuthState(auth),
    isAdmin: isAnyAdminByAuthState(auth),
    isCompanyAdmin: isCompanyAdminByAuthState(auth),
    // userType:
    deleteStates: manage.delete.states,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDevice,
      editDevice,
      fetchPlaces,
      fetchDeviceTypes,
      cleanDetect,
      deleteDevice,
      changeFormValue: change,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: formValidation,
  }),
)(List);
