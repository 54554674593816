import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import { readableDate } from 'shared/utils/date';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { toast } from 'react-toastify';
import { USER_CONTROLLER } from 'shared/constants/user';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Group, Title } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import DeleteCategoryModal from '../Files/DeleteCategoryModal';
import EditCategoryModal from '../Files/EditCategoryModal';

const FORM_NAME = 'place/FILES_CATEGORIES';
interface Props {
  placeId: string | number;
  setCategoriesData: any;
  categoriesData: any;
  selectedCategory: any;
  setSelectedCategory: any;
}

interface PlaceFile {
  id: number;
  createdAt: string;
  deletedAt: string | null;
  placeId: number;
  userId: number;
  name: string;
}
interface State {
  categories: PlaceFile[];
}

async function fetchData(setCategoriesData, props, setLoading) {
  try {
    setLoading(true);
    const { data } = await api({}).get(`places/${props.placeId}/files-categories`);
    setCategoriesData(data);
  } catch (e) {
  } finally {
    setLoading(false);
  }
}

const FilesCategories = (props: Props) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [categoryToEdit, setCategoryToEdit] = useState();
  const [categoryToDelete, setCategoryToDelete] = useState();
  const { setCategoriesData, categoriesData, selectedCategory, setSelectedCategory } = props;
  const [isSaving, setSaving] = useState(false);

  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};
  const userType = useSelector(state => state.auth.data.userType);

  useEffect(() => {
    fetchData(setCategoriesData, props, setLoading);
  }, []);

  const refetch = () => fetchData(setCategoriesData, props, setLoading);

  const saveFile = async () => {
    if (!values || !values.name) {
      return;
    }
    try {
      setSaving(true);
      await api({}).post(`places/${props.placeId}/files-categories`, { name: values.name });
      toast.success('Kategoria została dodana');
      setTimeout(() => fetchData(setCategoriesData, props, setLoading), 1000); // TODO: It seems that we need to wait for that response
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <EditCategoryModal
        categoryId={categoryToEdit?.id}
        placeId={props?.placeId}
        // action={actionToEdit}
        onFinish={refetch}
        close={() => setCategoryToEdit(undefined)}
      />
      <DeleteCategoryModal
        categoryId={categoryToDelete?.id}
        placeId={props?.placeId}
        isOpen={!!categoryToDelete}
        onFinish={refetch}
        close={() => {
          setCategoryToDelete(undefined);
        }}
      />
      <Title order={4}>{translations.global.categories}</Title>
      <Table isLoading={isLoading}>
        <Thead>
          <Th>#</Th>
          <Th>{translations.global.category}</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          {categoriesData.categories.map((category, index) => (
            <Tr
              key={category.id}
              onClick={() =>
                category.id === selectedCategory
                  ? setSelectedCategory(undefined)
                  : setSelectedCategory(category.id)
              }>
              <Td>
                <i
                  className="fa fa-folder"
                  style={{
                    marginRight: '10px',
                    color:
                      selectedCategory === category.id
                        ? 'rgba(0, 171, 145, 0.8)'
                        : 'rgba(0,0,0,0.3)',
                    fontSize: 20,
                    verticalAlign: 'middle',
                  }}
                />
                {category.name || '-'}
              </Td>
              <Td>
                <div style={{ display: 'flex' }}>
                  {userType !== USER_CONTROLLER && (
                    <Group>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCategoryToEdit(category);
                        }}
                        compact
                        size="xs"
                        color="blue">
                        <Pencil size={14} />
                      </Button>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCategoryToDelete(category);
                          // onDeleteFile(category)();
                        }}
                        compact
                        size="xs"
                        color="red">
                        <Trash size={14} />
                      </Button>
                    </Group>

                    // <Button onClick={onDeleteFile(file)} buttonType="button" small>
                    //   {translations.global.delete}
                    // </Button>
                  )}
                </div>
              </Td>
            </Tr>
          ))}
          {categoriesData.companyCategories?.length > 0 && (
            <Title order={4} mt={20}>
              Kategorie z firmy
            </Title>
          )}
          {categoriesData.companyCategories?.map((category, index) => (
            <Tr
              key={category.id}
              onClick={() =>
                category.id === selectedCategory
                  ? setSelectedCategory(undefined)
                  : setSelectedCategory(category.id)
              }>
              <Td>
                <i
                  className="fa fa-folder"
                  style={{
                    marginRight: '10px',
                    color:
                      selectedCategory === category.id
                        ? 'rgba(0, 171, 145, 0.8)'
                        : 'rgba(0,0,0,0.3)',
                    fontSize: 20,
                    verticalAlign: 'middle',
                  }}
                />
                {category.name || '-'}
              </Td>
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {userType !== USER_CONTROLLER && (
        <div className="row">
          <div className="col-md-12">
            <Title order={4} mt={20}>
              {translations.global.addCategory}
            </Title>
            <div className="row">
              <div className="col-md-6">
                <Field component={TextField} name="name" label={translations.global.categoryName} />
              </div>
              <div className="col-md-3">
                <Button
                  className={styles.uploadButton}
                  onClick={saveFile}
                  // type="primary"
                  loading={isSaving}>
                  {translations.global.save}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default reduxForm({
  form: FORM_NAME,
  asyncValidate: undefined,
  initialValues: {
    name: '',
  },
})(FilesCategories);
