import round from 'lodash/round';
import moment from 'moment';
import { translationService } from 'shared/translations/translations';
const maxBatteryLevel = 254; // Should be 255?
export const batteryLevelPercentage = batteryLevel => {
  const result = round((100 * batteryLevel) / maxBatteryLevel, 2);
  if (result > 100) {
    return 100;
  }
  if (result < 0) {
    return 0;
  }
  return result;
};

export const rssiToText = rssi => {
  const translations = translationService.getTranslations();
  if (rssi >= -50) {
    return translations.devices.signalHigh;
  }
  if (rssi >= -100 && rssi < -50) {
    return translations.devices.signalMedium;
  }
  if (rssi < -100) {
    return translations.devices.signalLow;
  }
  return rssi;
};

export const batteryLevelVoltage = batteryLevel =>
  round(2.2 + (1.1 * (batteryLevel - 2)) / maxBatteryLevel, 2);

export const batteryLevelToVoltage = level => {
  const minVoltage = 1.8;
  const maxVoltage = 3.0;
  const maxValue = 254;

  return parseFloat((level / maxValue) * (maxVoltage - minVoltage) + minVoltage).toFixed(2);
};

export const batteryLevelToVoltageLiIion = level => {
  const minVoltage = 3.0;
  const maxVoltage = 3.7;
  const maxValue = 254;

  return parseFloat((level / maxValue) * (maxVoltage - minVoltage) + minVoltage).toFixed(2);
};

export const batteryLevelToPercentage = level => {
  const maxValue = 254;

  return parseFloat((level / maxValue) * 100).toFixed(2);
};

export const dateLongerThanOneDay = date => moment(new Date()).diff(date, 'minutes') > 16;

export const dateLongerThanMinutes = (date, minutes = 15) =>
  moment(new Date()).diff(date, 'minutes') > minutes;

function interpolateColor(color1, color2, factor) {
  return [
    Math.round(color1[0] + (color2[0] - color1[0]) * factor),
    Math.round(color1[1] + (color2[1] - color1[1]) * factor),
    Math.round(color1[2] + (color2[2] - color1[2]) * factor),
  ];
}

export function getBatteryProgressBarColor(percentage) {
  const red = [203, 20, 20];
  const yellow = [192, 213, 29];
  const green = [18, 184, 134];

  let color;

  if (percentage <= 33) {
    color = interpolateColor(red, yellow, percentage / 33);
  } else if (percentage <= 66) {
    color = interpolateColor(yellow, green, (percentage - 33) / 33);
  } else {
    color = interpolateColor(green, green, (percentage - 66) / 34); // Utrzymaj kolor zielony po osiągnięciu 66%
  }

  return `rgb(${color[0]},${color[1]},${color[2]})`;
}
