import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Modal, TextInput } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { get } from 'lodash';
import TranslationForm from 'shared/components/TranslationForm';

const getInitialValues = object => {
  return {
    name: object?.name,
    translations: object?.translations
      ? [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ].map((translation, key) => {
          return {
            ...translation,
            name: get(object?.translations, `${translation.lang}.name`),
            description: get(object?.translations, `${translation.lang}.description`),
          };
        })
      : [
          { lang: 'pl', name: undefined },
          { lang: 'en', name: undefined },
          { lang: 'de', name: undefined },
          { lang: 'it', name: undefined },
          { lang: 'ru', name: undefined },
        ],
  };
};

const EditDeviceTypeObjectModal = ({ id, onFinish, close }) => {
  const translations = useTranslations();
  const isOpen = !!id;
  const [isSaving, setSaving] = useState<boolean>();

  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const object = data?.deviceTypeObject;

  const form = useForm({
    initialValues: getInitialValues(object),
  });

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`/device-type-objects/${id}`);
      setData({ deviceTypeObject: data });
      form.setValues(getInitialValues(data));
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, [id, form]);

  useEffect(() => {
    if (id && isOpen) {
      fetchDetails();
    } else {
      form.reset();
      setLoaded(false);
      setSaving(false);
    }
  }, [id, isOpen]);

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        name: formValues.name,
        translations: {
          pl: { name: '' },
          en: { name: '' },
          de: { name: '' },
          it: { name: '' },
          ru: { name: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
      });
      await apiService({}).patch(`/device-type-objects/${id}`, body);
      close();
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.editObjectSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <Modal opened={isOpen} onClose={() => close()} title={translations.deviceTypes.editObjectTitle}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          required
          label={translations.global.name}
          placeholder=""
          {...form.getInputProps('name')}
        />
        <TranslationForm
          fieldsLabel={translations.global.name}
          languageKey="lang"
          translationsKey="translations"
          fieldKey="name"
          form={form}
        />
        <Group position="right" mt="md">
          <Button type="submit" loading={isSaving}>
            {translations.global.save}
          </Button>
          <Button type="button" variant="outline" onClick={() => close()}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditDeviceTypeObjectModal;
