import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslations } from 'shared/translations/useTranslations';
import { map } from 'lodash';

import { Badge, Space, Table } from '@mantine/core';
import styles from './styles.module.scss';
import UnassignActionFromDeviceType from '../UnassignActionFromDeviceType';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {
  isLoading: boolean;
  deviceTypeId: string | number;
  actions?: any[];
  reset: () => void;
}

const Actions = (props: Props) => {
  const translations = useTranslations();
  const { isLoading, actions, reset, deviceTypeId } = props;
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>{translations.global.name}</th>
          <th>{translations.devices.cleanAction}</th>
          <th>{translations.devices.inspection}</th>
          <th>Automatyczna akcja podczas montazu</th>
          <th style={{ width: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {map(actions, action => (
          <tr key={`action-${action.id}`} className={styles.row}>
            <td>{action.name}</td>
            <td>
              {action.shouldClean ? (
                <Badge>{translations.global.yes}</Badge>
              ) : (
                translations.global.no
              )}
            </td>
            <td>
              {action.isInspection ? (
                <Badge>{translations.global.yes}</Badge>
              ) : (
                translations.global.no
              )}
            </td>
            <td>
              {action.settings?.addWhenDeviceAddedToInspection ? (
                <Badge>{translations.global.yes}</Badge>
              ) : (
                translations.global.no
              )}
            </td>
            <td>
              <div className={styles.buttons}>
                <div className="d-flex">
                  {/* <EditDeviceTypeAction id={action.id} action={action} onFinish={reset} /> */}
                  <Space w="xs" />
                  <UnassignActionFromDeviceType
                    id={action?.actionAssociation?.uuid}
                    onFinish={reset}
                    deviceTypeId={deviceTypeId}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>{/* <AddAction deviceTypeId={id} /> */}</tfoot>
    </Table>
  );
};

export default Actions;
