import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const REPORTS_FETCH_PLACES = createRequestTypes(
  'reports/REPORTS_FETCH_PLACES'
);
export const REPORTS_FETCH_REPORT = createRequestTypes(
  'reports/FETCH_REPOORTS'
);

export const fetchPlaces = () => ({
  type: REPORTS_FETCH_PLACES.REQUEST,
  payload: {},
});
export const fetchReport = (payload) => ({
  type: REPORTS_FETCH_REPORT.REQUEST,
  payload,
});

const initState = {
  places: {
    states: loadingStates,
    data: [],
    response: null,
  },

  report: {
    states: loadingStates,
    data: {},
    response: null,
  },
};

export default createReducer(initState, {
  [REPORTS_FETCH_PLACES.REQUEST]: (state) => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REPORTS_FETCH_PLACES.SUCCESS]: (state, action) => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REPORTS_FETCH_PLACES.FAILURE]: (state, action) => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [REPORTS_FETCH_REPORT.REQUEST]: (state) => {
    return update(state, {
      report: {
        $merge: {
          states: setLoadingStates({ isLoading: true }),
        },
      },
    });
  },
  [REPORTS_FETCH_REPORT.SUCCESS]: (state, action) => {
    return update(state, {
      report: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [REPORTS_FETCH_REPORT.FAILURE]: (state, action) => {
    return update(state, {
      report: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
});
