import apiService from 'shared/services/api';

export const deleteDeviceType = id => apiService({}).delete(`/device-types/${id}`);

export const deleteDeviceTypeAction = actionId =>
  apiService({}).delete(`/device-type-actions/${actionId}`);

export const deleteDeviceTypeObservation = observationId =>
  apiService({}).delete(`/api/v3/device-type-observations/${observationId}`);

export const deleteDeviceTypeObject = objectId =>
  apiService({}).delete(`/device-type-objects/${objectId}`);
