import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';

import { useTranslations } from 'shared/translations/useTranslations';
import { Alert, Button, Group, Modal, Notification } from '@mantine/core';

interface Props {
  observationId?: string;
  refreshParent: () => any;
  onClose: () => any;
  placeId: string | number;
  inspectionId: number;
}

const DeleteInspectionObservationModal = (props: Props) => {
  const { observationId, refreshParent, onClose, placeId, inspectionId } = props;
  const translations = useTranslations();

  const [isSaving, setSaving] = useState(false);
  const handleClick = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(
        `/api/places/${placeId}/inspections/${inspectionId}/observations/${observationId}`,
      );
      toast.success('Obserwacja została usunięta');
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!observationId);
  }, [observationId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal opened={opened} onClose={handleClose} title="Usuń obserwację">
      Czy napewno chcesz usunąć tą obserwację?
      <Alert color="yellow" title="Uwaga" style={{ marginTop: 20 }}>
        <strong>Usuwając obserwację:</strong><br />
        należy później manualnie edytować kolejne lub poprzednie obserwację. Tyczy się to również
        zmian wymaganych w innych przeglądach.
      </Alert>
      <Group position="right" mt={20}>
        <Button color="primary" loading={isSaving} type="submit" onClick={handleClick}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteInspectionObservationModal;
