import React from 'react';
import { Route, Switch } from 'react-router';
import { routes } from 'shared/constants/routes';
import { Place } from 'shared/types/Place';
import List from './List';
import ManageIntervention from './Manage';

const InterventionsRoutes = (props: { place: Place }) => {
  return (
    <Switch>
      {props.place && (
        <Route
          path={routes.places.interventions.default.path}
          key="places-interventions"
          component={() => <List place={props.place} />}
          exact
        />
      )}
      <Route
        path={routes.places.interventions.create.path}
        key="places-interventions-create"
        component={ManageIntervention}
        exact
      />
      <Route
        path={routes.places.interventions.edit.path}
        key="places-interventions-manage"
        component={ManageIntervention}
        exact
      />
    </Switch>
  );
};

export default InterventionsRoutes;
