import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import { get } from 'lodash';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  Select,
  Textarea,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';

interface DeviceImageModel {
  id: number;
  url: string;
  signedUrl?: string;
  createdAt: Date;
}

interface Props {
  deviceId?: string | number;
  imageId?: string | number;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  image: DeviceImageModel;
}

const DeleteImageModal = (props: Props) => {
  const { deviceId, imageId, refreshParent, onClose } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { image: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/devices/${deviceId}/images/${imageId}`),
  });

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();

  const handleSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(`/api/v3/devices/${deviceId}/images/${imageId}`);
      toast.success(translations.devices.deleteImageSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!imageId);
    if (imageId) {
      refresh();
    }
  }, [imageId]);

  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.devices.deleteImageTitle}>
      <LoadingOverlay visible={isLoading} />
      {error && (
        <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
          {translations.global.somethingWentWrong}
        </Notification>
      )}
      {!error && isLoaded && (
        <>
          {translations.devices.deleteImageDesc}
          {data?.image?.signedUrl ? <img src={data.image?.signedUrl} /> : 'Brak zdjęcia'}
        </>
      )}
      <Group position="right" mt={20}>
        <Button
          color="primary"
          loading={isSaving}
          type="button"
          onClick={handleSubmit}
          disabled={!!error || !isLoaded}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteImageModal;
