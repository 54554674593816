import React from 'react';
import { TextInput, Title } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import TranslationForm from 'shared/components/TranslationForm';

interface Props {
  form: any;
}

const RecommendationCategoryForm = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();
  return (
    <>
      <div className="col-md-6">
        <TextInput
          label={translations.global.categoryName}
          {...form.getInputProps('name')}
          mb={20}
        />

        <TranslationForm
          formTitle={translations.global.translations}
          fieldsLabel={translations.global.categoryName}
          languageKey="lang"
          translationsKey="translations"
          fieldKey="name"
          form={form}
        />
      </div>
    </>
  );
};

export default RecommendationCategoryForm;
