import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { UserRole } from 'shared/types/User';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import {
  Alert,
  Button,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  Overlay,
  TextInput,
} from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { AlertCircle } from 'tabler-icons-react';

const schema = z.object({
  password: z.string(),
});

interface Props {
  onClose: () => any;
  // refreshParent: () => any;
  opened: boolean;
  userId: string | number;
}

interface Data {
  roles: UserRole[];
}

function generatePassword() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
  const passwordLength = 12;
  let password = '';

  for (let i = 0; i < passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
}

const ChangeUserPasswordModal = (props: Props) => {
  const { onClose, userId, opened } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const form = useForm({
    initialValues: {
      password: generatePassword(),
    },
    schema: zodResolver(schema),
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        password: formValues.password,
      };
      await api({}).patch(`/users/${userId}/update-password`, body);
      toast.success('Hasło zmienione');
      setSaving(false);
      handleClose();
      onClose();
      // refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  useEffect(() => {
    form.setFieldValue('password', generatePassword());
  }, [opened]);

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.global.edit}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          className="ChangePassword__input"
          name="password"
          label={translations.auth.newPassword}
          placeholder={translations.auth.newPassword}
          description="Skopiuj hasło i wyślij użytkownikowi. Powiedz mu, żeby zmienił je po pierwszym zalogowaniu."
          required
          {...form.getInputProps('password')}
        />
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit">
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default ChangeUserPasswordModal;
