import React from 'react';

import { Center } from '@mantine/core';

import styles from './styles.module.scss';

const EnvBanner = () => {
  const env = process.env.REACT_APP_ENV;
  if (!env || env !== 'dev') {
    return null;
  }

  const title = 'Development Env';

  return <Center className={styles.envBanner}>{title}</Center>;
};

export default EnvBanner;
