export const USER = 'USER';
export const COMPANY_ADMIN = 'COMPANY_ADMIN';
export const SUPER_ADMIN = 'ADMIN';
export const USER_CONTROLLER = 'CONTROLLER';

export const USER_TYPE_LABELS = {
  [USER]: 'Technik',
  [COMPANY_ADMIN]: 'Administrator firmy',
  [SUPER_ADMIN]: 'Mousee',
  [USER_CONTROLLER]: 'Kontroler',
};
