import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Group,
  Loader,
  LoadingOverlay,
  Menu,
  Popover,
  ScrollArea,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { isSuperAdminByAuthState } from 'shared/utils/user';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { UserRole } from 'shared/types/User';
import { readableDate } from 'shared/utils/date';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { getCheckedDevices } from '../Place/containers/Manage/Inspections';
import SeeMoreButton from './SeeMoreButton';
import { Dots } from 'tabler-icons-react';

interface Props {
  placeId?: string | number;
  roles: { role: UserRole }[];
}

const DashboardLastInspections = (props: Props) => {
  const { data, isLoading, isLoaded, refresh } = useFetch<{ inspections: any[] }>({
    initialValue: { inspections: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(`/places/${props.placeId}/inspections?limit=10&status=COMPLETED`),
  });
  const form = useForm({
    initialValues: { placeId: undefined, companyId: undefined },
  });

  useEffect(() => {
    if (props.placeId) {
      refresh();
    }
  }, [props.placeId]);

  const [showComment, setShowComment] = useState(false);
  const translations = useTranslations();
  const { isSuperAdmin } = useSelector(state => ({
    isSuperAdmin: isSuperAdminByAuthState(state.auth),
  }));

  const [isGenerating, setGenerating] = useState<number | undefined>(undefined);
  const [isUnapproving, setUnapproving] = useState<number | undefined>(undefined);
  const [isApproving, setApproving] = useState<number | undefined>(undefined);
  const [isDownloading, setDownloading] = useState<number | undefined>(undefined);
  const [showCancelReportPopover, setShowCancelReportPopover] = useState();
  const [showApproveReportPopover, setShowApproveReportPopover] = useState();
  const onGeneratePdf = inspection => async e => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setGenerating(inspection.id);
      const {
        data: { url },
      } = await apiService({
        timeout: 120000,
        baseUrl: process.env.REACT_APP_BACKEND_PDF_URL,
      }).post(`/reports/pdf/inspection/${inspection.id}`, {
        placeId: props.placeId,
      });
      refresh();
      // await fetchData(setData, props);
    } catch {
    } finally {
      setGenerating(undefined);
    }
  };

  const onDownloadPdf = (inspection, report) => async () => {
    try {
      setDownloading(report.id);
      const {
        data: { url },
      } = await apiService({}).get(
        `/places/${props.placeId}/inspections/${inspection.id}/reports/${report.id}/signed-url`,
      );

      await saveFileFromUrl(
        url,
        `${get(inspection, 'name')}_${get(inspection, 'place.name')}_${readableDate(
          inspection.createdAt,
        )}`.toLowerCase(),
      );
    } catch {
    } finally {
      setDownloading(undefined);
    }
  };

  const unApproveInspection = inspection => async () => {
    try {
      setUnapproving(inspection.id);
      const {
        data: { url },
      } = await apiService({}).post(
        `/api/places/${inspection.placeId}/inspections/${inspection.id}/unapprove`,
        {
          placeId: props.placeId,
        },
      );
      setShowCancelReportPopover(false);
      await refresh();
    } catch {
    } finally {
      setUnapproving(undefined);
    }
  };

  const approveInspection = inspection => async () => {
    try {
      setApproving(inspection.id);
      const {
        data: { url },
      } = await apiService({}).post(
        `/api/places/${inspection.placeId}/inspections/${inspection.id}/approve`,
        {
          placeId: props.placeId,
        },
      );
      setShowApproveReportPopover(false);
      await refresh();
    } catch (e) {
    } finally {
      setApproving(undefined);
    }
  };

  const canApproveInspections =
    isSuperAdmin || props.roles?.find(r => r.role === UserRole.APPROVE_INSPECTION);

  return (
    <Card
      shadow="sm"
      p="lg"
      mb={20}
      style={{ maxHeight: '400px', height: '400px', overflow: 'unset' }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.global.lastInspections}</Title>
        {props.placeId && (
          <SeeMoreButton to={routes.places.manage.to(props.placeId, '?tab=inspections')} />
        )}
      </Group>
      <ScrollArea type="hover" style={{ height: '300px' }}>
        <Table>
          <thead>
            <tr>
              <th>{translations.global.name}</th>
              <th>{translations.inspections.startedAt}</th>
              <th>{translations.inspections.finishedAt}</th>
              <th>{translations.global.user}</th>
              <th>{translations.inspections.checkedDevices}</th>
              <th>{translations.global.recommendations}</th>
              <th>{translations.inspections.approved}</th>
              <th>{translations.global.report}</th>
            </tr>
          </thead>
          <tbody>
            {data?.inspections?.map(inspection => (
              <tr key={inspection.id}>
                <td>{inspection.name}</td>
                <td>{readableDate(inspection.startedAt)}</td>
                <td>{readableDate(inspection.createdAt)}</td>
                <td>
                  {get(inspection, 'user.name')} {get(inspection, 'user.surname')}
                </td>
                <td>
                  {getCheckedDevices(get(inspection, 'devices'))} /{' '}
                  {get(inspection, 'devices.length')}
                </td>
                <td>{get(inspection, 'recommendations.length')}</td>
                <td>
                  {get(inspection, 'approved') ? (
                    <Tooltip
                      label={`${inspection.approvedBy?.name} ${
                        inspection.approvedBy?.surname
                      }, ${readableDate(inspection.approvedAt)}`}>
                      <Badge color="green">{translations.global.yes}</Badge>
                    </Tooltip>
                  ) : (
                    <Badge color="red">{translations.global.no}</Badge>
                  )}
                </td>
                <td>
                  {isDownloading !== undefined &&
                    isDownloading === get(inspection, 'reports.0.id') && <Loader size="xs" />}
                  {inspection.id && isGenerating === inspection.id && <Loader size="xs" />}
                  <Popover
                    opened={showApproveReportPopover === inspection.id}
                    onClose={() => setShowApproveReportPopover(false)}
                    width={260}
                    position="bottom"
                    withArrow>
                    <Popover.Target>
                      <div />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Title order={5}>{translations.inspections.confirmApproval}</Title>
                      <Text size="sm" mb={10}>
                        {translations.inspections.confirmApprovalDesc}
                      </Text>
                      <Group>
                        <Button
                          size="xs"
                          onClick={approveInspection(inspection)}
                          loading={isApproving === inspection.id}
                          disabled={isApproving === inspection.id}
                          color="green">
                          {translations.global.confirm}
                        </Button>
                        <Button
                          size="xs"
                          onClick={() => setShowApproveReportPopover(undefined)}
                          variant="outline">
                          {translations.global.cancel}
                        </Button>
                      </Group>
                    </Popover.Dropdown>
                  </Popover>
                  <Popover
                    opened={showCancelReportPopover === inspection.id}
                    onClose={() => setShowCancelReportPopover(false)}
                    width={260}
                    position="bottom"
                    withArrow>
                    <Popover.Target>
                      <div />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Title order={5}>{translations.inspections.confirmUnapproval}</Title>
                      <Text size="sm" mb={10}>
                        {translations.inspections.confirmUnapprovalDesc}
                      </Text>
                      <Group>
                        <Button
                          size="xs"
                          onClick={unApproveInspection(inspection)}
                          loading={isUnapproving === inspection.id}
                          disabled={isUnapproving === inspection.id}
                          color="red">
                          {translations.global.confirm}
                        </Button>
                        <Button
                          size="xs"
                          onClick={() => setShowCancelReportPopover(undefined)}
                          variant="outline">
                          {translations.global.cancel}
                        </Button>
                      </Group>
                    </Popover.Dropdown>
                  </Popover>
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <Button size="xs" variant="light">
                        <Dots />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {get(inspection, 'reports.0.url') ? (
                        <Menu.Item
                          onClick={onDownloadPdf(inspection, get(inspection, 'reports.0'))}
                          disabled={!!isDownloading}>
                          {isDownloading !== undefined &&
                            isDownloading === get(inspection, 'reports.0.id') && (
                              <Loader size="xs" />
                            )}
                          {translations.global.download}
                        </Menu.Item>
                      ) : (
                        <Menu.Item onClick={onGeneratePdf(inspection)} disabled={!!isGenerating}>
                          {isGenerating === inspection.id && <Loader size="xs" />}{' '}
                          {translations.global.generate}
                        </Menu.Item>
                      )}
                      {!get(inspection, 'approved') && canApproveInspections && (
                        <Menu.Item
                          color="green"
                          onClick={e => {
                            setShowApproveReportPopover(o => (o ? undefined : inspection.id));
                          }}>
                          {translations.inspections.approveInspection}
                        </Menu.Item>
                      )}
                      {get(inspection, 'approved') && canApproveInspections && (
                        <Menu.Item
                          color="red"
                          onClick={() =>
                            setShowCancelReportPopover(o => (o ? undefined : inspection.id))
                          }>
                          {translations.inspections.unapproveInspection}
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                  <Group></Group>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
};

export default DashboardLastInspections;
