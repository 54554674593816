import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  NumberInput,
  Select,
  Textarea,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';
import { DeviceTypeObject } from '../../../../shared/types/deviceType';

interface DeviceObjectModel {
  id: number;
  amount: number;
  createdAt: Date;
  deviceTypeObjectId: string | number;
  deviceTypeObject?: {
    name: string;
    id: number;
  };
  note?: string;
}

interface Props {
  deviceId?: string | number;
  objectId?: string | number;
  deviceTypeId?: string | number;
  refreshParent: () => any;
  onClose: () => any;
  possibleObjects?: DeviceTypeObject[];
}

interface Data {
  object: DeviceObjectModel;
}
interface ObjectsData {
  objects: { name: string; id: string; deviceTypeId: string }[];
}
const EditObjectModal = (props: Props) => {
  const { deviceId, objectId, refreshParent, onClose, deviceTypeId } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { object: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/devices/${deviceId}/objects/${objectId}`),
  });

  const form = useForm({
    initialValues: {
      note: undefined,
      deviceTypeObjectId: undefined,
      amount: undefined,
    },

    validate: {},
  });
  useEffect(() => {
    form.setValues({
      deviceTypeObjectId: String(data.object?.deviceTypeObjectId),
      note: data?.object?.note,
      amount: data?.object?.amount,
    });
  }, [data?.object]);

  const [isSaving, setSaving] = useState(false);
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        amount: formValues.amount,
        note: formValues.note,
        deviceTypeObjectId: formValues.deviceTypeObjectId,
      };

      await api({}).patch(`/api/v3/devices/${deviceId}/objects/${objectId}`, body);
      toast.success(translations.devices.editObjectSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!objectId);
    if (objectId) {
      refresh();
    }
    if (!objectId) {
      form.reset();
    }
  }, [objectId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  const objectsSelectOptions = props.possibleObjects?.map(object => ({
    label: object.name,
    value: String(object.id),
  }));
  return (
    <Modal opened={opened} onClose={handleClose} title={translations.devices.editObjectTitle}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60}>
            {translations.global.somethingWentWrong}
          </Notification>
        )}
        {!error && isLoaded && (
          <>
            <Select
              label={translations.devices.object}
              name="deviceTypeObjectId"
              searchable
              data={objectsSelectOptions}
              required
              mb={20}
              {...form.getInputProps('deviceTypeObjectId')}
            />
            <Textarea
              label={translations.devices.note}
              name="note"
              mb={20}
              {...form.getInputProps('note')}
            />
            <NumberInput
              label={translations.devices.amount}
              placeholder="1"
              min={0}
              required
              name="amount"
              mb={20}
              {...form.getInputProps('amount')}
            />
          </>
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!!error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditObjectModal;
