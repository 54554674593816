import React, { useEffect, useMemo, useState } from 'react';
import { apiService } from 'shared/services/api';
import { toast } from 'react-toastify';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import {
  Anchor,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  Select,
  Textarea,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';
import {
  countDaysInRange,
  getCurrentMonthRange,
  getCurrentYearRange,
} from '../../../../shared/utils/date';
import { DatePickerInput } from '@mantine/dates';

interface Props {
  refreshParent: () => any;
  onClose: () => any;
  open: boolean;
  defaultPlaceUuid?: string;
}

const GenerateReportV2 = (props: Props) => {
  const { refreshParent, onClose, open } = props;
  const [error, setError] = useState();

  const translations = useTranslations();
  const { from, to } = getCurrentMonthRange();

  const {
    data: placesData,
    isLoading: isLoadingPlaces,
    isLoaded: isPlacesLoaded,
  } = useFetch<any[]>({
    initialValue: [],
    shouldNotFetchAtStart: false,
    fetchAction: () => apiService({}).get(`/places`),
  });

  const form = useForm({
    initialValues: {
      note: '',
      dateRange: [from, to],
      interval: 'DAY',
      placeUuid: props.defaultPlaceUuid,
      type: 'XLSX',
    },

    validate: {},
  });

  const {
    data: deviceTypesData,
    isLoading: isLoadingDeviceTypes,
    isLoaded: isLoadedDeviceTypes,
    refresh: refreshDeviceTypes,
  } = useFetch<any>({
    initialValue: [],
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/device-types?placeUuid=${form.values.placeUuid}`),
  });

  useEffect(() => {
    if (form.values.placeUuid) {
      refreshDeviceTypes();
    }
  }, [form.values.placeUuid]);

  useEffect(() => {
    if (open) {
      form.reset();
      form.setFieldValue('placeUuid', props.defaultPlaceUuid);
    }
  }, [open]);

  const [isSaving, setSaving] = useState(false);
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    setError(undefined);
    try {
      await apiService({
        timeout: 240000,
        baseUrl: process.env.REACT_APP_BACKEND_STATISTICS_URL,
      }).post(`/api/place/${formValues.placeUuid}/statistics/xlsx`, {
        from: formValues.dateRange[0],
        to: formValues.dateRange[1],
        interval: formValues.interval,
        type: formValues.type,
        note: formValues.note,
        deviceTypeId: formValues.deviceTypeId,
      });
      toast.success(translations.reports.reportWasGenerated);
      setSaving(false);
      onClose();
      refreshParent();
    } catch (e) {
      const code = e.response?.data?.code;
      const possibleErrors = {
        'reports/no-data': translations.reports.xlsxModal.noDataInCurrentRange,
      };
      const errorText = possibleErrors[code] || translations.global.somethingWentWrong;
      setError(errorText);
      toast.error(errorText);
    } finally {
      setSaving(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const intervalOptions = useMemo(() => {
    const defaultValues = [
      { value: 'DAY', label: 'Dzień', disabled: false },
      { value: 'WEEK', label: 'Tydzień', disabled: false },
      { value: 'MONTH', label: 'Miesiąc', disabled: false },
      { value: 'YEAR', label: 'Rok', disabled: false },
    ];
    const calculatedDays = countDaysInRange(form.values.dateRange[0], form.values.dateRange[1]);
    return defaultValues.map(option => {
      if (calculatedDays > 35) {
        if (['MONTH', 'YEAR'].includes(option.value)) {
          return option;
        } else {
          return { ...option, disabled: true };
        }
      }
      return option;
    });
  }, [form.values]);

  useEffect(() => {
    const hasDisabledMethod = intervalOptions.find(
      option => option.value === form.values.interval && option.disabled === true,
    );
    if (hasDisabledMethod) {
      const firstOption = intervalOptions.find(option => option.disabled === false);
      form.setFieldValue('interval', firstOption.value);
    }
  }, [form.values]);

  return (
    <Modal opened={open} onClose={handleClose} title={translations.reports.xlsxModal.title}>
      <LoadingOverlay visible={isLoadingPlaces || isLoadingDeviceTypes} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <>
          <Select
            label={translations.global.facility}
            name="placeUuid"
            defaultValue={props.defaultPlaceUuid}
            data={placesData?.map(place => ({ value: place.uuid, label: place.name }))}
            required
            mb={20}
            searchable
            {...form.getInputProps('placeUuid')}
          />
          <Select
            label={translations.global.deviceType}
            name="deviceTypeId"
            defaultValue={props.defaultPlaceUuid}
            clearable
            data={deviceTypesData.map(dt => ({ value: dt.id, label: dt.name }))}
            mb={20}
            searchable
            {...form.getInputProps('deviceTypeId')}
          />
          <DatePickerInput
            name="dateRange"
            type="range"
            label={translations.global.dateRange}
            clearable
            mb={20}
            {...form.getInputProps('dateRange')}
            description={
              <div>
                {translations.reports.xlsxModal.select}:
                <Anchor
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const { from, to } = getCurrentYearRange();
                    form.setFieldValue('dateRange', [from, to]);
                  }}>
                  {translations.reports.xlsxModal.currentYear}
                </Anchor>
                {` / `}
                <Anchor
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const { from, to } = getCurrentMonthRange();
                    form.setFieldValue('dateRange', [from, to]);
                  }}>
                  {translations.reports.xlsxModal.currentMonth}
                </Anchor>
              </div>
            }
          />
          <Select
            label={translations.global.interval}
            name="interval"
            data={intervalOptions}
            required
            mb={20}
            {...form.getInputProps('interval')}
          />
          <Select
            label={translations.global.type}
            name="type"
            data={[{ value: 'XLSX', label: 'Excel' }]}
            required
            mb={20}
            {...form.getInputProps('type')}
          />

          <Textarea
            label={translations.devices.note}
            name="note"
            mb={20}
            {...form.getInputProps('note')}
          />
        </>

        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!isPlacesLoaded}>
            {translations.global.generate}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={10} mb={10}>
            {error}
          </Notification>
        )}
      </form>
    </Modal>
  );
};

export default GenerateReportV2;
