import React from 'react';
import { Card, Grid } from '@mantine/core';

import { Link } from 'react-router-dom';
import { ExternalLink } from 'tabler-icons-react';

interface NumberCardProps {
  icon?: React.ReactNode;
  color?: string;
  title: string;
  amount: number;
  externalLink?: string;
  backgroundColor?: string;
  amountText?: string;
}

const NumberCard = ({
  icon,
  color,
  title,
  backgroundColor,
  amount,
  amountText = null,
  externalLink,
}: NumberCardProps) => {
  return (
    <Grid.Col span={3}>
      <Card
        shadow="sm"
        p="lg"
        style={{
          overflow: 'unset',
          color: color,
          backgroundColor,
        }}>
        <div style={{ fontSize: 50, fontWeight: 800 }}>
          {amount}
          {amountText ? (
            <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>{amountText}</span>
          ) : null}
        </div>
        {externalLink && (
          <Link to={externalLink} style={{ position: 'absolute', top: 20, right: 20 }}>
            <ExternalLink style={{ color }} />
          </Link>
        )}
        {icon ? icon : null} {title}
      </Card>
    </Grid.Col>
  );
};

export default NumberCard;
