import update from 'immutability-helper';

import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const FETCH_DEVICE = createRequestTypes('devices/device/FETCH_DEVICE');
export const FETCH_PLACES = createRequestTypes('devices/device/FETCH_PLACES');
export const EDIT_DEVICE = createRequestTypes('devices/device/EDIT_DEVICE');
export const DELETE_DEVICE = createRequestTypes('devices/device/DELETE_DEVICE');
export const CLEAN_DETECTION_IN_DEVICE = createRequestTypes(
  'devices/device/CLEAN_DETECTION_IN_DEVICE'
);

export const cleanDetect = ({ deviceId }) => ({
  type: CLEAN_DETECTION_IN_DEVICE.REQUEST,
  payload: { deviceId }
});

export const deleteDevice = ({ id }) => ({
  type: DELETE_DEVICE.REQUEST,
  payload: { id }
});
export const editDevice = payload => ({
  type: EDIT_DEVICE.REQUEST,
  payload: payload
});
export const fetchDevice = ({ id }) => ({
  type: FETCH_DEVICE.REQUEST,
  payload: { id }
});
export const fetchPlaces = () => ({
  type: FETCH_PLACES.REQUEST,
  payload: {}
});

const initState = {
  places: {
    states: loadingStates,
    data: {},
    response: null
  },
  details: {
    states: loadingStates,
    data: {},
    response: null
  },
  edit: {
    states: loadingStates,
    data: {},
    response: null
  },
  delete: {
    states: loadingStates,
    data: {},
    response: null
  }
};

export default createReducer(initState, {
  [FETCH_PLACES.REQUEST]: state => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [FETCH_PLACES.SUCCESS]: (state, action) => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [FETCH_PLACES.FAILURE]: (state, action) => {
    return update(state, {
      places: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [FETCH_DEVICE.REQUEST]: state => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [FETCH_DEVICE.SUCCESS]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [FETCH_DEVICE.FAILURE]: (state, action) => {
    return update(state, {
      details: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [EDIT_DEVICE.REQUEST]: state => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [EDIT_DEVICE.SUCCESS]: (state, action) => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [EDIT_DEVICE.FAILURE]: (state, action) => {
    return update(state, {
      edit: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  },
  [DELETE_DEVICE.REQUEST]: state => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoading: true })
        }
      }
    });
  },
  [DELETE_DEVICE.SUCCESS]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ isLoaded: true }),
          data: action.payload
        }
      }
    });
  },
  [DELETE_DEVICE.FAILURE]: (state, action) => {
    return update(state, {
      delete: {
        $merge: {
          states: setLoadingStates({ hasError: true }),
          response: action.payload.response
        }
      }
    });
  }
});
