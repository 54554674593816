import { useSelector } from 'react-redux';
import { UserType } from '../types/User';

interface CurrentUser {
  name: string;
  surname: string;
  email: string;
  companyId?: number;
  type: UserType;
  language: string;
  timezone: number;
}

const useCurrentUser = () => {
  const currentUser: CurrentUser | undefined = useSelector(state => state.auth.data?.user?.data);
  return currentUser;
};
export default useCurrentUser;
