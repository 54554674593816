import { DependencyList, useCallback, useState } from 'react';
import apiService from 'shared/services/api';

interface Props<T> {
  postEndpoint?: string;
  patchEndpoint?: string;
  isPatch?: boolean;
  additionalDeps?: DependencyList;
  onSuccessCallback?: (data: T) => void;
  onErrorCallback?: () => void;
}

const useSave = <T>(props: Props<T>) => {
  const {
    postEndpoint,
    patchEndpoint,
    isPatch,
    additionalDeps,
    onSuccessCallback,
    onErrorCallback,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>(null);

  const save = useCallback(
    async <K>(body: K) => {
      try {
        setData(null);
        setError(false);
        setLoading(true);
        const { data: newData } = isPatch
          ? await apiService({}).patch<T>(patchEndpoint, body)
          : await apiService({}).post<T>(postEndpoint, body);
        setLoading(false);
        setLoaded(true);
        setData(newData);
        if (onSuccessCallback) {
          onSuccessCallback(newData);
        }
      } catch {
        setLoading(false);
        setError(true);
        setLoaded(false);
        setData(null);
        if (onErrorCallback) {
          onErrorCallback();
        }
      }
    },
    [
      postEndpoint,
      patchEndpoint,
      isPatch,
      onSuccessCallback,
      onErrorCallback,
      ...(additionalDeps || []),
    ],
  );

  return {
    isLoading,
    isLoaded,
    error,
    save,
    data,
  };
};

export default useSave;
