import React, { useState } from 'react';
import { Tabs } from '@mantine/core';
import api from 'shared/services/api';
import useFetch from 'shared/hooks/useFetch';

import { useParams } from 'react-router';

import { Recommendation } from 'shared/types/recommendation';
import ListMapDetails from './ListMapDetails';

interface Props {
  recommendations: Recommendation[];
}

const ListMap = (props: Props) => {
  const { recommendations } = props;
  const { placeId } = useParams<{ placeId: string }>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { recommendations: [] },
    fetchAction: () => api({}).get(`/places/${placeId}/maps`),
  });

  return (
    <div style={{ width: '100%', maxWidth: '100%' }}>
      {data?.maps?.length > 0 && (
        <Tabs defaultValue={'0'}>
          <Tabs.List mb={20}>
            {data?.maps?.map((m, i) => (
              <Tabs.Tab key={m.id} value={`${i}`}>
                {m?.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {data?.maps?.map((m, i) => (
            <Tabs.Panel key={m.id} value={`${i}`}>
              <ListMapDetails recommendations={recommendations} currentMap={m} />
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </div>
  );
};
export default ListMap;
