import { Button, Group, Tabs } from '@mantine/core';
import cn from 'classnames';
import { latLngBounds } from 'leaflet';
import { flatMap, get, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';

import AppContent from 'shared/components/AppContent';
import ListHeading from 'shared/components/ListHeading';
import RotateLoader from 'shared/components/RotateLoader';
import { USER_CONTROLLER } from 'shared/constants/user';
import useFetch from 'shared/hooks/useFetch';
import api, { apiService } from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';

import { readableDate } from '../../../../shared/utils/date';
import { saveFileFromUrl } from '../../../../shared/utils/saveFile';
import EditMapModal from '../Maps/EditMapModal';
import DeleteMap from './DeleteMap';
import DraggableDevice from './DraggableDevice';
import Map from './Map';
import styles from './styles.module.scss';
import UnsetDeviceFromMap from './UnsetDeviceFromMap';
import UploadMap from './UploadMap';

const FORM_NAME = 'place/FILES';
interface Props {
  placeId: string | number;
  devices: {
    description: string;
    detect: boolean;
    id: number;
    mapId: string;
    mapX: string;
    mapY: string;
    name: string;
  }[];
}
export type Device = {
  description: string;
  detect: boolean;
  id: number;
  mapId: string;
  mapX: string;
  mapY: string;
  name: string;
  deviceTypeId: number;
};
type DevicesState = Device[];

interface PlaceMap {
  id: number;
  url: string;
  name: string;
  devices: string[];
}
interface State {
  maps: PlaceMap[];
}

const PlaceMap = (props: Props) => {
  const translations = useTranslations();
  const userType = useSelector(state => state.auth.data.userType);
  const [map, setMap] = useState(null);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number | undefined>();
  const [deleteDeviceFromMap, setDeleteDeviceFromMap] = useState<undefined | Device>();
  const [deleteMap, setDeleteMap] = useState<undefined | number>();
  const [editMap, setEditMap] = useState<undefined | number>();

  const [uploadModal, setUploadModal] = useState(false);
  const [currentMap, setCurrentMap] = useState(undefined);

  const [bounds, setBounds] = useState(
    latLngBounds([
      [0, 0],
      [0, 0],
    ]),
  );

  const {
    isLoading: isLoadingDevices,
    isLoaded: isLoadedDevices,
    data: devicesData,
    error: devicesError,
    refresh: fetchDevices,
  } = useFetch<{ devices: Device[] }>({
    fetchAction: () => apiService({}).get(`/places/${props.placeId}/devices`),
    fetchActionDeps: [props.placeId],
    initialValue: { devices: [] },
    shouldNotFetchAtStart: true,
  });

  const devices = devicesData.devices;

  const {
    isLoading: isLoadingMaps,
    isLoaded: isLoadedMaps,
    data,
    error: mapsError,
    refresh: fetchMaps,
  } = useFetch<{ maps: PlaceMap[] }>({
    fetchAction: () => apiService({}).get(`/places/${props.placeId}/maps`),
    fetchActionDeps: [props.placeId],
    initialValue: { maps: [] },
    shouldNotFetchAtStart: true,
  });

  const onRefetch = useCallback(async () => {
    try {
      fetchDevices();
      fetchMaps();
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    }
  }, [props, fetchDevices, fetchMaps]);

  useEffect(() => {
    onRefetch();
  }, []);

  useEffect(() => {
    if (data.maps.length > 0 && !currentMap) {
      setCurrentMap(data.maps[0]);
    } else if (data.maps.length > 0 && currentMap) {
      const newMap = data.maps.find(map => map.id === currentMap.id);
      setCurrentMap(newMap);
    }
  }, [data.maps, currentMap]);

  const onSelectMap = placeMap => async () => {
    setCurrentMap(placeMap);
    onRefetch();
  };

  const devicesWithoutMap = useMemo(
    () =>
      devices?.filter(
        device =>
          !device.mapId &&
          (!selectedDeviceType ? true : device.deviceTypeId === selectedDeviceType),
      ) || [],
    [devices, selectedDeviceType],
  );

  // const devicesInWrongMap = devices?.filter(device =>
  //   !data?.maps.map(m => m.id)?.includes(device.mapId),
  // );
  const deviceTypesArray = flatMap(currentMap?.devices, device => device.deviceType);
  const deviceTypes = uniqBy(deviceTypesArray, 'id') || [];

  const saveDroppedDevicePosition = useCallback(
    async (values: { mapId: number; deviceId: number; lat: number; lng: number }) => {
      try {
        const savedDeviceResponse = await api({}).post(
          `places/${props.placeId}/devices/${values.deviceId}/set-on-map`,
          {
            mapX: values.lng,
            mapY: values.lat,
            mapId: values.mapId,
          },
        );
        const { device: savedDevice } = savedDeviceResponse.data;
        toast.success(translations.map.addedToMap);
        onRefetch();
      } catch (e) {
        toast.error(translations.global.somethingWentWrong);
      }
    },
    [currentMap, devices],
  );

  const mapContainerRef = useRef<HTMLDivElement>();
  const [droppedDevice, setDroppedDevice] = useState<{
    device: Device;
    point: { x: number; y: number };
  }>();

  const onDrop = (item: { device: Device }, point) => {
    setDroppedDevice({ device: item.device, point });
  };

  const [_, drop] = useDrop(() => ({
    accept: 'DEVICE',
    drop(item: { device: Device }, monitor) {
      const offset = monitor.getClientOffset();
      if (offset && mapContainerRef.current) {
        const dropTargetXy = mapContainerRef.current.getBoundingClientRect();
        onDrop(item, {
          x: offset.x - dropTargetXy.left,
          y: offset.y - dropTargetXy.top,
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const onSetDroppedDeviceMarker = useCallback(
    async (droppedDevice: { mapId: number; device: Device; lat: number; lng: number }) => {
      await saveDroppedDevicePosition({
        mapId: droppedDevice.mapId,
        deviceId: droppedDevice.device.id,
        lat: droppedDevice.lat,
        lng: droppedDevice.lng,
      });
      setDroppedDevice(undefined);
    },
    [currentMap, devices],
  );

  const isLoading = isLoadingDevices || isLoadingMaps;
  const isLoaded = isLoadedDevices || isLoadedMaps;
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const onDownloadPdf = (type: string) => async () => {
    try {
      setIsDownloadingPdf(true);
      const {
        data: { pdf, jpg },
      } = await apiService({
        baseUrl: process.env.REACT_APP_BACKEND_PDF_URL,
        timeout: 5 * 60 * 1000, // NOTE: 5 minutes
      }).get(`/places/${props.placeId}/maps/${currentMap.id}/image`);
      await saveFileFromUrl(
        type === 'pdf' ? pdf : jpg,
        `${get(currentMap, 'name')}_${readableDate(new Date())}`.toLowerCase(),
      );
    } catch {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  return (
    <div>
      <ListHeading title={translations.map.facilityPlan}></ListHeading>
      <AppContent>
        <div style={{ padding: 10, paddingLeft: 5, paddingRight: 0 }}>
          <UploadMap
            open={uploadModal}
            close={(shouldRefetch: boolean) => {
              setUploadModal(false);
              if (shouldRefetch) {
                onRefetch();
              }
            }}
            placeId={props.placeId}
          />
          <UnsetDeviceFromMap
            open={!!deleteDeviceFromMap}
            close={(shouldRefetch: boolean) => {
              setDeleteDeviceFromMap(undefined);
              if (shouldRefetch) {
                onRefetch();
              }
            }}
            placeId={props.placeId}
            deviceId={deleteDeviceFromMap?.id}
          />
          <DeleteMap
            open={!!deleteMap}
            close={(shouldRefetch: boolean) => {
              setDeleteMap(undefined);
              if (shouldRefetch) {
                onRefetch();
              }
            }}
            placeId={props.placeId}
            mapId={deleteMap}
          />
          <EditMapModal
            close={(shouldRefetch: boolean) => {
              setEditMap();
            }}
            placeId={props.placeId}
            mapId={editMap?.id}
            onFinish={() => {
              onRefetch();
            }}
          />
          {isLoading && !isLoaded && (
            <div className="ContentLoader__loader">
              <RotateLoader relative />
            </div>
          )}
          {isLoaded && (
            <div className="row">
              <div className="col-md-3">
                <div className={styles.leftWrapper}>
                  <div>
                    {data.maps.map(placeMap => (
                      <div
                        key={`list-${placeMap.id}`}
                        onClick={onSelectMap(placeMap)}
                        className={cn(
                          styles.mapOption,
                          currentMap?.id === placeMap.id ? styles.mapOptionActive : '',
                        )}>
                        {placeMap.name} ({placeMap.devices?.length})
                        {userType !== USER_CONTROLLER && (
                          <div>
                            <button
                              className={styles.delete}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEditMap(placeMap);
                              }}>
                              <i className="fa fa-pencil"></i>
                            </button>
                            <button
                              className={styles.delete}
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDeleteMap(placeMap.id);
                              }}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                    {userType !== USER_CONTROLLER && (
                      <div
                        onClick={() => setUploadModal(true)}
                        className={cn(styles.mapOption, styles.mapOptionAdd)}>
                        {translations.map.addMap} +
                      </div>
                    )}
                  </div>
                  <Tabs defaultValue="0">
                    <Tabs.List className={styles.tabsList}>
                      <Tabs.Tab value="0">{translations.map.devicesOnMap}</Tabs.Tab>
                      <Tabs.Tab value="1">{translations.map.withoutCoordinations}</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="0">
                      <div className={styles.leftScroll}>
                        {currentMap?.devices
                          ?.filter(device =>
                            !selectedDeviceType ? true : device.deviceTypeId === selectedDeviceType,
                          )
                          .map(device => (
                            <div
                              key={`device-${device.id}`}
                              onClick={() => {
                                map.setView({ lng: device.mapX, lat: device.mapY });
                              }}
                              className={cn(
                                styles.device,
                                // currentMap?.id === placeMap.id ? styles.mapOptionActive : '',
                              )}>
                              <div>
                                {device.uniqueId} <br />
                                <span className={styles.deviceDesc}>{device.description}</span>
                              </div>
                              {userType !== USER_CONTROLLER && (
                                <button
                                  className={styles.delete}
                                  onClick={() => setDeleteDeviceFromMap(device)}>
                                  <i className="fa fa-trash"></i>
                                </button>
                              )}
                            </div>
                          ))}
                      </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="1">
                      <div className={styles.leftScroll}>
                        {devicesWithoutMap?.map(device => (
                          <DraggableDevice key={`device-${device.id}`} device={device}>
                            <div
                              onClick={() => {
                                map.setView({ lng: device.mapX, lat: device.mapY });
                              }}
                              className={cn(
                                styles.device,
                                // currentMap?.id === placeMap.id ? styles.mapOptionActive : '',
                              )}>
                              <div>
                                {device.uniqueId} <br />
                                <span className={styles.deviceDesc}>{device.description}</span>
                              </div>
                            </div>
                          </DraggableDevice>
                        ))}
                      </div>
                    </Tabs.Panel>
                  </Tabs>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    {translations.global.devicesCount}: <strong>{devices?.length}</strong>
                    <br />
                    {translations.map.withoutCoordinations}:{' '}
                    <strong>{devicesWithoutMap?.length}</strong>
                    <br /> <br />
                    {/* Ilosc urządzeń na nie istniejącej mapie: <strong>{devicesInWrongMap.length}</strong>
                <br /> <br /> */}
                    {translations.map.deviceLegends}:
                    <br />
                    <br />
                    {deviceTypes?.map(deviceType => (
                      <div
                        key={deviceType.id}
                        className={`${styles.deviceType} ${
                          selectedDeviceType === deviceType.id ? styles.deviceTypeSelected : ''
                        }`}
                        onClick={() => {
                          if (selectedDeviceType === deviceType.id) {
                            setSelectedDeviceType();
                            return;
                          }
                          setSelectedDeviceType(deviceType.id);
                        }}>
                        <div
                          style={{ backgroundColor: deviceType.color }}
                          className={styles.deviceTypeColor}
                        />
                        <div className={styles.deviceTypeName}>
                          {deviceType.name} (
                          {deviceTypesArray?.filter(dt => dt.id === deviceType.id)?.length})
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-6">
                    {translations.map.mouseeLegend}:
                    <div className={`${styles.legend}`}>
                      <div className={`${styles.legendIcon}`}>
                        <div className={`${styles.iconWrapper}`}>
                          <div className={`${styles.icon} ${styles.iconRed}`}>ID</div>
                        </div>
                        <div className={`${styles.legendIconText}`}>
                          {translations.devices.alert}
                        </div>
                      </div>
                      <div className={`${styles.legendIcon}`}>
                        <div className={`${styles.iconWrapper}`}>
                          <div className={`${styles.icon} ${styles.iconGreen}`}>ID</div>
                        </div>
                        <div className={`${styles.legendIconText}`}>
                          {translations.devices.online}
                        </div>
                      </div>
                      <div className={`${styles.legendIcon}`}>
                        <div className={`${styles.iconWrapper}`}>
                          <div className={`${styles.icon} ${styles.iconYellow}`}>ID</div>
                        </div>
                        <div className={`${styles.legendIconText}`}>
                          {translations.devices.noSignal}
                        </div>
                      </div>
                      <div className={`${styles.legendIcon}`}>
                        <div className={`${styles.iconWrapper}`}>
                          <div className={`${styles.icon} ${styles.iconYellowRed}`}>ID</div>
                        </div>
                        <div className={`${styles.legendIconText}`}>
                          {translations.devices.noSignal}
                          <br />
                          {translations.devices.alert}
                        </div>
                      </div>
                      <div className={`${styles.legendIcon}`}>
                        <div className={`${styles.iconWrapper}`}>
                          <div className={`${styles.icon} ${styles.iconNormal}`}>ID</div>
                        </div>
                        <div className={`${styles.legendIconText}`}>Normalne urządzenie</div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <Group mb={15}>
                  <Button
                    variant="outline"
                    size="xs"
                    disabled={isDownloadingPdf}
                    loading={isDownloadingPdf}
                    onClick={onDownloadPdf('pdf')}>
                    {translations.global.download} PDF
                  </Button>
                </Group>
                <div ref={drop}>
                  <div ref={mapContainerRef}>
                    {currentMap && (
                      <Map
                        bounds={bounds}
                        setMap={setMap}
                        placeId={props.placeId}
                        selectedDeviceType={selectedDeviceType}
                        onRefetch={onRefetch}
                        currentMap={currentMap}
                        devicesWithoutMap={devicesWithoutMap}
                        setBounds={setBounds}
                        droppedDevice={droppedDevice}
                        onSetDroppedDeviceMarker={onSetDroppedDeviceMarker}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </AppContent>
    </div>
  );
};
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    interval: 0,
    power: 0,
  },
})(PlaceMap);
