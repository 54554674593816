import { divIcon } from 'leaflet';
import styles from './styles.module.scss';

export const deviceIcon = (device, type) => {
  const classByType = {
    red: styles.iconRed,
    green: styles.iconGreen,
    normal: styles.iconNormal,
    yellow: styles.iconYellow,
    yellowRed: styles.iconYellowRed,
  };
  let style = '';
  if (type === 'normal' && device.deviceType?.color) {
    style = `background-color: ${device.deviceType.color} !important;border-radius: 0 !important;`;
  }
  return divIcon({
    className: styles.iconWrapper,
    html: `<div class="${styles.icon} ${classByType[type]}" style="${style}"></div>`,
    iconAnchor: [20, 40],
    iconSize: [40, 40],
    popupAnchor: [0, -40],
  });
};
