import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchCompanies } from 'modules/Company/reducers/list';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import { translationService } from '../../../../shared/translations/translations';

class List extends PureComponent {
  translations = translationService.getTranslations();

  componentDidMount() {
    this.props.fetchCompanies();
  }

  goToCreate = () => {
    this.props.history.push(routes.companies.create.path);
  };

  goToEdit = id => () => {
    this.props.history.push(routes.companies.manage.to(id));
  };

  countDevices = company => {
    const devices = flatMap(company.places, place => place.devices);
    return devices.length;
  };

  render() {
    const { data, states } = this.props;
    // const myCompany = get(data, '[0]') || {};
    // const isSingleCompany = myCompany.subCompanyLimit <= 1;
    const subCompanies = get(data, '[0].subCompanies') || [];
    return (
      <div>
        <ListHeading title={'Moja firma'}>
          <Button type="primary" onClick={this.goToCreate}>
            {this.translations.companies.addClient}
          </Button>
        </ListHeading>
        <AppContent>
          <h3> {this.translations.companies.myCompany}</h3>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading} fixed>
                <Thead>
                  <Tr>
                    <Th>Logo</Th>
                    <Th>{this.translations.global.name}</Th>
                    <Th>{this.translations.global.email}</Th>
                    <Th>{this.translations.global.address}</Th>
                    {/* <Th>Ilość placówek</Th> */}
                    {/* <Th>Ilość urządzeń</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data, company => (
                    <Tr key={company.uuid} onClick={this.goToEdit(company.uuid)}>
                      <Td>
                        {company.logo ? (
                          <img src={company.logo} className={styles.logo} alt="" />
                        ) : (
                          <div className={styles.logoPlaceholder} />
                        )}
                      </Td>
                      <Td>{company.name}</Td>
                      <Td>{company.email}</Td>
                      <Td>{company.address}</Td>
                      {/* <Td>{company.placesCount || company.places.length}</Td>
                      <Td>{company.devicesCount || this.countDevices(company)}</Td> */}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <div style={{ height: 40 }} />
              <h3>{this.translations.companies.clients}</h3>
              <Table isLoading={states.isLoading} fixed>
                <Thead>
                  <Tr>
                    <Th>Logo</Th>
                    <Th>{this.translations.global.name}</Th>
                    <Th>{this.translations.global.email}</Th>
                    <Th>{this.translations.global.address}</Th>
                    {/* <Th>Ilość placówek</Th>
                    <Th>Ilość urządzeń</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(subCompanies, company => (
                    <Tr key={company.uuid} onClick={this.goToEdit(company.uuid)}>
                      <Td>
                        {company.logo ? (
                          <img src={company.logo} className={styles.logo} alt="" />
                        ) : (
                          <div className={styles.logoPlaceholder} />
                        )}
                      </Td>
                      <Td>{company.name}</Td>
                      <Td>{company.email}</Td>
                      <Td>{company.address}</Td>
                      {/* <Td>{company.placesCount || company.places.length}</Td>
                      <Td>{company.devicesCount || this.countDevices(company)}</Td> */}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ companies }) => ({
  data: companies.list.data,
  states: companies.list.states,
});
const mapDispatchToProps = dispatch => bindActionCreators({ fetchCompanies }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(List);
