import { DependencyList, useCallback, useEffect, useState } from 'react';
interface Props {
  initialValue: any;
  fetchAction: any;
  shouldNotFetchAtStart?: boolean;
  fetchActionDeps?: DependencyList;
}
const useFetch = <T>(props: Props) => {
  const { fetchAction, initialValue, shouldNotFetchAtStart, fetchActionDeps } = props;
  const [data, setData] = useState<T>(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const load = useCallback(() => {
    async function loadData() {
      try {
        setLoading(true);
        const { data } = await fetchAction();
        setLoading(false);
        setLoaded(true);
        setError(false);
        setData(data);
      } catch {
        setError(true);
        setLoaded(false);
        setLoading(false);
      }
    }
    loadData();
  }, [fetchAction, ...(fetchActionDeps || [])]);

  useEffect(() => {
    if (shouldNotFetchAtStart) {
      return;
    }
    load();
  }, [shouldNotFetchAtStart, ...(fetchActionDeps || [])]);

  const refresh = useCallback(() => {
    load();
  }, [load, ...(fetchActionDeps || [])]);

  const reset = useCallback(() => {
    setLoading(false);
    setData(initialValue);
    setLoaded(false);
    setError(false);
  }, []);

  return {
    data,
    isLoading,
    isLoaded,
    error,
    refresh,
    reset,
  };
};

export default useFetch;
