import {
  Badge,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Header,
  Image,
  Loader,
  Overlay,
  Select,
  SimpleGrid,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useMemo } from 'react';
import useFetch from '../../shared/hooks/useFetch';
import apiService from '../../shared/services/api';
import DashboardActiveRecommendations from './DashboardActiveRecommendations';
import DashboardDevicesByType from './DashboardDevicesByType';
import DashboardLastInspections from './DashboardLastInspections';
import { DashboardData } from './types';
import styles from './styles.module.scss';
import DashboardObjectsChart from './DashboardObjectsChart';
import DashboardRecommendationsByStatusChart from './DashboardRecommendationsByStatusChart';
import DashboardRecommendationsByPriorityChart from './DashboardRecommendationsByPriorityChart';
import { routes } from '../../shared/constants/routes';
import { useHistory } from 'react-router';
import { USER_CONTROLLER } from '../../shared/constants/user';
import { useSelector } from 'react-redux';
import { UserType } from '../../shared/types/User';
import { useTranslations } from '../../shared/translations/useTranslations';
import { useDashboardQuery } from '../../queries/dashboard';
import { ExternalLink } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import NumberCard from './components/NumberCard';
import useCurrentUser from '../../shared/hooks/useCurrentUser';

const DashboardDetails = () => {
  const translations = useTranslations();
  const {
    data: dataCompanies,
    isLoading: isLoadingCompanies,
    isLoaded: isLoadedCompanies,
  } = useFetch<any[]>({
    initialValue: [],
    fetchAction: () => apiService({}).get(`/companies`),
  });
  const history = useHistory();
  const dashboardData = useDashboardQuery();
  const { data, isLoading, isLoaded, refresh } = useFetch<DashboardData>({
    initialValue: { deviceCountByDeviceType: [], recommendationsByPriority: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/api/places/${form.values.selectedPlaceId}/dashboard`),
  });
  const form = useForm({
    initialValues: {
      placeId: undefined,
      companyId: undefined,
      selectedPlaceId: undefined,
      shortcut: undefined,
    },
  });

  useEffect(() => {
    if (form.values.selectedPlaceId) {
      refresh();
    }
  }, [form.values.selectedPlaceId]);

  const companiesList = useMemo(() => {
    const result = [];
    result.push(
      ...dataCompanies?.map(company => ({
        label: `${company.name}`,
        value: String(company.uuid),
        group: String(company.name),
      })),
    );
    dataCompanies?.forEach(c => {
      result.push(
        ...c.subCompanies.map(sc => ({
          label: `${sc.name}`,
          value: String(sc.uuid),
          group: String(c.name),
        })),
      );
    });
    return result;
  }, [dataCompanies]);
  const userType = useSelector(state => state.auth.data.userType);
  const placeList = useMemo(() => {
    const result = [];
    const allPlaces =
      dataCompanies?.flatMap(company => {
        const subCompanyPlaces =
          company.subCompanies?.flatMap(subCompany => {
            return [...subCompany.places];
          }) || [];
        return [...company.places, ...subCompanyPlaces];
      }) || [];

    const filteredPlaces = allPlaces?.filter(place =>
      userType === UserType.CONTROLLER
        ? true
        : String(place.companyUuid) === String(form.values.companyId),
    );

    result.push(
      ...filteredPlaces?.map(place => ({
        label: `${place.name}`,
        value: String(place.uuid),
      })),
    );

    return result;
  }, [dataCompanies, form.values.companyId, userType]);

  const placeCompany = useMemo(() => {
    const allCompanies =
      dataCompanies?.flatMap(company => {
        return [company, ...company.subCompanies];
      }) || [];

    const x = allCompanies.find(company => {
      return String(company.uuid) === String(form.values.companyId);
    });
    return x;
  }, [form.values.selectedPlaceId, dataCompanies]);

  useEffect(() => {
    if (isLoadedCompanies) {
      const company = dataCompanies.length ? dataCompanies[0] : undefined;
      const companyId = company?.uuid ? String(company?.uuid) : undefined;
      const placeId = company?.places?.length ? String(company.places[0].uuid) : undefined;
      form.setValues({ companyId, placeId, selectedPlaceId: placeId, shortcut: undefined });
    }
  }, [isLoadedCompanies, form.setValues, dataCompanies]);
  const currentUser = useCurrentUser();
  return (
    <div style={{ padding: '20px' }}>
      <Title order={2} mb={20}>
         
      </Title>
      {dashboardData.isFetching && (
        <Center mt={30} mb={30}>
          <Loader />
        </Center>
      )}
      {!dashboardData.isFetching && (
        <>
          <Grid align={'flex-start'} className={styles.container} mb={40}>
            <NumberCard
              title={translations.dashboard.triggeredMouseeAlerts}
              amount={dashboardData.data?.electronicDevices.alertCount || 0}
              backgroundColor={
                dashboardData.data?.electronicDevices.alertCount > 0 ? 'rgb(217 21 15)' : '#00B287'
              }
              color="white"
            />
            <NumberCard
              title={translations.dashboard.todaysEvents}
              amount={dashboardData.data?.schedule.todayUpcomingEventsCount || 0}
              backgroundColor={
                dashboardData.data?.schedule.todayUpcomingEventsCount > 0
                  ? 'rgb(217 21 15)'
                  : '#00B287'
              }
              color="white"
              externalLink={routes.schedule.default.path}
            />

            <NumberCard
              externalLink={routes.recommendation.default.path}
              title={translations.dashboard.activeRecommendations}
              color="white"
              amount={dashboardData.data?.recommendations.count || 0}
              amountText={
                dashboardData.data?.recommendations.count > 0
                  ? `w ${dashboardData.data?.recommendations.placesCount} placówkach`
                  : null
              }
              backgroundColor={
                dashboardData.data?.recommendations.count > 0 ? '#7950f2' : '#00B287'
              }
            />
          </Grid>
          <Grid align={'flex-start'} className={styles.container} mb={40}>
            {currentUser?.type === UserType.COMPANY_ADMIN && (
              <NumberCard
                icon={<i className="fas fa-user" style={{ color: '#42c19a' }} />}
                externalLink={routes.users.default.path}
                title={`${translations.dashboard.usersInCompany} ${dashboardData.data?.company?.name}`}
                amount={dashboardData.data?.company.usersCount}
                backgroundColor="white"
              />
            )}
            {currentUser?.type === UserType.COMPANY_ADMIN && (
              <NumberCard
                icon={<i className="fas fa-user" style={{ color: '#42c19a' }} />}
                externalLink={routes.companies.default.path}
                title={`${translations.dashboard.clientsAmount} ${dashboardData.data?.company.name}`}
                amount={dashboardData.data?.company.clientsCount}
                backgroundColor="white"
              />
            )}
            <NumberCard
              icon={<i className="fas fa-warehouse" style={{ color: '#42c19a' }} />}
              externalLink={routes.places.default.path}
              title={translations.dashboard.placeAmount}
              amount={dashboardData.data?.places.count}
              backgroundColor="white"
            />
          </Grid>
        </>
      )}

      <Grid align={'flex-start'} className={styles.container}>
        <Grid.Col span={12}>
          <Title order={2}>{translations.dashboard.dataForPlace}</Title>
        </Grid.Col>
        <Grid.Col span={4}>
          <Card shadow="sm" p="lg" style={{ overflow: 'unset' }}>
            <Group align="end" style={{ marginBottom: 5, marginTop: 5 }}>
              {userType !== UserType.CONTROLLER && (
                <Select
                  placeholder=""
                  searchable
                  label={translations.global.company}
                  data={companiesList}
                  {...form.getInputProps('companyId')}
                  onChange={value => {
                    form.setFieldValue('placeId', undefined);
                    form.getInputProps('companyId').onChange(value);
                  }}
                />
              )}
              <Select
                data={placeList}
                searchable
                placeholder=""
                label={translations.global.facility}
                {...form.getInputProps('placeId')}
                disabled={!form.values.companyId}
              />
              <Button
                size="sm"
                disabled={!form.values.placeId}
                onClick={() => {
                  form.setFieldValue('selectedPlaceId', form.values.placeId);
                }}>
                {translations.global.showData}
              </Button>
            </Group>
          </Card>
        </Grid.Col>
        <Grid.Col span={2}>
          <Card shadow="sm" p="lg" style={{ overflow: 'unset' }}>
            <Group align="end" style={{ marginBottom: 5, marginTop: 5 }}>
              <Select
                placeholder=""
                searchable
                label={translations.global.shortcut}
                data={[
                  {
                    value: routes.places.manage.to(form.values.selectedPlaceId),
                    label: translations.global.details,
                  },
                  {
                    value: routes.places.manage.to(form.values.selectedPlaceId, '?tab=devices'),
                    label: translations.global.devices,
                  },
                  {
                    value: routes.places.manage.to(form.values.selectedPlaceId, '?tab=zones'),
                    label: translations.zones.zones,
                  },
                  {
                    value: routes.places.manage.to(
                      form.values.selectedPlaceId,
                      '?tab=radio_options',
                    ),
                    label: translations.devices.radioOptions,
                  },
                  {
                    value: routes.places.manage.to(form.values.selectedPlaceId, '?tab=files'),
                    label: translations.global.files,
                  },
                  {
                    value: routes.places.manage.to(form.values.selectedPlaceId, '?tab=map'),
                    label: translations.global.facilityPlan,
                  },
                  {
                    value: routes.reports.default.to(
                      `?placeId=${form.values.selectedPlaceId}&tab=inspections`,
                    ),
                    label: translations.global.inspections,
                  },
                  {
                    value: routes.places.manage.to(
                      form.values.selectedPlaceId,
                      '?tab=objects-limits',
                    ),
                    label: translations.limits.title,
                  },
                  {
                    value: routes.places.manage.to(
                      form.values.selectedPlaceId,
                      '?tab=visualInspection',
                    ),
                    label: translations.global.visualInspection,
                  },
                  {
                    value: routes.recommendation.place.to(form.values.selectedPlaceId),
                    label: translations.global.recommendations,
                  },
                ]}
                {...form.getInputProps('shortcut')}
              />
              <Button
                size="sm"
                disabled={!form.values.placeId}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (form.values.shortcut) {
                    window.open(form.values.shortcut, '_blank');
                  }
                  // form.setFieldValue('selectedPlaaceId', form.values.placeId);
                }}>
                {translations.global.navigate}
              </Button>
            </Group>
          </Card>
        </Grid.Col>
        {data.parentCompany && (
          <Grid.Col span={3}>
            <Card style={{ overflow: 'unset' }} shadow="sm" p="lg">
              <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
                <Title order={4}>Klient</Title>
              </Group>
              <>
                <div style={{ display: 'flex' }}>
                  {data.parentCompany.logo && (
                    <div style={{ paddingRight: 15, width: '115px' }}>
                      <img src={data.parentCompany?.logo} style={{ maxWidth: '100px' }} />
                    </div>
                  )}
                  <div style={{ flex: 1, flexGrow: 1 }}>
                    <strong>{data.parentCompany?.name}</strong>
                    {userType !== USER_CONTROLLER && (
                      <>
                        <div>
                          <strong>{translations.global.email}:</strong>
                          <br /> {data.parentCompany.email}
                        </div>
                        <div>
                          <strong>{translations.global.address}:</strong>
                          <br /> {data.parentCompany.address}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </Card>
          </Grid.Col>
        )}
        <Grid.Col span={3}>
          <Card style={{ overflow: 'unset' }} shadow="sm" p="lg">
            <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
              <Title order={4}>{translations.global.securingCompany}</Title>
            </Group>
            {data.rootCompany && (
              <>
                <div style={{ display: 'flex' }}>
                  {data.rootCompany.logo && (
                    <div style={{ paddingRight: 15, width: '115px' }}>
                      <img src={data.rootCompany?.logo} style={{ maxWidth: '100px' }} />
                    </div>
                  )}
                  <div style={{ flex: 1, flexGrow: 1 }}>
                    <strong>{data.rootCompany?.name}</strong>
                    <div>
                      <strong>{translations.global.email}:</strong>
                      <br /> {data.rootCompany.email}
                    </div>
                    <div>
                      <strong>{translations.global.address}:</strong>
                      <br /> {data.rootCompany.address}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </Grid.Col>
        <Grid.Col span={6}>
          <DashboardActiveRecommendations placeId={form.values.selectedPlaceId} />
        </Grid.Col>
        <Grid.Col span={3}>
          <DashboardRecommendationsByPriorityChart
            placeId={form.values.selectedPlaceId}
            recommendationsByPriority={data.recommendationsByPriority}
            loading={isLoading}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <DashboardRecommendationsByStatusChart
            loading={isLoading}
            placeId={form.values.selectedPlaceId}
            recommendationsByStatus={data.recommendationsByStatus}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <DashboardDevicesByType
            placeId={form.values.selectedPlaceId}
            deviceCountByDeviceType={data.deviceCountByDeviceType}
            loading={isLoading}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <DashboardLastInspections placeId={form.values.selectedPlaceId} roles={data.userRoles} />
        </Grid.Col>
        <Grid.Col span={8}>
          <DashboardObjectsChart placeId={form.values.selectedPlaceId} />
        </Grid.Col>
        {/* <Grid.Col span={4}>harmonogram</Grid.Col> */}
        {/* <Grid.Col span={4}>
          <DashboardRecommendationsByPriority
            placeId={form.values.selectedPlaceId}
            recommendationsByPriority={data.recommendationsByPriority}
            loading={isLoading}
          />
        </Grid.Col> */}
      </Grid>
    </div>
  );
};

export default DashboardDetails;
