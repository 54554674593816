import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';

import { User } from 'shared/types/User';

export const getCompanySelectData = (users: User[]) => {
  return uniqBy(users, 'companyUuid').filter((user) => user.company !== null).map(user => ({
    label: user.company?.name,
    value: user.company?.uuid,
  }));
};
