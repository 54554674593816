import { useForm } from '@mantine/form';

export const mapDeviceTypeObservationFormValuesToApiBody = ({ formValues, deviceTypeId }: any) => {
  const body = {
    deviceTypeId,
    name: formValues.name,
    type: formValues.type,
    translations: {
      pl: { name: '' },
      en: { name: '' },
      de: { name: '' },
      it: { name: '' },
      ru: { name: '' },
    },
  };
  formValues?.translations.forEach(translation => {
    body.translations[translation.lang].name = translation.name;
  });

  return body;
};

export const useDeviceTypeObservationForm = () => {
  const form = useForm({
    initialValues: {
      name: '',
      type: '',
      translations: [
        { lang: 'pl', name: undefined },
        { lang: 'en', name: undefined },
        { lang: 'de', name: undefined },
        { lang: 'it', name: undefined },
        { lang: 'ru', name: undefined },
      ],
    },
  });

  return form;
};
