import React, { useState } from 'react';
import { Button, Menu, Popover, Text } from '@mantine/core';
import { BuildingWarehouse, Dots, Folder } from 'tabler-icons-react';
import { useTranslations } from 'shared/translations/useTranslations';
import styles from './styles.module.scss';

const CompanyFilesCategoryRow = props => {
  const { active, category, onSelect, onEdit, onDelete } = props;
  const [opened, setOpened] = useState(false);
  const translations = useTranslations();
  return (
    <div
      className={`${styles.categoryRow} ${active ? styles.categoryRowActive : ''}`}
      onClick={onSelect}>
      <Folder
        className={`${styles.categoryRowIcon} ${active ? styles.categoryRowIconActive : ''}`}
      />
      <div className={`${styles.categoryRowName}`}>{category.name}</div>
      {category.subCompanies?.length > 0 && (
        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          width={260}
          position="bottom"
          withArrow>
          <Popover.Target>
            <BuildingWarehouse
              className={styles.categoryRowInfoIcon}
              size={18}
              onMouseEnter={() => setOpened(true)}
              onMouseLeave={() => setOpened(false)}
            />
          </Popover.Target>
          <Popover.Dropdown>
            <div style={{ display: 'flex' }}>
              <Text size="sm">
                Pliki w tym folderu są dostępne w firmach:{' '}
                {category?.subCompanies?.map(c => c.name).join(', ')}
              </Text>
            </div>
          </Popover.Dropdown>
        </Popover>
      )}
      <div className={styles.categoryRowInfo}>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button size="xs" variant="light">
              <Dots />
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                onEdit();
              }}>
              {translations.global.edit}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                onDelete();
              }}
              color="red">
              {translations.global.delete}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};
export default CompanyFilesCategoryRow;
