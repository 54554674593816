import React, { PropsWithChildren } from 'react';
import { useDrag } from 'react-dnd';
import { Device } from './PlaceMap';

interface DraggableDeviceProps {
  device: Device;
}

const DraggableDevice = (props: PropsWithChildren<DraggableDeviceProps>) => {
  const { device, children } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'DEVICE',
    item: { device },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={drag} style={{ backgroundColor: 'white', opacity }}>
      {children}
    </div>
  );
};

export default DraggableDevice;
