import React from 'react';

import Details from './Details';

import QRCodesList from './List';
import { Route, Switch } from 'react-router';
import { routes } from '../../shared/constants/routes';

function QRCodes() {
  return (
    <>
      <Switch>
        <Route path={routes.qrCodes.default.path} exact component={QRCodesList} />
        <Route path={routes.qrCodes.details.path} exact component={Details} />
      </Switch>
    </>
  );
}

export default QRCodes;
