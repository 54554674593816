import React, { useState } from 'react';

import map from 'lodash/map';
import { readableDate } from 'shared/utils/date';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import { getUserTypeLabel } from 'shared/utils/user';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import useFetch from '../../../../shared/hooks/useFetch';
import apiService from '../../../../shared/services/api';
import { ActionIcon } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import EditPlaceUserModal from './EditPlaceUserModal';

interface Props {
  placeId: string | number;
}

const PlaceUsers = (props: Props) => {
  const { placeId } = props;
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    users: any[];
  }>({
    initialValue: { users: [] },
    fetchAction: () => apiService({}).get(`/places/${placeId}/users`),
  });
  const translations = useTranslations();
  const handleRefresh = () => {
    refresh();
  };
  const handleModalClose = () => {
    setSelectedEditId(undefined);
  };
  return (
    <>
      <EditPlaceUserModal
        placeId={placeId}
        userId={selectedEditId}
        onClose={handleModalClose}
        refreshParent={handleRefresh}
      />
      <h2>{translations.global.users}</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>{translations.global.firstAndLastName}</Th>
            <Th>{translations.global.createdAt}</Th>
            <Th>{translations.global.type} </Th>
            <Th> </Th>
          </Tr>
        </Thead>
        <Tbody>
          {map(data.users, user => (
            <Tr key={user.uuid}>
              <Td>
                {user.name} {user.surname}
              </Td>
              <Td>{readableDate(user.user_places?.createdAt)}</Td>
              <Td>{getUserTypeLabel(user.type)}</Td>
              <Td>
                <ActionIcon
                  variant="transparent"
                  onClick={() => {
                    setSelectedEditId(user.uuid);
                  }}>
                  <Pencil size={16} />
                </ActionIcon>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default PlaceUsers;
