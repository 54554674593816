import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  Group,
  LoadingOverlay,
  Popover,
  ScrollArea,
  Table,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { get } from 'lodash';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import {
  getColorByRecommendationPriority,
  getColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import SeeMoreButton from './SeeMoreButton';

interface Props {
  placeId?: string | number;
}

const DashboardActiveRecommendations = (props: Props) => {
  const { data, isLoading, isLoaded, refresh } = useFetch<{ recommendations: any[] }>({
    initialValue: { recommendations: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(
        `/api/recommendations?placeId=${props.placeId}&limit=10&status=OPEN,IN_PROGRESS,WAITING`,
      ),
  });
  const form = useForm({
    initialValues: { placeId: undefined, companyId: undefined },
  });

  useEffect(() => {
    if (props.placeId) {
      refresh();
    }
  }, [props.placeId]);

  const [showComment, setShowComment] = useState(false);
  const translations = useTranslations();

  return (
    <Card
      shadow="sm"
      p="lg"
      mb={20}
      style={{ maxHeight: '400px', height: '400px', overflow: 'unset' }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.global.recommendations}</Title>

        {props.placeId && <SeeMoreButton to={routes.recommendation.place.to(props.placeId)} />}
      </Group>
      <ScrollArea type="hover" style={{ height: '300px' }}>
        <Table fontSize={11}>
          <thead>
            <tr>
              <th>ID</th>
              <th>{translations.recommendations.priority}</th>
              <th>{translations.recommendations.category}</th>
              <th>{translations.global.createdAt}</th>
              <th>{translations.recommendations.dueDate}</th>
              <th>Komentarz</th>
              <th>{translations.recommendations.recommendation}</th>
              <th>{translations.global.status}</th>
            </tr>
          </thead>
          <tbody>
            {data?.recommendations?.map(recommendation => (
              <tr key={recommendation.id}>
                <td>{recommendation.uniqueId}</td>
                <td>
                  <Badge color={getColorByRecommendationPriority(recommendation.priority)}>
                    {translations.recommendations.enums.priority[recommendation.priority] ||
                      recommendation.priority}
                  </Badge>
                </td>
                <td>
                  {' '}
                  {recommendation.recommendationCategoryId
                    ? get(recommendation, 'recommendationCategory.name')
                    : '-'}
                </td>
                <td>{readableDate(recommendation.createdAt)}</td>
                <td>{readableDateWithoutTime(recommendation.dueDate)}</td>
                <td>
                  {recommendation.comment ? (
                    <Popover
                      opened={showComment === recommendation.id}
                      onClose={() => setShowComment(false)}
                      position="bottom"
                      placement="center"
                      withArrow
                      trapFocus={false}
                      closeOnEscape={false}
                      transition="pop-top-left"
                      width={260}
                      styles={{ body: { pointerEvents: 'none' } }}>
                      <Popover.Target>
                        <i
                          className="fa fa-info"
                          onMouseEnter={() => setShowComment(recommendation.id)}
                          onMouseLeave={() => setShowComment(false)}></i>
                      </Popover.Target>
                      <Popover.Dropdown>{recommendation.comment}</Popover.Dropdown>
                    </Popover>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{recommendation.recommendation}</td>
                <td>
                  <Badge color={getColorByRecommendationStatus(recommendation.status)}>
                    {translations.recommendations.enums.status[recommendation.status] ||
                      recommendation.status}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
};

export default DashboardActiveRecommendations;
