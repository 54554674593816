export function formatZonesToDataOptions(
  zones,
  depth = 0,
  currentZoneUuid = undefined,
  shouldDisable = false,
) {
  let options = [];

  zones.forEach(zone => {
    const prefix = '--'.repeat(depth);
    const isCurrentOption = zone.uuid === currentZoneUuid;
    const disabled = isCurrentOption || shouldDisable;
    options.push({
      label: `${prefix} ${zone.name}`,
      value: zone.uuid,
      disabled,
    });

    if (zone.childZones && zone.childZones.length > 0) {
      const childOptions = formatZonesToDataOptions(
        zone.childZones,
        depth + 1,
        currentZoneUuid,
        disabled,
      );
      options = options.concat(childOptions);
    }
  });

  return options;
}
