import React from 'react';
import { Route } from 'react-router';

import { routes } from 'shared/constants/routes';
import { useRedirectToLoginIfNotAuthenticated } from 'shared/hooks/useRedirectToLogin';

import Login from './containers/Login';
import ForgotPasswordForm from './containers/ForgotPassword';
import ResetPasswordForm from './containers/ResetPassword';

export default () => {
  useRedirectToLoginIfNotAuthenticated();

  return [
    <Route path={routes.auth.login.path} key="auth-login" component={Login} exact />,
    <Route
      path={routes.auth.forgotPassword.path}
      key="auth-forgot-password"
      component={ForgotPasswordForm}
      exact
    />,
    <Route
      path={routes.auth.resetPassword.path}
      key="auth-reset-password"
      component={ResetPasswordForm}
      exact
    />,
  ];
};
