import { Flex, Grid, Tabs, Title } from '@mantine/core';
import { usePlaceMapsQuery } from 'queries/places';
import React, { useCallback } from 'react';
import HeatmapContainer from './HeatmapContainer';

interface PlaceHeatmapProps {
  placeUuid: string;
}

const PlaceHeatmap = (props: PlaceHeatmapProps) => {
  const { placeUuid } = props;

  const { data: heatmapData } = usePlaceMapsQuery(placeUuid);
  const maps = heatmapData?.data?.maps || [];

  const [currentMap, setCurrentMap] = React.useState(maps[0]);

  React.useEffect(() => {
    if (maps.length === 0) {
      return;
    }
    setCurrentMap(maps[0]);
  }, [maps, setCurrentMap]);

  const onSelectMap = useCallback(
    (index: string) => {
      setCurrentMap(maps[Number(index)]);
    },
    [maps],
  );

  return (
    <Flex direction="column" p={20}>
      <Title order={1} mb={30}>
        {' '}
        Heatmap
      </Title>
      <Tabs
        defaultValue="0"
        orientation="vertical"
        variant="outline"
        onTabChange={onSelectMap}
        keepMounted={false}>
        <Tabs.List>
          {maps.map((map, i) => (
            <Tabs.Tab key={`heatmap-tab-${map.id}`} value={`${i}`}>
              {map.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {maps.map((map, i) => (
          <Tabs.Panel key={`heatmap-panel-${map.id}`} value={`${i}`}>
            <HeatmapContainer placeUuid={placeUuid} mapId={map.id} />
          </Tabs.Panel>
        ))}
      </Tabs>
    </Flex>
  );
};

export default PlaceHeatmap;
