import axios from 'axios';
import storeJS from 'store';
import get from 'lodash/get';
import { store } from '../../store';
import { STORAGE_AUTH_KEY } from '../constants/auth';
import { performLogout } from '../../modules/Auth/reducers/login';
import { translationService } from 'shared/translations/translations';
import { toast } from 'react-toastify';
import enterpriseClientService from '../../modules/Auth/containers/EnterpriseClient/enterpriseClient.service';
import moment from 'moment';

export const API_URL = process.env.REACT_APP_BACKEND_URL;

export const apiService = ({ baseUrl = API_URL, headers: newHeaders = {}, timeout = 30000 }) => {
  const auth = storeJS.get(STORAGE_AUTH_KEY);
  const appStore = store.getState();
  const { token } = get(appStore, 'auth.data') || {};
  const authToken = get(auth, 'token') || token;

  const headers = {
    Authorization: authToken,
    customlanguage: translationService.getCurrentLanguage(),
    userDate: window.btoa(new Date().toISOString()),
    [`User-Language`]: translationService.getCurrentLanguage(),
    [`User-Date`]: window.btoa(new Date().toISOString()),
    [`User-Timezone`]: moment.tz.guess(),
    userDateString: window.btoa(new Date().toLocaleString()),
    enterprise: enterpriseClientService.name,
    ...newHeaders,
  };

  const instance = axios.create({
    baseURL: baseUrl || API_URL,
    headers,
    timeout,
  });

  function logoutOnUnauthorized(axios, refreshTokenCall, options = {}) {
    axios.interceptors.response.use(
      res => res,
      error => {
        if (error && error.response && [401].includes(error.response.status)) {
          store.dispatch(performLogout());
        }
        if (error && error.response && [403].includes(error.response.status)) {
          toast.error('Nie masz dostępu do tego zasobu.');
        }
        throw error;
      },
    );
  }
  logoutOnUnauthorized(instance);

  return instance;
};

export const getError = response => {
  const result = {
    response:
      get(response, 'response.data') || get(response, 'errors[0]') || response.error || 'error',
  };

  if (response.error === 'invalid_token') {
    result.type = 'UNAUTHORIZED_TOKEN';
  }
  return result;
};

export default apiService;
