import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import {
  Menu,
  Button,
  Popover,
  ActionIcon,
  ThemeIcon,
  Avatar,
  Indicator,
  Badge,
  Flex,
} from '@mantine/core';
import {
  Settings as IconSettings,
  Bell as IconBell,
  BellRinging as IconBellRinging,
  Logout as IconLogout,
  CircleCheck as IconCircleCheck,
  User as IconUser,
} from 'tabler-icons-react';
import moment from 'moment';
import useFetch from '../../../../shared/hooks/useFetch';
import apiService from '../../../../shared/services/api';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import { useHistory } from 'react-router';
import { routes } from '../../../../shared/constants/routes';
import {
  NotificationsCountResponse,
  NotificationsLatestResponse,
} from '../../../../shared/types/UserNotification';
import {
  getNotificationData,
  getNotificationIcon,
  getNotificationIconColor,
} from '../../../Account/containers/Notifications/helpers';
import useCurrentUser from '../../../../shared/hooks/useCurrentUser';
import { getUserTypeLabel } from '../../../../shared/utils/user';
import { performLogout } from '../../../Auth/reducers/login';
import { useDispatch } from 'react-redux';

const TopBar = () => {
  const [isClearing, setIsClearing] = useState(false);
  const translations = useTranslations();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(performLogout());
  };
  const {
    data: unreadData,
    isLoading: isLoadingUnread,
    isLoaded: isLoadedUnread,
    refresh: refreshUnread,
  } = useFetch<NotificationsCountResponse>({
    initialValue: { unreadCount: 0 },
    fetchAction: () => apiService({}).get(`/account/notifications/unread-count`),
  });
  const notificationsUnread = unreadData.unreadCount;
  const { data, isLoading, isLoaded, error, refresh } = useFetch<NotificationsLatestResponse>({
    initialValue: { rows: [], count: 0 },
    fetchAction: () => apiService({}).get(`/account/notifications/latest`),
  });
  const currentUnreadNotifications = data.rows.filter(notification => !notification.isRead);

  useEffect(() => {
    const id = setInterval(() => {
      refresh();
      refreshUnread();
    }, 60000 * 5);
    return () => {
      clearInterval(id);
    };
  }, []);
  const handleOnClose = async () => {
    if (currentUnreadNotifications.length > 0) {
      try {
        setIsClearing(true);
        await apiService({}).post(`/account/notifications/clear`, {
          notificationUuids: currentUnreadNotifications.map(notification => notification.uuid),
        });
        refreshUnread();
        refresh();
      } catch {
      } finally {
        setIsClearing(false);
      }
    }
  };
  const currentUser = useCurrentUser();
  const handleAll = () => {
    history.push(routes.account.notifications.path);
  };

  return (
    <div className={styles.root}>
      <div className={styles.right}>
        <div className={styles.notifications}>
          <Popover position="bottom" withArrow shadow="md" onClose={handleOnClose}>
            <Popover.Target>
              <Indicator
                inline
                label={notificationsUnread}
                size={25}
                disabled={notificationsUnread === 0}>
                <ActionIcon
                  style={{ width: 40, height: 40 }}
                  loading={isLoadingUnread || isLoading || isClearing}
                  variant="subtle"
                  sx={{
                    '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
                  }}>
                  {notificationsUnread === 0 && <IconBell />}
                  {notificationsUnread > 0 && <IconBellRinging />}
                </ActionIcon>
              </Indicator>
            </Popover.Target>
            <Popover.Dropdown>
              <div className={styles.notificationsContent}>
                {currentUnreadNotifications.length > 0 && (
                  <Flex justify="flex-end">
                    <Button variant="transparent" color="primary" onClick={handleOnClose}>
                      {translations.notifications.setAllAsRead}
                    </Button>
                  </Flex>
                )}
                {data.rows.length === 0 && isLoaded && (
                  <div className={styles.noNotifications}>
                    {translations.notifications.noNotifications}
                  </div>
                )}
                {data.rows?.map(notification => {
                  const notificationData = getNotificationData(notification);
                  return (
                    <div className={styles.notification} key={notification.uuid}>
                      <div className={styles.notificationIcon}>
                        <ThemeIcon
                          color={getNotificationIconColor(notification.notificationKey)}
                          size={24}
                          radius="xl"
                          variant={notification.isRead ? 'default' : 'filled'}>
                          {getNotificationIcon(notification.notificationKey, 15)}
                        </ThemeIcon>
                      </div>
                      <div className={styles.notificationBody}>
                        <div className={styles.notificationTitle}>{notificationData.title}</div>
                        <div
                          className={styles.notificationDescription}
                          dangerouslySetInnerHTML={{ __html: notificationData.body }}></div>
                      </div>
                      <div className={styles.notificationTime}>
                        <Badge
                          color={notification.isRead ? 'gray' : 'default'}
                          variant={notification.isRead ? 'light' : 'light'}>
                          {moment(notification.createdAt).fromNow()}
                        </Badge>
                      </div>
                    </div>
                  );
                })}
                <div className={styles.showAll}>
                  {isLoaded && data.count > data.rows.length && (
                    <Button onClick={handleAll}>{translations.global.showAll}</Button>
                  )}
                </div>
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <div className={styles.user}>
              <div className={styles.name}>
                {[currentUser?.name, currentUser?.surname].join(' ')}
                <div className={styles.userType}>{getUserTypeLabel(currentUser?.type)}</div>
              </div>
              <Avatar size="lg">
                <IconUser size={30} />
              </Avatar>
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label></Menu.Label>
            <Menu.Item
              onClick={() => history.push(routes.account.notifications.path)}
              icon={<IconBell size={14} />}>
              {translations.notifications.notifications}
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push(routes.account.default.path)}
              icon={<IconSettings size={14} />}>
              {translations.global.account}
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item color="red" icon={<IconLogout size={14} />} onClick={handleLogout}>
              {translations.global.signOut}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};

export default TopBar;
