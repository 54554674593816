import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'shared/services/api';
import { Field, getFormValues, reduxForm, change } from 'redux-form';
import SelectField from 'shared/components/SelectField';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

import Modal from 'shared/components/Modal';
import { LatLng } from 'leaflet';
import { useTranslations } from 'shared/translations/useTranslations';

const FORM_NAME = 'place/SET_DEVICE_ON_MAP';
interface Props {
  close: (shouldRefetch: boolean) => void;
  open: boolean;
  placeId: number | string;
  latLng: LatLng;
  mapId: number;
  devices: {
    description: string;
    detect: boolean;
    id: number;
    mapId: string;
    mapX: string;
    mapY: string;
    name: string;
  }[];
}

const SetDeviceOnMap = (props: Props) => {
  const translations = useTranslations();
  const { close, open, latLng } = props;
  const [isSaving, setSaving] = useState(false);

  const values = useSelector(state => getFormValues(FORM_NAME)(state)) || {};

  const savePosition = async () => {
    if (!values || !values.deviceId) {
      return;
    }
    try {
      setSaving(true);
      await api({}).post(`places/${props.placeId}/devices/${values.deviceId}/set-on-map`, {
        mapX: latLng.lng,
        mapY: latLng.lat,
        mapId: props.mapId,
      });
      toast.success(translations.map.addedToMap);
      close(true);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={open} onClose={() => close(false)} title={translations.map.addOnMap}>
      <div className="row">
        <div className="col-md-12">
          <Field
            component={SelectField}
            name="deviceId"
            label={translations.global.selectDevice}
            dataSource={props.devices.map(device => ({
              label: `${device.uniqueId} - ${device.description}`,
              value: device.id,
            }))}
          />
        </div>
        <div className="col-md-3">
          <Button
            className={styles.uploadButton}
            onClick={savePosition}
            type="primary"
            isLoading={isSaving}>
            {translations.global.save}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default reduxForm({
  form: FORM_NAME,
  initialValues: {
    interval: 0,
    power: 0,
  },
})(SetDeviceOnMap);
