import React from 'react';
import { Grid, TextInput } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import api from 'shared/services/api';
import { Table, Th, Thead, Tr, Td, Tbody } from 'shared/components/Table';
import { routes } from 'shared/constants/routes';
import RotateLoader from 'shared/components/RotateLoader';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import { Place } from 'shared/types/Place';

import { useFilterPlaces } from './hooks';
import styles from './styles.module.scss';

const Details = () => {
  const history = useHistory();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Place[]>({
    initialValue: [],
    fetchAction: () => api({}).get(`/places`),
  });
  const { data: count } = useFetch({
    initialValue: { categories: [] },
    fetchAction: () => api({}).get(`/api/recommendations/count`),
  });
  const countRows = count.rows || [];
  const countByPlaceId = placeId => {
    return countRows.filter(row => row.placeId === placeId).length;
  };
  const translations = useTranslations();

  const [searchFilter, setSearchFilter] = React.useState('');
  const filteredPlaces = useFilterPlaces(data, searchFilter);
  return (
    <div>
      <ListHeading title={translations.global.recommendations}></ListHeading>
      <AppContent>
        {isLoading && <RotateLoader relative />}
        <Grid mb={20}>
          <Grid.Col span={3}>
            <TextInput
              label={translations.places.searchPlace}
              placeholder={translations.places.searchPlace}
              icon={<Search size={14} />}
              onChange={event => setSearchFilter(event.currentTarget.value)}
            />
          </Grid.Col>
        </Grid>
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>{translations.global.facility}</Th>
              <Th>{translations.recommendations.recommendationRequiredAction}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPlaces.map(place => {
              const placeCount = countByPlaceId(place.id);
              return (
                <Tr key={place.id}>
                  <Td>
                    <Link to={routes.recommendation.place.to(place.id)}>{place.name}</Link>
                  </Td>
                  <Td>
                    {placeCount ? <div className={styles.placeCount}>{placeCount}</div> : '0'}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
};
export default Details;
