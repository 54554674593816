import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import { useHistory } from 'react-router';
import { Button, Grid, TextInput } from '@mantine/core';

import ListHeading from 'shared/components/ListHeading';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import styles from './styles.module.scss';
import { translationService } from 'shared/translations/translations';
import { Company } from 'shared/types/Company';
import { Search } from 'tabler-icons-react';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';
import { useFilterCompanies } from './hooks';

const List = () => {
  const { data, isLoading } = useFetch<Company[]>({
    initialValue: [],
    fetchAction: () => api({}).get(`/companies`),
  });

  const history = useHistory();
  const goToCreate = useCallback(() => {
    history.push(routes.companies.create.path);
  }, []);

  const goToEdit = useCallback(
    id => () => {
      history.push(routes.companies.manage.to(id));
    },
    [],
  );

  const countDevices = useCallback((company: Company) => {
    const devices = flatMap(company.places, place => place.devices);
    return devices.length;
  }, []);

  const [searchFilter, setSearchFilter] = useState<string>();
  const companies = useFilterCompanies(data, searchFilter);

  const translations = translationService.getTranslations();

  return (
    <div>
      <ListHeading title={translations.global.companies}>
        <Button type="primary" onClick={goToCreate}>
          {translations.companies.addCompany}
        </Button>
      </ListHeading>
      <AppContent>
        <Grid>
          <Grid.Col span={12}>
            <Grid.Col mb={20} span={4}>
              <TextInput
                label={translations.global.search}
                placeholder={translations.global.search}
                icon={<Search size={14} />}
                onChange={event => setSearchFilter(event.currentTarget.value)}
              />
            </Grid.Col>
            <Table isLoading={isLoading}>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{translations.global.logo}</Th>
                  <Th>{translations.global.name}</Th>
                  <Th>{translations.global.email}</Th>
                  <Th>{translations.global.address}</Th>
                  <Th>{translations.global.facilityCount}</Th>
                  <Th>{translations.global.devicesCount}</Th>
                  <Th>{translations.global.clientsCount}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {map(companies, (company, index) => (
                  <Tr key={company.id} onClick={goToEdit(company.uuid)}>
                    <Td>{index + 1}</Td>
                    <Td>
                      {company.logo ? (
                        <img src={company.logo} className={styles.logo} alt="" />
                      ) : (
                        <div className={styles.logoPlaceholder} />
                      )}
                    </Td>
                    <Td>{company.name}</Td>
                    <Td>{company.email}</Td>
                    <Td>{company.address}</Td>
                    <Td>{company.placesCount || company.places?.length}</Td>
                    <Td>{company.devicesCount || countDevices(company)}</Td>
                    <Td>{company.subCompaniesCount || company.subCompanies.length}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid.Col>
        </Grid>
      </AppContent>
    </div>
  );
};

export default List;
