import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import enterpriseClientService from '../Auth/containers/EnterpriseClient/enterpriseClient.service';
import { setClientId } from '../Auth/reducers/data';

const AppWrapper = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setClientId({
        clientId: enterpriseClientService.name,
        isDomainClient: enterpriseClientService.isDomainClient,
      }),
    );
  }, []);
  return <>{children}</>;
};

export default AppWrapper;
