import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import map from 'lodash/map';
import get from 'lodash/get';
import { Trash } from 'tabler-icons-react';
import { ActionIcon, Paper, LoadingOverlay, Title } from '@mantine/core';
import { useSelector } from 'react-redux';

import { readableDate } from 'shared/utils/date';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { isAnyAdminByAuthState } from 'shared/utils/user';

import DeleteImageModal from '../DeleteImageModal';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

interface ImagesData {
  images: {
    id: string;
    url: string;
    createdAt: string;
    signedUrl?: string;
    inspection?: {
      name: string;
    };
  }[];
}

const DeviceImages = ({ deviceId }) => {
  const {
    data: dataImages,
    isLoading: isLoadingImages,
    isLoaded: isLoadedImages,
    error: isErrorImages,
    refresh: refreshImages,
  } = useFetch<ImagesData>({
    initialValue: { action: {} },
    fetchAction: () => apiService({}).get(`/api/v3/devices/${deviceId}/images`),
  });
  const isAdmin = useSelector(state => isAnyAdminByAuthState(state.auth));
  const classes = useStyles();
  const translations = useTranslations();
  const [deleteImageId, setDeleteImageId] = useState(undefined);
  const handleClose = () => {
    setDeleteImageId(undefined);
  };

  const handleRefreshImages = () => {
    setDeleteImageId(undefined);
    refreshImages();
  };

  const handleOnDeleteImage = (id: string) => () => {
    setDeleteImageId(id);
  };

  return (
    <div>
      <DeleteImageModal
        imageId={deleteImageId}
        deviceId={deviceId}
        refreshParent={handleRefreshImages}
        onClose={handleClose}
      />

      <Title order={3} mt={40} mb={20}>
        {translations.devices.images}
      </Title>
      <div style={{ position: 'relative', minHeight: '300px' }}>
        <Paper className={classes.root} shadow='xs'>
          <LoadingOverlay visible={isLoadingImages} />
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.devices.image}</TableCell>
                <TableCell>{translations.global.user}</TableCell>
                <TableCell>{translations.devices.date}</TableCell>
                <TableCell>{translations.devices.inspection}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isLoadedImages && (
              <TableBody>
                {map(dataImages.images, row => (
                  <TableRow key={`image-${row.id}`}>
                    <TableCell>
                      {row?.signedUrl ? (
                        <img src={row.signedUrl} style={{ maxWidth: '200px' }} />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {get(row, 'user.name')} {get(row, 'user.surname')}
                    </TableCell>
                    <TableCell>{readableDate(row.createdAt)}</TableCell>
                    <TableCell>{row.inspection?.name || '-'}</TableCell>
                    <TableCell>
                      {isAdmin && (
                        <ActionIcon variant="transparent" onClick={handleOnDeleteImage(row.id)}>
                          <Trash size={16} />
                        </ActionIcon>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </Paper>
      </div>
    </div>
  );
};

export default DeviceImages;
