import {
  Translation,
  getTranslationOrDefault,
  translationService,
} from '../translations/translations';

export enum ErrorCode {
  USER_EMAIL_TAKEN = 'user/email_taken',
  USER_LIMIT_REACHED = 'user/company_users_limit_reached',
}
export type ErrorTranslations = {
  [key in ErrorCode]: string;
};

export const getApiErrorTranslationByCode = (code?: string) => {
  const translations = translationService.getTranslations();
  const defaultResponse = translations.global.somethingWentWrong;
  if (!code) {
    return defaultResponse;
  }

  const errorTranslations: ErrorTranslations = {
    [ErrorCode.USER_EMAIL_TAKEN]: getTranslationOrDefault(translations, `errors.${code}`),
    [ErrorCode.USER_LIMIT_REACHED]: getTranslationOrDefault(translations, `errors.${code}`),
  };

  return errorTranslations[code] || defaultResponse;
};

export const deviceErrorMessages = (translations: Translation) => ({
  LORA_DEV_EUI_ALREADY_EXISTS:
    'Urządzenie o podanym LoraDevEui już istnieje. Próbujesz użyć tej samej wartości dla różnych urządzeń.',
  UNIQUE_ID_REQUIRED: 'Unikalne id jest wymagane',
  UNIQUE_ID_TOO_LONG: 'Unikalne id może mieć maksymalnie 4 znaki',
  UNIQUE_ID_EXISTS: 'Unikalne id jest już wykorzystane w placówce',
  'devices/company_devices_limit_reached': 'Limit urządzeń w placówce został przekroczony',
  default: translations.global.somethingWentWrong,
});
