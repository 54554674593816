import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'moment/locale/pl';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import TextField from '../TextField';
import styles from './styles.module.scss';

class DateField extends PureComponent {
  state = {
    isOpen: false,
  };

  toggleOpen = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleDayClick = day => {
    this.setState({ isOpen: false });
    this.props.input.onChange(day);
  };

  render() {
    const { ...props } = this.props;

    const classes = classnames(styles.DateField, '', {});

    const value = this.props.input.value ? new Date(this.props.input.value) : '';

    const visibleValue = value instanceof Date ? moment(value).format('YYYY-MM-DD') : value;

    const input = { ...props.input, value: visibleValue };
    const selectedDay = value instanceof Date ? value : new Date();

    return (
      <div className={classes}>
        <TextField {...props} readOnly input={input} onClick={this.toggleOpen} ref="test" />
        {this.state.isOpen && (
          <div className={styles.DateFieldCalendar}>
            <DayPicker
              localeUtils={MomentLocaleUtils}
              locale="pl"
              onDayClick={this.handleDayClick}
              selectedDays={selectedDay}
              month={selectedDay}
            />
          </div>
        )}
      </div>
    );
  }
}

DateField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
};

export default DateField;
