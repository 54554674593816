import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { PLACES_FETCH_LIST } from '../../reducers/list/';

function* fetchPlaces() {
  try {
    const { data } = yield apiService({}).get('/places');
    yield put({
      type: PLACES_FETCH_LIST.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({ type: PLACES_FETCH_LIST.FAILURE, payload: getError(err) });
  }
}

export default [
  function*() {
    yield takeEvery(PLACES_FETCH_LIST.REQUEST, fetchPlaces);
  }
];
