import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import { get } from 'lodash';
import MixtureForm from '../Form';
import { Button, Group, LoadingOverlay, Modal, Notification } from '@mantine/core';
import { MixtureModel } from '../Mixture';
import { X as IconX } from 'tabler-icons-react';

interface Props {
  id?: string;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  mixture: MixtureModel;
}
const Details = (props: Props) => {
  const { id, refreshParent, onClose } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { mixture: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/admin/mixtures/${id}`),
  });

  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      grammage: undefined,
      type: 'BAIT',
      translations: [
        { lang: 'pl', name: undefined, description: undefined },
        { lang: 'en', name: undefined, description: undefined },
        { lang: 'de', name: undefined, description: undefined },
        { lang: 'it', name: undefined, description: undefined },
        { lang: 'ru', name: undefined, description: undefined },
      ],
    },

    validate: {},
  });
  useEffect(() => {
    if (data?.mixture?.translations) {
      const translations = form.values.translations.map((translation, key) => {
        return {
          ...translation,
          name: get(data?.mixture?.translations, `${translation.lang}.name`),
          description: get(data?.mixture?.translations, `${translation.lang}.description`),
        };
      });
      form.setValues({
        name: data?.mixture?.name,
        description: data?.mixture?.description,
        grammage: data?.mixture?.grammage,
        type: data?.mixture?.type,
        translations: translations,
      });
    } else {
      form.setValues({
        name: data?.mixture?.name,
        type: data?.mixture?.type,
        description: data?.mixture?.description,
        grammage: data?.mixture?.grammage,
        translations: [
          { lang: 'pl', name: data?.mixture?.name, description: data?.mixture?.description },
          { lang: 'en', name: data?.mixture?.name, description: data?.mixture?.description },
          { lang: 'de', name: data?.mixture?.name, description: data?.mixture?.description },
          { lang: 'it', name: data?.mixture?.name, description: data?.mixture?.description },
          { lang: 'ru', name: data?.mixture?.name, description: data?.mixture?.description },
        ],
      });
    }
  }, [data?.mixture]);

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        description: formValues.description,
        grammage: formValues.grammage,
        type: formValues.type,
        translations: {
          pl: { name: '', description: '' },
          en: { name: '', description: '' },
          de: { name: '', description: '' },
          it: { name: '', description: '' },
          ru: { name: '', description: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
        body.translations[translation.lang].description = translation.description;
      });
      await api({}).patch(`/api/v3/admin/mixtures/${id}`, body);
      toast.success(translations.mixtures.mixtureUpdatedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!id);
    if (id) {
      refresh();
    }
    if (!id) {
      form.reset();
    }
  }, [id]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal opened={opened} onClose={handleClose} title={translations.mixtures.editMixture}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
            {translations.global.somethingWentWrong}
          </Notification>
        )}
        {!error && <MixtureForm form={form} />}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!!error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default Details;
