import moment from 'moment';
export const readableDateUtc = date => (date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-');

export const dateFromNowUtc = date => (date ? moment(date).fromNow() : '-');
export const readableDate = date => (date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-');

export const dateFromNow = date => (date ? moment(date).fromNow() : '-');

export const readableDateWithoutTime = date => (date ? moment(date).format('DD/MM/YYYY') : '-');

export function countDaysInRange(from, to) {
  const oneDay = 24 * 60 * 60 * 1000;

  const fromDate = new Date(Math.floor(new Date(from).getTime() / oneDay) * oneDay);
  const toDate = new Date(Math.ceil(new Date(to).getTime() / oneDay) * oneDay);

  const diffInDays = Math.round(Math.abs((fromDate.getTime() - toDate.getTime()) / oneDay));

  return diffInDays + 1;
}

export function getCurrentYearRange() {
  const date = new Date();
  const from = new Date(date.getFullYear(), 0, 1);
  const to = new Date(date.getFullYear(), 11, 31);
  from.setHours(0, 0, 0, 0);
  to.setHours(23, 59, 59, 9999);
  return { from, to };
}

export function getCurrentMonthRange() {
  const date = new Date();
  const from = new Date(date.getFullYear(), date.getMonth(), 1);
  const to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  from.setHours(0, 0, 0, 0);
  to.setHours(23, 59, 59, 9999);
  return { from, to };
}

export const getHoursAndMinutes = dateStr => {
  if (!dateStr) {
    return ':';
  } else {
    const dateObj = new Date(dateStr);
    const hours = dateObj.getHours();
    const hoursFormatted = hours < 10 ? `0${hours}` : hours;
    const minutes = dateObj.getMinutes();
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

    return `${hoursFormatted}:${minutesFormatted}`;
  }
};
