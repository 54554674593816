import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import {
  REPORTS_FETCH_PLACES,
  REPORTS_FETCH_REPORT,
} from '../../reducers/report/';

function* fetchUsers() {
  try {
    const { data } = yield apiService({}).get('/places');
    yield put({
      type: REPORTS_FETCH_PLACES.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REPORTS_FETCH_PLACES.FAILURE, payload: getError(err) });
  }
}
function* fetchReport(action) {
  try {
    const { data } = yield apiService({}).get('/reports/single', {
      params: action.payload,
    });
    yield put({
      type: REPORTS_FETCH_REPORT.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: REPORTS_FETCH_REPORT.FAILURE, payload: getError(err) });
  }
}

export default [
  function* () {
    yield takeEvery(REPORTS_FETCH_PLACES.REQUEST, fetchUsers);
  },
  function* () {
    yield takeEvery(REPORTS_FETCH_REPORT.REQUEST, fetchReport);
  },
];
