import React, { useCallback, useEffect, useState } from 'react';
import Button from 'shared/components/Button';
import { Badge, Skeleton, Grid, Breadcrumbs, Timeline, Text, Drawer, Modal } from '@mantine/core';
import api from 'shared/services/api';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useHistory, useParams } from 'react-router';
import { routes } from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import DeleteRecommendationModal from '../DeleteRecommendationModal';
import {
  getColorByRecommendationPriority,
  getColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import { filter, get, map, sortBy } from 'lodash';
import DetailsRow from './DetailsRow';
import { dateFromNow, readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { Link } from 'react-router-dom';
import AddCommentDrawer from '../AddCommentDrawer';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  recommendation: any;
  handleClose: any;
  show: boolean;
  oldData?: any;
  newData?: any;
}

const CompareHistoryModal = (props: Props) => {
  const translations = useTranslations();
  const { recommendation, show, handleClose, oldData, newData } = props;
  const changedFields = {};
  map(oldData, (value, key) => {
    // console.log(value, newData[key]);
    if (value !== newData[key]) {
      changedFields[key] = { old: value, new: newData[key] };
      return true;
    }
    return false;
  });
  // console.log(changedFields);
  return (
    <Modal opened={show} onClose={handleClose} title={translations.recommendations.changes}>
      <Grid>
        <Grid.Col span={12}>
          {changedFields.priority && (
            <DetailsRow label={translations.recommendations.priority}>
              <div className="d-flex align-items-center">
                <Badge color={getColorByRecommendationPriority(oldData.priority)}>
                  {oldData.priority}
                </Badge>
                <i className="fa fa-arrow-right"></i>
                <Badge color={getColorByRecommendationPriority(newData.priority)}>
                  {newData.priority}
                </Badge>
              </div>
            </DetailsRow>
          )}
          {changedFields.recommendationCategoryId && (
            <DetailsRow label={translations.recommendations.category}>
              <div className="d-flex align-items-center">
                {oldData.recommendationCategoryId
                  ? get(oldData, 'recommendationCategory.name')
                  : '-'}
                <i className="fa fa-arrow-right"></i>
                {newData.recommendationCategoryId ? get(recommendation, 'newData.name') : '-'}
              </div>
            </DetailsRow>
          )}
          {changedFields.status && (
            <DetailsRow label={translations.global.status}>
              <div className="d-flex align-items-center">
                <Badge color={getColorByRecommendationStatus(oldData.status)}>
                  {oldData.status}
                </Badge>
                <i className="fa fa-arrow-right"></i>
                <Badge color={getColorByRecommendationStatus(newData.status)}>
                  {newData.status}
                </Badge>
              </div>
            </DetailsRow>
          )}
          {(changedFields.ownerId || changedFields.ownerText) && (
            <DetailsRow label={translations.recommendations.owner}>
              <div className="d-flex align-items-center">
                {oldData.owner
                  ? `${oldData.owner.name} ${oldData.owner.surname}`
                  : oldData.ownerText}
                <i className="fa fa-arrow-right"></i>
                {newData.owner
                  ? `${newData.owner.name} ${newData.owner.surname}`
                  : newData.ownerText}
              </div>
            </DetailsRow>
          )}
          {changedFields.dueDate && (
            <DetailsRow label={translations.recommendations.dueDate}>
              <div className="d-flex align-items-center">
                {readableDateWithoutTime(oldData.dueDate)}
                <i className="fa fa-arrow-right"></i>
                {readableDateWithoutTime(newData.dueDate)}
              </div>
            </DetailsRow>
          )}
          {changedFields.comment && (
            <DetailsRow label={translations.recommendations.comment}>
              <div className="d-flex align-items-center">
                {oldData.comment}
                <i className="fa fa-arrow-right"></i>
                {newData.comment}
              </div>
            </DetailsRow>
          )}
          {changedFields.recommendation && (
            <DetailsRow label={translations.recommendations.recommendation}>
              <div className="d-flex align-items-center">
                {oldData.recommendation}
                <i className="fa fa-arrow-right"></i>
                {newData.recommendation}
              </div>
            </DetailsRow>
          )}
          {changedFields.deviceId && (
            <DetailsRow label={translations.global.device}>
              <div className="d-flex align-items-center">
                {oldData.deviceId ? get(oldData, 'device.uniqueId') : '-'}
                <i className="fa fa-arrow-right"></i>
                {newData.deviceId ? get(newData, 'device.uniqueId') : '-'}
              </div>
            </DetailsRow>
          )}
          {changedFields.deviceTypeId && (
            <DetailsRow label={translations.global.deviceType}>
              <div className="d-flex align-items-center">
                {oldData.deviceTypeId ? get(oldData, 'deviceType.name') : '-'}
                <i className="fa fa-arrow-right"></i>
                {newData.deviceTypeId ? get(newData, 'deviceType.name') : '-'}
              </div>
            </DetailsRow>
          )}

          {changedFields.mapId && (
            <DetailsRow label={translations.recommendations.location}>
              <div className="d-flex align-items-center">
                {oldData.mapId ? get(oldData, 'map.name') : '-'}
                <i className="fa fa-arrow-right"></i>
                {newData.mapId ? get(newData, 'map.name') : '-'}
              </div>
            </DetailsRow>
          )}
          {changedFields.zoneId && (
            <DetailsRow label={translations.zones.zone}>
              <div className="d-flex align-items-center">
                {oldData.zoneId ? get(oldData, 'zone.name') : '-'}
                <i className="fa fa-arrow-right"></i>
                {newData.zoneId ? get(newData, 'zone.name') : '-'}
              </div>
            </DetailsRow>
          )}
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default CompareHistoryModal;
