import React, { PureComponent, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchPlaces } from 'modules/Place/reducers/list';
import Button from 'shared/components/Button';
import { isAnyAdminByAuthState, isSuperAdminByAuthState } from 'shared/utils/user';
import { translationService } from 'shared/translations/translations';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Select, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Place } from 'shared/types/Place';
import { useFilterPlaces } from './hooks';
import { Search } from 'tabler-icons-react';
import { formatZonesToDataOptions } from '../../../Company/containers/Manage/Zones/helpers';

const List = () => {
  const form = useForm({ initialValues: { companyId: undefined, companyZoneUuid: undefined } });

  const [searchFilter, setSearchFilter] = useState(undefined);

  const history = useHistory();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Place[]>({
    initialValue: [],
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(`/places`, {
        params: {
          companyId: form.values.companyId,
          companyZoneUuid: form.values.companyZoneUuid,
        },
      }),
  });
  const {
    data: dataCompanies,
    isLoading: isLoadingCompanies,
    isLoaded: isLoadedCompanies,
  } = useFetch<any[]>({
    initialValue: [],
    fetchAction: () => apiService({}).get(`/companies`),
  });
  const goToCreate = () => {
    history.push(routes.places.create.path);
  };

  const goToEdit = id => () => {
    history.push(routes.places.manage.to(id));
  };

  const translations = translationService.getTranslations();
  const isAdmin = useSelector(state => isAnyAdminByAuthState(state.auth));
  const isSuperAdmin = useSelector(state => isSuperAdminByAuthState(state.auth));

  useEffect(() => {
    if (form.values.companyId) {
      setSearchFilter('');
      refresh();
    }
  }, [form.values]);

  useEffect(() => {
    if (isLoadedCompanies && dataCompanies[0]) {
      form.setValues({ companyId: String(dataCompanies[0].id) });
    }
  }, [isLoadedCompanies, dataCompanies]);

  const companiesList = useMemo(() => {
    const result = [];
    result.push(
      ...dataCompanies?.map(company => ({
        label: `${company.name}`,
        value: String(company.id),
        group: String(company.name),
      })),
    );
    dataCompanies?.forEach(c => {
      result.push(
        ...c.subCompanies.map(sc => ({
          label: `${sc.name}`,
          value: String(sc.id),
          group: String(c.name),
        })),
      );
    });
    return result;
  }, [dataCompanies]);

  const places = useFilterPlaces(data, searchFilter);
  const selectedCompany = dataCompanies.find(c => c.id === Number(form.values.companyId));
  const zones = selectedCompany?.zones || [];
  const zoneOptions = useMemo(() => {
    return formatZonesToDataOptions(zones, 0);
  }, [zones]);
  return (
    <div>
      <ListHeading title={translations.global.facilities}>
        {(isSuperAdmin || isAdmin) && (
          <Button onClick={goToCreate} type="primary">
            {translations.global.add}
          </Button>
        )}
      </ListHeading>
      <AppContent>
        <div className="row">
          <div className="col-md-12">
            <SimpleGrid cols={4} mb={20}>
              <Select
                name="companyId"
                label={translations.global.company}
                data={companiesList}
                {...form.getInputProps('companyId')}
                searchable
              />
              <Select
                name="companyZoneUuid"
                label={translations.companyZones.zone}
                data={zoneOptions}
                {...form.getInputProps('companyZoneUuid')}
                searchable
                clearable
              />
              <TextInput
                label={translations.places.searchPlace}
                placeholder={translations.places.searchPlace}
                icon={<Search size={14} />}
                onChange={event => setSearchFilter(event.currentTarget.value)}
                value={searchFilter}
              />
            </SimpleGrid>
            <Table isLoading={isLoading}>
              <Thead>
                <Tr>
                  <Th>{translations.global.name}</Th>
                  <Th>{translations.global.address}</Th>
                  <Th>{translations.global.company}</Th>
                  <Th>{translations.global.devicesCount}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length === 0 && (
                  <Tr>
                    <Td colSpan={4}>{translations.global.noData}</Td>
                  </Tr>
                )}
                {map(places, (place, index) => (
                  <Tr key={place.id} onClick={goToEdit(place.uuid)}>
                    <Td>{place.name}</Td>
                    <Td>{place.address}</Td>
                    <Td>{get(place, 'company.name')}</Td>
                    <Td>{place.devicesCount || place.devices.length}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
      </AppContent>
    </div>
  );
};

export default List;
