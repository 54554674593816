const requiredFields = ['deviceType', 'placeId'];

const validate = (values) => {
  let errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Uzupełnij pole';
    }
  });
  return errors;
};

export default validate;
