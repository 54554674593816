import { useSelector } from 'react-redux';
import { SUPER_ADMIN } from 'shared/constants/user';

export const useIsSuperAdmin = () => {
  // TODO: Type state
  // @ts-ignore
  const userType = useSelector(state => state.auth.data.userType);

  return userType === SUPER_ADMIN;
};
