import { useQuery } from '@tanstack/react-query';
import api from 'shared/services/api';
import { User } from 'shared/types/User';

export const useAllPlacesList = () => {
  return useQuery({
    queryKey: ['places', 'all'],
    queryFn: () => api({}).get(`/companies`),
    staleTime: Infinity,
    select: (response: any) => {
      if (!response.data) return [];
      const allPlaces =
        response.data?.flatMap(company => {
          const subCompanyPlaces =
            company.subCompanies?.flatMap(subCompany => {
              return [...subCompany.places];
            }) || [];
          return [...company.places, ...subCompanyPlaces];
        }) || [];

      return allPlaces;
    },
  });
};

export const usePlaceUsersQuery = (placeUuid?: string) => {
  return useQuery<{ users: User[] } | undefined>({
    queryKey: ['places', 'users', placeUuid],
    queryFn: ({ queryKey }) => {
      const placeUuid = queryKey[2];
      if (!placeUuid) return Promise.resolve(undefined);
      return api({}).get(`/places/${placeUuid}/users`);
    },
    staleTime: Infinity,
    select: (response: any) => {
      if (!response.data) return undefined;
      return response.data;
    },
  });
};

export interface HeatmapData {
  devices: {
    id: number;
    uuid: string;
    mapX: number | null;
    mapY: number | null;
    mapId: number | null;
    values: {
      deviceTypeObjectId: number | null;
      totalAmount: number;
      totalAmountPercentage: number;
    }[];
  }[];
  deviceTypeObjects: {
    id: number;
    uuid?: string;
    name: string;
  }[];
}

export const usePlaceHeatmapQuery = (placeUuid: string) => {
  return useQuery<{ data: HeatmapData }>({
    queryKey: ['places', 'heatmap', placeUuid],
    staleTime: Infinity,
    queryFn: () => api({}).get(`/places/${placeUuid}/maps/heatmap`),
  });
};

export const useSignedUrlMapQuery = (placeUuid: string, mapId: number) => {
  return useQuery<{
    data: {
      url: string;
    };
  }>({
    queryKey: ['places', 'signedUrlMap', placeUuid, mapId],
    queryFn: () => {
      return api({}).get<{ url: string }>(`places/${placeUuid}/maps/${mapId}/signed-url`);
    },
    staleTime: Infinity,
  });
};

export const usePlaceMapsQuery = (placeUuid: string) => {
  return useQuery({
    queryKey: ['places', 'maps', placeUuid],
    queryFn: () => {
      return api({}).get(`places/${placeUuid}/maps`);
    },
    staleTime: Infinity,
  });
};
