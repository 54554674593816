import { useMemo } from 'react';
import fuzzysort from 'fuzzysort';
import { Place } from 'shared/types/Place';

export const useFilterPlaces = (
  places: Place[],
  searchFilter?: string,
) => {
  return useMemo(() => {
    let filteredPlaces = places;
    if (searchFilter) {
      filteredPlaces = fuzzysort
        .go(searchFilter, filteredPlaces, { keys: ['name'] })
        .map(result => result.obj);
    }
    return filteredPlaces;
  }, [places, searchFilter]);
};