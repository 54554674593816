import React, { useEffect, useMemo, useRef, useState } from 'react';
import { API_URL } from 'shared/services/api';

import { Recommendation } from 'shared/types/recommendation';
import ListMapMarker from '../ListMapMarker';

interface Props {
  recommendations: Recommendation[];
  currentMap: any;
}

function getMeta(url, setMapSize) {
  const img = new Image();
  img.addEventListener('load', function () {
    setMapSize({ width: this.naturalWidth, height: this.naturalHeight });
  });
  img.src = url;
}

const ListMapDetails = (props: Props) => {
  const { recommendations, currentMap } = props;
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  const [renderMapWidth, setRenderMapWidth] = useState(0);
  const [renderMapHeight, setRenderMapHeight] = useState(100);
  const aspectRatio = mapSize.height / mapSize.width;
  const url = useMemo(
    () => (currentMap?.url ? `${API_URL}/files/${currentMap?.url}` : undefined),
    [currentMap],
  );

  useEffect(() => {
    if (url) {
      getMeta(url, setMapSize);
    }
  }, [url]);

  const imgr = useRef();

  const onLoad = () => {
    setRenderMapWidth(imgr?.current?.offsetWidth);
  };

  useEffect(() => {
    setRenderMapHeight(aspectRatio * renderMapWidth);
  }, [aspectRatio, setRenderMapHeight, renderMapWidth]);
  useEffect(() => {
    function handleResize() {
      onLoad();
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div style={{ position: 'relative', height: renderMapHeight, width: '100%' }}>
      {(recommendations || [])
        ?.filter(r => r.mapId === currentMap.id)
        .map(r => (
          <ListMapMarker
            recommendation={r}
            mapSize={mapSize}
            renderedMap={{ height: renderMapHeight, width: renderMapWidth }}
            markerPosition={{ x: Number(r.mapX), y: Number(r.mapY) }}
            key={r.id}
          />
        ))}
      <img
        src={url}
        style={{ width: '100%', height: renderMapHeight }}
        ref={imgr}
        onLoad={onLoad}
      />
    </div>
  );
};
export default ListMapDetails;
