import React from 'react';

import Details from './Details';

import List from './List';
import Manage from './Manage';
import { Route, Switch } from 'react-router';
import { routes } from '../../../shared/constants/routes';

function LoraGatewaysRoutes() {
  return (
    <>
      <Switch>
        <Route path={routes.lora.gateways.default.path} exact component={List} />
        <Route path={routes.lora.gateways.details.path} exact component={Details} />
        <Route path={routes.lora.gateways.create.path} exact component={Manage} />
        <Route path={routes.lora.gateways.edit.path} exact component={Manage} />
      </Switch>
    </>
  );
}

export default LoraGatewaysRoutes;
