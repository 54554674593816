import React, { useState } from 'react';
import { Space, Button, Modal } from '@mantine/core';
import api from 'shared/services/api';
import { useParams } from 'react-router';

import { readableDateWithoutTime } from 'shared/utils/date';
import { DatePicker } from '@mantine/dates';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  placeId;
}

const DownloadCsv = (_: Props) => {
  const translations = useTranslations();
  const date = new Date();
  const firstDay = new Date(new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0));
  const lastDay = new Date(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 59),
  );
  const { placeId } = useParams<{ placeId: string }>();
  const [opened, setOpened] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<[Date | null, Date | null]>([firstDay, lastDay]);
  const downloadCsv = async () => {
    try {
      setLoading(true);
      const { data } = await api({}).get(
        `/api/recommendations/csv?placeId=${placeId}&dateFrom=${value[0]}&dateTo=${value[1]}`,
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `zalecenia_${readableDateWithoutTime(new Date())}.csv`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={translations.recommendations.downloadCsv}>
        <DatePicker type="range" value={value} onChange={setValue} />
        <Space h={30}></Space>
        {readableDateWithoutTime(value[0])} - {readableDateWithoutTime(value[1])}
        <Space h={30}></Space>
        <Button onClick={downloadCsv} loading={isLoading} disabled={!value[0] || !value[1]}>
          {translations.global.download}
        </Button>
        {/* Modal content */}
      </Modal>
      <Button onClick={() => setOpened(true)} variant="outline">
        {translations.recommendations.downloadCsv}
      </Button>
    </div>
  );
};
export default DownloadCsv;
