import update from 'immutability-helper';
import store from 'store';
import get from 'lodash/get';
import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';
import { STORAGE_AUTH_KEY } from 'shared/constants/auth';
import { AUTH_PERFORM_LOGIN, AUTH_PERFORM_LOGOUT } from '../login';
import jwt from 'jwt-decode';

export const FETCH_AUTH_USER = createRequestTypes('auth/FETCH_AUTH_USER');
export const SET_CLIENT_ID = 'auth/SET_CLIENT_ID';

export const performLogin = () => ({
  type: FETCH_AUTH_USER.REQUEST,
});
export const setClientId = ({ clientId, isDomainClient }) => ({
  type: SET_CLIENT_ID,
  payload: { clientId, isDomainClient },
});
const auth = store.get(STORAGE_AUTH_KEY);
const token = get(auth, 'token');
const decoded = token ? jwt(token) : null;

const initState = {
  enterprise: {
    clientId: undefined,
    isDomainClient: false,
  },
  token: get(auth, 'token') || undefined,
  refresh_token: get(auth, 'token') || undefined,
  user: {
    fetch: loadingStates,
    data: decoded,
    response: '',
  },
  userType: get(decoded, 'type'),
};

export default createReducer(initState, {
  [SET_CLIENT_ID]: (state, action) => {
    return update(state, {
      $merge: {
        enterprise: {
          clientId: action.payload.clientId,
          isDomainClient: action.payload.isDomainClient,
        },
      },
    });
  },
  [AUTH_PERFORM_LOGIN.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        token: action.payload.token,
        // role: action.payload.role,
        // refresh_token: action.payload.refresh_token,
        user: {
          fetch: setLoadingStates({ isLoaded: true }),
          data: jwt(action.payload.token),
        },
        userType: get(jwt(action.payload.token), 'type'),
      },
    });
  },
  [FETCH_AUTH_USER.REQUEST]: state => {
    return update(state, {
      $merge: {
        user: { fetch: setLoadingStates({ isLoading: true }) },
      },
    });
  },
  [FETCH_AUTH_USER.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        user: {
          fetch: setLoadingStates({ isLoaded: true }),
          data: action.payload,
        },
      },
    });
  },
  [FETCH_AUTH_USER.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        user: {
          fetch: setLoadingStates({ hasError: true }),
          response: action.payload.response,
        },
      },
    });
  },
  [AUTH_PERFORM_LOGOUT.REQUEST]: state => {
    return update(state, {
      $set: {
        ...initState,
        token: undefined,
        refresh_token: undefined,
      },
    });
  },
});
