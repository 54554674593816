import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import { get } from 'lodash';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  NumberInput,
  Select,
  Textarea,
} from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';
import { Observation } from 'shared/types/observations';

interface Props {
  deviceId?: string | number;
  observationId?: string | number;
  deviceTypeId?: string | number;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  observation: Observation;
}
interface ObservationsData {
  observations: { name: string; id: string; deviceTypeId: string }[];
}
const EditObservationModal = (props: Props) => {
  const { deviceId, observationId, refreshParent, onClose, deviceTypeId } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { observation: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/v3/devices/${deviceId}/observations/${observationId}`),
  });
  const {
    data: observationsData,
    refresh: refreshObservations,
    isLoaded: isObservationsLoaded,
    isLoading: isObservationsLoading,
    // data, isLoading, isLoaded, error, refresh
  } = useFetch<ObservationsData>({
    shouldNotFetchAtStart: true,
    initialValue: { observations: [] },
    fetchAction: () => api({}).get(`/api/v3/device-types/${deviceTypeId}/observations`),
  });

  const form = useForm({
    initialValues: {
      note: undefined,
      deviceTypeObservationId: undefined,
      amount: undefined,
    },

    validate: {},
  });
  useEffect(() => {
    form.setValues({
      deviceTypeObservationId: String(data.observation?.deviceTypeObservationId),
      note: data?.observation?.note,
      amount: data?.observation?.amount,
    });
  }, [data?.observation]);

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        amount: formValues.amount,
        note: formValues.note,
        // deviceTypeObservationId: formValues.deviceTypeObservationId,
        data: data.observation.data
          ? {
              ...data,
            }
          : data.observation.data,
      };

      // if (data.observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
      //   body.data.amountPercentage = formValues.amount;
      // }
      // if (data.observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
      //   body.data.totalMixtures = formValues.amount;
      // }

      await api({}).patch(`/api/v3/devices/${deviceId}/observations/${observationId}`, body);
      toast.success(translations.devices.editObservationSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!observationId);
    if (observationId) {
      refresh();
      refreshObservations();
    }
    if (!observationId) {
      form.reset();
    }
  }, [observationId]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  const observationsSelectOptions = observationsData.observations?.map(observation => ({
    label: observation.name,
    value: String(observation.id),
  }));
  return (
    <Modal opened={opened} onClose={handleClose} title={translations.devices.editObservationTitle}>
      <LoadingOverlay visible={isLoading || isObservationsLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
            {translations.global.somethingWentWrong}
          </Notification>
        )}
        {!error && isLoaded && isObservationsLoaded && (
          <>
            <Select
              label={translations.devices.observation}
              name="deviceTypeObservationId"
              disabled
              data={observationsSelectOptions}
              defaultValue={String(data.observation?.deviceTypeObservationId)}
              required
              mb={20}
              {...form.getInputProps('deviceTypeObservationId')}
            />
            <Textarea
              label={translations.devices.note}
              required
              name="note"
              mb={20}
              {...form.getInputProps('note')}
            />
            <NumberInput
              label={translations.devices.amount}
              placeholder="1"
              min={0}
              required
              name="amount"
              mb={20}
              {...form.getInputProps('amount')}
            />
          </>
        )}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!!error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditObservationModal;
