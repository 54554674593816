import React, { useState, useEffect } from 'react';

import Create from './../Create';
import { ActionIcon, Group, LoadingOverlay, Text } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Printer } from 'tabler-icons-react';
import AppContent from 'shared/components/AppContent';
import ListHeading from 'shared/components/ListHeading';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../shared/components/Table';
import { readableDate } from '../../../shared/utils/date';
import { useHistory } from 'react-router';
import { routes } from '../../../shared/constants/routes';
import { selectIsSuperAdmin } from '../../../shared/utils/user';

function QRCodesList() {
  const translations = useTranslations();
  const isSuperAdmin = selectIsSuperAdmin();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    batches: any[];
  }>({
    initialValue: { batches: [] },
    fetchAction: () => apiService({}).get(`/api/qrcodes/batches`),
  });
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>();
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | undefined>();
  const handleRefresh = () => {
    refresh();
  };
  const handleModalClose = () => {
    setSelectedEditId(undefined);
    setSelectedDeleteId(undefined);
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data.batches);
  }, [data.batches]);
  const history = useHistory();

  return (
    <div style={{ position: 'relative' }}>
      <ListHeading title={translations.qrCodes.title}>
        <Create refreshParent={handleRefresh} onClose={handleModalClose} />
      </ListHeading>
      <AppContent>
        <LoadingOverlay visible={isLoading} />
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>{translations.global.name}</Th>
              {isSuperAdmin && <Th>{translations.global.company}</Th>}
              <Th>{translations.global.utilized}</Th>
              <Th>{translations.global.createdAt}</Th>
              <Th>{translations.global.user}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.batches?.map(batch => {
              return (
                <Tr key={batch.id}>
                  <Td>{batch.name}</Td>
                  {isSuperAdmin && <Td>{batch.company?.name}</Td>}
                  <Td>
                    {batch.usedAmount} / {batch.amount}
                  </Td>
                  <Td>{readableDate(batch.createdAt)}</Td>
                  <Td>{batch.user ? `${batch.user?.name} ${batch.user.surname}` : '-'}</Td>
                  <Td>{batch.used}</Td>
                  <Td>
                    <Group ml="auto">
                      <ActionIcon
                        variant="transparent"
                        onClick={() => {
                          history.push(routes.qrCodes.details.to(batch.id));
                        }}>
                        <Printer size={16} />
                      </ActionIcon>
                    </Group>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
}

export default QRCodesList;
