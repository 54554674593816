import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';

import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Group, Modal, Notification } from '@mantine/core';
import { X as IconX } from 'tabler-icons-react';

interface Props {
  id?: string;
  refreshParent: () => any;
  onClose: () => any;
}

const DeleteQuestionModal = (props: Props) => {
  const { id, refreshParent, onClose } = props;
  const translations = useTranslations();

  const [isSaving, setSaving] = useState(false);
  const handleClick = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).delete(`/api/visual-inspection/questions/${id}`);
      toast.success(translations.visualInspection.questionDeletedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!id);
  }, [id]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={translations.visualInspection.deleteQuestion}>
      {translations.visualInspection.questionDeleteDescription}
      <Group position="right" mt={20}>
        <Button color="primary" loading={isSaving} type="submit" onClick={handleClick}>
          {translations.global.delete}
        </Button>
        <Button variant="outline" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteQuestionModal;
