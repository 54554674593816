import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AlertCircle } from 'tabler-icons-react';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';

import api from 'shared/services/api';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import { DeviceTypeObservationType } from 'shared/types/deviceType';
import { SUPER_ADMIN, USER, USER_CONTROLLER } from 'shared/constants/user';

import LimitForm from '../LimitForm';

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  deviceTypeId: string | number;
}

interface DataLimits {
  limits: any[];
}

interface Data {
  deviceTypeObjects: any[];
  deviceTypeObservations: any[];
}

const EditLimitModal = (props: Props) => {
  const { refreshParent, onClose, placeId, deviceTypeId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const userType = useSelector(state => state.auth.data.userType);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<DataLimits>({
    initialValue: { limits: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/places/${placeId}/device-types/${deviceTypeId}/limits`),
  });
  const {
    data: deviceTypeData,
    refresh: fetchDeviceType,
    isLoaded: isLoadedDeviceTypes,
    isLoading: isLoadingDeviceTypes,
  } = useFetch<Data>({
    initialValue: {},
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/device-types/${deviceTypeId}`),
  });

  const form = useForm({
    initialValues: {
      rootLimitId: undefined,
      limit: 0,
      objects: [],
      observations: [],
    },
    // schema: zodResolver(schema),
  });

  useEffect(() => {
    if (deviceTypeData.id) {
      const objects = deviceTypeData.deviceTypeObjects.map(object => {
        return {
          name: object.name,
          deviceTypeObjectId: object.id,
          limit: 0,
          objectCategory: 'NORMAL',
          type: 'OBJECT',
        };
      });
      const observations = deviceTypeData.deviceTypeObservations
        ?.filter(dto => dto.type === DeviceTypeObservationType.BAIT_COLLECT)
        ?.map(observation => {
          return {
            name: observation.name,
            deviceTypeObservationId: observation.id,
            limit: 0,
            type: 'OBSERVATION',
          };
        });
      refresh();
      form.setValues({
        limit: 0,
        rootLimitId: undefined,
        objects: objects,
        observations: observations,
      });
    }
  }, [deviceTypeData]);

  useEffect(() => {
    if (data.limits?.length > 0) {
      const newValues = form.values.objects.map(o => {
        const limitFound = data.limits.find(
          limit =>
            limit.deviceTypeObjectId === o.deviceTypeObjectId &&
            limit.deviceTypeId === deviceTypeId &&
            o.type === 'OBJECT',
        );
        if (!limitFound) {
          return o;
        }

        return {
          ...o,
          id: limitFound.id,
          limit: limitFound.limit,
          type: limitFound.type,
          objectCategory: limitFound.objectCategory,
        };
      });
      const newValuesObservations = form.values.observations.map(o => {
        const limitFound = data.limits.find(
          limit =>
            limit.deviceTypeObservationId === o.deviceTypeObservationId &&
            limit.deviceTypeId === deviceTypeId &&
            o.type === 'OBSERVATION',
        );
        if (!limitFound) {
          return o;
        }

        return {
          ...o,
          id: limitFound.id,
          limit: limitFound.limit,
          type: limitFound.type,
        };
      });
      const deviceTypeLimit = data.limits.find(limit => limit.type === 'DEVICE_TYPE');
      let deviceTypeLimitObject = {};
      if (deviceTypeLimit) {
        deviceTypeLimitObject = { rootLimitId: deviceTypeLimit.id, limit: deviceTypeLimit.limit };
      }
      form.setValues({
        ...form.values,
        ...deviceTypeLimitObject,
        objects: newValues,
        observations: newValuesObservations,
      });
    }
  }, [data]);

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      await api({}).put(`/api/places/${placeId}/device-types/${deviceTypeId}/limits`, formValues);
      toast.success(translations.limits.limitUpdatedSuccess);
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!deviceTypeId);
    if (deviceTypeId) {
      fetchDeviceType();
    }
  }, [deviceTypeId]);
  const isLoadingCombined = isLoading || isLoadingDeviceTypes;
  const isLoadedCombined = isLoaded && isLoadedDeviceTypes;
  const readOnly = [USER_CONTROLLER, USER, SUPER_ADMIN].includes(userType);

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.limits.title} size="lg">
      <LoadingOverlay visible={isLoadingCombined} />
      {readOnly && <Alert color="red">{translations.global.onlyAdminCanEdit}</Alert>}
      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {isLoadedCombined && !readOnly && (
          <LimitForm form={form} data={deviceTypeData} readOnly={readOnly} />
        )}
        {isLoadedCombined && !readOnly && (
          <Alert
            icon={<AlertCircle size={16} />}
            title={translations.global.information}
            color="blue">
            {translations.limits.informationDetails}
          </Alert>
        )}
        <Group position="right" mt={20}>
          {!readOnly && (
            <Button color="primary" loading={isSaving} type="submit" disabled={error || !isLoaded}>
              {translations.global.edit}
            </Button>
          )}
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditLimitModal;
