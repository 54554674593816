import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import store from 'store';
import apiService, { getError } from 'shared/services/api';
import { STORAGE_AUTH_KEY } from 'shared/constants/auth';
import {
  AUTH_PERFORM_LOGIN,
  AUTH_PERFORM_LOGOUT,
  AUTH_PERFORM_REFRESH_TOKEN,
} from '../../reducers/login/';
import { routes } from '../../../../shared/constants/routes';
import * as Sentry from '@sentry/browser';

function* performLogout() {
  try {
    store.remove(STORAGE_AUTH_KEY);
    yield put({ type: AUTH_PERFORM_LOGOUT.SUCCESS });
  } catch (err) {
    yield put({ type: AUTH_PERFORM_LOGOUT.FAILURE, payload: getError(err) });
  }
}

function* performLogoutSuccess() {
  yield put(push(routes.auth.login.path));
}

function* performLogin(action) {
  const { email, password } = action.payload;
  try {
    const body = {
      email: String(email).trim(),
      password: String(password).trim(),
    };
    const response = yield apiService({}).post('/auth/login', body);
    const data = response.data;
    store.set(STORAGE_AUTH_KEY, data);
    yield put({ type: AUTH_PERFORM_LOGIN.SUCCESS, payload: data });
    yield put(push(routes.app.default.path));
  } catch (err) {
    Sentry.captureException(err);
    /* eslint-disable no-console */
    console.error(err)
    yield put({ type: AUTH_PERFORM_LOGIN.FAILURE, payload: getError(err) });
  }
}

function* performRefreshToken(action) {
  try {
    const requestData = new FormData();
    requestData.append('grant_type', 'refresh_token');
    requestData.append('refresh_token', action.payload.refreshToken);
    requestData.append('scope', 'any');
    const basicToken = btoa(`test:test`);
    const { data } = yield apiService({
      baseUrl: process.env.REACT_APP_OAUTH_URL,
      headers: {
        Authorization: `Basic ${basicToken}`,
      },
    }).post('', requestData);

    if (action.payload.remember) {
      store.set(STORAGE_AUTH_KEY, data);
    }
    yield put({ type: AUTH_PERFORM_REFRESH_TOKEN.SUCCESS, payload: data });
    yield put(push(routes.app.default.path));
  } catch (err) {
    yield put({
      type: AUTH_PERFORM_REFRESH_TOKEN.FAILURE,
      payload: getError(err),
    });
  }
}

function* loginSaga() {
  yield takeEvery(AUTH_PERFORM_LOGIN.REQUEST, performLogin);
}

function* logoutSaga() {
  yield takeEvery(AUTH_PERFORM_LOGOUT.REQUEST, performLogout);
}

function* logoutSuccessSaga() {
  yield takeEvery(AUTH_PERFORM_LOGOUT.SUCCESS, performLogoutSuccess);
}

function* refreshTokenSaga() {
  yield takeEvery(AUTH_PERFORM_REFRESH_TOKEN.REQUEST, performRefreshToken);
}

export default [loginSaga, logoutSaga, logoutSuccessSaga, refreshTokenSaga];
