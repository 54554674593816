import React, { useState, useEffect } from 'react';

import { ActionIcon, Group, LoadingOverlay, Text } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Pencil, Printer, Trash } from 'tabler-icons-react';
import AppContent from 'shared/components/AppContent';
import ListHeading from 'shared/components/ListHeading';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import { readableDate } from '../../../../shared/utils/date';
import CreateZoneModal from './CreateZoneModal';
import DeleteZoneModal from './DeleteZoneModal';
import EditZoneModal from './EditZoneModal';

interface Props {
  placeId: string | number;
}

function Zones({ placeId }: Props) {
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    zones: any[];
  }>({
    initialValue: { zones: [] },
    fetchAction: () => apiService({}).get(`/api/places/${placeId}/zones`),
  });
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>();
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | undefined>();
  const handleRefresh = () => {
    refresh();
  };
  const handleModalClose = () => {
    setSelectedEditId(undefined);
    setSelectedDeleteId(undefined);
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data.zones);
  }, [data.zones]);

  return (
    <div style={{ position: 'relative' }}>
      <DeleteZoneModal
        placeId={placeId}
        zoneId={selectedDeleteId}
        onClose={handleModalClose}
        refreshParent={handleRefresh}
      />
      <EditZoneModal
        placeId={placeId}
        zoneId={selectedEditId}
        onClose={handleModalClose}
        refreshParent={handleRefresh}
      />
      <ListHeading title={translations.zones.title}>
        <CreateZoneModal
          refreshParent={handleRefresh}
          onClose={handleModalClose}
          placeId={placeId}
        />
      </ListHeading>
      <AppContent>
        <LoadingOverlay visible={isLoading} />
        <Table isLoading={isLoading}>
          <Thead>
            <Tr>
              <Th>{translations.global.name}</Th>
              <Th>{translations.global.description}</Th>
              <Th>{translations.global.createdAt}</Th>
              <Th>{translations.global.user}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.zones?.map(zone => {
              return (
                <Tr key={zone.id}>
                  <Td>{zone.name}</Td>
                  <Td>{zone.description}</Td>
                  <Td>{readableDate(zone.createdAt)}</Td>
                  <Td>{zone.user ? `${zone.user?.name} ${zone.user.surname}` : '-'}</Td>
                  <Td>
                    <Group ml="auto">
                      <Group ml="auto">
                        <ActionIcon
                          variant="transparent"
                          onClick={() => {
                            setSelectedEditId(zone.id);
                          }}>
                          <Pencil size={16} />
                        </ActionIcon>
                        <ActionIcon
                          variant="transparent"
                          onClick={() => {
                            setSelectedDeleteId(zone.id);
                          }}>
                          <Trash size={16} />
                        </ActionIcon>
                      </Group>
                    </Group>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </AppContent>
    </div>
  );
}

export default Zones;
