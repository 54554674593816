import React, { useEffect, useState } from 'react';
import { CRS, latLng, latLngBounds } from 'leaflet';
import { MapContainer } from 'react-leaflet';
import api from 'shared/services/api';
import SetLocationMap from '../SetLocationMap';

interface Props {
  mapId: any;
  placeId: any;
  map: any;
  position: { x?: string; y?: string };
  setValues: (values: any) => void;
}

const getSignedUrlMap = async (placeMapId, placeId, setSignedUrlMap) => {
  const {
    data: { url },
  } = await api({}).get(`places/${placeId}/maps/${placeMapId}/signed-url`);
  setSignedUrlMap(url);
};

function getMeta(url, setMapSize, setBounds) {
  const img = new Image();
  img.addEventListener('load', function () {
    setMapSize({ width: this.naturalWidth, height: this.naturalHeight });
    // setBounds(latLngBounds([[0, 0], latLng(this.naturalHeight, this.naturalWidth)]));
    const asd = latLng(this.naturalWidth, this.naturalHeight);
    setBounds(
      latLngBounds([[asd.lng * -1, asd.lat * -1], latLng(this.naturalHeight, this.naturalWidth)]),
    );
  });
  img.src = url;
}

const SetLocation = (props: Props) => {
  const { setValues, position, mapId } = props;
  const [signedUrlMap, setSignedUrlMap] = useState();
  useEffect(() => {
    if (!props.mapId) {
      return;
    }
    getSignedUrlMap(props.mapId, props.placeId, setSignedUrlMap);
  }, [mapId, props.placeId, getSignedUrlMap]);

  const [map, setMap] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number | undefined>();
  const [deleteDeviceFromMap, setDeleteDeviceFromMap] = useState<undefined | number>();
  const [deleteMap, setDeleteMap] = useState<undefined | number>();
  const [bounds, setBounds] = useState(
    latLngBounds([
      [0, 0],
      [0, 0],
    ]),
  );
  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (signedUrlMap) {
      getMeta(signedUrlMap, setMapSize, setBounds);
    }
  }, [signedUrlMap]);

  return (
    <>
      <div>
        {mapId && (
          <MapContainer
            whenCreated={setMap}
            center={bounds.getCenter()}
            scrollWheelZoom={false}
            style={{
              width: '100%',
              height: 732,
            }}
            boxZoom={true}
            keyboard={false}
            doubleClickZoom={false}
            zoom={-3}
            maxZoom={3}
            minZoom={-8}
            // maxZoom={1}
            // minZoom={-8}
            zoomControl={true}
            crs={CRS.Simple}
            touchZoom={false}
            tap={false}>
            <SetLocationMap
              bounds={bounds}
              signedUrlMap={signedUrlMap}
              setValues={setValues}
              position={position}
            />
          </MapContainer>
        )}
      </div>
    </>
  );
};

export default SetLocation;
