import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import map from 'lodash/map';
import './styles.scss';

class FieldOptions extends PureComponent {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.refs.wrapper &&
      !this.refs.wrapper.contains(event.target) &&
      this.props.isOpen &&
      this.props.onClose
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.props.onClose(event);
    }
  };

  render() {
    const { dataSource, onSelect, isOpen, currentValue, name } = this.props;
    const optionsClasses = classnames('FieldOptions', {
      'FieldOptions--active': isOpen
    });

    return (
      <div className={optionsClasses} ref="wrapper">
        {map(dataSource, option => (
          <button
            type="button"
            className={classnames('FieldOptions__option', {
              'FieldOptions__option--active': option.value === currentValue
            })}
            key={`${name}-${option.value}`}
            onClick={onSelect(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  }
}

FieldOptions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  dataSource: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  currentValue: PropTypes.any.isRequired
};

export default FieldOptions;
