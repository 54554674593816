import React, { useEffect, useState } from 'react';
import { Button, Group, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import apiService from '../../../../../shared/services/api';
import AddCompanyZoneForm from './AddCompanyZoneForm';
import { useTranslations } from 'shared/translations/useTranslations';
import { CompanyZone } from './types';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
  zones: CompanyZone[];
}

const AddCompanyZoneModal = (props: Props) => {
  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      validUntil: undefined,
      devicesLimit: undefined,
      usersLimit: undefined,
      active: 'true',
    },
  });
  const { onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState<any>();

  useEffect(() => {
    if (!props.opened) {
      form.reset();
    }
  }, [props.opened]);

  const handleAdd = async formValues => {
    try {
      setError(undefined);
      setSaving(true);
      const { data } = await apiService({}).post(`/companies/${props.companyId}/zones`, {
        ...formValues,
      });
      form.reset();
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e?.response?.data?.code);
    } finally {
      setSaving(false);
    }
  };

  const translations = useTranslations();
  return (
    <Modal opened={opened} onClose={onClose} title={translations.companyZones.addZone}>
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanyZoneForm form={form} zones={props.zones} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            {translations.global.add}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default AddCompanyZoneModal;
