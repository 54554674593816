import React, { useMemo } from 'react';
import { Card, Group, Text, SimpleGrid, Button } from '@mantine/core';
import { Doughnut, Line } from 'react-chartjs-2';
import { map } from 'lodash';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslations } from 'shared/translations/useTranslations';
import { htmlLegendPlugin } from 'shared/chartjs/htmlLegend';

import {
  getChartColorByRecommendationPriority,
  getChartColorByRecommendationStatus,
} from 'shared/utils/recommendation';
import {
  getDeviceTypesData,
  getLocationData,
  getOwnerData,
  getPlaceData,
  getPrioritiesData,
  getRecommendationsByDate,
  getStatusesData,
} from './helpers';
import './styles.scss';
interface RecomendationCategory {
  id?: string;
  name: string;
}

interface Props {
  placesData: any;
  data: any;
  deviceTypesData: any;
  formValues: any;
  cols;
  placeId;
}

const StatisticsByPlace = (props: Props) => {
  const translations = useTranslations();
  const { placesData, data, deviceTypesData, formValues, placeId, cols } = props;
  const values = { ...formValues, placeId };
  // data.recommendations
  const byPlace = useMemo(
    () => getPlaceData(placesData, data?.recommendations),
    [placesData, data.recommendations],
  );
  const byPriority = useMemo(
    () => getPrioritiesData(data?.recommendations, values),
    [data.recommendations, values],
  );
  const byDate = useMemo(
    () => getRecommendationsByDate(data?.recommendations, values),
    [data.recommendations, values],
  );
  const byStatus = useMemo(
    () => getStatusesData(data?.recommendations, values),
    [data.recommendations, values],
  );
  const byOwner = useMemo(
    () => getOwnerData(data?.recommendations, values),
    [data.recommendations, values],
  );
  const byLocation = useMemo(
    () => getLocationData(data?.recommendations, values),
    [data.recommendations, values],
  );
  const byDeviceTypes = useMemo(
    () => getDeviceTypesData(deviceTypesData, data?.recommendations, values),
    [deviceTypesData, data.recommendations, values],
  );

  const handleStatisticsByDateFullScreen = useFullScreenHandle();
  const handleStatusFullScreen = useFullScreenHandle();
  const handleDeviceTypeFullScreen = useFullScreenHandle();
  const handlePriorityFullScreen = useFullScreenHandle();
  const handleOwnerFullScreen = useFullScreenHandle();
  const handleLocationFullScreen = useFullScreenHandle();

  return (
    <div>
      <>
        <h3>{placesData?.find(place => String(place.id) === String(placeId))?.name}</h3>
        <SimpleGrid cols={cols} spacing="lg">
          <FullScreen handle={handleStatisticsByDateFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handleStatisticsByDateFullScreen.active
                    ? handleStatisticsByDateFullScreen.enter
                    : handleStatisticsByDateFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handleStatisticsByDateFullScreen.active && <i className="fas fa-expand" />}
                {handleStatisticsByDateFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="Card">
              <div id="legend-container-1" />
              <Card.Section>
                <Line
                  width={100}
                  height={100}
                  options={{
                    scales: {
                      yAxes: {
                        ticks: {
                          stepSize: 1,
                        },
                      },
                    },
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-1',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                  data={{
                    labels: [...Object.keys(byDate)],
                    datasets: [
                      {
                        label: 'Zalecenia',
                        data: map(byDate, (value, key) => ({ x: key, y: value })),
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                      },
                    ],
                  }}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.recommendations.statisticsByDate}</Text>
              </Group>
            </Card>
          </FullScreen>
          <FullScreen handle={handlePriorityFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handlePriorityFullScreen.active
                    ? handlePriorityFullScreen.enter
                    : handlePriorityFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handlePriorityFullScreen.active && <i className="fas fa-expand" />}
                {handlePriorityFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="Card">
              <div id="legend-container-2" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  data={{
                    labels: [...Object.keys(byPriority)],
                    datasets: [
                      {
                        label: 'Priorytet',
                        data: [...Object.values(byPriority)],
                        color: '#666',
                        backgroundColor: [
                          ...Object.keys(byPriority).map(getChartColorByRecommendationPriority),
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-2',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.recommendations.priority}</Text>
              </Group>
            </Card>
          </FullScreen>
          <FullScreen handle={handleStatusFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handleStatusFullScreen.active
                    ? handleStatusFullScreen.enter
                    : handleStatusFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handleStatusFullScreen.active && <i className="fas fa-expand" />}
                {handleStatusFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="Card">
              <div id="legend-container-3" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  data={{
                    labels: [...Object.keys(byStatus)],
                    datasets: [
                      {
                        label: 'Status',
                        data: [...Object.values(byStatus)],
                        backgroundColor: [
                          ...Object.keys(byStatus).map(getChartColorByRecommendationStatus),
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-3',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.global.status}</Text>
              </Group>
            </Card>
          </FullScreen>
          <FullScreen handle={handleDeviceTypeFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handleDeviceTypeFullScreen.active
                    ? handleDeviceTypeFullScreen.enter
                    : handleDeviceTypeFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handleDeviceTypeFullScreen.active && <i className="fas fa-expand" />}
                {handleDeviceTypeFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="§Card">
              <div id="legend-container-4" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  data={{
                    labels: [...Object.keys(byDeviceTypes)],
                    datasets: [
                      {
                        label: 'Status',
                        data: [...Object.values(byDeviceTypes).map(v => v.value)],
                        backgroundColor: [
                          ...Object.values(byDeviceTypes).map(v => v.color),
                          // ...Object.keys(byDeviceTypes).map(getChartColorByRecommendationStatus),
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-4',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.global.deviceType}</Text>
              </Group>
            </Card>
          </FullScreen>
          <FullScreen handle={handleOwnerFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handleOwnerFullScreen.active
                    ? handleOwnerFullScreen.enter
                    : handleOwnerFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handleOwnerFullScreen.active && <i className="fas fa-expand" />}
                {handleOwnerFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="Card">
              <div id="legend-container-5" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  options={{
                    cutout: 0,
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-5',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                  data={{
                    labels: [...Object.keys(byOwner)],
                    datasets: [
                      {
                        label: translations.recommendations.owner,
                        data: [...Object.values(byOwner)],
                        backgroundColor: [
                          'rgba(183, 240, 255, 1)',
                          'rgba(213, 240, 255, 1)',
                          'rgba(243, 240, 255, 1)',
                          'rgba(235, 251, 238, 1)',
                          'rgba(248, 249, 250, 1)',
                          'rgba(255, 249, 219, 1)',
                        ],
                      },
                    ],
                  }}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.recommendations.owner}</Text>
              </Group>
            </Card>
          </FullScreen>
          <FullScreen handle={handleLocationFullScreen} className={'Fullscreen'}>
            <div className="Fullscreen__title--end">
              <Button
                onClick={
                  !handleLocationFullScreen.active
                    ? handleLocationFullScreen.enter
                    : handleLocationFullScreen.exit
                }
                size="small"
                variant="outline">
                {!handleLocationFullScreen.active && <i className="fas fa-expand" />}
                {handleLocationFullScreen.active && <i className="fas fa-compress" />}
              </Button>
            </div>
            <Card shadow="sm" p="lg" className="Card">
              <div id="legend-container-6" />
              <Card.Section>
                <Doughnut
                  width={100}
                  height={100}
                  data={{
                    labels: [...Object.keys(byLocation)],
                    datasets: [
                      {
                        data: [...Object.values(byLocation)],
                        backgroundColor: [
                          'rgba(183, 240, 255, 1)',
                          'rgba(213, 240, 255, 1)',
                          'rgba(243, 240, 255, 1)',
                          'rgba(235, 251, 238, 1)',
                          'rgba(248, 249, 250, 1)',
                          'rgba(255, 249, 219, 1)',
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      htmlLegend: {
                        containerID: 'legend-container-6',
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  plugins={[htmlLegendPlugin]}
                />
              </Card.Section>

              <Group position="apart">
                <Text weight={500}>{translations.recommendations.location}</Text>
              </Group>
            </Card>
          </FullScreen>
        </SimpleGrid>
        <SimpleGrid cols={3} spacing="lg"></SimpleGrid>
      </>
    </div>
  );
};
export default StatisticsByPlace;
