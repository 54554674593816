import React, { useCallback, useState } from 'react';
import { Button, Flex, LoadingOverlay, Menu, Title } from '@mantine/core';
import { useHistory } from 'react-router';
import { get, map } from 'lodash';
import { Dots } from 'tabler-icons-react';

import { routes } from 'shared/constants/routes';
import { useTranslations } from 'shared/translations/useTranslations';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Intervention } from 'shared/types/Intervention';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { selectIsControllerByAuthSate } from 'shared/utils/user';

import { InterventionsListProps } from './types';
import { toast } from 'react-toastify';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { Link } from 'react-router-dom';

const List = (props: InterventionsListProps) => {
  const { place } = props;
  const history = useHistory();
  const translations = useTranslations();
  const placeUuid = place.uuid;
  const goToCreateIntervention = () => {
    history.push(routes.places.interventions.create.to(place.id, place.uuid));
  };

  const goToEditIntervention = (interventionUuid: string) => () => {
    history.push(routes.places.interventions.edit.to(place.id, place.uuid, interventionUuid));
  };

  const {
    data: interventions,
    isLoading,
    refresh: refreshInterventions,
  } = useFetch<{ interventions: Intervention[] }>({
    fetchAction: () => apiService({}).get(`/api/places/${placeUuid}/interventions`),
    initialValue: { interventions: [] },
    shouldNotFetchAtStart: false,
    fetchActionDeps: [placeUuid],
  });

  const isController = selectIsControllerByAuthSate();
  const [isDeletingIntervention, setIsDeletingIntervention] = useState(false);

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const onClickDelete = useCallback(
    (interventionUuid: string) => async () => {
      setIsDeletingIntervention(true);
      try {
        await apiService({}).delete(`/api/places/${placeUuid}/interventions/${interventionUuid}`);
        setIsDeletingIntervention(true);
        refreshInterventions();
      } catch (error) {
        setIsDeletingIntervention(false);
        toast.error(translations.global.somethingWentWrong);
      }
    },
    [setIsDeletingIntervention, refreshInterventions],
  );

  const onDownloadPdf = (intervention: Intervention) => async () => {
    try {
      setIsDownloadingPdf(true);
      const {
        data: { url },
      } = await apiService({}).get(
        `/api/places/${placeUuid}/interventions/${intervention.id}/signed-url`,
      );
      await saveFileFromUrl(
        url,
        `${get(intervention, 'name')}_${readableDate(intervention.createdAt)}`.toLowerCase(),
      );
    } catch {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  const onGeneratePdf = (intervention: Intervention) => async () => {
    try {
      setIsGeneratingPdf(true);
      const {
        data: { url },
      } = await apiService({}).post(
        `/api/places/${placeUuid}/interventions/${intervention.id}/pdf`,
        { generatedAt: new Date().toLocaleString() },
      );
      toast.success(translations.intervention.reportGeneratedSuccess);
    } catch {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  return (
    <AppContent>
      <LoadingOverlay
        visible={isGeneratingPdf || isDownloadingPdf || isDeletingIntervention}
        loaderProps={{ size: 'lg', variant: 'dots' }}></LoadingOverlay>
      <Flex justify="space-between" mb={20}>
        <Title>{translations.interventions.interventions}</Title>
        <Button onClick={goToCreateIntervention} mb={20}>
          {translations.interventions.createIntervention}
        </Button>
      </Flex>
      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>{translations.global.name}</Th>
            <Th>{translations.intervention.purpose}</Th>
            <Th>{translations.global.createdAt}</Th>
            <Th>{translations.global.actions}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {get(interventions, 'length') === 0 && (
            <Tr>
              <Td colSpan={5}>{translations.reports.noReports}</Td>
            </Tr>
          )}
          {map(interventions.interventions, intervention => (
            <Tr key={intervention.id}>
              <Td>
                <Link
                  to={routes.places.interventions.edit.to(place.id, place.uuid, intervention.id)}>
                  {get(intervention, 'name')}
                </Link>
              </Td>
              <Td>{get(intervention, 'purpose')}</Td>
              <Td>{readableDateWithoutTime(get(intervention, 'createdAt'))}</Td>
              <Td>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button size="xs" variant="light">
                      <Dots />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item onClick={onGeneratePdf(intervention)}>
                      {translations.global.generatePdf}
                    </Menu.Item>
                    {intervention?.reportPath && (
                      <Menu.Item onClick={onDownloadPdf(intervention)}>
                        {translations.global.download}
                      </Menu.Item>
                    )}
                    <Menu.Item onClick={goToEditIntervention(intervention.id)}>
                      {translations.global.edit}
                    </Menu.Item>
                    {!isController && (
                      <Menu.Item onClick={onClickDelete(intervention.id)} color="red">
                        {translations.global.delete}
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </AppContent>
  );
};
export default List;
