import isEmpty from 'lodash/isEmpty';
import { RRule } from 'rrule';
import { getHoursAndMinutes } from 'shared/utils/date';
import { ScheduleEvent } from './types';
import { VisitFormValues } from './VisitForm/types';

export const savedEventToCalendarEvent = (savedEvent: ScheduleEvent) => {
  return {
    start: new Date(savedEvent.dateFrom),
    end: new Date(savedEvent.dateTo),
    title: savedEvent.name,
    resource: savedEvent,
  };
};

export const savedEventToVisitFormValues = (
  savedEvent: ScheduleEvent,
): Partial<VisitFormValues> => {
  return {
    ...savedEvent,
    dateFrom: savedEvent.dateFrom ? new Date(savedEvent.dateFrom) : undefined,
    dateTo: savedEvent.dateTo ? new Date(savedEvent.dateTo) : undefined,
    periodical: !isEmpty(savedEvent.periodicity),
    timeFrom: getHoursAndMinutes(savedEvent.dateFrom),
    timeTo: getHoursAndMinutes(savedEvent.dateTo),
    customPeriodicity: savedEvent.periodicity?.count !== undefined,
    periodicity: savedEvent.periodicity
      ? {
          unit: savedEvent.periodicity.unit,
          count: savedEvent.periodicity.count,
          withoutWeekends: savedEvent.periodicity.withoutWeekends,
          endDate: savedEvent.periodicity.endDate
            ? new Date(savedEvent.periodicity.endDate)
            : undefined,
        }
      : {
          endDate: undefined,
          unit: undefined,
          count: undefined,
          withoutWeekends: undefined,
        },
  };
};

export const getEventFrequency = (savedEvent: ScheduleEvent) => {
  if (savedEvent.periodicity.unit === 'DAILY') {
    return {
      frequency: RRule.DAILY,
      interval: savedEvent.periodicity?.count || 1,
    };
  }
  if (savedEvent.periodicity.unit === 'WEEKLY') {
    return {
      frequency: RRule.WEEKLY,
      interval: savedEvent.periodicity?.count || 1,
    };
  }

  if (savedEvent.periodicity.unit === 'BIWEEKLY') {
    return {
      frequency: RRule.WEEKLY,
      interval: savedEvent.periodicity?.count || 2,
    };
  }

  if (savedEvent.periodicity.unit === 'MONTHLY') {
    return {
      frequency: RRule.MONTHLY,
      interval: savedEvent.periodicity?.count || 1,
    };
  }

  if (savedEvent.periodicity.unit === 'YEARLY') {
    return {
      frequency: RRule.YEARLY,
      interval: savedEvent.periodicity?.count || 1,
    };
  }
  if (!savedEvent.periodicity.unit && savedEvent.periodicity.count) {
    return {
      frequency: RRule.MONTHLY,
      interval: savedEvent.periodicity?.count || 1,
    };
  }
};
