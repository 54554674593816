import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Group, Modal, Radio } from '@mantine/core';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import useFetch from '../../../../shared/hooks/useFetch';

const AssignActionToDeviceType = ({
  onFinish,
  deviceTypeId = undefined,
  parentCompanyId = undefined,
  currentActions = [],
}) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState<boolean>();
  const [isSaving, setSaving] = useState<boolean>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<any[]>({
    initialValue: [],
    fetchAction: () => apiService({}).get(`/device-type-actions`),
  });

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        deviceTypeId,
        actionId: formValues.actionId,
        parentCompanyId,
        type: 'CUSTOM' || 'ROOT',
      };
      await apiService({}).post(`/device-types/${deviceTypeId}/actions/assign`, body);
      setOpen(false);
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.addActionSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const form = useForm({
    initialValues: {
      actionId: undefined,
    },
  });

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => setOpen(false)}
        title={translations.deviceTypes.addActionTitle}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            {...form.getInputProps('actionId')}
            label="Wybierz akcje"
            description="Wybierz akcje, która zostanie przypisana do tego typu urządzenia"
            required>
            {data?.map(da => (
              <Radio
                value={String(da.id)}
                label={da.name}
                key={da.id}
                disabled={currentActions.find(a => a.id === da.id)}
              />
            ))}
          </Radio.Group>
          <Group position="right" mt="md">
            <Button type="submit" loading={isSaving} disabled={!form.values.actionId}>
              {translations.global.add}
            </Button>
            <Button type="button" variant="outline" onClick={() => setOpen(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>

      <Button ml={30} leftIcon={<Plus size={14} />} size="xs" compact onClick={() => setOpen(true)}>
        {translations.global.add}
      </Button>
    </>
  );
};

export default AssignActionToDeviceType;
