import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Alert, Button, Select } from '@mantine/core';
import { Check } from 'tabler-icons-react';

import useSave from 'shared/hooks/useSave';
import { useTranslations } from 'shared/translations/useTranslations';
import validate from './validate';

import {
  Language,
  POSSIBLE_APP_LANGUAGES,
  translationService,
} from '../../../../shared/translations/translations';
import { User } from '../../../../shared/types/User';
import { notifications } from '@mantine/notifications';
import moment from 'moment-timezone';
import styles from './styles.module.scss';

interface Props {
  currentUser: User;
}

const ChangeAccountData = (props: Props) => {
  const { currentUser } = props;

  const form = useForm({
    initialValues: {
      language: String(currentUser.language).toLowerCase(),
      timezone: String(currentUser.timezone),
    },
    validate,
  });

  const { save, isLoading, error } = useSave({
    patchEndpoint: `/api/account/me`,
    isPatch: true,
    onSuccessCallback: data => {
      notifications.show({
        title: translations.global.changeSuccessful,
        message: '',
        color: 'teal',
        icon: <Check />,
        autoClose: 10000,
      });
      if (form.values.language !== currentUser.language) {
        translationService.changeLanguage(form.values.language as Language);
      }
    },
  });

  const submit = formValues => {
    save({
      language: String(formValues.language).toLowerCase(),
      timezone: String(formValues.timezone),
    });
  };
  const translations = useTranslations();
  const timezonesList = moment.tz.names().map(timezone => ({
    label: timezone,
    value: timezone,
  }));
  return (
    <div className={styles.root}>
      <form onSubmit={form.onSubmit(submit)}>
        <Select
          label={translations.global.timeZone}
          data={timezonesList}
          mb={10}
          mt={30}
          searchable
          required
          {...form.getInputProps('timezone')}
        />
        <Select
          label={translations.global.language}
          data={POSSIBLE_APP_LANGUAGES.map(lang => ({
            value: String(lang.value).toLowerCase(),
            label: lang.label,
          }))}
          searchable
          required
          mb={10}
          {...form.getInputProps('language')}
        />

        {error && !isLoading && (
          <Alert mt={10} mb={10} color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        <Button type="submit" loading={isLoading} disabled={isLoading} fullWidth>
          {translations.global.save}
        </Button>
      </form>
    </div>
  );
};

export default ChangeAccountData;
