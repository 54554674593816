import React from 'react';

import Schedule from 'containers/Schedule';

import { Place } from 'shared/types/Place';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';

import { ScheduleEvent } from 'containers/Schedule/types';

interface Props {
  place: Place;
}

const PlaceSchedule = (props: Props) => {
  const { place } = props;

  const { data: fetchedEvents } = useFetch<{ scheduleEvents: ScheduleEvent[] }>({
    initialValue: { scheduleEvents: [] },
    fetchAction: () => api({}).get(`/api/places/${place.uuid}/schedule/events`),
  });

  return <Schedule place={place} fetchedEvents={fetchedEvents.scheduleEvents} />;
};

export default PlaceSchedule;
