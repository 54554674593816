import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import flatMap from 'lodash/flatMap';

import { fetchCompanies } from 'modules/Company/services/companyService';
import { useTranslations } from 'shared/translations/useTranslations';

import { ManageSelectCompanyProps } from './types';

const ManageSelectCompany = (props: ManageSelectCompanyProps) => {
  const { disabled, form } = props;

  const [companies, setCompanies] = useState([]);
  const translations = useTranslations();

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await fetchCompanies();
        const data = response.data || [];
        // setData(data);
        const subCompanies = flatMap(data, company => company.subCompanies);
        const mappedCompanies = [...data, ...subCompanies].map(company => ({
          label: company.name,
          value: company.id,
        }));
        setCompanies(mappedCompanies);
      } catch (e) {}
    };
    fetch();
  }, []);

  return (
    <div>
      <Select
        name="companyId"
        label={translations.global.client}
        placeholder={translations.global.selectClient}
        data={companies}
        disabled={disabled}
        mb={20}
        {...form.getInputProps('companyId')}
      />
    </div>
  );
};

export default ManageSelectCompany;
