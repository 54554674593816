import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { isRouteActive } from 'shared/services/routes';
import { NavContext } from 'shared/context/nav';
import './styles.scss';

const NavigationItem = ({ children, to, exactActive, toBottom, onClick, tooltip, icon, sub }) => {
  const tooltipClasses = classnames('NavigationItem__tooltip', {
    'NavigationItem__tooltip--with-sub-menu': !!children,
    'NavigationItemLink__tooltip--with-sub-menu': !!children,
    NavigationItemLink__tooltip: true,
  });

  return (
    <NavContext.Consumer>
      {({ activeRoute }) => {
        const isActive = isRouteActive(activeRoute, to, exactActive);
        const classes = classnames('NavigationItem', {
          'NavigationItem--active': !sub && isRouteActive(activeRoute, to, exactActive),
          'NavigationItem--bottom': toBottom,
          'NavigationItem--sub': !!sub,
          'NavigationItem--sub-active': !!sub && isActive,
        });
        const classesLink = classnames('NavigationItemLink', {
          'NavigationItemLink--active': !sub && isRouteActive(activeRoute, to, exactActive),
          'NavigationItemLink--bottom': toBottom,
          'NavigationItemLink--sub': !!sub,
          'NavigationItemLink--sub-active': !!sub && isActive,
        });
        return (
          <div className={classes}>
            {to && (
              <Link className={classesLink} to={to} onClick={onClick}>
                <div className="NavigationItem__icon">{icon}</div>
                <div className={tooltipClasses}>{tooltip}</div>
              </Link>
            )}
            {!to && (
              <div className={classesLink} onClick={onClick}>
                <div className="NavigationItem__icon">{icon}</div>
                <div className={tooltipClasses}>{tooltip}</div>
              </div>
            )}
            {children && <div className="NavigationItem__sub-menu">{children}</div>}
          </div>
        );
      }}
    </NavContext.Consumer>
  );
};

export default NavigationItem;
