import { Place } from 'shared/types/Place';

export interface ScheduleProps {
  place?: Place;
  fetchedEvents: ScheduleEvent[];
}

export interface ScheduleEvent {
  uuid: string;
  name: string;
  type: 'INTERVENTION' | 'INSPECTION';
  placeUuid: string;
  userUuids: string[];
  dateFrom: string;
  dateTo: string;
  periodicity?: PeriodicityValue;
  description?: string;
}

export interface PeriodicityValue {
  unit: PeriodicityUnit;
  count?: number;
  endDate?: string;
  withoutWeekends?: boolean;
}

export interface PeriodicityValues {
  DAILY: PeriodicityValue;
  WEEKLY: PeriodicityValue;
  BIWEEKLY: PeriodicityValue;
  MONTHLY: PeriodicityValue;
  YEARLY: PeriodicityValue;
}

export enum PeriodicityUnit {
  'DAILY' = 'DAILY',
  'WEEKLY' = 'WEEKLY',
  'BIWEEKLY' = 'BIWEEKLY',
  'MONTHLY' = 'MONTHLY',
  'YEARLY' = 'YEARLY',
}
