import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, Loader, Modal, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';
import AppContent from '../../../../../shared/components/AppContent';
import ListHeading from '../../../../../shared/components/ListHeading';
import { useHistory, useParams } from 'react-router';
import { routes } from '../../../../../shared/constants/routes';
import useFetch from '../../../../../shared/hooks/useFetch';
import { deviceErrorMessages } from '../../../../../shared/constants/errors';
import { pick } from 'lodash';

const schema = z.object({
  uniqueId: z.string().optional(),
  prefix: z
    .string()
    .regex(new RegExp(/^[A-Z]+$/))
    .optional(),
  description: z.string().optional(),
  location: z.string().optional(),
  zoneId: z.string().optional(),
  deviceTypeId: z.number(),
});
interface Props {
  onClose: () => any;
  refreshParent: () => any;
  placeId: string;
  deviceUuid: string;
}

interface DeviceData {
  device: any;
}
const PlaceDevicesEdit = (props: Props) => {
  const { onClose, placeId, deviceUuid, refreshParent } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {},
    schema: zodResolver(schema),
  });
  const [zones, setZones] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);

  const { data, isLoading, isLoaded, error, refresh } = useFetch<DeviceData>({
    initialValue: { device: undefined },
    fetchAction: () => api({}).get(`/api/places/${placeId}/devices/${deviceUuid}`),
  });
  useEffect(() => {
    if (data?.device?.id) {
      form.setValues(data.device);
    }
  }, [data]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api({}).get(`/api/places/${placeId}/zones`);
        setZones(data.zones);
      } catch (e) {}
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api({}).get(`/api/places/${placeId}/device-types`);
        setDeviceTypes(data.deviceTypes);
      } catch (e) {}
    }
    fetchData();
  }, []);

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const { data } = await api({}).patch(
        `/api/places/${placeId}/devices/${deviceUuid}`,
        pick(formValues, ['name', 'description', 'zoneId', 'uniqueId', 'location']),
      );
      toast.success(translations.devices.create.success); // TODO
      setSaving(false);
      refreshParent();
      onClose();
    } catch (e) {
      setSaving(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {error && <Alert color="red">{translations.global.somethingWentWrong}</Alert>}
      {isLoaded && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Form form={form} zones={zones} deviceTypes={deviceTypes} isEdit />
          <Group position="right" mt={20}>
            <Button color="primary" loading={isSaving} type="submit">
              {translations.global.edit}
            </Button>
            <Button variant="outline" onClick={() => onClose()}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      )}
    </>
  );
};

export default PlaceDevicesEdit;
