import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import { Button, Group, Modal, Radio } from '@mantine/core';

import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { Plus } from 'tabler-icons-react';
import useFetch from 'shared/hooks/useFetch';

const AssignObservationToDeviceType = ({
  onFinish,
  deviceTypeId = undefined,
  parentCompanyId = undefined,
  currentObservations = [],
}) => {
  const translations = useTranslations();
  const [isOpen, setOpen] = useState<boolean>();
  const [isSaving, setSaving] = useState<boolean>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<{ observations: any[] }>({
    initialValue: [],
    fetchAction: () => apiService({}).get(`/api/v3/device-type-observations`),
  });

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        deviceTypeId,
        observationId: formValues.observationId,
        parentCompanyId,
        type: 'CUSTOM' || 'ROOT',
      };
      await apiService({}).post(`/device-types/${deviceTypeId}/observations/assign`, body);
      setOpen(false);
      setSaving(false);
      onFinish();
      toast.success(translations.deviceTypes.addObservationSuccess);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const form = useForm({
    initialValues: {
      observationId: undefined,
    },
  });

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={() => setOpen(false)}
        title={translations.deviceTypes.addObservationTitle}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            {...form.getInputProps('observationId')}
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            label="Wybierz szkodnika"
            description="Wybierz szkodnika, który zostanie przypisany do tego typu urządzenia"
            required>
            {data?.observations?.map(da => (
              <Radio
                value={String(da.id)}
                label={da.name}
                key={da.id}
                disabled={currentObservations.find(a => a.id === da.id)}
              />
            ))}
          </Radio.Group>
          <Group position="right" mt="md">
            <Button type="submit" loading={isSaving} disabled={!form.values.observationId}>
              {translations.global.add}
            </Button>
            <Button type="button" variant="outline" onClick={() => setOpen(false)}>
              {translations.global.cancel}
            </Button>
          </Group>
        </form>
      </Modal>

      <Button ml={30} leftIcon={<Plus size={14} />} size="xs" compact onClick={() => setOpen(true)}>
        {translations.global.add}
      </Button>
    </>
  );
};

export default AssignObservationToDeviceType;
