import React, { useMemo } from 'react';
import { Badge, Card, Group, LoadingOverlay, Table, Title } from '@mantine/core';

import { routes } from '../../shared/constants/routes';
import { useTranslations } from '../../shared/translations/useTranslations';
import {
  getChartColorByRecommendationPriority,
  getColorByRecommendationPriority,
} from '../../shared/utils/recommendation';
import SeeMoreButton from './SeeMoreButton';

import { DashboardRecommendationsByPriorityType } from './types';
import { Doughnut } from 'react-chartjs-2';
import { RecommendationPriority, RecommendationStatus } from '../../shared/types/recommendation';

interface Props {
  placeId?: string | number;
  recommendationsByPriority: DashboardRecommendationsByPriorityType[];
  loading: boolean;
}

const DashboardRecommendationsByPriorityChart = (props: Props) => {
  const translations = useTranslations();
  // const labels = ['a', 'b'];
  const getPriorityTranslation = priority =>
    translations.recommendations.enums.priority[priority] || priority;

  const labels = Object.keys(RecommendationPriority).map(getPriorityTranslation);
  const data = useMemo(() => {
    return labels.map(priority => {
      return (
        props.recommendationsByPriority.find(a => getPriorityTranslation(a.priority) === priority)
          ?.recommendationsCount || 0
      );
    });
  }, [props.recommendationsByPriority, labels]);

  const colors = Object.keys(RecommendationPriority).map(priority => {
    // @ts-ignore
    return getChartColorByRecommendationPriority(priority);
  });
  return (
    <Card
      shadow="sm"
      p="lg"
      mb={20}
      style={{ maxHeight: '400px', height: '400px', overflow: 'unset' }}>
      <LoadingOverlay visible={props.loading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.global.recommendations}</Title>
      </Group>
      <div style={{ maxWidth: '400px', minHeight: '300px', maxHeight: '300px' }}>
        <Doughnut
          width={100}
          height={100}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  const total = meta.total;
                  const currentValue = dataset.data[tooltipItem.index];
                  const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                  return `${currentValue} (${percentage}%)`;
                },
                title: function (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
              },
            },
          }}
          data={{
            labels,
            datasets: [
              {
                label: 'Status',
                data,
                backgroundColor: colors,
              },
            ],
          }}
        />
      </div>
    </Card>
  );
};

export default DashboardRecommendationsByPriorityChart;
