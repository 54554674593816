import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ListHeading from '../../../../shared/components/ListHeading';
import { useTranslations } from '../../../../shared/translations/useTranslations';
import AddDeviceTypeObject from '../../../DeviceType/containers/AddDeviceTypeObject';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import apiService from '../../../../shared/services/api';
import EditDeviceTypeObjectModal from '../../../DeviceType/containers/EditDeviceTypeObjectModal';
import { Button, Group } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';
import DeleteDeviceTypeObject from '../../../DeviceType/containers/DeleteDeviceTypeObject';

function AdminDevicesObjects() {
  const translations = useTranslations();
  const history = useHistory();
  const reset = () => {
    fetchDetails();
  };
  const [objectToEdit, setObjectToEdit] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [deleteObject, setDeleteObject] = useState();

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`/device-type-objects?parentCompanyId=ROOT`);
      setData(data);
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <EditDeviceTypeObjectModal
        id={objectToEdit?.id}
        // object={objectToEdit}
        onFinish={reset}
        close={() => setObjectToEdit(undefined)}
      />
      <DeleteDeviceTypeObject
        id={deleteObject?.id}
        isOpen={!!deleteObject}
        onFinish={reset}
        close={() => {
          setDeleteObject(undefined);
        }}
      />
      <ListHeading title={translations.devices.objects}>
        <AddDeviceTypeObject onFinish={reset} />
      </ListHeading>

      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>{translations.global.name}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(object => (
            <Tr key={object.id}>
              <Td>{object.id}</Td>
              <Td>{object.name}</Td>
              <Td>
                <Group>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setObjectToEdit(object);
                    }}
                    compact
                    size="xs"
                    color="blue">
                    <Pencil size={14} />
                  </Button>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDeleteObject(object);
                    }}
                    compact
                    size="xs"
                    color="red">
                    <Trash size={14} />
                  </Button>
                </Group>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default AdminDevicesObjects;
