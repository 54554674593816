import React, { useState } from 'react';
import { Badge, Button, Flex, Grid, LoadingOverlay, Menu, Table, Title } from '@mantine/core';
import { Dots, Plus } from 'tabler-icons-react';
import { useParams } from 'react-router';
import useFetch from '../../../../../shared/hooks/useFetch';
import apiService from '../../../../../shared/services/api';
import { readableDate } from 'shared/utils/date';
import { useTranslations } from 'shared/translations/useTranslations';
import AddCompanyZoneModal from './AddCompanyZoneModal';
import { CompanyZone } from './types';
import EditCompanyZoneModal from './EditCompanyZoneModal';

const CompanyZones = props => {
  const { id: companyUuid } = useParams<any>();
  const [isAddZoneModalOpened, setAddZoneModalOpened] = useState(false);
  const [editZoneId, setEditZoneId] = useState<string>();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    zones: CompanyZone[];
  }>({
    initialValue: { zones: [] },
    fetchAction: () => apiService({}).get(`/companies/${companyUuid}/zones`),
  });

  const handleCloseAddZoneModal = () => {
    setAddZoneModalOpened(false);
  };

  const handleCloseEditZoneModal = () => {
    setEditZoneId(undefined);
  };

  const handleOpenAddZoneModal = () => {
    setAddZoneModalOpened(true);
  };

  const translations = useTranslations();

  const handleOnEdit = zoneUuid => () => {
    setEditZoneId(zoneUuid);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Grid>
        <LoadingOverlay visible={isLoading} />
        <Grid.Col span={12}>
          <Flex align="bottom" justify="start" mb={10}>
            <Title order={3}>{translations.companyZones.zones} </Title>
            <Button size="xs" leftIcon={<Plus />} ml={10} onClick={handleOpenAddZoneModal}>
              {translations.global.add}
            </Button>
          </Flex>
          <Table captionSide="bottom">
            <thead>
              <tr>
                <th>{translations.global.name}</th>
                <th>{translations.global.facilities}</th>
                <th>{translations.companyZones.parentZone}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.zones.map(zone => (
                <Row zone={zone} onEdit={handleOnEdit} depth={0} key={zone.uuid} />
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
      <AddCompanyZoneModal
        opened={isAddZoneModalOpened}
        onClose={handleCloseAddZoneModal}
        companyId={companyUuid}
        onSuccess={refresh}
        zones={data.zones || []}
      />
      <EditCompanyZoneModal
        zoneUuid={editZoneId}
        opened={!!editZoneId}
        onClose={handleCloseEditZoneModal}
        companyUuid={companyUuid}
        onSuccess={refresh}
        zones={data.zones || []}
      />
    </div>
  );
};

export const Row = ({ zone, depth = 0, onEdit }: { zone: CompanyZone; depth: number; onEdit }) => {
  const depthArray = Array.from({ length: depth }, () => null);
  return (
    <>
      <tr key={`${zone.uuid}`}>
        <td>
          {depthArray.map(() => '-- ')}
          {zone.name}
        </td>
        <td>{zone.places?.length || 0}</td>
        <td>{zone.parentZone?.name || '-'}</td>

        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button size="xs" variant="light">
                <Dots />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={onEdit(zone.uuid)}>Edytuj</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </td>
      </tr>
      {zone.childZones?.map(childZone => (
        <Row zone={childZone} depth={depth + 1} onEdit={onEdit} key={childZone.uuid} />
      ))}
    </>
  );
};
export default CompanyZones;
