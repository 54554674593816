import React, { useEffect, useRef, useState } from 'react';
import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';

import { useForm } from '@mantine/form';
import apiService from '../../../../../shared/services/api';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';
import AddCompanyZoneForm from './AddCompanyZoneForm';
import { CompanyZone } from './types';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyUuid: string;
  zoneUuid: string;
  zones: CompanyZone[];
}

const EditCompanyZoneModal = (props: Props) => {
  const { companyUuid, zoneUuid, zones } = props;

  const { data, isLoading, refresh, reset } = useFetch<any>({
    initialValue: { zone: undefined },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/companies/${companyUuid}/zones/${zoneUuid}`),
  });

  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      validUntil: undefined,
      devicesLimit: undefined,
      usersLimit: undefined,
    },
  });

  useEffect(() => {
    if (zoneUuid) {
      refresh();
    } else {
      reset();
      form.reset();
    }
  }, [zoneUuid]);

  useEffect(() => {
    if (zoneUuid && data.zone) {
      form.setValues({
        ...data.zone,
      });
    }
  }, [zoneUuid, data]);

  const { onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const handleAdd = async formValues => {
    try {
      setSaving(true);
      const { data } = await apiService({}).patch(
        `/companies/${props.companyUuid}/zones/${zoneUuid}`,
        { ...formValues, active: String(formValues.active) === 'true' ? true : false },
      );
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };
  const translations = useTranslations();

  return (
    <Modal opened={opened} onClose={onClose} title={translations.companyZones.editZone}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanyZoneForm form={form} zones={zones} currentZoneUuid={zoneUuid} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            {translations.global.save}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default EditCompanyZoneModal;
