import React from 'react';
import { NumberInput, Radio, Textarea, TextInput } from '@mantine/core';

import { useTranslations } from 'shared/translations/useTranslations';
import { MixtureType } from 'shared/types/mixtures';
import TranslationForm from 'shared/components/TranslationForm';

interface Props {
  form: any;
}

const Form = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();
  return (
    <>
      <TextInput
        label={translations.mixtures.name}
        name="name"
        required
        mb={20}
        {...form.getInputProps('name')}
      />
      <Textarea
        label={translations.mixtures.description}
        name="description"
        mb={20}
        {...form.getInputProps('description')}
      />
      <NumberInput
        label={translations.mixtures.grammage}
        description={translations.mixtures.grammageDesc}
        placeholder="10"
        min={1}
        required
        name="grammage"
        mb={20}
        {...form.getInputProps('grammage')}
      />
      <Radio.Group
        label={translations.mixtures.type}
        name="type"
        mb={20}
        {...form.getInputProps('type')}
        required>
        <Radio
          value={MixtureType.BAIT}
          label={translations.mixtures.types[MixtureType.BAIT]}
          defaultChecked
        />
      </Radio.Group>
      <TranslationForm
        formTitle={translations.global.translations}
        fieldsLabel={translations.mixtures.name}
        languageKey="lang"
        translationsKey="translations"
        fieldKey="name"
        form={form}
      />
    </>
  );
};

export default Form;
