import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { translationService } from 'shared/translations/translations';
import styles from './styles.module.scss';
import { Select } from '@mantine/core';

export const LanguageSelector = () => {
  const activeLang = useMemo(() => translationService.getCurrentLanguage(), []);

  const languages = ['PL', 'EN', 'DE', 'IT', 'RU'];
  const handleLangSelect = useCallback(lang => {
    translationService.changeLanguage(lang);
  }, []);
  return (
    <div className={styles.root}>
      <Select
        style={{ backgroundColor: 'transparent' }}
        defaultValue={activeLang}
        styles={theme => ({
          wrapper: {
            // backgroundColor: 'red',
          },
          input: {
            background: '#3b3b3b',
            color: '#818181',
            border: 'none',
          },
          dropdown: { background: '#3b3b3b', border: 'none' },
          disabled: {
            opacity: '0.5',
            color: 'gray !important',
          },
          selected: {
            background: '#3b3b3b',
            color: 'white',
          },
          item: {
            background: '#3b3b3b',
            color: '#818181',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'white',
            },
          },
        })}
        onChange={value => handleLangSelect(value)}
        data={[
          { label: 'Polski', value: 'PL' },
          { label: 'English', value: 'EN' },
          { label: 'Deutsch', value: 'DE' },
          { label: 'Italiano', value: 'IT' },
          { label: 'Русский', value: 'RU' },
          { label: 'Español (coming soon)', value: 'ES', disabled: true },
          { label: 'Norsk (coming soon)', value: 'NO', disabled: true },
        ]}
      />
      {/* {languages.map(lang => {
        const classes = classNames(styles.button, {
          [styles.buttonActive]: lang === activeLang,
        });
        return (
          <div className={classes} onClick={handleLangSelect(lang)} key={lang}>
            {lang}
          </div>
        );
      })} */}
    </div>
  );
};
