class EnterPriseClient {
  name = undefined;
  isDomainClient = false;

  constructor() {
    this.checkDomainClientId();
    this.name = this.getInitalId();
  }

  set = name => {
    if (this.isDomainClient) {
      return;
    }
    localStorage.setItem('ENTERPRISE_CLIENT_IDENTIFIER', name);
    this.name = name;
  };

  reset = name => {
    if (this.isDomainClient) {
      return;
    }
    localStorage.removeItem('ENTERPRISE_CLIENT_IDENTIFIER');
    this.name = undefined;
  };

  getInitalId = () => {
    const clientId = this.checkDomainClientId();
    if (clientId) {
      return clientId;
    }
    return localStorage.getItem('ENTERPRISE_CLIENT_IDENTIFIER');
  };

  checkDomainClientId = () => {
    const regex = /^http(s)?:\/\/panel\.([^.]+)\.mousee\.io\/?.*$/;
    const match = window.location.href.match(regex);
    const clientId = match && match[2];

    if (!clientId) {
      return undefined;
    }
    this.isDomainClient = true;
    this.name = clientId;
    return clientId;
  };
}

const enterpriseClientService = new EnterPriseClient();
export default enterpriseClientService;
