import { notifications } from '@mantine/notifications';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { routes } from 'shared/constants/routes';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { LoraDevice } from 'shared/types/LoraDevice';
import { Check, X } from 'tabler-icons-react';

export const useOnSubmit = (loraDeviceUuid?: string) => {
  const history = useHistory();
  const [isSavingLoraDevice, setIsSavingLoraDevice] = useState(false);
  const translations = useTranslations();
  const submitValues = useCallback(
    async values => {
      setIsSavingLoraDevice(true);
      try {
        const { data } = loraDeviceUuid
          ? await apiService({}).patch<{ loraDevice: LoraDevice }>(
              `/api/lora/devices/${loraDeviceUuid}`,
              values,
            )
          : await apiService({}).post<{ loraDevice: LoraDevice }>(`/api/lora/devices`, values);

        if (!data.loraDevice) {
          setIsSavingLoraDevice(false);
          return;
        }

        setIsSavingLoraDevice(false);

        history.push(routes.lora.devices.details.to(data.loraDevice.uuid));
        notifications.show({
          title: 'Sukces',
          message: loraDeviceUuid ? 'Zapisano zmiany' : 'Dodano urządzenie', // TODO: Translations
          color: 'green',
          icon: <Check size="1rem" />,
        });
      } catch (error) {
        setIsSavingLoraDevice(false);
        notifications.show({
          title: 'Error',
          message: translations.global.somethingWentWrong,
          color: 'red',
          icon: <X size="1rem" />,
        });
      }
    },
    [history, loraDeviceUuid, isSavingLoraDevice, setIsSavingLoraDevice, translations],
  );

  return {
    submitValues,
    isSubmittingForm: isSavingLoraDevice,
  };
};
