import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

import Modal from 'shared/components/Modal';
import { useTranslations } from 'shared/translations/useTranslations';
import { Device } from './PlaceMap';

interface Props {
  close: (shouldRefetch: boolean) => void;
  open: boolean;
  placeId: number | string;
  deviceId: number;
}

const UnsetDeviceFromMap = (props: Props) => {
  const translations = useTranslations();
  const { close, open, deviceId } = props;
  const [isSaving, setSaving] = useState(false);

  const deleteFromMap = async () => {
    try {
      setSaving(true);
      await api({}).delete(`places/${props.placeId}/devices/${deviceId}/unset-from-map`);
      toast.success(translations.map.removedFromMap);
      close(true);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={open} onClose={close} title={translations.map.removeFromPlan}>
      <div className="row">
        <div className="col-md-12">
          <p>{translations.map.removeFromPlanDesc}</p>
        </div>
        <div className="col-md-3">
          <Button
            className={styles.uploadButton}
            onClick={deleteFromMap}
            type="primary"
            isLoading={isSaving}>
            {translations.global.delete}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default UnsetDeviceFromMap;
