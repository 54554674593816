import { translationService } from 'shared/translations/translations';

const requiredFields = ['name', 'address'];

const validate = values => {
  const translations = translationService.getTranslations();

  let errors = {};
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = translations.validationRules.requiredField;
    }
  });

  return errors;
};

export default validate;
