import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, LoadingOverlay, Menu, Title, Tooltip } from '@mantine/core';
import { useHistory } from 'react-router';
import { get, map } from 'lodash';
import { Dots } from 'tabler-icons-react';

import { routes } from 'shared/constants/routes';
import { useTranslations } from 'shared/translations/useTranslations';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import { readableDate, readableDateWithoutTime } from 'shared/utils/date';
import { selectIsControllerByAuthSate } from 'shared/utils/user';

import { LoraDevicesListProps } from './types';
import { toast } from 'react-toastify';
import { saveFileFromUrl } from 'shared/utils/saveFile';
import { Link } from 'react-router-dom';
import moment from 'moment';

const List = (props: LoraDevicesListProps) => {
  const history = useHistory();
  const translations = useTranslations();
  const goToCreateLoraDevice = () => {
    history.push(routes.lora.gateways.create.path);
  };

  const goToEditLoraDevice = (loraDeviceUuid: string) => () => {
    history.push(routes.lora.gateways.edit.to(loraDeviceUuid));
  };

  const {
    data: loraDevices,
    isLoading,
    refresh: refreshLoraDevices,
  } = useFetch<{ loraDevices: LoraDevice[] }>({
    fetchAction: () => apiService({}).get(`/api/lora/gateways`),
    initialValue: { loraDevices: [] },
    shouldNotFetchAtStart: false,
    fetchActionDeps: [],
  });

  const isController = selectIsControllerByAuthSate();
  const [isDeletingLoraDevice, setIsDeletingLoraDevice] = useState(false);

  const onClickDelete = useCallback(
    (loraDeviceUuid: string) => async () => {
      setIsDeletingLoraDevice(true);
      try {
        await apiService({}).delete(`/api/lora/gateways/${loraDeviceUuid}`);
        refreshLoraDevices();
      } catch (error) {
        toast.error(translations.global.somethingWentWrong);
      } finally {
        setIsDeletingLoraDevice(false);
      }
    },
    [setIsDeletingLoraDevice, refreshLoraDevices],
  );

  return (
    <AppContent>
      <LoadingOverlay
        visible={isDeletingLoraDevice}
        loaderProps={{ size: 'lg', variant: 'dots' }}></LoadingOverlay>
      <Flex justify="space-between" mb={20}>
        <Title>{translations.loraDevice.loraDevice}</Title>
        <div>
          <Button onClick={() => refreshLoraDevices()} mb={20} mr={15} variant="outline">
            {translations.global.refresh}
          </Button>
          <Button onClick={goToCreateLoraDevice} mb={20}>
            {translations.loraDevice.list.create}
          </Button>
        </div>
      </Flex>

      <Table isLoading={isLoading}>
        <Thead>
          <Tr>
            <Th>{translations.global.name}</Th>
            <Th>{translations.global.createdAt}</Th>

            <Th>{translations.global.lastInformation}</Th>
            <Th>{translations.loraDevice.devEui}</Th>
            <Th>{translations.global.actions}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {get(loraDevices, 'length') === 0 && (
            <Tr>
              <Td colSpan={5}>{translations.reports.noReports}</Td>
            </Tr>
          )}
          {map(loraDevices.loraDevices, loraDevice => (
            <Tr key={loraDevice.uuid}>
              <Td>
                <Link to={routes.lora.gateways.details.to(loraDevice.uuid)}>
                  {get(loraDevice, 'name')}
                </Link>
              </Td>
              <Td>{readableDate(get(loraDevice, 'createdAt'))}</Td>
              <Td>
                {loraDevice.lastUplinkAt ? (
                  <Tooltip label={readableDate(get(loraDevice, 'lastUplinkAt'))}>
                    <span>{moment(get(loraDevice, 'lastUplinkAt')).fromNow()}</span>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </Td>

              <Td>{get(loraDevice, 'devEui')}</Td>
              <Td>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button size="xs" variant="light">
                      <Dots />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item onClick={goToEditLoraDevice(loraDevice.uuid)}>
                      {translations.global.edit}
                    </Menu.Item>
                    {!isController && (
                      <Menu.Item onClick={onClickDelete(loraDevice.uuid)} color="red">
                        {translations.global.delete}
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </AppContent>
  );
};
export default List;
