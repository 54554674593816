import { DeviceTypeObservationType } from "shared/types/deviceType";
import { Observation } from "shared/types/observations";

export const observationAmountByData = (observation: Observation) => {
    if (observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
        const previousTotalMixtures = observation.data?.previousTotalMixtures
        if (!previousTotalMixtures) {
            return `${observation.data.amountPercentage}%`
        }
        const calculated = observation.data.amountPercentage * previousTotalMixtures / 100;
        return `${calculated}g / ${previousTotalMixtures}g  (${observation.data.amountPercentage}%)`
    }
    if (observation.data?.type === DeviceTypeObservationType.REFILL) {
        return `${observation.data.totalMixtures}g`
    }
    return 1
} 
export const observationAmountNumberByData = (observation: Observation) => {
    if (observation.data?.type === DeviceTypeObservationType.BAIT_COLLECT) {
        const previousTotalMixtures = observation.data?.previousTotalMixtures
        if (!previousTotalMixtures) {
            return 0; // observation.data.amountPercentage
        }
        const calculated = observation.data.amountPercentage * previousTotalMixtures / 100;
        return calculated;
    }
    if (observation.data?.type === DeviceTypeObservationType.REFILL) {
        return observation.data.totalMixtures
    }
    return 1
} 