import React from 'react';
import { NumberInput, Radio, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  form: any; // UseFormReturnType<any>;
}

const AddCompanySubscriptionForm = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();
  return (
    <div>
      <TextInput
        name={`name`}
        placeholder={translations.global.name}
        mb={10}
        label={translations.global.name}
        required
        {...form.getInputProps('name')}
      />
      <TextInput
        name={`description`}
        placeholder={translations.global.description}
        mb={10}
        label={translations.global.description}
        {...form.getInputProps('description')}
      />
      <Radio.Group
        label={translations.global.status}
        required
        {...form.getInputProps('active')}
        mb={10}
        defaultValue="true">
        <Radio value="true" label={translations.global.activeFem} />
        <Radio value="false" label={translations.global.inactiveFem} />
      </Radio.Group>
      <DatePickerInput
        name={`validUntil`}
        placeholder=""
        mb={10}
        label={translations.global.validUntil}
        {...form.getInputProps('validUntil')}
      />
      <NumberInput
        name={`devicesLimit`}
        placeholder=""
        mb={10}
        label={translations.global.devicesLimit}
        {...form.getInputProps('devicesLimit')}
      />
      <NumberInput
        name={`usersLimit`}
        placeholder=""
        mb={10}
        label={translations.global.usersLimit}
        {...form.getInputProps('usersLimit')}
      />
    </div>
  );
};
export default AddCompanySubscriptionForm;
