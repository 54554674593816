import React, { useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import map from 'lodash/map';

import { SUPER_ADMIN } from 'shared/constants/user';
import { getUserStatuses, getUserTypes, getUserTypesByAdmin } from 'shared/utils/user';
import './styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'shared/constants/routes';
import {
  Alert,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Radio,
  Select,
  SimpleGrid,
  TextInput,
  Title,
} from '@mantine/core';
import { toast } from 'react-toastify';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { useIsSuperAdmin } from 'shared/hooks/useIsSuperAdmin';
import { ErrorCode, getApiErrorTranslationByCode } from 'shared/constants/errors';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import ChangeUserPasswordModal from '../ChangeUserPasswordModal';

const List = () => {
  const translations = useTranslations();
  const isSuperAdmin = useIsSuperAdmin();
  const typeOptions = isSuperAdmin ? getUserTypesByAdmin() : getUserTypes();
  const statusOptions = getUserStatuses();

  const [isSaving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState<undefined | { code: string }>();
  const history = useHistory();
  const { id: userId } = useParams<{ id?: string }>();

  const form = useForm({
    initialValues: {
      name: undefined,
      surname: undefined,
      email: undefined,
      companyId: undefined,
      type: undefined,
      status: undefined,
      places: [],
    },
  });
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { mixtures: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/users/${userId}`),
  });
  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    isLoaded: isLoadedCompanies,
    error: errorLoadingCompanies,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => apiService({}).get(`/companies`),
  });

  useEffect(() => {
    if (userId) {
      refresh();
    }
  }, [userId]);

  const isAllLoading = userId ? isLoadingCompanies || isLoading : false;
  const isAllLoaded = userId ? isLoadedCompanies && isLoaded : true;

  useEffect(() => {
    if (userId && data && isAllLoaded) {
      const companyId = companiesData.some(c => c.id === data.companyId) ? String(data.companyId) : undefined;
      form.reset();
      form.setValues({
        ...data,
        companyId,
        places: data.places?.map(p => String(p.uuid)),
      });
    }
  }, [userId, companiesData, data, isAllLoaded]);

  const placesData = useMemo(() => {
    if (!form.values?.companyId) {
      return [];
    }
    const result = [];
    const selectedCompany = companiesData.find(c => String(c.id) === String(form.values.companyId));
    if (!selectedCompany) {
      return [];
    }
    result.push(
      ...selectedCompany?.places?.map(place => ({
        label: `${place.name}`,
        value: String(place.uuid),
        group: String(selectedCompany.name),
      })),
    );
    selectedCompany?.subCompanies?.forEach(sc => {
      result.push(
        ...sc?.places?.map(place => ({
          label: `${place.name}`,
          value: String(place.uuid),
          group: String(sc.name),
        })),
      );
    });

    return result;
  }, [companiesData, form.values.companyId]);

  const handleSelectAll = () => {
    form.setFieldValue(
      'places',
      placesData.map(p => p.value),
    );
  };
  const handleUnselectAll = () => {
    form.setFieldValue('places', []);
  };

  const onSubmit = async values => {
    try {
      setSaving(true);
      setSavingError(undefined);
      if (userId) {
        await apiService({}).patch(`/users/${userId}`, values);
        toast.success(translations.user.manage.updatedSuccess);
        refresh();
      } else {
        const { data: response } = await apiService({}).post(`/users`, values);
        history.replace(routes.users.manage.to(response.uuid));
        toast.success(translations.user.manage.createdSuccess);
      }
    } catch (e) {
      const errorBody = e?.response?.data;
      setSavingError(errorBody);
      if (errorBody?.code === ErrorCode.USER_EMAIL_TAKEN) {
        form.setFieldError('email', getApiErrorTranslationByCode(errorBody?.code));
      }
    } finally {
      setSaving(false);
    }
  };

  const [showSetPassword, setShowSetPassword] = useState(false);

  const goToList = useCallback(() => {
    history.push(routes.users.list.path);
  }, []);

  return (
    <>
      <ChangeUserPasswordModal
        userId={userId}
        onClose={() => {
          setShowSetPassword(false);
        }}
        opened={showSetPassword}
      />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <ListHeading
          title={
            <div>
              {!userId && (
                <div className="HeaderTextField__input">{translations.user.manage.addUser}</div>
              )}
              {userId && (
                <div className="HeaderTextField__input">
                  <small style={{ color: 'grey' }}>{get(data, 'company.name')}</small> /{' '}
                  {get(data, 'name')}
                </div>
              )}
            </div>
          }>
          <Group position="right" mt="md">
            <Button type="submit" loading={isSaving} disabled={isSaving}>
              {userId ? translations.global.save : translations.global.add}
            </Button>
            <Button type="button" color="" variant="outline" onClick={goToList}>
              {translations.global.cancel}
            </Button>
            {userId && isSuperAdmin && (
              <Button
                type="button"
                color="violet"
                variant="outline"
                onClick={() => {
                  setShowSetPassword(true);
                }}>
                Zmień hasło
              </Button>
            )}
          </Group>
        </ListHeading>
        <AppContent>
          {isAllLoading && !isLoaded && <Loader />}
          {isAllLoaded && (
            <SimpleGrid cols={2}>
              <LoadingOverlay visible={isAllLoading} />
              <div>
                <TextInput
                  // key={item.lang}
                  label={`${translations.global.firstName}`}
                  required
                  // sx={{ flex: 1 }}
                  {...form.getInputProps('name')}
                  mb={20}
                />
                <TextInput
                  // key={item.lang}
                  label={`${translations.global.lastName}`}
                  required
                  mb={20}
                  {...form.getInputProps('surname')}
                />
                <TextInput
                  // key={item.lang}
                  label={`${translations.global.email}`}
                  required
                  mb={20}
                  {...form.getInputProps('email')}
                />
                {isSuperAdmin && (
                  <Select
                    disabled={form.values.type === SUPER_ADMIN}
                    data={map(companiesData, ({ id, name }) => ({
                      value: String(id),
                      label: name,
                    }))}
                    label={`${translations.global.company}`}
                    required
                    mb={10}
                    {...form.getInputProps('companyId')}
                  />
                )}
                {form.values.type !== SUPER_ADMIN && (
                  <div>
                    <MultiSelect
                      data={placesData}
                      {...form.getInputProps('places')}
                      label={translations.global.facilities}
                      mb={10}
                      disabled={!form.values.companyId}
                    />
                    <Button
                      size="xs"
                      mr={10}
                      onClick={handleSelectAll}
                      disabled={placesData.length === form.values?.places?.length || !form.values.companyId}>
                      {translations.global.selectAll}
                    </Button>
                    <Button
                      size="xs"
                      variant="outline"
                      onClick={handleUnselectAll}
                      disabled={form.values?.places?.length === 0 || !form.values.companyId}>
                      {translations.global.unselectAll}
                    </Button>
                  </div>
                )}
                <Radio.Group
                  mt={20}
                  mb={20}
                  label={`${translations.global.type}`}
                  {...form.getInputProps('type')}
                  required>
                  {typeOptions.map(option => (
                    <Radio value={option.value} label={option.label} key={option.value} mb={5} />
                  ))}
                </Radio.Group>
                <Radio.Group
                  mb={20}
                  label={`${translations.global.status}`}
                  {...form.getInputProps('status')}
                  required>
                  {statusOptions.map(option => (
                    <Radio value={option.value} label={option.label} key={option.value} mb={5} />
                  ))}
                </Radio.Group>
              </div>
              {savingError && (
                <Alert title={translations.global.errorOccured} color="red">
                  {getApiErrorTranslationByCode(savingError?.code)}
                </Alert>
              )}
            </SimpleGrid>
          )}
        </AppContent>
      </form>
    </>
  );
};

export default List;
