import React, { useEffect, useRef } from 'react';
import L, { LatLngBounds } from 'leaflet';
import 'leaflet.heat';
import { ImageOverlay, Marker, useMap } from 'react-leaflet';
import { deviceIcon } from './helpers';

interface HeatmapProps {
  bounds: LatLngBounds;
  mapUrl: string;
  heatmapData: {
    mapX: number;
    mapY: number;
    deviceTypeObjectId: number;
    totalAmount: number;
    totalAmountPercentage: number;
  }[];
}

const Heatmap = (props: HeatmapProps) => {
  const { bounds, mapUrl, heatmapData } = props;

  const map = useMap();
  useEffect(() => {
    if (!map || !bounds) {
      return;
    }
    map.fitBounds(bounds);
  }, [map, bounds]);

  useEffect(() => {
    if (!map) {
      return;
    }

    //@ts-ignore
    if (map._size.x === 0 || map._size.y === 0) {
      return;
    }

    const heatData = heatmapData.map(value => [
      Number(value.mapY),
      Number(value.mapX),
      value.totalAmountPercentage,
    ]);

    //@ts-ignore
    const heatLayer = L.heatLayer(heatData, { radius: 100 }).addTo(map);
    //@ts-ignore
    return () => {
      map.removeLayer(heatLayer);
    };
    //@ts-ignore
  }, [map._size, heatmapData, bounds]);

  return (
    <>
      <ImageOverlay url={mapUrl} bounds={bounds} />
      {heatmapData?.map((value, i) => {
        // if (selectedDeviceType && device.deviceType?.id !== selectedDeviceType) {
        //   return null;
        // }
        // const date = get(device, 'deviceHistory[0].createdAt');
        // const battery = get(device, 'deviceHistory[0].battery');
        // const rssi = get(device, 'deviceHistory[0].rssi');
        // const lastSignalInterval = get(device, 'place.radioErrorInterval');
        // const showDateAlert = date && dateLongerThanMinutes(date, lastSignalInterval);
        // const showDateAlert = date && dateLongerThanOneDay(date);

        const icon = deviceIcon({}, 'normal');
        return (
          <Marker position={[value.mapY, value.mapX]} icon={icon} key={`device-${i}`}>
            {/* <Popup>
          <div
            className="device-box device-box--no-border"
            key={device.id}
            onClick={() => {
              window.open(`/app/devices/edit/${device.id}`, '_blank').focus();
              // push(`/app/devices/edit/${device.id}`);
            }}>
            <div className="device-box__header">
              <div className="device-box__id">{device.uniqueId}</div>
              <div className="device-box__id__icon">
                {get(device, 'detect') ? (
                  <i className="fa fa-exclamation-triangle device-box__id__icon--alert"></i>
                ) : (
                  <i className="fa fa-check-circle device-box__id__icon--good"></i>
                )}
              </div>
            </div>
            <div className="device-box__desc">{device.description}</div>
            <div className="device-box__place device-box__row">
              <i className="fas fa-warehouse device-box__icon" />
              {get(device, 'place.name')}
            </div>

            <div className="device-box__device-type device-box__row">
              <i className="fas fa-tablet-alt device-box__icon"></i>
              {get(device, 'deviceType.name')}
            </div>
            <div className="device-box__battery device-box__row">
              <i className="fas fa-battery-full  device-box__icon"></i>
              {battery ? `${batteryLevelPercentage(battery)}%` : '-'}
            </div>
            <div className="device-box__battery device-box__row">
              <i className="fas fa-signal  device-box__icon"></i>
              {battery ? `${rssiToText(rssi)}` : '-'}
            </div>
            <div className="device-box__last-action device-box__row">
              <i className="fas fa-clock device-box__icon"></i>
              {date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '-'}
              {showDateAlert && (
                <i className="fa fa-exclamation-triangle icon-alert-date"></i>
              )}
            </div>
          </div>
        </Popup> */}
          </Marker>
        );
      })}
    </>
  );
};

export default Heatmap;
