import React, { useCallback, useEffect, useMemo, useState } from 'react';
import L, { CRS, latLng, latLngBounds, LatLngBounds } from 'leaflet';
import 'leaflet.heat';
import { Flex, Select } from '@mantine/core';
import { MapContainer } from 'react-leaflet';
import { usePlaceHeatmapQuery, useSignedUrlMapQuery } from 'queries/places';

import Heatmap from '../Heatmap';

interface HeatmapContainerProps {
  placeUuid: string;
  mapId: number;
}

const HeatmapContainer = (props: HeatmapContainerProps) => {
  const { placeUuid, mapId } = props;

  const [bounds, setBounds] = React.useState<LatLngBounds>(
    latLngBounds([
      [0, 0],
      [0, 0],
    ]),
  );

  const { data: heatmapData } = usePlaceHeatmapQuery(placeUuid);

  const { data: signedUrlData } = useSignedUrlMapQuery(placeUuid, mapId);
  const signedUrlMap = signedUrlData?.data?.url;

  const [selectedDeviceTypeObjectId, setSelectedDeviceTypeObjectId] = useState<number>();

  const deviceTypeObjectsOptions = useMemo(() => {
    const deviceTypeObjects = heatmapData?.data?.deviceTypeObjects || [];
    return deviceTypeObjects.map(deviceTypeObject => ({
      value: `${deviceTypeObject.id}`,
      label: deviceTypeObject.name,
    }));
  }, [heatmapData?.data?.deviceTypeObjects]);

  const groupedHeatData = useMemo(() => {
    const groupedValues =
      heatmapData?.data?.devices
        ?.filter(device => device.mapId === mapId && device.mapX && device.mapY)
        .map(device => {
          return device.values.map(value => ({
            mapX: device.mapX,
            mapY: device.mapY,
            ...value,
          }));
        })
        .flat() || [];

    return selectedDeviceTypeObjectId === null
      ? groupedValues
      : groupedValues.filter(
          value => Number(value.deviceTypeObjectId) === selectedDeviceTypeObjectId,
        );
  }, [heatmapData?.data?.devices, selectedDeviceTypeObjectId]);

  useEffect(() => {
    const img = new Image();
    img.addEventListener('load', function () {
      setBounds(latLngBounds([[0, 0], latLng(this.naturalHeight, this.naturalWidth)]));
    });
    img.src = signedUrlMap;
  }, [mapId, signedUrlMap]);

  const onSelectDeviceTypeId = useCallback(
    (value: string) => {
      setSelectedDeviceTypeObjectId(Number(value));
    },
    [setSelectedDeviceTypeObjectId],
  );

  return (
    <Flex direction="column" p={20}>
      <Select
        onChange={onSelectDeviceTypeId}
        style={{ zIndex: 99999 }}
        placeholder="Select"
        data={deviceTypeObjectsOptions}
        mb={20}
      />
      <MapContainer
        center={bounds.getCenter()}
        scrollWheelZoom={false}
        style={{
          width: '100%',
          height: 732,
        }}
        boxZoom={true}
        keyboard={false}
        doubleClickZoom={false}
        zoom={-3}
        maxZoom={8}
        minZoom={-8}
        zoomControl={true}
        crs={CRS.Simple}
        touchZoom={false}
        tap={false}>
        {signedUrlMap && (
          <Heatmap mapUrl={signedUrlMap} bounds={bounds} heatmapData={groupedHeatData} />
        )}
      </MapContainer>
    </Flex>
  );
};

export default HeatmapContainer;
