import React from 'react';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { readableDateWithoutTime } from 'shared/utils/date';
import styles from './styles.module.scss';
import { useTranslations } from '../../../../shared/translations/useTranslations';

interface AuthUser {
  name: string;
  email?: string;
  surname: string;
  company?: {
    name: string;
    uuid: string;
    id: number;
    devicesCount?: number;
    usersCount?: number;
    qrCodesCount?: number;
    subscriptions?: {
      active: boolean;
      qrCodesLimit?: number;
      devicesLimit?: number;
      usersLimit?: number;
      validUntil?: Date;
    }[];
  };
}

const useMe = () => {
  const { data, isLoading, isLoaded, error, refresh } = useFetch<AuthUser>({
    initialValue: undefined,
    fetchAction: () => apiService({}).get(`/api/account/me`),
  });
  if (data?.name) {
    // @ts-ignore
    if (window.$crisp) {
      // @ts-ignore
      window.$crisp.push(['set', 'user:nickname', data.name + ' ' + data.surname]);
      if (data.email) {
        // @ts-ignore
        window.$crisp.push(['set', 'user:email', data.email || '']);
      }
      if (data?.company) {
        // @ts-ignore
        window.$crisp.push([
          'set',
          'session:data',
          [
            [
              ['company', data.company?.name],
              ['companyUuid', data.company?.uuid],
              ['companyId', data.company?.id],
            ],
          ],
        ]);
      }
    }
  }
  return { user: data };
};

const NavigationSubscription = () => {
  const translations = useTranslations();
  const { user } = useMe();
  if (!user?.company?.subscriptions?.length) {
    return null;
  }
  const subscription = user.company.subscriptions[0];
  return (
    <div className={styles.subscription}>
      Subskrypcja ważna do: {'  '}
      {readableDateWithoutTime(subscription.validUntil)}
      {!!subscription.devicesLimit && (
        <div>
          {translations.global.devices}: {user.company.devicesCount}/{subscription.devicesLimit}
        </div>
      )}
      {!!subscription.usersLimit && (
        <div>
          {translations.global.users} {user.company.usersCount}/{subscription.usersLimit}
        </div>
      )}
      {/* {!!subscription.qrCodesLimit && (
        <div>
          QR Kody: {user.company.qrCodesCount}/{subscription.qrCodesLimit}
        </div>
      )} */}
    </div>
  );
};

export default NavigationSubscription;
