import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/translations/useTranslations';
import { Button, Checkbox, Group, Loader, Modal, Select, Textarea, TextInput } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import apiService from 'shared/services/api';
import { useForm } from '@mantine/form';

const EditCategoryModal = ({ fileId, onFinish, close, placeId, categories = [] }) => {
  const translations = useTranslations();
  const isOpen = !!fileId;
  const [isSaving, setSaving] = useState<boolean>();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const form = useForm({
    initialValues: {
      name: undefined,
      note: undefined,
      fileCategoryId: undefined,
    },
  });

  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      setLoaded(false);
      const { data } = await apiService({}).get(`places/${placeId}/files/${fileId}`);
      form.setValues({
        ...data,
        fileCategoryId: data.fileCategoryId ? String(data.fileCategoryId) : undefined,
      });
      setLoaded(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, [fileId, form]);

  useEffect(() => {
    if (fileId && isOpen) {
      fetchDetails();
    } else {
      form.reset();
      setLoaded(false);
      setLoading(false);
    }
  }, [fileId, isOpen]);

  const handleSubmit = async formValues => {
    try {
      setSaving(true);
      const body = {
        name: formValues.name,
        note: formValues.note,
        fileCategoryId: formValues.fileCategoryId,
      };
      await apiService({}).patch(`places/${placeId}/files/${fileId}`, body);
      close();
      setSaving(false);
      onFinish();
      toast.success(translations.global.changeSuccessful);
    } catch {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <>
      <Modal opened={isOpen} onClose={() => close()} title={translations.global.edit}>
        {isLoading && <Loader />}
        {isLoaded && !isLoading && (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              required
              name="name"
              label={translations.global.name}
              placeholder=""
              {...form.getInputProps('name')}
            />
            <Textarea
              required
              name="note"
              label={translations.global.description}
              placeholder=""
              {...form.getInputProps('note')}
            />
            <Select
              label={translations.global.category}
              placeholder=""
              name="fileCategoryId"
              data={categories}
              {...form.getInputProps('fileCategoryId')}
            />
            <Group position="right" mt="md">
              <Button type="submit" loading={isSaving}>
                {translations.global.save}
              </Button>
              <Button type="button" variant="outline" onClick={() => close()}>
                {translations.global.cancel}
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default EditCategoryModal;
