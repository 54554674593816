import React, { useState } from 'react';
import Button from 'shared/components/Button';
import * as yup from 'yup';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useHistory } from 'react-router';
import { routes } from 'shared/constants/routes';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import RecommendationCategoryForm from '../Form';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {}
const Create = (props: Props) => {
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      name: undefined,
      translations: [
        { lang: 'pl', name: undefined },
        { lang: 'en', name: undefined },
        { lang: 'de', name: undefined },
        { lang: 'it', name: undefined },
        { lang: 'ru', name: undefined },
      ],
    },
  });
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        name: formValues.name,
        translations: {
          pl: { name: '' },
          en: { name: '' },
          de: { name: '' },
          it: { name: '' },
          ru: { name: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].name = translation.name;
      });
      await api({}).post(`/api/admin/recommendations/categories`, body);
      toast.success(translations.recommendations.addRecommendationCategorySuccess);
      setSaving(false);
      history.push(routes.admin.recommendation.default.path);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <ListHeading title={translations.recommendations.addRecommendationCategory}>
          <Button color="primary" className="ml-5" isLoading={isSaving}>
            {translations.global.add}
          </Button>
        </ListHeading>
        <AppContent>
          <RecommendationCategoryForm form={form} />
        </AppContent>
      </form>
    </>
  );
};

export default Create;
