import React, { useEffect, useState } from 'react';
import api from 'shared/services/api';
import Button from 'shared/components/Button';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

import Modal from 'shared/components/Modal';

interface Props {
  close: (shouldRefetch: boolean) => void;
  open: boolean;
  placeId: number | string;
  mapId: number;
}

const DeleteMap = (props: Props) => {
  const { close, open, mapId } = props;
  const [isSaving, setSaving] = useState(false);

  const deleteFromMap = async () => {
    try {
      setSaving(true);
      await api({}).delete(`places/${props.placeId}/maps/${mapId}`);
      toast.success('Mapa została usunięta. Urzadzenia zostały odpisane od tej mapy');
      close(true);
    } catch (e) {
      toast.error(translations.global.somethingWentWrong);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={open} onClose={close} title="Usuń mape">
      <div className="row">
        <div className="col-md-12">
          <p>
            Czy napewno chcesz usunąc tą mape? Wszystkie zamieszczone urządzenia na tej mapie
            zostaną od niej odpisane?
          </p>
        </div>
        <div className="col-md-3">
          <Button
            className={styles.uploadButton}
            onClick={deleteFromMap}
            type="primary"
            isLoading={isSaving}>
            Usuń
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteMap;
