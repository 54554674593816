import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { RRule } from 'rrule';
import isEmpty from 'lodash/isEmpty';

import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';
import { getHoursAndMinutes } from 'shared/utils/date';

import VisitForm from './VisitForm';

import { ScheduleEvent, ScheduleProps } from './types';
import {
  getEventFrequency,
  savedEventToCalendarEvent,
  savedEventToVisitFormValues,
} from './helpers';
import { VisitFormValues } from './VisitForm/types';
import calendar from './calendar.scss';
import styles from './styles.module.scss';

const localizer = momentLocalizer(moment);

const Schedule = (props: ScheduleProps) => {
  const { place, fetchedEvents } = props;
  const translations = useTranslations();

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCalendarEvents, setSelectedCalendarEvents] = useState([]);
  const [visitFormProps, setVisitFormProps] = useState<
    { formValues: Partial<VisitFormValues>; scheduleEvent?: ScheduleEvent } | undefined
  >(undefined);

  const [visibleDates, setVisibleDates] = useState<{ start?: Date; end?: Date }>({
    start: moment().startOf('month').toDate(),
    end: moment().endOf('month').toDate(),
  });

  const onRangeChange = ({ start, end }: { start?: Date; end?: Date }) => {
    if (start !== undefined && end !== undefined) {
      setVisibleDates({ start, end });
    }
  };

  const [savedEvents, setSavedEvents] = useState<ScheduleEvent[]>([]);

  const onClose = () => {
    close();
    setSelectedCalendarEvents([]);
    setVisitFormProps(undefined);
  };

  const onSelectSlot = slot => {
    setSelectedCalendarEvents(prev => [...prev, { start: slot.start, end: slot.start }]);
    const timeStart = getHoursAndMinutes(slot.start);
    const timeEnd = getHoursAndMinutes(slot.end);

    setVisitFormProps({
      formValues: {
        dateFrom: slot.start,
        dateTo: slot.start,
        placeUuid: place?.uuid,
        timeFrom: timeStart !== timeEnd ? timeStart : undefined,
        timeTo: timeStart !== timeEnd ? timeEnd : undefined,
      },
    });
    open();
  };

  const onSelectEvent = event => {
    const formValues = savedEventToVisitFormValues(event.resource);
    setVisitFormProps({ formValues: formValues, scheduleEvent: event.resource });
    open();
  };

  const onSaveEvent = useCallback(
    (event: ScheduleEvent, isUpdate?: boolean) => {
      if (!isUpdate) {
        setSavedEvents(prev => [...prev, event]);
      } else {
        const updatedEvents = savedEvents.map(savedEvent => {
          return savedEvent.uuid === event.uuid ? event : savedEvent;
        });

        setSavedEvents(updatedEvents);
      }
      onClose();
    },
    [savedEvents],
  );

  useEffect(() => {
    setSavedEvents(fetchedEvents);
  }, [fetchedEvents]);

  useEffect(() => {
    if (!visibleDates.start && !visibleDates.end) {
      return;
    }

    const nonPeriodicalCalendarEvents = savedEvents
      .filter(event => isEmpty(event.periodicity))
      .map(savedEventToCalendarEvent);
    const periodicalSavedEvents = savedEvents.filter(event => !isEmpty(event.periodicity));
    const periodicalCalendarEvents = periodicalSavedEvents
      .map(event => {
        const eventDateFrom = moment(event.dateFrom);
        const byweekday = event.periodicity?.withoutWeekends ? [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR] : undefined;
        if (eventDateFrom.isSameOrBefore(visibleDates.end)) {
          const frequency = getEventFrequency(event);
          const ruleStart = new RRule({
            freq: frequency.frequency,
            interval: frequency.interval,
            dtstart: eventDateFrom.toDate(),
            until: event.periodicity?.endDate
              ? new Date(event.periodicity?.endDate)
              : visibleDates.end,
            byweekday: byweekday,
          });

          const eventDateTo = moment(event.dateTo).toDate();
          const ruleEnd = new RRule({
            freq: frequency.frequency,
            interval: frequency.interval,
            dtstart: eventDateTo,
            until: event.periodicity?.endDate
              ? new Date(event.periodicity?.endDate)
              : visibleDates.end,
            byweekday: byweekday,
          });

          const allEndDates = ruleEnd.all();

          const visibleRecurringEvents = ruleStart.all().map((date, index) => ({
            start: date,
            end: allEndDates[index],
            title: event.name,
            resource: event,
          }));
          return visibleRecurringEvents;
        }

        return undefined;
      })
      .filter(recurringEvent => recurringEvent !== undefined);
    setSelectedCalendarEvents([...nonPeriodicalCalendarEvents, ...periodicalCalendarEvents.flat()]);
  }, [visibleDates.start, visibleDates.end, savedEvents, visitFormProps]);

  const onDeleteEvent = useCallback(
    (uuid: string) => {
      close();
      setSavedEvents(savedEvents.filter(event => event.uuid !== uuid));
    },
    [savedEvents, setSavedEvents],
  );

  return (
    <div className={styles.container}>
      <Calendar
        className={calendar.calendar}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        selectable={true}
        events={selectedCalendarEvents}
        onRangeChange={onRangeChange}
      />
      <Modal
        opened={opened}
        onClose={onClose}
        title={
          <p
            style={{
              fontSize: 16,
              marginBottom: 0,
            }}>
            {translations.schedule.visitForm.title}
          </p>
        }
        padding={'xl'}>
        <VisitForm
          initialValues={visitFormProps?.formValues}
          uuid={visitFormProps?.scheduleEvent?.uuid}
          onSaveEvent={onSaveEvent}
          onDeleteEvent={onDeleteEvent}
          placeUuid={place?.uuid}
        />
      </Modal>
    </div>
  );
};

export default Schedule;
