import React from 'react';
import { NumberInput, Select, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { selectIsSuperAdmin } from '../../../shared/utils/user';

interface Props {
  form: any;
  companies: any[];
}

const Form = (props: Props) => {
  const { form, companies } = props;
  const isSuperAdmin = selectIsSuperAdmin();
  const translations = useTranslations();
  return (
    <>
      <TextInput
        label={translations.mixtures.name}
        name="name"
        required
        mb={20}
        {...form.getInputProps('name')}
      />
      {isSuperAdmin && (
        <Select
          label={translations.global.company}
          data={companies?.map(company => ({ value: company.id, label: company.name }))}
          min={1}
          searchable
          required
          name="companyId"
          mb={20}
          {...form.getInputProps('companyId')}
        />
      )}

      <NumberInput
        label={translations.global.amount}
        placeholder="10"
        min={1}
        max={500}
        required
        name="amount"
        mb={20}
        {...form.getInputProps('amount')}
      />
    </>
  );
};

export default Form;
