import React, { useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import classNames from 'classnames';

import {
  AlertTriangle,
  ArrowBarToUp,
  CalendarTime,
  Campfire,
  Devices,
  FileSettings,
  ListDetails,
  MapPins,
  Radio,
  ScanEye,
  VirusSearch,
  ZoomInArea,
} from 'tabler-icons-react';
import { ActionIcon, Navbar, Title, Button, AppShell } from '@mantine/core';

import { selectIsControllerByAuthSate, selectIsSuperAdmin } from 'shared/utils/user';
import { translationService } from 'shared/translations/translations';
import { routes } from 'shared/constants/routes';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

interface Props {
  placeId: string | number;
  place?: any; // TODO: TYPE
}
const PlaceNavbar = (props: Props) => {
  const { placeId, place } = props;
  const history = useHistory();

  const isSuperAdmin = selectIsSuperAdmin();
  const isController = selectIsControllerByAuthSate();

  const translations = translationService.getTranslations();

  const onClickNavItem = (tab: string, route) => () => {
    history.push(route);
  };

  const buttonClassNames = useCallback((section: string) => {
    return classNames('navigationButton', {
      'navigationButton--active': section === 'b',
    });
  }, []);

  const navItems = [
    {
      id: 'details',
      color: 'blue',
      icon: <ListDetails strokeWidth={1} />,
      route: routes.places.manage.to(placeId),
      label: translations.global.details,
      condition: true,
      exact: true,
    },
    {
      id: 'devices',
      color: 'yellow',
      icon: <Devices strokeWidth={1} />,
      route: routes.places.devices.default.to(placeId),
      label: translations.global.devices,
      condition: placeId,
      exact: false,
    },
    {
      id: 'zones',
      color: 'green',
      icon: <ZoomInArea strokeWidth={1} />,
      route: routes.places.zones.default.to(placeId),
      label: translations.zones.zones,
      condition: placeId,
      exact: false,
    },
    {
      id: 'radio_options',
      color: 'orange',
      icon: <Radio strokeWidth={1} />,
      route: routes.places.radioOptions.default.to(placeId),
      label: translations.devices.radioOptions,
      condition: placeId && isSuperAdmin,
      exact: false,
    },
    {
      id: 'files',
      color: 'indigo',
      icon: <FileSettings strokeWidth={1} />,
      route: routes.places.files.default.to(placeId),
      label: translations.global.files,
      condition: placeId,
      exact: false,
    },
    {
      id: 'map',
      color: 'green',
      icon: <MapPins strokeWidth={1} />,
      route: routes.places.map.default.to(placeId),
      label: translations.global.facilityPlan,
      condition: placeId,
      exact: false,
    },
    {
      id: 'heatmap',
      color: 'red',
      icon: <Campfire strokeWidth={1} />,
      route: routes.places.heatmap.default.to(placeId),
      label: 'Heatmap',
      condition: placeId,
      exact: false,
    },
    {
      id: 'inspections',
      color: 'grape',
      icon: <VirusSearch strokeWidth={1} />,
      route: routes.places.inspections.default.to(placeId),
      label: translations.global.inspections,
      condition: placeId && !isController,
      exact: false,
    },
    {
      id: 'objects-limits',
      color: 'lime',
      icon: <ArrowBarToUp strokeWidth={1} />,
      route: routes.places.objectsLimit.default.to(placeId),
      label: translations.limits.title,
      condition: placeId,
      exact: false,
    },
    {
      id: 'visualInspection',
      color: 'pink',
      icon: <ScanEye strokeWidth={1} />,
      route: routes.places.visualInspection.default.to(placeId),
      label: translations.global.visualInspection,
      condition: placeId,
      exact: false,
    },
    {
      id: 'interventions',
      color: 'violet',
      icon: <AlertTriangle strokeWidth={1} />,
      route: routes.places.interventions.default.to(placeId),
      label: translations.interventions.interventions,
      condition: placeId,
      exact: false,
    },
    {
      id: 'schedule',
      color: 'yellow',
      icon: <CalendarTime strokeWidth={1} />,
      route: routes.places.schedule.default.to(placeId),
      label: translations.schedule.title,
      condition: placeId,
      exact: false,
    },
  ];

  return (
    <div className={styles.root}>
      <Navbar width={{ base: 250 }} height="auto" className={styles.navbar}>
        <Navbar.Section mb={14}>
          <Title order={2} style={{ padding: '16px 16px 0px 16px' }}>
            {place?.name || translations.global.facility}
          </Title>
        </Navbar.Section>
        <Navbar.Section>
          <Switch>
            <Route path={routes.places.create.path} component={() => null} exact />
            <Route
              path={'*'}
              component={() => (
                <div>
                  {navItems.map(
                    item =>
                      item.condition && (
                        <NavLink
                          to={item.route}
                          key={item.id}
                          exact={item.exact}
                          activeClassName="navigationButton--active"
                          className={buttonClassNames(item.id)}>
                          <Button
                            className={'navigationButton'}
                            variant="outline"
                            leftIcon={
                              <ActionIcon color={item.color} variant="light" size="lg">
                                {item.icon}
                              </ActionIcon>
                            }
                            onClick={onClickNavItem(item.id, item.route)}
                            fullWidth>
                            {item.label}
                          </Button>
                        </NavLink>
                      ),
                  )}
                </div>
              )}
            />
          </Switch>
        </Navbar.Section>
      </Navbar>
    </div>
  );
};

export default PlaceNavbar;
