import React, { useCallback, useState } from 'react';
import { ActionIcon, AppShell, Button, Flex, Loader, Navbar, Text, Title } from '@mantine/core';
import { Notification, Password, UserCircle } from 'tabler-icons-react';
import classNames from 'classnames';

import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { translationService } from 'shared/translations/translations';
import useCurrentUser from 'shared/hooks/useCurrentUser';

import NotificationSettings from '../NotificationSettings';
import ChangePassword from '../ChangePassword';

import styles from './styles.module.scss';
import moment from 'moment';
import ChangeAccountData from '../ChangeAccountData';
import useFetch from '../../../../shared/hooks/useFetch';
import apiService from '../../../../shared/services/api';
import { User } from '../../../../shared/types/User';

const Profile = () => {
  const [profileSection, setProfileSection] = useState<
    'INFO' | 'CHANGE_PASSWORD' | 'NOTIFICATIONS'
  >('INFO');
  const translations = translationService.getTranslations();
  const {
    isLoading,
    data: user,
    error,
  } = useFetch<User | undefined>({
    fetchAction: () => apiService({}).get('/api/account/me'),
    initialValue: undefined,
  });

  const buttonClassNames = useCallback(
    (section: string) => {
      return classNames(styles.navigationButton, {
        [styles.navigationButtonActive]: section === profileSection,
      });
    },
    [profileSection],
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" pt={100} pb={100}>
        <Loader />
      </Flex>
    );
  }
  return (
    <AppContent className={styles.content}>
      <AppShell
        styles={{
          main: {
            paddingLeft: 0,
          },
        }}
        navbar={
          <Navbar width={{ base: 300 }} height="auto" style={{ position: 'relative' }}>
            <Navbar.Section>
              <Title order={2} className={styles.navigationTitle}>
                {translations.global.account}
              </Title>
            </Navbar.Section>
            <Navbar.Section mt="md">
              <Button
                className={buttonClassNames('INFO')}
                variant="outline"
                leftIcon={
                  <ActionIcon color="blue" variant="light" size="lg">
                    <UserCircle strokeWidth={1} />
                  </ActionIcon>
                }
                onClick={() => setProfileSection('INFO')}
                fullWidth>
                {translations.global.account}
              </Button>
            </Navbar.Section>
            <Button
              className={buttonClassNames('CHANGE_PASSWORD')}
              variant="outline"
              leftIcon={
                <ActionIcon color="green" variant="light" size="lg">
                  <Password strokeWidth={1} />
                </ActionIcon>
              }
              onClick={() => setProfileSection('CHANGE_PASSWORD')}
              fullWidth>
              {translations.auth.changePassword}
            </Button>
            <Button
              className={buttonClassNames('NOTIFICATIONS')}
              variant="outline"
              leftIcon={
                <ActionIcon color="red" variant="light" size="lg">
                  <Notification strokeWidth={1} />
                </ActionIcon>
              }
              onClick={() => setProfileSection('NOTIFICATIONS')}
              fullWidth>
              {translations.account.notifications}
            </Button>
          </Navbar>
        }>
        <>
          {profileSection === 'INFO' && user && (
            <>
              <ListHeading title={translations.global.account} />
              <AppContent>
                <Text>
                  <b>{translations.global.firstName}:</b> {user.name}
                </Text>
                <Text>
                  <b>{translations.global.lastName}:</b> {user.surname}
                </Text>
                <Text>
                  <b>{translations.global.email}:</b> {user.email}
                </Text>

                {user && <ChangeAccountData currentUser={user} />}
              </AppContent>
            </>
          )}
          {profileSection === 'CHANGE_PASSWORD' && <ChangePassword />}
          {profileSection === 'NOTIFICATIONS' && <NotificationSettings />}
        </>
      </AppShell>
    </AppContent>
  );
};

export default Profile;
