import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { UserRole } from 'shared/types/User';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, LoadingOverlay, Modal, Overlay, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import PlaceUserForm from '../PlaceUserForm';
import useFetch from '../../../../../shared/hooks/useFetch';
import { AlertCircle } from 'tabler-icons-react';

const schema = z.object({
  roles: z.array(z.string()).optional(),
});

interface Props {
  onClose: () => any;
  placeId: string | number;
  refreshParent: () => any;
  userId: string | number;
}

interface Data {
  roles: UserRole[];
}

const EditPlaceUserModal = (props: Props) => {
  const { refreshParent, onClose, placeId, userId } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const { data, isLoading, isLoaded, error, refresh, reset } = useFetch<Data>({
    initialValue: { roles: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/places/${placeId}/users/${userId}/roles`),
  });

  const form = useForm({
    initialValues: {
      roles: [],
    },
    schema: zodResolver(schema),
  });

  useEffect(() => {
    if (data.roles) {
      form.setValues({ roles: data.roles });
    }
  }, [data]);

  const handleClose = () => {
    setOpened(false);
    onClose();
    form.reset();
  };

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        roles: formValues.roles,
      };
      await api({}).put(`/api/places/${placeId}/users/${userId}/roles`, body);
      toast.success(translations.user.roles.rolesUpdatedSuccessfully);
      setSaving(false);
      handleClose();
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!userId);
    if (userId) {
      refresh();
    } else {
      reset();
    }
  }, [userId]);

  return (
    <Modal opened={opened} onClose={handleClose} title={translations.global.edit}>
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        {error && (
          <Alert icon={<AlertCircle size={16} />} title="Bummer!" color="red">
            {translations.global.somethingWentWrong}
          </Alert>
        )}
        {!error && isLoaded && <PlaceUserForm form={form} />}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default EditPlaceUserModal;
