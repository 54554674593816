import React from 'react';
import { NumberInput, Select, Textarea, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { DateTimePicker } from '@mantine/dates';

interface Props {
  form: any;
  isCreating?: boolean;
  deviceTypeObjects?: any[];
}

const EditInspectionObjectForm = (props: Props) => {
  const { form, isCreating, deviceTypeObjects } = props;
  const translations = useTranslations();
  return (
    <>
      {' '}
      {isCreating && (
        <Select
          label={translations.devices.object}
          name="deviceTypeObjectId"
          data={deviceTypeObjects}
          required
          mb={20}
          {...form.getInputProps('deviceTypeObjectId')}
        />
      )}
      {!isCreating && (
        <TextInput
          label={translations.global.name}
          name="name"
          required
          mb={20}
          disabled
          {...form.getInputProps('name')}
        />
      )}
      {!isCreating && form.values.createdAt && (
        <DateTimePicker
          withSeconds
          label={'Data dodania szkodnika'}
          name="createdAt"
          required
          mb={20}
          // disabled
          {...form.getInputProps('createdAt')}
        />
      )}
      <NumberInput
        label={translations.global.amount}
        name="amount"
        required
        min={1}
        mb={20}
        {...form.getInputProps('amount')}
      />
      <Textarea
        label={translations.global.description}
        name="note"
        mb={20}
        {...form.getInputProps('note')}
      />
    </>
  );
};

export default EditInspectionObjectForm;
