import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Group,
  LoadingOverlay,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { get, map, sortBy } from 'lodash';
import { getObjectsDataSet } from 'modules/Statistics/List';
import { Line } from 'react-chartjs-2';
import { routes } from 'shared/constants/routes';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import SeeMoreButton from './SeeMoreButton';

interface Props {
  placeId?: string | number;
}

const sort = (unsorted?: any) => {
  if (!unsorted) {
    return undefined;
  }
  return sortBy(unsorted, s => {
    return new Date(s.date).getTime();
  });
};

const DashboardObjectsChart = (props: Props) => {
  const date = new Date();
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const dateFromDefault = new Date(date.getFullYear(), date.getMonth() - 2, 0);
  const dateToDefault = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [data, setData] = useState<Response>({} as Response);
  const getData = useCallback(async (query: string, placeId: string | number) => {
    setLoading(true);
    setLoaded(false);
    try {
      const { data: response } = await apiService({
        timeout: 240000,
        baseUrl: process.env.REACT_APP_BACKEND_STATISTICS_URL,
      }).get(`/api/place/${placeId}/statistics?${query}`);
      setLoaded(true);
      setData(response);
    } catch {
      setLoaded(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const form = useForm({
    initialValues: { dateFrom: dateFromDefault, dateTo: dateToDefault },
  });

  const [showComment, setShowComment] = useState(false);
  const translations = useTranslations();
  const onSubmit = async (formValues: any) => {
    let df = new Date();
    let dt = new Date();
    if (formValues.dateFrom) {
      df = new Date(formValues.dateFrom);
      df.setHours(0, 0, 0, 0);
    }
    if (formValues.dateTo) {
      dt = new Date(formValues.dateTo);
      dt.setHours(23, 59, 59, 59);
    }
    const query = `from=${df}&to=${dt}&placeId=${props.placeId}`;
    getData(query, props.placeId);
  };

  useEffect(() => {
    if (props.placeId) {
      onSubmit({
        dateFrom: form.values.dateFrom,
        dateTo: form.values.dateTo,
        placeId: props.placeId,
      });
    }
  }, [props.placeId]);

  return (
    <Card shadow="sm" p="lg" mb={20} style={{ maxHeight: '550px', height: '550px', overflow: 'unset'  }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.global.activity}</Title>
        {props.placeId && (
          <SeeMoreButton to={routes.statistics.default.to(`?placeId=${props.placeId}`)} />
        )}
      </Group>
      <Group mb={20}>
        <DatePickerInput label={translations.global.dateFrom} required {...form.getInputProps('dateFrom')} />
        <DatePickerInput label={translations.global.dateTo} required {...form.getInputProps('dateTo')} />
        <Button type="submit" loading={isLoading} mt={25} onClick={() => onSubmit(form.values)}>
          {translations.global.viewResults}
        </Button>
      </Group>
      <div style={{ maxHeight: '400px', minHeight: '400px' }}>
        <Line
          data={{
            labels: map(sort(get(data, `byPlace.${props.placeId}.dates`)), (data: any, date) => {
              return data.date;
            }),
            datasets: getObjectsDataSet(
              sort(get(data, `byPlace.${props.placeId}.dates`)),
              props.placeId,
            ),
          }}
          options={{
            tooltips: {
              callbacks: {
                title: function (tooltipItems, data) {
                  return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              position: 'top',
            },
            scales: {
              yAxes: {
                ticks: {
                  stepSize: 10,
                },
              },
            },
          }}
        />
      </div>
    </Card>
  );
};

export default DashboardObjectsChart;
