import React, { useEffect } from 'react';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Button,
  Title,
  LoadingOverlay,
  Textarea,
  Grid,
  SimpleGrid,
  Alert,
} from '@mantine/core';
import { useParams } from 'react-router';

import AppContent from 'shared/components/AppContent';
import { useTranslations } from 'shared/translations/useTranslations';
import apiService from 'shared/services/api';
import { LoraDevice } from 'shared/types/LoraDevice';
import useFetch from 'shared/hooks/useFetch';
import { useOnSubmit } from './hooks';
import BatteryChart from './BatteryChart';
import { readableDate } from '../../../../shared/utils/date';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../shared/components/Table';
import { batteryLevelToPercentage, batteryLevelToVoltage } from '../../../../shared/utils/device';
import moment from 'moment';
import { get } from 'lodash';

interface AwsData {
  awsUuid?: string;
  error?: 'NOT_FOUND';
}

const Manage = () => {
  const { loraDeviceUuid } = useParams<{
    loraDeviceUuid?: string;
  }>();

  const translations = useTranslations();

  const {
    isLoading,
    isLoaded,
    data,
    error,
    refresh: fetchLoraDevice,
  } = useFetch<{ loraDevice: LoraDevice; awsData: AwsData }>({
    fetchAction: () => apiService({}).get(`/api/lora/devices/${loraDeviceUuid}`),
    fetchActionDeps: [loraDeviceUuid],
    initialValue: undefined,
    shouldNotFetchAtStart: true,
  });

  useEffect(() => {
    if (loraDeviceUuid) {
      fetchLoraDevice();
    }
  }, [loraDeviceUuid]);
  const loraDevice = data?.loraDevice;

  const Row = ({ label, value }) => (
    <div>
      <div>
        <strong>{label}</strong>:
      </div>
      <div>{value}</div>
    </div>
  );
  return (
    <AppContent>
      <Title mb={20}>{loraDevice?.name}</Title>
      <LoadingOverlay visible={isLoading} overlayBlur={1} />
      <SimpleGrid cols={2} mb={20}>
        <div>
          {loraDevice && (
            <div>
              {data?.awsData?.error && (
                <Alert color="red">To urządzenie nie istnieje w platformie AWS</Alert>
              )}
              <Row label={translations.global.name} value={loraDevice.name} />
              <Row label={translations.global.description} value={loraDevice.description} />
              <Row label={translations.loraDevice.devEui} value={loraDevice.devEui} />
              <Row
                label={translations.global.createdAt}
                value={readableDate(loraDevice.createdAt)}
              />
              <Row
                label={'Last uplink at'}
                value={
                  loraDevice.lastUplinkAt ? (
                    <div>
                      {readableDate(loraDevice.lastUplinkAt)} -{' '}
                      {moment(get(loraDevice, 'lastUplinkAt')).fromNow()}
                    </div>
                  ) : null
                }
              />
            </div>
          )}
        </div>
        <div>
          <BatteryChart data={loraDevice?.uplinks || []} />
        </div>
      </SimpleGrid>

      <Table>
        <Thead>
          <Tr>
            <Th>{translations.global.createdAt}</Th>
            <Th>{translations.devices.battery}</Th>
            <Th>{translations.global.detectStatus}</Th>
            <Th>{translations.global.detectionAlgorithm}</Th>
            <Th>{translations.global.detectionNumber}</Th>
            <Th>{translations.global.detectionCounter}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loraDevice?.uplinks.map(uplink => (
            <Tr key={uplink.uuid}>
              <Td>{readableDate(uplink.createdAt)}</Td>
              <Td>
                {batteryLevelToPercentage(uplink.data?.Status?.batteryLevel)}% /{' '}
                {batteryLevelToVoltage(uplink?.data?.Status?.batteryLevel)}V
              </Td>
              <Td>{uplink.data.Status.detectStatus === 1 ? 'Detected' : 'Not detected'}</Td>
              <Td>{uplink.data.Status.detectedByAlgorithm}</Td>
              <Td>{uplink.data.Status.detectionNumber}</Td>
              <Td>{uplink.data.Status.detectionsCounter}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </AppContent>
  );
};

export default Manage;
