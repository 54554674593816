import React, { useState, useEffect } from 'react';

import Create from './Create';
import Details from './Details';
import { ActionIcon, Group, LoadingOverlay, Text } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { Trash, Pencil } from 'tabler-icons-react';
import AppContent from 'shared/components/AppContent';
import { MixtureModel } from './Mixture';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import styles from './styles.module.scss';
import ListHeading from 'shared/components/ListHeading';
import DeleteMixtureModal from './DeleteMixtureModal';

const DragHandle = SortableHandle(({ children }) => (
  <span className={styles.orderHandle}>{children}</span>
));

const SortableItem = SortableElement(
  ({
    value: mixture,
    order,
    setSelectedEditId,
    setSelectedDeleteId,
  }: {
    value: MixtureModel;
    order: number;
    setSelectedEditId: any;
    setSelectedDeleteId: any;
  }) => {
    return (
      <div>
        <Group>
          <DragHandle>{order + 1}.</DragHandle>
          <div style={{ width: '90%' }}>
            <Group>
              {mixture.name}
              <Group ml="auto">
                <ActionIcon
                  variant="transparent"
                  onClick={() => {
                    setSelectedEditId(mixture.id);
                  }}>
                  <Pencil size={16} />
                </ActionIcon>
                <ActionIcon
                  variant="transparent"
                  onClick={() => {
                    setSelectedDeleteId(mixture.id);
                  }}>
                  <Trash size={16} />
                </ActionIcon>
                {/* <ActionIcon variant="transparent">
                  <Trash size={16} />
                </ActionIcon> */}
              </Group>
            </Group>
            <div>
              <Text size="sm">{mixture.comment}</Text>
            </div>
          </div>
        </Group>
      </div>
    );
  },
);

const SortableList = SortableContainer(({ items, setSelectedEditId, setSelectedDeleteId }) => {
  return (
    <ul>
      {items.map((value: MixtureModel, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          order={index}
          setSelectedEditId={setSelectedEditId}
          setSelectedDeleteId={setSelectedDeleteId}
        />
      ))}
    </ul>
  );
});

function Mixture() {
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<{
    mixtures: MixtureModel[];
  }>({
    initialValue: { mixtures: [] },
    fetchAction: () => apiService({}).get(`/api/v3/admin/mixtures`),
  });
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>();
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | undefined>();
  const handleRefresh = () => {
    refresh();
  };
  const handleModalClose = () => {
    setSelectedEditId(undefined);
    setSelectedDeleteId(undefined)
  };
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(data.mixtures);
  }, [data.mixtures]);
  const [isSavingOrder, setSavingOrder] = useState(false);
  const saveMixturesOrder = async mixtures => {
    const result = mixtures.map((mixture, index) => ({
      id: mixture.id,
      order: index,
    }));
    setSavingOrder(true);
    await apiService({}).post(`/api/v3/admin/mixtures/set-order`, result);
    setSavingOrder(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(items => {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
      saveMixturesOrder(newOrder);
      return newOrder;
    });
  };
  return (
    <div style={{ position: 'relative' }}>
      <ListHeading title={translations.mixtures.title}>
        <Create refreshParent={handleRefresh} onClose={handleModalClose} />
      </ListHeading>
      <AppContent>
        <LoadingOverlay visible={isLoading || isSavingOrder} />
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          useDragHandle
          setSelectedEditId={setSelectedEditId}
          setSelectedDeleteId={setSelectedDeleteId}
        />
        <Details id={selectedEditId} refreshParent={handleRefresh} onClose={handleModalClose} />
        <DeleteMixtureModal id={selectedDeleteId} refreshParent={handleRefresh} onClose={handleModalClose} />
      </AppContent>
    </div>
  );
}

export default Mixture;
