import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  BarElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default () => {
  ChartJS.register(
    ArcElement,
    BarElement,
    CategoryScale,
    // ChartDataLabels,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
  );
};
