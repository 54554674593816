import React, { useEffect, useRef, useState } from 'react';
import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';

import { useForm } from '@mantine/form';
import apiService from '../../../../../shared/services/api';
import AddCompanyFileSubscriptionForm from './AddCompanySubscriptionForm';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  companyId: string;
  subscriptionId: string;
}

const EditCompanySubscriptionModal = (props: Props) => {
  const { companyId, subscriptionId } = props;

  const { data, isLoading, refresh, reset } = useFetch<any>({
    initialValue: { subscription: undefined },
    shouldNotFetchAtStart: true,
    fetchAction: () =>
      apiService({}).get(`/companies/${companyId}/subscriptions/${subscriptionId}`),
  });

  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      validUntil: undefined,
      devicesLimit: undefined,
      usersLimit: undefined,
    },
  });

  useEffect(() => {
    if (subscriptionId) {
      refresh();
    } else {
      reset();
      form.reset();
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (subscriptionId && data.subscription) {
      form.setValues({
        ...data.subscription,
        active: String(data?.subscription?.active),
        validUntil: data?.subscription?.validUntil
          ? new Date(data?.subscription?.validUntil)
          : undefined,
        usersLimit: data?.subscription?.usersLimit ? data?.subscription?.usersLimit : undefined,
        devicesLimit: data?.subscription?.devicesLimit
          ? data?.subscription?.devicesLimit
          : undefined,
      });
    }
  }, [subscriptionId, data]);

  const { onClose, opened } = props;
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const handleAdd = async formValues => {
    try {
      setSaving(true);
      const { data } = await apiService({}).patch(
        `/companies/${props.companyId}/subscriptions/${subscriptionId}`,
        { ...formValues, active: String(formValues.active) === 'true' ? true : false },
      );
      props.onClose();
      props.onSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setSaving(false);
    }
  };
  const translations = useTranslations();
  return (
    <Modal opened={opened} onClose={onClose} title="Edytuj subskrypcję">
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          handleAdd(values);
        })}>
        <AddCompanyFileSubscriptionForm form={form} />
        <Group position="right" mt="md">
          <Button onClick={onClose} variant="outline" type="button">
            {translations.global.cancel}
          </Button>
          <Button type="submit" loading={isSaving}>
            {translations.global.save}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default EditCompanySubscriptionModal;
