import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';

export const modifyReportForTable = (data) => {
  if (!data) {
    return [];
  }
  return map(
    groupBy(
      flatMap([
        ...map(data.actions, (action) => ({
          ...action,
          type: 'action',
          actionName: action.deviceTypeAction.name,
          date: `${action.day}-${action.month}-${action.year}`,
        })),
        ...map(data.objects, (action) => ({
          ...action,
          type: 'object',
          objectName: action.deviceTypeObject.name,
          date: `${action.day}-${action.month}-${action.year}`,
        })),
        ...map(data.alertsByDate, (action) => ({
          ...action,
          type: 'alert',
          date: `${action.day}-${action.month}-${action.year}`,
        })),
      ]),
      'date'
    ),
    (history, key) => {
      return {
        date: key,
        actions: history.filter((d) => d.type === 'action'),
        objects: history.filter((d) => d.type === 'object'),
        alerts: history.filter((d) => d.type === 'alert'),
      };
    }
  );
};
