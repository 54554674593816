import React from 'react';
import { Checkbox, Group, Input } from '@mantine/core';

import { useTranslations } from 'shared/translations/useTranslations';
import { UserPlaceNotification, UserRole } from 'shared/types/User';
import { UseFormReturnType } from '@mantine/form';

interface Props {
  form: UseFormReturnType<any>;
}

const PlaceUserForm = (props: Props) => {
  const { form } = props;
  const translations = useTranslations();

  return (
    <>
      <Checkbox.Group defaultValue={form.values.roles} {...form.getInputProps(`roles`)}>
        <Input.Wrapper label={translations.user.roles.title} mb={15}>
          {Object.values(UserRole).map(role => (
            <Checkbox
              mt="md"
              key={role}
              value={role}
              label={translations.user.roles[role] || '-'}
            />
          ))}
        </Input.Wrapper>
        <Input.Wrapper
          label={translations.user.placeNotifications?.title}
          description={translations.user.placeNotifications?.description}>
          {Object.values(UserPlaceNotification).map(role => (
            <Checkbox
              mt="md"
              key={role}
              value={role}
              description={
                translations.user.placeNotifications?.notifications[role]?.description || role
              }
              label={translations.user.placeNotifications?.notifications[role]?.name || role}
            />
          ))}
        </Input.Wrapper>
      </Checkbox.Group>
    </>
  );
};

export default PlaceUserForm;
