import { put, takeEvery } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import { SAVE_PLACE, FETCH_PLACE } from '../../reducers/manage/';
import { push } from 'connected-react-router';
import { routes } from 'shared/constants/routes';

function* fetchPlace(action) {
  try {
    const { data } = yield apiService({}).get(`/places/${action.payload.id}`);
    yield put({
      type: FETCH_PLACE.SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({ type: FETCH_PLACE.FAILURE, payload: getError(err) });
  }
}

function* editPlace(action) {
  try {
    let data = {};
    if (action.payload.id) {
      const { data: editData } = yield apiService({}).patch(
        `/places/${action.payload.id}`,
        action.payload
      );
      data = editData;
    } else {
      const { data: createData } = yield apiService({}).post(
        `/places`,
        action.payload
      );
      data = createData;
    }
    yield put({
      type: SAVE_PLACE.SUCCESS,
      payload: data
    });
    yield put(push(routes.places.list.path));
  } catch (err) {
    yield put({ type: SAVE_PLACE.FAILURE, payload: getError(err) });
  }
}

export default [
  function*() {
    yield takeEvery(FETCH_PLACE.REQUEST, fetchPlace);
  },
  function*() {
    yield takeEvery(SAVE_PLACE.REQUEST, editPlace);
  }
];
