import React, { useState } from 'react';
import ConfirmModal from 'shared/components/ConfirmModal';
import { toast } from 'react-toastify';
import api from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';

const DeleteCommentModal = ({ recommendationId, commentId, isOpen, onClose, onSuccess }) => {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState<boolean>();
  const onDelete = async () => {
    try {
      setLoading(true);
      await api({}).delete(`/api/recommendations/${recommendationId}/comment/${commentId}`);
      onClose();
      setLoading(false);
      onSuccess();
      toast.success('Komentarz został usunięty');
    } catch {
      setLoading(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        paragraph={<div>{translations.global.deleteCommentConfirmation}</div>}
        isLoading={isLoading}
        onClose={() => onClose()}
        onConfirm={onDelete}
        title={translations.global.deleteComment}
        confirmLabel={translations.global.delete}
        cancelLabel={translations.global.cancel}
      />
    </>
  );
};

export default DeleteCommentModal;
