import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export default ({ children, className = '' }) => (
  <div
    className={classNames(styles.AppContent, {
      [className]: className !== undefined,
    })}>
    {children}
  </div>
);
