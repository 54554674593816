import { ActionIcon, Button, Group, NumberInput, Radio, Select, TextInput } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Trash } from 'tabler-icons-react';

import { useTranslations } from 'shared/translations/useTranslations';

interface Props {
  form: any;
  zones: any[];
  deviceTypes: any[];
  isEdit?: boolean;
}

const Form = (props: Props) => {
  const { form, zones, deviceTypes, isEdit } = props;
  const translations = useTranslations();
  const isMouseeDevice = get(form.values, 'deviceTypeId') === 1; // TODO: Should check for param isElectronic
  const uniqueIdsToBeCreated = useMemo(() => {
    const result = [];
    if (form.values.amountType === 'MULTIPLE') {
      const startFrom = form.values.startFrom === 'YES' ? form.values.startFromNumber : 1;
      for (let i = startFrom; i < startFrom + form.values.amount; i++) {
        result.push(`${form.values.prefix || ''}${i}`);
      }
    }
    const sliced = result.slice(0, 10);
    const text = `Zostaną stworzone urządzenia o numerach: 
  ${sliced.join(', ')}
    ${result.length > sliced.length ? `- i ${result.length - sliced.length}  więcej` : ''}`;
    return {
      text,
      values: result.slice(0, 10).join(', '),
      total: result.length,
    };
  }, [form.values]);

  return (
    <>
      {!isEdit && (
        <>
          <Radio.Group
            label={translations.devices.deviceCountToAdd}
            name="amountType"
            mb={20}
            {...form.getInputProps('amountType')}
            required>
            <Group mt={5}>
              <Radio value={'ONE'} label={translations.devices.one} />
              <Radio value={'MULTIPLE'} label={translations.devices.multiple} />
              <Radio value={'SPECIFIC'} label={'Specyficzyne'} />
            </Group>
          </Radio.Group>

          {form.values.amountType === 'SPECIFIC' && (
            <>
              {form.values?.specificUniqueIds?.map((item, index) => (
                <Group key={item.key} mt="xs">
                  <TextInput
                    placeholder="np. Z21"
                    withAsterisk
                    label={translations.global.uniqIdentifier}
                    sx={{ flex: 1 }}
                    required
                    {...form.getInputProps(`specificUniqueIds.${index}.uniqueId`)}
                  />

                  <ActionIcon
                    color="red"
                    onClick={() => form.removeListItem('specificUniqueIds', index)}>
                    <Trash size="1rem" />
                  </ActionIcon>
                </Group>
              ))}

              <Group position="center" mt="md">
                <Button
                  onClick={() =>
                    form.insertListItem('specificUniqueIds', {
                      name: '',
                      active: false,
                      key: randomId(),
                    })
                  }>
                  {translations.devices.addDevice}
                </Button>
              </Group>
            </>
          )}
          {form.values.amountType === 'MULTIPLE' && (
            <>
              <TextInput
                description={translations.devices.prefixTooltip}
                label={'Prefix'}
                name="prefix"
                required
                mb={20}
                {...form.getInputProps('prefix')}
                maxLength={2}
                minLength={1}
                min={1}
                max={2}
              />
              <Radio.Group
                // TODO: Translate
                label={'Zacznij od liczby'}
                description="Jezeli wpiszesz 151, to pierwsze urzadzenie bedzie mialo numer 151, drugie 152 itd."
                name="startFrom"
                mb={20}
                {...form.getInputProps('startFrom')}>
                <Group mt={5}>
                  <Radio value={'NO'} label={translations.global.no} mt={5} />
                  <Radio value={'YES'} label={translations.global.yes} mt={5} />
                </Group>
              </Radio.Group>
              <NumberInput
                label={'Zacznij od numeru'}
                description=""
                name="startFromNumber"
                placeholder="1"
                required
                mb={20}
                {...form.getInputProps('startFromNumber')}
                min={1}
                disabled={form.values.startFrom === 'NO'}
              />
              <NumberInput
                label={translations.devices.amount}
                description="Max 300"
                name="amount"
                required
                mb={20}
                {...form.getInputProps('amount')}
                min={1}
                max={300}
              />
              {uniqueIdsToBeCreated.text}
            </>
          )}
        </>
      )}
      {(form.values.amountType === 'ONE' || isEdit) && (
        <TextInput
          label={translations.devices.uniqueId}
          name="uniqueId"
          required
          mb={20}
          {...form.getInputProps('uniqueId')}
          maxLength={4}
          max={4}
          disabled={form.values.amountType === 'MULTIPLE'}
        />
      )}
      {(form.values.amountType === 'ONE' || isEdit) && (
        <TextInput
          label={translations.global.name}
          name="name"
          mb={20}
          {...form.getInputProps('name')}
          disabled={form.values.amountType === 'MULTIPLE'}
        />
      )}
      {(form.values.amountType === 'ONE' || isEdit) && (
        <TextInput
          disabled={form.values.amountType === 'MULTIPLE'}
          label={translations.global.description}
          name="description"
          mb={20}
          {...form.getInputProps('description')}
        />
      )}

      <TextInput
        label={translations.devices.location}
        name="location"
        mb={20}
        {...form.getInputProps('location')}
      />

      <Select
        label={translations.zones.zone}
        data={zones?.map(zone => ({ value: zone.id, label: zone.name }))}
        min={1}
        name="zoneId"
        clearable
        mb={20}
        {...form.getInputProps('zoneId')}
      />

      <Select
        label={translations.global.deviceType}
        data={deviceTypes?.map(deviceType => ({ value: deviceType.id, label: deviceType.name }))}
        required
        description={
          isEdit ? 'Zmiana typu urzadzenia  jest niemozliwa. Nalezy stworzyc nowe urządzenie' : ''
        }
        disabled={isEdit}
        name="deviceTypeId"
        mb={20}
        {...form.getInputProps('deviceTypeId')}
      />

      {isMouseeDevice && !isEdit && form.values.amountType === 'ONE' && (
        <TextInput
          label={'loraDevEui'}
          name="loraDevEui"
          disabled={isEdit}
          mb={20}
          {...form.getInputProps('loraDevEui')}
        />
      )}
    </>
  );
};

export default Form;
