import 'chartjs-plugin-datalabels';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import App from './modules/App';

import chartjsConfig from 'shared/chartjs';
import EnvBanner from 'shared/components/EnvBanner';

import AuthorizedRoute from './shared/components/AuthorizedRoute';
import { routes } from './shared/constants/routes';
import Auth from './modules/Auth';
import { store, history } from './store';
import './assets/styles/main.scss';
import 'react-image-crop/lib/ReactCrop.scss';
import './index.css';
import { MantineProvider } from '@mantine/core';
import AppWrapper from './modules/AppWrapper';

import * as Sentry from '@sentry/react';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

Sentry.init({
  dsn: 'https://8f00034d6e8331f77774889d1338e7ab@o1039816.ingest.sentry.io/4505820843343872',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

chartjsConfig();

const queryClient = new QueryClient();

ReactDOM.render(
  <MantineProvider
    theme={{
      colors: {
        brand: [
          '#e6fcf5',
          '#c3fae8',
          '#96f2d7',
          '#63e6be',
          '#38d9a9',
          '#20c997',
          '#12b886',
          '#0ca678',
          '#099268',
          '#087f5b',
        ],
      },
      primaryColor: 'teal',
    }}>
    <Notifications position="top-right" />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <AppWrapper>
            <EnvBanner />
            <Route path={routes.auth.default.path} component={Auth} />
            <Route
              path="/app/"
              render={props => <AuthorizedRoute path="/app/" component={App} {...props} />}
            />
          </AppWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>
  </MantineProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
