import React, { useEffect, useMemo, useState } from 'react';
import { Card, Group, LoadingOverlay, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { getStatusesData } from 'modules/Recommendations/StatisticsByPlace/helpers';
import { Doughnut } from 'react-chartjs-2';
import useFetch from 'shared/hooks/useFetch';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { getChartColorByRecommendationStatus } from 'shared/utils/recommendation';
import { DashboardRecommendationsByStatusType } from './types';

interface Props {
  placeId?: string | number;
  recommendationsByStatus: DashboardRecommendationsByStatusType[];
  loading?: boolean;
}

const DashboardRecommendationsByStatusChart = (props: Props) => {
  const { data, isLoading, isLoaded, refresh } = useFetch<{ recommendations: any[] }>({
    initialValue: { recommendations: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/api/recommendations?placeId=${props.placeId}`),
  });
  const form = useForm({
    initialValues: { placeId: undefined, companyId: undefined },
  });

  useEffect(() => {
    if (props.placeId) {
      refresh();
    }
  }, [props.placeId]);

  const [showComment, setShowComment] = useState(false);
  const translations = useTranslations();
  const byStatus = useMemo(
    () => getStatusesData(data?.recommendations, { dateRange }),
    [data.recommendations, {}],
  );
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const labels = useMemo(() => {
    return [
      ...Object.keys(byStatus).map(
        status => translations.recommendations.enums.status[status] || status,
      ),
    ];
  }, [byStatus]);
  return (
    <Card
      shadow="sm"
      p="lg"
      mb={20}
      style={{ maxHeight: '400px', height: '400px', overflow: 'unset' }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.recommendations.statistics}</Title>
      </Group>
      <div style={{ maxWidth: '400px', minHeight: '300px', maxHeight: '300px' }}>
        <Doughnut
          width={100}
          height={100}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  const total = meta.total;
                  const currentValue = dataset.data[tooltipItem.index];
                  const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                  return `${currentValue} (${percentage}%)`;
                },
                title: function (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
              },
            },
          }}
          data={{
            labels,
            datasets: [
              {
                label: 'Status',
                data: [...Object.values(byStatus)],
                backgroundColor: [
                  ...Object.keys(byStatus).map(getChartColorByRecommendationStatus),
                ],
              },
            ],
          }}
        />
      </div>
    </Card>
  );
};

export default DashboardRecommendationsByStatusChart;
