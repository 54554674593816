import React, { useEffect, useState } from 'react';
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Menu,
  Table,
  TextInput,
  Title,
} from '@mantine/core';

import useFetch from '../../../../shared/hooks/useFetch';
import apiService from '../../../../shared/services/api';
import { Check, Dots, Download, X } from 'tabler-icons-react';
import { saveFileFromUrl } from '../../../../shared/utils/saveFile';
import { readableDateWithoutTime } from '../../../../shared/utils/date';
import DeleteCompanyFileModal from './DeleteCompanyFileModal';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import { useTranslations } from 'shared/translations/useTranslations';

const CompanyFilesTable = props => {
  const { categoryId, companyId, shouldRefetchFiles } = props;
  const [isDownloading, setDownloading] = useState();
  const [isSaving, setSaving] = useState();
  const [editId, setEditId] = useState();
  const [deleteId, setDeleteId] = useState();

  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: { files: [] },
    shouldNotFetchAtStart: true,
    fetchAction: () => apiService({}).get(`/companies/${companyId}/files?categoryId=${categoryId}`),
  });
  useEffect(() => {
    if (categoryId) {
      refresh();
    }
  }, [categoryId, shouldRefetchFiles]);

  const handleDownloadFile = async file => {
    try {
      setDownloading(file.id);
      const { data } = await apiService({}).get(
        `/companies/${companyId}/files/${file.id}/signed-url`,
      );
      await saveFileFromUrl(data.url, `${file.name}_${file.id}}`.toLowerCase());
    } catch {
    } finally {
      setDownloading(undefined);
    }
  };
  const form = useForm({
    initialValues: {
      name: undefined,
      description: undefined,
      validUntil: undefined,
    },
  });

  const handleUpdateFile = async file => {
    try {
      const { name, description, validUntil } = form.values;
      setSaving(file.id);
      await apiService({}).patch(`/companies/${companyId}/files/${file.id}`, {
        name,
        description,
        validUntil,
      });
      refresh();
      setEditId(undefined);
      form.reset();
    } catch {
    } finally {
      setSaving(undefined);
    }
  };

  const handleCloseDeleteFileModal = () => {
    setDeleteId(undefined);
  };

  const handleEditFile = file => {
    setEditId(file.id);
    const { name, description, validUntil } = file;
    form.setValues({
      name,
      description,
      validUntil: validUntil ? new Date(validUntil) : undefined,
    });
  };

  const handleCancelSave = () => {
    setEditId(undefined);
    form.reset();
  };

  const getDateColor = date => {
    if (!date) {
      return 'gray';
    }
    const inputDate = new Date(date);
    const currentDate = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(currentDate.getDate() + 7);
    if (inputDate < currentDate) {
      return 'red';
    }
    if (inputDate <= sevenDaysFromNow) {
      return 'yellow';
    }

    return 'green';
  };

  const translations = useTranslations();
  return (
    <div style={{ position: 'relative' }}>
      <Title order={4} mt={20} mb={10}>
        {translations.files.currentFiles}
      </Title>
      <LoadingOverlay visible={isLoading} />

      <Table captionSide="bottom">
        <thead>
          <tr>
            <th>{translations.global.name}</th>
            <th>{translations.global.description}</th>
            <th>{translations.global.validUntil}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.files.map((file, index) => (
            <tr key={`${file.name}-${index}`}>
              <td>
                {editId === file.id ? (
                  <TextInput
                    placeholder={translations.global.name}
                    pr={10}
                    pl={10}
                    required
                    {...form.getInputProps('name')}
                  />
                ) : (
                  file.name
                )}
              </td>
              <td>
                {editId === file.id ? (
                  <TextInput
                    name={`description.${index}`}
                    placeholder={translations.global.description}
                    pr={10}
                    pl={10}
                    {...form.getInputProps('description')}
                  />
                ) : (
                  file.description
                )}
              </td>
              <td>
                {editId === file.id ? (
                  <DatePickerInput
                    name="validUntil"
                    placeholder={translations.global.validUntil}
                    clearable
                    pr={10}
                    pl={10}
                    {...form.getInputProps('validUntil')}
                  />
                ) : (
                  <Badge color={getDateColor(file.validUntil)}>
                    {readableDateWithoutTime(file.validUntil)}
                  </Badge>
                )}
              </td>
              <td>
                {editId === file.id && (
                  <Group>
                    <ActionIcon
                      radius={1}
                      size="sm"
                      color="green"
                      onClick={() => handleUpdateFile(file)}
                      disabled={isSaving}
                      loading={isSaving && editId === file.id}>
                      <Check />
                    </ActionIcon>
                    <ActionIcon
                      radius={1}
                      size="sm"
                      onClick={() => handleCancelSave()}
                      disabled={isSaving}
                      loading={isSaving && editId === file.id}>
                      <X />
                    </ActionIcon>
                  </Group>
                )}
                {editId !== file.id && (
                  <Group>
                    <ActionIcon
                      radius={1}
                      size="sm"
                      onClick={() => handleDownloadFile(file)}
                      disabled={isDownloading || editId}
                      loading={isDownloading === file.id}>
                      <Download />
                    </ActionIcon>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <Button size="xs" variant="light">
                          <Dots />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item disabled={editId} onClick={() => handleEditFile(file)}>
                          {translations.global.edit}
                        </Menu.Item>
                        <Menu.Item
                          disabled={editId}
                          onClick={() => {
                            setDeleteId(file.id);
                          }}
                          color="red">
                          {translations.global.delete}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DeleteCompanyFileModal
        opened={!!deleteId}
        onClose={handleCloseDeleteFileModal}
        companyId={companyId}
        fileId={deleteId}
        onSuccess={refresh}
      />
    </div>
  );
};
export default CompanyFilesTable;
