// export const getFirebaseImageUrlsInVisualInspection = async (answers) => {
//     return await Promise.all(
//       answers.map(async (answer) => {
//         const images = await Promise.all(
//           answer.images.map(async (imageUri) => {
//             const file = await getFirebaseFile(imageUri);
//             const url = await getSignedUrl(file);
//             return { url };
//           })
//         );
//         return { ...answer, images };
//       })
//     );
//   };

import { useMemo } from 'react';
import { useTranslations } from '../../../../../shared/translations/useTranslations';

export const getAnswerTranslation = (translations, answer) => {
  if (answer === 'YES') {
    return translations.global.yes || answer;
  }
  if (answer === 'NO') {
    return translations.global.no || answer;
  }

  return answer;
};

export const useVisualInspectionAnswers = inspection => {
  const translations = useTranslations();

  //   if (inspection.visualInspection && inspection.visualInspection.answers) {
  //     visualInspectionAnswers = await getFirebaseImageUrlsInVisualInspection(visualInspectionAnswers);
  //   }

  return useMemo(() => {
    if (!inspection.visualInspection || !inspection.visualInspection.answers) {
      return [];
    }

    return inspection.visualInspection.answers.map((answer, index) => ({
      index: index + 1,
      question: answer.question.question,
      answer: getAnswerTranslation(translations, answer.value),
      note: answer.note && answer.note.length ? answer.note : '-',
      recommendation:
        answer.recommendation && answer.recommendation.length ? answer.recommendation : '-',
      images: answer.images, // TODO: Map with firebase
    }));
  }, [inspection, translations]);
};
