import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import shared from 'shared/reducers';
import auth from 'modules/Auth/reducers';
import companies from 'modules/Company/reducers';
import places from 'modules/Place/reducers';
import devices from 'modules/Device/reducers';
import deviceTypes from 'modules/DeviceType/reducers';
import users from 'modules/User/reducers';
import reports from 'modules/Reports/reducers';

export default history =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    auth,
    companies,
    places,
    shared,
    users,
    devices,
    reports,
    deviceTypes,
  });
