import React from 'react';
import { Card, Group, LoadingOverlay, ScrollArea, Table, Title } from '@mantine/core';
import { routes } from '../../shared/constants/routes';
import { useTranslations } from '../../shared/translations/useTranslations';
import SeeMoreButton from './SeeMoreButton';
import { DashboardDeviceCountByDeviceType } from './types';

interface Props {
  placeId?: string | number;
  deviceCountByDeviceType: DashboardDeviceCountByDeviceType[];
  loading: boolean;
}

const DashboardDevicesByType = (props: Props) => {
  const translations = useTranslations();

  return (
    <Card
      shadow="sm"
      p="lg"
      mb={20}
      style={{ maxHeight: '400px', height: '400px', overflow: 'unset' }}>
      <LoadingOverlay visible={props.loading} />
      <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
        <Title order={4}>{translations.global.deviceTypes}</Title>
        {props.placeId && (
          <SeeMoreButton to={routes.places.manage.to(props.placeId, '?tab=devices')} />
        )}
      </Group>
      <ScrollArea type="hover" style={{ height: '300px' }}>
        <Table>
          <thead>
            <tr>
              <th>{translations.global.deviceType}</th>
              <th>{translations.global.devicesCount}</th>
            </tr>
          </thead>
          <tbody>
            {props.deviceCountByDeviceType?.map((deviceType, index) => (
              <tr key={[deviceType, index].join('-')}>
                <td>{deviceType.deviceTypeName}</td>
                <td>{deviceType.deviceCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
};

export default DashboardDevicesByType;
