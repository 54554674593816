import { useSelector } from 'react-redux';
import React from 'react';
import { get } from 'lodash';

import { routes } from 'shared/constants/routes';

import { usePushAction } from './usePushRoute';

export const useRedirectToLoginIfNotAuthenticated = () => {
  const navigateToDashboard = usePushAction(routes.app.default.path);
  const isAuthenticated = useSelector(state => get(state, 'auth.data.token')) !== undefined;

  return React.useEffect(() => {
    if (isAuthenticated) {
      navigateToDashboard();
    }
  }, [isAuthenticated]);
};
