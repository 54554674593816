import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { routes } from 'shared/constants/routes';
import apiService from 'shared/services/api';
import { useTranslations } from 'shared/translations/useTranslations';
import { LoraDevice } from 'shared/types/LoraDevice';

export const useOnSubmit = (loraDeviceUuid?: string) => {
  const history = useHistory();
  const [isSavingLoraDevice, setIsSavingLoraDevice] = useState(false);
  const translations = useTranslations();
  const submitValues = useCallback(
    async values => {
      setIsSavingLoraDevice(true);
      try {
        const { data } = loraDeviceUuid
          ? await apiService({}).patch<{ loraDevice: LoraDevice }>(
              `/api/lora/devices/${loraDeviceUuid}`,
              values,
            )
          : await apiService({}).post<{ loraDevice: LoraDevice }>(`/api/lora/devices`, values);

        if (!data.loraDevice) {
          setIsSavingLoraDevice(false);
          return;
        }

        history.push(routes.lora.devices.details.to(data.loraDevice.uuid));
        setIsSavingLoraDevice(false);
        if (loraDeviceUuid) {
          // toast.success(translations.loraDevice.modifiedSuccess);
        } else {
          // toast.success(translations.loraDevice.createdSuccess);
        }
      } catch (error) {
        setIsSavingLoraDevice(false);
        toast.error(translations.global.somethingWentWrong);
      }
    },
    [history, loraDeviceUuid, isSavingLoraDevice, setIsSavingLoraDevice, translations],
  );

  return {
    submitValues,
    isSubmittingForm: isSavingLoraDevice,
  };
};
