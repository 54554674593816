import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { useForm } from '@mantine/form';
import { useTranslations } from 'shared/translations/useTranslations';
import { get } from 'lodash';
import QuestionForm from '../Form';
import { Button, Group, LoadingOverlay, Modal, Notification } from '@mantine/core';
import { VisualInspectionQuestionModel } from '../VisualInspection';
import { X as IconX } from 'tabler-icons-react';

interface Props {
  id?: string;
  refreshParent: () => any;
  onClose: () => any;
}

interface Data {
  question: VisualInspectionQuestionModel;
}
const Details = (props: Props) => {
  const { id, refreshParent, onClose } = props;
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<Data>({
    initialValue: { question: {} },
    shouldNotFetchAtStart: true,
    fetchAction: () => api({}).get(`/api/visual-inspection/questions/${id}`),
  });

  const form = useForm({
    initialValues: {
      question: undefined,
      comment: undefined,
      defaultAnswer: undefined,
      translations: [
        { lang: 'pl', question: undefined },
        { lang: 'en', question: undefined },
        { lang: 'de', question: undefined },
        { lang: 'it', question: undefined },
        { lang: 'ru', question: undefined },
      ],
    },

    validate: {},
  });
  useEffect(() => {
    if (data?.question?.translations) {
      const translations = form.values.translations.map((translation, key) => {
        return {
          ...translation,
          question: get(data?.question?.translations, `${translation.lang}.question`),
        };
      });
      form.setValues({
        question: data?.question?.question,
        comment: data?.question?.comment,
        defaultAnswer: data?.question?.defaultAnswer,
        translations: translations,
      });
    } else {
      form.setValues({
        question: data?.question?.question,
        comment: data?.question?.comment,
        defaultAnswer: data?.question?.defaultAnswer,
        translations: [
          { lang: 'pl', question: data?.question?.question },
          { lang: 'en', question: data?.question?.question },
          { lang: 'de', question: data?.question?.question },
          { lang: 'it', question: data?.question?.question },
          { lang: 'ru', question: data?.question?.question },
        ],
      });
    }
  }, [data?.question]);

  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const body = {
        question: formValues.question,
        comment: formValues.comment,
        defaultAnswer: formValues.defaultAnswer,
        translations: {
          pl: { question: '' },
          en: { question: '' },
          de: { question: '' },
          it: { question: '' },
          ru: { question: '' },
        },
      };
      formValues?.translations.forEach(translation => {
        body.translations[translation.lang].question = translation.question;
      });
      await api({}).patch(`/api/visual-inspection/questions/${id}`, body);
      toast.success(translations.visualInspection.questionUpdatedSuccess);
      setSaving(false);
      setOpened(false);
      onClose();
      refreshParent();
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(!!id);
    if (id) {
      refresh();
    }
    if (!id) {
      form.reset();
    }
  }, [id]);
  const handleClose = () => {
    setOpened(false);
    onClose();
  };
  return (
    <Modal opened={opened} onClose={handleClose} title={translations.visualInspection.editQuestion}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        {error && (
          <Notification icon={<IconX size={18} />} color="red" mt={60} mb={60} disallowClose>
            {translations.global.somethingWentWrong}
          </Notification>
        )}
        {!error && <QuestionForm form={form} />}
        <Group position="right" mt={20}>
          <Button color="primary" loading={isSaving} type="submit" disabled={!!error || !isLoaded}>
            {translations.global.edit}
          </Button>
          <Button variant="outline" onClick={handleClose}>
            {translations.global.cancel}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default Details;
