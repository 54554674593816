import React, { useMemo } from 'react';
import { Select, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import { CompanyZone } from './types';
import { formatZonesToDataOptions } from './helpers';

interface Props {
  zones: CompanyZone[];
  form: any; // UseFormReturnType<any>;
  currentZoneUuid?: string;
}

const AddCompanyZoneForm = (props: Props) => {
  const { form, zones, currentZoneUuid } = props;
  const translations = useTranslations();
  const parentZoneOptions = useMemo(() => {
    return formatZonesToDataOptions(zones, 0, currentZoneUuid);
  }, [zones]);
  return (
    <div style={{ minHeight: 300 }}>
      <TextInput
        name={`name`}
        placeholder={translations.global.name}
        mb={10}
        label={translations.global.name}
        required
        {...form.getInputProps('name')}
      />
      <TextInput
        name={`description`}
        placeholder={translations.global.description}
        mb={10}
        label={translations.global.description}
        {...form.getInputProps('description')}
      />
      <Select
        name={`parentZoneUuid`}
        mb={10}
        searchable
        clearable
        label={translations.companyZones.parentZone}
        data={parentZoneOptions}
        {...form.getInputProps('parentZoneUuid')}
      />
    </div>
  );
};
export default AddCompanyZoneForm;
