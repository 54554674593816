import React, { useState } from 'react';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import ListHeading from 'shared/components/ListHeading';
import AppContent from 'shared/components/AppContent';
import { useHistory, useParams } from 'react-router';
import { routes } from 'shared/constants/routes';
import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';

import Form from '../Form';
import { useTranslations } from 'shared/translations/useTranslations';
interface Props {}
const Create = (props: Props) => {
  const translations = useTranslations();
  const { placeId } = useParams<{ placeId: string }>();
  const [isSaving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      priority: 'LOW',
      status: 'OPEN',
      recommendation: undefined,
      comment: undefined,
      ownerId: undefined,
      ownerEmail: undefined,
      ownerText: undefined,
      area: undefined,
      dueDate: new Date(),
      recommendationCategoryId: undefined,
      deviceId: undefined,
      deviceTypeId: undefined,
      zoneId: undefined,
      mapId: undefined,
      mapText: undefined,
      mapX: undefined,
      mapY: undefined,
      notifications: [],
    },

    validate: {},
  });
  const history = useHistory();
  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      const isUserCreated = !!data?.ownerText;
      const ownerText = isUserCreated ? data?.ownerText : undefined;
      const ownerId = !isUserCreated
        ? Number(String(data.ownerId).replace('existing-', ''))
        : undefined;

      const body = {
        ...data,
        ownerId,
        ownerText,
        mapId: !Number.isNaN(Number(data.mapId)) ? data.mapId : undefined,
        mapText: !Number.isNaN(Number(data.mapId)) ? undefined : data.mapId,
        placeId,
      };
      const response = await api({}).post(`/api/recommendations/${placeId}`, body);
      toast.success(translations.recommendations.hasBeenAddedSuccessfully);
      setSaving(false);
      history.push(routes.recommendation.details.to(placeId, response.data.recommendation.id));
    } catch (e) {
      setSaving(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  const goToList = () => {
    history.push(routes.recommendation.place.to(placeId));
  };

  return (
    <>
      <form
        onSubmit={form.onSubmit(values => {
          onSubmit(values);
        })}>
        <ListHeading title={translations.recommendations.addRecommendation}>
          <Group position="right" mt="md">
            <Button type="submit" loading={isSaving}>
              {translations.global.add}
            </Button>
            <Button type="button" color="" variant="outline" onClick={goToList}>
              {translations.global.cancel}
            </Button>
          </Group>
        </ListHeading>
        <AppContent>
          <Form form={form} />
        </AppContent>
      </form>
    </>
  );
};

export default Create;
