import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FieldError = ({ meta }) => {
  if (!(meta.error && meta.touched)) {
    return null;
  }
  return <div className="FieldError">{meta.error}</div>;
};

FieldError.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
};

export default FieldError;
