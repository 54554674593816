import apiService from 'shared/services/api';

export const fetchReports = params => apiService({}).get(`/reports`, { params });

export const generatePdf = payload =>
  apiService({ timeout: 120000, baseUrl: process.env.REACT_APP_BACKEND_PDF_URL }).post(
    `/reports/pdf`,
    payload,
  );
export const generatePdfPreview = payload =>
  apiService({ timeout: 120000, baseUrl: process.env.REACT_APP_BACKEND_PDF_URL }).post(
    `/reports/pdf?shouldRenderHtml=true`,
    payload,
  );

export const getPdfTemporaryUrl = id => apiService({}).get(`/reports/${id}/signed-url`);

export const deleteReport = id => apiService({}).delete(`/reports/${id}`);
