import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useForm, zodResolver } from '@mantine/form';
import { Alert, Button, Group, Loader, Modal, Select, TextInput } from '@mantine/core';
import { useTranslations } from 'shared/translations/useTranslations';
import Form from '../Form';
import useFetch from '../../../../../shared/hooks/useFetch';
import { deviceErrorMessages } from '../../../../../shared/constants/errors';
import { pick } from 'lodash';

interface Props {
  onClose: () => any;
  refreshParent: () => any;
  placeId: string;
  selectedDevices: any[];
  opened: boolean;
}

const AssignDevicesToZoneModal = (props: Props) => {
  const { onClose, placeId, selectedDevices, refreshParent, opened } = props;
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);

  const {
    data: zonesData,
    isLoading: isLoadingZones,
    isLoaded,
    error,
    refresh,
  } = useFetch<{
    zones: any[];
  }>({
    initialValue: { mixtures: [] },
    fetchAction: () => api({}).get(`/api/places/${placeId}/zones`),
  });

  const form = useForm({
    initialValues: {
      zone: undefined,
    },
  });

  const onSubmit = async () => {
    setSaving(true);
    try {
      const { data } = await api({}).post(`/api/places/${placeId}/devices/bulk-zone-assign`, {
        deviceUuids: selectedDevices?.map(d => d.uuid),
        zoneId: form.values.zone,
      });

      toast.success(translations.global.deleteSuccessful);
      setSaving(false);
      refreshParent();
      onClose();
    } catch (e) {
      setSaving(false);
      const errorMessages = deviceErrorMessages(translations);
      if (errorMessages[e?.response?.data?.code]) {
        toast.error(errorMessages[e.response.data.code]);
      } else {
        toast.error(translations.global.somethingWentWrong);
      }
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Przypisz urządzenia do strefy">
      <div style={{ minHeight: 205 }}>
        Ilość wybranych urządzeń: {selectedDevices.length}
        <Select
          mb={10}
          mt={10}
          searchable
          clearable
          data={
            zonesData?.zones?.map(zone => ({
              value: zone.id,
              label: zone.name,
            })) || []
          }
          label={translations.zones.zone}
          {...form.getInputProps('zone')}
        />
        <Group position="right" mt={20}>
          <Button loading={isSaving} type="submit" onClick={onSubmit}>
            {translations.global.yes}
          </Button>
          <Button variant="outline" onClick={() => onClose()}>
            {translations.global.cancel}
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default AssignDevicesToZoneModal;
