import update from 'immutability-helper';
import { createReducer } from 'shared/utils/redux';
import { loadingStates, setLoadingStates } from 'shared/constants/redux';
import { createRequestTypes } from 'shared/utils/request';

export const AUTH_PERFORM_LOGIN = createRequestTypes('auth/PERFORM_LOGIN');
export const AUTH_PERFORM_LOGOUT = createRequestTypes('auth/PERFORM_LOGOUT');
export const AUTH_PERFORM_REFRESH_TOKEN = createRequestTypes(
  'auth/PERFORM_REFRESH_TOKEN'
);

export const performLogout = () => ({
  type: AUTH_PERFORM_LOGOUT.REQUEST
});
export const performLogin = ({ email, password }) => ({
  type: AUTH_PERFORM_LOGIN.REQUEST,
  payload: {
    email,
    password
  }
});
export const performRefreshToken = ({ refreshToken }) => ({
  type: AUTH_PERFORM_REFRESH_TOKEN.REQUEST,
  payload: { refreshToken }
});

const initState = {
  refreshToken: loadingStates,
  states: loadingStates,
  response: ''
};

export default createReducer(initState, {
  [AUTH_PERFORM_REFRESH_TOKEN.REQUEST]: state => {
    return update(state, {
      $merge: {
        refreshToken: setLoadingStates({ isLoading: true })
      }
    });
  },
  [AUTH_PERFORM_REFRESH_TOKEN.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        refreshToken: setLoadingStates({ isLoaded: true })
      }
    });
  },
  [AUTH_PERFORM_REFRESH_TOKEN.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        refreshToken: setLoadingStates({ hasError: true })
      }
    });
  },
  [AUTH_PERFORM_LOGIN.REQUEST]: state => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoading: true })
      }
    });
  },
  [AUTH_PERFORM_LOGIN.SUCCESS]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ isLoaded: true }),
        response: action.payload.response
      }
    });
  },
  [AUTH_PERFORM_LOGIN.FAILURE]: (state, action) => {
    return update(state, {
      $merge: {
        states: setLoadingStates({ hasError: true }),
        response: action.payload.response
      }
    });
  }
});
