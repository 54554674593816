import React, { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'shared/translations/useTranslations';
import { map } from 'lodash';
import { Space, Table } from '@mantine/core';
import styles from './styles.module.scss';
import UnassignObservationFromDeviceType from '../UnassignObservationFromDeviceType';

interface Props {
  isLoading: boolean;
  observations?: any[];
  reset: () => void;
  deviceTypeId: string | number;
}

const Observations = (props: Props) => {
  const translations = useTranslations();
  const { isLoading, observations, reset, deviceTypeId } = props;
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>{translations.global.name}</th>
          <th>{translations.global.type}</th>
          <th style={{ width: '100px' }}></th>
        </tr>
      </thead>
      <tbody>
        {map(observations, observation => (
          <tr key={`observation-${observation.id}`} className={styles.row}>
            <td>{observation.name}</td>
            <td>{translations.deviceTypes.observationTypes[observation.type]}</td>
            <td>
              <div className={styles.buttons}>
                <div className="d-flex">
                  {/* <EditDeviceTypeObservation
                    currentObservations={observations}
                    id={observation.id}
                    deviceTypeId={deviceTypeId}
                    onFinish={reset}
                  /> */}
                  <UnassignObservationFromDeviceType
                    id={observation?.observationAssociation?.uuid}
                    onFinish={reset}
                    deviceTypeId={deviceTypeId}
                  />
                  <Space w="xs" />
                  {/* <DeleteDeviceTypeObservation id={observation.id} onFinish={reset} /> */}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default Observations;
